import {
  Autocomplete,
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import InfoIcon from "@mui/icons-material/Info";
import * as React from "react";
import { pickBy } from "lodash";
import { LoadingButton } from "@mui/lab";
import {
  serviceProvidersSortedSelector,
  spFullNameSelector
} from "../../../reducers/serviceProvider";
import {
  btnStyles,
  SERVICE_PROVIDER_RATE_UNIT,
  serviceProviderRateUnitType
} from "../../Calendar/Booking/ServicesSelectWithSpAssign";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { updateServices } from "../../../actions";
import { ServiceInterface } from "../../../interfaces/ServiceInterface";
import { ServiceActions } from "../../../interfaces/ActionTypes";
import { notificationAdd } from "../../../actions/notification";
import { removeGeneralErrorByKey } from "../../../actions/error";
import { commonErrorMessage } from "../../../helpers/messages";
import { tl } from "../../../components/translate";

interface Props {
  serviceId: number;
  openDialog: boolean;
  onCancel: () => void;
  onSave: (spId: number) => void;
}

const ServiceProviderCommissionDialog = ({
  serviceId,
  openDialog,
  onCancel,
  onSave
}: Props): JSX.Element => {
  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting, isValid }
  } = useForm<FieldValues>({
    defaultValues: {
      serviceProvider: null,
      rate: 0
    },
    mode: "all"
  });
  const [serviceProviderRateUnit, setServiceProviderRateUnit] =
    React.useState<serviceProviderRateUnitType>(SERVICE_PROVIDER_RATE_UNIT.PERCENTAGE);
  const serviceProviders = useAppSelector((state) => serviceProvidersSortedSelector(state));
  const services = useAppSelector((state) => state.services.collection);

  const dispatch = useAppDispatch();

  const selectedService = React.useMemo(
    () => services.find((item) => item.id === serviceId),
    [serviceId, services]
  );

  const existingAssignedSps = (selectedService?.document?.rates || []).map(
    (item: { serviceProviderId: number; rate: string }) => item.serviceProviderId
  );

  const rateAdornment =
    serviceProviderRateUnit === SERVICE_PROVIDER_RATE_UNIT.RUPEE
      ? { startAdornment: <InputAdornment position="start">Rs.</InputAdornment> }
      : { endAdornment: <InputAdornment position="end">%</InputAdornment> };

  React.useEffect(() => {
    if (selectedService) {
      setServiceProviderRateUnit(
        selectedService.serviceProviderRateUnit || SERVICE_PROVIDER_RATE_UNIT.PERCENTAGE
      );
    }
  }, [selectedService]);

  return (
    <Dialog open={openDialog} onClose={onCancel} fullWidth maxWidth="sm">
      <DialogTitle>{`Add service provider commission on ${selectedService?.name}`}</DialogTitle>
      <DialogContent sx={{ pb: 0 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            flexWrap: "wrap",
            gap: 2,
            pt: 2
          }}
        >
          <Controller
            control={control}
            name="serviceProvider"
            rules={{ required: { value: true, message: "Service provider is required" } }}
            render={({ field, fieldState }) => (
              <Autocomplete
                value={field.value}
                onChange={(_, newValue) => field.onChange(newValue)}
                options={serviceProviders.filter(
                  (rcSp) => !existingAssignedSps.find((item) => item === rcSp.id)
                )}
                sx={{ width: 300 }}
                getOptionLabel={(option) => spFullNameSelector(option)}
                renderOption={(renderProps, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...renderProps} key={option.id}>
                    {spFullNameSelector(option)}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Service Provider"
                    placeholder="select a service provider"
                    variant="outlined"
                    error={Boolean(fieldState.error)}
                    helperText={fieldState.error?.message}
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                  />
                )}
              />
            )}
          />
          <Controller
            control={control}
            name="rate"
            rules={{
              required: { value: true, message: "Rate is required" },
              pattern: {
                value: /^\d+(\.\d+)?$/,
                message: "Please enter valid rate"
              }
            }}
            render={({ field, fieldState }) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                variant="outlined"
                label="Rate"
                placeholder="Enter rate"
                error={Boolean(fieldState.error)}
                helperText={fieldState.error?.message}
                slotProps={{
                  inpit: { rateAdornment },
                  inputLabel: { shrink: true }
                }}
                onFocus={(e) => e.target.select()}
              />
            )}
          />
          <ButtonGroup>
            <Button
              onClick={() => setServiceProviderRateUnit(SERVICE_PROVIDER_RATE_UNIT.RUPEE)}
              sx={
                serviceProviderRateUnit === SERVICE_PROVIDER_RATE_UNIT.RUPEE
                  ? btnStyles.disabled
                  : btnStyles.active
              }
            >
              Rs.
            </Button>
            <Button
              onClick={() => setServiceProviderRateUnit(SERVICE_PROVIDER_RATE_UNIT.PERCENTAGE)}
              sx={
                serviceProviderRateUnit === SERVICE_PROVIDER_RATE_UNIT.PERCENTAGE
                  ? btnStyles.disabled
                  : btnStyles.active
              }
            >
              %
            </Button>
          </ButtonGroup>
        </Box>
        <Box sx={{ display: "flex", gap: 1, mt: 2 }}>
          <InfoIcon color="info" />
          <Typography>
            This will add service provider to your service. Changing rate unit will also affect
            other service providers on this service.
          </Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            onCancel();
            reset();
          }}
        >
          Cancel
        </Button>
        <LoadingButton
          onClick={handleSubmit(async (data) => {
            const rates = [...(selectedService?.document?.rates || [])];
            rates.push({
              serviceProviderId: data.serviceProvider.id,
              rate: data.rate
            });
            const serviceData: ServiceInterface = {
              ...pickBy(selectedService),
              serviceProviderRateUnit,
              document: {
                ...(selectedService.document || []),
                rates
              }
            };

            await dispatch(async (_, getState) => {
              await dispatch(updateServices(serviceData));
              const errorObject = getState().error.filter(
                ({ key }) => key === ServiceActions.UPDATE_SERVICES
              );
              if (errorObject.length) {
                dispatch(
                  notificationAdd({
                    id: new Date().getUTCMilliseconds(),
                    variant: "error",
                    message: errorObject[0].message || commonErrorMessage,
                    autoTimeout: true
                  })
                );
                dispatch(removeGeneralErrorByKey("UPDATE_SERVICES"));
              } else {
                dispatch(
                  notificationAdd({
                    id: new Date().getUTCMilliseconds(),
                    variant: "success",
                    message: tl("services.successUpdateProduct"),
                    autoTimeout: true
                  })
                );
                onSave(data.serviceProvider.id);
                reset();
                onCancel();
              }
            });
          })}
          variant="contained"
          loading={isSubmitting}
          disabled={!isValid || isSubmitting}
          type="submit"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ServiceProviderCommissionDialog;
