import { Box, Grid2 as Grid, Typography } from "@mui/material";
import React from "react";
import moment from "moment";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import KeyboardDoubleArrowDown from "@mui/icons-material/KeyboardDoubleArrowDown";
import BedOutlinedIcon from "@mui/icons-material/BedOutlined";
import { getIpdStatsForToday } from "../../../api/ipd";
import { Bed } from "../../../api/wards";
import { IPD } from "../../../interfaces/IpdInterface";
import OccupiedBed from "../../../Svgicons/OccupiedBed";
import EmptyBed from "../../../Svgicons/EmptyBed";
import { notificationAdd } from "../../../actions/notification";
import { tl } from "../../../components/translate";

export enum StatKeys {
  "TotalBeds" = "totalBeds",
  "AvailableBeds" = "availableBeds",
  "OccupiedBeds" = "occupiedBeds",
  "AdmittedToday" = "admittedToday",
  "DischargedToday" = "dischargedToday",
  "AdmittedClients" = "admittedClients"
}

const SingleBedKPI = ({ title, value, statKey }): JSX.Element => (
  <Box
    sx={{
      border: "1px solid rgba(90, 96, 127, 0.5)",
      borderRadius: "10px",
      display: "flex",
      height: "100%",
      justifyContent: "space-between",
      alignItems: "center",
      "&:hover": {
        boxShadow: "0px 15px 30px rgb(0 150 84 / 25%)",
        cursor: "pointer"
      }
    }}
    py={3}
    px={5}
  >
    <Box>
      <Typography fontSize="16px">{title}</Typography>
      <Typography fontWeight="700" fontSize="25px" mt={1}>
        {value}
      </Typography>
    </Box>
    <Box
      sx={{
        height: "48px",
        width: "48px",
        borderRadius: "24px",
        padding: "10px",
        backgroundColor: "rgba(0, 150, 84, 0.25)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      {statKey === StatKeys.TotalBeds && <BedOutlinedIcon color="success" />}
      {statKey === StatKeys.AvailableBeds && <EmptyBed />}
      {statKey === StatKeys.OccupiedBeds && <OccupiedBed />}
      {statKey === StatKeys.AdmittedToday && <KeyboardDoubleArrowUpIcon color="success" />}
      {statKey === StatKeys.DischargedToday && <KeyboardDoubleArrowDown color="warning" />}
    </Box>
  </Box>
);

export interface IpdStats {
  admitted: number;
  discharged: number;
}

interface Props {
  beds: Array<Bed>;
  activeIpds: Array<Partial<IPD>>;
  selectedDate: moment.Moment;
  setFilter: React.Dispatch<
    React.SetStateAction<{
      selectedWard: number | null;
      bedNumber: string;
      kpiKey: string;
      filterAvailableBeds: boolean;
    }>
  >;
}

const BedStats = ({ beds, activeIpds, selectedDate, setFilter }: Props): JSX.Element => {
  const onlyActiveIpds = activeIpds.filter((ipd) => ipd.active);
  const [ipdStats, setIpdStats] = React.useState<IpdStats | null>(null);

  const kpis = [
    {
      title: tl("ipd.totalBeds"),
      key: StatKeys.TotalBeds,
      value: beds.length
    },
    {
      title: tl("ipd.availableBeds"),
      key: StatKeys.AvailableBeds,
      value: beds.length - onlyActiveIpds.length
    },
    {
      title: tl("ipd.admittedClients"),
      key: StatKeys.AdmittedClients,
      value: onlyActiveIpds.length
    },
    {
      title: tl("ipd.admittedToday"),
      key: StatKeys.AdmittedToday,
      value: ipdStats?.admitted
    },
    {
      title: tl("ipd.dischargeToday"),
      key: StatKeys.DischargedToday,
      value: ipdStats?.discharged
    }
  ];

  React.useEffect(() => {
    const fetchStats = async () => {
      try {
        const query = {
          from: moment(selectedDate).startOf("day").toISOString(),
          until: moment(selectedDate).endOf("day").toISOString()
        };
        const ipdKPIs = await getIpdStatsForToday(query);
        setIpdStats(ipdKPIs);
      } catch (err) {
        setIpdStats({
          admitted: 0,
          discharged: 0
        });
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Couldn't get Ipd stats",
          autoTimeout: true
        });
      }
    };
    fetchStats();
  }, [selectedDate]);

  return (
    <Grid container spacing={2} px={3}>
      {kpis.map((kpi) => (
        <Grid
          size={{ xs: 6, sm: 4, md: 2.4 }}
          key={kpi.key}
          onClick={() =>
            setFilter({
              kpiKey: kpi.key,
              selectedWard: null,
              bedNumber: "",
              filterAvailableBeds: false
            })
          }
          data-testmation={kpi.title}
        >
          <SingleBedKPI title={kpi.title} value={kpi.value} statKey={kpi.key} />
        </Grid>
      ))}
    </Grid>
  );
};

export default BedStats;
