import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Menu, MenuItem, Button, Divider, CircularProgress, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Clear } from "@mui/icons-material";
import DebouncedTextField from "../../components/DebouncedTextField";
import { ReverseMap } from "../../helpers/types";

export enum PRODUCT_SEARCH_BY {
  DEFAULT = "default",
  BARCODE = "barcode",
  GENERIC_NAME = "genericName"
}

export type ProductSearchByType = ReverseMap<typeof PRODUCT_SEARCH_BY>;

const searchComponentMapper = {
  [PRODUCT_SEARCH_BY.DEFAULT]: {
    label: "Default",
    placeholder: "Name or product code..."
  },
  [PRODUCT_SEARCH_BY.BARCODE]: {
    label: "Barcode",
    placeholder: "Barcode..."
  },
  [PRODUCT_SEARCH_BY.GENERIC_NAME]: {
    label: "Generic name",
    placeholder: "Generic name..."
  }
};

const menuItems = Object.keys(searchComponentMapper);

interface Props {
  value: string;
  loading: boolean;
  onLoadingChange: (value: boolean) => void;
  onInputChange: (value?: string) => void;
  onSearchByChange: (value: ProductSearchByType) => void;
}

const ProductSearchBar = ({
  value,
  onInputChange,
  onSearchByChange,
  onLoadingChange,
  loading
}: Props): JSX.Element => {
  const toggleRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeMenuItem, setActiveMenuItem] = React.useState(menuItems[0]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  return (
    <Box
      sx={(theme) => ({
        position: "relative",
        borderRadius: 1,
        width: "300px",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        transition: "width .5s, background 1s ease",
        backgroundColor: "#ececec",
        [theme.breakpoints.down("sm")]: {
          width: "280px"
        }
      })}
      data-testmation="search"
    >
      <SearchIcon sx={{ position: "absolute", width: "35px" }} />
      <Box sx={{ mt: 1, width: "220px" }}>
        <DebouncedTextField
          sx={{ ml: 4 }}
          value={value}
          placeholder={searchComponentMapper[activeMenuItem].placeholder}
          size="small"
          slotProps={{
            htmlInput: { maxLength: 30 },
            input: { disableUnderline: true }
          }}
          onChange={(e) => {
            onInputChange(e.target.value);
          }}
          debounceAt={1000}
          setDebounceLoading={onLoadingChange}
        />
      </Box>
      {loading ? (
        <Box sx={{ display: "grid", placeItems: "center", mr: 1 }}>
          <CircularProgress size={20} />
        </Box>
      ) : (
        value && (
          <IconButton onClick={() => onInputChange()} size="small">
            <Clear />
          </IconButton>
        )
      )}
      <Divider sx={{ height: "20px" }} orientation="vertical" />
      <div>
        <Button
          aria-controls="search-menu"
          aria-haspopup="true"
          onClick={handleClick}
          sx={{ whiteSpace: "pre" }}
        >
          {searchComponentMapper[activeMenuItem].label} <ExpandMoreIcon />
        </Button>
        <Menu
          ref={toggleRef}
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={() => setAnchorEl(null)}
        >
          {menuItems.map((item) => (
            <MenuItem
              key={item}
              onClick={() => {
                setAnchorEl(null);
                setActiveMenuItem(item);
                onSearchByChange(item);
              }}
            >
              {searchComponentMapper[item].label}
            </MenuItem>
          ))}
        </Menu>
      </div>
    </Box>
  );
};

export default ProductSearchBar;
