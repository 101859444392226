import { MenuItem, TextField } from "@mui/material";
import * as React from "react";
import { StockItemInterface, TransactionTypes } from "../../../../interfaces/StockInterfaces";
import { tl } from "../../../../components/translate";

interface Props {
  transactionType: TransactionTypes;
  item: StockItemInterface;
  onUnitSelect: () => void;
  onPackageSelect: () => void;
}

export default function StockUnit(props: Props): JSX.Element {
  const { transactionType, item, onUnitSelect, onPackageSelect } = props;
  return (
    <div>
      <TextField
        disabled={["editPurchase", "editOpeningStock"].includes(transactionType)}
        select
        label={tl("StockEntry.Unit")}
        value={item.enteredQuantityUnit === "unit" ? item.unit : item.package}
        margin="none"
        fullWidth
        slotProps={{
          inputLabel: { shrink: true }
        }}
        variant="outlined"
      >
        <MenuItem
          value={item.unit}
          onClick={() => {
            onUnitSelect();
          }}
        >
          {item.unit}
        </MenuItem>
        <MenuItem
          value={item.package}
          onClick={() => {
            onPackageSelect();
          }}
        >
          {item.package}
        </MenuItem>
      </TextField>
    </div>
  );
}
