import React from "react";
import { Box, Grid2 as Grid, TextField, Typography } from "@mui/material";
import { match as MatchProps } from "react-router";
import produce from "immer";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { notificationAdd } from "../../actions/notification";
import { getUsersWithVerificationCodes, regenerateToken } from "../../api/user";
import { User } from "../../interfaces/User";
import { MultipleHeader, MultipleHeadersInterface } from "../../components/List";

export const publicHeadersInfo = (match: MatchProps): MultipleHeadersInterface => ({
  headers: [
    { key: "verificationCodes", title: "Verification Codes", goto: "/public/verificationCodes" },
    {
      key: "chatStats",
      title: "Chat Stats",
      goto: "/public/chatStats"
    },
    {
      key: "mobileUsers",
      title: "Mobile Users",
      goto: "/public/mobileUsers"
    }
  ],
  url: match.url
});

const VerificationCodes = ({ match }: { match: MatchProps }): JSX.Element => {
  const [verificationCodes, setVerificationCodes] = React.useState<Array<Partial<User>>>([]);
  const [isSaving, setIsSaving] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const dispatch = useDispatch();
  React.useEffect(() => {
    (async () => {
      if (query && query.length <= 3) {
        return;
      }
      try {
        const data = await getUsersWithVerificationCodes(query);
        setVerificationCodes(data);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to get verification codes",
            autoTimeout: true
          })
        );
      }
    })();
  }, [dispatch, query]);

  const handleRegenerateCode = async (payload: { id: number; phone: string }) => {
    setIsSaving(true);
    try {
      const data = await regenerateToken(payload);
      const newState = produce(verificationCodes, (draft) => {
        const index = draft.findIndex((code) => code.id === payload.id);
        if (index !== -1)
          draft[index] = {
            ...draft[index],
            ...data
          };
      });
      setVerificationCodes(newState);
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Failed to regenerate verification code",
          autoTimeout: true
        })
      );
    }
    setIsSaving(false);
  };

  return (
    <Box p={2}>
      <MultipleHeader multipleHeaders={publicHeadersInfo(match)} />
      <Box display="flex" justifyContent="flex-end" width="85%" mb={2}>
        <TextField
          variant="outlined"
          onChange={(e) => setQuery(e.target.value)}
          value={query}
          placeholder="Search Phone"
        />
      </Box>
      <Box my={2}>
        <Grid container size={12}>
          <Grid size={2}>
            <Typography fontSize={16} fontWeight="bold">
              User Id
            </Typography>
          </Grid>
          <Grid size={3}>
            <Typography fontSize={16} fontWeight="bold">
              Phone
            </Typography>
          </Grid>
          <Grid size={2}>
            <Typography fontSize={16} fontWeight="bold">
              Verification Code
            </Typography>
          </Grid>
          <Grid size={2}>
            <Typography fontSize={16} fontWeight="bold">
              Status
            </Typography>
          </Grid>
          <Grid size={3}>
            <Typography fontSize={16} fontWeight="bold">
              Actions
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box>
        {verificationCodes.map((code) => (
          <Grid container key={code.id} mt={1}>
            <Grid size={2}>
              <Typography>{code.id}</Typography>
            </Grid>
            <Grid size={3}>
              <Typography>{code.phone}</Typography>
            </Grid>
            <Grid size={2}>
              <Typography>{code.verificationCode}</Typography>
            </Grid>
            <Grid size={2}>
              <Typography>{code.verificationSentDate}</Typography>
            </Grid>
            <Grid size={3}>
              <LoadingButton
                variant="contained"
                onClick={() => handleRegenerateCode({ id: code.id, phone: code.phone })}
                loading={isSaving}
              >
                Regenerate Code
              </LoadingButton>
            </Grid>
          </Grid>
        ))}
      </Box>
    </Box>
  );
};

export default VerificationCodes;
