import React, { useEffect } from "react";
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  TextField,
  Typography
} from "@mui/material";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { pickBy } from "lodash";
import * as NotificationActions from "../../../actions/notification";
import { ProductInterface } from "../../../interfaces/ProductInterface";
import { useAppDispatch } from "../../../store/hooks";
import { t, tl } from "../../../components/translate";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { updateServices } from "../../../actions";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { getSpFullName } from "../../Client/ClientInfo/PrintClientInfoSticker";
import { commonErrorMessage } from "../../../helpers/messages";
import { removeGeneralErrorByKey } from "../../../actions/error";

interface Props {
  value: ProductInterface[];
  assignableServices: Partial<ProductInterface>[];
  spSpecificServices: Partial<ProductInterface>[];
  selectedSP: ServiceProvider;
  selectedServicesId: number[];
  error: { value: boolean; message: string };
  onChange: (value: ProductInterface[]) => void;
  afterSpAssignSave: ({
    serviceIds,
    paymentAmount
  }: {
    serviceIds: number[];
    paymentAmount: number;
  }) => void;
  isBookingWindow?: boolean;
}

export enum SERVICE_PROVIDER_RATE_UNIT {
  PERCENTAGE = "percentage",
  RUPEE = "rupee"
}

export type serviceProviderRateUnitType =
  | SERVICE_PROVIDER_RATE_UNIT.PERCENTAGE
  | SERVICE_PROVIDER_RATE_UNIT.RUPEE;

const filterOptions = createFilterOptions<ProductInterface>();

export const btnStyles = {
  disabled: {
    pointerEvents: "none",
    color: "primary.main",
    borderColor: "primary.main"
  },
  active: {
    color: "grey.A400",
    borderColor: "grey.A400",
    "&:hover": { borderColor: "grey.A400" }
  }
};

const ServicesSelectWithSpAssign = ({
  value,
  assignableServices,
  error,
  selectedSP,
  onChange,
  afterSpAssignSave,
  selectedServicesId,
  spSpecificServices,
  isBookingWindow = false
}: Props): React.JSX.Element => {
  const dispatch = useAppDispatch();
  const [openDialog, setOpenDialog] = React.useState(false);
  const [serviceProviderRateUnit, setServiceProviderRateUnit] =
    React.useState<serviceProviderRateUnitType>(SERVICE_PROVIDER_RATE_UNIT.PERCENTAGE);
  const {
    handleSubmit,
    control,
    watch,
    reset,
    formState: { isSubmitting, isValid }
  } = useForm<FieldValues>({
    defaultValues: {
      service: null,
      rate: 0
    },
    mode: "all"
  });
  const selectServiceInfo = watch("service");
  const rateAdornment =
    serviceProviderRateUnit === SERVICE_PROVIDER_RATE_UNIT.RUPEE
      ? { startAdornment: <InputAdornment position="start">Rs.</InputAdornment> }
      : { endAdornment: <InputAdornment position="end">%</InputAdornment> };

  useEffect(() => {
    if (selectServiceInfo) setServiceProviderRateUnit(selectServiceInfo.serviceProviderRateUnit);
  }, [selectServiceInfo]);

  return (
    <>
      <Autocomplete
        data-testmation="servicesSelect"
        multiple
        value={value}
        fullWidth={isBookingWindow}
        options={spSpecificServices}
        filterSelectedOptions
        disableClearable
        getOptionLabel={(option) => option?.name || ""}
        noOptionsText="No service assigned"
        filterOptions={(options, state) => {
          const results = filterOptions(options, state);
          results.push({
            name: "Assign new service",
            id: null
          });
          return results;
        }}
        renderOption={(props, option) =>
          option.id ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={option.id}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: 1,
                  width: "100%",
                  fontSize: isBookingWindow ? "12px" : ""
                }}
              >
                {option.name}
                {option.productType === "package" && <CardGiftcardIcon fontSize="small" />}
              </Box>
            </li>
          ) : (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
            <li
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              tabIndex={-1}
              key={option.name}
              onClick={() => {
                setOpenDialog(true);
              }}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setOpenDialog(true);
                }
              }}
            >
              <Typography fontWeight={600} color="primary" fontSize={isBookingWindow ? "12px" : ""}>
                {option.name}
              </Typography>
            </li>
          )
        }
        renderTags={(tags, getTagProps) =>
          tags.map((tag, index) => (
            <Chip
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...getTagProps({ index })}
              key={tag.id}
              label={tag.name}
              size="small"
              sx={{
                height: "22px",
                margin: "0px 3px 2px 0px"
              }}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            fullWidth
            margin="dense"
            placeholder={t("booking.selectService")}
            data-testmation="serviceSelectTextfield"
            size="small"
            variant={isBookingWindow ? "outlined" : undefined}
            slotProps={{
              inputLabel: { shrink: true },
              input: isBookingWindow
                ? {
                    ...params.InputProps,
                    disableUnderline: true,
                    style: {
                      width: "100%",
                      fontSize: "12px"
                    }
                  }
                : params.InputProps,
              formHelperText: {
                sx: {
                  fontSize: "0.625rem",
                  marginTop: "10px"
                }
              }
            }}
            error={error.value}
            helperText={error.message}
          />
        )}
        onChange={(e, v) => onChange(v)}
      />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} fullWidth maxWidth="sm">
        <DialogTitle>{`Assign Service For ${getSpFullName(selectedSP)}`}</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-start",
              gap: 2,
              pt: 2
            }}
          >
            <Controller
              control={control}
              name="service"
              rules={{ required: { value: true, message: "Service is required" } }}
              render={({ field, fieldState }) => (
                <Autocomplete
                  value={field.value}
                  onChange={(_, newValue) => field.onChange(newValue)}
                  options={assignableServices}
                  sx={{ width: 300 }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(renderProps, option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <li {...renderProps} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="Services"
                      placeholder="select a service"
                      variant="outlined"
                      error={Boolean(fieldState.error)}
                      helperText={fieldState.error?.message}
                      slotProps={{
                        inputLabel: { shrink: true }
                      }}
                    />
                  )}
                />
              )}
            />
            <Controller
              control={control}
              name="rate"
              rules={{
                required: { value: true, message: "Rate is required" },
                pattern: {
                  value: /^\d+(\.\d+)?$/,
                  message: "Please enter valid rate"
                }
              }}
              render={({ field, fieldState }) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...field}
                  variant="outlined"
                  label="Rate"
                  placeholder="Enter rate"
                  error={Boolean(fieldState.error)}
                  helperText={fieldState.error?.message}
                  slotProps={{
                    inputLabel: { shrink: true },
                    input: { rateAdornment }
                  }}
                  onFocus={(e) => e.target.select()}
                />
              )}
            />
            <ButtonGroup>
              <Button
                onClick={() => setServiceProviderRateUnit(SERVICE_PROVIDER_RATE_UNIT.RUPEE)}
                sx={
                  serviceProviderRateUnit === SERVICE_PROVIDER_RATE_UNIT.RUPEE
                    ? btnStyles.disabled
                    : btnStyles.active
                }
              >
                Rs.
              </Button>
              <Button
                onClick={() => setServiceProviderRateUnit(SERVICE_PROVIDER_RATE_UNIT.PERCENTAGE)}
                sx={
                  serviceProviderRateUnit === SERVICE_PROVIDER_RATE_UNIT.PERCENTAGE
                    ? btnStyles.disabled
                    : btnStyles.active
                }
              >
                %
              </Button>
            </ButtonGroup>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setOpenDialog(false);
              reset();
            }}
          >
            Cancel
          </Button>
          <StatefulButton
            onClick={handleSubmit(async (data) => {
              const rates = [...(data?.service?.document?.rates || [])];
              rates.push({
                serviceProviderId: selectedSP.id,
                rate: data.rate
              });
              const serviceData: Partial<ProductInterface> = {
                ...pickBy(data.service),
                serviceProviderRateUnit,
                document: {
                  ...data.service.document,
                  rates
                }
              };
              await dispatch(async (dispatchInner, getState) => {
                await dispatchInner(updateServices(serviceData));
                const currentState = getState();
                const errorObject = currentState.error.filter(
                  ({ key }) => key === "UPDATE_SERVICES"
                );
                if (errorObject.length) {
                  dispatchInner(
                    NotificationActions.notificationAdd({
                      id: new Date().getUTCMilliseconds(),
                      variant: "error",
                      message: errorObject[0].message || commonErrorMessage,
                      autoTimeout: true
                    })
                  );
                  dispatchInner(removeGeneralErrorByKey("UPDATE_SERVICES"));
                } else {
                  dispatchInner(
                    NotificationActions.notificationAdd({
                      id: new Date().getUTCMilliseconds(),
                      variant: "success",
                      message: tl("services.successUpdateProduct"),
                      autoTimeout: true
                    })
                  );
                  const selectedServices = selectedServicesId.map(
                    (item) =>
                      spSpecificServices.concat(assignableServices).filter((s) => s.id === item)[0]
                  );
                  let paymentAmount = 0;
                  const serviceIds: number[] = [
                    ...selectedServices,
                    currentState.services.lastTouched
                  ].map((service: ProductInterface) => {
                    if (Number(service.grossTotalPrice))
                      paymentAmount += Number(service.grossTotalPrice);
                    return service.id;
                  });
                  reset();
                  afterSpAssignSave({ serviceIds, paymentAmount });
                  setOpenDialog(false);
                }
              });
            })}
            variant="contained"
            circularProgressProps={{ size: 16 }}
            isLoading={isSubmitting}
            disabled={!isValid || isSubmitting}
          >
            Save
          </StatefulButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ServicesSelectWithSpAssign;
