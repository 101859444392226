import React, { useState } from "react";
import { MenuItem, TextField } from "@mui/material";
import startCase from "lodash/startCase";
import { SHOW_CLIENT_BY, ShowClientByType } from "../../containers/Reports/Filters";

interface Props {
  value: ShowClientByType;
  onChange: (v: string) => void;
}
const ShowClientByDateSelect = ({ value, onChange }: Props): JSX.Element => {
  const [visitType, setVisitType] = useState<ShowClientByType>(value);

  return (
    <TextField
      size="small"
      label="Show Client By"
      value={visitType}
      select
      fullWidth
      slotProps={{
        inputLabel: { shrink: true }
      }}
      onChange={(event) => {
        setVisitType(event.target.value as ShowClientByType);
        onChange(event.target.value);
      }}
    >
      {Object.values(SHOW_CLIENT_BY).map((item) => (
        <MenuItem value={item} key={item}>
          {startCase(item)}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default ShowClientByDateSelect;
