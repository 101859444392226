import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { Box, Typography, Autocomplete, TextField } from "@mui/material";
import produce from "immer";
import { FieldValues, useForm } from "react-hook-form";
import startCase from "lodash/startCase";
import { getAccount, updateUser, profilePicUpload } from "../../../actions/user";
import * as NotificationActions from "../../../actions/notification";
import { isEmail, isNepaliPhoneNumber } from "../../../helpers/validators";
import Collapse from "../../../components/CollapseBar/CollapseBar";
import ContentEditableWithTextField from "../../../components/ContentEditableWithTextField/ContentEditableWithTextField";
import { tl, t } from "../../../components/translate";
import Dialog from "../../../components/Dialog/Dialog";
import ImageCrop from "../../../components/ImageCrop/ImageCrop";
import AppSettings from "./AppSettings";
import ChangePassword from "./ChangePassword";
import Signature from "./Signature";
import Designation from "./Designation";
import { IThunkDispatch, RootState } from "../../../store";
import { User, UserCreds, userViewMode } from "../../../interfaces/UserContext";
import dummy from "../../../../assets/images/dummyProfile.png";
import styles from "./style.module.css";
import "./style.css";
import Theme from "../../ResourceCentre/OtherSettings/Theme";
import useCurrentResourceCentre from "../../../hooks/useCurrentResourceCentre";

interface AccountProps {
  user: User;
  userCreds: UserCreds;
  onInvalidEmailPhone: (invalidData) => Promise<void>;
  onProfilePictureChange: (userId, pic) => Promise<void>;
  onUserUpdate: (userObj) => Promise<void>;
}

export const Account: React.FC<AccountProps> = ({
  user,
  userCreds,
  onInvalidEmailPhone,
  onProfilePictureChange,
  onUserUpdate
}) => {
  const [id, setId] = useState(null);
  const [state, setState] = useState({
    email: null,
    phone: null
  });
  const [image, setImage] = useState(null);
  const [isPasswordOpen, setIsPasswordOpen] = useState(false);
  const resourceCentre = useCurrentResourceCentre();

  useEffect(() => {
    if (!user) return;
    setId(userCreds.id);
    setState({
      email: user.email,
      phone: user.phone
    });
    if (userCreds.profileImage) setImage(userCreds.profileImage.s3ResourceURL);
    else setImage(dummy);
  }, [user, userCreds]);

  const [isDialogOpen, setIsDialogOpen] = React.useState(false);

  const dialogToggle = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  function onSaveEmail(email: string) {
    if (isEmail({ msg: "" })(email)) {
      setState({ ...state, email });
      onUserUpdate({ email });
    } else {
      setState({ ...state, email });
      setState({ ...state, email: user.email });
      onInvalidEmailPhone("account.invalidEmailId");
    }
  }

  function onSavePhone(phone: string) {
    setState({ ...state, phone });
    if (isNepaliPhoneNumber({ msg: "" })(phone)) {
      onUserUpdate({ phone });
    } else {
      setState({ ...state, phone: user.phone });
      onInvalidEmailPhone("account.invalidPhoneNumber");
    }
  }

  const [isCropperOpen, setIsCropperOpen] = React.useState(false);

  const fileUploader = useRef("fileUploader");

  const handleProfilePicture = () => {
    fileUploader.current.click();
  };

  const [tempImg, setTempImg] = React.useState(null);

  function handleImageChange(draft) {
    draft.preventDefault();
    if (draft.target.files[0].size >= 2097152) {
      draft.target.value = "";
      dialogToggle();
      return;
    }
    const file = draft.target.files[0];
    const someVar = file.name.lastIndexOf(".") + 1;
    const someVar2 = file.name.substr(someVar, file.name.length).toLowerCase();
    if (someVar2 === "jpg" || someVar2 === "jpeg" || someVar2 === "png" || someVar2 === "gif") {
      setTempImg(URL.createObjectURL(file));
      setIsCropperOpen(true);
      draft.target.value = "";
    } else {
      draft.target.value = "";
      dialogToggle();
    }
  }

  const { setValue, getValues } = useForm<FieldValues>({
    defaultValues: {
      primaryColour:
        userCreds?.userPreferences?.lookAndFeel?.primaryColour ||
        resourceCentre?.settings?.lookAndFeel?.primaryColour ||
        "#009654"
    }
  });

  if (!user) return null;
  return (
    <Box margin="20px 0px">
      <Box marginBottom="32px">
        <Typography>
          <Box component="span" fontSize="20px" fontWeight={600} lineHeight="40px" margin="20px">
            {tl("account.profileSettings")}
          </Box>
        </Typography>
      </Box>
      <Collapse label={tl("account.myDetails")} collapsed={false}>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ width: "160px" }}> {tl("name")}</Typography>
          <Typography>{`${startCase(user.firstName || "")} ${startCase(
            user.lastName || ""
          )}`}</Typography>
        </Box>
        <Box pt={2}>
          <Box className={styles.profileImageEditable} width="100%" display="flex">
            <Box
              flexBasis="100px"
              fontWeight="600"
              display="flex"
              minWidth="160px"
              alignItems="center"
              justifyContent="flex-start"
            >
              <Typography pt={3}>{tl("account.profileImage")}</Typography>
            </Box>
            <Box className={styles.profileImageWrapper} display="flex">
              <img className={styles.profileImage} src={image} alt="profileimage" />
            </Box>
            <ImageCrop
              image={tempImg}
              isOpen={isCropperOpen}
              dynamicRatio={false}
              onClose={() => setIsCropperOpen(false)}
              onSave={(croppedImage) => {
                const formData = new FormData();
                formData.append("qqfile", croppedImage);
                onProfilePictureChange(id, formData);
              }}
            />
            <Box style={{ cursor: "pointer" }} pt={3}>
              <Typography>
                <Box
                  component="span"
                  fontSize="14px"
                  pl={1}
                  style={{ cursor: "pointer" }}
                  onClick={handleProfilePicture}
                >
                  {tl("account.edit")}
                </Box>
              </Typography>
              {isDialogOpen && (
                <Dialog
                  title="Cannot upload image"
                  description="File size limit is only 2MB or wrong file format"
                  next={dialogToggle}
                  readMode
                />
              )}

              <input
                type="file"
                ref={fileUploader}
                name="fileUploader"
                style={{ display: "none" }}
                onChange={handleImageChange}
                accept="image/gif, image/jpeg, image/png"
              />
            </Box>
          </Box>
        </Box>
        <Box pt={1}>
          <Box mt={2} height="30px">
            <ContentEditableWithTextField
              label={tl("email")}
              placeholder={t("settings.typeYourEmailHere")}
              saveOnFocusOut={false}
              value={state.email}
              onSave={(newVal) => onSaveEmail(newVal)}
              width="160px"
            />
          </Box>
          <Box mt={1} height="30px">
            <ContentEditableWithTextField
              label={tl("phone")}
              placeholder={t("settings.typeYourPhoneHere")}
              saveOnFocusOut={false}
              value={state.phone}
              onSave={(newVal) => onSavePhone(newVal)}
              width="160px"
            />
          </Box>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography sx={{ width: "160px" }}> {tl("employee.permissionGroup")}</Typography>
          <div>
            <Typography>{startCase(userCreds.userGroups[0])}</Typography>
            <Typography
              variant="body2"
              sx={{
                color: "#969696"
              }}
            >
              {userCreds.userGroupDescription}
            </Typography>
          </div>
        </Box>
      </Collapse>
      <Collapse label={tl("account.security")} collapsed>
        {!isPasswordOpen ? (
          <Typography>
            <Box
              component="span"
              onClick={() => setIsPasswordOpen(!isPasswordOpen)}
              style={{ cursor: "pointer" }}
              data-testmation="changePassword"
            >
              <u>{tl("account.changePassword")}</u>
            </Box>
          </Typography>
        ) : (
          <ChangePassword onCancel={() => setIsPasswordOpen(false)} />
        )}
      </Collapse>
      <Collapse label={tl("account.language")} collapsed>
        <AppSettings />
      </Collapse>

      <Collapse label={tl("account.signature")} collapsed>
        <Signature />
        <Designation />
      </Collapse>

      <Collapse label="User Preferences" collapsed>
        <Box
          sx={{
            display: "flex",
            alignItems: "center"
          }}
        >
          <Typography sx={{ width: "150px" }}>View mode</Typography>
          <Autocomplete
            sx={{ width: "200px" }}
            options={Object.values(userViewMode)}
            disableClearable
            value={userCreds.userPreferences.viewMode}
            onChange={(e, v) => {
              const updatedUserCreds = produce(userCreds, (draft) => {
                draft.userPreferences.viewMode = v || null;
              });
              onUserUpdate({ userPreferences: updatedUserCreds.userPreferences });
            }}
            // eslint-disable-next-line react/jsx-props-no-spreading
            renderInput={(params) => <TextField {...params} />}
          />
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "10px"
          }}
        >
          <Typography sx={{ width: "150px" }}>Theme Colour</Typography>
          <div>
            <Theme
              setValue={setValue}
              getValues={getValues}
              showDefaultLabel={false}
              onColorChange={(color) => {
                const updatedUserCreds = produce(userCreds, (draft) => {
                  draft.userPreferences.lookAndFeel = {
                    primaryColour: color || getValues("primaryColour")
                  };
                });
                onUserUpdate({ userPreferences: updatedUserCreds.userPreferences });
              }}
            />
          </div>
        </Box>
      </Collapse>
    </Box>
  );
};

export default connect(
  (state: RootState) => ({
    user: state.userContext.user,
    userCreds: state.userContext.userCreds,
    resourceCentre: state.userContext.resourceCentre
  }),
  (dispatch: IThunkDispatch) => ({
    getAccount: () => {
      dispatch(getAccount());
    },
    onProfilePictureChange: async (userId, pic) => {
      await dispatch(profilePicUpload(userId, pic));
    },
    onUserUpdate: async (userObj) => {
      await dispatch(updateUser(userObj));
    },
    onInvalidEmailPhone: async (invalidData) => {
      await dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: tl(invalidData),
          autoTimeout: true,
          timeout: 4000
        })
      );
    }
  })
)(Account);
