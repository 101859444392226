import React from "react";
import { Autocomplete, createFilterOptions, TextField } from "@mui/material";
import { PackageUnitInterface } from "./CreateStockProduct";

interface Props {
  value: PackageUnitInterface;
  options: PackageUnitInterface[];
  onChange: (value: PackageUnitInterface) => void;
  label: string;
  placeholder: string;
}

const UnitPackageCreateSelect = ({
  value,
  onChange,
  options,
  label,
  placeholder
}: Props): JSX.Element => (
  <Autocomplete
    fullWidth
    disableClearable
    value={value}
    options={options}
    autoHighlight
    filterOptions={(opt, params) => {
      const filtered: PackageUnitInterface[] = createFilterOptions<PackageUnitInterface>()(
        opt,
        params
      );
      const isExisting = opt.some((option) => params.inputValue === option.value);
      if (params.inputValue !== "" && !isExisting) {
        filtered.push({
          value: params.inputValue,
          label: params.inputValue,
          isCustomValue: true
        });
      }
      return filtered;
    }}
    renderOption={(props, option) => (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <li {...props} key={option.label}>
        {option.isCustomValue ? `Add "${option.label}"` : option.label}
      </li>
    )}
    onChange={(_, unit) => onChange(unit)}
    onFocus={(e) => e.target.select()}
    renderInput={(params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        margin="dense"
        slotProps={{
          inputLabel: { shrink: true }
        }}
        label={label}
        placeholder={placeholder}
        variant="outlined"
      />
    )}
  />
);

export default UnitPackageCreateSelect;
