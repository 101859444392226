/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { FunctionComponent } from "react";
import { useSelector } from "react-redux";
import { Typography, Box, Link, Modal } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import moment from "moment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import VisibilityIcon from "@mui/icons-material/Visibility";
import startCase from "lodash/startCase";
import styles from "./styles.module.scss";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import { employeeFullNameSelector } from "../../../reducers/employee";
import { spFullNameSelector } from "../../../reducers/serviceProvider";
import { tl, t } from "../../../components/translate";
import IntraoralAssessmentContent from "./IntraoralAssessmentContent";
import { countableTypes } from "../../Assessment/Medication/medicineType";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { FilePreview } from "../../../components/FilesInput/FilePreview";
import EyeAssessmentContent from "./EyeAssessmentContent";
import AdvancedIntraOralContent, { hasContentAdvancedIntraOral } from "./AdvancedIntraOralContent";
import ExtraOralContent from "./ExtraOralContent";
import { Assessment, AssessmentDocument, followup } from "../../../interfaces/AssessmentInterfaces";
import {
  getMedicineMultiplier,
  showFrequencyUnit,
  showMeal,
  showTimeUnit
} from "../../Assessment/Medication/Medication";
import { opdDurationFormatter } from "../../Reports/PatientAssessments";
import { clientFullNameSelector } from "../../../reducers/client";
import {
  EditableTableView,
  filterEmptyTableData
} from "../../../components/EditableTable/EditableTable";
import useAssessmentLabel from "../../../hooks/useAssessmentLabel";
import DiagnosisRenderer, { hasDiagnosis } from "./DiagnosisRenderer";
import { RootState } from "../../../store";
import { SORT_ORDER } from "../../Assessment/AssessmentForm/OPDComponents";
import ClientInfo from "../../Client/ClientInfo/ClientInfo";
import ClientCreateEdit from "../../Client/ClientCreateEdit";

interface AssessmentContentProps {
  data: Assessment;
  // eslint-disable-next-line react/require-default-props
  assessmentDoc?: AssessmentDocument;
}

interface dataToPrint {
  name1?: string;
  value1?: string;
  name2?: string;
  value2?: string;
  name3?: string;
  value3?: string;
}

export const formatAssessmentForPrint = (
  data: { name?: string; reading?: string; unit?: string }[]
): dataToPrint[] => {
  const dataToPrint: dataToPrint[] = [];
  for (let i = 0; i < data?.length; i += 3) {
    const dataRow = {
      name1: data[i].name,
      value1: `${data[i].reading} ${data[i].unit}`,
      name2: data[i + 1]?.name,
      value2: `${data[i + 1]?.reading} ${data[i + 1]?.unit}`,
      name3: data[i + 2]?.name,
      value3: `${data[i + 2]?.reading} ${data[i + 2]?.unit}`
    };
    dataToPrint.push(dataRow);
  }
  return dataToPrint;
};

export const calcInDays = (weeks: number): number => weeks * 7;

const useStyles = makeStyles((theme) => ({
  [theme.breakpoints.down("md")]: {
    root: {
      padding: "16px"
    }
  }
}));

export const getFollowupFormat = (data: followup): string => {
  const isPlural = data.days < 2 ? "" : "s";
  switch (data.format) {
    case "days":
      return `day${isPlural}.`;
    case "weeks":
      return `week${isPlural}.`;
    case "months":
      return `month${isPlural}.`;
    default:
      return `year${isPlural}.`;
  }
};

// Assessment Image and Product Medication
const AssessmentComponent = ({ componentName, data, assessmentLabels }): JSX.Element | null => {
  const vitalToPrint = formatAssessmentForPrint(data.vitals);
  const questionnaireToPrint = formatAssessmentForPrint(data.questionnaire);
  const servicesAndTests = [...(data?.tests || []), ...(data?.services || [])];
  const tabularComponentData = data.tabularComponent && filterEmptyTableData(data.tabularComponent);
  const [currentAttachment, setCurrentAttachment] = React.useState(null);
  const [openPreview, setOpenPreview] = React.useState(false);
  const productMedication = data.productMedication ? data.productMedication : [];
  const isMobileScreen = useMobileScreen();
  switch (componentName) {
    case SORT_ORDER.RECORD_PRESCRIPTION:
      return data.prescriptionAudioUrl ? (
        <Box pb={2}>
          <Typography sx={{ fontWeight: 600 }}>{assessmentLabels.recordPrescription}</Typography>
          {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
          <audio controls src={data.prescriptionAudioUrl} />
        </Box>
      ) : null;
    case SORT_ORDER.CHIEF_COMPLAINTS:
      return (
        <Box>
          {data.symptoms?.length > 0 ? (
            <>
              <Box pb={2}>
                <Typography>
                  <Box pt={3} fontWeight={600} component="span">
                    {assessmentLabels.chiefComplaints}
                  </Box>
                </Typography>
                <Typography>
                  <Box display="flex" component="span">
                    {data.symptoms.map((symptom) => symptom.symptom).join(", ")}
                  </Box>
                </Typography>
              </Box>
            </>
          ) : null}
        </Box>
      );
    case SORT_ORDER.PAST_HISTORY_OF_ALLERGY:
      return (
        <Box>
          {data.pastHistoryOfAllergy ? (
            <>
              <Box pb={2}>
                <Typography>
                  <Box pt={3} fontWeight={600} component="span">
                    {assessmentLabels.pastHistoryOfAllergy}
                  </Box>
                </Typography>
                <Typography>
                  <Box component="span">{data.pastHistoryOfAllergy}</Box>
                </Typography>
              </Box>
            </>
          ) : null}
        </Box>
      );
    case SORT_ORDER.HOPI:
      return data.hopi ? (
        <>
          <Box pb={2}>
            <Typography>
              <Box pt={3} fontWeight={600} component="span">
                {assessmentLabels.hopi}
              </Box>
            </Typography>
            <Typography>
              <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                {data.hopi}
              </Box>
            </Typography>
          </Box>
        </>
      ) : null;
    case SORT_ORDER.EXAMINATION_OR_STATUS:
      return (
        <>
          {data.assessment ? (
            <Box>
              <Box pb={2}>
                <Typography>
                  <Box pt={3} fontWeight={600} component="span">
                    {assessmentLabels.examination}
                  </Box>
                </Typography>

                <Typography>
                  <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                    {data.assessment}
                  </Box>
                </Typography>
              </Box>
            </Box>
          ) : null}
          <Box>
            {data.assessmentImage?.length > 0 && (
              <>
                <Box pb={2}>
                  <img
                    src={data.assessmentImage}
                    className={styles.assessmentImg}
                    alt="Assessment"
                  />
                </Box>
              </>
            )}
          </Box>
        </>
      );
    case SORT_ORDER.INVESTIGATION:
      return (
        <div>
          {data.investigation ? (
            <>
              <Box pb={2}>
                <Typography>
                  <Box pt={3} fontWeight={600} component="span">
                    {assessmentLabels.investigation}
                  </Box>
                </Typography>

                <Typography>
                  <Box component="span" whiteSpace="pre-line">
                    {data.investigation}
                  </Box>
                </Typography>
              </Box>
            </>
          ) : null}
        </div>
      );
    case SORT_ORDER.INTRA_ORAL:
      return (
        <Box>
          {data?.intraOral &&
            (Object.keys(data.intraOral.primary).length !== 0 ||
              Object.keys(data.intraOral.permanent).length !== 0) && (
              <>
                <Box pb={2}>
                  <Typography>
                    <Box pt={3} fontWeight={600} component="span">
                      {assessmentLabels.intraoral}
                    </Box>
                  </Typography>
                  <IntraoralAssessmentContent intraOralDoc={data.intraOral} />
                </Box>
              </>
            )}
        </Box>
      );
    case SORT_ORDER.EXTRA_ORAL:
      return Object.keys(data.extraOral || {}).some((key) => !!data.extraOral[key]) ? (
        <ExtraOralContent
          assessmentDocument={data}
          mode="view"
          componentLabel={assessmentLabels.extraOral}
        />
      ) : null;
    case SORT_ORDER.ADVANCED_INTRA_ORAL:
      return data.advancedIntraOral && hasContentAdvancedIntraOral(data.advancedIntraOral) ? (
        <AdvancedIntraOralContent
          assessmentDocument={data}
          mode="view"
          componentLabel={assessmentLabels.advancedIntraOral}
        />
      ) : null;
    case SORT_ORDER.EYE:
      return (
        <Box>
          {data?.eye && Object.keys(data?.eye).length > 0 && (
            <EyeAssessmentContent eyeExaminationDoc={data?.eye} />
          )}
        </Box>
      );
    case SORT_ORDER.QUESTIONNAIRE:
      return questionnaireToPrint?.length > 0 ? (
        <>
          <Typography fontWeight="bold" marginTop="10px">
            {assessmentLabels.questionnaire}:
          </Typography>
          <Box width="100%">
            {questionnaireToPrint.map((questionnaire, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Box key={index} display="flex" justifyContent="space-between" flexWrap="wrap">
                <Box display="flex" flexBasis="230px">
                  <Typography fontWeight="bold">{startCase(questionnaire.name1)}</Typography>
                  <Typography> : {questionnaire.value1}</Typography>
                </Box>
                <Box display="flex" flexBasis="230px">
                  <Typography fontWeight="bold">{startCase(questionnaire.name2)}</Typography>
                  {questionnaire.name2 && <Typography> : {questionnaire.value2}</Typography>}
                </Box>
                <Box display="flex" flexBasis="230px">
                  <Typography fontWeight="bold">{startCase(questionnaire.name3)}</Typography>
                  {questionnaire.name3 && <Typography> : {questionnaire.value3}</Typography>}
                </Box>
              </Box>
            ))}
          </Box>
        </>
      ) : null;
    case SORT_ORDER.VITALS:
      return vitalToPrint?.length > 0 ? (
        <>
          <Typography fontWeight="bold" marginTop="10px">
            {assessmentLabels.vitals}:
          </Typography>
          {vitalToPrint.map((vital, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={index} display="flex" justifyContent="space-between">
              <Box display="flex" width="33%">
                <Typography fontWeight="bold">{startCase(vital.name1)}</Typography>
                <Typography> : {vital.value1}</Typography>
              </Box>
              <Box display="flex" width="33%">
                <Typography fontWeight="bold">{startCase(vital.name2)}</Typography>
                {vital.name2 && <Typography> : {vital.value2}</Typography>}
              </Box>
              <Box display="flex" width="33%">
                <Typography fontWeight="bold">{startCase(vital.name3)}</Typography>
                {vital.name3 && <Typography> : {vital.value3}</Typography>}
              </Box>
            </Box>
          ))}
        </>
      ) : null;
    case SORT_ORDER.PROVISIONAL_DIAGNOSIS:
      return (
        <Box>
          {data.provisionalDiagnosis && (
            <>
              <Box pb={2}>
                <Typography>
                  <Box pt={3} fontWeight={600} component="span">
                    {assessmentLabels.provisionalDiagnosis}
                  </Box>
                </Typography>
                <Typography>
                  <Box component="span">{data.provisionalDiagnosis}</Box>
                </Typography>
              </Box>
            </>
          )}
        </Box>
      );
    case SORT_ORDER.LAB_TEST:
      return (
        <Box>
          {servicesAndTests?.length > 0 && (
            <>
              <Box pb={2}>
                <Typography>
                  <Box pt={3} fontWeight={600} component="span">
                    {assessmentLabels.labTest}
                  </Box>
                </Typography>
                <ol style={{ paddingLeft: "1.5rem", margin: 0 }}>
                  {servicesAndTests.map(({ code, name, id }) => (
                    <li key={code || id}>
                      <Typography>{name}</Typography>
                    </li>
                  ))}
                </ol>
              </Box>
            </>
          )}
        </Box>
      );
    case SORT_ORDER.DIAGNOSIS:
      return hasDiagnosis(data.diagnosis) ? (
        <Box pb={2}>
          <Typography pt={3} fontWeight={600} component="span">
            {assessmentLabels.diagnosis}
          </Typography>
          <DiagnosisRenderer data={data.diagnosis} />
        </Box>
      ) : null;
    case SORT_ORDER.TABULAR:
      return tabularComponentData ? <EditableTableView tableState={tabularComponentData} /> : null;
    case SORT_ORDER.PRESENT_HEALTH_STATUS:
      return (
        <Box>
          {data.presentHealthStatus && (
            <Box display="flex" mb={2}>
              <Typography>
                <Box pt={3} fontWeight={600} component="span">
                  {assessmentLabels.presentHealthStatus}
                </Box>
              </Typography>
              <Typography>
                <Box component="span" pl={1}>
                  {data.presentHealthStatus}
                </Box>
              </Typography>
            </Box>
          )}
        </Box>
      );
    case SORT_ORDER.ATTACHMENTS:
      return (
        <Box>
          {data.attachments?.length > 0 && (
            <Box pb={2}>
              <Typography>
                <Box pt={3} pb="5px" fontWeight={600} component="span">
                  {assessmentLabels.attachments}
                </Box>
              </Typography>

              <Modal open={openPreview}>
                <FilePreview
                  onClose={() => {
                    setOpenPreview(false);
                  }}
                  fileDetails={currentAttachment}
                />
              </Modal>

              <Box>
                <ol style={{ paddingLeft: "1.5rem", margin: 0 }}>
                  {Array.isArray(data.attachments) &&
                    data.attachments.map((attachment) => (
                      <Box
                        display="flex"
                        style={{ textAlign: "center", alignItems: "center" }}
                        key={attachment.fileName}
                      >
                        <img
                          style={{
                            width: "80px",
                            height: "100px",
                            objectFit: "cover",
                            margin: "40px",
                            boxShadow: "1px 1px 2px 1px gray"
                          }}
                          src={attachment.downloadLink}
                          alt={attachment.fileName}
                          onClick={() => {
                            setOpenPreview(true);
                            setCurrentAttachment({
                              name: attachment.fileName,
                              url: attachment.downloadLink
                            });
                          }}
                          onKeyDown={null}
                        />
                        <Link
                          key={attachment.fileName}
                          href={attachment.downloadLink}
                          target="_blank"
                        >
                          <li>
                            <Box display="flex">
                              <Box>
                                <Typography>{attachment.fileName}</Typography>
                              </Box>
                              <Box>
                                <AttachFileIcon />
                              </Box>
                            </Box>
                          </li>
                        </Link>
                        <VisibilityIcon
                          onClick={() => {
                            setOpenPreview(true);
                            setCurrentAttachment({
                              name: attachment.fileName,
                              url: attachment.downloadLink
                            });
                          }}
                        />
                      </Box>
                    ))}
                </ol>
              </Box>
            </Box>
          )}
        </Box>
      );
    case SORT_ORDER.TREATMENT_PLAN:
      return (
        <Box>
          {data?.treatmentPlan && (
            <>
              <Box pb={2}>
                <Typography>
                  <Box pt={3} fontWeight={600} component="span">
                    {assessmentLabels.treatmentPlan}
                  </Box>
                </Typography>
                <Typography>
                  <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                    {data.treatmentPlan}
                  </Box>
                </Typography>
              </Box>
            </>
          )}
        </Box>
      );
    case SORT_ORDER.TREATMENT_GIVEN:
      return (
        <Box>
          {data?.treatmentGiven && (
            <>
              <Box pb={2}>
                <Typography>
                  <Box pt={3} fontWeight={600} component="span">
                    {assessmentLabels.treatmentGiven}
                  </Box>
                </Typography>
                <Typography>
                  <Box component="span" sx={{ whiteSpace: "pre-wrap" }}>
                    {data.treatmentGiven}
                  </Box>
                </Typography>
              </Box>
            </>
          )}
        </Box>
      );
    case SORT_ORDER.MEDICATION:
      return (
        <Box>
          {(data.medication?.length > 0 || data.productMedication?.length > 0) && (
            <>
              <Box pb={2}>
                <Typography>
                  <Box pt={3} fontWeight={600} component="span">
                    {tl("assessment.medicationAndSupplements")}
                  </Box>
                </Typography>

                <Box>
                  <ol style={{ paddingLeft: "15px", margin: 0 }}>
                    {[...data.medication, ...productMedication]?.map(
                      ({
                        id,
                        duration,
                        form,
                        meal,
                        brand,
                        genericName,
                        remark,
                        frequency,
                        frequencyType,
                        strength,
                        times
                      }) => (
                        <li key={id} style={{ paddingTop: "5px" }}>
                          <Typography>
                            <Box component="span">
                              {" "}
                              {`${genericName || ""}${
                                genericName && brand && "/"
                              } ${brand}${" "}${strength}${" "} ${form ? `(${form})` : ""} `}{" "}
                            </Box>
                          </Typography>

                          <Box display={`${isMobileScreen ? "block" : "flex"}`} fontWeight={450}>
                            <Typography>
                              <Box component="span">
                                {frequency === 0 ? "" : `${frequency} ${t("assessment.times")}`}
                                {frequency !== 0 && showTimeUnit(times)}
                                {frequency !== 0 && <br />}
                                {showMeal(meal)}
                              </Box>
                            </Typography>

                            <Typography>
                              {frequency !== 0 && (
                                <Box pl={`${isMobileScreen ? "0px" : "32px"}`} component="span">
                                  {`${
                                    countableTypes.includes(form)
                                      ? frequency *
                                        getMedicineMultiplier(times, duration, frequencyType)
                                      : ""
                                  } ${form} (${duration} ${showFrequencyUnit(
                                    frequencyType,
                                    duration
                                  )})`}
                                </Box>
                              )}
                            </Typography>
                          </Box>

                          <Typography>
                            <Box fontWeight={450} component="span" fontSize="0.8rem">
                              {remark && (
                                <Box component="span">{`${t(
                                  "assessment.medication.remark"
                                )} : ${remark}`}</Box>
                              )}
                            </Box>
                          </Typography>
                        </li>
                      )
                    )}
                  </ol>
                </Box>
              </Box>
            </>
          )}
        </Box>
      );
    // case SORT_ORDER.PRODUCT_MEDICATION:
    case SORT_ORDER.FOLLOWUP:
      return (
        <Box>
          {data.followup && (
            <>
              <Box pb={2}>
                <Typography>
                  <Box pt={3} fontWeight={600} component="span">
                    {assessmentLabels.followup}{" "}
                  </Box>
                </Typography>

                <Typography>
                  <Box component="span">{`Follow up in ${data.followup.days} ${getFollowupFormat(
                    data.followup
                  )} ${data.followup.note}`}</Box>
                </Typography>
              </Box>
            </>
          )}
        </Box>
      );
    case SORT_ORDER.ADVICE:
      return (
        <Box>
          {data.advice && (
            <>
              <Box pb={2}>
                <Typography>
                  <Box pt={3} fontWeight={600} component="span">
                    {assessmentLabels.advice}
                  </Box>
                </Typography>

                <Typography>
                  <Box component="span" whiteSpace="pre-line">
                    {data.advice}
                  </Box>
                </Typography>
              </Box>
            </>
          )}
        </Box>
      );
    case SORT_ORDER.OTHERS:
      return (
        <Box>
          {data?.others && (
            <>
              <Box pb={2}>
                <Typography>
                  <Box pt={3} fontWeight={600} component="span">
                    {assessmentLabels.others}
                  </Box>
                </Typography>

                <Typography>
                  <Box component="span">{data.others}</Box>
                </Typography>
              </Box>
            </>
          )}
        </Box>
      );
    default:
      return null;
  }
};

const InfoRow = ({
  label,
  value
}: {
  label: React.ReactNode;
  value: React.ReactNode;
}): JSX.Element => (
  <Box display="flex">
    <Typography style={{ fontWeight: 600, width: "140px" }}>{label}</Typography>
    <Typography>{value}</Typography>
  </Box>
);

const AssessmentContent: FunctionComponent<AssessmentContentProps> = ({ data, assessmentDoc }) => {
  const [showClientInfo, setShowClientInfo] = React.useState(false);
  const [clientEditMode, setClientEditMode] = React.useState(false);
  const assessmentDocument = assessmentDoc || data.assessmentDocument;
  const classes = useStyles();
  const assessmentLabels = useAssessmentLabel();
  const sortOrder = useSelector(
    (state: RootState) => state.userContext.resourceCentre?.settings?.assessmentSettings?.sortOrder
  );
  return (
    <Box className={classes.root}>
      <InfoRow
        label={tl("assessment.Dated")}
        value={convertADtoBS(moment(data.createdDate) || moment()).formatted2}
      />
      <Box mb={2}>
        <InfoRow
          label={tl("assessment.assessedBy")}
          value={spFullNameSelector(assessmentDocument.serviceProvider)}
        />

        <Box display="flex">
          <Typography sx={{ fontWeight: 600, width: "140px" }}>
            {tl("assessment.clientName")}
          </Typography>
          <Box
            onClick={() => setShowClientInfo(true)}
            role="button"
            tabIndex={0}
            component={Typography}
            sx={{ cursor: "pointer", textDecoration: "underline" }}
          >
            {clientFullNameSelector(assessmentDocument.client).toUpperCase()}
          </Box>
        </Box>
        {data.claimCode && <InfoRow label="Claim Code" value={data.claimCode} />}

        {data?.referToSpId && data?.referToSp && (
          <InfoRow
            label={tl("assessment.referToSp")}
            value={spFullNameSelector(data.referToSp).toUpperCase()}
          />
        )}

        {data.assessmentDocument.preparedBy && (
          <InfoRow
            label={tl("assessment.preparedBy")}
            value={employeeFullNameSelector(assessmentDocument.preparedBy)}
          />
        )}
        {data.assessmentDocument.opdDuration && (
          <InfoRow
            label={assessmentLabels.opdDuration}
            value={opdDurationFormatter(assessmentDocument.opdDuration)}
          />
        )}
      </Box>
      <hr style={{ marginBottom: "15px" }} />
      {sortOrder?.map((component) => (
        <AssessmentComponent
          key={component}
          componentName={component}
          data={{
            ...assessmentDocument,
            attachments: data.attachments
          }}
          assessmentLabels={assessmentLabels}
        />
      ))}
      {showClientInfo && (
        <ClientInfo
          wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
          id={data.clientId}
          handleViewClose={() => setShowClientInfo(false)}
          setEditMode={setClientEditMode}
          stayOnCurrentPage
        />
      )}

      {clientEditMode && (
        <ClientCreateEdit
          setEditMode={setClientEditMode}
          clientId={data.clientId}
          mode="edit"
          onCancel={() => {
            setClientEditMode(false);
          }}
          stayOnCurrentPage
        />
      )}
    </Box>
  );
};

export default AssessmentContent;
