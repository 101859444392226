import { push } from "connected-react-router";
import { has } from "lodash";
import produce from "immer";
import cloneDeep from "lodash/cloneDeep";
import * as moment from "moment";
import actionCreatorFactory from "typescript-fsa";
import * as api from "../api/bill";
import { tl } from "../components/translate";
import { paymentOptionsEnum } from "../containers/Billing/Editor/BillSummary";
import hasOwnProperty from "../helpers/object";
import {
  BillDocumentSettingsType,
  BillDocumentType,
  BillItemFieldTypes,
  BillItemType,
  BillStatus,
  BillType,
  DiscountBasedOn,
  DiscountTypes,
  DocumentTypes,
  Receipt
} from "../interfaces/BillInterfaces";
import { Client } from "../interfaces/ClientInterface";
import { ReferrerProductServiceEntities } from "../interfaces/ReferrerInterface";
import { AppThunk, IThunkDispatch, RootState } from "../store";
import { actionCreatorAsync } from "./actionHelpers";
import {
  calculateCreditNoteBillItems,
  calculateCreditNoteSummary,
  createTemplateDraft,
  defaultDiscountSettings,
  getBillNumberType,
  isOldFormatBill,
  mergeBatchInfo,
  reformDraftData,
  splitBatchInfo
} from "./helpers/billHelper";
import * as NotificationActions from "./notification";
import { notificationAdd } from "./notification";
import { Bed } from "../api/wards";
import { updateErrorMessage, MODULE } from "../helpers/messages";

const actionCreator = actionCreatorFactory();

export enum Type {
  INIT_DRAFT = "INIT_DRAFT",
  ADD_NEW_ROW = "ADD_NEW_ROW",
  REMOVE_ROW = "REMOVE_ROW",
  UPDATE_DRAFT = "UPDATE_DRAFT",
  FETCH_BILLS = "FETCH_BILLS",
  UPDATE_DRAFT_SETTINGS = "UPDATE_DRAFT_SETTINGS",
  CLEAR_DRAFT = "CLEAR_DRAFT",
  GET_BILL = "GET_BILL",
  UPDATE_BILL = "UPDATE_BILL",
  UPDATE_BILLS = "UPDATE_BILLS",
  REMOVE_BILL = "REMOVE_BILL",
  FINALISE_BILL = "FINALISE_BILL",
  UPDATE_REFERRER = "UPDATE_REFERRER",
  UPDATE_BILL_ON_CLIENT_EDIT = "UPDATE_BILL_ON_CLIENT_EDIT",
  UPDATE_PRINT_COUNT = "UPDATE_PRINT_COUNT",
  RECEIPT_CANCEL = "RECEIPT_CANCEL",
  BILL_CANCELLATION = "BILL_CANCELLATION",
  BARCODE_SCANNER_DETECTED = "BARCODE_SCANNER_DETECTED"
}

export const initDraftAction = actionCreator(Type.INIT_DRAFT);
export const clearDraft = actionCreator(Type.CLEAR_DRAFT);
export const addNewRow = actionCreator(Type.ADD_NEW_ROW);
export const removeRow = actionCreator(Type.REMOVE_ROW);
export const updateDraftAction = actionCreator(Type.UPDATE_DRAFT);
export const updateDraftSettings = actionCreator(Type.UPDATE_DRAFT_SETTINGS);
export const removeBill = actionCreator(Type.REMOVE_BILL);

// reform draft data before saving
const reformDraftBeforeSaving = (billDraft) => {
  const reformedDraft = produce(billDraft, (draft) => {
    reformDraftData(draft);
    draft.billItems = splitBatchInfo(draft.billItems);
    draft.counterName = localStorage.getItem("billCounter");
    draft.summary.roundOffAmt = Number(draft.summary.roundOffAmt || 0);
  });

  return reformedDraft;
};

export const updateReferrerAction =
  (updatedDraft: BillDocumentType) =>
  (dispatch: IThunkDispatch, getState: () => RootState): void => {
    const { referrers } = getState().referrers;

    const relatedReferrer = referrers.find((referrer) => referrer.id === updatedDraft.referrerId);

    const updatedPropDraft = produce(updatedDraft, (draft) => {
      draft.billItems.forEach((draftItem) => {
        if (draftItem.batchInfo.batchId) {
          const foundReferrerProductService = relatedReferrer?.productService.find(
            (item) =>
              item.entityType === ReferrerProductServiceEntities.products &&
              item.entityId === draftItem.productId
          );
          if (foundReferrerProductService) {
            draftItem.perUnit = foundReferrerProductService.price;
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "warning",
                message: "Bill item price has been updated with referrer specific price!",
                autoTimeout: false
              })
            );
          }
        } else {
          const foundReferrerProductService = relatedReferrer?.productService.find(
            (item) =>
              item.entityType === ReferrerProductServiceEntities.services &&
              item.entityId === draftItem.productId
          );
          if (foundReferrerProductService) {
            draftItem.perUnit = foundReferrerProductService.price;
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "warning",
                message: "Bill item price has been updated with referrer specific price!",
                autoTimeout: false
              })
            );
          }
        }
      });
    });

    dispatch(updateDraftAction(updatedPropDraft));
  };

export const getNextBillNumber =
  (type: string, date?: string) =>
  async (dispatch: IThunkDispatch, getState: () => RootState): Promise<void> => {
    try {
      const nextNumber = (await api.getNextBillNumber(
        type,
        ["internalBilling", "billing"].includes(type) ? null : date
      )) as { nextNumber: string };
      const { draft } = getState().bills;
      if (draft) {
        const updatedDraft = produce(draft, (draftItem) => {
          draftItem.billNumber = String(nextNumber.nextNumber);
        });
        dispatch(updateDraftAction(updatedDraft));
      }
    } catch (e) {
      const { draft } = getState().bills;
      if (draft) {
        const updatedDraft = produce(draft, (draftItem) => {
          draftItem.billNumber = null;
        });
        dispatch(updateDraftAction(updatedDraft));
      }
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: e.message,
          autoTimeout: true
        })
      );
    }
  };

const updateBalance = (billState, clientLedger) => {
  const paymentInfo = {} as {
    paymentMethod: paymentOptionsEnum;
    paymentDistribution: Record<string, unknown>;
    paid: boolean;
    paidAmount: number;
  };
  return produce(billState, (draft) => {
    draft.client.balance = Number(clientLedger.balance) > 0 ? Number(clientLedger.balance) : 0;
    if (draft.client.balance > 0 && (billState.summary.totalBalance || 0) <= draft.client.balance) {
      // if balance is enough to cover totalAmount of the bill, set it by default
      paymentInfo.paymentMethod = paymentOptionsEnum.balance;
      paymentInfo.paymentDistribution = {
        paymentFromBalance: billState.summary.totalBalance,
        additionalPayment: 0
      };
      paymentInfo.paid = true;
      paymentInfo.paidAmount = billState.summary.totalBalance || 0;
      draft.paymentInfo = paymentInfo;
    }
  });
};

export const getClientBalance =
  (clientId: number) =>
  async (dispatch: IThunkDispatch, getState: () => RootState): Promise<void> => {
    const clientLedger = await api.getClientBalance(clientId);
    const { draft } = getState().bills;
    if (draft) {
      const updatedDraft = updateBalance(draft, clientLedger);
      dispatch(updateDraftAction(updatedDraft));
    }
  };

export const initDraft =
  ({
    clientId,
    visitType,
    bedId,
    bed,
    ipdId,
    isSsfBilling,
    isProformaBill = false
  }: {
    clientId: number;
    visitType: string;
    bedId: number;
    bed: Bed;
    ipdId: number;
    isSsfBilling?: boolean;
    isProformaBill?: boolean;
  }) =>
  async (dispatch: IThunkDispatch, getState: () => RootState): Promise<void> => {
    const { userContext, clients, resources } = getState();
    const resourceCentre = resources.resourceCentres.find(
      (rc) => rc.id === userContext.resourceCentreId
    );

    const client = clients.collection?.find(({ id }) => id === clientId);
    await dispatch(
      initDraftAction({
        ...createTemplateDraft(
          userContext,
          client,
          resourceCentre?.settings,
          isProformaBill,
          isSsfBilling
        ),
        visitType,
        ...(bedId && { bedId: +bedId, bed, ipdId })
      })
    );
    if (client?.id) await dispatch(getClientBalance(client.id));
  };

export const loadBill =
  (billId: number, billData?: BillType, isForKitchenPharmacy: boolean = false) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.GET_BILL, async () => {
        let bill = billData;
        if (!bill) {
          bill = (await api.getBill(billId, isForKitchenPharmacy)) as BillType;
        }
        bill.document.paymentInfo = bill.paymentInfo || {
          paid: true,
          paidAmount: null
        };
        return bill;
      })
    );
  };

const makeBillSettingsBackwardCompatible = (settingsParam: BillDocumentSettingsType) => {
  const settings = cloneDeep(settingsParam);
  if (hasOwnProperty(settings, "vatBill")) {
    // eslint-disable-next-line no-param-reassign
    delete settings.vatBill;
    if (hasOwnProperty(settings, "vatPct")) {
      // eslint-disable-next-line no-param-reassign
      delete settings.vatPct;
    }
  }
  if (
    hasOwnProperty(settings, "discountType") &&
    settings.discountType &&
    !settings.discountSettings
  ) {
    settings.discountSettings = {
      ...defaultDiscountSettings,
      discountType: settings.discountType as DiscountTypes
    };
    settings.showFields = [
      BillItemFieldTypes.delivered,
      BillItemFieldTypes.description,
      BillItemFieldTypes.quantity,
      BillItemFieldTypes.unit,
      BillItemFieldTypes.perUnit,
      BillItemFieldTypes.discountPercent,
      BillItemFieldTypes.discountAmt,
      BillItemFieldTypes.VATPercent,
      BillItemFieldTypes.grossTotal
    ].filter((val) => val);
    delete settings.discountType;
  }
  return settings;
};

export const loadBillForEdit =
  (billId: number) =>
  async (dispatch: IThunkDispatch, getState: () => RootState): Promise<void> => {
    dispatch(clearDraft());
    await dispatch(loadBill(billId));
    const { bills } = getState();
    const bill = bills.collection.find(({ id }) => id === Number(billId));
    const clientLedger = await api.getClientBalance(bill.client.id);
    const updatedBill = produce(bill.document, (draft) => {
      if (isOldFormatBill(draft.version)) {
        draft.settings = makeBillSettingsBackwardCompatible(draft.settings);
      }
      // Make backward compatible as concept of extraColumnSettings was brought later
      // All columns were shown before
      if (!draft.settings.extraColumnSettings) {
        draft.settings.extraColumnSettings = {
          vat: true,
          delivered: true,
          department: true,
          quantity: true,
          unit: true
        };
      }

      // quantity and unit were not saved in settings before.
      if (draft.settings.extraColumnSettings) {
        if (!has(draft.settings.extraColumnSettings, "quantity")) {
          draft.settings.extraColumnSettings = {
            ...draft.settings.extraColumnSettings,
            quantity: true
          };
        }
        if (!has(draft.settings.extraColumnSettings, "unit")) {
          draft.settings.extraColumnSettings = {
            ...draft.settings.extraColumnSettings,
            unit: true
          };
        }
      }
      draft.id = bill.id;
      draft.issueDate = new Date().toISOString();

      if (draft.paymentInfo?.paymentMethod === "noPayment") {
        draft.paymentInfo.paymentMethod = "cash";
      }
    });
    await dispatch(initDraftAction(updateBalance(updatedBill, clientLedger)));
    if (bill.isDraft) {
      const billNumberType = getBillNumberType(bill);
      dispatch(getNextBillNumber(billNumberType, bill.issueDate));
    }
  };

export const copyAsDraft =
  (bill: BillType): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    const sourceBill = (await api.getBill(bill.id)) as BillType;
    const clientLedger = await api.getClientBalance(sourceBill.client.id);

    const updatedBill = produce(sourceBill.document, (draft) => {
      if (isOldFormatBill(draft.version)) {
        draft.settings = makeBillSettingsBackwardCompatible(draft.settings);
      }

      // Make backward compatible as concept of extraColumnSettings was brought later
      // All columns were shown before
      if (!draft.settings.extraColumnSettings) {
        draft.settings.extraColumnSettings = {
          vat: true,
          delivered: true,
          department: true
        };
      }
      if (!draft.paymentInfo.paymentDistribution) {
        draft.paymentInfo.paymentDistribution = {
          paymentFromBalance: 0,
          additionalPayment: draft.paymentInfo.paidAmount
        };
      }

      if (draft.paymentInfo?.paymentMethod === "noPayment") {
        draft.paymentInfo.paymentMethod = "cash";
      }
      if (draft.paymentInfo.tenderAmount) {
        draft.paymentInfo.tenderAmount = 0;
        draft.paymentInfo.changeAmount = 0;
      }
      delete draft.id;
      draft.isDraft = true;
      draft.title = "bill";
      draft.issueDate = moment().toISOString();
      draft.billItems = mergeBatchInfo(draft.billItems) as Array<BillItemType>;
    });

    dispatch(initDraftAction(updateBalance(updatedBill, clientLedger)));
    const billNumberType = getBillNumberType(document);
    dispatch(getNextBillNumber(billNumberType, document.issueDate));
  };

export const createBillFromPurchaseRequisitionForm =
  (trxId: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    localStorage.removeItem("billDraft");
    await dispatch(clearDraft());

    const bill = await api.getBillFromPurchaseTransaction(trxId);
    const updatedBill = produce(bill.document, (draft) => {
      if (isOldFormatBill(draft.version)) {
        draft.settings = makeBillSettingsBackwardCompatible(draft.settings);
      }
      // Make backward compatible as concept of extraColumnSettings was brought later
      // All columns were shown before
      if (!draft.settings.extraColumnSettings) {
        draft.settings.extraColumnSettings = {
          vat: true,
          delivered: true,
          department: true
        };
      }
      draft.id = bill.id;
      draft.issueDate = new Date().toISOString();

      if (draft.paymentInfo?.paymentMethod === paymentOptionsEnum.noPayment) {
        draft.paymentInfo.paymentMethod = paymentOptionsEnum.cash;
      }
    });
    await dispatch(updateDraftAction(updatedBill));
  };

export const copyAsCredit =
  (bill: BillType) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    const sourceBill = (await api.getBill(bill.id)) as BillType;
    const billItems = cloneDeep(sourceBill.document.billItems);
    sourceBill.document.billItems = mergeBatchInfo(billItems) as Array<BillItemType>;
    let document = cloneDeep(sourceBill.document);
    document.settings.discountSettings = {
      discountBasis: DiscountBasedOn.inline,
      discountType: DiscountTypes.amount
    };
    /**
     * @todo handle advance payment while making credit note
     * i.e. on creation of credit note, let user choose whether to use the amount to refund or
     * keep it as advance payment
     * temporarily it is forced to refund the amount (because of some bug due to change in
     *  payment method, haven't looked into it yet though)
     * Work Around - finalize credit note and make another record payment to keep the
     * amount as advance
     */
    if (document.paymentInfo.paymentMethod === paymentOptionsEnum.balance) {
      document.paymentInfo.paymentMethod = paymentOptionsEnum.cash;
    }
    if (document.paymentInfo?.paymentDistribution) delete document.paymentInfo.paymentDistribution;
    delete document.id;
    document.billItems = document.billItems.map((draftItem) => {
      draftItem.returned = draftItem.delivered;
      draftItem.quantity = -draftItem.quantity;
      delete draftItem.delivered;
      return draftItem;
    });

    document = await calculateCreditNoteBillItems(document, sourceBill);
    document = await calculateCreditNoteSummary(document, sourceBill);
    document.paymentInfo.paidAmount = -sourceBill.paymentInfo.paidAmount;
    if (document.paymentInfo.tenderAmount) {
      delete document.paymentInfo.tenderAmount;
      delete document.paymentInfo.changeAmount;
    }
    document.isDraft = true;
    document.title = "bill";
    document.remarks = "";
    document.type = DocumentTypes.CREDITNOTE;
    document.referenceTo = sourceBill.id;
    document.referencedBillNum = sourceBill.billNumber;
    document.issueDate = moment().toISOString();
    dispatch(initDraftAction(document));
    const billNumberType = getBillNumberType(document);
    dispatch(getNextBillNumber(billNumberType, document.issueDate));
  };

const saveDraft = async ({ bills: billsParam }) => {
  if (billsParam.draft) {
    const bills = cloneDeep(billsParam);
    bills.draft = reformDraftData(bills.draft);
    bills.draft.billItems = splitBatchInfo(bills.draft.billItems);
    bills.draft.counterName = localStorage.getItem("billCounter");
    bills.draft.summary.roundOffAmt = Number(bills.draft.summary.roundOffAmt || 0);
    if (bills.draft?.id) {
      return api.putDraft(bills.draft.id, bills.draft);
    }
    return api.postDraft(bills.draft);
  }
  return null;
};

const getDraft = (billDraft): BillDocumentType =>
  produce(billDraft as BillDocumentType, (draft) => {
    // eslint-disable-next-line no-param-reassign
    draft = reformDraftData(draft);
    draft.billItems = splitBatchInfo(draft.billItems);
    draft.summary.roundOffAmt = +draft.summary.roundOffAmt;
  });

export const saveAsDraft =
  () =>
  async (dispatch: IThunkDispatch, getState: () => RootState): Promise<unknown> => {
    const draft = await saveDraft(getState());
    dispatch(clearDraft());
    return draft;
  };

export const finaliseDraft =
  (status: string, openPrintWindow: boolean) =>
  async (dispatch: IThunkDispatch, getState: () => RootState): Promise<void> => {
    let draft;
    try {
      draft = (await saveDraft(getState())) as { id: number };
    } catch (error) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: error?.data?.message || updateErrorMessage(MODULE.BILL),
          autoTimeout: false
        })
      );
    }
    if (!draft) return;
    await dispatch(
      actionCreatorAsync(Type.FINALISE_BILL, async () => {
        try {
          const result = await api.finaliseBill(draft.id);
          if (status !== BillStatus.cancelled) {
            if (result === "OK") dispatch(push(`/billing/bills/${draft.id}`, { openPrintWindow }));
            dispatch(clearDraft());
            dispatch(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "success",
                message: "Successfully finalized bill!",
                autoTimeout: true
              })
            );
          }
          return result;
        } catch (e) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message:
                e?.data?.message || `Error occured while finalizing the bill! \n ${e.message}`,
              autoTimeout: false
            })
          );
          return undefined;
        }
      })
    );
  };

export const updatePaymentInfo =
  (billId: number, paymentInfo: Record<string, unknown>) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.UPDATE_BILL, async () => {
        await api.updatePaymentInfo(billId, paymentInfo);
        return api.getBill(billId);
      })
    );
  };

export const recordPayment =
  (trxData: Record<string, unknown>) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.UPDATE_BILLS, async () => {
        try {
          const response = await api.recordPayment(trxData);
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: tl("billing.recordPayment.successSaving"),
              autoTimeout: true
            })
          );
          return response;
        } catch (error) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: error?.data?.message || "Sorry! Something went wrong.",
              autoTimeout: true
            })
          );
          return null;
        }
      })
    );
  };

export const fetchBills =
  (query?: Record<string, string>) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.FETCH_BILLS, async () => {
        if (query) return api.getBills(query);
        return api.getBills();
      })
    );
  };

export const billCancel =
  (cancelledBill: BillType) =>
  (dispatch: IThunkDispatch): void => {
    dispatch(actionCreatorAsync(Type.BILL_CANCELLATION, async () => cancelledBill));
  };

export const cancelBill =
  (id: number, remarks: string) =>
  async (dispatch: IThunkDispatch, getState: () => RootState): Promise<void> => {
    try {
      await dispatch(copyAsCredit({ id } as BillType));
      const draft = await getDraft(getState().bills.draft);
      if (draft.billItems.length === 0) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Sorry! Bill Items cannot be empty.",
            autoTimeout: true
          })
        );
        dispatch(clearDraft());
        return;
      }
      const cancelledBill = await api.cancelBill(id, { ...draft, remarks } as BillDocumentType);
      await dispatch(
        actionCreatorAsync(Type.FINALISE_BILL, async () => {
          await api.finaliseBill(cancelledBill.referenceTo, true);
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: "Bill Cancelled Successfully.",
              autoTimeout: true
            })
          );
        })
      );
      dispatch(clearDraft());
      dispatch(billCancel(cancelledBill));
      dispatch(push(`/billing/bills/${cancelledBill.id}`));
    } catch (error) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: error?.data?.message || "Sorry! Something went wrong.",
          autoTimeout: true
        })
      );
    }
  };

export const deleteDraft =
  (id: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    await api.deleteDraft(id);
    dispatch(removeBill(id));
  };

export const registerPrint =
  (id: number) =>
  async (dispatch: IThunkDispatch): Promise<void> => {
    const updatedBill = (await api.registerPrint(id)) as BillType;
    dispatch({ type: Type.UPDATE_PRINT_COUNT, payload: updatedBill });
  };

export const updateBillOnClientEdit =
  (client: Client): AppThunk =>
  (dispatch: IThunkDispatch) =>
    dispatch({
      type: Type.UPDATE_BILL_ON_CLIENT_EDIT,
      payload: client
    });

export const scannerDetected =
  (detected: boolean): AppThunk =>
  (dispatch: IThunkDispatch) =>
    dispatch({
      type: Type.BARCODE_SCANNER_DETECTED,
      payload: detected
    });

export const cancelReceipt =
  (receipt: Receipt): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    try {
      const updatedBill = await api.receiptCancel(receipt);
      dispatch({ type: Type.RECEIPT_CANCEL, payload: updatedBill });
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: "Successfully! Cancelled your receipt.",
          variant: "success",
          autoTimeout: true
        })
      );
    } catch (error) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: error?.data?.message || "Sorry! Cancellation Failed.",
          variant: "error",
          autoTimeout: true
        })
      );
    }
  };

// proforma invoice
export const saveProformaBill =
  (): AppThunk => async (dispatch: IThunkDispatch, getState: () => RootState) => {
    try {
      const { draft } = getState().bills;
      const reformedDraft = reformDraftBeforeSaving(draft);

      // currently we only allow to create credit note for a proforma bill
      // no edit allowed, no cancel allowed, no delete allowed
      if (reformedDraft.type === DocumentTypes.CREDITNOTE) {
        await api.createProfrmaBillCreditNote(reformedDraft);
      } else {
        await api.postProformaBill(reformedDraft);
      }

      dispatch(clearDraft());
      dispatch(push(`/billing/ipd?tab=proformaBills`));
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message:
            reformedDraft?.type === DocumentTypes.CREDITNOTE
              ? "Created proforma bill credit note!"
              : "Created proforma bill!",
          autoTimeout: true
        })
      );
    } catch (err) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err?.data?.message || "Sorry! Something went wrong.",
          autoTimeout: true
        })
      );
    }
  };

// finalise proforma bills
export const finaliseProformaBills =
  (billId: string): AppThunk =>
  async (dispatch: IThunkDispatch) => {
    try {
      // finalise proforma bills
      await api.finaliseBill(Number(billId));

      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Finalized selected proforma bills",
          autoTimeout: true
        })
      );
    } catch (err) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Failed to finalize proforma bills",
          autoTimeout: true
        })
      );
    }
  };
