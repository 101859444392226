/* eslint-disable react/no-danger */
import LocalHospitalIcon from "@mui/icons-material/LocalHospital";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Button, CircularProgress, Grid2 as Grid, Typography } from "@mui/material";
import React from "react";
import CachedIcon from "@mui/icons-material/Cached";
import { useSelector, useDispatch } from "react-redux";
import {
  FilterProps,
  PublicLabDetailsProps,
  PublicLabInterface,
  PublicService,
  ResourceCentreLocation
} from "../../../interfaces/PublicLabInterface";
import { RootState } from "../../../store";
import { createMarkup } from "../../Lab/LabPrint/LabPrintFunctions";
import ScheduleDetails from "./ScheduleDetails";
import { loadMorePublicServices } from "../../../slices/publicLab";

interface Props {
  selectedLab: PublicLabInterface | null;
  selectedRcLocation: ResourceCentreLocation | null;
  filters: FilterProps;
  data: PublicLabDetailsProps;
  loading: boolean;
}

export default function PublicLabDetails({
  selectedRcLocation,
  selectedLab,
  filters,
  data,
  loading
}: Props): JSX.Element {
  const [selectedService, setSelectedService] = React.useState<PublicService | null>(null);
  const dispatch = useDispatch();
  const hasMore = useSelector((state: RootState) => state.public.hasMore);
  const [page, setPage] = React.useState(filters.page);

  React.useEffect(() => {
    setSelectedService(null);
  }, [data]);

  React.useEffect(() => {
    setPage(0);
  }, [selectedRcLocation, selectedLab]);

  if (selectedService) {
    return (
      <ScheduleDetails onSave={() => setSelectedService(null)} selectedService={selectedService} />
    );
  }

  return (
    <Grid container spacing={2}>
      <Grid size={{ xs: 12, sm: 4 }}>
        <div
          dangerouslySetInnerHTML={createMarkup(data.labDetails || "")}
          style={{ fontSize: "15px" }}
        />
      </Grid>

      <Grid size={{ xs: 12, sm: 4 }}>
        {loading && <CircularProgress />}
        {!loading && data.publicServices.length ? (
          <>
            {data.publicServices.map((service) => (
              <Box sx={{ borderBottom: "0.5px solid gray" }} key={service.id} p={2}>
                <Box m="10px 0" display="flex" justifyContent="space-between">
                  <Typography fontSize="1rem" fontWeight="bold">
                    {service.name}
                  </Typography>
                  <Typography fontWeight="bold">{`Rs. ${service.grossTotalPrice}/-`}</Typography>
                </Box>
                <Box m="10px 0" display="flex" alignItems="center">
                  <LocationOnIcon fontSize="small" color="primary" />
                  <Typography ml="5px">{service.resourceCentre?.city || ""}</Typography>
                </Box>
                <Box m="10px 0" display="flex" justifyContent="space-between">
                  <Box display="flex" alignItems="center">
                    <LocalHospitalIcon fontSize="small" color="primary" />
                    <Typography ml="5px">{service.resourceCentre?.name || ""}</Typography>
                  </Box>
                  <Button
                    variant="contained"
                    onClick={() => setSelectedService(service)}
                    data-testmation="orderTestBtn"
                  >
                    Order Test
                  </Button>
                </Box>
              </Box>
            ))}
            {!loading && hasMore && (
              <Box mt={1} sx={{ cursor: "pointer" }} display="flex" justifyContent="center">
                <Box
                  p="10px"
                  gap={2}
                  onClick={() => {
                    setPage(page + 1);
                    dispatch(
                      loadMorePublicServices({ filters: { ...filters, page: page + 1 }, dispatch })
                    );
                  }}
                  display="flex"
                  sx={{ backgroundColor: "#e6e6e6", borderRadius: "5px" }}
                >
                  <CachedIcon />
                  <Typography>Show More</Typography>
                </Box>
              </Box>
            )}
          </>
        ) : (
          <Typography>No results found</Typography>
        )}
      </Grid>
    </Grid>
  );
}
