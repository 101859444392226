import * as React from "react";
import { Alert, Box, Grid2 as Grid, Paper, Button } from "@mui/material";
import { connect } from "react-redux";
import { Entry, ErrorState, Stock, Supplier } from "../../../interfaces/StockInterfaces";
import { RootState } from "../../../store";
import EntryItemsView from "./EntryItemsView";
import "./purchaseEntry.scss";
import Header from "./Header";
import EntryEnterer from "./EntryEnterer";
import PurchaseDiscountSettings from "./PurchaseDiscountSettings";

interface StockEntryItemsInterface {
  updateEntry: (entry: Entry) => void;
  entry: Entry;
  onRowRemove: (id: number) => void;
  supplierOptions: Supplier[];
  errors: ErrorState;
  setShowRowRemovedMsg: () => void;
  showRowRemovedMsg: boolean;
  stocks: Stock[];
  setStocks: (value: Stock[]) => void;
}
const StockEntryItems = ({
  updateEntry,
  entry,
  onRowRemove,
  supplierOptions,
  errors,
  setShowRowRemovedMsg,
  showRowRemovedMsg,
  stocks,
  setStocks
}: StockEntryItemsInterface) => {
  const [editRowId, setEditRowId] = React.useState(null);

  const onRowRemoveFn = React.useCallback(
    (id) => {
      onRowRemove(id);
      setEditRowId(null);
    },
    [onRowRemove]
  );

  return (
    <Box className="purchase_entry_items__stock" width="100%">
      <Grid container>
        <Grid size={{ xs: 12, sm: 12, md: 4 }} px="8px">
          <Header supplierOptions={supplierOptions} entry={entry} updateEntry={updateEntry} />
        </Grid>
        <Grid size={{ xs: 12, sm: 12, md: 8 }} p="0 32px 32px 32px">
          <Box display="flex" flexDirection="row-reverse">
            <PurchaseDiscountSettings />
          </Box>
          <Paper elevation={6}>
            <EntryEnterer
              stocks={stocks}
              setStocks={setStocks}
              editRowId={editRowId}
              cancelEdit={() => setEditRowId(null)}
            />
          </Paper>
        </Grid>
      </Grid>
      <EntryItemsView
        errors={errors}
        onRowRemove={onRowRemoveFn}
        onEdit={(id) => setEditRowId(id)}
        stocks={stocks}
      />
      {showRowRemovedMsg && (
        <Alert
          action={
            <Button onClick={setShowRowRemovedMsg} size="small">
              Ok
            </Button>
          }
          sx={{ margin: "10px" }}
          variant="outlined"
          severity="error"
        >
          Some products were out of stock and have been removed.
        </Alert>
      )}
    </Box>
  );
};

export default connect(
  (state: RootState) => ({
    stockSettings: state.stock?.entry?.settings?.discountSettings
  }),
  null
)(StockEntryItems);
