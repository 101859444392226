import React from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  TextField,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import startCase from "lodash/startCase";
import ClientSearch from "../../../components/ClientSearch";
import MuiPhoneNumber from "../../../components/PhoneNumber";
import { IpdTypes } from "../../../interfaces/IpdInterface";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { clientSearchSecondaryLabel } from "../../../models/Client";
import { RootState } from "../../../store";
import ReferrerCreateSelect from "../../Referrers/ReferrerCreateSelect";
import styles from "../styles.module.css";
import { IPDFormState } from "./CreateIpdAdmission";
import { isValidPhone } from "../../../helpers/phoneNumber";
import useGetNextIpdNumber from "../../../hooks/useGetNextIpdNumber";
import { t, tl } from "../../../components/translate";
import useIsSSFEnabled from "../../../hooks/useIsSSFEnabled";
import { useAppDispatch } from "../../../store/hooks";
import { getActiveClaimCode } from "../../../api/ssf";
import { notificationAdd } from "../../../actions/notification";
import { commonErrorMessage } from "../../../helpers/messages";
import { getTakenBedsInWard } from "../../../api/wards";

// leaving it commented for right now as it is no more used but can be used in future

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     root: {
//       "& .MuiTextField-root": {
//         margin: theme.spacing(1, 1, 1, 0),
//         width: 250
//       }
//     }
//   })
// );

export default function IpdAdmissionForm({
  setIpdFormData,
  ipdFormData,
  serviceProviders,
  mode,
  isFromDashboard
}: {
  setIpdFormData: (data: IPDFormState) => void;
  ipdFormData: IPDFormState;
  serviceProviders: ServiceProvider[];
  mode: string;
  isFromDashboard?: boolean;
}): JSX.Element {
  const [takenBedsInWard, setTakenBedsInWard] = React.useState<number[]>([]);

  const [validPhone, setValidPhone] = React.useState(false);
  const { beds, wards } = useSelector((state: RootState) => state.bedManagement);

  const [wardValue, setWardValue] = React.useState({
    shortCode: "",
    id: null
  });

  const [bedValue, setBedValue] = React.useState({
    number: "",
    id: null
  });
  const { isSsfEnabled } = useIsSSFEnabled();
  const dispatch = useAppDispatch();
  const [isSsfIpd, setIsSsfIpd] = React.useState(false);

  const ipdNumber = useGetNextIpdNumber(mode === "create");

  React.useEffect(() => {
    if (ipdFormData?.wardNumber) {
      const ipdWard = wards.find((ward) => ward.id === ipdFormData.wardNumber);
      setWardValue(ipdWard);
      setIpdFormData({
        ...ipdFormData,
        roomType: ipdWard?.isRoom ? "Cabin" : "Ward"
      });
    }
  }, [ipdFormData.wardNumber, wards]);

  React.useEffect(() => {
    if (ipdFormData?.bedNumber) {
      setBedValue(beds.find((bed) => bed.id === ipdFormData.bedNumber));
    }
  }, [ipdFormData.bedNumber, beds]);

  React.useEffect(() => {
    if (ipdFormData.client?.id && isSsfIpd) {
      (async () => {
        try {
          const code = await getActiveClaimCode(ipdFormData.client.id);
          setIpdFormData({ ...ipdFormData, claimCode: code.claimCode });
        } catch (e) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: e.msg || commonErrorMessage,
              autoTimeout: false
            })
          );
        }
      })();
    }
  }, [ipdFormData.client?.id]);

  React.useEffect(() => {
    // fetch the taken beds for the selected ward
    (async () => {
      try {
        if (!ipdFormData.wardNumber) return;
        const res = await getTakenBedsInWard(ipdFormData.wardNumber);
        setTakenBedsInWard(res);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: err.msg || "Failed to fetch taken beds",
            autoTimeout: false
          })
        );
      }
    })();
  }, [ipdFormData.wardNumber]);

  const phoneErrorMsg = "Please, Enter the valid phone No !";

  return (
    <Box className={styles.root}>
      <Grid container spacing={2}>
        <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
          {isSsfEnabled && (
            <Grid item xs={12} display="flex" gap={2} alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={isSsfIpd}
                    onChange={() => {
                      setIsSsfIpd(!isSsfIpd);
                      setIpdFormData({ ...ipdFormData, claimCode: "", client: {} });
                    }}
                  />
                }
                label="Is SSF"
              />
              {isSsfIpd && ipdFormData.client.id && (
                <Typography>
                  {`Claim code: ${
                    ipdFormData.claimCode || "Not found, please create bill for ipd first."
                  }`}
                </Typography>
              )}
            </Grid>
          )}

          <Grid item xs={12} sm={6}>
            <ClientSearch
              data-testmation="clientSearch"
              autoFocus={"createAnother" && !ipdFormData.client}
              client={ipdFormData.client}
              setClient={(v) => setIpdFormData({ ...ipdFormData, client: v })}
              variant="outlined"
              isDisabled={mode === "edit"}
              margin="dense"
              label={tl("selectClient")}
              placeholder={t("selectClient")}
              secondaryText={[
                { type: clientSearchSecondaryLabel.CUSTOMER_NUMBER },
                {
                  type: clientSearchSecondaryLabel.EXTERNAL_IDENTIFIER
                }
              ]}
            />
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          columnSpacing={2}
          rowSpacing={1}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label={tl("file/referenceNumber")}
              variant="outlined"
              placeholder={t("file/referenceNumber")}
              value={ipdFormData.referenceNumber}
              onChange={(e) => setIpdFormData({ ...ipdFormData, referenceNumber: e.target.value })}
              slotProps={{
                inputLabel: { shrink: true }
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography>
              IPD Number:{" "}
              <strong>
                {mode === "create" ? ipdNumber?.nextNumber || "" : ipdFormData.ipdNumber}
              </strong>
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          columnSpacing={2}
          rowSpacing={1}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid item xs={12} sm={6}>
            <Autocomplete
              disabled={isFromDashboard}
              value={wardValue}
              options={wards}
              getOptionLabel={(item) => item.shortCode}
              data-testmation="wardNumber"
              id="wardNumber"
              onChange={(_, v) => {
                if (!v || typeof v === "string") return;
                setIpdFormData({
                  ...ipdFormData,
                  wardNumber: v.id
                });
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  label={tl("wardName")}
                  placeholder={t("wardName")}
                  slotProps={{
                    inputLabel: { shrink: true }
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              disabled={isFromDashboard}
              value={bedValue}
              options={beds.filter(
                (bed) => bed.wardId === ipdFormData.wardNumber && !takenBedsInWard.includes(bed.id)
              )}
              getOptionLabel={(item) => item.number}
              data-testmation="bedNumber"
              id="bedNumber"
              onChange={(_, v) => {
                if (!v || typeof v === "string") return;
                setIpdFormData({
                  ...ipdFormData,
                  bedNumber: v.id
                });
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  label={tl("bedNumber")}
                  placeholder={t("bedNumber")}
                  slotProps={{
                    inputLabel: { shrink: true }
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              data-testmation="roomType"
              id="roomType"
              label={tl("roomType")}
              placeholder={t("roomType")}
              variant="outlined"
              size="small"
              disabled={Boolean(ipdFormData?.roomType)}
              margin="dense"
              value={ipdFormData.roomType}
              slotProps={{
                inputLabel: { shrink: true }
              }}
              onChange={(e) => setIpdFormData({ ...ipdFormData, roomType: e.target.value })}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12}>
            <Typography style={{ marginBottom: "0.5rem " }}>
              {tl("emergencyContactDetails")}
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            columnSpacing={2}
            rowSpacing={1}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                data-testmation="name"
                id="secondaryContactName"
                label={tl("guardianName")}
                placeholder={t("guardianName")}
                variant="outlined"
                size="small"
                required
                margin="dense"
                slotProps={{
                  inputLabel: { shrink: true }
                }}
                value={ipdFormData.emergencyContact.name}
                onChange={(e) =>
                  setIpdFormData({
                    ...ipdFormData,
                    emergencyContact: {
                      ...ipdFormData.emergencyContact,
                      name: e.target.value
                    }
                  })
                }
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <MuiPhoneNumber
                fullWidth
                onlyCountries={["np"]}
                size="small"
                required
                value={ipdFormData.emergencyContact.phone}
                defaultCountry="np"
                onChange={(value) => {
                  if (value && isValidPhone(value)) {
                    setValidPhone(false);
                    setIpdFormData({
                      ...ipdFormData,
                      emergencyContact: {
                        ...ipdFormData.emergencyContact,
                        phone: value
                      }
                    });
                  } else {
                    setValidPhone(true);
                    setIpdFormData({
                      ...ipdFormData,
                      emergencyContact: {
                        ...ipdFormData.emergencyContact,
                        phone: ""
                      }
                    });
                  }
                }}
                data-testmation="phone"
                label={tl("phoneNumber")}
                variant="outlined"
                error={validPhone}
                helperText={validPhone ? phoneErrorMsg : ""}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                data-testmation="relation"
                id="relation"
                label={tl("relation")}
                placeholder={t("relation")}
                variant="outlined"
                size="small"
                required
                margin="dense"
                slotProps={{
                  inputLabel: { shrink: true }
                }}
                value={ipdFormData.emergencyContact.relation}
                onChange={(e) =>
                  setIpdFormData({
                    ...ipdFormData,
                    emergencyContact: {
                      ...ipdFormData.emergencyContact,
                      relation: e.target.value
                    }
                  })
                }
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid container item xs={12} columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12}>
            <Typography style={{ marginBottom: "0.5rem " }}>{tl("caseInfo")}</Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              data-testmation="labTestSelectSP"
              options={serviceProviders.map((item) => ({
                ...item,
                fullname: `${item.firstName} ${item.lastName}`
              }))}
              getOptionLabel={(option: any) => startCase(`${option.fullname} `)}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  required
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label={tl("selectDoc")}
                  placeholder={t("selectDoc")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
              renderOption={(props, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <li {...props} key={option.id}>
                  {startCase(option.fullname)}
                </li>
              )}
              value={ipdFormData.doctorInCharge}
              onChange={(e, value) => {
                setIpdFormData({
                  ...ipdFormData,
                  doctorInChargeId: value?.id,
                  doctorInCharge: value
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <Autocomplete
              data-testmation="ipdAuthorized"
              options={serviceProviders.map((item) => ({
                ...item,
                fullname: `${item.firstName} ${item.lastName}`
              }))}
              getOptionLabel={(option: any) => startCase(`${option.fullname} `)}
              renderOption={(props, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <li {...props} key={option.id}>
                  {startCase(option.fullname)}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  margin="dense"
                  variant="outlined"
                  label={tl("selectAuthorisedPersonal")}
                  placeholder={t("selectAuthorisedPersonal")}
                  InputLabelProps={{ shrink: true }}
                />
              )}
              value={ipdFormData.authorizedPersonnel}
              onChange={(e, value) => {
                setIpdFormData({
                  ...ipdFormData,
                  authorizedPersonnelId: value?.id,
                  authorizedPersonnel: value
                });
              }}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              id="ipd Case"
              data-testmation="ipdCase"
              select
              label={tl("IPDCase")}
              value={ipdFormData.ipdCase}
              onChange={(e) => setIpdFormData({ ...ipdFormData, ipdCase: e.target.value })}
              placeholder="Select Case"
              SelectProps={{
                MenuProps: {
                  className: styles.menu
                }
              }}
              variant="outlined"
              size="small"
              required
              margin="dense"
              InputLabelProps={{ shrink: true }}
            >
              {Object.values(IpdTypes).map((option) => (
                <MenuItem style={{ height: "50px" }} key={option} value={option} id={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              data-testmation="expectedLengthofStay"
              id="ExpectedLengthofStay"
              label={tl("expectedLengthOfStay")}
              placeholder={t("enterDays")}
              variant="outlined"
              size="small"
              margin="dense"
              InputLabelProps={{ shrink: true }}
              value={ipdFormData.expectedLengthOfStay}
              onChange={(e) =>
                setIpdFormData({ ...ipdFormData, expectedLengthOfStay: Number(e.target.value) })
              }
            />
          </Grid>
        </Grid>

        <Grid container item xs={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>{t("forms.more")}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    data-testmation="provisionalDiagonosis"
                    sx={{ width: "100%" }}
                    id="Provisional Diagnosis"
                    label={tl("provisionalDiagnosis")}
                    variant="outlined"
                    placeholder={t("provisionalDiagnosis")}
                    multiline
                    minRows={4}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setIpdFormData({ ...ipdFormData, provisionalDiagnosis: e.target.value })
                    }
                    value={ipdFormData.provisionalDiagnosis}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    data-testmation="conditionAtAdmission"
                    sx={{ width: "100%" }}
                    id="Admission Condition"
                    label={tl("conditionatAdmission")}
                    variant="outlined"
                    placeholder={t("conditionatAdmission")}
                    multiline
                    minRows={4}
                    value={ipdFormData.admissionCondition}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) =>
                      setIpdFormData({ ...ipdFormData, admissionCondition: e.target.value })
                    }
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    data-testmation="hopi"
                    sx={{ width: "100%" }}
                    id="History of Past/Present Illness"
                    label="HOPI"
                    variant="outlined"
                    placeholder="History of Past/Present Illness"
                    multiline
                    minRows={4}
                    value={ipdFormData.hopi}
                    InputLabelProps={{ shrink: true }}
                    onChange={(e) => setIpdFormData({ ...ipdFormData, hopi: e.target.value })}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </Grid>

        {mode === "create" && (
          <Grid item xs={12} sm={6}>
            <ReferrerCreateSelect
              width="100%"
              textFieldWidth="100%"
              onReferrerChange={(value) => {
                if (value) {
                  setIpdFormData({ ...ipdFormData, referredBy: value?.referrer });
                }
              }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}

IpdAdmissionForm.defaultProps = {
  isFromDashboard: false
};
