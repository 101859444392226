import { Box, Typography } from "@mui/material";
import { capitalize } from "lodash";
import React from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import { TemplateData } from "./TemplateHelpers";
import Panel from "../../../components/Panel";
import * as templateActions from "../../../actions/template";
import { navigateRemoveModal, showDialog } from "../../../actions/navigation";
import Can from "../../Policy/Can";

interface TemplateInfoProps {
  template: TemplateData;
  onClose: any;
  onDeleteTemplate: any;
  navigateTo: any;
}

const TemplateInfo: React.FC<TemplateInfoProps> = ({
  template,
  navigateTo,
  onClose,
  onDeleteTemplate
}) => {
  const editButton = (
    <Can policyAccessKey="campaign:editTemplate">
      <EditIcon
        data-testmation="editButton"
        style={{ cursor: "pointer" }}
        onClick={() => navigateTo(`/campaign/template/${template.id}/edit`)}
      />
    </Can>
  );

  const deleteButton = (
    <Can policyAccessKey="campaign:deleteTemplate">
      <DeleteIcon
        data-testmation="deleteButton"
        style={{ cursor: "pointer" }}
        onClick={() => onDeleteTemplate(template.id, template.name)}
      />
    </Can>
  );

  return (
    <Panel onClose={onClose} editButton={editButton} deleteButton={deleteButton}>
      <Box px="32px" width="100%">
        <Typography variant="h6">{template.name}</Typography>
        <Box marginTop="32px" width="100%">
          <Box display="flex">
            <Box width="25%" fontSize="14px" fontWeight={600}>
              <Typography style={{ fontWeight: 600 }}>{"Type: "}</Typography>
            </Box>
            <Box fontSize="14px">
              <Typography>{capitalize(template.mediaType)}</Typography>
            </Box>
          </Box>
        </Box>
        <Box width="100%">
          <Box width="25%" fontSize="14px" fontWeight={600}>
            <Typography style={{ fontWeight: 600 }}>{"Content: "}</Typography>
          </Box>
          <Box fontSize="14px" whiteSpace="pre-line">
            {template.mediaType === "sms" && (
              <Typography>{capitalize(template.message)}</Typography>
            )}
            {template.mediaType === "email" && (
              <Typography>
                <div dangerouslySetInnerHTML={{ __html: template.message }} />
              </Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Panel>
  );
};

export default connect(
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  () => {},
  (dispatch) => ({
    navigateTo: (url) => dispatch(push(url)),
    onDeleteTemplate: (id, name) => {
      dispatch(
        showDialog({
          title: `Delete template`,
          description: `Are you sure you want to delete ${name}?`,
          next: () => {
            dispatch(templateActions.deleteTemplate(id));
            dispatch(navigateRemoveModal("Dialog"));
            dispatch(push("/campaign/template"));
          },
          onCancel: () => dispatch(navigateRemoveModal)
        })
      );
    }
  })
)(TemplateInfo);
