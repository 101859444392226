import { Autocomplete, Box, Grid2 as Grid, TextField } from "@mui/material";
import { startCase } from "lodash";
import * as React from "react";
import { connect, useSelector } from "react-redux";
import * as clientActions from "../../../actions/client";
import { navigateRemoveModal } from "../../../actions/navigation";
import Form from "../../../components/Form";
import Modal from "../../../components/Modal/Modal";
import ToggleInputDate from "../../../components/ToggleADBS";
import { tl } from "../../../components/translate";
import { isISODateString } from "../../../helpers/validators";
import { ClientCodes, TransactionType } from "../../../interfaces/Accounts";
import { Client } from "../../../interfaces/ClientInterface";
import { bsDateField } from "../../../models/Accounts";
import { fields as clientFields } from "../../../models/Client";
import { RootState } from "../../../store";
import { extractSecondaryData, getAllChildGl, useCoaAll } from "../../accounts/hooks";
import AddressSelect from "../../Address/AddressSelect";

interface propsType {
  next: () => void;
  clientDefaultData: Client;
  actions: {
    onClose: () => void;
    onSave: (data) => Promise<null>;
  };
  resourceCentreId?: number | null;
}

const minimumRequiredField = ["firstName", "lastName", "phone", "dob", "gender"];

const ClientCreateModal: React.FC<propsType> = ({
  next,
  clientDefaultData,
  actions,
  resourceCentreId = null
}) => {
  const { isAccountSubscribed } = useSelector((state: RootState) => ({
    isAccountSubscribed: state.subscriptions.currentSubscription?.features?.account?.subscribed
  }));
  const [formData, setFormData] = React.useState(clientDefaultData);
  const [errorsCount, setErrorsCount] = React.useState(null);
  const [saveAttempted, setSaveAttempted] = React.useState(false);
  const [palikaId, setpalikaId] = React.useState(clientDefaultData?.palikaId || null);
  const { assets } = extractSecondaryData(useCoaAll());
  const assetsChildLedger = getAllChildGl(assets);
  const clientLedger = assetsChildLedger.find((gl) => ClientCodes.CLIENT === gl.code);

  React.useEffect(() => {
    if (clientLedger && isAccountSubscribed) {
      setFormData({ ...formData, ledgerId: clientLedger?.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientLedger, isAccountSubscribed]);

  return (
    <Modal
      open
      onClose={actions.onClose}
      title={tl("clients.createClient")}
      onSave={() => {
        if (formData && !errorsCount && formData?.phone) {
          if (palikaId) {
            formData.palikaId = palikaId;
          }
          actions
            .onSave({ ...formData, ...(resourceCentreId && { resourceCentreId }) })
            .then(() => {
              if (next) next();
            });
          actions.onClose();
        } else {
          setSaveAttempted(true);
        }
      }}
    >
      <Form
        fields={
          resourceCentreId
            ? clientFields.filter((item) => minimumRequiredField.includes(item.key))
            : clientFields
        }
        data={clientDefaultData || {}}
        onChange={(newData, errors, errorCount) => {
          setFormData({ ...formData, ...newData });
          setErrorsCount(errorCount);
        }}
        showErrors={saveAttempted}
      />
      {!resourceCentreId && (
        <AddressSelect
          onChange={(v) => {
            setpalikaId(v);
          }}
          selectedOption={palikaId}
        />
      )}
      {clientLedger && (
        <TextField
          sx={{ marginTop: "10px" }}
          disabled
          fullWidth
          value={clientLedger.ledgerName}
          variant="outlined"
          label="Child Ledger Mapping"
          slotProps={{
            inputLabel: { shrink: true }
          }}
        />
      )}
      {isAccountSubscribed && (
        <>
          <Box display="flex" gap={2} m="10px 0">
            <TextField
              variant="outlined"
              label="Opening Balance"
              placeholder="Opening Balance"
              type="number"
              fullWidth
              onChange={({ target }) =>
                setFormData({ ...formData, openingBalance: Number(target.value) })
              }
            />
            <Box width="400px">
              <ToggleInputDate
                key="openingBalanceDate"
                field={{
                  ...bsDateField,
                  key: "openingBalanceDate",
                  label: "openingBalanceDate"
                }}
                data={{ openingBalanceDate: formData.openingBalanceDate }}
                isFocused={() => ({})}
                isBlurred={() => ({})}
                showAgeField={false}
                changeDate={(value) => {
                  if (isISODateString(value)) {
                    setFormData({ ...formData, openingBalanceDate: value });
                  } else {
                    setFormData({ ...formData, openingBalanceDate: null });
                  }
                }}
              />
            </Box>
          </Box>
          <Grid size={12}>
            <Autocomplete
              options={[TransactionType.DEBIT, TransactionType.CREDIT]}
              onChange={(e, value) => {
                setFormData({ ...formData, transactionType: value as TransactionType });
              }}
              getOptionLabel={(option) => startCase(option)}
              renderInput={(params) => (
                <TextField
                  //   eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  variant="outlined"
                  label="Transaction Type"
                  slotProps={{
                    inputLabel: { shrink: true }
                  }}
                />
              )}
            />
          </Grid>
        </>
      )}
    </Modal>
  );
};

export default connect(null, (dispatch) => ({
  actions: {
    onClose: () => dispatch(navigateRemoveModal("ClientCreateModal")),
    onSave: async (clientData) => {
      await dispatch(clientActions.saveClient(clientData, true));
    }
  }
}))(ClientCreateModal);
