import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid2";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { Button, InputAdornment } from "@mui/material";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import styles from "./styles.module.css";
import { gray, lightGray } from "../../../styles/colors";
import Modal from "../../../components/Modal/Modal";
import { updatePassword } from "../../../actions/user";
import TwoFactorAuthQrScanModal from "../../User/TwoFactorAuthQrScanModal";
import { RootState } from "../../../store";
import { login } from "../../../actions/authentication";

const Welcome = ({ user }) => (
  <Grid className={styles.welcomeHeader} size={12}>
    <Box fontSize={16} fontWeight={900} textAlign="center" color={gray}>
      Welcome, {user.title} {user.firstName} {user.lastName}!
    </Box>
  </Grid>
);

const isFirstTime = () => true;

const isvalidPassword = (value) => {
  const regix = new RegExp("^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])([a-zA-Z0-9_@.#&+!-$?]{8,})$");
  return regix.test(value);
};

const ChangePassword = ({ onSave, user }) => {
  const [pwRevealState, setPwRevealState] = React.useState<{
    [fieldKey: string]: boolean;
  }>({
    password: false,
    confirmPassword: false
  });

  const dispatch = useDispatch();

  const { userCreds } = useSelector((state: RootState) => state.userContext);

  function handleRevealToggle(key) {
    setPwRevealState((state) => ({ ...pwRevealState, [key]: !state[key] }));
  }

  const [form, updateForm] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  });

  return (
    <>
      <Modal
        open
        footer={
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                onSave(form);
              }}
              disabled={
                !isvalidPassword(form.newPassword) || !(form.confirmPassword === form.newPassword)
              }
            >
              SAVE
            </Button>
          </Box>
        }
      >
        <Welcome user={user} />
        <Divider className={styles.divider} />
        <Box fontSize={14} fontWeight={500} textAlign="center" style={{ color: lightGray }}>
          To continue, please replace the temporary password with a new strong one!
        </Box>
        <Grid size={12}>
          {!isFirstTime && (
            <TextField
              id="outlined-oldPassword"
              className={styles.input}
              label="Old password"
              value={form.oldPassword}
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  onSave(form);
                }
              }}
              onChange={(e) => updateForm({ ...form, oldPassword: e.target.value })}
              margin="dense"
              type="password"
            />
          )}
          <TextField
            id="outlined-newPassword"
            className={styles.input}
            autoComplete="new-password"
            label="New password"
            value={form.newPassword}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSave(form);
              }
            }}
            onChange={(e) => {
              updateForm({ ...form, newPassword: e.target.value });
            }}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="start">
                    {pwRevealState.password ? (
                      <VisibilityIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRevealToggle("password")}
                      />
                    ) : (
                      <VisibilityOffIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRevealToggle("password")}
                      />
                    )}
                  </InputAdornment>
                )
              }
            }}
            margin="dense"
            type={pwRevealState.password ? "text" : "password"}
          />
          <Typography style={{ color: isvalidPassword(form.newPassword) ? "green" : "red" }}>
            Password must be minimum 8 characters long. Contain at least one lowercase, one
            uppercase and one numeric digit.
          </Typography>
          <TextField
            id="outlined-newPasswordRepeat"
            className={styles.input}
            autoComplete="new-password"
            label="Repeat New Password"
            value={form.confirmPassword}
            onKeyDown={(e) => {
              if (e.keyCode === 13) {
                onSave(form);
              }
            }}
            onChange={(e) => updateForm({ ...form, confirmPassword: e.target.value })}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="start">
                    {pwRevealState.confirmPassword ? (
                      <VisibilityIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRevealToggle("confirmPassword")}
                      />
                    ) : (
                      <VisibilityOffIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRevealToggle("confirmPassword")}
                      />
                    )}
                  </InputAdornment>
                )
              }
            }}
            margin="dense"
            type={pwRevealState.confirmPassword ? "text" : "password"}
          />
        </Grid>
      </Modal>
      <TwoFactorAuthQrScanModal
        onSave={(token) =>
          dispatch(login({ email: userCreds.username, password: form.newPassword, token }))
        }
      />
    </>
  );
};

export default connect(
  (state: RootState) => ({
    tempPassword: state.userContext,
    mode: state.userContext.mode,
    user: state.userContext.user
  }),
  (dispatch) => ({
    onSave: (passwords) => {
      dispatch(updatePassword(passwords));
    }
  })
)(ChangePassword);
