import { push } from "connected-react-router";
import { match as MatchProps } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import React, { Dispatch, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  Autocomplete,
  Typography
} from "@mui/material";

import { RootState } from "../../store";
import Panel from "../../components/Panel";
import { t, tl } from "../../components/translate";
import useParticulars from "../../hooks/useParticulars";
import { DaybookInterface } from "../../interfaces/Daybook";
import { notificationAdd } from "../../actions/notification";
import StatefulButton from "../../components/StatefulButton/StatefulButton";
import { DaybookFormMode, DaybookTransactionEnum } from "../../enum/Daybook";
import { getDaybookEntryById, patchDaybookData, postDaybookData } from "../../api/daybook";

interface DaybookCreateEditProps {
  mode: string;
  match: MatchProps;
  onClose?: () => void;
  selectedRow?: Partial<DaybookInterface> | null;
  setDaybookData: Dispatch<
    React.SetStateAction<{ results: Array<DaybookInterface>; total: number }>
  >;
}

const DaybookCreateEdit = ({
  mode,
  match,
  onClose,
  selectedRow,
  setDaybookData
}: DaybookCreateEditProps): JSX.Element => {
  const { particulars, addParticular } = useParticulars();
  const [inputValue, setInputValue] = useState("");

  const [openOptions, setOpenOptions] = useState(false);

  const dispatch = useDispatch();
  const daybookRecordId = Number(match.params.id);
  const [loading, setLoading] = useState<boolean>(false);
  const [saveAndCreateNew, setSaveAndCreateNew] = useState<boolean>(false);
  const rcId = useSelector((state: RootState) => state.userContext?.resourceCentreId);

  const [entry, setEntry] = useState<Partial<DaybookInterface>>({
    transactionType: DaybookTransactionEnum.EXPENSE,
    particulars: "",
    remarks: "",
    amount: 0,
    resourceCentreId: rcId
  });

  const isEntryValid = (ent: Partial<DaybookInterface>) =>
    ent.transactionType && ent.particulars && (ent.amount ?? 0) > 0;

  const handleSave = async () => {
    try {
      setLoading(true);

      // Add current entry if it's valid
      if (!isEntryValid(entry)) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Please fill all the required fields",
            autoTimeout: true
          })
        );
        setLoading(false);
        return;
      }

      if (mode === DaybookFormMode.CREATE) {
        const createdEntry = await postDaybookData(entry);
        addParticular(entry.particulars as string);
        setDaybookData((prevData) => ({
          ...prevData,
          results: [createdEntry, ...prevData.results],
          total: prevData.total + 1
        }));
      }

      if (mode === DaybookFormMode.EDIT) {
        const updatedEntry = await patchDaybookData(daybookRecordId, entry);
        setDaybookData((prevData) => ({
          ...prevData,
          results: prevData.results.map((item) =>
            item.id === updatedEntry?.id ? updatedEntry : item
          )
        }));
      }

      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: `Daybook record${
            mode === DaybookFormMode.CREATE ? "s created" : " updated"
          } successfully`,
          autoTimeout: true
        })
      );

      if (saveAndCreateNew) {
        setEntry({
          transactionType: DaybookTransactionEnum.EXPENSE,
          particulars: "",
          remarks: "",
          amount: 0,
          resourceCentreId: rcId
        });
      } else {
        dispatch(push("/daybook"));
      }
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.message || "Something went wrong.",
          autoTimeout: true
        })
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    const fetchAndSetData = async () => {
      if (!isMounted) return;

      if (daybookRecordId && selectedRow) {
        setEntry({
          transactionType: selectedRow.transactionType || DaybookTransactionEnum.INCOME,
          particulars: selectedRow.particulars || "",
          remarks: selectedRow?.remarks || "",
          amount: selectedRow?.amount || 0,
          resourceCentreId: selectedRow?.resourceCentreId
        });
      } else if (daybookRecordId) {
        try {
          const res = await getDaybookEntryById(daybookRecordId);
          setEntry({
            transactionType: res.transactionType || DaybookTransactionEnum.INCOME,
            particulars: res.particulars || "",
            remarks: res?.remarks || "",
            amount: res?.amount || 0,
            resourceCentreId: res?.resourceCentreId
          });
        } catch (err) {
          if (!isMounted) return;
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: err.message || "Failed to fetch request form",
              autoTimeout: true
            })
          );
        }
      }
    };

    fetchAndSetData();

    return () => {
      isMounted = false;
    };
  }, [daybookRecordId, selectedRow, dispatch]);

  const handleOptionChange = (_, newValue) => {
    if (typeof newValue === "string" && newValue.startsWith("Add")) {
      const newParticular = inputValue;
      addParticular(newParticular);
      setInputValue(newParticular);
      setEntry({ ...entry, particulars: newParticular });
    } else {
      setEntry({ ...entry, particulars: newValue || "" });
    }
  };

  const onInputChange = (_, newInputValue) => {
    setInputValue(newInputValue);
    if (!particulars.includes(newInputValue)) {
      setEntry({ ...entry, particulars: newInputValue });
    }
  };

  const renderForm = () => (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "10px" }}>
      <Box
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px" }}
      >
        <Autocomplete
          sx={{ width: "50%" }}
          value={entry.particulars}
          onChange={handleOptionChange}
          inputValue={inputValue}
          onInputChange={onInputChange}
          options={particulars}
          open={openOptions && inputValue !== ""}
          onOpen={() => setOpenOptions(true)}
          onClose={() => setOpenOptions(false)}
          getOptionLabel={(option) => option}
          data-testmation="daybookParticularsInput"
          filterOptions={(options, params) => {
            const filtered = options.filter((option) =>
              option.toLowerCase().includes(params.inputValue.toLowerCase())
            );

            if (!particulars.includes(params.inputValue)) {
              filtered.push(`Add "${params.inputValue}"`);
            }

            return params.inputValue === "" ? [] : filtered;
          }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="outlined"
              label={tl("daybook.particulars")}
              placeholder={t("daybook.particularsEg")}
              margin="dense"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              disabled={loading}
            />
          )}
          noOptionsText={inputValue ? `Add "${inputValue}"` : ""}
        />

        <FormControl variant="outlined" size="small" style={{ width: "50%" }}>
          <InputLabel id="transactionType-label">{tl("daybook.transactionType")}</InputLabel>
          <Select
            variant="outlined"
            id="transactionType"
            value={entry.transactionType}
            label={tl("daybook.transactionType")}
            disabled={loading || mode === DaybookFormMode.EDIT}
            data-testmation="daybookTransactionType"
            onChange={(e) =>
              setEntry({
                ...entry,
                transactionType: e.target.value as DaybookTransactionEnum
              })
            }
          >
            {Object.values(DaybookTransactionEnum).map((item) => (
              <MenuItem key={item} value={item}>
                <Typography variant="body2" textTransform="capitalize">
                  {item}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", gap: "10px" }}
      >
        <TextField
          fullWidth
          type="number"
          value={entry.amount}
          disabled={loading || mode === DaybookFormMode.EDIT}
          margin="dense"
          variant="outlined"
          label={tl("daybook.amount")}
          placeholder={t("daybook.amount")}
          slotProps={{
            inputLabel: { shrink: true }
          }}
          onFocus={(e) => e.target.select()}
          data-testmation="daybookAmountInput"
          onChange={(e) => setEntry({ ...entry, amount: Number(e.target.value) })}
        />
      </Box>

      <TextField
        fullWidth
        type="text"
        value={entry.remarks}
        multiline
        rows={4}
        disabled={loading}
        margin="dense"
        variant="outlined"
        label={tl("daybook.remarks")}
        placeholder={t("daybook.remarks")}
        slotProps={{
          inputLabel: { shrink: true }
        }}
        data-testmation="daybookRemarksInput"
        onChange={(e) => setEntry({ ...entry, remarks: e.target.value })}
      />
    </Box>
  );

  const footer = (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginRight: "32px",
        justifyContent: "flex-end",
        gap: "10px"
      }}
    >
      <Box sx={{ marginRight: "auto" }}>
        <Checkbox
          checked={saveAndCreateNew}
          onChange={() => setSaveAndCreateNew(!saveAndCreateNew)}
        />
        {tl("daybook.createAnotherAfterSave")}
      </Box>
      <Button
        onClick={() => dispatch(push("/daybook"))}
        data-testmation="cancelIpdRequestForm"
        style={{ height: "32px", width: "60px" }}
      >
        {tl("cancel")}
      </Button>
      <StatefulButton
        variant="contained"
        color="primary"
        disabled={loading || !isEntryValid(entry)}
        onClick={handleSave}
        isLoading={loading}
        circularProgressProps={{ size: 16 }}
        data-testmation="daybookSave"
        style={{ height: "35px", width: "80px" }}
      >
        {mode === DaybookFormMode.CREATE ? tl("save") : tl("update")}
      </StatefulButton>
    </Box>
  );

  return (
    <Panel
      title={mode === DaybookFormMode.CREATE ? tl("daybook.create") : tl("daybook.edit")}
      onClose={() => {
        if (onClose) {
          onClose();
        } else {
          dispatch(push("/daybook"));
        }
      }}
      footer={footer}
    >
      <Box
        p={5}
        width="100%"
        height="calc(100vh - 125px)"
        sx={{ overflowY: "auto", display: "flex", flexDirection: "column" }}
      >
        {renderForm()}
      </Box>
    </Panel>
  );
};

export default DaybookCreateEdit;
