import { Autocomplete, Box, TextField } from "@mui/material";
import React from "react";
import {
  extractSecondaryData,
  getAllChildGl,
  getAllChildGlWithLedgerTypeFilter,
  useCoaAll
} from "../../containers/accounts/hooks";
import { ChildGeneralLedger, PrimaryLedgerNames } from "../../interfaces/Accounts";

const IncomeAccountLedgerAutocomplete = ({
  ledgerId = null,
  onChange,
  ledgerType,
  ...rest
}: {
  ledgerId: number | null;
  onChange: (ledger) => void;
  ledgerType;
}): React.ReactElement => {
  const [data, setData] = React.useState(null);
  const [ledgerValue, setLedgerValue] = React.useState<Partial<ChildGeneralLedger | null>>(null);
  const coa = useCoaAll();
  const { equityAndLiabilities, assets, income, expenses } = extractSecondaryData(coa);

  React.useEffect(() => {
    switch (ledgerType) {
      case PrimaryLedgerNames.INCOME:
        setData(income);
        break;
      case PrimaryLedgerNames.ASSETS:
        setData(assets);
        break;
      case PrimaryLedgerNames.EQUITY_AND_LIABILITIES:
        setData(equityAndLiabilities);
        break;
      case PrimaryLedgerNames.EXPENSES:
        setData(expenses);
        break;
      default:
        setData(null);
    }
  }, [equityAndLiabilities, assets, income, expenses, ledgerType]);

  const incomeLedgerData: ChildGeneralLedger[] = React.useMemo(() => getAllChildGl(data), [data]);
  const allChildLedgers: ChildGeneralLedger[] = getAllChildGlWithLedgerTypeFilter();

  React.useEffect(() => {
    if (ledgerId) {
      const value = allChildLedgers.find(
        (ledgerData: ChildGeneralLedger) => ledgerData.id === ledgerId
      );
      setLedgerValue(value);
    } else {
      setLedgerValue(null);
    }
  }, [ledgerId, allChildLedgers]);

  return (
    <Autocomplete
      id="combo-box"
      options={incomeLedgerData.map((option) => option)}
      getOptionLabel={(option) => (option.code ? `${option.code} ${option.ledgerName}` : "")}
      renderOption={(renderProps, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...renderProps} key={option.code}>
          <Box style={{ display: "flex", width: "100%" }}>
            <Box>{option.code}</Box>
            <Box style={{ marginLeft: "8px" }}>{option.ledgerName}</Box>
          </Box>
        </li>
      )}
      data-testmation="productCreateIALDropdown"
      value={ledgerValue || null}
      onChange={(e, v: ChildGeneralLedger | null) => {
        if (v) {
          setLedgerValue({
            id: v.id,
            ledgerName: v.ledgerName,
            code: v.code
          });
          if (onChange) {
            onChange(v);
          }
        } else {
          setLedgerValue(null);
          onChange(null);
        }
      }}
      renderInput={(params) => (
        <TextField
          data-testmation="accountLedger"
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          margin="dense"
          fullWidth
          variant="outlined"
          label={`${ledgerType} Account Ledger`}
          placeholder={`${ledgerType} Account Ledger`}
          slotProps={{
            inputLabel: { shrink: true }
          }}
        />
      )}
    />
  );
};

export default IncomeAccountLedgerAutocomplete;
