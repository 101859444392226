import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import moment from "moment";
import { Typography, Box, Menu, MenuItem, Button, ButtonGroup, Link } from "@mui/material";
import PackageIcon from "@mui/icons-material/Redeem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { startCase } from "lodash";
import { servicesSortedSelector } from "../../reducers/services";
import { getServices } from "../../actions/services";
import { serviceProviderActions as actions } from "../../actions";
import { tl } from "../../components/translate";
import List, { Filters } from "../../components/List";
import "./ServiceList.scss";
import { spFullNameSelector, spFromIdSelector } from "../../reducers/serviceProvider";
import useMobileScreen from "../../hooks/useMobileScreen";
import Can from "../Policy/Can";
import {
  ProductInterface,
  ProductType,
  SpecialLabCategories
} from "../../interfaces/ProductInterface";
import { ServiceProvider } from "../../interfaces/ServiceProvidersInterface";
import { CanActions } from "../../interfaces/policy";
import { IThunkDispatch, RootState } from "../../store";
import { ServiceInterface } from "../../interfaces/ServiceInterface";
import { PublicServiceStatus } from ".";
import { ListActions, Menu as ListMenu, MenuItem as ListMenuItem } from "../../components/OList";
import CsvUploader from "../../components/CsvUploader/CsvUploader";
import { serviceImportColumns } from "../../components/CsvUploader/csvHelpers";
import { batchDeleteServices, batchUploadService } from "../../api/services";
import useGetDepartments from "../../hooks/department";
import { downloadExcel } from "../../helpers/files";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import { notificationAdd as addNotification, notificationAdd } from "../../actions/notification";
import useIsSSFEnabled from "../../hooks/useIsSSFEnabled";
import OkhatiDialog from "../../components/Dialog/Dialog";

export const defaultServiceTypes = [
  "consultation",
  "labTest",
  "medication",
  "imaging",
  "procedures",
  "video",
  "nursing",
  "physiotherapy",
  "vaccination",
  "others",
  "home",
  "allopathy",
  "naturopathy",
  "exercise"
];

function downloadServiceUploadExample() {
  window.open(
    "https://docs.google.com/spreadsheets/d/1kYDyUiSOf_yMCqtVAzYtqjPOi6AaOQPy8VdPE_MIF5E",
    "_blank"
  );
}

const requiredFieldInfoText =
  "Service name, gross total price, category, productType, service price excluding vat are required.";
// enum to array
export const specialLabCategories = Object.values(SpecialLabCategories);

export const isServicePublic = (service: ServiceInterface | ProductInterface): boolean =>
  service.referenceId && service.publicBooking;

export const filterData = (
  services: Array<ServiceInterface> | Array<ProductInterface>,
  filter: string
): Array<ServiceInterface> =>
  services.filter((service) => {
    if (filter === "all") {
      return service;
    }
    if (filter === "package") {
      return service.productType === filter;
    }
    if (filter === "others") {
      const othersIncludes = ["others"];
      return othersIncludes.includes(service.category);
    }

    if (filter === "labTest") {
      return ["labTest", ...specialLabCategories].includes(service.category);
    }

    return service.category === filter;
  });

export const getCategory = (category: string): ProductType | SpecialLabCategories => {
  if (specialLabCategories.includes(category)) return ProductType.labTest;
  return category as ProductType;
};

interface ServiceListProps {
  servicesFiltered: Array<ProductInterface>;
  actions: {
    loadServiceProviders: (id) => void;
    loadServices: () => void;
    navigateTo: (url, params?) => void;
  };
  serviceProviders: Array<ServiceProvider>;
  selectedRow: ProductInterface;
  setSelectedRow: ProductInterface | null;
  policies: Partial<CanActions>;
  isPublicTab: boolean;
  isSsfTab: boolean;
}

interface FilterObject {
  key: string;
  title: JSX.Element | string;
}

const getServiceNamesByIds = (services: ServiceInterface[], ids: number[]): string[] => {
  const idSet = new Set(ids);
  return services.filter((service) => idSet.has(service.id)).map((service) => service.name);
};

export const getFiltersForServiceType = (
  otherTypes: FilterObject[]
): {
  filters: FilterObject[];
  moreFilters: FilterObject[];
} => ({
  filters: [
    { key: "all", title: tl(`services.all`) },
    { key: "package", title: tl(`services.package`) },
    { key: "labTest", title: tl(`services.labTest`) },
    { key: "consultation", title: tl(`services.consultation`) },
    { key: "imaging", title: tl(`services.imaging`) },
    { key: "procedures", title: tl(`services.procedures`) },
    { key: "vaccination", title: tl(`services.vaccination`) }
  ],
  moreFilters: [
    { key: "medication", title: tl(`services.medication`) },
    { key: "video", title: "Video Consultation" },
    { key: "nursing", title: "Nursing Care" },
    { key: "physiotherapy", title: "Physiotherapy" },
    { key: "home", title: "Home Service" },
    { key: "allopathy", title: tl("services.allopathy") },
    { key: "naturopathy", title: tl("services.naturopathy") },
    { key: "exercise", title: tl("services.exercise") },
    ...otherTypes,
    { key: "others", title: "Others" }
  ]
});

const docColumns = () => [
  "Name",
  "Gross Total Price",
  "Vat Pct",
  "Product Type",
  "Category",
  "Unit",
  "Material Charge",
  "Service Provider Rate Unit",
  "Service Price Exc VAT",
  "Department Id"
];

const docRowProcessor = ({
  name,
  grossTotalPrice,
  vatPct,
  productType,
  category,
  unit,
  materialCharge,
  serviceProviderRateUnit,
  servicePriceExcVAT,
  departmentId
}) => [
  name,
  grossTotalPrice || 0,
  vatPct || 0,
  productType,
  category,
  unit,
  materialCharge || 0,
  serviceProviderRateUnit,
  servicePriceExcVAT || 0,
  departmentId || "N / A"
];

export const ServiceList: React.FC<ServiceListProps> = ({
  servicesFiltered,
  actions: actionsService,
  serviceProviders,
  selectedRow,
  setSelectedRow,
  policies,
  isPublicTab,
  isSsfTab
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [filter, setFilter] = React.useState("all");
  const [checkedServices, setCheckedServices] = React.useState<number[]>([]);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = React.useState(false);
  const [isDeletingServices, setIsDeletingServices] = React.useState(false);

  const { isSsfEnabled } = useIsSSFEnabled();

  const dispatch = useDispatch();
  const serviceList = React.useMemo(
    () => filterData(servicesFiltered, filter),
    [servicesFiltered, filter]
  );
  const serviceTypes = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres[0]?.settings?.serviceSettings?.serviceTypes || []
  );
  const otherExceptDefaultTypes = serviceTypes
    .filter((type) => !defaultServiceTypes.includes(type))
    .map((type) => ({ key: type, title: startCase(type) }));

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const publicBookingEnabled = useSelector(
    (state: RootState) => state.resources?.resourceCentres[0]?.publicBooking || false
  );

  const isMobileScreen = useMobileScreen();

  const dropdownMenuItems = (
    <ButtonGroup
      color="primary"
      variant="contained"
      ref={anchorEl}
      aria-label="split button"
      style={{ marginLeft: "20px" }}
    >
      <Button
        onClick={() => {
          handleClose();
          actionsService.navigateTo(`/services/newProduct`, { filter });
        }}
        data-testmation="serviceListCreateProduct"
        disabled={!policies["services:createProduct"]}
      >
        {isMobileScreen ? "Create" : tl("services.createService")}
      </Button>
      <Button onClick={handleClick} size="small">
        <ArrowDropDownIcon />
      </Button>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        data-testmation="serviceListCreateDropdown"
      >
        <MenuItem
          onClick={() => {
            handleClose();
            actionsService.navigateTo(`/services/newPackage`);
          }}
          data-testmation="serviceListCreatePackage"
          disabled={!policies["services:createPackage"]}
        >
          {tl("services.createPackage")}
        </MenuItem>
        <MenuItem onClick={() => dispatch(push("/linkServicesAndLabTests"))}>
          {tl("services.linkServicesAndLabTests")}
        </MenuItem>
      </Menu>
    </ButtonGroup>
  );

  const filtersData = getFiltersForServiceType(otherExceptDefaultTypes);
  const multipleHeaders = {
    headers: [
      {
        key: "service",
        title: tl("services.services"),
        goto: "/services"
      },
      publicBookingEnabled && {
        key: "publicService",
        title: tl("services.publicServices"),
        goto: "/publicServices"
      },
      isSsfEnabled && {
        key: "socialSecurityFundService",
        title: tl("services.ssfServices"),
        goto: "/socialSecurityFundServices"
      }
    ],
    // eslint-disable-next-line no-nested-ternary
    url: isPublicTab ? "/publicServices" : isSsfTab ? "/socialSecurityFundServices" : "/services"
  };
  const departments = useGetDepartments();

  const departmentSubscription = useSelector(
    (state: RootState) =>
      state.userContext.resourceCentre?.subscriptions.features.department?.subscribed
  );

  const renderType = (item) => {
    if (isSsfTab) {
      return <Box>{item.type || ""}</Box>;
    }

    if (item.productType === "package") {
      return (
        <Box display="flex">
          {tl(`services.${item.productType}`)} <PackageIcon />
        </Box>
      );
    }

    return getCategory(item.category);
  };

  const columns = [
    {
      key: "name",
      label: tl("name"),
      sortable: true,
      sortFunction: (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
      formatter: (item) => (
        <Box display="flex" flexDirection="column">
          <Box>
            <Typography>{item.name}</Typography>
          </Box>
          {item.serviceProviderId && (
            <Box>
              <Typography>
                <Box component="span" fontSize="0.7rem">
                  {spFullNameSelector(spFromIdSelector(serviceProviders, item.serviceProviderId))}
                </Box>
              </Typography>
            </Box>
          )}
        </Box>
      )
    },
    {
      key: "productType",
      label: tl("type"),
      formatter: (item) => (
        <Box pr={1}>
          <Typography>{renderType(item)}</Typography>
        </Box>
      )
    },
    departmentSubscription && !isSsfTab
      ? {
          key: "department",
          label: tl("department"),
          formatter: (item) => (
            <Typography
              sx={{
                maxWidth: "100%",
                width: "100px"
              }}
            >
              {departments.find((department) => department.id === item.departmentId)?.name || ""}
            </Typography>
          )
        }
      : {
          key: "",
          label: ""
        },
    {
      key: "grossTotalPrice",
      label: tl("price"),
      formatter: (item) => (
        <Typography
          textAlign="right"
          sx={{
            maxWidth: "100%",
            width: "100px"
          }}
        >
          {tl("rs")}
          {item.grossTotalPrice}
        </Typography>
      )
    },
    {
      key: "date",
      label: isMobileScreen ? <>Created</> : tl("services.createdDate"),
      hideInNarrowView: true,
      sortable: true,
      sortFunction: (a, b) => (+new Date(a.created_at) < +new Date(b.created_at) ? 1 : -1),
      formatter: (item) => (
        <Box display="flex" flexDirection="column">
          <Typography>{moment(item.created_at).format("YYYY-MM-DD")}</Typography>
        </Box>
      )
    }
  ];

  if (isPublicTab) {
    columns.push({
      key: "status",
      label: <>Status</>,
      sortable: true,
      sortFunction: (a, b) =>
        (isServicePublic(a) ? a.referenceId : 0) < (isServicePublic(b) ? b.referenceId : 0)
          ? 1
          : -1,
      formatter: (item) => (
        <Box
          display="flex"
          flexDirection="column"
          sx={{
            backgroundColor: isServicePublic(item) ? "#009654" : "#e39130",
            padding: "5px 10px",
            minWidth: "50px",
            borderRadius: "20px",
            color: "#fff"
          }}
        >
          <Typography>
            {isServicePublic(item) ? PublicServiceStatus.PUBLIC : PublicServiceStatus.NOTPUBLIC}
          </Typography>
        </Box>
      )
    });
  }

  const onBulkServiceDelete = async () => {
    setIsDeletingServices(true);
    try {
      await batchDeleteServices(checkedServices);
      dispatch(
        addNotification({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Services deleted successfully",
          autoTimeout: true
        })
      );
      dispatch(getServices());
    } catch (e) {
      dispatch(
        addNotification({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: e.message || "Something went wrong",
          autoTimeout: true
        })
      );
    } finally {
      setIsDeletingServices(false);
      setOpenDeleteConfirmation(false);
    }
  };

  return (
    <Can policyAccessKey="services:listService">
      <div className="serviceList">
        <List
          testmationLabel="createNew"
          automation="ServiceList"
          createDropdownLabel={isMobileScreen ? "Create" : tl("services.createService")}
          hideCreateButton
          dropdownMenuItems={!isPublicTab && dropdownMenuItems}
          onDropdownClicked={handleClick}
          defaultSortColumn={isPublicTab ? "status" : "date"}
          additionalHeaderContent={
            <Filters filter={filter} onFilter={setFilter} filterData={filtersData} />
          }
          multiSelectable
          onMultiSelect={(selectedIds) => {
            setCheckedServices(selectedIds);
          }}
          multiSelectContextHeader={
            <Box display="flex" flex={1} alignItems="center" gap={2} flexDirection="row">
              <Typography variant="body2" color="textSecondary">
                {`${checkedServices.length} Selected`}
              </Typography>
              <Button
                color="error"
                variant="outlined"
                size="small"
                onClick={() => setOpenDeleteConfirmation(true)}
              >
                Deleted selected Services
              </Button>
            </Box>
          }
          multipleHeaders={multipleHeaders}
          isResponsive
          columns={columns}
          data={serviceList}
          isLoading={false}
          data-testmation="serviceListRows"
          activeRow={selectedRow && selectedRow.id}
          onRowClick={(row) => {
            const unselect = selectedRow && selectedRow.id === row.id;
            setSelectedRow(unselect ? null : row);

            // eslint-disable-next-line no-nested-ternary
            const pathName = isPublicTab
              ? "publicServices"
              : isSsfTab
              ? "socialSecurityFundServices"
              : "services";
            if (row.productType === "package") {
              actionsService.navigateTo(
                unselect ? `/${pathName}` : `/${pathName}/package/${row.id}`
              );
            } else {
              actionsService.navigateTo(
                unselect ? `/${pathName}` : `/${pathName}/product/${row.id}`
              );
            }
          }}
        >
          <ListActions>
            {({ getProcessedData }) => (
              <ListMenu>
                <Can policyAccessKey="services:importCSV">
                  <CsvUploader
                    buttonText="Upload services (.csv)"
                    columns={serviceImportColumns}
                    requiredFieldInfoText={requiredFieldInfoText}
                    createDataApi={batchUploadService}
                    useBatchUpload
                    runAfterSave={() => dispatch(getServices())}
                    renderAdditionalInfo={() => (
                      <Typography
                        style={{
                          cursor: "pointer",
                          position: "fixed",
                          right: 20
                        }}
                      >
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <Link onClick={downloadServiceUploadExample}>{tl("stock.viewSample")}</Link>
                      </Typography>
                    )}
                  />
                  <ListMenuItem onClick={downloadServiceUploadExample}>
                    {tl("stock.viewSample")}
                  </ListMenuItem>
                  <ListMenuItem
                    onClick={async () => {
                      try {
                        await downloadExcel(
                          "Service List",
                          formatHeaderForExcel(docColumns()),
                          formatDataForExcel(getProcessedData(), docRowProcessor)
                        );
                      } catch (err) {
                        dispatch(
                          notificationAdd({
                            id: new Date().getUTCMilliseconds(),
                            variant: "error",
                            message: "Failed to download Excel.",
                            autoTimeout: true
                          })
                        );
                      }
                    }}
                  >
                    {tl("reports.excel")}
                  </ListMenuItem>
                </Can>
              </ListMenu>
            )}
          </ListActions>
        </List>
        <OkhatiDialog
          open={openDeleteConfirmation}
          cancel={() => setOpenDeleteConfirmation(false)}
          next={onBulkServiceDelete}
          title="Are you sure ?"
          description={
            <Box display="flex" gap={2} flexDirection="column">
              <Typography
                gutterBottom
              >{`Are you sure you want to delete ${checkedServices.length} services ?`}</Typography>
              <Box maxHeight={100} overflow="auto">
                {getServiceNamesByIds(serviceList, checkedServices).map((name) => (
                  <Typography key={name}>{name}</Typography>
                ))}
              </Box>
            </Box>
          }
          isLoading={isDeletingServices}
          promptBeforeSave
          promptComparisonValue="delete"
          readMode={false}
        />
      </div>
    </Can>
  );
};

export default connect(
  (state: RootState) => ({
    services: servicesSortedSelector(state),
    serviceProviders: state.resources.resourceCentreServiceProviders,
    policies: state.userContext.policies
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      loadServiceProviders: (id) =>
        dispatch(actions.getResourceCentreServiceProviders({ resourceCentreId: id })),
      loadServices: () => dispatch(getServices()),
      navigateTo: (url, params?) =>
        dispatch(
          push({
            pathname: url,
            state: params
          })
        )
    }
  })
)(ServiceList);
