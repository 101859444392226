import produce from "immer";
import React, { JSX } from "react";
import { LoadingButton } from "@mui/lab";
import { useDispatch } from "react-redux";
import { Box, Button, Typography } from "@mui/material";

import { tl } from "../../../components/translate";
import VaccinationRow from "./VaccinationRow";
import { Vaccination } from "../../../interfaces/AssessmentInterfaces";
import { notificationAdd } from "../../../actions/notification";

export enum VaccinationMode {
  ADD = "ADD",
  UPDATE = "UPDATE"
}

interface Props {
  onChange: (data) => void;
  vaccinations: Array<Vaccination> | [];
  mode?: VaccinationMode;
  onVaccinationUpdate?: (data) => void;
}

export default function AddUpdateMedicalVaccination({
  onChange,
  vaccinations,
  mode = VaccinationMode.ADD,
  onVaccinationUpdate
}: Props): JSX.Element {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = React.useState(false);

  const handleUpdateVaccination = async () => {
    try {
      setIsUpdating(true);
      const filteredVaccinations = vaccinations
        .filter((v) => v.name !== "" && v.yesno)
        .filter((v, index, self) => self.findIndex((t) => t.name === v.name) === index);
      await onVaccinationUpdate?.(filteredVaccinations);
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err?.message || err?.data?.message || "Failed to update vaccinations",
          autoTimeout: true
        })
      );
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Box sx={{ width: "100%", marginTop: "20px" }}>
      <Box sx={{ width: "100%", display: "flex", gap: "10px", justifyContent: "space-between" }}>
        <Box sx={{ width: "40%" }}>
          <Typography sx={{ fontWeight: 600 }}>{tl("assessment.vaccination")}</Typography>
        </Box>
        <Box sx={{ width: "20%" }}>
          <Typography sx={{ fontWeight: 600 }}>{tl("assessment.yesno")}</Typography>
        </Box>
        <Box sx={{ width: "30%" }}>
          <Typography sx={{ fontWeight: 600 }}>{tl("assessment.date")}</Typography>
        </Box>
        <Box sx={{ width: "10%" }} />
      </Box>

      <Box sx={{ width: "100%" }}>
        {vaccinations.map((item, index) => (
          <VaccinationRow
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            vaccination={item}
            onChange={(newObj) => {
              const newArr = produce(vaccinations, (draft) => {
                draft[index] = newObj;
              });
              onChange(newArr);
            }}
            onRemove={() => {
              const newArr = produce(vaccinations, (draft) => {
                draft.splice(index, 1);
              });
              onChange(newArr);
            }}
          />
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: mode === VaccinationMode.UPDATE ? "10px" : "0px"
        }}
      >
        <Button
          disabled={mode === VaccinationMode.UPDATE && isUpdating}
          onClick={() => {
            onChange([
              ...vaccinations,
              {
                name: "",
                yesno: true,
                date: new Date().toISOString().split("T")[0]
              }
            ]);
          }}
        >
          {tl("assessment.addNew")}
        </Button>

        {mode === VaccinationMode.UPDATE && (
          <LoadingButton
            variant="contained"
            color="primary"
            disabled={isUpdating}
            loading={isUpdating}
            onClick={handleUpdateVaccination}
            sx={{ marginRight: "10%" }}
          >
            {tl("assessment.save")}
          </LoadingButton>
        )}
      </Box>
    </Box>
  );
}
