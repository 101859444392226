import * as React from "react";
import { connect, useDispatch } from "react-redux";
import { startCase } from "lodash";
import { Box, TextField, Typography, Autocomplete } from "@mui/material";
import { t, tl } from "../../../components/translate";
import { fields } from "../../../models/Referrer";
import Form from "../../../components/Form";
import { postReferrer } from "../../../actions/referrers";
import Modal from "../../../components/Modal/Modal";
import { navigateRemoveModal } from "../../../actions/navigation";
import { Referrer } from "../../../interfaces/ReferrerInterface";
import { TransactionType, SubLedgerTypes } from "../../../interfaces/Accounts";
import VendorMapping from "../../../components/VendorMapping";
import { ledgerFindBySubLedgerType } from "../../accounts/hooks";
import ToggleInputDate from "../../../components/ToggleADBS";
import { bsDateField } from "../../../models/Accounts";
import { isISODateString } from "../../../helpers/validators";
import useIsAccountSubscribed from "../../../hooks/accounts";

const { useState } = React;

interface Props {
  onClose: () => void;
  next: (data?) => void;
  defaultData: Partial<Referrer>;
}

function ReferrerCreateModal({ onClose, next, defaultData }: Props) {
  const [form, setForm] = useState<Partial<Referrer>>(defaultData);
  const [errorsCount, setErrorsCount] = useState(1);
  const [saveAttempted, setSaveAttempted] = useState(false);

  const dispatch = useDispatch();

  const isAccountSubscribed = useIsAccountSubscribed();

  const referrerLedger = ledgerFindBySubLedgerType(SubLedgerTypes.REFERRER);

  React.useEffect(() => {
    if (isAccountSubscribed && referrerLedger) {
      setForm({ ...form, ledgerId: referrerLedger.id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referrerLedger, isAccountSubscribed]);

  return (
    <Modal
      open
      title="Create Referrer"
      onSave={async () => {
        setSaveAttempted(true);
        if (!errorsCount) {
          await dispatch(postReferrer(form, (response) => next(response)));
          onClose();
        }
      }}
      onClose={onClose}
    >
      <Form
        fields={fields}
        data={defaultData}
        onChange={(formData, _, errorCount) => {
          setForm({ ...form, ...formData });
          setErrorsCount(errorCount);
        }}
        showErrors={saveAttempted}
      />
      {isAccountSubscribed && (
        <>
          <Typography m="10px 0">{tl("accounts.accountInformation")}</Typography>
          <Box display="flex" justifyContent="space-between" flexDirection="column" gap={1.5}>
            {referrerLedger && (
              <VendorMapping
                subLedgerType={SubLedgerTypes.REFERRER}
                onChange={(value) => {
                  if (value) {
                    setForm({ ...form, ledgerId: value.id });
                  }
                }}
                id={form.ledgerId}
              />
            )}
            <TextField
              label={tl("openingBalance")}
              margin="dense"
              variant="outlined"
              data-testmation="referrerOpeningBalance"
              fullWidth
              type="number"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              value={form?.openingBalance}
              onChange={(e) => setForm({ ...form, openingBalance: Number(e.target.value) })}
            />
            <ToggleInputDate
              key="openingBalanceDate"
              field={{
                ...bsDateField,
                key: "openingBalanceDate",
                label: `${t("openingBalanceDate")}`
              }}
              data={{ openingBalanceDate: form?.openingBalanceDate }}
              isFocused={() => ({})}
              isBlurred={() => ({})}
              showAgeField={false}
              changeDate={(value) => {
                if (isISODateString(value)) {
                  setForm({ ...form, openingBalanceDate: value });
                } else {
                  setForm({ ...form, openingBalanceDate: null });
                }
              }}
            />
            <Autocomplete
              options={[TransactionType.DEBIT, TransactionType.CREDIT]}
              onChange={(e, value) => {
                setForm({ ...form, transactionType: value as TransactionType });
              }}
              getOptionLabel={(option) => startCase(option)}
              renderInput={(params) => (
                <TextField
                  //   eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  variant="outlined"
                  label={tl("transactionType")}
                  slotProps={{
                    inputLabel: { shrink: true }
                  }}
                />
              )}
            />
          </Box>
        </>
      )}
    </Modal>
  );
}

const mapDispatchToProps = (dispatch) => ({
  onClose: () => dispatch(navigateRemoveModal("ReferrerCreateModal"))
});

export default connect(null, mapDispatchToProps)(ReferrerCreateModal);
