import React from "react";
import InfoIcon from "@mui/icons-material/Info";
import moment from "moment";

import startCase from "lodash/startCase";
import { Typography, Box, Chip, Tooltip } from "@mui/material";
import { spFullNameSelector } from "../../../reducers/serviceProvider";
import { Booking } from "../../../interfaces/BookingInterfaces";
import { StatusBackgroundMap } from "../../Dashboard/FlowItem";
import { BookingStatus } from "../../Bookings/ManageBooking";
import useGetBookedServices from "../../../hooks/useGetBookedServices";
import { GROUP_BOOKINGS_BY, GroupBookingsByType, GroupedBooking } from "./index";

interface ActiveBookingsProps {
  bookings: Booking[];
  setClientId: (id: number) => void;
  setShowClientInfoPanel: (show: boolean) => void;
  setBooking: (booking: Booking) => void;
  handleClick: (e) => void;
  groupedBookings: GroupedBooking[];
  groupBookingsBy: GroupBookingsByType;
}

interface Props {
  booking: Booking;
  setClientId: (id: number) => void;
  setShowClientInfoPanel: (show: boolean) => void;
  setBooking: (booking: Booking) => void;
  handleClick: (event) => void;
  showBookableResource: boolean;
}

const ActiveBookingDetail = ({
  setClientId,
  setShowClientInfoPanel,
  setBooking,
  booking,
  handleClick,
  showBookableResource
}: Props) => {
  const { start, serviceProvider, client, status, services, remarks } = booking;
  const bookedServices = useGetBookedServices(services);
  const isReservedStatus = status === BookingStatus.reserved;
  const styleProps = {
    bgColor: StatusBackgroundMap[status],
    color: isReservedStatus ? "#000000" : "#FFFFFF"
  };

  return (
    <Box
      sx={{
        padding: "14px 0",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        borderBottom: "1px solid  rgba(90, 96, 127, 0.25)",
        cursor: "pointer",
        "&:hover": {
          backgroundColor: "#F1FFF3"
        },
        "&:last-child": {
          borderBottom: "none"
        }
      }}
      onClick={(e) => {
        setBooking(booking);
        handleClick(e);
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "4px",
          width: "20%"
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1
          }}
        >
          <Typography sx={{ textTransform: "uppercase" }}>
            {moment(start).format("hh:mm a")}
          </Typography>

          {remarks && (
            <Tooltip title={remarks} placement="top">
              <InfoIcon sx={{ color: "#676766" }} />
            </Tooltip>
          )}

          {booking.followUp && (
            <Tooltip title="Follow up" placement="top">
              <Box
                sx={{
                  height: "16px",
                  width: "16px",
                  borderRadius: "100%",
                  backgroundColor: "#EAB308"
                }}
              />
            </Tooltip>
          )}
        </Box>

        <Box
          sx={{
            px: "4px",
            py: "2px",
            borderRadius: "50px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: styleProps.bgColor,
            color: styleProps.color,
            border: booking.status === BookingStatus.reserved ? "1px solid black" : ""
          }}
        >
          <Chip
            size="small"
            sx={{
              backgroundColor: "transparent",
              border: "none",
              color: booking.status === BookingStatus.reserved ? "black" : "white"
            }}
            label={startCase(status || "")}
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "4px",
          width: "50%"
        }}
      >
        {booking.bookableResource && showBookableResource && (
          <Typography sx={{ width: "100%", fontWidth: 400, fontSize: "12px", textAlign: "start" }}>
            {booking.bookableResource.name}
          </Typography>
        )}
        <Typography
          sx={{
            width: "100%",
            fontWidth: 400,
            fontSize: "12px",
            textAlign: "start"
          }}
        >
          {bookedServices.map((service) => service.name).join(", ")}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-end"
        }}
      >
        <Typography sx={{ fontWeight: 700 }}>
          {serviceProvider ? spFullNameSelector(serviceProvider) : booking.bookableResource?.name}
        </Typography>
        <Box
          onClick={(e) => {
            e.stopPropagation();
            setClientId(client?.id);
            setShowClientInfoPanel(true);
          }}
        >
          <Typography
            sx={{
              cursor: "pointer",
              fontSize: "16px",
              lineHeight: "24px",
              textTransform: "capitalize",
              "&:hover": {
                textDecoration: "underline"
              }
            }}
          >
            {`${client?.firstName} ${client?.lastName}`.toUpperCase()}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const ActiveBookings = ({
  bookings,
  setClientId,
  setShowClientInfoPanel,
  setBooking,
  handleClick,
  groupedBookings,
  groupBookingsBy
}: ActiveBookingsProps): React.ReactElement => (
  <Box
    sx={{
      fontWeight: 500,
      fontSize: "12px",
      lineHeight: "18px"
    }}
  >
    {groupBookingsBy === GROUP_BOOKINGS_BY.NONE ? (
      <>
        {bookings?.map((booking) => (
          <ActiveBookingDetail
            showBookableResource
            key={booking.id}
            booking={booking}
            setClientId={setClientId}
            setShowClientInfoPanel={setShowClientInfoPanel}
            setBooking={setBooking}
            handleClick={handleClick}
          />
        ))}
      </>
    ) : (
      <>
        {groupedBookings.map((item) => (
          <React.Fragment key={item.groupName}>
            <Typography sx={{ backgroundColor: "#efefef", p: 1, my: 1, borderRadius: 1 }}>
              {startCase(item.groupName)}
            </Typography>
            {item.bookings.map((booking) => (
              <ActiveBookingDetail
                showBookableResource={groupBookingsBy === GROUP_BOOKINGS_BY.SERVICE_PROVIDER}
                key={booking.id}
                booking={booking}
                setClientId={setClientId}
                setShowClientInfoPanel={setShowClientInfoPanel}
                setBooking={setBooking}
                handleClick={handleClick}
              />
            ))}
          </React.Fragment>
        ))}
      </>
    )}
  </Box>
);

export default ActiveBookings;
