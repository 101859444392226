import { CanActions } from "./policy";
import { ResourceCentre } from "./ResourceCentreInterface";
import { PermissionGroups, UserMode } from "./User";

export interface UserContext {
  user: User;
  userCreds: UserCreds;
  resourceCentre: ResourceCentre;
  policies: Partial<CanActions | { terminated: true }>;
  resourceCentreId: number;
  mode: UserMode;
}

export interface User {
  id: number;
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  resourceCentreId: number;
  /* eslint-disable camelcase */
  created_at: Date;
  updated_at: Date;
  /* eslint-enable */
  active: boolean;
  signature: string;
  signatureId: number;
  designation: string;
}

export enum userViewMode {
  DEFAULT = "Default",
  PHARMACY = "Pharmacy"
}

export interface UserCreds {
  id: number;
  username: string;
  name: string;
  email: string;
  userGroupDescription: string;
  profileImageRef: null;
  authenticableId: number;
  authenticable: UserMode;
  tempPassword: null | string;
  profileImage: { s3ResourceURL: string; downloadLink: string } | null;
  userGroups: Array<PermissionGroups>;
  userPreferences: {
    viewMode: viewMode.DEFAULT | viewMode.PHARMACY;
    lookAndFeel: { primaryColour: string };
  };
}
