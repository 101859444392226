import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import { startCase } from "lodash";
import { push } from "connected-react-router";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import SmsIcon from "@mui/icons-material/Sms";
import { useParams } from "react-router";
import TimePicker from "react-time-picker";
import produce from "immer";
import IconButton from "@mui/material/IconButton";
import { serviceProviderActions as actions, serviceProviderActions } from "../../actions";
import { spFullNameSelector } from "../../reducers/serviceProvider";
import { tl, t } from "../../components/translate";
import { showDialog, navigateRemoveModal } from "../../actions/navigation";
import { resetPasswordFor } from "../../actions/user";
import Panel from "../../components/Panel";
import styles from "./ServiceProvider.module.css";
import { getSpeciality } from "../../reducers/serviceTags";
import Can from "../Policy/Can";
import { IThunkDispatch, RootState } from "../../store";
import { ServiceProvider } from "../../interfaces/ServiceProvidersInterface";
import { IServiceTag } from "../../interfaces/ServiceTagsInterface";
import { DefaultServiceProviders, UserGroups } from "../ResourceCentre/UserSettings/SPCreateEdit";
import MessageCreate, { MESSAGE_TYPE } from "../Campaign/InstantMessage/MessageCreate";
import ServiceProviderTransactions from "./ServiceProviderTransactions";
import { infoIconColor } from "../Lab/LabTestCreate/AddLabtests";
import { AdminPermissionGroup } from "../../interfaces/User";
import { updateEmployeeTwoFactorAuth } from "../../api/user";
import { notificationAdd } from "../../actions/notification";
import { commonErrorMessage } from "../../helpers/messages";
import dummy from "../../../assets/images/dummyProfile.png";
import { User } from "../../interfaces/UserContext";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";

const DEFAULT_SCHEDULE = {
  holidays: [],
  weekDays: [
    {
      name: "Sunday",
      hours: [{ start: "10:00", end: "14:00" }],
      day: 0,
      isAvailable: true
    },
    {
      name: "Monday",
      hours: [{ start: "10:00", end: "14:00" }],
      day: 1,
      isAvailable: true
    },
    {
      name: "Tuesday",
      hours: [{ start: "10:00", end: "14:00" }],
      day: 2,
      isAvailable: true
    },
    {
      name: "Wednesday",
      hours: [{ start: "10:00", end: "14:00" }],
      day: 3,
      isAvailable: true
    },
    {
      name: "Thursday",
      hours: [{ start: "10:00", end: "14:00" }],
      day: 4,
      isAvailable: true
    },
    {
      name: "Friday",
      hours: [{ start: "10:00", end: "14:00" }],
      day: 5,
      isAvailable: true
    },
    {
      name: "Saturday",
      hours: [{ start: "10:00", end: "14:00" }],
      day: 6,
      isAvailable: false
    }
  ]
};

interface ServiceProviderShowProps {
  serviceProviderId: number;
  serviceProvider: ServiceProvider;
  loadServiceProvider: (id: number) => void;
  navigateTo: (url: string) => void;
  onDeleteServiceProvider: (serviceProvider: ServiceProvider) => void;
  onResetPassword: (serviceProvider: ServiceProvider) => void;
  permissionGroup: string;
  user: User;
  handleViewClose: () => void;
  setServiceProvider: (serviceProvider: ServiceProvider) => void;
  serviceTags: IServiceTag[];
}

export const displayUserGroup = (userGroup: string): JSX.Element | string => {
  if (Object.values(DefaultServiceProviders).includes(userGroup as DefaultServiceProviders)) {
    return tl(userGroup);
  }
  return startCase(userGroup);
};

export const canUseSpecialities = (userGroup: string, specialities: Array<number>): boolean => {
  if (userGroup === UserGroups.ServiceProviderFull && specialities?.length) {
    return true;
  }
  return false;
};

export const ServiceProviderShow = ({
  serviceProviderId,
  serviceProvider,
  loadServiceProvider,
  navigateTo,
  onDeleteServiceProvider,
  onResetPassword,
  permissionGroup,
  user,
  handleViewClose,
  setServiceProvider,
  serviceTags
}: ServiceProviderShowProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const [showSignature, setShowSignature] = React.useState(false);
  const [messagePanel, setMessagePanel] = React.useState({ show: false, type: MESSAGE_TYPE.SMS });
  const resourceCentrePermissionGroups =
    useSelector((state: RootState) => state.resourceCentreUserGroups.collection) || [];
  const params = useParams<{ id: string }>();

  const [schedule, setSchedule] = React.useState(serviceProvider?.schedule);

  const rcPublicBookingStatus = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres.find((item: ResourceCentre) => item.id === Number(params.id))
        ?.publicBooking
  );
  React.useEffect(() => {
    loadServiceProvider(serviceProviderId);
  }, [serviceProviderId, loadServiceProvider]);

  React.useEffect(() => {
    if (serviceProvider) {
      setSchedule(serviceProvider.schedule || DEFAULT_SCHEDULE);
    }
  }, [serviceProvider]);

  if (!serviceProvider) return null;

  const { user: serviceProviderUser } = serviceProvider;
  const userGroup = serviceProviderUser?.userGroups[0]?.name || "";
  const canAdminServiceProvider =
    AdminPermissionGroup.includes(permissionGroup) || user.id === serviceProvider.id;

  let editButton = null;
  let deleteButton = null;
  let resetPassButton = null;

  const canUpdateSpSignature = ["resourceCentreAdmin"].includes(permissionGroup);

  if (canAdminServiceProvider) {
    editButton = (
      <Can policyAccessKey="doctors:editDoctors" superAdminPass>
        <EditIcon
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigateTo(
              `/resourcecentres/${serviceProvider.resourceCentreId}/serviceProviders/${serviceProvider.id}/edit`
            )
          }
        />
      </Can>
    );
  }

  if (["resourceCentreAdmin", "superAdmin"].includes(permissionGroup)) {
    resetPassButton = (
      <Can policyAccessKey="doctors:resetPassword" superAdminPass>
        <Button
          size="small"
          onClick={() => onResetPassword(serviceProvider)}
          data-testmation="resetPassword"
        >
          {tl("resetPassword.title")}
        </Button>
      </Can>
    );
  }

  const isAdmin = ["superAdmin", "resourceCentreAdmin"].includes(permissionGroup);

  if (isAdmin) {
    deleteButton = (
      <Can policyAccessKey="doctors:deleteDoctor" superAdminPass>
        <DeleteIcon
          style={{ cursor: "pointer" }}
          onClick={() => onDeleteServiceProvider(serviceProvider)}
        />
      </Can>
    );
  }

  const userGroupDescription = resourceCentrePermissionGroups.find(
    (permission) => permission.name === userGroup
  )?.description;

  const twoFactorAuthHandler = async (enforcedTwoFactorAuth) => {
    try {
      await updateEmployeeTwoFactorAuth({
        id: serviceProvider.user.id,
        enforcedTwoFactorAuth,
        rcId: serviceProvider.resourceCentreId
      });
      dispatch(actions.getResourceCentreServiceProvider(serviceProvider.id));
    } catch (error) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: commonErrorMessage,
          variant: "error",
          autoTimeout: true
        })
      );
    }
  };

  return (
    <Panel
      onClose={handleViewClose}
      editButton={editButton}
      deleteButton={deleteButton}
      genericButton={resetPassButton}
    >
      <Box pl={5} pr={5} width="100%" className={styles.boxHeight}>
        <Box display="flex" alignItems="center">
          <Typography variant="h6" gutterBottom>
            {spFullNameSelector(serviceProvider)}
          </Typography>
          <Box ml={2}>
            <img
              src={serviceProvider?.serviceProviderDetails?.profileImage || dummy}
              style={{
                height: "64px",
                width: "64px",
                borderRadius: "50%",
                border: "2px solid grey"
              }}
              alt="profile"
            />
          </Box>
        </Box>
        <Box mt={2} mb={1} />
        {serviceProvider.user && (
          <Grid container>
            <Grid size={4}>
              <Typography variant="body1">{tl("employee.userGroup")}</Typography>
            </Grid>
            <Grid size={8} display="flex" alignItems="center">
              <Typography variant="body2">{displayUserGroup(userGroup)}</Typography>
              {userGroupDescription && (
                <Tooltip title={userGroupDescription}>
                  <InfoIcon
                    sx={{
                      color: infoIconColor,
                      marginLeft: "5px"
                    }}
                  />
                </Tooltip>
              )}
            </Grid>
            <Grid size={4}>
              <Typography variant="body1">{tl("employee.username")}</Typography>
            </Grid>
            <Grid size={8}>
              <Typography variant="body2">{serviceProvider.user.username}</Typography>
            </Grid>
            {canAdminServiceProvider && !!serviceProvider.user.tempPassword && (
              <>
                <Grid size={4}>
                  <Typography variant="body1">{tl("employee.tempPassword")}</Typography>
                </Grid>
                <Grid size={8}>
                  <Typography variant="body2">{serviceProvider.user.tempPassword}</Typography>
                </Grid>
              </>
            )}
            {["email", "phone"].map((attr) => (
              <>
                <Grid size={4}>
                  <Typography variant="body1">{tl(attr)}</Typography>
                </Grid>
                <Grid size={8}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: 1,
                      alignItems: "center"
                    }}
                  >
                    <Typography variant="body2">{serviceProvider[attr]}</Typography>
                    {attr === "email" && serviceProvider[attr] && (
                      <Tooltip title="Send Email">
                        <ForwardToInboxIcon
                          sx={{ cursor: "pointer", width: "0.9rem" }}
                          onClick={() => {
                            setMessagePanel((prevState) => ({
                              show: !prevState.show,
                              type: MESSAGE_TYPE.EMAIL
                            }));
                          }}
                        />
                      </Tooltip>
                    )}
                    {attr === "phone" && serviceProvider[attr] && (
                      <Tooltip title="Send SMS">
                        <SmsIcon
                          sx={{ cursor: "pointer", width: "0.9rem" }}
                          onClick={() => {
                            setMessagePanel((prevState) => ({
                              show: !prevState.show,
                              type: MESSAGE_TYPE.SMS
                            }));
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                </Grid>
              </>
            ))}
            <>
              <Grid size={4} mt={2}>
                <Typography variant="body1">{tl("speciality")}</Typography>
              </Grid>
              <Grid size={8}>
                <Typography variant="body2">
                  {canUseSpecialities(userGroup, serviceProvider.specialities)
                    ? getSpeciality(serviceTags, serviceProvider.specialities[0])
                    : serviceProvider.speciality}
                </Typography>
              </Grid>
            </>
            <Grid size={4}>
              <Typography variant="body1">{tl("service.tags")}</Typography>
            </Grid>
            <Grid size={8}>
              <Typography variant="body2">
                {serviceProvider.serviceTagIds?.map((id, i) => (
                  <span key={id}>
                    {i !== 0 ? ", " : ""}
                    {serviceTags.find((item) => item.id === id)?.tag || ""}
                  </span>
                ))}
              </Typography>
            </Grid>
            {["registrationNumber", "qualification"].map((attr) => (
              <>
                <Grid size={4}>
                  <Typography variant="body1">{tl(attr)}</Typography>
                </Grid>
                <Grid size={8}>
                  <Typography variant="body2">
                    {attr === "role" ? displayUserGroup(userGroup) : serviceProvider[attr]}
                  </Typography>
                </Grid>
              </>
            ))}
            {isAdmin && (
              <>
                <Grid size={4} style={{ marginTop: "8px" }}>
                  <Typography style={{ width: "70%" }}>{tl("2FAuthentication")}</Typography>
                </Grid>
                <Grid size={8} style={{ marginLeft: "-8px" }}>
                  <Checkbox
                    data-testmation="publicBookingCheckSpShow"
                    checked={serviceProvider.user?.enforcedTwoFactorAuth}
                    onChange={({ target }) => {
                      twoFactorAuthHandler(target.checked);
                    }}
                  />
                </Grid>
              </>
            )}
            {rcPublicBookingStatus && (
              <Can policyAccessKey="doctors:editPublicBookingSetting" superAdminPass>
                <>
                  <Grid size={4} style={{ marginTop: "8px" }}>
                    <Typography style={{ width: "50%" }}>{tl("publicBooking")}</Typography>
                  </Grid>
                  <Grid size={8} style={{ marginLeft: "-8px" }}>
                    <Checkbox
                      data-testmation="publicBookingCheckSpShow"
                      checked={serviceProvider.publicBooking}
                      onChange={() => {
                        setServiceProvider({
                          ...serviceProvider,
                          publicBooking: !serviceProvider.publicBooking
                        });
                      }}
                    />
                  </Grid>
                </>
              </Can>
            )}
          </Grid>
        )}
        {rcPublicBookingStatus && serviceProvider.publicBooking && (
          <Box mt={2}>
            <Typography gutterBottom>Available hours</Typography>
            <TableContainer component={Paper}>
              <Table sx={{ minWidth: 500 }} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>Day</TableCell>
                    <TableCell>Is available</TableCell>
                    <TableCell align="left">Start time</TableCell>
                    <TableCell align="right">End time</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schedule?.weekDays.map((row, dayIndex) => (
                    <TableRow
                      key={row.name}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell component="th" scope="row" sx={{ verticalAlign: "top" }}>
                        {row.name}
                      </TableCell>
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <Checkbox
                          checked={row.isAvailable}
                          onChange={(_, checked) => {
                            const updatedSchedule = produce(schedule, (draft) => {
                              draft.weekDays[dayIndex].isAvailable = checked;
                            });
                            setSchedule(updatedSchedule);
                          }}
                        />
                      </TableCell>
                      <TableCell colSpan={2}>
                        <Stack gap={0.5}>
                          {row.hours.map((item, hourIndex) => (
                            <Box display="flex" justifyContent="space-between" key={item.start}>
                              <div>
                                <TimePicker
                                  value={item.start}
                                  onChange={(v) => {
                                    const updatedSchedule = produce(schedule, (draft) => {
                                      draft.weekDays[dayIndex].hours[hourIndex].start = v as string;
                                    });
                                    setSchedule(updatedSchedule);
                                  }}
                                  disableClock
                                  clearIcon={null}
                                />
                              </div>
                              <div>
                                <TimePicker
                                  value={item.end}
                                  onChange={(v) => {
                                    const updatedSchedule = produce(schedule, (draft) => {
                                      draft.weekDays[dayIndex].hours[hourIndex].end = v as string;
                                    });
                                    setSchedule(updatedSchedule);
                                  }}
                                  disableClock
                                  clearIcon={null}
                                />
                                {row.hours.length > 1 && (
                                  <Tooltip title="Remove hour" arrow>
                                    <IconButton
                                      size="small"
                                      color="error"
                                      onClick={() => {
                                        const updatedSchedule = produce(schedule, (draft) => {
                                          draft.weekDays[dayIndex].hours = row.hours.filter(
                                            (_, index) => index !== hourIndex
                                          );
                                        });
                                        setSchedule(updatedSchedule);
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Tooltip>
                                )}
                              </div>
                            </Box>
                          ))}
                        </Stack>
                        <Box display="flex" justifyContent="flex-end">
                          <Tooltip title="Add hours" arrow>
                            <IconButton
                              size="small"
                              onClick={() => {
                                const updatedSchedule = produce(schedule, (draft) => {
                                  draft.weekDays[dayIndex].hours.push({
                                    start: "10:00",
                                    end: "14:00"
                                  });
                                });
                                setSchedule(updatedSchedule);
                              }}
                            >
                              <AddIcon />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={4}>
                      <Box display="flex" justifyContent="flex-end">
                        <Button
                          variant="contained"
                          onClick={() => {
                            setServiceProvider({
                              ...serviceProvider,
                              schedule
                            });
                            dispatch(
                              notificationAdd({
                                id: new Date().getUTCMilliseconds(),
                                variant: "success",
                                message: "Schedule updated.",
                                autoTimeout: true
                              })
                            );
                          }}
                        >
                          Save
                        </Button>
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        )}{" "}
        {canUpdateSpSignature && serviceProvider.signature && (
          <>
            <Button
              onClick={() => setShowSignature((prevState) => !prevState)}
              sx={{ my: 1 }}
              endIcon={showSignature ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            >
              {tl(showSignature ? "signature.hideSignature" : "signature.showSignature")}
            </Button>
            {showSignature && (
              <div>
                <Box
                  component="img"
                  sx={{
                    height: "120px",
                    width: "220px",
                    borderRadius: "8px",
                    border: "1px solid #d3d3d3",
                    objectFit: "contain"
                  }}
                  src={serviceProvider.signature}
                  alt=""
                />
              </div>
            )}
          </>
        )}
        <Divider sx={{ mt: 1 }} />
        <Can policyAccessKey="account:listAccount">
          <ServiceProviderTransactions serviceProvider={serviceProvider} />
        </Can>
      </Box>
      {messagePanel.show && (
        <MessageCreate
          onCancel={() => {
            setMessagePanel((prevState) => ({ ...prevState, show: false }));
          }}
          client={serviceProvider}
          messageType={messagePanel.type}
        />
      )}
    </Panel>
  );
};

export default connect(
  (
    { userContext, resources, bookableResources }: RootState,
    { serviceProviderId }: { serviceProviderId: number }
  ) => ({
    user: { ...userContext.user, role: userContext.mode },
    serviceProvider: resources.resourceCentreServiceProviders.find(
      (sp) => sp.id === serviceProviderId
    ),
    permissionGroup: userContext?.userCreds?.userGroups[0],
    serviceTags: bookableResources.serviceTags
  }),
  (dispatch: IThunkDispatch) => ({
    setServiceProvider: (data) => {
      const dataWithProfileImage = {
        ...data,
        profileImage: data.serviceProviderDetails?.profileImage,
        profileKey: data.serviceProviderDetails?.profileKey,
        blurHash: data.serviceProviderDetails?.blurHash
      };
      dispatch(serviceProviderActions.putResourceCentreServiceProvider(dataWithProfileImage));
    },
    loadServiceProvider: (id) => dispatch(actions.getResourceCentreServiceProvider(id)),
    navigateTo: (url) => dispatch(push(url)),
    onDeleteServiceProvider: (serviceProvider) => {
      dispatch(
        showDialog({
          title: "Are you sure about deleting?",
          description: `${serviceProvider.firstName} ${serviceProvider.lastName}`,
          next: () => {
            dispatch(actions.deleteResourceCentreServiceProvider(serviceProvider.id));
            dispatch(navigateRemoveModal("Dialog"));
          },
          onCancel: () => dispatch(navigateRemoveModal)
        })
      );
    },
    onResetPassword: (serviceProvider) => {
      dispatch(
        showDialog({
          title: tl("resetPassword.title"),
          description: `${t("resetPassword.message")} ${serviceProvider.firstName} ${
            serviceProvider.lastName
          }?`,
          next: async () => {
            await dispatch(resetPasswordFor(serviceProvider.user.id));
            dispatch(navigateRemoveModal("Dialog"));
            await dispatch(actions.getResourceCentreServiceProvider(serviceProvider.id));
          },
          onCancel: () => dispatch(navigateRemoveModal)
        })
      );
    }
  })
)(ServiceProviderShow);
