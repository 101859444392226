import React, { JSX } from "react";
import { capitalize } from "lodash";
import * as moment from "moment-timezone";
import { Box, Typography } from "@mui/material";
import { clientFullNameSelector } from "../../../reducers/client";
import { separateByComma } from "../../../helpers/formatters";
import { tl } from "../../../components/translate";
import { Client } from "../../../interfaces/ClientInterface";
import hasOwnProperty from "../../../helpers/object";
import { showDOBAndGender } from "./EightyMmPrint/ClientInfo";
import { getCustomerNumber } from "../../Client/ClientList";
/* eslint-disable react/require-default-props */

export enum ForceTemplate {
  BANK_PAPER = "bankPaper"
}

const DEFAULT_FONT_SCALE = 1.15;
export const scaleFont = (size: string, scale = DEFAULT_FONT_SCALE): string | number => {
  const fontSize = size.replace(/[^0-9.]/g, "");
  const suffix = size.replace(fontSize, "");
  return Number(fontSize) * scale + suffix;
};

export const getLocalDateInAD = (date: string | Date): string =>
  moment.tz(date, "Asia/kathmandu").format("YYYY/MM/DD");

interface InfoFieldProps {
  label: JSX.Element;
  data: JSX.Element;
  labelStyle?: Record<string, unknown>;
  valueStyle?: Record<string, unknown>;
}

export const InfoField: React.FC<InfoFieldProps> = ({
  label,
  data,
  labelStyle,
  valueStyle
}): JSX.Element => {
  const baseLabelStyle = {
    fontSize: scaleFont("0.33cm"),
    color: "black",
    fontWeight: 400,
    width: "3cm"
  };
  const baseValueStyle = {
    fontSize: scaleFont("0.33cm"),
    fontWeight: 500
  };
  return (
    <Box display="flex">
      <Typography style={{ ...baseLabelStyle, ...labelStyle }}>{label}</Typography>
      <Typography style={{ ...baseValueStyle, ...valueStyle }}>{data}</Typography>
    </Box>
  );
};

interface ClientInfoProps {
  client: Client;
  customerNumber: string;
  showDobOnPrint?: boolean;
}

export const BillClientInfoSmall: React.FC<ClientInfoProps> = ({
  client,
  customerNumber = null,
  showDobOnPrint
}) => (
  <>
    <Typography
      style={{
        textAlign: "left",
        fontSize: scaleFont("0.3cm"),
        fontWeight: 600,
        paddingBottom: "8px",
        textTransform: "uppercase"
      }}
    >
      {clientFullNameSelector(client).toUpperCase()}
      <span style={{ marginLeft: "5px" }}>{showDOBAndGender(client.gender, client.dob, true)}</span>
    </Typography>
    {customerNumber && (
      <InfoField
        label={tl("billPrint.customerNumber")}
        data={<>{getCustomerNumber(customerNumber)}</>}
        labelStyle={{ width: "2cm", fontSize: scaleFont("0.33cm", 0.85) }}
        valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
      />
    )}
    {client.registrationNo && (
      <InfoField
        label={tl("bills.registrationNo")}
        data={<>{client.registrationNo}</>}
        labelStyle={{ width: "2cm", fontSize: scaleFont("0.33cm", 0.85) }}
        valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
      />
    )}
    {showDobOnPrint && client?.dob && (
      <InfoField
        label={tl("billPrint.customerDob")}
        data={<>{getLocalDateInAD(client.dob)}</>}
        labelStyle={{ width: "2cm", fontSize: scaleFont("0.33cm", 0.85) }}
        valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
      />
    )}
    {(client.address || client.city) && (
      <InfoField
        label={tl("address")}
        data={<>{separateByComma(capitalize(client.address), capitalize(client.city))}</>}
        labelStyle={{ width: "2cm", fontSize: scaleFont("0.33cm", 0.85) }}
        valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
      />
    )}
    {client.phone && (
      <InfoField
        label={tl("billing.phoneNo")}
        data={
          <>{client.phone?.substring(0, 4) === "+977" ? client.phone.substring(4) : client.phone}</>
        }
        labelStyle={{ width: "2cm", fontSize: scaleFont("0.33cm", 0.85) }}
        valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
      />
    )}
    {client?.panVatNumber && (
      <InfoField
        label={tl("billing.panVatNo")}
        data={<>{client.panVatNumber}</>}
        labelStyle={{ width: "2cm", fontSize: scaleFont("0.33cm", 0.85) }}
        valueStyle={{ fontSize: scaleFont("0.33cm", 0.85) }}
      />
    )}
  </>
);

export const BillClientInfo: React.FC<
  ClientInfoProps & { hideRegistrationInfo?: boolean; isBordered?: boolean }
> = ({
  client,
  customerNumber = null,
  showDobOnPrint = false,
  hideRegistrationInfo = false,
  isBordered = false
}) => (
  <>
    {isBordered ? (
      <>
        <InfoField label={tl("name")} data={<>{clientFullNameSelector(client)}</>} />
        <InfoField
          label={tl("age/sex")}
          data={<>{showDOBAndGender(client.gender, client.dob, true)}</>}
        />
      </>
    ) : (
      <Typography
        sx={{
          fontSize: scaleFont("0.33cm"),
          fontWeight: 600,
          ...(!isBordered ? { textTransform: "uppercase" } : {})
        }}
      >
        {clientFullNameSelector(client)}
        <span style={{ marginLeft: "5px" }}>
          {showDOBAndGender(client.gender, client.dob, true)}
        </span>
      </Typography>
    )}
    {customerNumber && (
      <InfoField label={tl("billPrint.customerNumber")} data={<>{customerNumber}</>} />
    )}
    {!hideRegistrationInfo && client.registrationNo && (
      <InfoField label={tl("bills.registrationNo")} data={<>{client.registrationNo}</>} />
    )}
    {/* show date of birth only if insurance number is present */}
    {showDobOnPrint && client?.dob && (
      <InfoField label={tl("billPrint.customerDob")} data={<>{getLocalDateInAD(client.dob)}</>} />
    )}
    {(client.address || client.city) && (
      <InfoField
        label={tl("address")}
        data={
          <>
            {client.address && `${capitalize(client.address)},`}
            {client.city && ` ${capitalize(client.city)}`}
          </>
        }
      />
    )}
    {client.phone && (
      <InfoField
        label={tl("billing.phoneNo")}
        data={
          <>{client.phone?.substring(0, 4) === "+977" ? client.phone.substring(4) : client.phone}</>
        }
      />
    )}
    {client?.panVatNumber && (
      <InfoField label={tl("billing.panVatNo")} data={<>{client.panVatNumber}</>} />
    )}
  </>
);

export const checkWalkInCustomer = (client: Client, isWalkInCustomer: string): boolean => {
  if (!hasOwnProperty(client, isWalkInCustomer) || !client?.isWalkInCustomer) return true;

  return false;
};

interface InfoFieldForBankPaperProps {
  label: JSX.Element;
  data: JSX.Element;
  labelStyle?: { [key: string]: string | number };
  valueStyle?: { [key: string]: string | number };
}

export const InfoFieldForBankPaper: React.FC<InfoFieldForBankPaperProps> = ({
  label,
  data,
  labelStyle,
  valueStyle
}): JSX.Element => {
  const baseLabelStyle = {
    fontSize: "0.3cm",
    color: "black",
    fontWeight: 400,
    width: "2.5cm"
  };
  const baseValueStyle = {
    fontSize: "0.3cm",
    fontWeight: 500,
    width: "4cm"
  };
  const style = {
    ...baseValueStyle,
    ...valueStyle
  };

  return (
    <Box display="flex">
      <Typography
        style={{
          ...baseLabelStyle,
          ...labelStyle
        }}
      >
        {label}
      </Typography>
      <Typography style={style}>{data}</Typography>
    </Box>
  );
};

export const BillClientInfoBankPaper: React.FC<ClientInfoProps> = ({
  client,
  customerNumber = null,
  showDobOnPrint = false
}) => (
  <>
    <Typography
      style={{
        textAlign: "left",
        fontSize: "0.3cm",
        fontWeight: 600,
        paddingBottom: "8px",
        textTransform: "uppercase"
      }}
    >
      {clientFullNameSelector(client)}
      <span style={{ marginLeft: "4px" }}>{showDOBAndGender(client.gender, client.dob, true)}</span>
    </Typography>
    {customerNumber && (
      <InfoFieldForBankPaper label={tl("billPrint.customerNumber")} data={<>{customerNumber}</>} />
    )}
    {client.registrationNo && (
      <InfoFieldForBankPaper
        label={tl("bills.registrationNo")}
        data={<>{client.registrationNo}</>}
      />
    )}
    {/* show date of birth only if insurance number is present */}
    {showDobOnPrint && client?.dob && (
      <InfoFieldForBankPaper
        label={tl("billPrint.customerDob")}
        data={<>{getLocalDateInAD(client.dob)}</>}
      />
    )}
    {(client.address || client.city) && (
      <InfoFieldForBankPaper
        label={tl("address")}
        data={
          <>
            {client.address && `${capitalize(client.address)},`}
            {client.city && ` ${capitalize(client.city)}`}
          </>
        }
      />
    )}
    {client.phone && (
      <InfoFieldForBankPaper
        label={tl("billing.phoneNo")}
        data={
          <>{client.phone?.substring(0, 4) === "+977" ? client.phone.substring(4) : client.phone}</>
        }
      />
    )}

    {client?.panVatNumber && (
      <InfoFieldForBankPaper label={tl("billing.panVatNo")} data={<>{client.panVatNumber}</>} />
    )}
  </>
);
