import React from "react";
import { TextField, Box, Typography } from "@mui/material";

interface StrikeThroughPriceProps {
  onChange: (v: number) => void;
  value: string;
  minValue: string;
}

const isServicePriceSmaller = (serviceP: number, originalP: number): boolean =>
  serviceP < originalP || originalP === 0;

export default function StrikeThroughPrice({
  onChange,
  value,
  minValue
}: StrikeThroughPriceProps): JSX.Element {
  const error = !isServicePriceSmaller(Number(minValue), Number(value));
  return (
    <Box display="flex" mb={1}>
      <Typography style={{ fontSize: "12px", minWidth: "55%" }}>Strike-Through Price</Typography>
      <TextField
        placeholder="Enter Price"
        slotProps={{
          input: {
            disableUnderline: true
          },
          htmlInput: {
            style: {
              borderBottom: "1px solid black",
              width: "80px",
              padding: "0px"
            }
          }
        }}
        error={error}
        value={value}
        size="small"
        style={{ width: "300px" }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(Number(e.target.value))}
        helperText={error && "Must be greater than service price"}
      />
      ,
    </Box>
  );
}
