import * as React from "react";
import { connect } from "react-redux";
import Hidden from "@mui/material/Hidden";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import AddBoxIcon from "@mui/icons-material/AddBox";
import PeopleRoundedIcon from "@mui/icons-material/PeopleRounded";
import AllInboxIcon from "@mui/icons-material/AllInbox";
import EmailIcon from "@mui/icons-material/Email";
import LockIcon from "@mui/icons-material/Lock";
import RssFeed from "@mui/icons-material/RssFeed";
import EventNoteIcon from "@mui/icons-material/EventNote";
import { Box, ListItemButton, Tooltip } from "@mui/material";
import { push } from "connected-react-router";
import DashboardIcon from "@mui/icons-material/Dashboard";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import DomainIcon from "@mui/icons-material/Domain";
import SettingsIcon from "@mui/icons-material/Settings";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import AssessmentIcon from "@mui/icons-material/Assessment";
import InventoryIcon from "@mui/icons-material/Inventory";
import SmsIcon from "@mui/icons-material/Sms";
import PaidIcon from "@mui/icons-material/Paid";
import ShopIcon from "@mui/icons-material/Shop";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import { MenuBook } from "@mui/icons-material";
import FlaskIcon from "../../../assets/icons/Flask.icon";
import BillIcon from "../../../assets/icons/Bill.icon";
import styles from "./styles.module.css";
import { tl } from "../../components/translate";
import { getRCFromState } from "../../actions/resourceCentre";
import BedIcon from "../../../assets/icons/Bed.icon";
import { PermissionGroups, UserMode } from "../../interfaces/User";
import { userViewMode } from "../../interfaces/UserContext";
import Can from "../Policy/Can";
import { clearDraft } from "../../actions/bill";
import { clearDraft as clearFeedDraft } from "../../actions/feed";
import { RootState } from "../../store";

import prescriptionIcon from "../../../assets/images/prescription.svg";
import logo from "../../../assets/images/okhati_logo_textless_white_shadowed.png";
import logoGreenFull from "../../../assets/images/okhati_full_green.png";
import { clearJournalVoucher } from "../../slices/journalEntry";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../mixpanel-analytics/event";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";
import useIsDaybookSubscribed from "../../hooks/useIsDaybookSubscribed";
import DaybookNotSubscribedPanel from "../Daybook/DaybookNotSubscribedPanel";

const selectedClassName = (route, router) =>
  router.location.pathname?.indexOf(route) === 0 ? styles.selected : "";

export const isResourceCentreUser = (mode: UserMode): boolean =>
  [UserMode.Employee, UserMode.ServiceProvider].includes(mode);

export const isSuperAdmin = (permissionGroup: string): boolean => permissionGroup === "superAdmin";

export const isReadOnlyUser = (permissionGroup: string): boolean =>
  permissionGroup === PermissionGroups.ReasourceCentreReadOnlyEmployee ||
  permissionGroup === PermissionGroups.ResourceCentreReportOnlyAccessEmployee;

export const isReportOnlyUser = (permissionGroup: string): boolean =>
  permissionGroup === PermissionGroups.ResourceCentreReportOnlyAccessEmployee;

export const isResourceCentreCampaignOnlyEmployee = (permissionGroup: string): boolean =>
  permissionGroup === PermissionGroups.ResourceCentreCampaignOnlyEmployee;

export const isSelfDataAccessEmployee = (permissionGroup: string): boolean =>
  permissionGroup === PermissionGroups.SelfDataAccessEmployee;

export const isAdmin = (mode: UserMode): boolean =>
  [UserMode.SuperAdmin, UserMode.Admin].includes(mode);

export const isSupportAdmin = (mode: UserMode, permissionGroup: PermissionGroups): boolean =>
  isAdmin(mode) && permissionGroup === PermissionGroups.SupportAdmin;

export const isSuperAdminAgent = (mode: UserMode, permissionGroup: PermissionGroups): boolean =>
  isAdmin(mode) && permissionGroup === PermissionGroups.SuperAdminAgent;

const MenuItems = ({
  goto,
  resourceCentreId,
  router,
  clearBillDraft,
  clearFeedDraftAction,
  clearJV,
  adminImpersonating,
  viewMode
}) => {
  const rc = useCurrentResourceCentre();
  const { isDaybookSubscribed } = useIsDaybookSubscribed();

  const gotoAndClearDrafts = (url) => {
    goto(url);
    clearBillDraft();
    clearFeedDraftAction();
    clearJV();
  };

  const [showDaybookNotSubscribedPanel, setShowDaybookNotSubscribedPanel] = React.useState(false);

  return (
    <>
      {adminImpersonating && (
        <Box
          position="fixed"
          top="2px"
          left="calc( 50% - 50px)"
          style={{ backgroundColor: "red", color: "white" }}
          padding="10px"
          fontWeight={700}
          borderRadius="3px"
        >
          {" "}
          Impersonation Mode
        </Box>
      )}
      {showDaybookNotSubscribedPanel && (
        <DaybookNotSubscribedPanel
          setShowDaybookNotSubscribedPanel={setShowDaybookNotSubscribedPanel}
        />
      )}
      <div style={{ flexGrow: 1 }}>
        <Hidden mdUp>
          <img className={styles.logoMobileMenu} src={logoGreenFull} alt="main-logo" />
        </Hidden>
        <List className={styles.list}>
          <Can
            policyAccessKey="allow"
            superAdminPassReverse
            supportAccountAdminPass
            publicAdminPassReverse
          >
            <ListItem
              button
              data-testmation="sideBarDashboard"
              className={`${styles.menuItem} ${selectedClassName("/dashboard", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_DASHBOARD, {
                  rcId: rc.id,
                  rcName: rc.name
                });
                gotoAndClearDrafts("/dashboard");
              }}
            >
              <DashboardIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.dashboard")}
              </Typography>
            </ListItem>
          </Can>
          <Can
            policyAccessKey={["booking:listBooking", "booking:listSchedule"]}
            partialCheck
            superAdminPassReverse
          >
            <ListItem
              button
              data-testmation="sideBarCalendar"
              className={`${styles.menuItem} ${selectedClassName("/calendar", router)}`}
              onClick={() => {
                gotoAndClearDrafts("/calendar");
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_CALENDAR, {
                  rcId: rc.id,
                  rcName: rc.name
                });
              }}
            >
              <CalendarTodayIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.calendar")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="bill:listBill" superAdminPassReverse>
            <ListItem
              button
              data-testmation="sideBarBilling"
              className={`${styles.menuItem} ${selectedClassName("/billing", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_BILLING, {
                  rcId: rc.id,
                  rcName: rc.name
                });
                goto("/billing/new");
              }}
            >
              <BillIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.billing")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="deny" superAdminPass>
            <ListItem
              button
              data-testmation="sideBarClinics"
              className={`${styles.menuItem} ${selectedClassName("/resourcecentres", router)}`}
              onClick={() => {
                if (rc) {
                  mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_CLINICS, {
                    rcId: rc.id,
                    rcName: rc.name
                  });
                }
                gotoAndClearDrafts("/resourcecentres");
              }}
            >
              <DomainIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.clinics")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="opd:listOpd" superAdminPassReverse>
            <ListItem
              button
              data-testmation="sideBarOpd"
              className={`${styles.menuItem} ${selectedClassName("/assessment", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_OPD, { rcId: rc.id, rcName: rc.name });
                gotoAndClearDrafts(`/assessment/new`);
              }}
            >
              <img
                src={prescriptionIcon}
                width="20px"
                height="20px"
                alt="Prescription"
                className={styles.menuItemIcon}
              />

              <Typography className={styles.label} variant="button">
                {tl("navigation.opd")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="lab:listLab" superAdminPassReverse>
            <ListItem
              button
              data-testmation="sideBarLab"
              className={`${styles.menuItem} ${selectedClassName("/lab", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_LAB, { rcId: rc.id, rcName: rc.name });
                gotoAndClearDrafts("/lab/labRecords/all?status=Ordered");
              }}
            >
              <FlaskIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.lab")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="services:listService" superAdminPassReverse>
            <ListItem
              button
              data-testmation="sideBarServices"
              className={`${styles.menuItem} ${selectedClassName("/services", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_SERVICES, {
                  rcId: rc.id,
                  rcName: rc.name
                });
                gotoAndClearDrafts("/services");
              }}
            >
              <NoteAddIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.services")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="deny" superAdminPass>
            <ListItem
              button
              data-testmation="sideBarOnDemandProducts"
              className={`${styles.menuItem} ${selectedClassName("/onDemandProduct", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_ON_DEMAND_PRODUCT);
                gotoAndClearDrafts("/onDemandProduct");
              }}
            >
              <NoteAddIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button" style={{ textAlign: "center" }}>
                {tl("navigation.onDemandProducts")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="clients:listClient" superAdminPassReverse>
            <ListItem
              button
              data-testmation="sideBarClients"
              className={`${styles.menuItem} ${selectedClassName("/clients", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_CLIENTS, {
                  rcId: rc.id,
                  rcName: rc.name
                });
                gotoAndClearDrafts("/clients");
              }}
            >
              <PeopleRoundedIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.clients")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="deny" superAdminAgentPass>
            <ListItem
              button
              data-testmation="masterDataControl"
              className={`${styles.menuItem} ${selectedClassName("/masterDataControl", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_MASTER_DATA_CONTROL);
                gotoAndClearDrafts("/masterDataControl");
              }}
            >
              <PeopleRoundedIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                Master Data Control
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="deny" superAdminAgentPass>
            <ListItem
              button
              data-testmation="smsProviders"
              className={`${styles.menuItem} ${selectedClassName("/smsProviders", router)}`}
              onClick={() => {
                gotoAndClearDrafts("/smsProviders");
              }}
            >
              <SmsIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                SMS
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="stock:listStock" superAdminPassReverse>
            {viewMode === userViewMode.PHARMACY ? (
              <>
                <ListItem
                  button
                  data-testmation="sideBarProducts"
                  className={`${styles.menuItem} ${selectedClassName(
                    "/stock/stockProducts",
                    router
                  )}`}
                  onClick={() => {
                    mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_STOCK_PRODUCTS, {
                      rcId: rc.id,
                      rcName: rc.name
                    });
                    gotoAndClearDrafts("/stock/stockProducts");
                  }}
                >
                  <AllInboxIcon className={styles.menuItemIcon} />
                  <Typography className={styles.label} variant="button">
                    {tl("stock.products")}
                  </Typography>
                </ListItem>

                <ListItem
                  button
                  data-testmation="sideBarInternalInventory"
                  className={`${styles.menuItem} ${selectedClassName(
                    "/stock/stockInternal",
                    router
                  )}`}
                  onClick={() => {
                    mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_INTERNAL_INVENTORY, {
                      rcId: rc.id
                    });
                    gotoAndClearDrafts("/stock/stockInternal");
                  }}
                >
                  <InventoryIcon className={styles.menuItemIcon} />
                  <Typography className={styles.label} variant="button">
                    {tl("stock.internalInventory")}
                  </Typography>
                </ListItem>

                <ListItem
                  button
                  data-testmation="sideBarStockTransactions"
                  className={`${styles.menuItem} ${selectedClassName(
                    "/stock/stockTransactions",
                    router
                  )}`}
                  onClick={() => {
                    mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_STOCK_TRANSACTIONS, {
                      rcId: rc.id
                    });
                    gotoAndClearDrafts("/stock/stockTransactions");
                  }}
                >
                  <PaidIcon className={styles.menuItemIcon} />
                  <Typography className={styles.label} variant="button">
                    {tl("stock.stockTransactions")}
                  </Typography>
                </ListItem>
              </>
            ) : (
              <ListItem
                button
                data-testmation="sideBarStock"
                className={`${styles.menuItem} ${selectedClassName("/stock", router)}`}
                onClick={() => {
                  mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_STOCK, {
                    rcId: rc.id,
                    rcName: rc.name
                  });
                  gotoAndClearDrafts("/stock/stockProducts");
                }}
              >
                <AllInboxIcon className={styles.menuItemIcon} />
                <Typography className={styles.label} variant="button">
                  {tl("navigation.stock")}
                </Typography>
              </ListItem>
            )}
          </Can>
          {viewMode === userViewMode.PHARMACY && (
            <Can policyAccessKey="clinic:listSuppliers" superAdminPassReverse>
              <ListItem
                button
                data-testmation="sideBarSuppliers"
                className={`${styles.menuItem} ${selectedClassName("/clients", router)}`}
                onClick={() => {
                  mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_SUPPLIER, {
                    rcId: rc.id,
                    rcName: rc.name
                  });
                  gotoAndClearDrafts("/suppliers");
                }}
              >
                <ShopIcon className={styles.menuItemIcon} />
                <Typography className={styles.label} variant="button">
                  {tl("employee.suppliers")}
                </Typography>
              </ListItem>
            </Can>
          )}
          <Can
            policyAccessKey={[
              "campaign:listCampaign",
              "campaign:listTemplate",
              "campaign:listAudience"
            ]}
            partialCheck
            superAdminPassReverse
          >
            <ListItem
              button
              data-testmation="sideBarCampaign"
              className={`${styles.menuItem} ${selectedClassName("/campaign", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_CAMPAIGN, {
                  rcId: rc.id,
                  rcName: rc.name
                });
                gotoAndClearDrafts("/campaign/campaigns");
              }}
            >
              <EmailIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.messaging")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="deny" superAdminPass>
            <ListItem
              button
              data-testmation="sideBarFeed"
              className={`${styles.menuItem} ${selectedClassName("/feeds", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_FEED);
                gotoAndClearDrafts("/feeds");
              }}
            >
              <RssFeed className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                Feeds
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="medical:listMedical" superAdminPassReverse>
            <ListItem
              button
              data-testmation="sideBarMedical"
              className={`${styles.menuItem} ${selectedClassName("/medical", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_MEDICAL, {
                  rcId: rc.id,
                  rcName: rc.name
                });
                gotoAndClearDrafts("/medical");
              }}
            >
              <AddBoxIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.medical")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="report:*" superAdminPass>
            <ListItem
              button
              data-testmation="sideBarReports"
              className={`${styles.menuItem} ${selectedClassName("/reports", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_REPORTS, { rcId: rc?.id });
                gotoAndClearDrafts("/reports");
              }}
            >
              <AssessmentIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.reports")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="deny" superAdminAgentPass>
            <ListItem
              button
              data-testmation="sideBarBookings"
              className={`${styles.menuItem} ${selectedClassName("/bookings", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_BOOKINGS, {
                  rcId: rc.id,
                  rcName: rc.name
                });
                gotoAndClearDrafts("/bookings");
              }}
            >
              <EventNoteIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.bookings")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="deny" superAdminPass>
            <ListItem
              button
              data-testmation="accountsSettings"
              className={`${styles.menuItem} ${selectedClassName("/accountSettings", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_ACCOUNT_SETTINGS);
                gotoAndClearDrafts("/accountSettings");
              }}
            >
              <MonetizationOnIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.accountSettings")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="ipd:listIpd" superAdminPassReverse>
            <ListItem
              button
              data-testmation="sideBarIPD"
              className={`${styles.menuItem} ${selectedClassName("/beds", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_IPD, { rcId: rc.id, rcName: rc.name });
                gotoAndClearDrafts("/beds");
              }}
            >
              <BedIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.ipd")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="hmis:listHmis" superAdminPassReverse>
            <ListItem
              button
              data-testmation="sideBarHmis"
              className={`${styles.menuItem} ${selectedClassName("/hmis/mulDarta", router)}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_HMIS, { rcId: rc.id, rcName: rc.name });
                gotoAndClearDrafts("/hmis/mulDarta");
              }}
            >
              <BedIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("navigation.hmis")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="deny" superAdminPass>
            <ListItem
              data-testmation="sideBarPermissions"
              className={`${styles.menuItem} ${selectedClassName("/userGroups", router)}`}
              onClick={() => {
                gotoAndClearDrafts("/userGroups");
              }}
            >
              <DomainIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("sidebar.permission")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="account:listAccount" superAdminPassReverse>
            <ListItem
              button
              data-testmation="sidebarAccount"
              className={`${styles.menuItem} ${selectedClassName(
                "/accounts/vouchers/sales",
                router
              )}`}
              onClick={() => {
                mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_ACCOUNT_VOUCHER_SALES, {
                  rcId: rc.id,
                  rcName: rc.name
                });
                gotoAndClearDrafts("/accounts/vouchers/sales");
              }}
            >
              <MonetizationOnIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                {tl("accounts")}
              </Typography>
            </ListItem>
          </Can>
          <Can policyAccessKey="deny" superAdminPass supportAccountAdminPass publicAdminPass>
            <ListItem
              button
              data-testmation="sideBarPublic"
              className={`${styles.menuItem} ${selectedClassName(
                "/public/verificationCodes",
                router
              )}`}
              onClick={() => {
                gotoAndClearDrafts(`/public/verificationCodes`);
              }}
            >
              <DomainIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                Okhati Public
              </Typography>
            </ListItem>
          </Can>

          <Can policyAccessKey="allow" superAdminPassReverse supportAccountAdminPass>
            <Box sx={{ position: "relative" }}>
              <ListItem
                button
                data-testmation="sideBarDaybook"
                className={`${styles.menuItem} ${selectedClassName("/daybook", router)}`}
                sx={{ cusor: "pointer" }}
                onClick={() => {
                  mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_DAYBOOK, {
                    rcId: rc.id,
                    rcName: rc.name
                  });

                  if (!isDaybookSubscribed) {
                    setShowDaybookNotSubscribedPanel((prev) => !prev);
                    return;
                  }

                  gotoAndClearDrafts("/daybook");
                }}
              >
                {!isDaybookSubscribed && (
                  <Tooltip title="Daybook is locked" arrow placement="right">
                    <LockIcon
                      color="action"
                      sx={{ position: "absolute", top: 0, right: "10px" }}
                      fontSize="small"
                    />
                  </Tooltip>
                )}
                <MenuBook className={styles.menuItemIcon} />
                <Typography className={styles.label} variant="button">
                  {tl("navigation.daybook")}
                </Typography>
              </ListItem>
            </Box>
          </Can>
          <Can policyAccessKey="deny" superAdminAgentPass>
            <ListItemButton
              className={`${styles.menuItem} ${selectedClassName("/importDemoData", router)}`}
              onClick={() => {
                gotoAndClearDrafts("/importDemoData");
              }}
            >
              <SmsIcon className={styles.menuItemIcon} />
              <Typography className={styles.label} variant="button">
                Import Demo Data
              </Typography>
            </ListItemButton>
          </Can>
        </List>
      </div>
      <div className={styles.bottom}>
        <Can policyAccessKey="allow" superAdminPassReverse publicAdminPassReverse>
          <ListItem
            button
            data-testmation="sideBarClinics"
            className={`${styles.menuItem} ${selectedClassName("/resourcecentres", router)}`}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_CLINIC_DETAILS, {
                rcId: rc.id,
                rcName: rc.name
              });
              gotoAndClearDrafts(`/resourcecentres/${resourceCentreId}/details`);
            }}
          >
            <SettingsIcon className={styles.menuItemIcon} />
            <Typography className={styles.label} variant="button">
              {tl("navigation.settings")}
            </Typography>
          </ListItem>
        </Can>
        <Can policyAccessKey="deny" superAdminPass>
          <ListItem
            button
            data-testmation="sideBarNotifications"
            className={`${styles.menuItem} ${selectedClassName(
              "/resourceCentreNotifications",
              router
            )}`}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_NOTIFICATIONS);
              gotoAndClearDrafts(`/resourceCentreNotifications`);
            }}
          >
            <NotificationImportantIcon className={styles.menuItemIcon} />
            <Typography className={styles.label} variant="button">
              {tl("navigation.notification")}
            </Typography>
          </ListItem>
        </Can>
        <Can policyAccessKey="deny" superAdminPass supportAccountAdminPass>
          <ListItem
            button
            data-testmation="sideBarPayments"
            className={`${styles.menuItem} ${selectedClassName(
              "/resourceCentreSubscriptions",
              router
            )}`}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.SIDEBAR_GO_TO_SUBSCRIPTION);
              gotoAndClearDrafts(`/resourceCentreSubscriptions`);
            }}
          >
            <DomainIcon className={styles.menuItemIcon} />
            <Typography className={styles.label} variant="button">
              {tl("sidebar.subscription")}
            </Typography>
          </ListItem>
        </Can>
      </div>
    </>
  );
};

const SideBar = (props) => {
  const { hamMenuOpen, toggleHamMenu, gotoPath, permissionGroup, viewMode } = props;

  return (
    <>
      <Hidden mdUp>
        <Drawer
          open={hamMenuOpen}
          onClose={() => toggleHamMenu(false)}
          className={styles.drawerWrapper}
          classes={{
            paper: styles.drawer
          }}
        >
          <MenuItems
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            goto={(path) => {
              gotoPath(path);
              toggleHamMenu(false);
            }}
            viewMode={viewMode}
            permissionGroup={permissionGroup}
          />
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          variant="permanent"
          className={styles.drawerWrapper}
          classes={{
            paper: styles.drawer
          }}
        >
          <Box
            className={styles.logo}
            style={{ flexBasis: 60 }}
            onClick={() => {
              if (!isReadOnlyUser(permissionGroup)) gotoPath("/");
            }}
          >
            <img className={styles.logoimg} src={logo} alt="logo" />
          </Box>
          <MenuItems
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            goto={(path) => {
              gotoPath(path);
              toggleHamMenu(false);
            }}
            viewMode={viewMode}
            permissionGroup={permissionGroup}
          />
        </Drawer>
      </Hidden>
    </>
  );
};

export default connect(
  ({ router, userContext, resources }: RootState) => {
    const resourceCentre = getRCFromState({ userContext, resources } as RootState);
    const adminImpersonating = isAdmin(userContext.mode) && userContext.resourceCentreId;
    const userGroup = userContext.userCreds.userGroups[0];
    return {
      router,
      userGroup,
      mode: userContext.mode,
      resourceCentreId: userContext.resourceCentreId,
      user: { ...userContext.user, role: userContext.mode },
      viewMode: userContext.userCreds.userPreferences?.viewMode,
      permissionGroup:
        userContext && userContext.userCreds.userGroups && userContext.userCreds.userGroups[0],
      subscriptions: resourceCentre?.subscriptions.features,
      adminImpersonating
    };
  },
  (dispatch) => ({
    gotoPath: (route) => {
      dispatch(push(route));
    },
    clearBillDraft: () => dispatch(clearDraft()),
    clearFeedDraftAction: () => dispatch(clearFeedDraft()),
    clearJV: () => dispatch(clearJournalVoucher())
  })
)(SideBar);
