import React from "react";
import { Box, FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from "@mui/material";
import isEqual from "lodash/isEqual";
import { DiscountBasedOn, DiscountTypes } from "../../../interfaces/BillInterfaces";
import { tl } from "../../../components/translate";
import { BillDiscountSettings } from "../../../interfaces/ResourceCentreInterface";

const DiscountSetting = ({
  discountSettings,
  onChange
}: {
  discountSettings: BillDiscountSettings;
  onChange: (data) => void;
}): JSX.Element => {
  const [discountBasis, setDiscountBasis] = React.useState(
    discountSettings.discountBasis || DiscountBasedOn.invoice
  );
  const [discountType, setDiscountType] = React.useState(
    discountSettings.discountType || DiscountTypes.percentage
  );

  React.useEffect(() => {
    if (!isEqual(discountSettings, { discountBasis, discountType }))
      onChange({ discountBasis, discountType });
  }, [discountBasis, discountType]);

  return (
    <Box width={1} display="flex" flexDirection="column">
      <FormControl component="fieldset" fullWidth>
        <FormLabel component="legend" style={{ color: "inherit" }}>
          {tl("billingSettings.discountBasis")}
        </FormLabel>
        <RadioGroup
          row
          name="discountBasis"
          value={discountBasis}
          onChange={(e) => setDiscountBasis(e.target.value)}
          sx={{ ml: 2 }}
        >
          <FormControlLabel
            value={DiscountBasedOn.invoice}
            control={<Radio />}
            label={tl("billingSettings.discountBasedOn.invoiceTotal")}
          />
          <FormControlLabel
            value={DiscountBasedOn.inline}
            control={<Radio />}
            label={tl("billingSettings.discountBasedOn.invoiceLine")}
          />
        </RadioGroup>
      </FormControl>
      <Box mt={2}>
        <FormControl component="fieldset" fullWidth>
          <FormLabel component="legend" style={{ color: "inherit" }}>
            {tl("billingSettings.discountType")}
          </FormLabel>
          <RadioGroup
            row
            name="discountType"
            value={discountType}
            onChange={(e) => setDiscountType(e.target.value)}
            sx={{ ml: 2 }}
          >
            <FormControlLabel
              value={DiscountTypes.percentage}
              control={<Radio />}
              label={tl("billingSettings.discountType.percentage")}
            />
            <FormControlLabel
              value={DiscountTypes.amount}
              control={<Radio />}
              label={tl("billingSettings.discountType.amount")}
            />
          </RadioGroup>
        </FormControl>
      </Box>
    </Box>
  );
};

export default DiscountSetting;
