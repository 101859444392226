import React from "react";
import startCase from "lodash/startCase";
import { Box, Typography } from "@mui/material";
import {
  scaleFont,
  verifyShowTestCategory,
  getGender,
  createMarkup,
  AdvancedLabRangeHandler,
  advancedLabRangeIndicator,
  showCategory,
  showSubRow,
  addDefaultFormData,
  readingPresent,
  isRadiologyOrCytology
} from "../../LabPrintFunctions";
import styles from "../../../Lab.module.css";
import { LabTest, LabTestRecordResultData } from "../../../../../interfaces/Lab";
import { EditableTableView } from "../../../../../components/EditableTable/EditableTable";
import hasOwnProperty from "../../../../../helpers/object";
import { removeCommasFromNumber } from "../../../../../helpers/number";
import DataHeaders from "./DataHeaders";

const EXCLUDE_GROUP_CATEGORY = ["Special", "undefined"]; // add group category here to hide

const CustomEl = ({ data }) => (
  <div dangerouslySetInnerHTML={createMarkup(data)} className={styles.additionalData} />
);

interface SubRowProps {
  pl: number;
  lt: LabTest;
  children?: React.ReactNode;
  hasSubTests?: boolean;
  gender: "1" | "2";
  dob: string;
  showMethods: boolean;
  showRanges: boolean;
  showUnit: boolean;
  showReading: boolean;
  showRangeFlag: boolean;
}

export const SubRow = ({
  pl,
  lt,
  children = <></>,
  hasSubTests = false,
  gender,
  showMethods,
  dob,
  showRanges,
  showUnit,
  showReading,
  showRangeFlag
}: SubRowProps): JSX.Element => {
  const clientGender = getGender(lt, gender);
  const commonStyles = { fontSize: scaleFont("0.33cm", 1), wordBreak: "break-all" };

  const rangeSymbol = advancedLabRangeIndicator(
    Number(removeCommasFromNumber(lt.formData.reading)),
    lt.ranges,
    dob,
    gender
  );

  return (
    <>
      {showSubRow(hasSubTests, lt) && (
        <Box display="flex" py="2px" sx={{ borderBottom: "1px solid #ddd" }}>
          <Box
            sx={{ paddingLeft: `${pl}cm`, flexBasis: "7cm", flexGrow: "0.4", flexShrink: "0.4" }}
          >
            <Typography
              sx={{
                fontSize: scaleFont("0.33cm", 1),
                paddingRight: "0.6cm",
                ...(hasSubTests && { fontWeight: "bold" })
              }}
            >
              {lt.name}
            </Typography>
          </Box>
          {showReading && (
            <Typography
              sx={{
                ...commonStyles,
                fontWeight: rangeSymbol ? 900 : 500,
                marginRight: "0.5cm",
                flexBasis: "5cm",
                flexGrow: "0.15",
                flexShrink: "0.15"
              }}
            >
              {lt?.formData.reading || null}
            </Typography>
          )}
          {showRangeFlag && (
            <Typography
              sx={{ ...commonStyles, flexBasis: "1.55cm", flexGrow: "0.15", flexShrink: "0.15" }}
            >
              {rangeSymbol.highSymbol || rangeSymbol.lowSymbol || null}
            </Typography>
          )}
          {showUnit && (
            <Typography
              sx={{ ...commonStyles, flexBasis: "1.8cm", flexGrow: "0.15", flexShrink: "0.15" }}
            >
              {!(lt.subTests?.length > 0) && hasOwnProperty(lt, "unit") && lt.unit}
            </Typography>
          )}
          {showRanges && (
            <Box flexBasis="3.7cm" flexGrow="0.15" flexShrink="0.15">
              <AdvancedLabRangeHandler range={lt.ranges} gender={Number(clientGender)} dob={dob} />
            </Box>
          )}
          {showMethods && (
            <Typography flexBasis="3cm" flexGrow="0.15" flexShrink="0.15">
              {lt.methods}
            </Typography>
          )}
        </Box>
      )}
      {children}
      {lt.subTests &&
        lt.subTests?.map((sT) => (
          <SubRow
            key={sT.id}
            pl={pl + 0.5}
            lt={addDefaultFormData(sT)}
            gender={gender}
            showMethods={showMethods}
            dob={dob}
            hasSubTests={Boolean(sT.subTests?.length)}
            showRanges={showRanges}
            showUnit={showUnit}
            showReading={showReading}
            showRangeFlag={showRangeFlag}
          />
        ))}
    </>
  );
};

export const TestNameWithSampleInfo = ({
  test,
  sampleInfo,
  collectedBy
}: {
  test: {
    name: string;
    style?: React.CSSProperties;
  };
  sampleInfo: {
    sampleType?: string;
    style?: React.CSSProperties;
  };
  collectedBy: {
    name?: string;
    style?: React.CSSProperties;
  };
}): JSX.Element => (
  <Box sx={{ display: "flex", flexDirection: "column" }}>
    {test.name && (
      <Typography sx={{ fontWeight: 800, ...(test.style || {}) }}>
        {startCase(test.name)}
      </Typography>
    )}
    <Typography
      component="span"
      sx={{
        ...(sampleInfo.style || {
          fontSize: scaleFont("0.33cm", 0.8),
          fontStyle: "italic",
          whiteSpace: "break-spaces"
        })
      }}
    >
      {sampleInfo.sampleType && `Sample Type: ${sampleInfo.sampleType}`}
      {collectedBy.name &&
        `${sampleInfo.sampleType ? "," : "Sample"} Taken by: ${collectedBy.name}`}
    </Typography>
  </Box>
);

interface RowProps {
  item: LabTestRecordResultData;
  gender: "1" | "2";
  showAdditionalLabData: boolean;
  showMethods: boolean;
  dob: string;
  showRanges: boolean;
  showUnit: boolean;
  showReading: boolean;
  showRangeFlag: boolean;
  showSampleCollectedBy: boolean;
}

export const Row = ({
  item,
  gender,
  showAdditionalLabData,
  showMethods,
  dob,
  showRanges,
  showUnit,
  showReading,
  showRangeFlag,
  showSampleCollectedBy
}: RowProps): JSX.Element => {
  const showTestCategory = verifyShowTestCategory(item);
  return (
    <Box>
      <TestNameWithSampleInfo
        test={{
          name: showTestCategory ? item.name : "",
          style: {
            fontSize: scaleFont("0.33cm", 1)
          }
        }}
        sampleInfo={{ sampleType: item.sampleInfo?.sampleType }}
        collectedBy={{ name: showSampleCollectedBy ? item.collectedBy?.name : "" }}
      />
      {item.labTests?.map((lt) => (
        <SubRow
          key={lt.id}
          pl={showTestCategory ? 0.5 : 0}
          lt={addDefaultFormData(lt)}
          hasSubTests={Boolean(lt.subTests?.length)}
          gender={gender}
          showMethods={showMethods}
          dob={dob}
          showRanges={showRanges}
          showUnit={showUnit}
          showReading={showReading}
          showRangeFlag={showRangeFlag}
        />
      ))}
      {showAdditionalLabData && item?.additionalTestData && (
        <div style={{ whiteSpace: "pre-wrap" }}>
          <CustomEl data={item?.additionalTestData} />
        </div>
      )}
      <div>
        {item?.interpretationTemplate && (
          <EditableTableView tableState={item?.interpretationTemplate} />
        )}
      </div>
    </Box>
  );
};

interface CoreDataProps {
  labData: Record<string, unknown>;
  showAdditionalLabData: boolean;
  showMethods: boolean;
  showRanges: boolean;
  showUnit: boolean;
  showReading: boolean;
  showRangeFlag: boolean;
  showSampleCollectedBy: boolean;
}

export const CoreData = ({
  labData,
  showAdditionalLabData,
  showMethods,
  showRanges,
  showUnit,
  showReading,
  showRangeFlag,
  showSampleCollectedBy
}: CoreDataProps): JSX.Element => {
  const { data } = labData.results;
  const { gender, dob } = labData.client;
  const showRowColumn = !isRadiologyOrCytology(labData.type);
  const readingContains = readingPresent(labData);
  return (
    <Box py="0.1cm">
      {Object.keys(data).map(
        (item) =>
          showCategory(data[item]) && (
            <div key={item}>
              {!EXCLUDE_GROUP_CATEGORY.includes(item) && (
                <Typography
                  sx={{
                    textAlign: "center",
                    textTransform: "uppercase",
                    fontWeight: "bold",
                    textDecoration: "underline"
                  }}
                >
                  {item}
                </Typography>
              )}
              <DataHeaders
                showMethods={showMethods}
                type={labData.type}
                showRanges={showRanges}
                showUnit={showUnit}
                showReading={showReading}
                readingsPresent={readingContains}
                showRangeFlag={showRangeFlag}
              />
              <Box ml={!EXCLUDE_GROUP_CATEGORY.includes(item) ? 1 : 0}>
                {data[item].map((test) => (
                  <Row
                    key={test.name}
                    item={test}
                    gender={gender}
                    showAdditionalLabData={showAdditionalLabData}
                    showMethods={showRowColumn && showMethods}
                    dob={dob}
                    showRanges={showRowColumn && showRanges}
                    showUnit={showRowColumn && showUnit}
                    showReading={showReading}
                    showRangeFlag={showRangeFlag}
                    showSampleCollectedBy={showSampleCollectedBy}
                  />
                ))}
              </Box>
            </div>
          )
      )}
    </Box>
  );
};

export const DataComment = ({ comment }: { comment: string }): JSX.Element => (
  <Box display="flex" width="100%">
    <Typography sx={{ fontSize: scaleFont("0.33cm", 1), whiteSpace: "pre-wrap", width: "100%" }}>
      <CustomEl data={comment} />
    </Typography>
  </Box>
);

interface LabPrintCoreDataProps {
  labData: Record<string, unknown>;
  showAdditionalLabData: boolean;
  showMethods: boolean;
  showRanges: boolean;
  showUnit: boolean;
  showReading: boolean;
  showRangeFlag: boolean;
  showSampleCollectedBy: boolean;
}

const LabPrintCoreData = ({
  labData,
  showAdditionalLabData,
  showMethods = false,
  showRanges = false,
  showUnit = false,
  showReading = false,
  showRangeFlag = false,
  showSampleCollectedBy = false
}: LabPrintCoreDataProps): JSX.Element => (
  <div>
    <Box borderBottom="1px solid lightgrey">
      <CoreData
        labData={labData}
        showAdditionalLabData={showAdditionalLabData}
        showMethods={showMethods}
        showRanges={showRanges}
        showUnit={showUnit}
        showReading={showReading}
        showRangeFlag={showRangeFlag}
        showSampleCollectedBy={showSampleCollectedBy}
      />
    </Box>
    {labData.results.showComment && labData.results.comment && (
      <Box mt={2}>
        <DataComment comment={labData.results.comment} />
      </Box>
    )}
  </div>
);

export default LabPrintCoreData;
