import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid2 as Grid,
  TextField,
  Typography
} from "@mui/material";
import React from "react";
import Panel from "../../../components/Panel";
import { getLabTests } from "../../../slices/labTestSettingsSlice";
import { LabTest } from "../../../interfaces/LabInterfaces";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { AdvancedRange } from "../../Lab/LabPrint/LabPrintFunctions";
import hasOwnProperty from "../../../helpers/object";
import { createUpdateLabTestRc } from "../../../api/labTestSettings";
import { notificationAdd } from "../../../actions/notification";
import AdvancedRangesEdit, {
  handleRangesSanitization
} from "../../Lab/Settings/LabMasterDataEdit/AdvancedRangesEdit";
import LabTestTypeSelector from "./LabTestTypeSelector";
import { TestTypes } from "../../../interfaces/Lab";
import LabTestCategorySelector from "./LabTestCategorySelector";

interface FormDataType {
  name: string;
  code: string;
  category: string | null;
  ranges: null | AdvancedRange;
  unit: string;
  specimen: string;
  defaultValue: string;
  methods: string;
  isSubTest: boolean;
  parentId: null | number;
  type: TestTypes;
}

const DefaultValue = {
  name: "",
  code: "",
  category: "",
  ranges: null,
  unit: "",
  specimen: "",
  defaultValue: "",
  methods: "",
  isSubTest: false,
  parentId: null,
  type: TestTypes.PATHOLOGY
};

interface Props {
  onClose: () => void;
}

function LabTestEditPanel({ onClose }: Props): JSX.Element {
  const [formData, setFormData] = React.useState<FormDataType>(DefaultValue);
  const [selectedLab, setSelectedLab] = React.useState<(LabTest & { inputValue?: string }) | null>(
    null
  );

  const dispatch = useAppDispatch();
  const labTests: LabTest[] = useAppSelector((state) => state.labSettings.labTests);

  React.useEffect(() => {
    dispatch(getLabTests());
  }, [dispatch]);

  React.useEffect(() => {
    if (selectedLab?.id) {
      setFormData({
        name: selectedLab.name,
        code: selectedLab.code,
        category: selectedLab.category,
        ranges: selectedLab.ranges,
        unit: selectedLab.unit,
        specimen: selectedLab.specimen,
        defaultValue: selectedLab.info?.defaultValue || "",
        methods: selectedLab.methods,
        isSubTest: Boolean(selectedLab.parentId),
        parentId: selectedLab.parentId,
        type: selectedLab.type || TestTypes.PATHOLOGY
      });
    }
  }, [selectedLab]);

  return (
    <Panel onClose={onClose} title="Lab Test Create/Edit">
      <Box px={4} py={2}>
        <Box height="calc(100vh - 115px)" style={{ overflowY: "auto" }}>
          <Autocomplete<LabTest | { inputValue: string; name: string }>
            getOptionLabel={(o) => (hasOwnProperty(o, "inputValue") ? o.inputValue : o.name)}
            value={selectedLab}
            options={labTests}
            renderOption={(props, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...props} key={hasOwnProperty(option, "id") ? option.id : option.name}>
                <div>{option.name}</div>
              </li>
            )}
            filterOptions={(options, params) => {
              if (params.inputValue !== "") {
                options.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`
                });
              }
              return options.filter((o) =>
                o.name.toLowerCase().includes(params.inputValue.toLowerCase())
              );
            }}
            onChange={(_, v) => {
              setSelectedLab(v);
              if (hasOwnProperty(v, "inputValue") && v.inputValue) {
                setFormData({ ...formData, name: v.inputValue });
              }
            }}
            renderInput={(params) => (
              <TextField
                data-testmation="labSelect"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                margin="dense"
                fullWidth
                variant="outlined"
                label="Select Or Add Lab"
                placeholder="Select Or Add Lab"
                slotProps={{
                  inputLabel: { shrink: true }
                }}
              />
            )}
          />

          {(selectedLab?.id || selectedLab?.inputValue) && (
            <Box mb={2}>
              {selectedLab.id ? (
                <Typography variant="h5" mt="32px">
                  Editing {selectedLab.name}
                </Typography>
              ) : (
                <Typography variant="h5" mt="32px">
                  Creating {selectedLab.inputValue}
                </Typography>
              )}
              <Grid container spacing={4} mt={0}>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <LabTestCategorySelector
                    value={formData.category}
                    onChange={(value) => {
                      setFormData({ ...formData, category: value });
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <LabTestTypeSelector
                    value={formData.type}
                    onChange={(value) => {
                      setFormData({ ...formData, type: value });
                    }}
                  />
                </Grid>

                <Grid size={{ xs: 12, sm: 6 }}>
                  <TextField
                    variant="outlined"
                    label="Test Name"
                    value={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    fullWidth
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <TextField
                    variant="outlined"
                    label="Code"
                    value={formData.code}
                    onChange={(e) => setFormData({ ...formData, code: e.target.value })}
                    fullWidth
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                  />
                </Grid>

                <Grid size={{ xs: 12, sm: 6 }}>
                  <TextField
                    variant="outlined"
                    label="Unit"
                    value={formData.unit}
                    onChange={(e) => setFormData({ ...formData, unit: e.target.value })}
                    fullWidth
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <TextField
                    variant="outlined"
                    label="Specimen"
                    value={formData.specimen}
                    onChange={(e) => setFormData({ ...formData, specimen: e.target.value })}
                    fullWidth
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <TextField
                    variant="outlined"
                    label="Default Value"
                    value={formData.defaultValue}
                    onChange={(e) => setFormData({ ...formData, defaultValue: e.target.value })}
                    fullWidth
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <TextField
                    variant="outlined"
                    label="Methods"
                    value={formData.methods}
                    onChange={(e) => setFormData({ ...formData, methods: e.target.value })}
                    fullWidth
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }}>
                  <AdvancedRangesEdit
                    ranges={handleRangesSanitization(formData.ranges || {})}
                    onUpdate={(v) => setFormData({ ...formData, ranges: v })}
                    buttonText="Edit Ranges"
                  />
                </Grid>
                <Grid size={{ xs: 12, sm: 6 }} gap={1}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.isSubTest}
                        onChange={(e) => setFormData({ ...formData, isSubTest: e.target.checked })}
                      />
                    }
                    label="Is Sub Test"
                  />
                  {formData.isSubTest && (
                    <Autocomplete<LabTest>
                      getOptionLabel={(option) => option.name}
                      value={labTests.find((labTest) => labTest.id === formData.parentId) || null}
                      options={labTests.filter((labTest) => labTest.id !== selectedLab.id)}
                      renderOption={(props, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...props} key={option.id}>
                          <div>{option.name}</div>
                        </li>
                      )}
                      onChange={(_, v) => setFormData({ ...formData, parentId: v?.id || null })}
                      renderInput={(params) => (
                        <TextField
                          data-testmation="labSelect"
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          margin="dense"
                          fullWidth
                          variant="outlined"
                          label="Select Parent Test"
                        />
                      )}
                    />
                  )}
                </Grid>
              </Grid>
            </Box>
          )}
        </Box>
        <Box display="flex" justifyContent="flex-end" gap={2}>
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            disabled={!formData.name}
            sx={{ ml: "32px" }}
            onClick={async () => {
              try {
                await createUpdateLabTestRc({ ...formData, id: selectedLab.id || null });
                dispatch(getLabTests());
                dispatch(
                  notificationAdd({
                    id: new Date().toISOString(),
                    autoTimeout: true,
                    message: `Successfully created ${formData.name}!`,
                    variant: "success"
                  })
                );
                setFormData(DefaultValue);
                setSelectedLab(null);
                onClose();
              } catch (e) {
                dispatch(
                  notificationAdd({
                    id: new Date().toISOString(),
                    autoTimeout: true,
                    message: "Couldn't create!",
                    variant: "error"
                  })
                );
              }
            }}
          >
            {selectedLab?.id ? "Update Lab Test" : "Create Lab Test"}
          </Button>
        </Box>
      </Box>
    </Panel>
  );
}

export default LabTestEditPanel;
