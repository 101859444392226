import InsertChartIcon from "@mui/icons-material/InsertChart";
import SummarizeIcon from "@mui/icons-material/Summarize";
import HistoryIcon from "@mui/icons-material/History";
import ScienceIcon from "@mui/icons-material/Science";
import { Box, Card, Grid2 as Grid, Typography } from "@mui/material";
import { push } from "connected-react-router";
import * as React from "react";
import { useDispatch } from "react-redux";
import { tl } from "../../components/translate";
import Can from "../Policy/Can";
import styles from "./Reports.module.css";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../mixpanel-analytics/event";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";

const OtherReport = (): JSX.Element => {
  const dispatch = useDispatch();
  const rc = useCurrentResourceCentre();

  return (
    <Box height="100%" padding="0 32px">
      <Typography fontSize="20px" fontWeight={600} lineHeight="40px" component="div">
        {tl("reports.otherReport")}
      </Typography>
      <Box marginTop="32px">
        <Can policyAccessKey="report:summaryReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_OTHERS_SUMMARY, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/otherReport/serviceCenterSummaryReport"));
            }}
            data-testmation="summaryReportCard"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography>
                    <Box component="span" fontWeight="700" fontSize="24px">
                      {tl("reports.serviceCenterSummaryReport")}
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <InsertChartIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="report:labReport" superAdminPass>
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_OTHERS_HMIS_LAB, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/otherReport/hmislab"));
            }}
            data-testmation="hmisLabreport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography component="span" fontWeight="700" fontSize="24px">
                    HMIS Lab Report
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <SummarizeIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_OTHERS_HMIS_MAIN, {
              rcId: rc.id,
              rcName: rc.name
            });
            return dispatch(push("/reports/otherReport/hmis"));
          }}
          data-testmation="hmisReport"
        >
          <Grid container>
            <Grid size={8}>
              <Box height="100%" width="100%">
                <Typography component="span" fontWeight="700" fontSize="24px">
                  HMIS Main Report
                </Typography>
              </Box>
            </Grid>
            <Grid size={4}>
              <SummarizeIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
        <Can policyAccessKey="report:historyReport" superAdminPass>
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_OTHERS_ACTIVITY_HISTORY, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/otherReport/history"));
            }}
            data-testmation="historyReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography component="span" fontWeight="700" fontSize="24px">
                    Activity History Report
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <HistoryIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_OTHERS_HMIS_MAIN, {
              rcId: rc.id,
              rcName: rc.name
            });
            return dispatch(push("/reports/otherReport/labReferralReport"));
          }}
          data-testmation="labRefarralReport"
        >
          <Grid container>
            <Grid size={8}>
              <Box height="100%" width="100%">
                <Typography component="span" fontWeight="700" fontSize="24px">
                  Dental Lab Referral Report
                </Typography>
              </Box>
            </Grid>
            <Grid size={4}>
              <ScienceIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
        <Can policyAccessKey="deny" superAdminPass>
          <Card
            className={styles.reportCardRoot}
            onClick={() => dispatch(push("/reports/otherReport/publicSms"))}
            data-testmation="hmisReport"
          >
            <Grid container>
              <Grid xs={8} lg={8}>
                <Box height="100%" width="100%">
                  <Typography component="span" fontWeight="700" fontSize="24px">
                    Public Sms
                  </Typography>
                </Box>
              </Grid>
              <Grid xs={4} lg={4}>
                <SummarizeIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
      </Box>
    </Box>
  );
};

export default OtherReport;
