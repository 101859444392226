import React from "react";
import { TextField, MenuItem, Box, Typography } from "@mui/material";
import styles from "./Assessment.module.css";
import useAssessmentLabel from "../../hooks/useAssessmentLabel";

const PresentHealthStatus = ({
  assessmentPresentHealthStatus,
  onChange
}: {
  assessmentPresentHealthStatus: { value: string; label: string };
  onChange: (v: string) => void;
}): JSX.Element => {
  const [presentHealthStatus, setPresentHealthStatus] = React.useState();

  React.useEffect(() => {
    if (assessmentPresentHealthStatus) setPresentHealthStatus(assessmentPresentHealthStatus);
  }, [assessmentPresentHealthStatus]);
  const assessmentLabels = useAssessmentLabel();
  const options = [
    { value: "Fit", label: "Fit" },
    { value: "Unfit", label: "Unfit" }
  ];

  return (
    <Box className={styles.assessmentRow}>
      <Box className={styles.assessmentLabel}>
        <Typography component="span" fontWeight="600">
          {assessmentLabels.presentHealthStatus}
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        <TextField
          size="small"
          variant="outlined"
          value={presentHealthStatus}
          select
          fullWidth
          slotProps={{
            inputLabel: { shrink: true }
          }}
          onChange={(event) => {
            onChange(event.target.value);
          }}
        >
          {options.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Box>
  );
};

export default PresentHealthStatus;
