import { Alert, Box, Button, Grid2 as Grid, TextField, Typography } from "@mui/material";
import { push } from "connected-react-router";
import React from "react";
import { useDispatch } from "react-redux";
import Panel from "../../../components/Panel";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { tl } from "../../../components/translate";
import { AccountingVoucherSeriesInterface } from "../../../interfaces/Accounts";
import { usePatchAccVoucherSeries } from "./hooks/useAccountingVoucherSeries";

interface AccStateInterface {
  id: number | null;
  digitsCount: number;
  customPrefix: string;
  startingVoucher: null | string | number;
}

const getDigitCountValue = (value) => {
  if (value > 6) return 6;
  return value;
};

const EditAccountingVoucher = ({
  setOpenEditPanel,
  voucher
}: {
  setOpenEditPanel: (edit: boolean) => void;
  voucher: AccountingVoucherSeriesInterface;
}): React.ReactElement => {
  const onClose = () => setOpenEditPanel(false);
  const dispatch = useDispatch();
  const [formData, setFormData] = React.useState<AccStateInterface>({} as AccStateInterface);
  const [saving, setSaving] = React.useState(false);
  const patchAccVoucher = usePatchAccVoucherSeries();

  React.useEffect(() => {
    setFormData({
      id: voucher.id,
      digitsCount: voucher.digitsCount || 4,
      customPrefix: voucher.customPrefix,
      startingVoucher: voucher.startingVoucher || 1
    });
  }, [voucher]);

  const isValid = (data) => {
    if (data.digitsCount && data.customPrefix && data.startingVoucher) {
      return false;
    }
    return true;
  };

  const disabled = isValid(formData);

  return (
    <Panel
      onClose={onClose}
      wrapperStyle={{ minWidth: "670px", maxWidth: "670px" }}
      title={tl("EditAccountingSeries")}
      footer={
        <Box display="flex" justifyContent="space-between" width="100%" marginRight="32px">
          <Box />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button
              data-testmation="cancel"
              onClick={() => {
                dispatch(push(`/accounts/accountingVoucherSeries/${voucher.id}`));
              }}
              style={{ marginRight: "32px" }}
            >
              <Typography>{tl("cancel")}</Typography>
            </Button>
            <StatefulButton
              data-testmation="save"
              variant="contained"
              color="primary"
              disabled={saving || disabled}
              onClick={async () => {
                if (formData) {
                  setSaving(true);
                  patchAccVoucher(formData);
                  setOpenEditPanel(false);
                  setSaving(false);
                }
              }}
              isLoading={saving}
              circularProgressProps={{ size: 16 }}
            >
              <Typography>{tl("save")}</Typography>
            </StatefulButton>
          </Box>
        </Box>
      }
    >
      <Box style={{ margin: "12px 64px", height: "calc(100vh - 124px)", overflowY: "auto" }}>
        <Grid container spacing={2}>
          <Grid size={{ sm: 12 }}>
            {voucher.warnOnChange && (
              <Alert severity="warning">
                Changed voucher preferences will be reflected only in next fiscal period.
              </Alert>
            )}
          </Grid>
          <Grid size={{ sm: 12 }}>
            <Typography variant="h4" style={{ fontSize: "14px" }}>
              {tl("account.voucherType")}: {voucher.voucherName}
            </Typography>
          </Grid>
          <Grid size={{ sm: 12 }}>
            <Typography variant="h4" style={{ fontSize: "14px" }}>
              {tl("account.voucherCode")}: {voucher.voucherCode}
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} style={{ marginTop: "10px" }}>
          <Grid size={{ sm: 6 }}>
            <TextField
              type="number"
              data-testmation="digitsCount"
              id="outlined-basic"
              label="Digits Count"
              variant="outlined"
              style={{ width: "100%" }}
              slotProps={{
                inputLabel: { shrink: true }
              }}
              value={formData.digitsCount}
              error={!formData.digitsCount}
              onFocus={(e) => e.target.select()}
              onChange={({ target }) => {
                const digitCountValue = getDigitCountValue(target.value);
                setFormData({ ...formData, digitsCount: Number(digitCountValue) });
              }}
            />
          </Grid>
          <Grid size={{ sm: 6 }}>
            <TextField
              id="outlined-basic"
              label={tl("account.customPrefix")}
              variant="outlined"
              style={{ width: "100%" }}
              value={formData.customPrefix}
              onFocus={(e) => e.target.select()}
              onChange={({ target }) => {
                if (target.value.length < 6) {
                  setFormData({ ...formData, customPrefix: target.value });
                }
              }}
            />
          </Grid>
          <Grid size={{ sm: 6 }}>
            <TextField
              slotProps={{
                inputLabel: { shrink: true }
              }}
              type="number"
              id="outlined-basic"
              label={tl("startingVoucher")}
              variant="outlined"
              style={{ width: "100%" }}
              value={formData.startingVoucher}
              onFocus={(e) => e.target.select()}
              onChange={({ target }) => {
                if (Number(target.value) > 0) {
                  setFormData({ ...formData, startingVoucher: target.value });
                }
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Panel>
  );
};

export default EditAccountingVoucher;
