import React, { useState } from "react";
import { Typography, Box, Button, Checkbox } from "@mui/material";
import produce from "immer";
import { useDispatch } from "react-redux";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { vitals } from "../../Assessment/Vitals/VitalRow";
import { resourceCentreActions } from "../../../actions";
import { notificationAdd } from "../../../actions/notification";
import { commonErrorMessage } from "../../../helpers/messages";
import { removeGeneralErrorByKey } from "../../../actions/error";
import { ORDER_ACTION, OrderActionType, OrderButtons } from "./AssessmentLabels";

const AssessmentVitals = ({ resourceCentre }: { resourceCentre: ResourceCentre }): JSX.Element => {
  const [isDirty, setIsDirty] = useState(false);

  const [vitalsOrder, setVitalsOrder] = React.useState<Array<string>>(
    resourceCentre.settings.assessmentSettings?.vitalsOrder
  );
  const [vitalSettings, setVitalSettings] = React.useState(resourceCentre.settings.vitalSettings);

  const handleOrdering = ({ action, label }: { action: OrderActionType; label: string }) => {
    const currentIndex = vitalsOrder.indexOf(label);
    let updatedIndex: number = -1;
    if (action === ORDER_ACTION.UP) {
      updatedIndex = currentIndex - 1;
    } else if (action === ORDER_ACTION.DOWN) {
      updatedIndex = currentIndex + 1;
    }
    if (updatedIndex >= 0) {
      setVitalsOrder(() =>
        produce(vitalsOrder, (draft) => {
          draft.splice(currentIndex, 1);
          draft.splice(updatedIndex, 0, label);
        })
      );
    }
  };

  const dispatch = useDispatch();

  const handleIsDirty = () => {
    if (!isDirty) setIsDirty(true);
  };

  return (
    <Box ml={2}>
      <Box display="flex" mb={2} gap={2}>
        <Typography width="260px" variant="body1" fontWeight="bold">
          Vitals
        </Typography>
        <Typography width="100px" variant="body1" fontWeight="bold">
          Ordering
        </Typography>
      </Box>
      <Box sx={{ height: "30rem", overflowY: "scroll", maxWidth: "41rem" }}>
        {vitalsOrder.map((vital, index) => (
          <Box display="flex" mb={2} gap={2} key={vital}>
            <>
              <Checkbox
                checked={Boolean(vitalSettings[vital])}
                onChange={() => {
                  handleIsDirty();
                  setVitalSettings({
                    ...vitalSettings,
                    [vital]: !vitalSettings[vital]
                  });
                }}
              />
              <Typography width="260px" variant="body1" fontWeight="bold">
                {vitals.find(({ dbKey }) => dbKey === vital)?.label}
              </Typography>
            </>
            <Typography width="100px" variant="body1" fontWeight="bold">
              <OrderButtons
                isDisabled={{ up: index < 1, down: index > vitalsOrder.length - 2 }}
                onClick={{
                  up: () => {
                    handleIsDirty();
                    handleOrdering({
                      action: ORDER_ACTION.UP,
                      label: vital
                    });
                  },
                  down: () => {
                    handleIsDirty();
                    handleOrdering({
                      action: ORDER_ACTION.DOWN,
                      label: vital
                    });
                  }
                }}
              />
            </Typography>
          </Box>
        ))}
      </Box>
      <Button
        variant="contained"
        disabled={!isDirty}
        onClick={() => {
          setIsDirty(false);
          const updatedRc = produce(resourceCentre, (draft) => {
            draft.settings = {
              ...draft.settings,
              vitalSettings,
              assessmentSettings: {
                ...draft.settings.assessmentSettings,
                vitalsOrder
              }
            };
          });
          dispatch(async (dispatchInner, getState) => {
            await dispatchInner(resourceCentreActions.putResourceCentre(updatedRc));
            const currentState = getState();
            const errorObject = currentState.error.filter(
              ({ key }) => key === "PUT_RESOURCECENTRE"
            );
            if (errorObject.length) {
              setIsDirty(true);
              dispatchInner(
                notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: errorObject[0].message || commonErrorMessage,
                  autoTimeout: true
                })
              );
              dispatchInner(removeGeneralErrorByKey("PUT_RESOURCECENTRE"));
            } else {
              dispatchInner(
                notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "success",
                  message: "Assessment Labels updated successfully.",
                  autoTimeout: true
                })
              );
            }
          });
        }}
      >
        Save Changes
      </Button>
    </Box>
  );
};

export default AssessmentVitals;
