import * as React from "react";
import { connect } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DoneIcon from "@mui/icons-material/Done";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { Box } from "@mui/material";
import { push } from "connected-react-router";
import { tl } from "../../../components/translate";
import { updateBooking } from "../../../actions/booking";
import mixpanelAnalytics from "../../../mixpanel-analytics/mixpanelAnalytics";
import EVENT, { BOOKING_EVENT_MAPPING } from "../../../mixpanel-analytics/event";
import hasOwnProperty from "../../../helpers/object";
import useCurrentResourceCentre from "../../../hooks/useCurrentResourceCentre";

const options = {
  serviceProvider: {
    reserved: [
      "markHandled",
      "markHandledAndCreateBill",
      "createAssessment",
      "createFollowUp",
      "confirmBooking",
      "markReferred"
    ],
    handled: ["createBill", "createAssessment", "createFollowUp"],
    referred: ["createBill", "createAssessment", "createFollowUp"],
    confirmed: [
      "markHandled",
      "markHandledAndCreateBill",
      "createAssessment",
      "createFollowUp",
      "markReferred"
    ]
  },
  employee: {
    reserved: [
      "markHandled",
      "markHandledAndCreateBill",
      "createAssessment",
      "createLabTest",
      "createMedicalReport",
      "createFollowUp",
      "confirmBooking",
      "markWaiting",
      "markEngaged",
      "markReferred"
    ],
    handled: [
      "createBill",
      "createAssessment",
      "createLabTest",
      "createMedicalReport",
      "createFollowUp"
    ],
    referred: [
      "createBill",
      "createAssessment",
      "createLabTest",
      "createMedicalReport",
      "createFollowUp"
    ],
    confirmed: [
      "markHandled",
      "markHandledAndCreateBill",
      "createAssessment",
      "createLabTest",
      "createMedicalReport",
      "createFollowUp",
      "markWaiting",
      "markEngaged",
      "markReferred"
    ],
    waiting: [
      "markHandled",
      "markHandledAndCreateBill",
      "createAssessment",
      "createLabTest",
      "createMedicalReport",
      "markEngaged",
      "createFollowUp",
      "markReferred"
    ],
    engaged: [
      "markHandled",
      "markHandledAndCreateBill",
      "createAssessment",
      "createLabTest",
      "createMedicalReport",
      "createFollowUp",
      "markReferred"
    ]
  }
};

const getOptions = (userMode, booking) => {
  if (booking) return options[userMode][booking.status] || [];
  return [];
};

const formatRemarksLength = (newRemarks, remarks) => {
  let allRemarks = remarks.length ? `${remarks}\n${newRemarks}` : newRemarks;

  if (allRemarks.length > 1000) {
    allRemarks = allRemarks
      .slice(-1000)
      .split("\n")
      .slice(1)
      .reduce((t, c) => `${t}\n${c}`);
  }

  return allRemarks;
};

const BookingHandlingOptions = (props) => {
  const { booking, user, userMode, afterUpdate, onCreateBookigFromBookingInfo } = props;
  if (userMode === "serviceProvider" && booking.serviceProviderId !== user.id) {
    return null;
  }
  /* eslint-disable react-hooks/rules-of-hooks */
  const [openMenu, setOpenMenu] = React.useState(false);
  const [openHandledRemarks, setOpenHandledRemarks] = React.useState(false);
  const [actionAfter, setActionAfter] = React.useState(null);
  const [selectedIndex, setSelectedIndex] = React.useState(1);
  const rc = useCurrentResourceCentre();

  const [remarks, setRemarks] = React.useState("");
  const anchorRef = React.useRef<HTMLDivElement>(null);
  // eslint-disable-next-line no-underscore-dangle
  const _options = React.useMemo(() => getOptions(userMode, booking), [userMode, booking]);

  const handleClick = (action) => {
    if (
      ["markHandled", "markHandledAndCreateBill", "markReferred", "confirmBooking"].includes(action)
    ) {
      setActionAfter(action);
      setOpenHandledRemarks(true);
    } else if (action === "createFollowUp") {
      onCreateBookigFromBookingInfo(booking);
    } else {
      // eslint-disable-next-line react/destructuring-assignment
      props[action](booking);
    }
  };

  const markHandled = (action) => {
    // eslint-disable-next-line react/destructuring-assignment
    props[action](booking, formatRemarksLength(remarks, booking.remarks), afterUpdate);
  };

  const handleMenuItemClick = (option: string, index: number) => {
    setSelectedIndex(index);
    setOpenMenu(false);
    handleClick(option);
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleClose = (event: React.MouseEvent<Document, MouseEvent>) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenMenu(false);
  };

  if (!_options.length) return null;

  return (
    <>
      <ButtonGroup
        size="small"
        variant="outlined"
        color="primary"
        ref={anchorRef}
        aria-label="split button"
      >
        <Button
          onClick={() => {
            handleClick(_options[0]);
            if (_options[0] === "markHandled") {
              mixpanelAnalytics.track(EVENT.BOOKING_STATUS_MARK_HANDLED, {
                rcId: rc.id,
                rcName: rc.name
              });
            }
          }}
          data-testmation={_options[0]}
        >
          {tl(_options[0])}
        </Button>
        {_options.length > 1 && (
          <Button
            size="small"
            color="primary"
            aria-owns={openMenu ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            data-testmation="handleBookingDownArrow"
          >
            <ArrowDropDownIcon />
          </Button>
        )}
      </ButtonGroup>

      <Popper
        style={{ zIndex: 1 }}
        open={openMenu}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList>
                  {_options.map((option, index) => {
                    if (index === 0) {
                      return null;
                    }
                    const isConfirmBooking = option === "confirmBooking";

                    return (
                      <MenuItem
                        key={option}
                        selected={index === selectedIndex}
                        onClick={(event) => {
                          if (hasOwnProperty(BOOKING_EVENT_MAPPING, option)) {
                            mixpanelAnalytics.track(BOOKING_EVENT_MAPPING[option], {
                              rcId: rc.id,
                              rcName: rc.name
                            });
                          }
                          handleMenuItemClick(option, index, event);
                        }}
                        data-testmation={option}
                        sx={{
                          borderTop: isConfirmBooking ? "1px solid #e0e0e0" : ""
                        }}
                      >
                        {tl(option)}
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Popper
        style={{ zIndex: 1 }}
        open={openHandledRemarks}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={() => setOpenHandledRemarks(false)}>
                <div
                  style={{
                    padding: "16px",
                    border: "1px solid #908f8f",
                    boxShadow: "0px 0px 3px 3px #c7c7c754"
                  }}
                >
                  <TextField
                    id="outlined-dense-multiline"
                    label={tl("booking.remarks")}
                    margin="dense"
                    variant="outlined"
                    multiline
                    maxRows="2"
                    style={{ width: "100%", minHeight: "42px" }}
                    value={remarks}
                    onChange={({ target }) => setRemarks(target.value)}
                    autoFocus
                    data-testmation="bookingHandleAddRemarks"
                  />
                  <Box component="div" textAlign="right" marginTop="16px">
                    <Button
                      style={{ marginLeft: "32px" }}
                      onClick={() => setOpenHandledRemarks(false)}
                      data-testmation="bookingHandleCancelButton"
                    >
                      {tl("booking.cancel")}
                    </Button>
                    <Button
                      style={{ marginLeft: "8px" }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        markHandled(actionAfter);
                        setRemarks("");
                        setOpenHandledRemarks(false);
                      }}
                      data-testmation="bookingHandleAfterAction"
                    >
                      {tl(actionAfter)}
                      <DoneIcon />
                    </Button>
                  </Box>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default connect(
  ({ userContext }) => ({
    user: userContext.user,
    userMode: userContext.mode
  }),
  (dispatch, ownProps) => ({
    markHandled: async (booking, remarks, next) => {
      await dispatch(updateBooking(booking.id, { status: "handled", remarks }, next));
      ownProps.onClose();
    },
    markReferred: async (booking, remarks, next) => {
      await dispatch(updateBooking(booking.id, { status: "referred", remarks }, next));
      ownProps.onClose();
    },
    markEngaged: async (booking, remarks, next) => {
      await dispatch(updateBooking(booking.id, { status: "engaged", remarks }, next));
      ownProps.onClose();
    },
    markWaiting: async (booking, remarks, next) => {
      await dispatch(updateBooking(booking.id, { status: "waiting", remarks }, next));
      ownProps.onClose();
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createLabTest: async (booking, remarks, next) => {
      dispatch(push(`/lab/labRecords/all/create?cId=${booking.clientId}`));
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createMedicalReport: async (booking, remarks, next) => {
      dispatch(push(`/lab/labRecords/all/create?cId=${booking.clientId}&medical=true`));
    },
    createAssessment: (booking) => {
      dispatch(
        push(
          `/assessment/new?clientId=${booking.clientId}&spId=${ownProps.booking.serviceProviderId}`
        )
      );
    },
    markHandledAndCreateBill: (booking, remarks, next) => {
      dispatch(updateBooking(booking.id, { status: "handled", remarks }, next));
      dispatch(push(`/billing/new?clientId=${booking.clientId}&bookingId=${booking.id}`));
    },
    createBill: (booking) => {
      dispatch(push(`/billing/new?clientId=${booking.clientId}&bookingId=${booking.id}`));
    },
    confirmBooking: async (booking, remarks, next) => {
      await dispatch(updateBooking(booking.id, { status: "confirmed", remarks }, next));
      ownProps.onClose();
    }
  })
)(BookingHandlingOptions);
