import React, { useState, useEffect, RefObject } from "react";
import {
  Box,
  Typography,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  MenuItem,
  Autocomplete,
  CircularProgress,
  Grid2 as Grid
} from "@mui/material";
import { push } from "connected-react-router";
import { chain, round } from "mathjs";
import { connect, useSelector, useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import { produce } from "immer";
import { isNil, omitBy, union } from "lodash";
import startCase from "lodash/startCase";
import { postStockProduct } from "../../../api/stockProducts";
import MenuedTextField from "../../../components/MenuedTextField";
import { MedicationListView } from "../../Services/CommonServiceFields";
import { getMedicineSearch, getGenericMedicineSearch } from "../../../api/prescrption";
import { medicineType } from "../../Assessment/Medication/medicineType";
import {
  Currency,
  Mode,
  PriceCalculatedOn,
  StockProducts,
  StructuredInfo
} from "../../../interfaces/StockInterfaces";
import { t, tl } from "../../../components/translate";
import Can from "../../Policy/Can";
import { IThunkDispatch, RootState } from "../../../store";
import hasOwnProperty from "../../../helpers/object";
import {
  initialPackageOptions,
  initialUnitOptions,
  TaxationInterface,
  TaxType,
  VoucherAssociations,
  VoucherAssociationsType
} from "../../../interfaces/ProductInterface";
import AccountLedgerAutocomplete from "../../../components/AccountLedgerAutocomplete/AccountLedgerAutocomplete";
import { PrimaryLedgerNames, ProductCodes } from "../../../interfaces/Accounts";
import VatOptionSelection from "./VatOptionSelection";
import { convertToINR, convertToRs, currencyStartAdornment, processPrice } from "../StockUtils";
import { extractSecondaryData, findByCode, useCoaAll } from "../../accounts/hooks";
import DepartmentSelect from "../../../components/DepartmentSelect";
import useTaxations from "../../../hooks/useTaxations";
import DebouncedTextField from "../../../components/DebouncedTextField";
import ManufactureOptions from "./ManufactureOptions";
import { notificationAdd } from "../../../actions/notification";
import { extractAdministrativeAndOtherExp } from "../../../hooks/accounts";
import { calculateSalesPrice, findProfitMargin } from "../helper";
import { ReverseMap } from "../../../helpers/types";
import OkhatiDialog from "../../../components/Dialog/Dialog";
import UnitPackageCreateSelect from "./UnitPackageCreateSelect";
import HtmlTooltip from "../../../components/HtmlTooltip";

const MedicineField = ({ value, onChange, createMode }) => {
  const [options, setOptions] = useState([]);
  const brand = value?.brand;

  useEffect(() => {
    if (createMode && brand?.length) {
      (async () => {
        const initOptions = await getMedicineSearch(brand);
        setOptions(
          initOptions.map((item) => ({
            code: item.code,
            form: item.form,
            brand: item.brand,
            strength: item.strength,
            genericCode: item.genericCode,
            genericName: item.genericName
          }))
        );
      })();
    }
  }, [brand, createMode]);

  return (
    <Autocomplete
      freeSolo
      disableClearable
      options={options}
      getOptionLabel={(option) =>
        `${option.brand || ""}${option.strength ? ` ${option.strength}` : ""}${
          option.form ? ` - ${option.form}` : ""
        }`
      }
      renderOption={(props, option) => (
        // eslint-disable-next-line react/jsx-props-no-spreading
        <li {...props} key={option.code + option.form + option.strength}>
          <MedicationListView
            form={option.form}
            brand={option.brand}
            strength={option.strength}
            genericName={option.genericName}
          />
        </li>
      )}
      data-testmation="MedicationSearchStock"
      onChange={(e, v) => {
        setOptions([]);
        onChange(v);
      }}
      value={value}
      renderInput={(params) => (
        <TextField
          data-testmation="productName"
          fullWidth
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label={tl("product.productName")}
          placeholder={t("product.productName")}
          autoFocus
          slotProps={{
            inputLabel: { shrink: true }
          }}
          margin="dense"
          variant="outlined"
          onChange={async (e) => {
            onChange({
              code: "",
              form: "",
              brand: e.target.value,
              strength: "",
              genericCode: "",
              genericName: ""
            });
            if (e.target.value?.length > 2) {
              const medicines = await getMedicineSearch(e.target.value);
              setOptions(
                medicines.map((item) => ({
                  code: item.code,
                  form: item.form,
                  brand: item.brand,
                  strength: item.strength,
                  genericCode: item.genericCode,
                  genericName: item.genericName
                }))
              );
            }
          }}
        />
      )}
    />
  );
};

function MedicineView({ genericName, form, strength, clearInfo }) {
  if (!genericName) return null;
  return (
    <Box display="flex" mt="16px">
      {medicineType[form] && (
        <Box mt="2px" color="black">
          {medicineType[form]()}
        </Box>
      )}
      <Typography
        style={{
          marginLeft: medicineType[form] ? "16px" : "0px",
          fontWeight: 500,
          marginTop: "-2px",
          fontSize: "0.875rem"
        }}
      >
        {genericName}
      </Typography>
      <Typography
        style={{
          marginLeft: "16px",
          fontWeight: 500,
          marginTop: "-2px",
          fontSize: "0.875rem"
        }}
      >
        {strength}
      </Typography>
      <CloseIcon
        style={{
          marginTop: "-2px",
          marginLeft: "16px",
          cursor: "pointer"
        }}
        htmlColor="lightgrey"
        fontSize="small"
        onClick={clearInfo}
      />
    </Box>
  );
}

export function isDirty(
  startState: Partial<CreateStockProductState>,
  endState: CreateStockProductState
): boolean {
  return JSON.stringify(startState) !== JSON.stringify(endState);
}

interface CreateStockProductProps {
  saveAction: (savedItem) => void;
  productName?: string;
  createMode?: boolean;
  handleClose?: () => void;
  data?: CreateStockProductState;
  batchList?: JSX.Element | null;
  isBatchDirty?: boolean;
  mode?: string;
  isvatResistered: boolean;
  actions: {
    navigateTo: (url: string) => void;
  };
  batchRef?: RefObject<HTMLInputElement> | null;
  focusBatch?: () => void | null;
}

export enum ProductType {
  Sellable = 1,
  InternalUse = 2
}

export enum ProductTypeInfo {
  Sellable = "Sellable",
  InternalUse = "InternalUse"
}

export enum SellableProductCategory {
  Medicine = "Medicine",
  Cosmetics = "Cosmetics",
  Suppliments = "Suppliments",
  Kits = "Kits",
  MedicalItems = "MedicalItems",
  Derma = "Derma",
  Surgical = "Surgical",
  Hair = "Hair"
}

export enum DefaultInternalUseCategory {
  Stationery = "Stationery",
  MedicalKits = "MedicalKits",
  LabAccessories = "LabAccessories"
}

export enum StockTrxListFilters {
  ALL = "all",
  PURCHASE = "purchase",
  PURCHASE_RETURN = "purchaseReturn",
  EXPIRY_OR_DISCARDMENT = "expiryOrDiscardment",
  EXCESS = "excess",
  SALES = "sales",
  SALES_RETURN = "salesReturn",
  OPENING_STOCK = "openingStock",
  ADJUSTMENT = "adjustment",
  OPENING_STOCK_ADJUSTMENT = "openingStockAdjustment"
}

export enum ExtraFilters {
  All = "All",
  Other = "Other"
}

export type StockTrxListFilterType = ReverseMap<typeof StockTrxListFilters>;
export type ProductListFilterType =
  | ReverseMap<typeof SellableProductCategory>
  | ReverseMap<typeof DefaultInternalUseCategory>
  | ReverseMap<typeof ExtraFilters>;

export interface PackageUnitInterface {
  value: string;
  label: string;
  isCustomValue?: boolean;
}

export interface CreateStockProductState {
  productName: string;
  productType: ProductType;
  category: string;
  unit: string;
  package: string;
  unitsPerPackage: number;
  vatPct: number;
  priceExcVAT: number | null;
  priceCalculatedOn: PriceCalculatedOn;
  purchaseCurrency: Currency;
  salesCurrency: Currency;
  minStockQuantity: number;
  structuredInfo: StructuredInfo;
  purchasePricePerUnit: number | null;
  purchasePriceCalculationOn: PriceCalculatedOn;
  purchasePricePerPackage: number | null;
  productCode: string;
  manufactureBy: string;
  nonDiscountable: boolean;
  id?: number;
  stocks?: StockProducts[];
  voucherAssociation?: VoucherAssociationsType;
  salesLedgerId?: number;
  purchaseLedgerId?: number;
  purchaseTaxationId?: number;
  salesTaxationId?: number;
  profitMargin: number;
  departmentId?: number;
  unitPriceExcVAT: number;
  packagePriceExcVAT: number;
  priceCalculationOn: string;
  internalExpenseLedgerId: number | null;
  barCode: string | null;
}

const extractStateFromData = (productData) => {
  if (productData) {
    return {
      productName: productData.name,
      productType: productData.productType,
      category: productData.category,
      unit: productData.unit,
      package: productData.package,
      unitsPerPackage: productData.unitsPerPackage,
      vatPct: productData.vatPct,
      priceCalculatedOn: productData.priceCalculationOn,
      purchaseCurrency: Currency.Rs,
      salesCurrency: Currency.Rs,
      minStockQuantity: productData.minStockQuantity,
      structuredInfo: productData.structuredInfo,
      priceExcVAT:
        (productData.priceCalculationOn === PriceCalculatedOn.unit
          ? productData.unitPriceExcVAT
          : productData.packagePriceExcVAT) || 0,
      purchasePricePerUnit: Number(productData.purchasePricePerUnit),
      purchasePriceCalculationOn: productData.purchasePriceCalculationOn || PriceCalculatedOn.unit,
      purchasePricePerPackage:
        Number(productData.purchasePricePerPackage) ||
        Number(productData.purchasePricePerUnit * Number(productData.unitsPerPackage)),
      productCode: productData?.productCode || "",
      manufactureBy: productData?.manufactureBy || "",
      nonDiscountable: productData.nonDiscountable || false,
      voucherAssociation: productData?.voucherAssociation || VoucherAssociations.PURCHASE,
      salesLedgerId: productData?.salesLedgerId,
      purchaseLedgerId: productData?.purchaseLedgerId,
      purchaseTaxationId: productData.purchaseTaxationId,
      salesTaxationId: productData.salesTaxationId,
      profitMargin: findProfitMargin(productData),
      internalExpenseLedgerId: productData.internalExpenseLedgerId,
      barCode: productData.barCode || ""
    };
  }
  return null;
};

const selectInitialState = (data, createMode, initialState) => {
  if (!createMode && data.id) {
    return extractStateFromData(data);
  }
  return initialState;
};

export const getPerUnitPriceWithoutVAT = (
  priceCalculatedOn: string,
  unitsPerPackage: number,
  price: number
): null | number => {
  if (priceCalculatedOn == null || unitsPerPackage == null || price == null) {
    return 0;
  }
  if (priceCalculatedOn === PriceCalculatedOn.unit) return price;
  return round(
    chain(price)
      .divide(unitsPerPackage || 1)
      .done(),
    2
  );
};

export const getPerPackagePriceWithoutVAT = (
  priceCalculatedOn: string,
  unitsPerPackage: number,
  price: number
): null | number => {
  if (priceCalculatedOn == null || unitsPerPackage == null || price == null) {
    return null;
  }
  if (priceCalculatedOn === PriceCalculatedOn.package) return price;
  return round(chain(price).multiply(unitsPerPackage).done(), 2);
};

export const getPerUnitPriceWithVAT = (
  priceCalculatedOn: string,
  unitsPerPackage: number,
  price: number,
  vatPct: number
): null | number => {
  const unitPriceWithoutVAT = getPerUnitPriceWithoutVAT(priceCalculatedOn, unitsPerPackage, price);
  if (unitPriceWithoutVAT == null || vatPct == null) return null;
  return round(
    chain(unitPriceWithoutVAT)
      .add((unitPriceWithoutVAT * vatPct) / 100)
      .done(),
    2
  );
};

export const getPerPackagePriceWithVAT = (
  priceCalculatedOn: string,
  unitsPerPackage: number,
  price: number,
  vatPct: number
): null | number => {
  const unitPriceWithoutVAT = getPerPackagePriceWithoutVAT(
    priceCalculatedOn,
    unitsPerPackage,
    price
  );
  if (unitPriceWithoutVAT == null || vatPct == null) return null;
  return round(
    chain(unitPriceWithoutVAT)
      .add((unitPriceWithoutVAT * vatPct) / 100)
      .done(),
    2
  );
};

const coerceNull = (value): number | null => {
  if (value == null || value === "") return 0;
  return Number(value);
};

export const getTaxationOptions = (
  data: TaxationInterface[]
): {
  purchaseTaxationOptions: TaxationInterface[];
  salesTaxationOptions: TaxationInterface[];
} => {
  const purchaseTaxationOptions = data.filter((tx) => tx.type === TaxType.PURCHASE);
  const salesTaxationOptions = data.filter((tx) => tx.type === TaxType.SALES);
  return {
    purchaseTaxationOptions,
    salesTaxationOptions
  };
};

const CreateStockProduct: React.FC<CreateStockProductProps> = ({
  handleClose,
  saveAction,
  productName = "",
  createMode = true,
  data = null,
  batchList = null,
  isBatchDirty = true,
  mode = null,
  isvatResistered,
  actions,
  batchRef = null,
  focusBatch = null
}) => {
  const isAccountSubscribed = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription?.features?.account?.subscribed
  );
  const internalCategories = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres[0].settings.stockSettings?.productCategory?.internal
  );
  const sellableCategories = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres[0].settings.stockSettings?.productCategory?.sellable
  );

  const administrativeAndOtherExp = extractAdministrativeAndOtherExp(
    extractSecondaryData(useCoaAll())?.expenses
  );
  const dispatch = useDispatch();
  const taxations = useTaxations();
  const { purchaseTaxationOptions, salesTaxationOptions } = getTaxationOptions(taxations);
  const vatOptions = [0, 13];

  const productPurchaseLedger = findByCode(ProductCodes.PURCHASE_LEDGER);
  const productSalesLedger = findByCode(ProductCodes.SALES_LEDGER);

  const [genericOptions, setGenericOptions] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isSavingProduct, setIsSavingProduct] = useState(false);

  const initialState = {
    productName,
    productType: ProductType.Sellable,
    category: SellableProductCategory.Medicine,
    unit: "Pcs.",
    package: "Box",
    unitsPerPackage: 1,
    vatPct: 0,
    priceExcVAT: 0,
    priceCalculatedOn: PriceCalculatedOn.unit,
    purchaseCurrency: Currency.Rs,
    salesCurrency: Currency.Rs,
    minStockQuantity: 0,
    structuredInfo: { isNarcotics: false },
    purchasePricePerUnit: 0,
    purchasePriceCalculationOn: PriceCalculatedOn.unit,
    purchasePricePerPackage: 0,
    productCode: "",
    manufactureBy: "",
    nonDiscountable: false,
    intangible: false,
    profitMargin: 16,
    barCode: "",
    ...(isAccountSubscribed
      ? {
          voucherAssociation: VoucherAssociations.PURCHASE
        }
      : {})
  };

  const [startState, setStartState] = useState(extractStateFromData(data) || initialState);

  const [isSaveDialogOpen, setIsSaveDialogOpen] = useState(false);

  const [unitOptions, setUnitOptions] = React.useState<PackageUnitInterface[]>(initialUnitOptions);
  const [selectedUnit, setSelectedUnit] = React.useState<PackageUnitInterface>({
    label: "Pcs.",
    value: "Pcs."
  });

  const [packageOptions, setPackageOptions] =
    React.useState<PackageUnitInterface[]>(initialPackageOptions);
  const [selectedPackage, setSelectedPackage] = React.useState<PackageUnitInterface>({
    label: "Box",
    value: "Box"
  });

  const [state, setState] = useState<CreateStockProductState>(
    selectInitialState(data, createMode, initialState)
  );

  useEffect(() => {
    if (!createMode && data) {
      setStartState(extractStateFromData(data) || initialState);
      setSelectedPackage({
        label: data.package,
        value: data.package
      });
      setSelectedUnit({
        label: data.unit,
        value: data.unit
      });
      setState(selectInitialState(data, createMode, initialState));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, createMode]);

  const productCategories = union(
    Object.keys(DefaultInternalUseCategory),
    internalCategories
  ).concat(sellableCategories);

  const onChangePurchaseCurrency = ({ target }) => {
    if (target.value === Currency.Inr) {
      setState({
        ...state,
        purchaseCurrency: target.value,
        purchasePricePerUnit: convertToINR(state.purchasePricePerUnit),
        purchasePricePerPackage: convertToINR(state.purchasePricePerPackage)
      });
    } else {
      setState({
        ...state,
        purchaseCurrency: target.value,
        purchasePricePerUnit: convertToRs(state.purchasePricePerUnit),
        purchasePricePerPackage: convertToRs(state.purchasePricePerPackage)
      });
    }
  };

  const onChangeSalesCurrency = ({ target }) => {
    if (target.value === Currency.Inr) {
      setState({
        ...state,
        salesCurrency: target.value,
        priceExcVAT: convertToINR(state.priceExcVAT) || 0
      });
    } else {
      setState({
        ...state,
        salesCurrency: target.value,
        priceExcVAT: convertToRs(state.priceExcVAT) || 0
      });
    }
  };

  React.useEffect(() => {
    if (isAccountSubscribed) {
      setState({
        ...state,
        ...(productPurchaseLedger?.id ? { purchaseLedgerId: productPurchaseLedger.id } : {}),
        ...(productSalesLedger?.id ? { salesLedgerId: productSalesLedger.id } : {})
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productPurchaseLedger, isAccountSubscribed]);

  const onProductSave = async () => {
    setIsSavingProduct(true);
    const finalState = {
      ...state,
      purchasePricePerUnit: processPrice(state.purchaseCurrency, state.purchasePricePerUnit),
      purchasePricePerPackage: processPrice(state.purchaseCurrency, state.purchasePricePerPackage),
      priceExcVAT: processPrice(state.salesCurrency, state.priceExcVAT) || 0
    };

    const extraRelatedInfo = {
      structuredInfo:
        finalState.category === SellableProductCategory.Medicine ? finalState.structuredInfo : null,
      unitPriceExcVAT:
        getPerUnitPriceWithoutVAT(
          finalState.priceCalculatedOn,
          finalState.unitsPerPackage,
          finalState.priceExcVAT
        ) || 0,
      unitPriceIncVAT: getPerUnitPriceWithVAT(
        finalState.priceCalculatedOn,
        finalState.unitsPerPackage,
        finalState.priceExcVAT,
        finalState.vatPct
      ),
      packagePriceIncVAT: getPerPackagePriceWithVAT(
        finalState.priceCalculatedOn,
        finalState.unitsPerPackage,
        finalState.priceExcVAT,
        finalState.vatPct
      ),
      packagePriceExcVAT: getPerPackagePriceWithoutVAT(
        finalState.priceCalculatedOn,
        finalState.unitsPerPackage,
        finalState.priceExcVAT
      ),
      intangible: false
    };
    if (createMode) {
      try {
        const response = await postStockProduct({
          ...omitBy(finalState, isNil),
          ...extraRelatedInfo
        });
        if (handleClose) handleClose();
        if (saveAction) saveAction(response);
      } catch (e) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: e.message || "Couldn't create product.",
            autoTimeout: true
          })
        );
      }
    } else {
      saveAction({
        ...omitBy(finalState, isNil),
        ...extraRelatedInfo
      });
    }

    if (batchRef && focusBatch) {
      focusBatch();
    }
    setIsSavingProduct(false);
  };

  const switchNameFieldRenderer = (category, cMode) => {
    switch (category) {
      case "Medicine":
        return (
          <MedicineField
            onChange={(val) =>
              setState({
                ...state,
                productName: `${val.brand}${val.strength ? ` ${val.strength}` : ""}${
                  val.form ? ` - ${val.form}` : ""
                }`,
                structuredInfo: { ...state.structuredInfo, ...val }
              })
            }
            value={
              hasOwnProperty(state.structuredInfo, "brand")
                ? state.structuredInfo
                : {
                    code: "",
                    form: "",
                    brand: state.productName,
                    strength: "",
                    genericCode: "",
                    genericName: ""
                  }
            }
            createMode={cMode}
          />
        );
      default:
        return (
          <TextField
            data-testmation="name"
            fullWidth
            variant="outlined"
            label="Name"
            margin="dense"
            value={state.productName}
            onChange={({ target }) =>
              setState({
                ...state,
                productName: target.value
              })
            }
          />
        );
    }
  };
  const footer = (
    <Box display="flex" justifyContent="space-between">
      <Box />
      <Box display="flex" pr="32px">
        <Box width="90px">
          <Button onClick={handleClose}>sCancel</Button>
        </Box>
        <Button
          data-testmation="save"
          variant="contained"
          color="primary"
          disabled={
            isSavingProduct ||
            (createMode
              ? !isDirty(startState, state)
              : !(isDirty(startState, state) || isBatchDirty)) ||
            !state.productName
          }
          onClick={async () => {
            if (mode === Mode.Create) {
              setIsSaveDialogOpen(true);
            } else {
              await onProductSave();
            }
          }}
        >
          Save
        </Button>
      </Box>
    </Box>
  );

  const handleIsNarcotics = () => {
    const updatedState = produce(state, (draft) => {
      if (draft.structuredInfo) {
        draft.structuredInfo.isNarcotics = !state.structuredInfo.isNarcotics;
      } else {
        draft.structuredInfo = {
          isNarcotics: true,
          brand: state.productName
        };
      }
    });
    setState(updatedState);
  };

  const CustomHeading = ({ label }: { label: string }): JSX.Element => (
    <Typography margin="10px 0 5px 0" fontWeight="medium" fontSize="16px">
      {label}
    </Typography>
  );

  return (
    <Can policyAccessKey="stock:createStockProduct">
      <Box
        px={5}
        pt={2}
        style={{
          width: "100%",
          height: "calc(100vh - 100px)",
          overflowY: "auto"
        }}
      >
        {createMode && (
          <Box ml="-14px" mt="8px">
            <FormControlLabel
              control={
                <Checkbox
                  data-testmation="isInternalInventoryItem"
                  size="small"
                  onChange={({ target }) => {
                    setState({
                      ...initialState,
                      productType: target.checked ? ProductType.InternalUse : ProductType.Sellable,
                      category: target.checked
                        ? DefaultInternalUseCategory.MedicalKits
                        : SellableProductCategory.Medicine
                    });
                  }}
                  checked={state.productType === ProductType.InternalUse}
                />
              }
              label="Is Internal Inventory Item"
              labelPlacement="start"
            />
          </Box>
        )}
        {switchNameFieldRenderer(state.category, createMode)}
        {state.structuredInfo &&
          state.productName &&
          state.structuredInfo?.strength &&
          state.category === SellableProductCategory.Medicine && (
            <MedicineView
              genericName={state.structuredInfo?.genericName}
              form={state.structuredInfo?.form}
              strength={state.structuredInfo?.strength}
              clearInfo={() =>
                setState({
                  ...state,
                  structuredInfo: {
                    ...state.structuredInfo,
                    genericName: ""
                  }
                })
              }
            />
          )}

        {state.category === SellableProductCategory.Medicine && (
          <Autocomplete
            data-testmation="genericNameInput"
            freeSolo
            disableClearable
            options={genericOptions}
            value={state.structuredInfo?.genericName || null}
            getOptionLabel={(option) => option}
            onChange={(e, value) => {
              setState(
                produce(state, (draft) => {
                  if (draft.structuredInfo) {
                    draft.structuredInfo.genericName = value;
                  } else {
                    draft.structuredInfo = { genericName: value };
                  }
                })
              );
            }}
            renderInput={(params) => (
              <Box component="div" mt={1}>
                <DebouncedTextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label={tl("product.genericName")}
                  placeholder={t("product.genericName")}
                  slotProps={{
                    inputLabel: { shrink: true },
                    input: {
                      ...params.InputProps,
                      disableUnderline: true,
                      endAdornment: (
                        <>
                          {loading ? <CircularProgress color="primary" size={16} /> : null}
                          {params.InputProps.endAdornment}
                        </>
                      )
                    }
                  }}
                  variant="outlined"
                  sx={{ marginTop: "6px" }}
                  fullWidth
                  onFocus={(e) => e.target.select()}
                  setDebounceLoading={(debounceLoading) => setLoading(debounceLoading)}
                  onChange={async (e) => {
                    if (e.target.value?.length > 2) {
                      setLoading(true);
                      try {
                        const response = await getGenericMedicineSearch(e.target.value);
                        setGenericOptions(response.map((option) => option.name));
                        setState(
                          produce(state, (draft) => {
                            if (draft.structuredInfo) {
                              draft.structuredInfo.genericName = e.target.value;
                            } else {
                              draft.structuredInfo = { genericName: e.target.value };
                            }
                          })
                        );
                      } catch (err) {
                        dispatch(
                          notificationAdd({
                            id: new Date().getUTCMilliseconds(),
                            variant: "error",
                            message: err?.message || "Something went wrong!",
                            autoTimeout: true
                          })
                        );
                      }
                      setLoading(false);
                    }
                  }}
                  debounceAt={1000}
                />
              </Box>
            )}
          />
        )}

        <Box display="flex" justifyContent="space-between" mt={1}>
          <TextField
            fullWidth
            data-testmation="productCode"
            sx={{ marginRight: "10px" }}
            margin="dense"
            value={state.productCode}
            label={tl("product.productCode")}
            placeholder={t("product.productCode")}
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            onChange={({ target }) => {
              setState({
                ...state,
                productCode: target.value
              });
            }}
          />

          <Autocomplete
            freeSolo
            disableClearable
            options={ManufactureOptions}
            value={state.manufactureBy}
            getOptionLabel={(option) => option}
            fullWidth
            onChange={(e, value) => {
              setState({
                ...state,
                manufactureBy: value
              });
            }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label={tl("product.manufacturedBy")}
                placeholder={t("product.manufacturedBy")}
                slotProps={{
                  inputLabel: { shrink: true },
                  input: {
                    ...params.InputProps,
                    type: "search"
                  }
                }}
                fullWidth
                variant="outlined"
                data-testmation="manufacturedBy"
                margin="dense"
                onChange={(e) => {
                  setState({
                    ...state,
                    manufactureBy: e.target.value
                  });
                }}
              />
            )}
          />
        </Box>
        <Box display="flex" mt={1} gap={1}>
          <TextField
            data-testmation="category"
            select
            fullWidth
            label={tl("product.category")}
            variant="outlined"
            margin="dense"
            value={state.category}
            onChange={(e) => {
              if (e.target.value !== SellableProductCategory.Medicine) {
                const newState = produce(state, (draft) => {
                  draft.category = e.target.value;
                  if (draft?.structuredInfo) {
                    draft.structuredInfo.isNarcotics = false;
                    draft.structuredInfo.genericName = "";
                  } else {
                    draft.structuredInfo = { isNarcotics: false };
                  }
                });
                setState(newState);
              } else {
                const newState = produce(state, (draft) => {
                  draft.category = e.target.value;
                  if (draft?.structuredInfo) {
                    draft.structuredInfo.isNarcotics = false;
                  } else {
                    draft.structuredInfo = { isNarcotics: false };
                  }
                  draft.structuredInfo.brand = state.productName;
                });
                setState(newState);
              }
            }}
          >
            {productCategories.map((item) => (
              <MenuItem key={item} value={item}>
                {startCase(item)}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            fullWidth
            data-testmation="minStock"
            variant="outlined"
            label={tl("product.minimumStockQty")}
            placeholder={t("product.minimumStockQty")}
            margin="dense"
            type="number"
            value={state.minStockQuantity}
            onFocus={(e) => e.target.select()}
            onChange={({ target }) =>
              setState({
                ...state,
                minStockQuantity: coerceNull(target.value)
              })
            }
          />
          <TextField
            fullWidth
            data-testmation="barCodeNumber"
            variant="outlined"
            label={tl("product.barCodeNumber")}
            placeholder={t("product.barCodeNumber")}
            margin="dense"
            type="string"
            value={state.barCode}
            onFocus={(e) => e.target.select()}
            onChange={({ target }) => {
              const barCode = target.value;
              if (barCode?.length < 14) {
                setState({
                  ...state,
                  barCode
                });
              }
            }}
            slotProps={{
              inputLabel: { shrink: true },
              input: {
                endAdornment: <HtmlTooltip description="Leaving blank will auto generate barcode" />
              }
            }}
          />
        </Box>
        {state.category === SellableProductCategory.Medicine && (
          <FormControlLabel
            control={
              <Checkbox
                checked={state.structuredInfo?.isNarcotics}
                onChange={handleIsNarcotics}
                name="checkedB"
                color="primary"
              />
            }
            label={tl("product.isNarcotics")}
          />
        )}
        <CustomHeading label={tl("product.QuantityInformations")} />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1
          }}
        >
          <Grid container spacing={1}>
            <Grid size={4}>
              <UnitPackageCreateSelect
                value={selectedUnit}
                options={unitOptions}
                onChange={(unit) => {
                  if (unit.isCustomValue) {
                    setUnitOptions([...unitOptions, { value: unit.value, label: unit.label }]);
                    setState({
                      ...state,
                      unit: unit.value
                    });
                    setSelectedUnit({ value: unit.value, label: unit.label });
                  } else {
                    setSelectedUnit(unit);
                    setState({
                      ...state,
                      unit: unit.value
                    });
                  }
                }}
                label={tl("product.unit")}
                placeholder="Select or create unit"
              />
            </Grid>
            {state.productType === ProductType.Sellable && (
              <>
                <Grid size={4}>
                  <UnitPackageCreateSelect
                    value={selectedPackage}
                    options={packageOptions}
                    onChange={(packageValue) => {
                      if (packageValue?.isCustomValue) {
                        setPackageOptions([
                          ...packageOptions,
                          { value: packageValue.value, label: packageValue.label }
                        ]);
                        setState({
                          ...state,
                          package: packageValue.value
                        });
                        setSelectedPackage({
                          value: packageValue.value,
                          label: packageValue.label
                        });
                      } else {
                        setSelectedPackage(packageValue);
                        setState({
                          ...state,
                          package: packageValue.value
                        });
                      }
                    }}
                    label={tl("product.package")}
                    placeholder="Select or create package"
                  />
                </Grid>
                <Grid size={4}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    type="number"
                    label={tl("product.unit/package")}
                    value={state.unitsPerPackage}
                    onFocus={(e) => e.target.select()}
                    slotProps={{
                      input: {
                        endAdornment: (
                          <Typography
                            sx={{
                              width: "160px",
                              fontSize: "0.65rem",
                              textAlign: "right"
                            }}
                          >
                            {`${state.unit} / ${state.package}`}
                          </Typography>
                        )
                      }
                    }}
                    onChange={({ target }) => {
                      const updatedState = {
                        ...state,
                        unitsPerPackage: coerceNull(target.value),
                        purchasePricePerPackage: state.purchasePricePerUnit * Number(target.value)
                      };
                      setState({
                        ...updatedState,
                        priceExcVAT: calculateSalesPrice(updatedState)
                      });
                    }}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <Box sx={{ display: "flex", gap: 1 }}>
            <InfoIcon color="info" />
            <Typography>
              {`1 ${state.package} contains ${state.unitsPerPackage} ${state.unit}`}
            </Typography>
          </Box>
        </Box>
        {mode === Mode.Create && (
          <>
            <CustomHeading label={tl("product.purchaseInformation")} />
            <Box flexWrap="wrap" display="flex" rowGap={1}>
              <MenuedTextField
                data-testmation="costPrice"
                menuItems={[state.unit, state.package]}
                label={`Per ${state.purchasePriceCalculationOn} purchase price excl. VAT`}
                style={{ marginRight: "10px", width: "300px" }}
                onFocus={(e) => e.target.select()}
                onTextChange={(value) => {
                  let updatedState;
                  if (state.purchasePriceCalculationOn === PriceCalculatedOn.unit) {
                    updatedState = {
                      ...state,
                      purchasePricePerUnit: coerceNull(value),
                      purchasePricePerPackage: Number(value) * state.unitsPerPackage
                    };
                    setState({
                      ...updatedState,
                      priceExcVAT: calculateSalesPrice(updatedState)
                    });
                  } else {
                    updatedState = {
                      ...state,
                      purchasePricePerPackage: coerceNull(value),
                      purchasePricePerUnit: round(Number(value) / state.unitsPerPackage, 2)
                    };
                    setState({
                      ...updatedState,
                      priceExcVAT: calculateSalesPrice(updatedState)
                    });
                  }
                }}
                fieldValue={
                  state.purchasePriceCalculationOn === PriceCalculatedOn.unit
                    ? state.purchasePricePerUnit
                    : state.purchasePricePerPackage
                }
                onSelectedMenuChange={(value) => {
                  let updatedState;
                  if (value === state.unit) {
                    updatedState = {
                      ...state,
                      purchasePriceCalculationOn: PriceCalculatedOn.unit,
                      priceCalculatedOn: PriceCalculatedOn.unit
                    };
                  } else {
                    updatedState = {
                      ...state,
                      purchasePriceCalculationOn: PriceCalculatedOn.package,
                      priceCalculatedOn: PriceCalculatedOn.package
                    };
                  }
                  setState({
                    ...updatedState,
                    priceExcVAT: calculateSalesPrice(updatedState)
                  });
                }}
                selectedMenuItem={
                  state.purchasePriceCalculationOn === PriceCalculatedOn.unit
                    ? `/ ${state.unit}`
                    : `/ ${state.package}`
                }
                startAdornment={currencyStartAdornment(
                  state.purchaseCurrency,
                  onChangePurchaseCurrency
                )}
                type="number"
              />

              {isAccountSubscribed && (
                <VatOptionSelection
                  sx={{ width: "200px" }}
                  value={purchaseTaxationOptions.find(
                    (option) => option.id === state.purchaseTaxationId
                  )}
                  onChange={(val) => {
                    setState({ ...state, purchaseTaxationId: val?.id });
                  }}
                  vatPercentageOptions={purchaseTaxationOptions}
                />
              )}
              <Can policyAccessKey="account:listAccount">
                <AccountLedgerAutocomplete
                  sx={{ width: "300px", marginRight: "20px" }}
                  ledgerId={state.purchaseLedgerId}
                  onChange={(v) => setState({ ...state, purchaseLedgerId: v?.id })}
                  ledgerType={PrimaryLedgerNames.EXPENSES}
                />
              </Can>
              <TextField
                data-testmation="marginInputField"
                sx={{ marginTop: "8px", width: "320px" }}
                type="number"
                variant="outlined"
                onFocus={(e) => e.target.select()}
                onChange={({ target }) => {
                  const updateState = { ...state, profitMargin: Number(target.value) };
                  setState({
                    ...updateState,
                    priceExcVAT: calculateSalesPrice(updateState)
                  });
                }}
                label="Profit Margin"
                value={state.profitMargin}
              />
              <Can policyAccessKey="account:listAccount">
                <Autocomplete
                  options={administrativeAndOtherExp}
                  value={
                    administrativeAndOtherExp.find(
                      (lg) => lg.id === state.internalExpenseLedgerId
                    ) || null
                  }
                  getOptionLabel={(option) => `${option.code} ${option.ledgerName}`}
                  fullWidth
                  onChange={(e, value) => {
                    setState({ ...state, internalExpenseLedgerId: value?.id || null });
                  }}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="Internal Consumption Mapping"
                      placeholder="Internal Consumption Mapping"
                      slotProps={{
                        inputLabel: { shrink: true },
                        input: {
                          ...params.InputProps
                        }
                      }}
                      fullWidth
                      variant="outlined"
                      margin="dense"
                    />
                  )}
                />
              </Can>
            </Box>
          </>
        )}
        {state.productType === ProductType.Sellable && (
          <>
            <CustomHeading label={tl("product.salesInformation")} />
            <Box display="flex" width="100%" flexWrap="wrap">
              <MenuedTextField
                menuItems={[state.unit, state.package]}
                label={`Per ${state.priceCalculatedOn} sales price excl. VAT`}
                style={{ width: "40%" }}
                onFocus={(e) => e.target.select()}
                onTextChange={(value) => setState({ ...state, priceExcVAT: coerceNull(value) })}
                fieldValue={state.priceExcVAT}
                onSelectedMenuChange={(value) => {
                  if (value === state.unit) {
                    setState({
                      ...state,
                      priceCalculatedOn: PriceCalculatedOn.unit
                    });
                  } else {
                    setState({
                      ...state,
                      priceCalculatedOn: PriceCalculatedOn.package
                    });
                  }
                }}
                selectedMenuItem={
                  state.priceCalculatedOn === PriceCalculatedOn.unit
                    ? `/ ${state.unit}`
                    : `/ ${state.package}`
                }
                startAdornment={currencyStartAdornment(state.salesCurrency, onChangeSalesCurrency)}
                type="number"
              />
              {isAccountSubscribed ? (
                <VatOptionSelection
                  sx={{ width: "200px" }}
                  value={salesTaxationOptions.find((option) => option.id === state.salesTaxationId)}
                  onChange={(val) => {
                    setState({ ...state, salesTaxationId: val?.id, vatPct: Number(val.rate) || 0 });
                  }}
                  vatPercentageOptions={salesTaxationOptions}
                />
              ) : (
                <TextField
                  disabled={!isvatResistered}
                  variant="outlined"
                  margin="dense"
                  label={tl("product.VAT%")}
                  placeholder={t("product.VAT%")}
                  type="number"
                  style={{ margin: "10px", width: "90px" }}
                  value={Number(state.vatPct)}
                  onChange={({ target }) =>
                    setState({ ...state, vatPct: coerceNull(target.value) })
                  }
                  select
                  slotProps={{
                    input: {
                      endAdornment: "%"
                    }
                  }}
                >
                  {vatOptions.map((item) => (
                    <MenuItem value={item} key={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              <FormControlLabel
                control={
                  <Checkbox
                    data-testmation="nonDiscountable"
                    checked={state?.nonDiscountable}
                    onChange={() =>
                      setState({
                        ...state,
                        nonDiscountable: !state.nonDiscountable
                      })
                    }
                    name="nonDiscountable"
                    color="primary"
                  />
                }
                label={tl("product.nonDiscountable")}
              />
            </Box>
            <Can policyAccessKey="account:listAccount">
              <AccountLedgerAutocomplete
                sx={{ width: "300px" }}
                ledgerId={state.salesLedgerId}
                onChange={(v) => setState({ ...state, salesLedgerId: v?.id })}
                ledgerType={PrimaryLedgerNames.INCOME}
              />
            </Can>
          </>
        )}

        {createMode && state.productType === ProductType.Sellable && (
          <>
            <Box>
              <div>
                <CustomHeading label="Per Unit Sales Price" />
                <Box display="flex">
                  <Typography width={200}>Without VAT : </Typography>
                  <Typography width={150}>{state.salesCurrency} </Typography>
                  <Typography>
                    {getPerUnitPriceWithoutVAT(
                      state.priceCalculatedOn,
                      state.unitsPerPackage,
                      state.priceExcVAT
                    )}
                  </Typography>
                </Box>
              </div>
              <Box display="flex">
                <Typography width={200}>With Vat : </Typography>
                <Typography width={150}>{state.salesCurrency} </Typography>
                <Typography>
                  {getPerUnitPriceWithVAT(
                    state.priceCalculatedOn,
                    state.unitsPerPackage,
                    state.priceExcVAT,
                    state.vatPct
                  )}
                </Typography>
              </Box>
            </Box>

            <Box>
              <CustomHeading label="Per Package Sales Price" />
              <Box display="flex">
                <Typography width={200}>Without Vat : </Typography>
                <Typography width={150}>{state.salesCurrency} </Typography>
                <Typography>
                  {getPerPackagePriceWithoutVAT(
                    state.priceCalculatedOn,
                    state.unitsPerPackage,
                    state.priceExcVAT
                  )}
                </Typography>
              </Box>

              <Box display="flex">
                <Typography width={200}>With Vat : </Typography>
                <Typography width={150}>{state.salesCurrency} </Typography>
                <Typography>
                  {getPerPackagePriceWithVAT(
                    state.priceCalculatedOn,
                    state.unitsPerPackage,
                    state.priceExcVAT,
                    state.vatPct
                  )}
                </Typography>
              </Box>
            </Box>
          </>
        )}
        {state.productType === ProductType.Sellable && (
          <div>
            <CustomHeading label="Department" />
            <DepartmentSelect
              placeholderText="Select department"
              style={{ marginTop: "8px" }}
              id={state?.departmentId || data?.departmentId}
              onChange={(department) => setState({ ...state, departmentId: department?.id })}
            />
          </div>
        )}
        <Box mt="8px">{!createMode && batchList}</Box>
        {!createMode && data?.stocks?.length > 0 && (
          <Can policyAccessKey="stock:quantityAdjustment">
            <Button
              data-testmation="makeAdjustmentButton"
              component="button"
              onClick={() => {
                actions.navigateTo(`/stock/${data.id}/adjustment/`);
              }}
            >
              Make Quantity Adjustment
            </Button>
          </Can>
        )}
      </Box>
      <Box>{footer}</Box>
      <OkhatiDialog
        title="Confirm Product Details"
        disableConfirmBtn={isSavingProduct}
        description={
          <div>
            <Typography>
              Please confirm that product unit and package are correctly entered
            </Typography>
            <Typography sx={{ mt: 2 }}>
              {`1 ${state.package} contains ${state.unitsPerPackage} ${state.unit}`}
            </Typography>
          </div>
        }
        next={() => {
          setIsSaveDialogOpen(false);
          onProductSave();
        }}
        readMode={false}
        cancel={() => {
          setIsSaveDialogOpen(false);
        }}
        open={isSaveDialogOpen}
      />
    </Can>
  );
};

export default connect(
  (state: RootState) => ({
    isvatResistered: state.userContext.resourceCentre.settings.billingSettings.vatRegistered
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      navigateTo: (url) => dispatch(push(url))
    }
  })
)(CreateStockProduct);
