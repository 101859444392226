import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid2 as Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography
} from "@mui/material";
import { Controller, FieldValues, useForm } from "react-hook-form";
import HighlightOff from "@mui/icons-material/HighlightOff";
import { startCase } from "lodash";
import { ICD } from "../../../interfaces/AssessmentInterfaces";
import Panel from "../../../components/Panel";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { notificationAdd } from "../../../actions/notification";
import { commonErrorMessage, commonSuccessMessage } from "../../../helpers/messages";
import { useAppDispatch } from "../../../store/hooks";
import { clientFullNameWithPhoneSelector } from "../../../reducers/client";
import { t } from "../../../components/translate";
import { InfoRow } from "./ClaimDetails";
import FormTextField from "../../../components/FormTextField/FormTextField";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";
import DiagnosisWithTreeView from "../../Assessment/Diagnosis/DiagnosisWithTreeView";
import { postSsfClaim } from "../../../api/ssf";
import { Claim } from "../../../interfaces/ssf";
import AttachmentUploader from "./AttachmentUploader";
import { MEDICAL_SUB_PRODUCT } from "../../../../map/ssf";
import { ATTACHMENT_TYPE, getSchemeFromClaim } from "../../../models/ssf";
import fileToBase64 from "../../../helpers/fileToBase64";

interface IClaimForm {
  onClose: () => void;
  claim: Claim;
}

const ClaimForm = ({ onClose, claim }: IClaimForm): JSX.Element | null => {
  const [attachmentType, setAttachmentType] = useState(ATTACHMENT_TYPE.BILL);
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { isSubmitting, errors }
  } = useForm<FieldValues>({
    defaultValues: {
      ssfId: claim.client?.ssfId || "",
      visitDate: new Date(claim.created_at),
      isReclaim: false,
      previousClaimCode: "",
      employerId: "",
      schemeType: claim.scheme,
      subProduct: claim.subProduct,
      isAdmitted: false,
      woundCondition: "",
      injuredBodyPart: "",
      isDisable: false,
      isDead: false,
      accidentDescription: "",
      reasonOfSickness: "",
      dischargeType: "",
      dischargeSummary: "",
      dischargeDate: new Date(claim.created_at),
      cancer: false,
      hiv: false,
      heartAttack: false,
      highBp: false,
      diabetes: false,
      diagnosis: [],
      attachments: []
    },
    mode: "onChange"
  });
  const { isReclaim, diagnosis, attachments } = watch();

  const dispatch = useAppDispatch();

  const onSubmit = async (data: FieldValues) => {
    let billTotal = 0;
    const billedServices = claim.bill.reduce((allBills, currentBill) => {
      billTotal += currentBill.total;
      const items = currentBill.document.billItems.map((billItem) => ({
        code: billItem.code,
        quantity: billItem.quantity,
        perUnit: billItem.perUnit,
        info: billItem.info
      }));
      return [...allBills, ...items];
    }, []);

    try {
      const updatedData = {
        ...data,
        id: claim.id,
        uuid: claim.client.ssfUuid,
        claimCode: claim.claimCode,
        services: billedServices,
        billTotal,
        visitDate: data.visitDate.toISOString()
      };

      updatedData.attachments = await Promise.all(
        attachments.map(async (attachment) => ({
          title: `${attachment.type}-${attachment.file.name}`,
          contentType: attachment.file.type,
          creation: attachment.file.lastModifiedDate.toISOString(),
          data: await fileToBase64(attachment.file)
        }))
      );

      await postSsfClaim(updatedData);
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: commonSuccessMessage,
          variant: "success",
          autoTimeout: true
        })
      );
      onClose();
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: err.message || commonErrorMessage,
          variant: "error",
          autoTimeout: true
        })
      );
    }
  };

  if (!claim) return null;

  const { scheme, subProduct } = getSchemeFromClaim({ data: claim });
  const isOpdScheme = claim.subProduct === MEDICAL_SUB_PRODUCT.OPD.value;

  return (
    <Panel
      onClose={onClose}
      title="Create Ssf Claim"
      footer={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            width: "100%",
            mx: 4
          }}
        >
          <Button onClick={onClose}>{t("global.cancel")}</Button>
          <StatefulButton
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            type="submit"
            form="claimForm"
            disabled={isSubmitting}
            isLoading={isSubmitting}
            circularProgressProps={{ size: 16 }}
          >
            {t("global.save")}
          </StatefulButton>
        </Box>
      }
    >
      <Box
        sx={{
          px: 5,
          pt: 1,
          overflowY: "auto",
          height: "calc(100vh - 100px)"
        }}
        component="form"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Box mb={2}>
          <Typography variant="h6" fontWeight={500} gutterBottom>
            {clientFullNameWithPhoneSelector(claim.client)}
            {claim.client.phone && claim.client.email ? ", " : ""}
            {claim.client.email || ""}
          </Typography>
          <InfoRow label="SSF Id" value={claim.client.ssfId} />
          <InfoRow label="Hospital Claim Code" value={claim.claimCode} />
          <InfoRow label="Scheme" value={scheme.label} />
          <InfoRow label="Sub Product" value={subProduct.label} />
        </Box>
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: isOpdScheme ? 12 : 6 }}>
            <Controller
              name="visitDate"
              control={control}
              render={({ field: { onChange, value } }) => (
                <CalendarDropdown
                  date={value}
                  label="Visit Date"
                  fullwidth
                  onChange={(v) => onChange(v)}
                  TextFieldProps={{
                    margin: "dense",
                    variant: "outlined"
                  }}
                />
              )}
            />
          </Grid>
          {!isOpdScheme && (
            <Grid size={{ xs: 12, sm: 6 }}>
              <Controller
                name="dischargeDate"
                rules={{
                  required: `This field is required`
                }}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <CalendarDropdown
                    date={value}
                    label="Discharge Date"
                    fullwidth
                    onChange={(v) => onChange(v)}
                    TextFieldProps={{
                      margin: "dense",
                      variant: "outlined"
                    }}
                  />
                )}
              />
            </Grid>
          )}
          <Grid size={{ xs: 12, sm: 6 }}>
            <Controller
              name="isReclaim"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Is Reclaim"
                />
              )}
            />
          </Grid>
          {isReclaim && (
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormTextField
                name="previousClaimCode"
                rules={{ required: `This field is required` }}
                fullWidth
                control={control}
                label="Previous Claim Code"
                placeholder="Enter previous claim code"
                dataTestmation=""
                errors={errors}
              />
            </Grid>
          )}
          <Grid size={{ xs: 12, sm: 6 }}>
            <Controller
              name="isAdmitted"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Is Admitted"
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Controller
              name="isDisable"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Is Disable"
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Controller
              name="isDead"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Is Dead"
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Controller
              name="cancer"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Has Cancer"
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Controller
              name="hiv"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Has HIV"
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Controller
              name="heartAttack"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Had HeartAttack"
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <Controller
              name="highBp"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Has HighBp"
                />
              )}
            />
          </Grid>
          <Grid size={12}>
            <Controller
              name="diabetes"
              control={control}
              render={({ field }) => (
                <FormControlLabel
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  control={<Checkbox {...field} checked={field.value} />}
                  label="Has Diabetes"
                />
              )}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <FormTextField
              name="woundCondition"
              fullWidth
              control={control}
              label="Wound Condition"
              placeholder="Enter condition of wound"
              dataTestmation=""
              errors={errors}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <FormTextField
              name="injuredBodyPart"
              fullWidth
              control={control}
              label="Injured BodyPart"
              placeholder="Enter name of injured part"
              dataTestmation=""
              errors={errors}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <FormTextField
              name="accidentDescription"
              fullWidth
              control={control}
              label="Accident Description"
              placeholder="Enter accident description"
              dataTestmation=""
              errors={errors}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <FormTextField
              name="reasonOfSickness"
              fullWidth
              control={control}
              label="Reason Of Sickness"
              placeholder="Enter sickness reason"
              dataTestmation=""
              errors={errors}
            />
          </Grid>
          {!isOpdScheme && (
            <>
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormTextField
                  name="dischargeType"
                  fullWidth
                  control={control}
                  label="Discharge type"
                  placeholder="Eg: normal"
                  dataTestmation=""
                  errors={errors}
                />
              </Grid>
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormTextField
                  name="dischargeSummary"
                  fullWidth
                  control={control}
                  label="Discharge Summary"
                  placeholder="Enter short discharge summary"
                  dataTestmation=""
                  errors={errors}
                />
              </Grid>
            </>
          )}
          <Grid size={12}>
            <Controller
              name="diagnosis"
              rules={{
                required: `Icd Code is required for every claim.`,
                validate: (value) => (!value.length ? "Please enter a valid phone number" : true)
              }}
              control={control}
              render={({ field: { onChange }, fieldState: { error } }) => (
                <>
                  <DiagnosisWithTreeView
                    disableCustomIcd
                    label="Diagnois is required"
                    isTitleHidden
                    onChange={(value: Partial<ICD>) => {
                      const newV = {
                        diagnosis: value.code ? `${value.code} - ${value.title}` : value.title,
                        icdCode: value.code || ""
                      };
                      onChange([...diagnosis, newV]);
                    }}
                  />
                  {error && (
                    <FormHelperText error sx={{ pl: 2 }}>
                      {error.message}
                    </FormHelperText>
                  )}
                </>
              )}
            />
          </Grid>
          <Grid size={12}>
            <Stack gap={1} width="100%">
              {diagnosis.map((item) => (
                <Box
                  key={item.diagnosis}
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    gap: 2
                  }}
                >
                  <Typography>{item.diagnosis}</Typography>
                  <IconButton
                    size="small"
                    onClick={() => {
                      setValue(
                        "diagnosis",
                        diagnosis.filter((i) => {
                          if (item.icdCode) {
                            return item.icdCode !== i.icdCode;
                          }
                          return item.diagnosis !== i.diagnosis;
                        })
                      );
                    }}
                  >
                    <HighlightOff />
                  </IconButton>
                </Box>
              ))}
            </Stack>
          </Grid>
          <Grid size={12}>
            <FormControl fullWidth variant="outlined" margin="normal">
              <InputLabel id="attachment-type-label">Attachment Type</InputLabel>
              <Select
                labelId="attachment-type-label"
                id="attachment-type-select"
                value={attachmentType}
                variant="outlined"
                size="small"
                onChange={(event) => setAttachmentType(event.target.value as ATTACHMENT_TYPE)}
                label="Attachment Type"
              >
                {Object.values(ATTACHMENT_TYPE).map((type) => (
                  <MenuItem key={type} value={type}>
                    {startCase(type)}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <AttachmentUploader
              label={`(Select ${startCase(attachmentType)} .pdf file up to 5MB)`}
              onFileSelected={(file) => {
                setValue("attachments", [...attachments, { type: attachmentType, file }]);
              }}
            />
            <Box mt={2}>
              {attachments.map((attachment: { type: string; file: File }, index) => (
                <Box key={attachment.file.name} display="flex" gap={2} alignItems="center">
                  <Typography>{`${attachment.file.name} (${startCase(
                    attachment.type
                  )})`}</Typography>
                  <IconButton
                    size="small"
                    onClick={() =>
                      setValue(
                        "attachments",
                        attachments.filter((item, currentItemIndex) => index !== currentItemIndex)
                      )
                    }
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Panel>
  );
};

export default ClaimForm;
