import React from "react";
import { Box, TextField, Autocomplete } from "@mui/material";
import { connect } from "react-redux";
import * as addressActions from "../../actions/addressOptions";
import { IThunkDispatch } from "../../store";
import { t, tl } from "../../components/translate";

export interface AddressOption {
  id: number;
  provinceName: string;
  districtName: string;
  palikaName: string;
  palikaId: number;
}

interface AddressSelectProps {
  onChange: (selectedOption: number) => void;
  addressOptions: AddressOption[];
  selectedOption: number;
  cssStyle?: React.CSSProperties;
  getAddressOptions: () => void;
  error?: { value: boolean; message: string };
  label?: string;
}

const AddressSelect: React.FC<AddressSelectProps> = ({
  selectedOption,
  addressOptions,
  cssStyle,
  onChange,
  getAddressOptions,
  error,
  label
}) => {
  React.useEffect(() => {
    if (addressOptions.length === 0) {
      getAddressOptions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getAddressOptions]);

  const getSelectedAddress = (id) => {
    if (!id) return null;
    return addressOptions.find((address) => address.palikaId === id);
  };
  return (
    <Box style={{ marginTop: cssStyle?.marginTop || "10px" }}>
      <Autocomplete
        value={getSelectedAddress(selectedOption)}
        options={addressOptions || []}
        getOptionLabel={(option: AddressOption) =>
          `${option.palikaName} , ${option.districtName} , ${option.provinceName}`
        }
        fullWidth
        onChange={(e, v) => {
          if (typeof v !== "string") onChange(v?.palikaId);
        }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            data-testmation="palikaSelect"
            label={label || tl("palika.District.Province")}
            variant="outlined"
            placeholder={t("palika.District.Province")}
            slotProps={{
              inputLabel: { shrink: true }
            }}
            error={error ? error.value : false}
            helperText={error?.message || ""}
          />
        )}
      />
    </Box>
  );
};

AddressSelect.defaultProps = {
  cssStyle: {}
};

export default connect(
  (state) => ({
    addressOptions: state.addressOptions.collection
  }),
  (dispatch: IThunkDispatch) => ({
    getAddressOptions: () => dispatch(addressActions.getAddressOptions())
  })
)(AddressSelect);
