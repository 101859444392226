import React from "react";
import { Box, Button, Fade } from "@mui/material";
import { RichText } from "prismic-reactjs";
import { KnowledgeBaseDocument } from "../../interfaces/KnowledgeBase";
import KnowledgeBaseReview from "./KnowledgeBaseReview";
import styles from "./styles.module.scss";

interface SelectedArticleProps {
  data: KnowledgeBaseDocument;
  onClose?: () => void;
}

function SelectedArticle({ data, onClose }: SelectedArticleProps): JSX.Element {
  return (
    <Fade in={!!data} timeout={300} unmountOnExit>
      <Box className={styles.selectedArticleRoot}>
        <h1>{data.data.title}</h1>
        <RichText render={data.data.article_content} />
        {onClose && (
          <Box mt={1} display="flex" justifyContent="end">
            <Button variant="outlined" size="small" onClick={() => onClose()}>
              Close
            </Button>
          </Box>
        )}

        <Box sx={{ marginTop: "20px", borderTop: "1px solid #DAE2ED" }}>
          <KnowledgeBaseReview knowledgebaseTitle={data?.data?.title || ""} />
        </Box>
      </Box>
    </Fade>
  );
}

SelectedArticle.defaultProps = {
  onClose: null
};

export default SelectedArticle;
