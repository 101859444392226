import React from "react";
import { useForm, FieldValues, Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { isArray, isObject } from "lodash";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Button,
  Grid2 as Grid,
  TextField,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Panel from "../../components/Panel";
import FormTextField from "../../components/FormTextField/FormTextField";
import ResourceCentreSelect from "./ResourceCentreSelect";
import { resourceCentreActions } from "../../actions";
import { RootState } from "../../store";
import { createUserGroup, updateUserGroup } from "../../slices/userGroupSlice";
import { notificationAdd } from "../../actions/notification";
import { replaceCommaWithLineBreak } from "./UserGroupDetail";

const isValidJSON = (json) => {
  try {
    const userGroup = JSON.parse(json);
    if (isArray(userGroup) && userGroup?.length) {
      return true;
    }
    if (isObject(userGroup) && Object.keys(userGroup)?.length) {
      return true;
    }
    return false;
  } catch (err) {
    return false;
  }
};
enum UserGroups {
  ResourceCentreEmployee = "resourceCentreEmployee",
  OkhatiAdmin = "okhatiAdmin",
  ServiceProvider = "serviceProvider"
}
export interface UserGroupForm {
  name: string;
  displayName: string;
  description: string;
  statements: string;
  resourceCentreId: number;
  userGroupType: string;
}

const UserGroupCreateEdit = ({
  onClose,
  userGroup,
  createMode = true
}: {
  onClose: () => void;
  createMode: boolean;
  userGroup: UserGroupForm;
}): JSX.Element => {
  const dispatch = useDispatch();
  const resourceCentres =
    useSelector((state: RootState) => state?.resources?.resourceCentres) || [];
  const {
    handleSubmit,
    control,
    watch,
    formState: { errors }
  } = useForm<FieldValues>({
    defaultValues: userGroup || {
      name: "",
      displayName: "",
      description: "",
      statements: "",
      resourceCentreId: "",
      userGroupType: ""
    },
    mode: "onChange"
  });

  React.useEffect(() => {
    if (!resourceCentres.length) {
      dispatch(resourceCentreActions.getResourceCentres());
    }
  }, [resourceCentres.length]);

  const { resourceCentreId } = watch();
  return (
    <Panel title={userGroup ? "Update User Group" : "Create User Group"} onClose={onClose}>
      <Grid container columnSpacing={2} rowSpacing={1} mt={1} px={2}>
        <Grid size={6}>
          <FormTextField
            dataTestmation="userGroupName"
            placeholder="Enter user group name"
            name="name"
            control={control}
            label="User Group Name"
            errors={errors}
            fullWidth
            rules={{ required: { value: true, message: "User Group name is required" } }}
          />
        </Grid>
        <Grid size={6}>
          <FormTextField
            dataTestmation="displayName"
            placeholder="Enter display name"
            name="displayName"
            control={control}
            label="User Group Display Name"
            errors={errors}
            fullWidth
            rules={{ required: { value: true, message: "User Group display name is required" } }}
          />
        </Grid>
        <Grid size={12}>
          <FormTextField
            dataTestmation="ugDescription"
            placeholder="Enter description"
            name="description"
            control={control}
            label="Description"
            errors={errors}
            multiline
            fullWidth
          />
        </Grid>
        <Grid size={6}>
          <ResourceCentreSelect
            errors={errors}
            control={control}
            options={resourceCentres.map((rc) => ({ label: rc.name, value: rc.id }))}
            value={resourceCentreId}
          />
        </Grid>
        <Grid size={6}>
          <Controller
            name="userGroupType"
            control={control}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
              <Autocomplete
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                options={Object.values(UserGroups)}
                getOptionLabel={(option) => option}
                onChange={(_, value) => onChange(value === UserGroups.OkhatiAdmin ? null : value)}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                    placeholder="Select user group type"
                    variant="outlined"
                    label="User group Type"
                    margin="normal"
                    error={!!error}
                    helperText={error?.message || " "}
                    sx={{ my: 0 }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <FormTextField
            dataTestmation="statements"
            placeholder="Policy Statement"
            name="statements"
            control={control}
            label="Policy Statement"
            errors={errors}
            multiline
            fullWidth
          />
        </Grid>
        <Grid size={12}>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <Typography>Previously Used Policies</Typography>
            </AccordionSummary>
            <AccordionDetails>
              {userGroup?.policy?.policyHistory?.map(({ statements }, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Box key={i} mt={1}>
                  <pre>{replaceCommaWithLineBreak(JSON.stringify(statements))}</pre>
                </Box>
              ))}
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          padding: "1rem 2rem",
          gap: 4,
          justifyContent: "flex-end"
        }}
      >
        <Button onClick={onClose}>Cancel</Button>
        <Button
          data-testmation="spCreate"
          color="primary"
          variant="contained"
          type="submit"
          onClick={handleSubmit((data) => {
            if (!isValidJSON(data.statements)) {
              dispatch(
                notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: "Invalid policy statements",
                  autoTimeout: true
                })
              );
              return;
            }
            if (data.id) {
              dispatch(
                updateUserGroup({
                  ...data,
                  statements: JSON.parse(data.statements?.replace(/\n/g, ""))
                })
              );
            } else {
              dispatch(
                createUserGroup({
                  ...data,
                  statements: JSON.parse(data.statements?.replace(/\n/g, ""))
                })
              );
            }
            onClose();
          })}
        >
          {createMode ? "Create" : "Update"}
        </Button>
      </Box>
    </Panel>
  );
};

export default UserGroupCreateEdit;
