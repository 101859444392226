import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { Badge, Menu, MenuItem, Theme } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid2";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import { makeStyles } from "@mui/styles";
import cx from "classnames";
import { push } from "connected-react-router";
import Fuse from "fuse.js";
import { isEmpty } from "lodash";
import * as React from "react";
import { useDispatch } from "react-redux";
import classNames from "../../helpers/classNames";
import useMobileScreen from "../../hooks/useMobileScreen";
import OList, { EmptyView } from "../OList";
import { DataType, PropsType } from "../OList/ListInterface";
import Search from "../Search";
import styles from "./styles.module.css";
import "./styles.scss";
import EmptyListIcon from "../../../assets/icons/EmptyListIcon";

export interface MultipleHeadersInterface {
  headers: Array<{ key: string; title: string | JSX.Element; goto: string; badge?: number }>;
  url: string;
}

interface MultipleHeaderProps {
  multipleHeaders: MultipleHeadersInterface;
  onChange?: () => void;
  useAsSubHeader?: boolean;
}
interface FilterDataProps {
  filters: Array<{ key: string; title: string }>;
  moreFilters?: Array<{ key: string; title: string }>;
}

interface FilterProps {
  filter: string;
  onFilter: (key) => void;
  filterData: FilterDataProps;
}
export interface ListType<T extends DataType> extends PropsType<T> {
  title?: string | JSX.Element;
  data: unknown[];
  customCreateButton?: JSX.Element;
  createLabel?: string | JSX.Element;
  createDropdownLabel?: string | JSX.Element;
  dropdownMenuItems?: string | JSX.Element;
  customButtonGroup?: string | JSX.Element;
  onDropdownClicked?: (e) => void;
  hideCreateButton?: boolean;
  additionalHeaderContent?: JSX.Element;
  withoutSearch?: boolean;
  showListHeader?: boolean;
  onEdit?: (id) => void;
  onDelete?: (id) => void;
  onCreateNew?: () => void;
  testmationLabel?: string;
  additionalHeaderFilters?: JSX.Element;
  errorRowIndexes?: Array<number>;
  multipleHeaders?: MultipleHeadersInterface;
  onSearch?: (searchText: string) => void;
  onCreateClick?: () => void;
  children: React.ReactNode;
  emptyViewContent?: React.ReactNode;
}

const getSearchableColumnKeys = (data) => Object.keys(data[0] || {});

const search = (data, searchText = "") => {
  let listItems = data;
  let toSearchText = searchText;
  if (searchText) {
    if (searchText.endsWith(" ")) {
      toSearchText = searchText.replace(" ", "");
    }
    const options = {
      id: "id",
      shouldSort: true,
      tokenize: true,
      matchAllTokens: true,
      threshold: 0.1,
      keys: getSearchableColumnKeys(data)
    };
    const fuse = new Fuse(data, options);
    const ids = fuse.search(toSearchText);
    listItems = ids.map((id) => data.find((item) => item.id === Number(id)));
  }
  return listItems;
};

export const ListRowActions = ({
  onEditRow,
  onDeleteRow
}: {
  onEditRow?: () => void;
  onDeleteRow?: () => void;
}): React.JSX.Element => (
  <>
    {onEditRow && (
      <IconButton
        onClick={(e) => {
          onEditRow();
          e.stopPropagation();
        }}
        size="large"
      >
        <EditIcon fontSize="small" />
      </IconButton>
    )}
    {onDeleteRow && (
      <IconButton
        onClick={(e) => {
          onDeleteRow();
          e.stopPropagation();
        }}
        size="large"
      >
        <DeleteIcon fontSize="small" />
      </IconButton>
    )}
  </>
);

export const InfoView = ({
  children,
  closeInfo
}: {
  children: React.ReactNode;
  closeInfo: () => void;
}): JSX.Element => (
  <div className={styles.rightSide}>
    <IconButton aria-label="delete" className={styles.closeInfo} size="small" onClick={closeInfo}>
      <ClearIcon fontSize="large" />
    </IconButton>
    {children}
  </div>
);

export const MultipleHeader = ({
  multipleHeaders,
  onChange,
  useAsSubHeader = false
}: MultipleHeaderProps): JSX.Element => {
  const dispatch = useDispatch();
  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Box display="flex" flexDirection="row" alignItems="center">
        {multipleHeaders?.headers?.map((header) => (
          <Box key={header.key} data-testmation={header.key}>
            <Typography component="span">
              <Box
                className={classNames(styles.filter, styles.header, {
                  [styles.active]: multipleHeaders?.url.includes(header.key),
                  [styles.subHeader]: useAsSubHeader
                })}
                onClick={() => {
                  if (!multipleHeaders?.url.includes(header.key)) {
                    dispatch(push(header.goto));
                    if (onChange) {
                      onChange();
                    }
                  }
                }}
              >
                {header?.badge ? (
                  <Badge color="primary" badgeContent={header.badge}>
                    {header.title}
                  </Badge>
                ) : (
                  header.title
                )}
              </Box>
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

MultipleHeader.defaultProps = {
  onChange: null
};

const useStyles = makeStyles((theme: Theme) => ({
  filtersRoot: {
    display: "flex",
    width: "auto",
    paddingTop: "8px",
    [theme.breakpoints.down("md")]: {
      maxWidth: "88vw",
      overflowX: "auto",
      whiteSpace: "pre"
    }
  }
}));
export const Filters = ({
  filter,
  onFilter,
  filterData = { filters: [], moreFilters: [] }
}: FilterProps): React.JSX.Element => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  return (
    <Box className={classes.filtersRoot}>
      {filterData?.filters.map((f) => (
        <Typography
          key={f.key}
          className={classNames(styles.filter, { [styles.active]: filter === f.key })}
          onClick={() => onFilter(f.key)}
          component="span"
          data-testmation={f.key}
        >
          <Box fontWeight="0.875em">{f.title}</Box>
        </Typography>
      ))}
      {filterData?.moreFilters && !isEmpty(filterData?.moreFilters) && (
        <>
          <Typography
            style={{ fontSize: "0.875rem" }}
            className={styles.filter}
            onClick={(e) => setAnchorEl(e.currentTarget)}
          >
            More...
          </Typography>
          <Menu
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            {filterData?.moreFilters.map((f) => (
              <MenuItem
                key={f.key}
                onClick={() => {
                  onFilter(f.key);
                  setAnchorEl(null);
                }}
              >
                {f.title}
              </MenuItem>
            ))}
          </Menu>
        </>
      )}
    </Box>
  );
};

export function ListHeader<T>({
  createLabel,
  createDropdownLabel,
  dropdownMenuItems,
  onDropdownClicked,
  customButtonGroup,
  onCreateClick,
  onSearch = undefined,
  hideCreateButton,
  title,
  additionalHeaderContent,
  testmationLabel,
  additionalHeaderFilters,
  customCreateButton,
  multipleHeaders
}: Partial<ListType<T & DataType>>): JSX.Element {
  const withAdditionalFilterStyles = {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center"
  };

  const isMobileScreen = useMobileScreen();
  return (
    <>
      <div
        style={!title ? { paddingTop: 0 } : {}}
        className={[styles.root, "rootContainer"].join(" ")}
      >
        <Grid container>
          <Grid
            xs={12}
            size={{ xs: 12, sm: isEmpty(multipleHeaders) ? 3 : 7 }}
            className="headContainer"
            alignItems="center"
          >
            {!isEmpty(multipleHeaders) && <MultipleHeader multipleHeaders={multipleHeaders} />}

            <Typography>
              <Box component="span" fontSize="20px" fontWeight={600}>
                {title || ""}
              </Box>
            </Typography>
          </Grid>
          <Grid
            size={{ xs: 12, sm: isEmpty(multipleHeaders) ? 9 : 5 }}
            style={withAdditionalFilterStyles}
            className="subHeadContainer"
          >
            <Box className="vListSearch">
              {onSearch && <Search testmation={testmationLabel} onSearch={onSearch} />}
            </Box>
            {!isMobileScreen && additionalHeaderFilters && (
              <Box display="flex" mr="25px">
                {additionalHeaderFilters}
              </Box>
            )}
            {isMobileScreen && additionalHeaderFilters && (
              <Box display="flex" mr="25px" className="searchContainer">
                {additionalHeaderFilters}
              </Box>
            )}
            {!hideCreateButton && !customCreateButton && (
              <Button
                variant="contained"
                color="primary"
                className={styles.button}
                onClick={onCreateClick || onDropdownClicked}
                data-testmation={testmationLabel}
              >
                <Typography variant="button">{createLabel || createDropdownLabel}</Typography>
              </Button>
            )}
            {customCreateButton && customCreateButton}
            {customButtonGroup && customButtonGroup}
            {createDropdownLabel && dropdownMenuItems}
          </Grid>
        </Grid>
        {additionalHeaderContent && (
          <Grid container className="smallHeaderContainer">
            {additionalHeaderContent}
          </Grid>
        )}
      </div>
    </>
  );
}

export default function List<T>(props: ListType<T & DataType>): React.JSX.Element {
  const {
    children,
    createLabel,
    createDropdownLabel,
    dropdownMenuItems,
    customButtonGroup,
    onDropdownClicked,
    title,
    onCreateNew,
    data,
    withoutSearch = false,
    showListHeader = true,
    additionalHeaderContent = null,
    testmationLabel,
    additionalHeaderFilters = null,
    errorRowIndexes = [],
    multipleHeaders = {} as MultipleHeadersInterface,
    rowHeight,
    emptyViewContent,
    ...rest
  } = props;

  const [listItems, setListItems] = React.useState<Array<T & DataType>>(data);
  const [searchText, setSearchText] = React.useState<string>("");

  React.useEffect(() => {
    setListItems(search(data, searchText));
  }, [data, searchText]);

  const getChildComponent = (componentType) =>
    React.Children.toArray(children).find(
      (child) => (child as unknown as { type: unknown }).type === componentType
    ) || null;

  const onSearch = (st = searchText) => {
    setSearchText(st);
    setListItems(search(data, st) || data);
  };

  const infoView = getChildComponent(InfoView);

  return (
    <div className={styles.listMainArea}>
      <div className={styles.leftSide}>
        {showListHeader && (
          <ListHeader
            testmationLabel={testmationLabel}
            title={title}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...(withoutSearch ? {} : { onSearch: (st) => onSearch(st) })}
            hideCreateButton={rest.hideCreateButton}
            createLabel={createLabel}
            createDropdownLabel={createDropdownLabel}
            dropdownMenuItems={dropdownMenuItems}
            customButtonGroup={customButtonGroup}
            onDropdownClicked={onDropdownClicked}
            onCreateClick={onCreateNew}
            additionalHeaderContent={additionalHeaderContent}
            additionalHeaderFilters={additionalHeaderFilters}
            customCreateButton={rest.customCreateButton}
            multipleHeaders={multipleHeaders}
          />
        )}
        <div
          className={cx(
            additionalHeaderContent ? styles.filtersListContainer : styles.listContainer,
            {
              [styles.noHeaderOffset]: !showListHeader
            }
          )}
        >
          <OList<T & DataType>
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...rest}
            data={listItems}
            rowHeight={rowHeight || 50}
            shouldSort={searchText?.length === 0}
            errorRows={errorRowIndexes}
          >
            <EmptyView>
              <Typography component="div" variant="body2">
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    height: "350px",
                    width: "100%"
                  }}
                >
                  <EmptyListIcon />
                  <Typography component="div">
                    <Box textAlign="center" marginTop="16px">
                      {emptyViewContent || "There are no items to display..."}
                    </Box>
                  </Typography>
                </Box>
              </Typography>
            </EmptyView>
            {children}
          </OList>
        </div>
      </div>
      {infoView}
    </div>
  );
}

List.defaultProps = {
  title: "",
  customCreateButton: null,
  createLabel: "",
  createDropdownLabel: "",
  dropdownMenuItems: "",
  customButtonGroup: null,
  onDropdownClicked: () => ({}),
  hideCreateButton: false,
  additionalHeaderContent: null,
  withoutSearch: false,
  showListHeader: true,
  onEdit: () => ({}),
  onDelete: () => ({}),
  onCreateNew: () => ({}),
  testmationLabel: "",
  additionalHeaderFilters: null,
  errorRowIndexes: [],
  multipleHeaders: {},
  onSearch: () => ({}),
  onCreateClick: () => ({})
};
