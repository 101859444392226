import React from "react";
import { Box, Typography } from "@mui/material";
import { scaleFont } from "../LabPrintFunctions";

export interface InfoFieldProps {
  label: string;
  data: JSX.Element | string | number;
  labelStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
  withColon?: boolean;
  highlight?: boolean;
}

const InfoField: React.FC<InfoFieldProps> = ({
  label,
  data,
  labelStyle = {},
  valueStyle = {},
  withColon = false,
  highlight = false
}) => {
  const baseLabelStyle = {
    fontSize: scaleFont("0.33cm"),
    color: "black",
    fontWeight: highlight ? 700 : 400,
    width: "2.8cm"
  };
  const baseValueStyle = {
    flex: 1,
    fontSize: scaleFont("0.33cm"),
    fontWeight: highlight ? 700 : 500
  };
  return (
    <Box display="flex" alignItems="flex-start">
      <Typography sx={{ ...baseLabelStyle, ...labelStyle }}>{label}</Typography>
      {withColon ? ": " : ""}
      <Typography sx={{ ...baseValueStyle, ...valueStyle }}>{data}</Typography>
    </Box>
  );
};

InfoField.defaultProps = {
  labelStyle: {},
  valueStyle: {}
};

export default InfoField;
