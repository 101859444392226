import * as React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Can from "../../Policy/Can";
import { BillDocumentSettingsType, BillDocumentType } from "../../../interfaces/BillInterfaces";
import { resourceCentreActions } from "../../../actions";
import BillingSettingsPanel from "../../ResourceCentre/ModuleSettings/Panels/BillingSettingsPanel";
import SettingsButton from "../../../components/SettingsButton/SettingsButton";

export const BillCounters = {
  counter1: "Counter 1",
  counter2: "Counter 2",
  counter3: "Counter 3"
};

function BillSettings({
  draft,
  updateSettings,
  updateBillCounter,
  isSettingsOpen,
  setIsSettingsOpen,
  onExtraReferrerSettingChange,
  isExtraReferrerEnabled
}: {
  draft: BillDocumentType;
  updateSettings: (value: BillDocumentSettingsType) => void;
  updateBillCounter: (value) => void;
  isSettingsOpen: boolean;
  setIsSettingsOpen: (value: boolean) => void;
  onExtraReferrerSettingChange: (value: boolean) => void;
  isExtraReferrerEnabled: boolean;
}): JSX.Element {
  return (
    <Can policyAccessKey="bill:changeSettings">
      <div>
        <SettingsButton dataTestmation="billing.settings" onClick={() => setIsSettingsOpen(true)} />
        {isSettingsOpen && (
          <BillingSettingsPanel
            showLocalisedSettings
            onClose={() => setIsSettingsOpen(false)}
            billDraft={draft}
            updateSettings={updateSettings}
            updateBillCounter={updateBillCounter}
            onExtraReferrerSettingChange={onExtraReferrerSettingChange}
            isExtraReferrerEnabled={isExtraReferrerEnabled}
          />
        )}
      </div>
    </Can>
  );
}

export default connect(null, (dispatch) => ({
  actions: bindActionCreators({ ...resourceCentreActions }, dispatch)
}))(BillSettings);
