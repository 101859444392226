import React from "react";
import { Box, Typography } from "@mui/material";
import { t } from "../../components/translate";
import styles from "./Assessment.module.css";
import DebouncedTextField from "../../components/DebouncedTextField";
import useAssessmentLabel from "../../hooks/useAssessmentLabel";

const HOPI = ({
  assessmentHOPI,
  onChange
}: {
  assessmentHOPI: string;
  onChange: (value) => void;
}): JSX.Element => {
  const assessmentLabels = useAssessmentLabel();
  return (
    <Box data-testmation="PastHistoryOfAllergy" className={styles.assessmentRow}>
      <Box className={styles.assessmentLabel}>
        <Typography component="span" fontWeight="600">
          {assessmentLabels.hopi}
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        <DebouncedTextField
          margin="dense"
          id="outlined-basic"
          data-testmation="hopiTextField"
          fullWidth
          multiline
          variant="outlined"
          rows="3"
          rowsMax="3"
          slotProps={{
            inputLabel: { shrink: true },
            htmlInput: { maxLength: 255 },
            formHelperText: { style: { position: "absolute", right: 0, bottom: 0, margin: 5 } }
          }}
          placeholder={t("assessment.typeHOPI")}
          value={assessmentHOPI}
          onChange={(e) => onChange(e.target.value)}
          showTextCounter
          debounceAt={400}
        />
      </Box>
    </Box>
  );
};

export default HOPI;
