import { Autocomplete, TextField } from "@mui/material";
import * as React from "react";
import { RootState } from "../../../store";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { getLabTestGroups } from "../../../slices/labTestSettingsSlice";

interface Props {
  value: string;
  onChange: (value: string) => void;
}
const LabTestCategorySelector = ({ value, onChange }: Props): JSX.Element => {
  const allTestGroups = useAppSelector((state: RootState) => state.labSettings.labTestGroups);
  const [category, setCategory] = React.useState(value);
  const dispatch = useAppDispatch();

  const categories = React.useMemo(
    () =>
      allTestGroups.reduce((acc, cur) => {
        if (!acc.includes(cur.category)) {
          acc.push(cur.category);
        }
        return acc;
      }, []),
    [allTestGroups]
  );

  React.useEffect(() => {
    setCategory(value);
    if (!allTestGroups.length) {
      dispatch(getLabTestGroups());
    }
  }, [value]);

  return (
    <Autocomplete
      freeSolo
      disablePortal
      disableClearable
      options={categories as Array<string>}
      fullWidth
      value={category}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          slotProps={{
            inputLabel: { shrink: true }
          }}
          label="Category Name"
          variant="outlined"
        />
      )}
      onBlur={() => onChange(category)}
      onChange={(_, v) => {
        setCategory(v);
      }}
    />
  );
};

export default LabTestCategorySelector;
