import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  Slide,
  Link
} from "@mui/material";
import React from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { TransitionProps } from "@mui/material/transitions";
import AndroidIcon from "@mui/icons-material/Android";
import AppleIcon from "@mui/icons-material/Apple";
import { useSelector } from "react-redux";
import { LoadingButton } from "@mui/lab";
import { RootState } from "../../store";
import androidGoogleAuthenticator from "../../../assets/images/androidGoogleAuthenticatorQr.png";
import appleGoogleAuthenticatorQr from "../../../assets/images/appleGoogleAuthenticatorQr.png";

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>
    // eslint-disable-next-line react/jsx-props-no-spreading
  ) => <Slide direction="up" ref={ref} {...props} />
);

enum Device {
  ANDROID = "android",
  APPLE = "apple"
}

export default function TwoFactorAuthQrScanModal({
  onSave,
  isLoading
}: {
  onSave: (token: string) => void;
  isLoading: boolean;
}): JSX.Element {
  const { required2FAToken, imageUrl } = useSelector((s: RootState) => s.twoFactorAuthInfo);
  const [token, setToken] = React.useState("");
  const [selectedDevice, setSelectedDevice] = React.useState<Device | null>(null);

  useHotkeys(
    "enter",
    () => {
      if (token) {
        onSave(token);
        if (selectedDevice) {
          setSelectedDevice(null);
        }
      }
    },
    {
      enableOnTags: ["INPUT", "SELECT", "TEXTAREA"]
    }
  );

  return (
    <div>
      <Dialog
        open={required2FAToken && imageUrl}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => ({})}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>Two Factor Authentication</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Download and set up Google Authenticator on your Android device by clicking{" "}
            <Link sx={{ cursor: "pointer" }} onClick={() => setSelectedDevice(Device.ANDROID)}>
              here
            </Link>
            , or for Apple devices, click{" "}
            <Link sx={{ cursor: "pointer" }} onClick={() => setSelectedDevice(Device.APPLE)}>
              here
            </Link>
            . To complete the setup, scan the provided QR code and enter the generated code to log
            in.
          </DialogContentText>
          <Box display="flex" justifyContent="center">
            <img width={200} alt="QR" src={imageUrl} />
          </Box>
          <Box display="flex" justifyContent="center">
            <TextField
              autoFocus
              value={token}
              onChange={({ target }) => setToken(target.value)}
              variant="outlined"
              label="Enter the 6 digit Code"
              slotProps={{
                inputLabel: { shrink: true }
              }}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={isLoading} variant="contained" onClick={() => onSave(token)}>
            Save
          </LoadingButton>
        </DialogActions>
        <Dialog
          open={Boolean(selectedDevice)}
          TransitionComponent={Transition}
          keepMounted
          onClose={() => setSelectedDevice(null)}
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogContent>
            {selectedDevice === Device.ANDROID ? (
              <>
                <Box display="flex" justifyContent="center">
                  <AndroidIcon />
                </Box>
                <img alt="google_authenticator" src={androidGoogleAuthenticator} />
                <Box display="flex" justifyContent="flex-end">
                  <Button size="small" variant="contained" onClick={() => setSelectedDevice(null)}>
                    OK
                  </Button>
                </Box>
              </>
            ) : (
              <>
                <Box display="flex" justifyContent="center">
                  <AppleIcon />
                </Box>
                <img alt="google_authenticator" src={appleGoogleAuthenticatorQr} />
                <Box display="flex" justifyContent="flex-end">
                  <Button size="small" variant="contained" onClick={() => setSelectedDevice(null)}>
                    OK
                  </Button>
                </Box>
              </>
            )}
          </DialogContent>
        </Dialog>
      </Dialog>
    </div>
  );
}
