/* eslint-disable jsx-a11y/control-has-associated-label */
import React, { JSX } from "react";
import capitalize from "lodash/capitalize";
import { connect } from "react-redux";
import { Typography, Box } from "@mui/material";
import Grid from "@mui/material/Grid2";
import ReactToPrint from "react-to-print";
import "./Print.scss";
import { clientFullNameSelector } from "../../reducers/client";
import { tl } from "../translate";
import { getDisplayAgeFromDOB } from "../../helpers/formatters";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import { Client } from "../../interfaces/ClientInterface";
import { getCustomerNumber } from "../../containers/Client/ClientList";

const DEFAULT_FONT_SCALE = 1.15;
export const scaleFont = (size: string, scale = DEFAULT_FONT_SCALE): string => {
  const fontSize = size.replace(/[^0-9.]/g, "");
  const suffix = size.replace(fontSize, "");
  return Number(fontSize) * scale + suffix;
};

export const DefaultLetterHead = ({
  resourceCentre
}: {
  resourceCentre: ResourceCentre;
}): JSX.Element => {
  const {
    resourceCentreLogo,
    name,
    slogan,
    address,
    city,
    registrationNumber,
    phone,
    optionalPhone,
    web,
    email,
    settings
  } = resourceCentre;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      pl={1}
      backgroundColor={resourceCentre.settings.printSettings.labPrintSettings.color}
    >
      <Box display="flex">
        {resourceCentreLogo && (
          <Box mt="8px">
            <img
              src={resourceCentreLogo.s3ResourceURL}
              className="billLogo"
              alt="logo"
              style={{ width: "78px", height: "auto" }}
            />
          </Box>
        )}
        <Box pl={resourceCentreLogo ? 2 : 0}>
          <Typography style={{ fontWeight: 500, fontSize: "0.6cm" }}>{name}</Typography>
          {slogan && (
            <Typography style={{ fontSize: "0.35cm", fontWeight: 600 }}>{slogan}</Typography>
          )}
          <Typography
            style={{ textAlign: "left", textTransform: "capitalize", fontSize: "0.325cm" }}
          >
            {address} {city}
          </Typography>
          {registrationNumber && (
            <Typography
              style={{ textAlign: "left", textTransform: "capitalize", fontSize: "0.325cm" }}
            >
              Reg No: {registrationNumber}
            </Typography>
          )}
        </Box>
      </Box>
      <Box>
        <Typography style={{ textAlign: "right", fontSize: "0.325cm" }}>
          {phone}
          {optionalPhone && `, ${optionalPhone}`}
        </Typography>
        <Typography style={{ textAlign: "right", fontSize: "0.325cm" }}>{web || ""}</Typography>
        <Typography style={{ textAlign: "right", fontSize: "0.325cm" }}>{email || ""}</Typography>
        {Boolean(settings?.billingSettings?.panNo) && (
          <Typography style={{ textAlign: "right", fontSize: "0.325cm" }}>
            Pan No: {settings.billingSettings.panNo}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export const CenteredLetterHead = ({
  resourceCentre
}: {
  resourceCentre: ResourceCentre;
}): JSX.Element => {
  const {
    resourceCentreLogo,
    name,
    slogan,
    address,
    city,
    registrationNumber,
    phone,
    optionalPhone,
    web,
    email,
    settings
  } = resourceCentre;
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      maxHeight="3.5cm"
      backgroundColor={settings.printSettings.labPrintSettings.color}
    >
      <Box style={{ width: "2.8cm", height: "auto" }}>
        {resourceCentreLogo && (
          <Box>
            <img
              src={resourceCentreLogo.s3ResourceURL}
              className="billLogo"
              alt="logo"
              style={{ width: "78px", height: "auto" }}
            />
          </Box>
        )}
      </Box>
      <Box flex={1} alignItems="center">
        <Typography style={{ fontWeight: 500, fontSize: "0.6cm", textAlign: "center" }}>
          {name}
        </Typography>
        {slogan && (
          <Typography style={{ fontSize: "0.35cm", fontWeight: 600, textAlign: "center" }}>
            {slogan}
          </Typography>
        )}
        <Typography
          style={{ textAlign: "center", textTransform: "capitalize", fontSize: "0.325cm" }}
        >
          {address} {city}
        </Typography>
        <Typography style={{ textAlign: "center", fontSize: "0.325cm" }}>
          {phone}
          {optionalPhone && `, ${optionalPhone}`} {email && `, ${email}`} {web && `, ${web}`}
        </Typography>
      </Box>
      <Box style={{ width: "2.8cm", height: "auto" }}>
        {registrationNumber && (
          <Typography
            style={{ textAlign: "right", textTransform: "capitalize", fontSize: "0.275cm" }}
          >
            Reg No: {registrationNumber}
          </Typography>
        )}
        {Boolean(settings?.billingSettings?.panNo) && (
          <Typography style={{ textAlign: "right", fontSize: "0.275cm" }}>
            Pan No: {settings.billingSettings.panNo}
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export const genderPrefix = {
  1: "Mr. ",
  2: "Mrs. "
};

export const InfoField = ({
  label,
  data,
  labelStyle = {},
  valueStyle = {},
  containerStyle = {}
}: {
  label: JSX.Element | string;
  data: unknown;
  labelStyle: React.CSSProperties;
  valueStyle: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}): JSX.Element => {
  const baseLabelStyle = {
    fontSize: scaleFont("0.33cm"),
    color: "black",
    fontWeight: 400,
    width: "2.8cm"
  };
  const baseValueStyle = {
    fontSize: scaleFont("0.33cm"),
    fontWeight: 500
  };
  return (
    <Box sx={{ display: "flex" }} style={{ ...containerStyle }}>
      <Typography sx={{ ...baseLabelStyle, ...labelStyle }}>{label}</Typography>
      <Typography sx={{ ...baseValueStyle, ...valueStyle }}>{data}</Typography>
    </Box>
  );
};

export const ClientInfo = ({
  client,
  customerNumber = null,
  extraInfo = null,
  clientIpd = null,
  registrationId = null,
  passportNo = null
}: {
  client: Client;
  customerNumber: string;
  extraInfo: string;
  passportNo: string;
}): JSX.Element => (
  <>
    <Typography
      style={{
        textAlign: "left",
        fontSize: scaleFont("0.42cm", 1),
        fontWeight: 600,
        paddingBottom: "8px"
      }}
    >
      {clientFullNameSelector(client)}
      {client.dob && `, ${getDisplayAgeFromDOB(client.dob)}`}
      {client.dob && client.gender && "/"}
      {client.gender && (client.gender === "1" ? " M" : " F")}
    </Typography>
    {customerNumber && (
      <InfoField
        label={tl("billPrint.customerNumber")}
        data={<>{getCustomerNumber(customerNumber)}</>}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {registrationId && (
      <InfoField
        label={<>Registration ID</>}
        data={<>{registrationId}</>}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {clientIpd && (
      <InfoField
        label={<>Patient id</>}
        data={<>{clientIpd}</>}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {(client.address || client.city) && (
      <InfoField
        label={<>{tl("address")}</>}
        data={
          <>
            {client.address && `${capitalize(client.address)},`}
            {client.city && ` ${capitalize(client.city)}`}
          </>
        }
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {client.phone && (
      <InfoField
        label={<>{tl("billing.phoneNo")}</>}
        data={
          <>{client.phone?.substring(0, 4) === "+977" ? client.phone.substring(4) : client.phone}</>
        }
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {passportNo && (
      <InfoField
        label={<>Passport no</>}
        data={<>{passportNo}</>}
        labelStyle={{ width: "140px", fontSize: scaleFont("0.28cm", 1.2) }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
      />
    )}
    {extraInfo && extraInfo}
  </>
);

const Print = ({
  resourceCentre,
  triggerAction,
  showLetterhead = true,
  letterHead = <DefaultLetterHead resourceCentre={resourceCentre} />,
  billTitle,
  client,
  clientIpd = false,
  customerNumber = null,
  extraClientInfo = null,
  documentInfo,
  children,
  qr = null
}) => {
  const printRef = React.useRef(null);
  return (
    <div style={{ width: "100%" }}>
      <ReactToPrint trigger={() => triggerAction} content={() => printRef.current} />
      <iframe title="printAssessment" className="displayContents">
        <div ref={printRef}>
          <Box component="table" width="100%">
            <tfoot>
              <tr>
                <td aria-label="content">
                  {/* footer offset */}
                  <div style={{ height: qr ? "4cm" : "2cm" }} />
                </td>
              </tr>
            </tfoot>
            {!showLetterhead && (
              <thead>
                <tr>
                  <td aria-label="content">
                    <div style={{ height: "3.5cm" }} />
                  </td>
                </tr>
              </thead>
            )}
            <tbody>
              <tr>
                <td>
                  {showLetterhead && letterHead && <>{letterHead}</>}
                  <Typography
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      fontSize: "0.45cm",
                      marginTop: "0.5cm",
                      marginBottom: "0.3cm",
                      fontWeight: 400,
                      textTransform: "uppercase"
                    }}
                  >
                    {billTitle}
                  </Typography>
                  <Box
                    component="div"
                    display="flex"
                    marginBottom="1cm"
                    marginTop="1cm"
                    justifyContent="space-between"
                  >
                    <Box component="div" width="50%">
                      <ClientInfo
                        client={client}
                        customerNumber={getCustomerNumber(customerNumber)}
                        extraInfo={extraClientInfo}
                        clientIpd={clientIpd}
                      />
                    </Box>
                    <Box component="div" width="40%">
                      {documentInfo}
                    </Box>
                  </Box>
                  <>{children}</>
                </td>
              </tr>
            </tbody>
          </Box>
          {showLetterhead ? (
            <Box
              component="div"
              paddingTop="0.5rem"
              display="flex"
              width="100%"
              className="billFooterPos borderTopBlack1"
            >
              <Grid container justifyContent="space-between">
                <Box component="span" marginLeft={2}>
                  {qr && qr}
                </Box>
                <Box component="span" marginRight={2} display="flex" justifyContent="space-between">
                  <Box textAlign="right" mr="16px">
                    <Typography>
                      <Box fontSize="0.3cm" style={{ textTransform: "uppercase" }}>
                        Powered By
                      </Box>
                      <Box className="footer-brand" fontSize="0.35cm">
                        Okhati
                      </Box>
                    </Typography>
                  </Box>
                  <Box className="pl-1">
                    <img
                      src="https://cdn-assets-cloud.frontify.com/local/frontify/h_lNxVXLqrDqb2kyrixW3lMmUl7n-aBRzJUzyvzD7_-xWNyzdNelgV4ke4zoF7uPQXJPVU25PimTum-JGbomXNz_WgyVrog_I64U35uLaL_zO1E-zaC6HtKw6uhv8wsp?width=800"
                      className="logo"
                      alt="logo"
                    />
                  </Box>
                </Box>
              </Grid>
            </Box>
          ) : (
            <Box height="56px" />
          )}
        </div>
      </iframe>
    </div>
  );
};

const mapStateToProps = (state) => {
  const { resourceCentres } = state.resources;
  const rcId = state.userContext.resourceCentreId;
  const getResourceCentre = (id: number, resourceCentresList: ResourceCentre[]) =>
    resourceCentresList.find((rc) => rc.id === id);
  return {
    resourceCentre: getResourceCentre(rcId, resourceCentres) || state.userContext.resourceCentre
  };
};

export default connect(mapStateToProps, null)(Print);
