import { useDispatch } from "react-redux";
import React, { useState, useMemo } from "react";
import { push } from "connected-react-router";
import { Autocomplete, TextField } from "@mui/material";
import useReportList from "./useReportList";

export default function ReportSearch(): JSX.Element {
  const dispatch = useDispatch();
  const reports = useReportList();

  const [input, setInput] = useState("");
  const [report, setReport] = useState(null);

  const handleChange = (event, val) => {
    if (val) {
      setReport(val);
      dispatch(push(val.path));
    }
  };

  const filteredReports = reports.filter((group) => group.canUserAccess);
  const allOptions = filteredReports.flatMap((group) => group.reports);

  // Filter options based on input
  const filteredOptions = useMemo(() => {
    if (input === "") return [];
    return allOptions.filter((option) => option.label.toLowerCase().includes(input.toLowerCase()));
  }, [allOptions, input]);

  return (
    <Autocomplete
      value={report}
      onChange={handleChange}
      input={input}
      onInputChange={(event, newinput) => {
        setInput(newinput);
      }}
      options={filteredOptions}
      getOptionLabel={(option) => option.label}
      sx={{ width: 300 }}
      disablePortal
      disableClearable
      handleHomeEndKeys
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          fullWidth
          variant="outlined"
          placeholder="Search a report"
          margin="dense"
          slotProps={{
            inputLabel: { shrink: true }
          }}
        />
      )}
      noOptionsText="No matching reports"
      open={input !== ""}
    />
  );
}
