import { Box, TextField, Typography } from "@mui/material";
import * as React from "react";
import styles from "../Assessment.module.css";
import useAssessmentLabel from "../../../hooks/useAssessmentLabel";

export interface ExtraOralData {
  tmz: string;
  lymphNodes: string;
  otherFindings: string;
}

interface Props {
  extraOralData: ExtraOralData;
  onChange: (v: ExtraOralData) => void;
}

export default function ExtraOral(props: Props): JSX.Element {
  const { extraOralData, onChange } = props;
  const assessmentLabels = useAssessmentLabel();
  return (
    <Box className={styles.assessmentRow} height="auto">
      <Box className={styles.assessmentLabel}>
        <Typography component="span" fontWeight="600">
          {assessmentLabels.extraOral}
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        <Box sx={{ display: "flex", gap: 2, width: "100%" }}>
          <TextField
            value={extraOralData.tmz}
            placeholder="Enter TMZ"
            margin="dense"
            fullWidth
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            onChange={(e) => {
              onChange({ ...extraOralData, tmz: e.target.value });
            }}
          />
          <TextField
            value={extraOralData.lymphNodes}
            placeholder="Enter lymph nodes"
            margin="dense"
            fullWidth
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            onChange={(e) => {
              onChange({ ...extraOralData, lymphNodes: e.target.value });
            }}
          />
          <TextField
            value={extraOralData.otherFindings}
            placeholder="Any other findings"
            margin="dense"
            fullWidth
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            onChange={(e) => {
              onChange({ ...extraOralData, otherFindings: e.target.value });
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}
