import { IPD } from "../interfaces/IpdInterface";
import { WardPayload } from "../slices/bedManagementSlice";
import { Get, Patch, Post } from "./apiHelper";

const wardsRoot = "/api/wards";
const bedsRoot = "/api/beds";
const ipdRoot = "/api/ipd";

export interface Ward {
  id: number;
  name: string;
  shortCode: string;
  resourceCentreId: number;
  departmentId: number;
  isRoom: boolean;
}

export interface Bed {
  id: number;
  number: string;
  resourceCentreId: number;
  wardId: number;
  roomId: number;
  ward?: Ward;
  activeIpds?: Array<Partial<IPD>>;
  customName?: string;
  name?: string;
}

export interface WardResponse {
  ward: Ward | null;
  beds: Array<Bed>;
}

export const getWards = async (): Promise<Ward> => {
  const response = await Get(wardsRoot, true);
  return response.data as Ward;
};

export const postWards = async (payload: Partial<WardPayload>): Promise<WardResponse> => {
  const response = await Post(wardsRoot, payload, true);
  return response.data as WardResponse;
};

export const getBeds = async (): Promise<Bed> => {
  const response = await Get(bedsRoot, true);
  return response.data as Bed;
};

export const addBeds = async (payload: Partial<Bed>): Promise<Array<Bed>> => {
  const response = await Post(bedsRoot, payload, true);
  return response.data as Array<Bed>;
};

export const transferIpdBed = async (
  ipdId: number,
  payload: { bedId: number; wardId: number }
): Promise<IPD> => {
  const response = await Patch(`${ipdRoot}/${ipdId}/transfer`, payload, true);
  return response.data as IPD;
};

export const updateWard = async (payload: Partial<Ward>): Promise<Ward> => {
  const response = await Patch(`${wardsRoot}/${payload.id}`, payload, true);
  return response.data as Ward;
};

export const updateBed = async (payload: Partial<Bed>): Promise<Bed> => {
  const response = await Patch(`${bedsRoot}/${payload.id}`, payload, true);
  return response.data as Bed;
};

export const getTakenBedsInWard = async (wardId: number): Promise<Array<number>> => {
  const response = await Get(`${bedsRoot}/takenbeds/${wardId}`, true);
  return response.data as Array<number>;
};
