import React from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  Typography
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import FullScreenIcon from "@mui/icons-material/Fullscreen";
import FullScreenExitIcon from "@mui/icons-material/FullscreenExit";
import startCase from "lodash/startCase";
import { GROUP_BOOKINGS_BY, GroupBookingsByType } from "./index";
import { tl } from "../../../components/translate";

interface HeaderInterfaceProps {
  fullscreenActive: boolean;
  enterFullscreen: () => void;
  exitFullscreen: () => void;
  showByStatus: boolean;
  setShowByStatus: () => void;
  onGroupBookingByChange: (value: GroupBookingsByType) => void;
  groupBookingsBy: GroupBookingsByType;
  showFollowUpOnly: boolean;
  setShowFollowUpOnly: React.Dispatch<React.SetStateAction<boolean>>;
}

const useStyles = makeStyles({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "62px",
    paddingLeft: "20px",
    paddingRight: "28px"
  },
  title: {
    fontWeight: 700,
    fontSize: "20px"
  },
  liveContainer: {
    display: "flex",
    alignItems: "center"
  },
  red: {
    background: "#F03636",
    borderRadius: "50%",
    width: "8px",
    height: "8px",
    marginRight: "4px"
  },
  live: {
    fontWeight: 500,
    fontSize: "12px",
    lineHeight: "18px",
    marginRight: "24px"
  },
  iconContainer: {
    width: "30px",
    height: "30px",
    cursor: "pointer",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      background: "#F1FFF3"
    }
  },
  icon: {
    color: "rgba(0, 0, 0, 0.54)",
    height: "18px",
    width: "18px"
  }
});

const Header = ({
  fullscreenActive,
  exitFullscreen,
  enterFullscreen,
  showByStatus,
  setShowByStatus,
  onGroupBookingByChange,
  groupBookingsBy,
  setShowFollowUpOnly,
  showFollowUpOnly
}: HeaderInterfaceProps): React.ReactElement => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <div className={classes.root}>
      <Typography variant="h2" className={classes.title}>
        {tl("dashboard.patientflow")}
      </Typography>
      <div className={classes.liveContainer}>
        <FormGroup
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={showFollowUpOnly}
                onClick={() => setShowFollowUpOnly((prev) => !prev)}
              />
            }
            label={
              <Typography variant="h5" className={classes.live}>
                {tl("dashboard.showFollowUpOnly")}
              </Typography>
            }
          />

          <FormControlLabel
            control={<Checkbox size="small" checked={showByStatus} onClick={setShowByStatus} />}
            label={
              <Typography variant="h5" className={classes.live}>
                {tl("dashboard.showbystatus")}
              </Typography>
            }
          />
        </FormGroup>
        <span className={classes.red} />
        <Typography variant="h5" className={classes.live}>
          Live
        </Typography>
        <div className={classes.iconContainer}>
          {fullscreenActive ? (
            <FullScreenExitIcon className={classes.icon} onClick={exitFullscreen} />
          ) : (
            <FullScreenIcon className={classes.icon} onClick={enterFullscreen} />
          )}
        </div>
        <Box sx={{ mr: -3 }}>
          <IconButton onClick={handleClick} size="small">
            <MoreVertIcon />
          </IconButton>
          <Menu
            disablePortal
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center"
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right"
            }}
          >
            {Object.values(GROUP_BOOKINGS_BY).map((item) => (
              <MenuItem
                key={item}
                dense
                onClick={() => {
                  handleClose();
                  if (item !== groupBookingsBy) onGroupBookingByChange(item);
                }}
              >
                <FormControlLabel
                  label={`Group By ${startCase(item)}`}
                  control={<Radio size="small" checked={groupBookingsBy === item} />}
                />
              </MenuItem>
            ))}
          </Menu>
        </Box>
      </div>
    </div>
  );
};

export default Header;
