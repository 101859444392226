import { TextField } from "@mui/material";
import * as React from "react";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { StockItemInterface, TransactionType } from "../../../../interfaces/StockInterfaces";
import { tl } from "../../../../components/translate";

interface Props {
  transactionType: TransactionType;
  item: StockItemInterface;
  onRateUpdate: (v) => void;
  error: boolean;
  onEnterPress: () => void;
}

export default function StockRate(props: Props): JSX.Element {
  const { transactionType, item, onRateUpdate, error, onEnterPress } = props;
  return (
    <div>
      <TextField
        disabled={[TransactionType.ADJUSTMENT].includes(transactionType as TransactionType)}
        error={error}
        value={item.price || 0}
        fullWidth
        label={tl("StockEntry.Rate")}
        data-testmation="itemRate"
        margin="none"
        onFocus={(e) => e.target.select()}
        type="number"
        variant="outlined"
        slotProps={{
          inputLabel: { shrink: true },
          input: {
            endAdornment: <KeyboardReturnIcon />
          }
        }}
        onChange={(e) => {
          onRateUpdate(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnterPress();
          }
        }}
      />
    </div>
  );
}
