import * as React from "react";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CalendarToday from "@mui/icons-material/CalendarToday";
import { slotFromTimeSelector, slotUntilTimeSelector } from "../../reducers/slots";
import { spFullNameSelector } from "../../reducers/serviceProvider";
import { CalendarFunctions as calFns } from "../../components/Calendar";
import styles from "./BookingCreateEdit.module.css";
import { ServiceProvider } from "../../interfaces/ServiceProvidersInterface";

const EventProvider = ({
  serviceProvider,
  slot
}: {
  serviceProvider: ServiceProvider;
  slot: any;
}): JSX.Element => {
  const profileImage = serviceProvider.user?.profileImage?.downloadLink;
  return (
    <Grid container style={{ flexGrow: 1 }}>
      <Grid size={12} className={styles.serviceProvider}>
        <div>
          <Avatar
            alt={serviceProvider.firstName}
            src={profileImage || "https://picsum.photos/100/100/?random"}
            className={styles.bigAvatar}
          />
        </div>
        <div className={styles.eventProvider}>
          <Typography component="div" variant="body2">
            <Box fontWeight={500} fontSize="1.1em" component="div">
              {spFullNameSelector(serviceProvider)}
            </Box>
            <Box fontWeight={400} component="span">
              {serviceProvider.speciality || ""}
            </Box>
          </Typography>
        </div>
      </Grid>
      <Grid size={12}>
        <Typography component="div" variant="body2">
          <Box className={styles.box} component="span" fontWeight={400}>
            <ScheduleIcon className={styles.icon} />
          </Box>
          <Box className={styles.box} component="span" fontWeight={400} m={1}>
            {slotFromTimeSelector(slot)}
          </Box>
          <Box className={styles.box} component="span" fontWeight={300} m={1}>
            -
          </Box>
          <Box className={styles.box} component="span" fontWeight={300} m={1}>
            {slotUntilTimeSelector(slot)}
          </Box>
        </Typography>
        <Typography component="div" variant="body2">
          <CalendarToday className={styles.icon} />
          <Box className={styles.box} component="span" fontWeight={400} m={1}>
            {calFns.bsFullDate(slot.from)}
          </Box>
          <Box className={styles.box} component="span" fontWeight={300} m={1}>
            {calFns.adFullDate(slot.from)}
          </Box>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default EventProvider;
