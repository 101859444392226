import moment from "moment";
import { Booking } from "../interfaces/BookingInterfaces";
import { clientFullNameSelector } from "../reducers/client";
import { getDefaultReminders } from "../containers/Calendar/Booking/BookingCreateEdit";

export const getDefaultSmsSubscriptions = (): { sendToSp: boolean; sendToClient: boolean } => ({
  sendToSp: true,
  sendToClient: true
});

export const getNewBooking = (defaults: Partial<Booking>): Partial<Booking> => {
  const { serviceProviderId, resourceCentreId, start, end, bookableResource, client, followUp } =
    defaults;
  return {
    id: undefined,
    start,
    end,
    clientId: client?.id || undefined,
    clientName:
      client?.firstName && client?.lastName
        ? clientFullNameSelector(client).toUpperCase()
        : undefined,
    clientPhoneNo: client?.phone || undefined,
    clientEmail: client?.email || undefined,
    clientAddress: client?.address || undefined,
    services: [],
    isVideoAppointment: false,
    paymentInfo: {
      amount: 0,
      isPaid: false,
      required: true
    },
    followUp: followUp || false,
    remarks: "",
    reasonOfVisit: "",
    reminders: getDefaultReminders(moment(start)).map((reminder) => ({
      ...reminder,
      on: reminder.on.toISOString()
    })),
    resourceCentreId,
    serviceProviderId,
    resourceId: undefined,
    referredBy: "",
    knownFrom: "",
    bookableResource,
    ...getDefaultSmsSubscriptions()
  };
};
