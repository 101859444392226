import { useDispatch } from "react-redux";
import React, { useState, useEffect } from "react";
import { Box, Chip, Divider, TextField, Typography } from "@mui/material";

import QrImageUploader from "./QrImageUploader";
import { tl } from "../../../../components/translate";
import { updateQrData } from "../../../../actions/resourceCentre";
import { getQrPaymentInfo } from "../../../../api/resourceCentre";
import { notificationAdd } from "../../../../actions/notification";
import { QrPaymentInfoType } from "../../../../interfaces/QrPayment";
import { StyledTab, StyledTabs } from "../../../../components/StyledTabs";
import { ResourceCentre } from "../../../../interfaces/ResourceCentreInterface";
import { QrPaymentMethodsEnum } from "../../../../enum/billing";
import useMobileScreen from "../../../../hooks/useMobileScreen";

export const qrPaymentMethods = [
  { value: "esewa", label: "eSewa" },
  { value: "khalti", label: "Khalti" },
  { value: "fonepay", label: "Fonepay" },
  { value: "bank", label: "Bank" }
];

interface QrPaymentInfoFormProps {
  resourceCentre: ResourceCentre;
}

const QrPaymentInfoForm = ({ resourceCentre }: QrPaymentInfoFormProps): JSX.Element => {
  const dispatch = useDispatch();
  const isMobile = useMobileScreen();
  const [activeTab, setActiveTab] = useState(qrPaymentMethods[0].value);
  const [qrImage, setQrImage] = useState<string>("");
  const [qrPaymentInfo, setQrPaymentInfo] = useState<QrPaymentInfoType>({
    esewa: {
      credentials: [{ id: "", name: "" }],
      images: [{ url: "", key: "" }]
    },
    khalti: {
      credentials: [{ id: "", name: "" }],
      images: [{ url: "", key: "" }]
    },
    fonepay: {
      credentials: [{ id: "", name: "" }],
      images: [{ url: "", key: "" }]
    },
    bank: {
      credentials: [{ id: "", name: "" }],
      images: [{ url: "", key: "" }]
    }
  });

  useEffect(() => {
    (async () => {
      try {
        const data = await getQrPaymentInfo(Number(resourceCentre?.id));
        setQrPaymentInfo({
          esewa: data?.qrPaymentInfo?.esewa || {
            credentials: [{ id: "", name: "" }],
            images: [{ url: "", key: "" }]
          },
          khalti: data?.qrPaymentInfo?.khalti || {
            credentials: [{ id: "", name: "" }],
            images: [{ url: "", key: "" }]
          },
          fonepay: data?.qrPaymentInfo?.fonepay || {
            credentials: [{ id: "", name: "" }],
            images: [{ url: "", key: "" }]
          },
          bank: data?.qrPaymentInfo?.bank || {
            credentials: [{ id: "", name: "" }],
            images: [{ url: "", key: "" }]
          }
        });
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: error.message,
            autoTimeout: true
          })
        );
      }
    })();
  }, []);

  useEffect(() => {
    setQrImage(qrPaymentInfo[activeTab].images[0].url || "");
  }, [activeTab, qrPaymentInfo]);

  const handleUpdateQrPaymentInfo = async () => {
    await dispatch(updateQrData(Number(resourceCentre?.id), qrPaymentInfo));
  };

  const handlePaymentInfoChange = (method, infoType, subfield, value) => {
    setQrPaymentInfo((prev) => {
      const updatedMethodInfo = {
        ...prev[method],
        [infoType]: [
          {
            ...prev[method][infoType][0],
            [subfield]: value
          }
        ]
      };

      return {
        ...prev,
        [method]: updatedMethodInfo
      };
    });
  };

  return (
    <Box>
      <Typography style={{ fontWeight: 600 }}>{tl("settings.qrPaymentReceiptMethod")}</Typography>
      <StyledTabs value={activeTab} onChange={(e, tab) => setActiveTab(tab)}>
        {qrPaymentMethods.map((method) => (
          <StyledTab
            key={method.value}
            value={method.value}
            label={tl(`settings.qrPaymentReceiptMethod.${method.value}`)}
          />
        ))}
      </StyledTabs>

      <Box marginTop="20px" width={`${isMobile ? "100%" : "40%"}`}>
        <QrImageUploader
          initialImage={qrImage}
          activePaymentMethod={activeTab}
          rcId={resourceCentre?.id}
          qrPaymentInfo={qrPaymentInfo}
          setQrPaymentInfo={setQrPaymentInfo}
        />

        {[QrPaymentMethodsEnum.ESEWA, QrPaymentMethodsEnum.KHALTI].includes(activeTab) && (
          <Divider style={{ marginTop: "30px", marginBottom: "30px" }}>
            <Chip label="Or Provide Credentials" size="small" />
          </Divider>
        )}

        {/* esewa */}
        {activeTab === QrPaymentMethodsEnum.ESEWA && (
          <Box display="flex" flexDirection="column" gap="20px">
            <TextField
              label="Esewa ID"
              placeholder="Enter Esewa ID/Number"
              value={qrPaymentInfo?.esewa?.credentials?.[0]?.id || ""}
              variant="outlined"
              type="text"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              onChange={(e) =>
                handlePaymentInfoChange("esewa", "credentials", "id", e.target.value)
              }
              onBlur={() => handleUpdateQrPaymentInfo()}
            />

            <TextField
              label="Name"
              placeholder="Enter Esewa Name"
              value={qrPaymentInfo?.esewa?.credentials?.[0]?.name || ""}
              variant="outlined"
              type="text"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              onChange={(e) =>
                handlePaymentInfoChange("esewa", "credentials", "name", e.target.value)
              }
              onBlur={() => handleUpdateQrPaymentInfo()}
            />
          </Box>
        )}

        {/* khalti */}
        {activeTab === QrPaymentMethodsEnum.KHALTI && (
          <Box display="flex" flexDirection="column" gap="20px">
            <TextField
              label="Khalti ID"
              placeholder="Enter Khalti ID/Number"
              value={qrPaymentInfo?.khalti?.credentials?.[0]?.id || ""}
              variant="outlined"
              type="text"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              onChange={(e) =>
                handlePaymentInfoChange("khalti", "credentials", "id", e.target.value)
              }
              onBlur={() => handleUpdateQrPaymentInfo()}
            />
            <TextField
              label="Name"
              placeholder="Enter Khalti Name"
              value={qrPaymentInfo?.khalti?.credentials?.[0]?.name || ""}
              variant="outlined"
              type="text"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              onChange={(e) =>
                handlePaymentInfoChange("khalti", "credentials", "name", e.target.value)
              }
              onBlur={() => handleUpdateQrPaymentInfo()}
            />
          </Box>
        )}

        {/**
         * Fonepay credentials is not implemented yet because
         * currently we dont have enough information on what params we need to generate its QR code
         */}
      </Box>
    </Box>
  );
};

export default QrPaymentInfoForm;
