import React, { useState, useEffect } from "react";
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid2 as Grid,
  MenuItem,
  ButtonGroup,
  Menu,
  IconButton,
  MenuProps
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import makeStyles from "@mui/styles/makeStyles";
import Rating from "@mui/material/Rating";
import { styled } from "@mui/styles";
import { Add, Remove } from "@mui/icons-material";
import { merge } from "lodash";
import { tl, t } from "../../../components/translate";
import { medicineType } from "./medicineType";
import Modal from "../../../components/Modal/Modal";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { addNewMedicine } from "../../../api/prescrption";
import { Medicine } from "../../../interfaces/AssessmentInterfaces";

const disableMedicationTimeForItems = [
  "Injection",
  "AMP",
  "Cream",
  "Drop",
  "Ear Drop",
  "Ear/Eye",
  "EYE",
  "Eye drop",
  "Eye Ointment",
  "Gel",
  "Inhaler",
  "Lotion",
  "Nasal Drop",
  "Nasal Spray",
  "Ointment",
  "PATCH",
  "Spray",
  "VIAL",
  "Facewash",
  "Serum",
  "Shampoo",
  "Toothpaste"
];

export enum Times {
  DAY = "day",
  WEEK = "week",
  MONTH = "month"
}

export enum DaysOfWeek {
  SUNDAY = "sunday",
  MONDAY = "monday",
  TUESDAY = "tuesday",
  WEDNESDAY = "wednesday",
  THURSDAY = "thursday",
  FRIDAY = "friday",
  SATURDAY = "saturday"
}

const timesOptions = Object.values(merge(Times, DaysOfWeek));

const disabledDayAndMonth = (times) => Object.values(DaysOfWeek).includes(times);
const isInjectionAndFrequencyNotSelected = (form: string, frequency: number) =>
  form === "Injection" && !frequency;

const useStyles = makeStyles((theme) => ({
  leftPaddingNoneSm: {
    paddingLeft: 1
  },
  [theme.breakpoints.down("sm")]: {
    leftpaddingNoneSm: {
      paddingLeft: 0,
      marginTop: "8px",
      marginBottom: "8px"
    },
    leftpaddingOneSm: {
      paddingLeft: 0,
      marginTop: "8px",
      marginBottom: "8px"
    }
  },
  leftPaddingOneSm: {
    paddingLeft: 2
  }
}));

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right"
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right"
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(() => ({
  "& .MuiPaper-root": {
    boxShadow: "0px 4px 16px rgba(0, 150, 84, 0.16)",
    "& .MuiMenu-list": {
      padding: "4px 0"
    },
    height: "130px",
    overflow: "auto",
    scrollbarWidth: "thin",
    "&::-webkit-scrollbar": {
      width: "0.3em"
    },
    "&::-webkit-scrollbar-track": {
      background: "#f1f1f1",
      borderRadius: "10px",
      marginTop: "10px",
      marginBottom: "10px"
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#d7d7d7",
      borderRadius: "10px"
    },
    "&::-webkit-scrollbar-thumb:hover": {
      background: "#d7d7d7"
    },
    borderRadius: 5
  }
}));

const MedicationDialog = ({
  modalIsOpen,
  handleModalSave,
  handleCancel,
  data,
  spID,
  isProductMedication
}: {
  modalIsOpen: boolean;
  handleModalSave: (data: Medicine) => void;
  handleCancel: () => void;
  data: Medicine;
  spID?: number | string;
  isProductMedication?: boolean;
}): JSX.Element => {
  const [brand, setBrand] = useState("");
  const [form, setForm] = useState("Tablet");
  const [strength, setStrength] = useState("");
  const [frequency, setFrequency] = useState(0);
  const [medicationTime, setMedicationTime] = useState(isProductMedication ? "" : "after");
  const [durationAmount, setDurationAmount] = useState(isProductMedication ? 0 : 7);
  const [times, setTimes] = useState(isProductMedication ? "" : "day");
  const [durationFormat, setDurationFormat] = useState(isProductMedication ? "" : "day");
  const [remarks, setRemarks] = useState("");
  const [genericName, setGenericName] = useState("");
  const FrequencyPicker = styled(Rating)({
    "& .MuiRating-iconFilled": {
      fill: "#ff3d47"
    },
    "& .MuiRating-iconHover": {
      fill: "#ff3d47"
    },
    "& .MuiRating-iconEmpty": {
      fill: "inherit"
    }
  });

  useEffect(() => {
    if (isProductMedication) {
      setGenericName(data.genericName);
    }
    setBrand(data.brand);
    setForm(data.form);
    setStrength(data.strength);
    if (data.form === "Injection") {
      setFrequency(0);
    } else {
      setFrequency(isProductMedication ? 0 : 3);
    }
    if (disableMedicationTimeForItems.includes(data.form) || isProductMedication) {
      setMedicationTime(null);
    } else {
      setMedicationTime("after");
    }
  }, [data, isProductMedication]);

  const handleSave = () => {
    if (data) {
      const newObj = {
        id: data.id,
        duration: durationAmount,
        form,
        meal: medicationTime,
        brand,
        remark: remarks,
        strength,
        frequency,
        genericName: genericName || data.genericName,
        frequencyType: durationFormat,
        times
      };
      const newMed = {
        medicineBrandId: data.id || null,
        brand,
        strength,
        serviceProviderId: spID || null,
        form
      };
      if (
        (data.brand !== brand || data.strength !== strength || data.form !== form) &&
        !isProductMedication
      ) {
        addNewMedicine(newMed);
      }
      // eslint-disable-next-line no-unused-expressions
      newObj && handleModalSave(newObj);
    }
  };
  const classes = useStyles();
  const isMobileScreen = useMobileScreen();

  const setInitialStateForMedication = () => {
    setMedicationTime("after");
    setDurationAmount(7);
    setDurationFormat("day");
    setFrequency(3);
    setRemarks("");
    setTimes("day");
    setGenericName("");
  };
  const setInitialStateForProductMedication = () => {
    setMedicationTime("");
    setDurationAmount(0);
    setDurationFormat("");
    setFrequency(0);
    setRemarks("");
    setTimes("");
    setGenericName("");
  };
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  return (
    <>
      <Modal
        open={modalIsOpen}
        onClose={() => {
          handleCancel();
          if (!isProductMedication) {
            setInitialStateForMedication();
          } else {
            setInitialStateForProductMedication();
          }
        }}
        onSave={() => {
          handleSave();
          if (!isProductMedication) {
            setInitialStateForMedication();
          } else {
            setInitialStateForProductMedication();
          }
        }}
        title={tl("assessment.createNewMedication")}
      >
        <Grid container spacing={1}>
          <Grid size={{ xs: 12, sm: 7 }}>
            <TextField
              label={t("assessment.medicineName")}
              variant="outlined"
              size="small"
              fullWidth
              value={brand}
              onChange={(e) => {
                setBrand(e.target.value);
              }}
              data-testmation="medicationNameTextField"
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 2 }}>
            <Box
              className={classes.leftpaddingOneSm}
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <TextField
                label={t("assessment.medicationStrength")}
                variant="outlined"
                size="small"
                fullWidth
                value={strength}
                onChange={(e) => setStrength(e.target.value)}
              />
            </Box>
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="center"
              className={classes.leftPaddingOneSm}
            >
              <TextField
                id="outlined-basic"
                label={t("type")}
                variant="outlined"
                size="small"
                fullWidth
                select
                value={form}
                onChange={(e) => {
                  setForm(e.target.value);
                  if (disableMedicationTimeForItems.includes(e.target.value)) {
                    setMedicationTime(null);
                  }
                }}
                data-testmation="medicationTypeDropDown"
              >
                {Object.keys(medicineType).map((item) => (
                  <MenuItem key={item} value={item}>
                    {item}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
          <Grid size={12}>
            {isProductMedication ? (
              <TextField
                variant="outlined"
                value={genericName}
                label="Generic name"
                onChange={({ target }) => setGenericName(target.value)}
              />
            ) : (
              data.genericName && (
                <Grid size={12}>
                  <Box pl={1} pt="4px" fontSize="0.7rem">
                    {data.genericName}
                  </Box>
                </Grid>
              )
            )}
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box pt={2} display="flex" alignItems="center">
              <FrequencyPicker
                name="simple-controlled"
                value={frequency}
                size="large"
                onChange={(e, v) => {
                  setFrequency(v);
                }}
                icon={(form && medicineType[form]()) || medicineType.Tablet()}
                emptyIcon={form && medicineType[form]()}
                data-testmation="frequencyPickerSelector"
              />
              <Box pl={2} display="flex" alignItems="center">
                {tl("assessment.times")}
                <Box display="flex" alignItems="center">
                  <Typography>{times}</Typography>
                  <IconButton
                    size="small"
                    sx={{ cursor: "pointer" }}
                    onClick={(e: React.MouseEvent<HTMLElement>) => setAnchorEl(e.currentTarget)}
                  >
                    <ArrowDropDownIcon />
                  </IconButton>
                </Box>
                <StyledMenu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={() => setAnchorEl(null)}
                >
                  {timesOptions.map((item) => (
                    <MenuItem
                      onClick={() => {
                        setTimes(item);
                        if (Object.values(DaysOfWeek).includes(item as DaysOfWeek)) {
                          setDurationFormat("week");
                        } else {
                          setDurationFormat(item);
                        }
                        setAnchorEl(null);
                      }}
                      key={item}
                      value={item}
                    >
                      {item}
                    </MenuItem>
                  ))}
                </StyledMenu>
              </Box>
            </Box>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <Box pt={2}>
              <ButtonGroup
                size="small"
                aria-label="small outlined button group"
                disabled={disableMedicationTimeForItems.includes(form)}
              >
                <Button
                  variant="outlined"
                  color={medicationTime === "before" ? "primary" : "inherit"}
                  onClick={() => setMedicationTime("before")}
                  data-testmation="medicationBeforeMealButton"
                >
                  {tl("assessment.beforeMeal")}
                </Button>
                <Button
                  variant="outlined"
                  color={medicationTime === "after" ? "primary" : "inherit"}
                  onClick={() => setMedicationTime("after")}
                  data-testmation="medicationAfterMealButton"
                >
                  {tl("assessment.afterMeal")}
                </Button>
              </ButtonGroup>
            </Box>
          </Grid>
          <Grid size={12}>
            <Box pt={2}>
              <Typography>
                <b>{tl("assessment.duration")}</b>
              </Typography>
            </Box>
          </Grid>
          <Grid size={12}>
            <Box pt={2} display="flex" flexDirection={isMobileScreen ? "column" : "row"}>
              <Box>
                <Button
                  size="large"
                  onClick={() => setDurationAmount(Number(durationAmount) - 1)}
                  disabled={
                    durationAmount <= 1 || isInjectionAndFrequencyNotSelected(form, frequency)
                  }
                  data-testmation="medicationAddDaysButton"
                >
                  <Remove />
                </Button>
                <TextField
                  type="number"
                  variant="outlined"
                  color="primary"
                  value={durationAmount}
                  slotProps={{
                    htmlInput: { style: { textAlign: "center", width: "50px", padding: "10px" } }
                  }}
                  onChange={(e) => setDurationAmount(Number(e.target.value))}
                  data-testmation="medicationSetDaysTextField"
                  disabled={isInjectionAndFrequencyNotSelected(form, frequency)}
                />
                <Button
                  size="large"
                  onClick={() => setDurationAmount(Number(durationAmount) + 1)}
                  data-testmation="medicationRemoveDaysButton"
                  disabled={isInjectionAndFrequencyNotSelected(form, frequency)}
                >
                  <Add />
                </Button>
              </Box>
              <Box pl={2} pt={isMobileScreen && "16px"}>
                <ButtonGroup
                  size="small"
                  aria-label="small outlined button group"
                  disabled={isInjectionAndFrequencyNotSelected(form, frequency)}
                >
                  <Button
                    size="large"
                    color={durationFormat === "day" ? "primary" : "inherit"}
                    onClick={() => setDurationFormat("day")}
                    data-testmation="medicationDurationDaysButton"
                    disabled={times === "week" || times === "month" || disabledDayAndMonth(times)}
                  >
                    {tl("assessment.days")}
                  </Button>
                  <Button
                    size="large"
                    color={
                      durationFormat === "week" ||
                      Object.values(DaysOfWeek).includes(times as DaysOfWeek)
                        ? "primary"
                        : "inherit"
                    }
                    onClick={() => setDurationFormat("week")}
                    data-testmation="medicationDurationWeeksButton"
                    disabled={times === "month"}
                  >
                    {tl("assessment.week")}
                  </Button>
                  <Button
                    size="large"
                    color={durationFormat === "month" ? "primary" : "inherit"}
                    onClick={() => setDurationFormat("month")}
                    data-testmation="medicationDurationMonthsButton"
                    disabled={disabledDayAndMonth(times)}
                  >
                    {tl("assessment.month")}
                  </Button>
                </ButtonGroup>
              </Box>
            </Box>
          </Grid>
          <Grid size={12}>
            <Box pt={2}>
              <TextField
                label={t("assessment.medicationRemark")}
                variant="outlined"
                size="small"
                fullWidth
                value={remarks}
                slotProps={{
                  htmlInput: { maxLength: 200 }
                }}
                onChange={(e) => setRemarks(e.target.value)}
                data-testmation="medicationRemarksTextField"
              />
            </Box>
          </Grid>
        </Grid>
      </Modal>
    </>
  );
};

export default MedicationDialog;

MedicationDialog.defaultProps = {
  isProductMedication: false,
  spID: null
};
