import { TextField } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { notificationAdd } from "../../../../actions/notification";

interface Props {
  value: number;
  onQtyUpdate: (v) => void;
  error: boolean;
  label: string;
}

export default function StockQuantity(props: Props): JSX.Element {
  const { value, onQtyUpdate, error, label } = props;
  const dispatch = useDispatch();
  return (
    <div>
      <TextField
        type="number"
        data-testmation="itemQuantity"
        error={error}
        value={value}
        slotProps={{
          inputLabel: { shrink: true }
        }}
        fullWidth
        label={label}
        variant="outlined"
        onFocus={(e) => e.target.select()}
        onChange={(e) => {
          let quantity = Number(e.target.value);
          if (quantity % 1 !== 0) {
            dispatch(
              notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: "Quantity can't have decimal values.",
                autoTimeout: true
              })
            );
            return;
          }
          if (quantity < 1) {
            quantity = 0;
          }
          onQtyUpdate(quantity);
        }}
      />
    </div>
  );
}
