import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import qs from "query-string";
import moment from "moment";
import { connect } from "react-redux";
import capitalize from "lodash/capitalize";
import Barcode from "react-barcode";
import { isEmpty, has } from "lodash";
import { getLabTestsRecordByUUID } from "../../../api/labTest";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { clientFullNameSelector } from "../../../reducers/client";
import LabPrint from "./index";
import { LabPrintSettings } from "../../../interfaces/LabInterfaces";
import DefaultTemplate from "./Templates/DefaultTemplate";
import BorderedTemplate from "./Templates/BorderedTemplate";
import AuthorizedSPInfo from "./LabprintComponents/AuthorizedSPInfo";
import InfoField from "./LabprintComponents/LabPrintInfofield";
import {
  addDefaultFormData,
  AdvancedLabRangeHandler,
  advancedLabRangeIndicator,
  createMarkup,
  groupLabTestByCategory
} from "./LabPrintFunctions";
import { getDisplayAgeFromDOB } from "../../../helpers/formatters";
import { handleLabTestVersions } from "../../../reducers/labTest";
import GovernmentTemplate from "./Templates/GovernmentTemplate";
import { RootState } from "../../../store";
import { t } from "../../../components/translate";
import styles from "../Lab.module.css";
import { EditableTableView } from "../../../components/EditableTable/EditableTable";
import { LabRecord, LabStatuses, LabTestRecordResultData } from "../../../interfaces/Lab";
import { removeCommasFromNumber } from "../../../helpers/number";
import { getCustomerNumber } from "../../Client/ClientList";
import isAndroidDevice from "../../../helpers/android";
import useIsReactNativeWebView from "../../../hooks/useIsReactNativeWebView";

export const appLink =
  "https://play.app.goo.gl/?link=https://play.google.com/store/apps/details?id=com.okhatiapp";

export const ViewInBrowserOrAppBanner = (): JSX.Element | null => {
  const [showAppBanner, setShowAppBanner] = useState(true);
  const { isRnWebView } = useIsReactNativeWebView();

  if (isAndroidDevice() && !isRnWebView && showAppBanner) {
    return (
      <Box
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        borderTop="1px solid green"
        p={2}
        bgcolor="white"
      >
        <Box display="flex" gap={2} justifyContent="center">
          <Button onClick={() => setShowAppBanner(false)} variant="outlined">
            Continue on browser
          </Button>
          <Button
            onClick={() => {
              window.location.href = appLink;
            }}
            variant="contained"
          >
            Open App
          </Button>
        </Box>
      </Box>
    );
  }
  return null;
};

const CustomEl = ({ data }) => {
  const formattedData = isEmpty(data) ? null : data;
  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={createMarkup(formattedData)}
      className={styles.additionalData}
    />
  );
};

const SubRow = ({
  pl,
  lt,
  children = <></>,
  isChild = false,
  gender,
  showMethods,
  hideEmpty,
  dob
}) => {
  const commonStyles = {
    fontSize: "0.75rem",
    display: "flex"
  };

  const rangeSymbol = advancedLabRangeIndicator(
    removeCommasFromNumber(lt.formData.reading),
    lt.ranges,
    dob,
    gender
  );

  const show = !isChild || !hideEmpty ? true : Boolean(lt.formData.reading);

  return (
    <>
      {show && (
        <Box display="flex" py="2px" width="100%">
          <Box flex={1} style={{ paddingLeft: `${pl}px` }}>
            <Typography style={{ fontSize: "0.75rem" }}>{lt.name}</Typography>
          </Box>
          <Typography
            style={{
              ...commonStyles,
              fontWeight: rangeSymbol ? 600 : "normal"
            }}
            flexBasis="80px"
          >
            {has(lt, "formData") && lt.formData.reading}
            {rangeSymbol.symbol}
          </Typography>
          <Typography style={commonStyles} flexBasis="80px">
            {!(lt.subTests?.length > 0) && has(lt, "unit") && lt.unit}
          </Typography>
          <Typography style={commonStyles} flexBasis="100px">
            {!(lt.subTests?.length > 0) && has(lt, "ranges") && (
              <AdvancedLabRangeHandler range={lt.ranges} dob={dob} gender={gender} />
            )}
          </Typography>
          {showMethods && (
            <Typography style={commonStyles} flexBasis="100px">
              {lt.methods}
            </Typography>
          )}
        </Box>
      )}
      {children}

      {
        lt.subTests?.map((sT) => (
          <div key={sT.name}>
            <SubRow
              key={sT.id}
              pl={pl + 10}
              lt={addDefaultFormData(sT)}
              hideEmpty={hideEmpty}
              isChild
              gender={gender}
              showMethods={showMethods}
              dob={dob}
            />
          </div>
        )) as unknown as JSX.Element
      }
    </>
  );
};

interface RowProps {
  item: LabTestRecordResultData;
  gender: "1" | "2";
  showAdditionalLabData: boolean;
  showMethods?: boolean;
  hideEmpty?: boolean;
  dob: string;
}

export const Row = ({
  item,
  gender,
  showAdditionalLabData,
  showMethods,
  hideEmpty,
  dob
}: RowProps): JSX.Element => (
  <div>
    {item.labTests.length > 1 && (
      <Typography
        style={{
          fontWeight: 600,
          paddingTop: "4px",
          paddingBottom: "4px",
          fontSize: "0.75rem"
        }}
      >
        {item.name}
      </Typography>
    )}
    {item.labTests?.map((lt) => (
      <SubRow
        key={lt.id}
        pl={item.labTests.length > 1 ? 10 : 0}
        lt={addDefaultFormData(lt)}
        gender={gender}
        hideEmpty={hideEmpty}
        showMethods={showMethods}
        dob={dob}
      />
    ))}
    {showAdditionalLabData && item?.additionalTestData && (
      <div style={{ whiteSpace: "pre-wrap" }}>
        <CustomEl data={item?.additionalTestData} />
      </div>
    )}
    <div>
      {item?.interpretationTemplate && (
        <EditableTableView tableState={item?.interpretationTemplate} />
      )}
    </div>
  </div>
);

Row.defaultProps = {
  showMethods: false,
  hideEmpty: false
};

const renderForMobileView = (
  labRecord,
  resourceCentre,
  settings,
  isMobileWebView,
  isResultAvailable
) => {
  const { client } = labRecord;
  const { data } = groupLabTestByCategory(labRecord).results;

  const labRecordLabelStyle = { width: "160px", fontSize: "0.75rem" };
  const labRecordValueStyle = { fontSize: "0.75rem" };
  const headers = ["Test", "Reading", "Unit", "Range"];
  const enum LabHeadersFlexBasis {
    "Reading" = "80px",
    "Unit" = "80px",
    "Range" = "100px",
    "Methods" = "100px"
  }
  if (settings?.showMethods) headers.push("Methods");

  return (
    <Box
      sx={{
        maxWidth: "100vw",
        padding: "8px"
      }}
    >
      {!isMobileWebView && isResultAvailable && (
        <Box mb="8px">
          <Button variant="contained">
            <LabPrint resourceCentre={resourceCentre} settings={settings} labData={labRecord} />
          </Button>
        </Box>
      )}

      {isResultAvailable ? (
        <>
          <Box
            sx={{
              display: "flex",
              pb: "24px"
            }}
          >
            <Box>
              <Typography style={{ fontWeight: 600 }}>
                {clientFullNameSelector(client)}
                {client.dob && `, ${getDisplayAgeFromDOB(client.dob)}`}
                {client.dob && client.gender && "/"}
                {client.gender && (client.gender === "1" ? " M" : " F")}
              </Typography>
              <InfoField
                label="Customer ID"
                data={<>{getCustomerNumber(labRecord.customerNumber)}</>}
                labelStyle={labRecordLabelStyle}
                valueStyle={labRecordValueStyle}
              />

              {client.registrationNo && (
                <InfoField
                  label="Registration ID"
                  data={<>{client.registrationNo}</>}
                  labelStyle={labRecordLabelStyle}
                  valueStyle={labRecordValueStyle}
                />
              )}
              {client.ipdNo && (
                <InfoField
                  label="Patient id"
                  data={<>{client.ipdNo}</>}
                  labelStyle={labRecordLabelStyle}
                  valueStyle={labRecordValueStyle}
                />
              )}
              {client.phone && (
                <InfoField
                  label="Phone No. "
                  data={
                    <>
                      {client.phone?.substring(0, 4) === "+977"
                        ? client.phone.substring(4)
                        : client.phone}
                    </>
                  }
                  labelStyle={labRecordLabelStyle}
                  valueStyle={labRecordValueStyle}
                />
              )}
              {client.passportInfo?.passportNumber && (
                <InfoField
                  label="Passport no"
                  data={<>{client.passportInfo.passportNumber}</>}
                  labelStyle={labRecordLabelStyle}
                  valueStyle={labRecordValueStyle}
                />
              )}
              {(client.address || client.city) && (
                <InfoField
                  label="Address"
                  data={
                    <>
                      {client.address && `${capitalize(client.address)},`}
                      {client.city && ` ${capitalize(client.city)}`}
                    </>
                  }
                  labelStyle={labRecordLabelStyle}
                  valueStyle={labRecordValueStyle}
                />
              )}

              {client.nationality && (
                <InfoField
                  label={t("nationality")}
                  data={client.nationality}
                  labelStyle={labRecordLabelStyle}
                  valueStyle={labRecordValueStyle}
                />
              )}

              {labRecord.collectionDate && (
                <InfoField
                  label="Sample Collected"
                  data={moment(labRecord.collectionDate).format("LL")}
                  labelStyle={labRecordLabelStyle}
                  valueStyle={labRecordValueStyle}
                />
              )}
              {labRecord.resultDate && (
                <InfoField
                  label="Reported on"
                  data={moment(labRecord.resultDate).format("LL")}
                  labelStyle={labRecordLabelStyle}
                  valueStyle={labRecordValueStyle}
                />
              )}
              {labRecord.results.modifiedDate && (
                <InfoField
                  label="Modified Date"
                  data={moment(labRecord.results.modifiedDate).format("LL")}
                  labelStyle={labRecordLabelStyle}
                  valueStyle={labRecordValueStyle}
                />
              )}
              <Box mt="8px">
                <Barcode
                  value={labRecord.id}
                  width={2}
                  displayValue={false}
                  fontSize={5}
                  height={20}
                  margin={2}
                  font="arial"
                />
              </Box>
            </Box>
          </Box>
          <Typography sx={{ fontWeight: 600, marginTop: "8px" }}>Test particulars</Typography>
          <Box sx={{ overflowX: "auto" }}>
            <Box
              sx={{
                display: "flex",
                width: "600px",
                borderBottom: "1px solid lightgrey"
              }}
            >
              {headers.map((item, i) => (
                <Typography
                  key={item}
                  sx={{
                    flex: i === 0 ? 1 : 0,
                    flexBasis: LabHeadersFlexBasis[item],
                    textTransform: "uppercase",
                    fontWeight: 500,
                    display: "flex"
                  }}
                >
                  {item}
                </Typography>
              ))}
            </Box>
            <Box
              sx={{
                width: "600px",
                borderBottom: "1px solid lightgrey"
              }}
            >
              {Object.keys(data).map((item) => (
                <Box key={item}>
                  {item !== "undefined" && (
                    <Typography sx={{ textTransform: "uppercase", fontWeight: 600 }}>
                      {item}
                    </Typography>
                  )}
                  <Box
                    sx={{
                      ml: item !== "undefined" ? "8px" : "0px"
                    }}
                  >
                    {data[item].map((test) => (
                      <Row
                        key={item}
                        item={test}
                        hideEmpty
                        showMethods={settings?.showMethods}
                        gender={client.gender}
                        dob={client.dob}
                      />
                    ))}
                  </Box>
                </Box>
              ))}
              {labRecord.results?.comment && (
                <Box sx={{ mt: "16px" }}>
                  <Typography style={labRecordValueStyle}>Remarks: </Typography>
                  <Typography style={labRecordValueStyle}>{labRecord.results.comment}</Typography>
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mt: "32px",
                pb: "32px"
              }}
            >
              {labRecord.results?.approvedBy?.authorizedSP1 && (
                <Box>
                  <AuthorizedSPInfo sp={labRecord.results.approvedBy.authorizedSP1} />
                </Box>
              )}
              {labRecord.results?.approvedBy?.authorizedSP2 && (
                <Box>
                  <AuthorizedSPInfo sp={labRecord.results.approvedBy.authorizedSP2} />
                </Box>
              )}
              {labRecord.results?.approvedBy?.authorizedSP3 && (
                <Box>
                  <AuthorizedSPInfo sp={labRecord.results.approvedBy.authorizedSP3} />
                </Box>
              )}
            </Box>
          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "90vh",
            gap: "8px",
            textAlign: "center"
          }}
        >
          <Typography>
            Lab record status: <b>{labRecord.status || ""}</b>.
          </Typography>
          <Typography>
            Please check back later when the lab record is result ready or dispatched.
          </Typography>
        </Box>
      )}
      <ViewInBrowserOrAppBanner />
    </Box>
  );
};

const LabQR = ({ match, location, user, additionallabdata }) => {
  const [labRecord, setLabRecord] = useState<LabRecord | null>(null);
  const [isResultAvailable, setIsResultAvailable] = useState(false);

  useEffect(() => {
    (async () => {
      const labRecordI = await getLabTestsRecordByUUID(match.params.uuid);
      const updatedLabRecord = handleLabTestVersions(labRecordI);

      setLabRecord(updatedLabRecord);
      setIsResultAvailable(
        updatedLabRecord.status === LabStatuses.RESULT_READY ||
          updatedLabRecord.status === LabStatuses.DISPATCHED
      );
    })();
  }, [match.params.uuid]);

  const { mobile } = qs.parse(location.search);
  const isMobileWebView = mobile === "true";

  const isMobileScreen = useMobileScreen();

  if (!labRecord) return null;

  const { resourceCentre } = labRecord;
  const settings: LabPrintSettings = {
    ...resourceCentre.labPrintSettings,
    ...resourceCentre.labSettings,
    showFooter: true,
    showLetterhead: resourceCentre.labPrintSettings?.showLetterhead || false,
    headerColor:
      (resourceCentre.labPrintSettings.enableColor && resourceCentre.labPrintSettings.color) ||
      null,
    labRemarks: resourceCentre.labSettings.labRemarks || "",
    showLabRemarks: resourceCentre.labSettings.showLabRemarks || false,
    clinicNameFontScale: resourceCentre.clinicNameFontScale,
    dateSetting: resourceCentre.labPrintSettings.dateFormat,
    categoryOrder: resourceCentre.labSettings.categoryOrder
  };

  const printTemplate = settings.template;

  if (isMobileScreen) {
    return (
      <Box
        sx={{
          mt: !user ? "-50px" : ""
        }}
      >
        {renderForMobileView(
          labRecord,
          resourceCentre,
          settings,
          isMobileWebView,
          isResultAvailable
        )}
      </Box>
    );
  }

  const getGovermentOrBorderedTemplate =
    printTemplate === "governmentTemplate" ? (
      <GovernmentTemplate
        labData={labRecord}
        settings={settings}
        resourceCentre={resourceCentre}
        otherProps={{ unfixFooter: true }}
        showAdditionalLabData={additionallabdata}
      />
    ) : (
      <BorderedTemplate
        labData={labRecord}
        settings={settings}
        resourceCentre={resourceCentre}
        otherProps={{ unfixFooter: true }}
        showAdditionalLabData={additionallabdata}
      />
    );

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        mt: !user ? "-50px" : "",
        mb: 4
      }}
    >
      <Box>
        {!isMobileWebView && isResultAvailable && (
          <Box my="8px" display="flex" justifyContent="flex-end">
            <Button variant="contained">
              <LabPrint resourceCentre={resourceCentre} settings={settings} labData={labRecord} />
            </Button>
          </Box>
        )}

        {isResultAvailable ? (
          <Box
            sx={{
              border: "1px solid lightgrey"
            }}
          >
            <>
              {printTemplate === "defaultTemplate" ? (
                <DefaultTemplate
                  labData={labRecord}
                  settings={settings}
                  resourceCentre={resourceCentre}
                  otherProps={{ unfixFooter: true }}
                  showAdditionalLabData={additionallabdata}
                />
              ) : (
                getGovermentOrBorderedTemplate
              )}
            </>
          </Box>
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              height: "90vh",
              gap: "8px",
              textAlign: "center"
            }}
          >
            <Typography>
              Lab record status: <b>{labRecord.status || ""}</b>.
            </Typography>
            <Typography>
              Please check back later when the lab record is result ready or dispatched.
            </Typography>
          </Box>
        )}
      </Box>
      <ViewInBrowserOrAppBanner />
    </Box>
  );
};

export default connect((state: RootState) => {
  const rc =
    state.resources.resourceCentres.find((r) => r.id === state.userContext.resourceCentreId) ||
    state.userContext.resourceCentre;
  const additionallabdata: boolean = rc?.labSettings?.additionallabdata;
  const { user } = state.userContext;
  return {
    additionallabdata,
    user
  };
}, null)(LabQR);
