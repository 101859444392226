import React, { JSX } from "react";
import { Autocomplete, Grid2 as Grid, TextField } from "@mui/material";
import { Control, Controller, FieldValues } from "react-hook-form";
import { FieldErrors } from "react-hook-form/dist/types/errors";
import FormTextField from "../../components/FormTextField/FormTextField";
import { isISODateString } from "../../helpers/validators";
import ToggleInputDate from "../../components/ToggleADBS";
import { bsDateField } from "../../models/Accounts";
import Can from "../Policy/Can";
import { ChildGeneralLedger } from "../../interfaces/Accounts";
import { Client as ClientInterface, Client } from "../../interfaces/ClientInterface";

interface Props {
  ledgerForClient: ChildGeneralLedger | null;
  control: Control<FieldValues>;
  formData: Client;
  formState: Partial<Client>;
  errors: FieldErrors<ClientInterface>;
}

function AccountAndLedgerFields({
  ledgerForClient,
  control,
  formData,
  formState,
  errors
}: Props): JSX.Element {
  return (
    <>
      <Grid size={{ xs: 12, sm: 6 }}>
        <FormTextField
          dataTestmation="openingBalanceInput"
          fullWidth
          control={control}
          name="openingBalance"
          errors={errors}
          label="Opening Balance"
          placeholder="Opening Balance"
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }} mt="-10px">
        <Controller
          name="openingBalanceDate"
          control={control}
          rules={{
            required: false,
            validate: (value) => (value ? !!isISODateString(value) : true)
          }}
          render={({ field }) => (
            <ToggleInputDate
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...field}
              data-testmation="openingBalanceDate"
              key="openingBalanceDate"
              field={{
                ...bsDateField,
                key: "openingBalanceDate",
                label: "openingBalanceDate"
              }}
              data={{
                openingBalanceDate: formState.openingBalanceDate || ""
              }}
              changeDate={(value) => {
                field.onChange(value);
              }}
              label="openingBalanceDate"
              error={!!errors?.openingBalanceDate}
              helperText={errors?.openingBalanceDate?.message || ""}
              isFocused={() => ({})}
              isBlurred={() => ({})}
              showAgeField={false}
            />
          )}
        />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <FormTextField
          dataTestmation="transactionTypeSelect"
          control={control}
          name="transactionType"
          label="Type"
          errors={errors}
          select
          fullWidth
          options={[
            {
              value: "debit",
              label: "Debit"
            },
            {
              value: "credit",
              label: "Credit"
            }
          ]}
        />
      </Grid>
      {ledgerForClient && (
        <Grid size={12}>
          <Can policyAccessKey="account:listAccount">
            <Controller
              control={control}
              name="ledgerId"
              render={({ field }) => (
                <Autocomplete
                  options={[ledgerForClient]}
                  value={
                    (formState?.ledgerId || formData?.ledgerId) && ledgerForClient
                      ? ledgerForClient
                      : null
                  }
                  onChange={(e, ledger) => field.onChange(ledger?.id)}
                  disabled={Boolean(formData?.ledgerId)}
                  getOptionLabel={(option) => `${option.ledgerName}`}
                  renderInput={(params) => (
                    <TextField
                      //   eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      data-testmation="ledgerSelect"
                      fullWidth
                      variant="outlined"
                      label="Child Mapping"
                      slotProps={{
                        inputLabel: { shrink: true }
                      }}
                    />
                  )}
                />
              )}
            />
          </Can>
        </Grid>
      )}
    </>
  );
}

export default AccountAndLedgerFields;
