import * as React from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import PencilIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/DeleteOutline";
import FaceIcon from "@mui/icons-material/Face";
import PhoneIcon from "@mui/icons-material/Phone";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import AssignmentIcon from "@mui/icons-material/Assignment";
import VideocamIcon from "@mui/icons-material/Videocam";
import { Typography, Link } from "@mui/material";
import ActionBar from "../../components/ActionBar/ActionBar";
import { getVideoUrl } from "../../api/video";
import EventProvider from "./EventProvider";
import BookingStatus from "./BookingStatus/BookingStatus";
import HandleBookingOptions from "./HandleBooking/HandleBooking";
import HistoryItems from "../History/HistoryItems";
import ServiceTags from "../Services/ServicesTags";
import { navigateAddModal, navigateRemoveModal } from "../../actions/navigation";
import { cancelBooking, updateBooking } from "../../actions/booking";
import { tl } from "../../components/translate";
import ClientNameWithInfoModal from "../Client/ClientInfoModal";
import ContentEditableWithTextField from "../../components/ContentEditableWithTextField/ContentEditableWithTextField";
import { bsFullDate } from "../../components/Calendar/functions/calendarFunctions";
import useMobileScreen from "../../hooks/useMobileScreen";
import styles from "./BookingCreateEdit.module.css";

const BookingInfo = ({
  serviceProvider,
  slot,
  event,
  editBooking,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  cancelBooking,
  bookingId,
  onClose,
  // eslint-disable-next-line @typescript-eslint/no-shadow
  updateBooking,
  resourceCentreVideoSetting,
  afterUpdate
}) => {
  const booking = bookingId ? event.bookings.find(({ id }) => id === bookingId) : event.bookings[0];
  const [showHistory, setShowHistory] = React.useState(false);
  const [videoUrl, setVideoUrl] = React.useState(null);
  const isMobileScreen = useMobileScreen();

  const fetchVideoUrl = () => {
    getVideoUrl(booking.id).then((url) => {
      setVideoUrl(url);
    });
  };
  const actions = [
    {
      label: tl("booking.activitiesHistory"),
      action: () => setShowHistory(true),
      Icon: AssignmentIcon,
      dataTestmation: "activitiesHistory"
    },
    {
      label: tl("booking.cancelBooking"),
      action: () => cancelBooking(booking.id),
      Icon: CancelIcon,
      dataTestmation: "cancelBooking"
    },
    {
      label: tl("booking.editBooking"),
      action: () => editBooking(booking.id),
      Icon: PencilIcon,
      dataTestmation: "editBooking"
    }
  ];
  return (
    <div style={isMobileScreen ? { height: "100vh" } : { width: "500px" }}>
      <DialogTitle>
        <Box component="span" fontSize="1rem" fontWeight={500}>
          <Box component="span" marginRight="32px">
            {tl("booking")}{" "}
            {resourceCentreVideoSetting && booking.isVideoAppointment && <VideocamIcon />}
          </Box>
          <BookingStatus booking={booking} />
        </Box>
        <ActionBar actions={actions} onClose={onClose} />
      </DialogTitle>
      <DialogContent classes={{ root: styles.bookingContent }} style={{ width: "100%" }}>
        <Grid container style={{ flexGrow: 1 }}>
          <EventProvider serviceProvider={serviceProvider} slot={slot || event} />
          <Grid container className="marginTop24">
            <Box className={styles.iconBox} component="span" fontWeight={400}>
              <FaceIcon className={styles.icon} />
            </Box>
            <Box flex="1" className={styles.alignVerticallyMiddle} component="span">
              <ClientNameWithInfoModal clientId={booking.client.id} />
            </Box>
          </Grid>
          <Grid container>
            <Box className={styles.iconBox} component="span" fontWeight={400}>
              <PhoneIcon className={styles.icon} />
            </Box>
            <Box className={styles.alignVerticallyMiddle} component="span" fontWeight={400}>
              {booking.client.phone}
            </Box>
          </Grid>
          {booking.isVideoAppointment && (
            <Box marginTop="8px">
              {videoUrl ? (
                <span>Client video url: {videoUrl}</span>
              ) : (
                <Link onClick={fetchVideoUrl}>Show client video url</Link>
              )}
            </Box>
          )}
          <Box width="100%" className="marginTop24 marginBotton24">
            <Typography component="div">
              <Box display="flex" marginTop="16px" marginBottom="4px" component="div">
                <Box width="160px" fontSize="0.875rem" fontWeight="500" component="span">
                  {tl("booking.reasonOfVisit")}
                </Box>
                <Box flex="1" fontSize="0.875rem" fontWeight="400" component="span">
                  {booking.reasonOfVisit}
                </Box>
              </Box>
              <Box display="flex" marginTop="16px" marginBottom="4px" component="div">
                <Box width="160px" fontSize="0.875rem" fontWeight="500" component="div">
                  {tl("booking.servicesOrProducts")}
                </Box>
                <Box flex="1" fontWeight="400" component="div">
                  <ServiceTags values={booking.services || []} />
                </Box>
              </Box>
            </Typography>
            {booking.reminders.length > 0 && (
              <>
                <Box display="flex" marginTop="16px" marginBottom="4px" component="div">
                  <Box width="160px" fontWeight="500" component="div">
                    {tl("booking.reminders")}
                  </Box>
                  <Box display="flex" flex="1" component="div">
                    {booking.reminders.map((reminder) => (
                      <Box key={reminder.id} component="div" display="flex">
                        <Box width="100px" marginRight="8px" component="div">
                          {bsFullDate(reminder.on)}
                        </Box>
                        <Box flex="1">{reminder.remark}</Box>
                      </Box>
                    ))}
                  </Box>
                </Box>
              </>
            )}
            <Box marginTop="16px" component="div">
              <ContentEditableWithTextField
                label={tl("booking.notes")}
                placeholder="Booking notes..."
                maxLength={1000}
                saveOnFocusOut
                value={booking.remarks}
                textArea
                rows={6}
                onSave={(remarks) => {
                  updateBooking(
                    booking.id,
                    {
                      remarks
                    },
                    afterUpdate
                  );
                }}
              />
            </Box>
          </Box>
          <HandleBookingOptions
            booking={booking}
            serviceProvider={serviceProvider}
            afterUpdate={afterUpdate}
          />
          {showHistory && <HistoryItems entity="booking" entityId={booking.id} />}
        </Grid>
      </DialogContent>
    </div>
  );
};

export default connect(
  ({ resources, slots, userContext }, { event }) => ({
    serviceProvider: resources.resourceCentreServiceProviders.find(
      (sp) => sp.id === event.serviceProviderId
    ),
    slot: slots.find((s) => s.id === event.id),
    resourceCentreVideoSetting:
      userContext?.resourceCentre?.subscriptions?.features?.video?.subscribed
  }),
  (dispatch) => ({
    cancelBooking: (bookingId) => {
      dispatch(
        navigateAddModal({
          component: "Dialog",
          props: {
            title: "Do you want to cancel the booking?",
            next: async () => {
              await dispatch(cancelBooking(bookingId));
              dispatch(navigateRemoveModal("Dialog"));
            },
            cancel: () => {
              dispatch(navigateRemoveModal("Dialog"));
            }
          }
        })
      );
    },
    updateBooking: async (bookingId, updateAttrs, next) => {
      await dispatch(updateBooking(bookingId, updateAttrs, next));
    }
  })
)(BookingInfo);
