import * as React from "react";
import { Box, Typography, Card, Grid2 as Grid } from "@mui/material";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import AssignmentReturnIcon from "@mui/icons-material/AssignmentReturn";
import ReceiptIcon from "@mui/icons-material/Receipt";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import PostAddIcon from "@mui/icons-material/PostAdd";
import CurrencyPoundIcon from "@mui/icons-material/CurrencyPound";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import ChromeReaderModeIcon from "@mui/icons-material/ChromeReaderMode";
import styles from "./Reports.module.css";
import { tl } from "../../components/translate";
import Can from "../Policy/Can";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../mixpanel-analytics/event";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";

const ReportsFinancial = (): JSX.Element => {
  const dispatch = useDispatch();
  const rc = useCurrentResourceCentre();

  return (
    <Box height="100%" padding="0 32px">
      <Typography fontSize="20px" fontWeight={600} lineHeight="40px" component="div">
        {tl("reports.financial")}
      </Typography>
      <Box marginTop="32px">
        <Can policyAccessKey="report:salesReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_FINANCIAL_SALES, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/financial/salesReport"));
            }}
            data-testmation="salesReportCard"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography>
                    <Box component="span" fontWeight="700" fontSize="24px">
                      {tl("reports.salesReport")}
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <ChromeReaderModeIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="report:feesAndReferralReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_FINANCIAL_AND_REFERRAL, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/financial/commissionReport"));
            }}
            data-testmation="commissionReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    Fees and Referral Report
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <MonetizationOnIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="report:salesByServiceReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_FINANCIAL_SALES_BY_SERVICE, {
                rcId: rc.id
              });
              return dispatch(push("/reports/financial/salesByServiceReport"));
            }}
            data-testmation="salesByServiceReportCard"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    {tl("reports.salesByServiceReport")}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <PostAddIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="report:dueReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_FINANCIAL_DUE, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/financial/dueReport"));
            }}
            data-testmation="dueReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    {tl("reports.dueReport")}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <AssignmentReturnIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="report:receiptReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_FINANCIAL_RECEIPT, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/financial/receiptReport"));
            }}
            data-testmation="receiptReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    {tl("reports.receiptReport")}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <ReceiptIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_FINANCIAL_CLIENT_LEDGER, {
              rcId: rc.id,
              rcName: rc.name
            });
            return dispatch(push("/reports/financial/clientLedger"));
          }}
          data-testmation="clientLedgerReport"
        >
          <Grid container>
            <Grid size={8}>
              <Typography fontWeight="700" fontSize="24px">
                Client Ledger Report
              </Typography>
            </Grid>
            <Grid size={4}>
              <CurrencyPoundIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
        <Can policyAccessKey="report:serviceProviderChargesReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_FINANCIAL_SERVICE_PROVIDER_CHARGES, {
                rcId: rc.id
              });
              return dispatch(push("/reports/financial/serviceProviderChargesReport"));
            }}
            data-testmation="serviceProviderChargesReportCard"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography>
                    <Box component="span" fontWeight="700" fontSize="24px">
                      {tl("reports.serviceProviderChargesReport")}
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <DonutSmallIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_IPD_BILLS_REPORT, {
              rcId: rc.id
            });
            return dispatch(push("/reports/financial/ipdBillsReport"));
          }}
          data-testmation="ipdBillsReport"
        >
          <Grid container>
            <Grid xs={8} lg={8}>
              <Box height="100%" width="100%">
                <Typography fontWeight="700" fontSize="24px">
                  {tl("reports.ipdBillsReport")}
                </Typography>
              </Box>
            </Grid>
            <Grid xs={4} lg={4}>
              <ReceiptLongIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Box>
  );
};

export default ReportsFinancial;
