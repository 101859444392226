import React from "react";
import { match as MatchProps } from "react-router";
import { Box, Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { MultipleHeader } from "../../../components/List";
import { ipdMultipleHeaders } from "../IpdList";
import { fetchActiveIpds, fetchBeds, fetchWards } from "../../../slices/bedManagementSlice";
import { RootState } from "../../../store";
import Beds from "./Beds";
import BedStats, { StatKeys } from "./BedStats";
import Header from "../../DashboardV2/Header";
import Can from "../../Policy/Can";
import CreateIpdAdmission from "../IpdAdmission/CreateIpdAdmission";
import { MODE } from "../../Assessment/AssessmentForm/Form";
import { t } from "../../../components/translate";
import WardBedSearch from "../../../components/WardBedSearch";
import { Bed } from "../../../api/wards";
import { IPD } from "../../../interfaces/IpdInterface";
import SettingsButton from "../../../components/SettingsButton/SettingsButton";
import IpdSettingsPanel from "../../ResourceCentre/ModuleSettings/Panels/IpdSettingsPanel";

interface SelectedBedState extends Bed {
  isVacant: boolean;
  activeIpd: Partial<IPD>;
}

export const formatBedName = (bed: Bed, wards: any[]): string => {
  const bedNameFormat: Array<string> = [];
  if (bed.ward) {
    bedNameFormat.push(bed.ward?.shortCode);
  } else {
    const relatedWard = wards?.find((ward) => ward.id === bed.wardId);
    bedNameFormat.push(relatedWard.shortCode);
  }
  bedNameFormat.push(bed.number);

  return bedNameFormat.join("-");
};

const BedManagement = ({ match }: { match: MatchProps }): JSX.Element => {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = React.useState(moment());
  const beds = useSelector((state: RootState) => state.bedManagement?.beds);
  const wards = useSelector((state: RootState) => state.bedManagement?.wards);
  const activeIpds = useSelector((state: RootState) => state.bedManagement?.activeIPDs);
  const [isCreateIpdPanelOpen, setIsCreateIpdPanelOpen] = React.useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = React.useState<boolean>(false);
  const [filter, setFilter] = React.useState<{
    selectedWard: number | null;
    bedNumber: string;
    kpiKey: string;
    filterAvailableBeds: boolean;
  }>({
    selectedWard: null,
    bedNumber: "",
    kpiKey: "",
    filterAvailableBeds: false
  });

  const [filteredBeds, setFilteredBeds] = React.useState<
    Array<SelectedBedState> | Array<Bed> | null
  >(null);

  const onDateChange = (date) => {
    setSelectedDate(date);
  };

  React.useEffect(() => {
    if (!beds.length) {
      dispatch(fetchBeds());
    }
  }, [beds.length, dispatch]);

  React.useEffect(() => {
    if (!wards.length) {
      dispatch(fetchWards());
    }
  }, [wards.length, dispatch]);

  React.useEffect(() => {
    dispatch(fetchActiveIpds());
  }, [dispatch]);

  React.useEffect(() => {
    let activeBeds: Array<Bed> | Array<SelectedBedState> =
      beds?.map((bed) => {
        const activeIpd = activeIpds.find((ipd) => ipd.bedId === bed.id);
        return {
          ...bed,
          isVacant: !activeIpd || !activeIpd.active,
          activeIpd: activeIpd || null,
          bedSlug: formatBedName(bed, wards).toLowerCase(),
          dateOfAdmission: activeIpd?.dateOfAdmission || null
        };
      }) || [];

    if (filter.kpiKey !== StatKeys.TotalBeds) {
      if (filter.selectedWard && !filter.filterAvailableBeds) {
        activeBeds = activeBeds.filter(({ wardId }) => wardId === filter.selectedWard);
      }

      if (filter.selectedWard && filter.filterAvailableBeds) {
        activeBeds = activeBeds.filter(
          ({ wardId, isVacant }) => wardId === filter.selectedWard && isVacant
        );
      }

      if (filter.bedNumber) {
        activeBeds = activeBeds.filter(
          ({ bedSlug }) => bedSlug.indexOf(filter.bedNumber.toLowerCase()) >= 0
        );
      }

      if (filter.kpiKey === StatKeys.AvailableBeds) {
        activeBeds = activeBeds.filter(({ isVacant }) => isVacant);
      }

      if (filter.kpiKey === StatKeys.AdmittedClients) {
        activeBeds = activeBeds.filter(({ activeIpd }) => activeIpd?.active);
      }

      if (filter.kpiKey === StatKeys.AdmittedToday) {
        activeBeds = activeBeds.filter(({ activeIpd }) => {
          if (!activeIpd?.active) {
            return false;
          }
          return moment(activeIpd?.dateOfAdmission).isSame(selectedDate, "day");
        });
      }
    }

    setFilteredBeds(activeBeds);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beds, activeIpds, filter]);

  return (
    <>
      <Box
        px={2.5}
        pt={2}
        sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}
      >
        <MultipleHeader multipleHeaders={ipdMultipleHeaders("/beds")} />
        <Can policyAccessKey="ipd:createIpd">
          <Box display="flex" gap={2} alignItems="center">
            <WardBedSearch wards={wards} filter={filter} setFilter={setFilter} />
            <Can policyAccessKey="ipd:createIpd">
              <Button variant="contained" onClick={() => setIsCreateIpdPanelOpen(true)}>
                {t("ipd.admitPatient")}
              </Button>
            </Can>
            <SettingsButton
              dataTestmation="billing.settings"
              onClick={() => setIsSettingsOpen(true)}
            />
          </Box>
        </Can>
      </Box>
      <Box pl={3} mt={2}>
        <Header selectedDate={selectedDate} onDateChange={onDateChange} showButtonGroup={false} />
      </Box>
      <Box p={2} mt={2}>
        <BedStats
          beds={beds}
          activeIpds={activeIpds}
          selectedDate={selectedDate}
          setFilter={setFilter}
        />
        <Beds
          beds={beds}
          wards={wards}
          activeIpds={activeIpds}
          match={match}
          filteredBeds={filteredBeds}
          setFilter={setFilter}
        />
      </Box>
      {isCreateIpdPanelOpen && (
        <CreateIpdAdmission
          mode={MODE.CREATE}
          onCancel={() => setIsCreateIpdPanelOpen(false)}
          stayOnPage={false}
        />
      )}
      <Can policyAccessKey="ipd:changeSettings">
        {isSettingsOpen && <IpdSettingsPanel onClose={() => setIsSettingsOpen(false)} />}
      </Can>
    </>
  );
};

export default BedManagement;
