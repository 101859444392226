import React from "react";
import { TextField, Typography, Box } from "@mui/material";

export const Label = ({ children }: { children: React.ReactNode }): JSX.Element => (
  <Typography component="span" style={{ display: "flex" }}>
    <Box
      component="span"
      display="flex"
      fontSize="0.83em"
      fontWeight={740}
      alignItems="center"
      width="100px"
    >
      {children}
    </Box>
  </Typography>
);

interface GenericTextFieldProps {
  value: any;
  dataTestmation?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  placeholderText?: string;
  maxLength?: number;
  rows?: number;
  rowsMax?: number;
}

function GenericTextField({
  value,
  dataTestmation,
  onChange,
  placeholderText,
  maxLength,
  rows = 1,
  rowsMax = 3
}: GenericTextFieldProps): JSX.Element {
  return (
    <TextField
      data-testmation={dataTestmation}
      margin="dense"
      variant="outlined"
      fullWidth
      multiline
      rows={rows}
      maxRows={rowsMax}
      value={value || ""}
      placeholder={placeholderText}
      helperText={`${value?.length || 0}/${maxLength}`}
      onChange={onChange}
      slotProps={{
        htmlInput: { maxLength },
        formHelperText: { style: { position: "absolute", right: 0, bottom: -5, margin: 5 } }
      }}
    />
  );
}

export default GenericTextField;
