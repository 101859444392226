import React from "react";
import { Box, Button, Tooltip } from "@mui/material";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import Panel from "../../components/Panel";
import AssessmentContent from "../Modals/Assessment/AssessmentContent";
import { Assessment, AssessmentDocument } from "../../interfaces/AssessmentInterfaces";
import OkhatiDialog from "../../components/Dialog/Dialog";
import { tl } from "../../components/translate";
import MultipleButtonGroup, { PRINT_FROM } from "../../components/MultipleButtonGroup";
import Print from "./Print/Print";
import { useAppSelector } from "../../store/hooks";
import { getClientAllergies } from "../../api/allergies";
import { Allergy } from "../../interfaces/Allergy";
import { UserMode } from "../../interfaces/User";
import * as NotificationActions from "../../actions/notification";
import { errorFetchMessage, MODULE } from "../../helpers/messages";
import hasOwnProperty from "../../helpers/object";
import ComparePanel from "./ComparePanel";

export const isRiskInfoOnPrintVisibleItemsFromStorage = (): string =>
  localStorage.getItem("isRiskInfoOnPrintVisible");

const showCreatedDateOpdItemsFromLocalStorage = () =>
  JSON.parse(localStorage.getItem("showCreationDateInADOnOpdPrint") || "{}");

interface Props {
  onClose: () => void;
  assessment: AssessmentDocument;
  setAssessmentCopy?: React.Dispatch<React.SetStateAction<Assessment>>;
  disableCarryForward?: boolean;
  wrapperStyle?: { maxWidth: string; minWidth: string };
}
export default function AssessmentInfoPanel({
  onClose,
  assessment,
  setAssessmentCopy,
  disableCarryForward = false,
  wrapperStyle = { maxWidth: "720px", minWidth: "720px" }
}: Props): JSX.Element {
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [riskInfoFlag, setRiskInfoFlag] = React.useState<Record<string, boolean>>({});
  const isRiskInfoVisibleOnCurrentAssessment = riskInfoFlag[assessment.id];
  const authenticable = useAppSelector((state) => state.userContext.userCreds.authenticable);
  const isAssessmentEditable = moment().diff(moment(assessment.created_at), "day", true) < 3;
  const [clientAllergies, setClientAllergies] = React.useState<Allergy[]>([]);
  const [showCreationDateInADOnPrint, setShowCreationDateInADOnPrint] = React.useState(false);
  const [showComparePanel, setShowComparePanel] = React.useState(false);

  React.useEffect(() => {
    const riskItems = isRiskInfoOnPrintVisibleItemsFromStorage();
    const showInADItems = showCreatedDateOpdItemsFromLocalStorage();
    if (riskItems) {
      setRiskInfoFlag(JSON.parse(riskItems));
    }
    if (showInADItems && hasOwnProperty(showInADItems, assessment.id)) {
      setShowCreationDateInADOnPrint(showInADItems[assessment.id]);
    }

    (async () => {
      try {
        const response = await getClientAllergies(assessment.clientId);
        setClientAllergies(response);
      } catch (error) {
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: error?.data?.message || errorFetchMessage(MODULE.RISK_INFO),
          autoTimeout: true
        });
      }
    })();
  }, []);

  const onShowADInPrintToggle = (id: number, checked: boolean) => {
    localStorage.setItem(
      "showCreationDateInADOnOpdPrint",
      JSON.stringify({ ...showCreatedDateOpdItemsFromLocalStorage(), [id]: checked })
    );
    setShowCreationDateInADOnPrint(checked);
  };

  const onShowRiskInfoToggle = (id: number) => {
    const newValue = {
      ...riskInfoFlag,
      [id]: !riskInfoFlag[id]
    };
    setRiskInfoFlag(newValue);
    localStorage.setItem("isRiskInfoOnPrintVisible", JSON.stringify(newValue));
  };

  const ActionButtons = (
    <Box display="flex" gap={2}>
      {authenticable !== UserMode.Client && (
        <Tooltip
          arrow
          title={
            isAssessmentEditable ? "" : "Assessment can only be edited within 24 hours of creation."
          }
        >
          <div>
            <Button
              variant="contained"
              data-testmation="assessmentEditButton"
              disabled={!isAssessmentEditable}
              onClick={() => {
                dispatch(
                  push(`/assessment/new/${assessment.id}/edit/?clientId=${assessment.clientId}`)
                );
              }}
            >
              {tl("edit")}
            </Button>
          </div>
        </Tooltip>
      )}
      {!disableCarryForward && (
        <Button
          variant="contained"
          onClick={(e) => {
            e.preventDefault();
            setIsDialogOpen(true);
          }}
        >
          {tl("assessment.carryForward")}
        </Button>
      )}
      {!!assessment.attachments.length && (
        <Tooltip title="Compare assessment images">
          <Button
            variant="contained"
            onClick={() => {
              setShowComparePanel(true);
            }}
          >
            {tl("assessment.compare")}
          </Button>
        </Tooltip>
      )}
    </Box>
  );

  const PrintButton = (
    <MultipleButtonGroup
      assessmentId={assessment.id}
      isRiskInfoOnPrintVisible={isRiskInfoVisibleOnCurrentAssessment}
      onShowRiskInfoToggle={onShowRiskInfoToggle}
      setShowDateInAD={onShowADInPrintToggle}
      showDateInAD={showCreationDateInADOnPrint}
      printFrom={PRINT_FROM.ASSESSMENT}
      options={[
        {
          label: "Print Default",
          onClickAction: () => ({}),
          component: (
            <Print
              clientAllergies={clientAllergies}
              buttonText="Print Default"
              key="assessmentPrint"
              assessment={assessment}
              clientId={assessment.clientId}
              isRiskInfoVisible={isRiskInfoVisibleOnCurrentAssessment}
              isCreationDateInADVisible={showCreationDateInADOnPrint}
            />
          )
        },
        {
          label: "Print Doctor",
          onClickAction: () => ({}),
          component: (
            <Print
              clientAllergies={clientAllergies}
              buttonText="Print Doctor"
              key="assessmentPrint"
              assessment={assessment}
              clientId={assessment.clientId}
              isRiskInfoVisible={isRiskInfoVisibleOnCurrentAssessment}
              isCreationDateInADVisible={showCreationDateInADOnPrint}
            />
          )
        }
      ]}
    />
  );

  return (
    <div>
      <Panel
        onClose={onClose}
        title="Details"
        genericButton={ActionButtons}
        printButton={PrintButton}
        wrapperStyle={wrapperStyle}
      >
        <Box px={4} pb={4} pt={2}>
          <AssessmentContent data={assessment} assessmentDoc={assessment.assessmentDocument} />
        </Box>
        {isDialogOpen && (
          <OkhatiDialog
            title={tl("assessment.carryForwardTitle")}
            description={tl("assessment.confirmCarryForward")}
            next={async () => {
              if (setAssessmentCopy) {
                setAssessmentCopy({ ...assessment, createdDate: new Date() });
              }
              dispatch(
                push(`/assessment/new/copy?clientId=${assessment.clientId}&id=${assessment.id}`)
              );
              setIsDialogOpen(false);
            }}
            cancel={() => {
              setIsDialogOpen(false);
            }}
            readMode={false}
          />
        )}
      </Panel>
      {showComparePanel && (
        <ComparePanel data={assessment} onClose={() => setShowComparePanel(false)} />
      )}
    </div>
  );
}
