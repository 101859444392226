import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid2 as Grid,
  Radio,
  RadioGroup,
  Stack
} from "@mui/material";
import React, { JSX } from "react";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { push } from "connected-react-router";
import FormTextField from "../../../components/FormTextField/FormTextField";
import { t, tl } from "../../../components/translate";
import ServiceProviderSelect from "../../../components/ServiceProviderSelect/ServiceProviderSelect";
import Diagnosis from "../../Assessment/Diagnosis/Diagnosis";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import ReferrerCreateSelect from "../../Referrers/ReferrerCreateSelect";
import { useAppDispatch } from "../../../store/hooks";
import { notificationAdd } from "../../../actions/notification";
import { createOpdRegister } from "../../../api/hmis";
import { errorFetchMessage, MODULE } from "../../../helpers/messages";
import * as hmisActions from "../../../actions/hmis";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";

interface Props {
  onClose: () => void;
  mulDartaId?: number;
  clientId?: number;
  nextOpdNumber: string;
}

export interface OpdRegister {
  isFirstTime: boolean;
  serviceProviderId: number;
  isTBPatient: boolean;
  researchBasedExamination: string;
  provisionalDiagnosis: string;
  diagnosis: { icdCode?: string; diagnosis: string };
  medications: string;
  surgicalProcedure: string;
  sexualViolence: boolean;
  freeServiceCode: string;
  referredToId: number;
  clientId: number;
  mulDartaId: number;
}

function OpdRegisterForm({ onClose, clientId, mulDartaId, nextOpdNumber }: Props): JSX.Element {
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useForm<FieldValues>({
    defaultValues: {
      isFirstTime: true,
      serviceProvider: null,
      isTBPatient: "no",
      researchBasedExamination: "",
      provisionalDiagnosis: "",
      diagnosis: null,
      medications: "",
      surgicalProcedure: "",
      sexualViolence: "no",
      freeServiceCode: "",
      referredToId: null,
      opdNumber: nextOpdNumber,
      opdRegistrationDate: new Date()
    }
  });
  const dispatch = useAppDispatch();

  const { diagnosis } = watch();

  const onSubmit = async (data) => {
    try {
      await createOpdRegister({
        isFirstTime: data.isFirstTime,
        researchBasedExamination: data.researchBasedExamination,
        provisionalDiagnosis: data.provisionalDiagnosis,
        diagnosis: data.diagnosis,
        medications: data.medications,
        surgicalProcedure: data.surgicalProcedure,
        freeServiceCode: data.freeServiceCode,
        referredToId: data.referredToId,
        sexualViolence: data.sexualViolence === "yes",
        isTBPatient: data.isTBPatient === "yes",
        clientId,
        mulDartaId,
        serviceProviderId: data.serviceProvider?.id || null,
        opdNumber: data.opdNumber,
        opdRegistrationDate: data.opdRegistrationDate
      });
      onClose();
      dispatch(push("/hmis/opd"));
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Successfully created",
          autoTimeout: true
        })
      );
      dispatch(hmisActions.getOpdRegister({ pageSize: 20, page: 0 }));
    } catch (e) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: e.message || errorFetchMessage(MODULE.OPD_REGISTER),
          autoTimeout: true
        })
      );
    }
  };

  return (
    <div>
      <Grid
        container
        spacing={1}
        component="form"
        id="opdRegisterForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid size={12}>
          <Controller
            name="isFirstTime"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                // eslint-disable-next-line react/jsx-props-no-spreading
                control={<Checkbox {...field} checked={field.value} />}
                label={t("hmis.isOPDFirstTime")}
              />
            )}
          />
        </Grid>
        <Grid size={12} mb={1}>
          <Controller
            name="serviceProvider"
            control={control}
            rules={{ required: "This field is required" }}
            render={({ field: { onChange, value }, fieldState }) => (
              <ServiceProviderSelect
                serviceProvider={value}
                onChange={onChange}
                fullWidth
                error={{
                  value: Boolean(fieldState.error),
                  message: fieldState.error?.message || ""
                }}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <FormTextField
            name="opdNumber"
            rules={{ required: `This field is required` }}
            fullWidth
            control={control}
            label={t("hmis.opdNumber")}
            placeholder={t("hmis.opdNumber")}
            dataTestmation=""
            errors={errors}
          />
        </Grid>
        <Grid size={12}>
          <Controller
            name="opdRegistrationDate"
            control={control}
            render={({ field: { onChange, value } }) => (
              <CalendarDropdown
                date={value}
                label={tl("hmis.registeredDate")}
                fullwidth
                onChange={(v) => onChange(v)}
                TextFieldProps={{
                  margin: "dense",
                  variant: "outlined"
                }}
              />
            )}
          />
        </Grid>
        <Grid size={12} mb={1}>
          <FormControl>
            <Stack direction="row" gap={2} alignItems="center">
              <FormLabel>{t("hmis.possibleTbPatient")}</FormLabel>
              <Controller
                control={control}
                name="isTBPatient"
                render={({ field }) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <RadioGroup row {...field}>
                    <FormControlLabel value="yes" control={<Radio />} label={t("hmis.yes")} />
                    <FormControlLabel value="no" control={<Radio />} label={t("hmis.no")} />
                  </RadioGroup>
                )}
              />
            </Stack>
          </FormControl>
        </Grid>
        <Grid size={12}>
          <FormTextField
            name="researchBasedExamination"
            fullWidth
            control={control}
            label={t("hmis.researchBasedExamination")}
            placeholder={t("hmis.form.researchBasedExamination")}
            dataTestmation="researchBasedExamination"
            errors={errors}
          />
        </Grid>
        <Grid size={12}>
          <FormTextField
            name="provisionalDiagnosis"
            fullWidth
            rules={{ required: `This field is required` }}
            control={control}
            label={t("hmis.provisionalDiagnosis")}
            placeholder={t("hmis.form.provisionalDiagnosis")}
            dataTestmation="provisionalDiagnosis"
            errors={errors}
          />
        </Grid>
        <Grid size={12} mb={1}>
          <Diagnosis
            hidetitle
            label="ICD Code"
            diagnosis={diagnosis}
            onChange={(v) => {
              setValue("diagnosis", v);
            }}
          />
        </Grid>
        <Grid size={12}>
          <FormTextField
            fullWidth
            multiline
            name="medications"
            control={control}
            rules={{ required: `This field is required` }}
            label={t("hmis.medicationsAndSuggestions")}
            placeholder={t("hmis.form.medicationsAndSuggestions")}
            dataTestmation="medicationsAndSuggestions"
            errors={errors}
          />
        </Grid>
        <Grid size={12}>
          <FormTextField
            name="surgicalProcedure"
            fullWidth
            multiline
            control={control}
            label={t("hmis.surgicalProcedure")}
            placeholder={t("hmis.form.surgicalProcedure")}
            dataTestmation="surgicalProcedure"
            errors={errors}
          />
        </Grid>
        <Grid size={12} md={6}>
          <FormControl>
            <Stack direction="row" gap={2} alignItems="center">
              <FormLabel>{t("hmis.sexualViolence")}</FormLabel>
              <Controller
                control={control}
                name="sexualViolence"
                render={({ field }) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <RadioGroup row {...field}>
                    <FormControlLabel value="yes" control={<Radio />} label={t("hmis.yes")} />
                    <FormControlLabel value="no" control={<Radio />} label={t("hmis.no")} />
                  </RadioGroup>
                )}
              />
            </Stack>
          </FormControl>
        </Grid>
        <Grid size={12} md={6}>
          <FormTextField
            name="freeServiceCode"
            fullWidth
            control={control}
            label={t("hmis.freeServiceCode")}
            placeholder={t("hmis.form.freeServiceCode")}
            dataTestmation="freeServiceCode"
            errors={errors}
          />
        </Grid>
        <Grid size={12}>
          <Controller
            name="referredToId"
            control={control}
            render={({ field: { onChange, value }, fieldState }) => (
              <ReferrerCreateSelect
                onReferrerChange={(v) => onChange(v?.id || null)}
                label={t("hmis.referrerToOrganization")}
                placeholder={t("hmis.form.referrerdToOrganization")}
                error={{
                  value: Boolean(fieldState.error),
                  message: fieldState.error?.message || ""
                }}
                referrerId={value}
                textFieldWidth="100%"
                width="100%"
                textFieldProps={{
                  InputLabelProps: { shrink: true }
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 4,
          px: 4,
          py: 2,
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        <Button data-testmation="hmisCancel" onClick={onClose}>
          Cancel
        </Button>
        <StatefulButton
          data-testmation="mulDartaSave"
          type="submit"
          form="opdRegisterForm"
          variant="contained"
          color="primary"
          circularProgressProps={{ size: 16 }}
          isLoading={isSubmitting}
          disabled={isSubmitting || !clientId || !mulDartaId}
        >
          Save
        </StatefulButton>
      </Box>
    </div>
  );
}

export default OpdRegisterForm;
