import React from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "../../../../store/hooks";
import Panel from "../../../../components/Panel";
import MedicalReportSettings from "../MedicalReportSettings";
import { resourceCentreActions as actions } from "../../../../actions";

interface Props {
  onClose: () => void;
}

const MedicalSettingsPanel = ({ onClose }: Props): JSX.Element => {
  const serviceProviders = useAppSelector((state) =>
    state.resources.resourceCentreServiceProviders.filter((sp) => sp.active === true)
  );
  const resourceCentre = useAppSelector((state) => state.resources.resourceCentres[0]);

  return (
    <Panel onClose={onClose} title="Medical Settings">
      <Box px={4}>
        <MedicalReportSettings
          resourceCentre={resourceCentre}
          actions={actions}
          serviceProviders={serviceProviders}
        />
      </Box>
    </Panel>
  );
};

export default MedicalSettingsPanel;
