import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { Box } from "@mui/material";
import PhoneNumber from "awesome-phonenumber";
import { t } from "../../../components/translate";

const isValidPhoneNumber = (number) => {
  if (number === null) return false;
  const ph = new PhoneNumber(number);
  return ph.isValid();
};

const isValidEmail = (email) =>
  email?.match(
    // eslint-disable-next-line no-useless-escape, max-len
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );

interface optionProps {
  name: string;
  val: string;
  id: number;
}
interface PhoneEmailMultiSelectProps {
  options: Array<optionProps>;
  value: Array<optionProps>;
  setValue: (v) => void;
  type: string;
  disabled?: boolean;
}

const PhoneEmailMultiSelect = ({
  options,
  value,
  setValue,
  type,
  disabled
}: PhoneEmailMultiSelectProps): JSX.Element => {
  const [inputValue, setInputValue] = React.useState("");

  const setBubbleValue = () => {
    if (inputValue.length > 0) {
      const phoneWith977 = inputValue.startsWith("+977") ? inputValue : `+977${inputValue}`;
      if (type === "sms") {
        if (isValidPhoneNumber(phoneWith977)) {
          setValue([...value, { name: "", val: phoneWith977 }]);
          setInputValue("");
        }
      } else if (isValidEmail(inputValue)) {
        setValue([...value, { name: "", val: inputValue }]);
        setInputValue("");
      }
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    switch (event.key) {
      case ",":
      case "Enter":
      case ";":
      case " ": {
        event.preventDefault();
        event.stopPropagation();
        setBubbleValue(event.target.value);
        break;
      }
      default:
    }
  };
  return (
    <Box>
      <Autocomplete
        data-testmation="selectContact"
        multiple
        freeSolo
        options={options}
        disabled={disabled}
        renderOption={(props, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={option.id}>
            {option.name} ( {option.val} )
          </li>
        )}
        getOptionLabel={(option) => {
          if (typeof option === "string") {
            return option;
          }
          return `${option.name} <${option.val}>`;
        }}
        value={value}
        onChange={(_, newValue) => setValue(newValue)}
        inputValue={inputValue}
        onInputChange={(_, newValue) => setInputValue(newValue)}
        filterSelectedOptions
        isOptionEqualToValue={(option, v) => option.val === v.val}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            onBlur={setBubbleValue}
            onKeyDown={handleKeyDown}
            slotProps={{
              inputLabel: { shrink: true }
            }}
            margin="dense"
            label={t("campaign.msg.to")}
            placeholder={t("campaign.msg.receiver")}
            fullWidth
          />
        )}
      />
    </Box>
  );
};

export default PhoneEmailMultiSelect;
