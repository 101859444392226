import { Box, Stack, Typography } from "@mui/material";
import startCase from "lodash/startCase";
import React from "react";
import { connect } from "react-redux";
import { getAssessmentByUUId } from "../../actions/assessments";
import { t, tl } from "../../components/translate";
import { ageFormatter } from "../../helpers/formatters";
import { AssessmentDocument } from "../../interfaces/AssessmentInterfaces";
import { spFullNameSelector } from "../../reducers/serviceProvider";
import { getMedicineMultiplier, showFrequencyUnit } from "../Assessment/Medication/Medication";
import { countableTypes } from "../Assessment/Medication/medicineType";
import AdvancedIntraOralContent, {
  hasContentAdvancedIntraOral
} from "../Modals/Assessment/AdvancedIntraOralContent";
import { formatAssessmentForPrint } from "../Modals/Assessment/AssessmentContent";
import ExtraOralContent from "../Modals/Assessment/ExtraOralContent";
import IntraoralAssessmentContent from "../Modals/Assessment/IntraoralAssessmentContent";
import Row from "./Row";
import "./styles.css";
import { RootState } from "../../store";
import FooterQR from "./FooterQR";
import { ComponentLabelsInterface } from "../../interfaces/ResourceCentreInterface";
import { getLabelForComponent } from "../Assessment/AssessmentForm/Form";
import DiagnosisRenderer, { hasDiagnosis } from "../Modals/Assessment/DiagnosisRenderer";
import { getAssessmentBaseUrl } from "../../helpers/urls";
import logo from "../../../assets/images/poweredByOkhati.png";
import { ViewInBrowserOrAppBanner } from "../Lab/LabPrint/LabQR";

interface QRProps {
  qrAssessment: {
    resourceCentre: {
      resourceCentreLogo: {
        s3ResourceURL: string;
      };
      name: string;
      address: string;
      city: string;
      phone: string;
      web: string;
      assessmentLabels: ComponentLabelsInterface;
    };
    client: {
      firstName: string;
      lastName: string;
      fullName: string;
      dob: string;
      city: string;
      address: string;
      phone: string;
      gender: number;
      registrationNo: number;
      ipdNo: number;
    };
    symptoms: [{ symptom }];
    assessment: string;
    assessmentHandwritten: string;
    diagnosis: { diagnosis: string };
    medication: [
      {
        genericName: string;
        brand: string;
        strength: string;
        form: string;
        frequency: number;
        meal: string;
        frequencyType: string;
        days: string;
        remark: string;
        times: string;
        duration: number;
      }
    ];
    tests: [{ name: string }];
    followup: { days: string | number; format: string };
    clientId: number;
    serviceProviderId: number;
    resourceCentreId: number;
    createdDate: string | Date;
    // eslint-disable-next-line camelcase
    created_at: string | Date;
    // eslint-disable-next-line camelcase
    updated_at: string | Date;
    assessmentImage: string;
    preparedByUserId: number;
    assessmentDocument: AssessmentDocument;
    assessmentUUID: string;
    others: string;
    vitals: [{ vital: string; reading: string; unit: string; extraInfo: string }];
    provisionalDiagnosis: string;
    intraOral: { primary: Record<string, unknown>; permanent: Record<string, unknown> };
    hopi: string;
    treatmentPlan: string;
    treatmentGiven: string;
    attachments: [];
  };
  getAssessment: (uuid: string) => void;
  uuid: string;
  match: {
    params: { uuid: string };
  };
  params: Record<string, unknown>;
}

const AssessmentQR: React.FC<QRProps> = ({
  qrAssessment,
  getAssessment,
  match: {
    params: { uuid }
  }
}) => {
  React.useEffect(() => {
    getAssessment(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFollowupFormat = () => {
    if (qrAssessment.assessmentDocument.followup.format === "days") return "days";

    if (qrAssessment.assessmentDocument.followup.format === "weeks") return "weeks";

    if (qrAssessment.assessmentDocument.followup.format === "months") return "months";

    return "years";
  };

  const { assessmentLabels } = qrAssessment?.resourceCentre || {};

  return (
    <>
      {qrAssessment && (
        <Box className="assessmentQR">
          <Box p={1}>
            {qrAssessment.resourceCentre && (
              <Box display="flex" justifyContent="space-between">
                <Box display="flex">
                  {qrAssessment.resourceCentre.resourceCentreLogo && (
                    <img
                      src={qrAssessment.resourceCentre.resourceCentreLogo.s3ResourceURL}
                      className="logo"
                      alt="logo"
                    />
                  )}
                  <Box pl={qrAssessment.resourceCentre.resourceCentreLogo ? 2 : 0}>
                    <Typography>
                      <Box component="span" textAlign="left" fontWeight="600" fontSize="0.875em">
                        {qrAssessment.resourceCentre.name}
                      </Box>
                    </Typography>
                    <Typography>
                      <Box component="span" textAlign="left">
                        {`${
                          qrAssessment.resourceCentre.address &&
                          startCase(qrAssessment.resourceCentre.address)
                        }, ${
                          qrAssessment.resourceCentre.city &&
                          startCase(qrAssessment.resourceCentre.city)
                        } `}
                      </Box>
                    </Typography>
                  </Box>
                </Box>
                <Box>
                  <Typography>
                    <Box component="span" textAlign="right" fontSize="0.875em">
                      {qrAssessment.resourceCentre.phone}
                    </Box>
                  </Typography>
                  <Typography>
                    <Box component="span" textAlign="right" fontSize="0.875em">
                      {qrAssessment.resourceCentre.web || "www.okhati.com.np"}
                    </Box>
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box>
            <Typography>
              <Box component="span" textAlign="center" paddingBottom="1rem" fontWeight="600">
                {tl("patientReport")}
              </Box>
            </Typography>
            <Box
              display="flex"
              justifyContent="space-between"
              borderBottom="1px solid gray"
              pb="10px"
            >
              {qrAssessment.client && (
                <>
                  <Box textAlign="left">
                    <Typography>
                      <Box component="span" fontWeight="600" fontSize="0.875em">
                        {`${startCase(qrAssessment.client.firstName)} ${startCase(
                          qrAssessment.client.lastName
                        )}`}
                        {(qrAssessment.client.gender &&
                          qrAssessment.client.gender === 1 &&
                          ", Male") ||
                          (qrAssessment.client.gender === 2 && ", Female")}
                        {ageFormatter(qrAssessment.client.dob) &&
                          `, ${ageFormatter(qrAssessment.client.dob)}`}
                      </Box>
                      <Box fontSize="0.875em">
                        {`${
                          qrAssessment.client.address && startCase(qrAssessment.client.address)
                        }, ${qrAssessment.client.city && startCase(qrAssessment.client.city)}`}
                      </Box>
                      <Box fontSize="0.875em">{qrAssessment.client.phone}</Box>
                    </Typography>
                  </Box>
                  <Box textAlign="right">
                    <Typography>
                      <Box component="span" fontSize="0.875em">
                        {qrAssessment.client.registrationNo &&
                          `${t("registrationNumber")} : ${qrAssessment.client.registrationNo}`}
                      </Box>
                      <Box fontSize="0.875em">
                        {qrAssessment.client.ipdNo &&
                          `${t("ipdNo")} : ${qrAssessment.client.ipdNo}`}
                      </Box>
                    </Typography>
                  </Box>
                </>
              )}
            </Box>
          </Box>
          <Box width="100%" mt={2}>
            {qrAssessment.assessmentDocument && (
              <Stack gap={1}>
                {qrAssessment.assessmentDocument.symptoms?.length > 0 &&
                  Array.isArray(qrAssessment.assessmentDocument.symptoms) && (
                    <Row label={getLabelForComponent(assessmentLabels.chiefComplaints)}>
                      <Typography>
                        {qrAssessment.assessmentDocument.symptoms.map(
                          (data, index) =>
                            `${startCase(data.symptom)}${
                              qrAssessment.assessmentDocument.symptoms.length === index + 1
                                ? ""
                                : ", "
                            }`
                        )}
                      </Typography>
                    </Row>
                  )}
                {qrAssessment.assessmentDocument.pastHistoryOfAllergy && (
                  <Row label={getLabelForComponent(assessmentLabels.pastHistoryOfAllergy)}>
                    <Typography>
                      <Box component="span">
                        {qrAssessment.assessmentDocument.pastHistoryOfAllergy}
                      </Box>
                    </Typography>
                  </Row>
                )}
                {qrAssessment.assessmentDocument.hopi?.length > 0 && (
                  <Row label={getLabelForComponent(assessmentLabels.hopi)}>
                    <Typography>{qrAssessment.assessmentDocument.hopi}</Typography>
                  </Row>
                )}

                {qrAssessment.assessment?.length > 0 && (
                  <Row label={getLabelForComponent(assessmentLabels.examination)}>
                    <Typography sx={{ whiteSpace: "pre" }}>
                      {qrAssessment.assessment.trim()}
                    </Typography>
                  </Row>
                )}

                {qrAssessment.assessmentDocument.investigation && (
                  <Row label={getLabelForComponent(assessmentLabels.investigation)}>
                    <Typography sx={{ whiteSpace: "pre" }}>
                      {qrAssessment.assessmentDocument.investigation}
                    </Typography>
                  </Row>
                )}

                {Object.keys(qrAssessment.assessmentDocument.extraOral || {}).some(
                  (key) => !!qrAssessment.assessmentDocument.extraOral[key]
                ) && (
                  <Row label={getLabelForComponent(assessmentLabels.extraOral)}>
                    <ExtraOralContent
                      assessmentDocument={qrAssessment.assessmentDocument}
                      mode="print"
                      componentLabel={getLabelForComponent(assessmentLabels.extraOral)}
                    />
                  </Row>
                )}

                {qrAssessment.assessmentDocument.assessmentImage && (
                  <Row label="">
                    <img
                      src={qrAssessment.assessmentDocument.assessmentImage}
                      alt="AssessmentImage"
                      className="assessment-image"
                    />
                    <Typography>
                      <Box component="span" fontWeight="500">
                        Status illustration
                      </Box>
                    </Typography>
                  </Row>
                )}
                {qrAssessment.assessmentDocument.intraOral &&
                  (Object.keys(qrAssessment.assessmentDocument.intraOral.primary)?.length !== 0 ||
                    Object.keys(qrAssessment.assessmentDocument.intraOral.permanent)?.length !==
                      0) && (
                    <Row label={getLabelForComponent(assessmentLabels.intraoral)}>
                      <Box className="intraOralContentQR">
                        <IntraoralAssessmentContent
                          intraOralDoc={qrAssessment.assessmentDocument.intraOral}
                        />
                      </Box>
                    </Row>
                  )}

                {qrAssessment.assessmentDocument.advancedIntraOral &&
                  hasContentAdvancedIntraOral(
                    qrAssessment.assessmentDocument.advancedIntraOral
                  ) && (
                    <Row label={getLabelForComponent(assessmentLabels.advancedIntraOral)}>
                      <AdvancedIntraOralContent
                        assessmentDocument={qrAssessment.assessmentDocument}
                        mode="print"
                        componentLabel={getLabelForComponent(assessmentLabels.advancedIntraOral)}
                      />
                    </Row>
                  )}

                {qrAssessment.assessmentDocument.questionnaire?.length > 0 && (
                  <Row label={getLabelForComponent(assessmentLabels.questionnaire)}>
                    {formatAssessmentForPrint(qrAssessment.assessmentDocument.questionnaire).map(
                      (questionnaire, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Box key={index} display="flex" flexWrap="wrap">
                          <Box display="flex" width="33%" gap={1}>
                            <Typography fontWeight="bold">
                              {`${startCase(questionnaire.name1)} :`}
                            </Typography>
                            <Typography>{questionnaire.value1}</Typography>
                          </Box>
                          {questionnaire.name2 && (
                            <Box display="flex" width="33%" gap={1}>
                              <Typography fontWeight="bold">
                                {`${startCase(questionnaire.name2)} :`}
                              </Typography>
                              <Typography>{questionnaire.value2}</Typography>
                            </Box>
                          )}
                          {questionnaire.name3 && (
                            <Box display="flex" width="33%" gap={1}>
                              <Typography fontWeight="bold">
                                {`${startCase(questionnaire.name3)} :`}
                              </Typography>
                              <Typography>{questionnaire.value3}</Typography>
                            </Box>
                          )}
                        </Box>
                      )
                    )}
                  </Row>
                )}

                {qrAssessment.assessmentDocument.vitals?.length > 0 && (
                  <Row label={getLabelForComponent(assessmentLabels.vitals)}>
                    {formatAssessmentForPrint(qrAssessment.assessmentDocument.vitals).map(
                      (vital, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <Box key={index} display="flex" flexWrap="wrap">
                          <Box display="flex" width="33%" gap={0.5}>
                            <Typography fontWeight="bold">
                              {`${startCase(vital.name1)} :`}
                            </Typography>
                            <Typography>{vital.value1}</Typography>
                          </Box>
                          {vital.name2 && (
                            <Box display="flex" width="33%" gap={0.5}>
                              <Typography fontWeight="bold">
                                {`${startCase(vital.name2)} :`}
                              </Typography>
                              <Typography>{vital.value2}</Typography>
                            </Box>
                          )}
                          {vital.name3 && (
                            <Box display="flex" width="33%" gap={0.5}>
                              <Typography fontWeight="bold">
                                {`${startCase(vital.name3)} :`}
                              </Typography>
                              <Typography>{vital.value3}</Typography>
                            </Box>
                          )}
                        </Box>
                      )
                    )}
                  </Row>
                )}

                {qrAssessment.assessmentDocument.provisionalDiagnosis?.length > 0 && (
                  <Row label={getLabelForComponent(assessmentLabels.provisionalDiagnosis)}>
                    <Typography>{qrAssessment.assessmentDocument.provisionalDiagnosis}</Typography>
                  </Row>
                )}

                {qrAssessment.assessmentDocument.tests?.length > 0 &&
                  Array.isArray(qrAssessment.assessmentDocument.tests) && (
                    <Row label={getLabelForComponent(assessmentLabels.labTest)}>
                      {qrAssessment.assessmentDocument.tests.map((data, index) => (
                        <Typography key={data.name}>{`${index + 1}. ${data.name}`}</Typography>
                      ))}
                    </Row>
                  )}

                {hasDiagnosis(qrAssessment.assessmentDocument.diagnosis) && (
                  <Row label={tl("assessment.ICD")}>
                    <DiagnosisRenderer data={qrAssessment.assessmentDocument.diagnosis} />
                  </Row>
                )}

                {qrAssessment.assessmentDocument.treatmentPlan?.length > 0 && (
                  <Row label={getLabelForComponent(assessmentLabels.treatmentPlan)}>
                    <Typography>{qrAssessment.assessmentDocument.treatmentPlan}</Typography>
                  </Row>
                )}

                {qrAssessment.assessmentDocument.treatmentGiven?.length > 0 && (
                  <Row label={getLabelForComponent(assessmentLabels.treatmentGiven)}>
                    <Typography>{qrAssessment.assessmentDocument.treatmentGiven}</Typography>
                  </Row>
                )}

                {qrAssessment.assessmentDocument.medication?.length > 0 &&
                  Array.isArray(qrAssessment.assessmentDocument.medication) && (
                    <Row label={getLabelForComponent(assessmentLabels.medication)}>
                      <Box className="medicationQR">
                        {qrAssessment.assessmentDocument.medication.map((data, index) => (
                          // eslint-disable-next-line react/no-array-index-key
                          <Box key={`med-${index}`}>
                            <Typography>
                              {`${index + 1}. ${data.genericName || ""}${
                                data.genericName && data.brand && "/"
                              } ${data.brand}${" "}${data.strength}${" "}(${data.form})`}
                            </Typography>

                            <Box pl={0.5}>
                              <Typography>
                                {data.frequency > 0 &&
                                  `- ${data.frequency} ${t("assessment.timesDay")} ${
                                    data.meal &&
                                    (data.meal === "before"
                                      ? t("assessment.beforeMeal")
                                      : data.meal === "after" && t("assessment.afterMeal"))
                                  }`}
                              </Typography>
                            </Box>
                            <Box pl={0.5}>
                              <Typography>
                                {data.frequency !== 0 &&
                                  `- ${
                                    countableTypes.includes(data.form)
                                      ? data.frequency *
                                        getMedicineMultiplier(
                                          data.times,
                                          data.duration,
                                          data.frequencyType
                                        )
                                      : ""
                                  } ${data.form} (${data.duration} ${showFrequencyUnit(
                                    data.frequencyType,
                                    data.duration
                                  )})`}
                              </Typography>
                            </Box>
                            <Box pl={0.5}>
                              <Typography>{data.remark && <>Remarks: {data.remark}</>}</Typography>
                            </Box>
                          </Box>
                        ))}
                      </Box>
                    </Row>
                  )}

                {qrAssessment.assessmentDocument.presentHealthStatus && (
                  <Row label={getLabelForComponent(assessmentLabels.presentHealthStatus)}>
                    <Typography>{qrAssessment.assessmentDocument.presentHealthStatus}</Typography>
                  </Row>
                )}

                {qrAssessment.assessmentDocument.followup && (
                  <Row label={getLabelForComponent(assessmentLabels.followup)}>
                    <Typography>
                      {`Follow up in ${
                        qrAssessment.assessmentDocument.followup.days
                      } ${getFollowupFormat()}`}
                    </Typography>
                  </Row>
                )}

                {qrAssessment.assessmentDocument.others?.length > 0 && (
                  <Row label={getLabelForComponent(assessmentLabels.others)}>
                    <Typography>{qrAssessment.assessmentDocument.others}</Typography>
                  </Row>
                )}

                <Box mt={2} display="flex" flexDirection="column" alignItems="flex-start">
                  {qrAssessment.assessmentDocument && (
                    <>
                      <Box>
                        {qrAssessment.assessmentDocument.serviceProvider?.signature && (
                          <img
                            src={qrAssessment.assessmentDocument.serviceProvider.signature}
                            alt="Service Provider Signature"
                            height="80"
                            width="160"
                          />
                        )}
                      </Box>
                      <Typography
                        fontWeight="600"
                        fontSize="0.775em"
                        paddingTop="0.2cm"
                        textAlign="left"
                        borderTop="1px solid lightgrey"
                      >
                        {spFullNameSelector(qrAssessment.assessmentDocument.serviceProvider)}
                      </Typography>

                      {qrAssessment.assessmentDocument.serviceProvider?.designation ? (
                        <Typography fontSize="0.770em" style={{ whiteSpace: "pre-wrap" }}>
                          {qrAssessment.assessmentDocument.serviceProvider?.designation}
                        </Typography>
                      ) : (
                        <>
                          <Typography fontSize="0.770em">
                            {qrAssessment.assessmentDocument.serviceProvider?.qualification}
                          </Typography>

                          {qrAssessment.assessmentDocument.serviceProvider?.registrationNumber && (
                            <Typography fontSize="0.770em">
                              {qrAssessment.assessmentDocument.serviceProvider?.registrationNumber}
                            </Typography>
                          )}
                        </>
                      )}
                    </>
                  )}
                </Box>
              </Stack>
            )}
            <Box borderTop="1px solid black" mt={1} p={1}>
              <Box className="footerContent">
                {qrAssessment.assessmentUUID && (
                  <FooterQR url={`${getAssessmentBaseUrl()}/${qrAssessment.assessmentUUID}`} />
                )}

                <img src={logo} alt="logo" width={80} />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <ViewInBrowserOrAppBanner />
    </>
  );
};

const mapStateToProps = (state: RootState) => ({
  qrAssessment: state.assessments.clientSpecific[0]
});

const mapDispatchToProps = (dispatch) => ({
  getAssessment: (id) => dispatch(getAssessmentByUUId(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(AssessmentQR);
