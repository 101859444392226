import React from "react";
import { TextField, MenuItem, Box } from "@mui/material";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import { get } from "lodash";

interface FormTextFieldProps {
  style?: { [key: string]: string | number };
  control: Control<FieldValues>;
  name?: string;
  label: string;
  rules?: { [key: string]: string | boolean | number };
  errors?: FieldErrors;
  fullWidth?: boolean;
  disabled?: boolean;
  options?: { value: string; label: string }[] | null;
  select?: boolean;
  multiline?: boolean;
  type?: string;
  testmation?: string;
  placeholder?: string;
}
const getError = (errors, name) => get(errors, name, "");

export default function FormInput({
  style,
  control,
  name,
  rules,
  label,
  errors,
  fullWidth,
  disabled,
  options,
  select,
  multiline,
  type,
  testmation,
  placeholder = ""
}: FormTextFieldProps): JSX.Element {
  return (
    <Box m="10px 0">
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...field}
            select={select}
            slotProps={{
              inputLabel: { shrink: true }
            }}
            sx={style}
            disabled={disabled}
            fullWidth={fullWidth}
            label={label}
            variant="outlined"
            multiline={multiline}
            rows={multiline ? 4 : 1}
            error={!!getError(errors, name)}
            helperText={getError(errors, name)?.message || ""}
            type={type}
            data-testmation={testmation}
            placeholder={placeholder}
          >
            {select &&
              options?.map((item) => (
                <MenuItem value={item.value} key={item.label}>
                  {item.label}
                </MenuItem>
              ))}
          </TextField>
        )}
      />
    </Box>
  );
}

FormInput.defaultProps = {
  fullWidth: false,
  disabled: false,
  style: {},
  name: "",
  options: null,
  select: false,
  rules: {},
  multiline: false,
  errors: {},
  type: "text",
  testmation: ""
};
