import React from "react";
import { Control, Controller, FieldErrors, FieldValues } from "react-hook-form";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import { get } from "lodash";
import { tl } from "../../../components/translate";

interface Props {
  control: Control<FieldValues>;
  errors: FieldErrors;
  options: Array<{
    value: string;
    label: string;
    description?: string;
  }>;
  value: string;
}
const getError = (errors, name) => get(errors, name, "");

const UserGroupSelect = ({ control, errors, options, value }: Props): JSX.Element => (
  <Controller
    control={control}
    name="userGroup"
    rules={{ required: { value: true, message: "User group is required" } }}
    render={({ field }) => (
      <Autocomplete
        options={options}
        value={options.find((opt) => opt.value === value)}
        onChange={(e, group) => field.onChange(group?.value)}
        getOptionLabel={(option) => `${option.label}`}
        renderOption={(props, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={option.value}>
            <Box>
              <Typography>{option.label}</Typography>
              <Typography variant="caption">{option.description}</Typography>
            </Box>
          </li>
        )}
        renderInput={(params) => (
          <TextField
            //   eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            error={!!getError(errors, "userGroup")}
            data-testmation="spUserGroup"
            fullWidth
            variant="outlined"
            label={tl("userGrp")}
            slotProps={{
              inputLabel: { shrink: true }
            }}
          />
        )}
      />
    )}
  />
);

export default UserGroupSelect;
