import * as React from "react";
import { startCase } from "lodash";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Button from "@mui/material/Button";
import { Box, Chip } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import { push } from "connected-react-router";
import MenuList from "@mui/material/MenuList";
import DoneIcon from "@mui/icons-material/Done";
import TextField from "@mui/material/TextField";
import { useDispatch, useSelector } from "react-redux";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import { statuses } from "./BookingStatus";
import { RootState } from "../../../store";
import { tl } from "../../../components/translate";
import { UserMode } from "../../../interfaces/User";
import hasOwnProperty from "../../../helpers/object";
import { updateBooking } from "../../../actions/booking";
import { Booking } from "../../../interfaces/BookingInterfaces";
import mixpanelAnalytics from "../../../mixpanel-analytics/mixpanelAnalytics";
import useCurrentResourceCentre from "../../../hooks/useCurrentResourceCentre";
import EVENT, { BOOKING_EVENT_MAPPING } from "../../../mixpanel-analytics/event";

const options = {
  serviceProvider: {
    reserved: ["confirmBooking", "markHandled", "markHandledAndCreateBill", "markReferred"],
    handled: [],
    referred: [],
    confirmed: ["markHandled", "markHandledAndCreateBill", "markReferred"]
  },
  employee: {
    reserved: [
      "markHandled",
      "markHandledAndCreateBill",
      "confirmBooking",
      "markWaiting",
      "markEngaged",
      "markReferred"
    ],
    handled: [],
    referred: [],
    confirmed: [
      "markHandled",
      "markHandledAndCreateBill",
      "markWaiting",
      "markEngaged",
      "markReferred"
    ],
    waiting: ["markHandled", "markHandledAndCreateBill", "markEngaged", "markReferred"],
    engaged: ["markHandled", "markHandledAndCreateBill", "markReferred"]
  }
};

// get options based on user mode and booking status
export const getOptions = (
  userMode: UserMode.Employee | UserMode.ServiceProvider,
  booking: Booking
): string[] => {
  if (!booking) return [];
  return options[userMode][booking.status] || [];
};

// format remarks length
export const formatRemarksLength = (newRemarks: string, remarks: string): string => {
  let allRemarks = remarks.length ? `${remarks}\n${newRemarks}` : newRemarks;

  if (allRemarks.length > 1000) {
    allRemarks = allRemarks.slice(-1000).split("\n").slice(1).join("\n");
  }

  return allRemarks;
};

interface PropType {
  booking: Booking;
  afterUpdate?: () => void;
  onClose?: () => void;
}

export default function HandleBookingStatus(props: PropType): React.ReactNode {
  const { booking } = props;

  const dispatch = useDispatch();

  const rc = useCurrentResourceCentre();
  const [remarks, setRemarks] = React.useState("");
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [actionAfter, setActionAfter] = React.useState<string | null>(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [openHandledRemarks, setOpenHandledRemarks] = React.useState(false);

  const user = useSelector((state: RootState) => state.userContext.user);
  const userMode = useSelector((state: RootState) => state.userContext.mode);

  const finalOptions = React.useMemo(() => getOptions(userMode, booking), [userMode, booking]);
  const status = React.useMemo(
    () => statuses.find((s) => s.label === booking.status),
    [booking.status]
  );

  if (userMode === "serviceProvider" && booking.serviceProviderId !== user.id) return null;

  const statusHandlers = {
    markHandled: () => {
      dispatch(
        updateBooking(Number(booking.id), {
          status: "handled",
          remarks: formatRemarksLength(remarks, booking.remarks)
        })
      );
    },

    markHandledAndCreateBill: () => {
      dispatch(
        updateBooking(Number(booking.id), {
          status: "handled",
          remarks: formatRemarksLength(remarks, booking.remarks)
        })
      );
      dispatch(push(`/billing/new?clientId=${booking.clientId}&bookingId=${booking.id}`));
    },

    markReferred: () => {
      dispatch(
        updateBooking(Number(booking.id), {
          status: "referred",
          remarks: formatRemarksLength(remarks, booking.remarks)
        })
      );
    },

    markEngaged: () => {
      dispatch(
        updateBooking(Number(booking.id), {
          status: "engaged",
          remarks: formatRemarksLength(remarks, booking.remarks)
        })
      );
    },

    markWaiting: () => {
      dispatch(
        updateBooking(Number(booking.id), {
          status: "waiting",
          remarks: formatRemarksLength(remarks, booking.remarks)
        })
      );
    },

    confirmBooking: () => {
      dispatch(
        updateBooking(Number(booking.id), {
          status: "confirmed",
          remarks: formatRemarksLength(remarks, booking.remarks)
        })
      );
    }
  };

  const handleClick = (action: keyof typeof statusHandlers) => {
    if (
      ["markHandled", "markHandledAndCreateBill", "markReferred", "confirmBooking"].includes(action)
    ) {
      setActionAfter(action);
      setOpenHandledRemarks(true);
    } else {
      // eslint-disable-next-line react/destructuring-assignment
      statusHandlers[action]();
    }
  };

  const handleActionAfter = (action: keyof typeof statusHandlers) => {
    statusHandlers[action]();
    setActionAfter(null);
  };

  const handleMenuItemClick = (option: keyof typeof statusHandlers, index: number) => {
    setSelectedIndex(index);
    setOpenMenu(false);
    handleClick(option);
  };

  const handleMenuListToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const handleMenuClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenMenu(false);
  };

  return (
    <>
      <Box
        ref={anchorRef}
        sx={{
          padding: 0,
          paddingLeft: "4px",
          borderRadius: "50px",
          paddingRight: "4px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: status?.color,
          cursor: "pointer",
          border: booking.status === "reserved" ? "1px solid black" : ""
        }}
        onClick={() => {
          if (finalOptions.length === 0) return;
          handleMenuListToggle();
        }}
      >
        <Chip
          size="small"
          sx={{
            backgroundColor: "transparent",
            border: "none",
            color: booking.status === "reserved" ? "black" : "white"
          }}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(status?.icon ? { icon: <status.icon style={{ color: "#fff" }} /> } : {})}
          label={startCase(status?.label || "")}
        />

        {finalOptions.length > 0 && (
          <ArrowDropDownIcon
            sx={{
              color: booking.status === "reserved" ? "black" : "white"
            }}
          />
        )}
      </Box>

      <Popper
        style={{ zIndex: 1 }}
        open={openMenu}
        anchorEl={anchorRef.current}
        transition
        disablePortal
        placement="bottom-start"
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={handleMenuClose}>
                <MenuList>
                  {finalOptions.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      data-testmation={option}
                      onClick={() => {
                        if (hasOwnProperty(BOOKING_EVENT_MAPPING, option)) {
                          mixpanelAnalytics.track(BOOKING_EVENT_MAPPING[option] as EVENT, {
                            rcId: rc.id,
                            rcName: rc.name
                          });
                        }
                        handleMenuItemClick(option as keyof typeof statusHandlers, index);
                      }}
                    >
                      {tl(option)}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>

      <Popper
        style={{ zIndex: 1 }}
        open={openHandledRemarks}
        anchorEl={anchorRef.current}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...TransitionProps}
            style={{
              transformOrigin: placement === "bottom" ? "center top" : "center bottom"
            }}
          >
            <Paper id="menu-list-grow">
              <ClickAwayListener onClickAway={() => setOpenHandledRemarks(false)}>
                <div
                  style={{
                    padding: "16px",
                    border: "1px solid #908f8f",
                    boxShadow: "0px 0px 3px 3px #c7c7c754"
                  }}
                >
                  <TextField
                    id="outlined-dense-multiline"
                    label={tl("booking.remarks")}
                    margin="dense"
                    variant="outlined"
                    multiline
                    maxRows="2"
                    style={{ width: "100%", minHeight: "42px" }}
                    value={remarks}
                    onChange={({ target }) => setRemarks(target.value)}
                    autoFocus
                    data-testmation="bookingHandleAddRemarks"
                  />
                  <Box component="div" textAlign="right" marginTop="16px">
                    <Button
                      style={{ marginLeft: "32px" }}
                      onClick={() => setOpenHandledRemarks(false)}
                      data-testmation="bookingHandleCancelButton"
                    >
                      {tl("booking.cancel")}
                    </Button>
                    <Button
                      style={{ marginLeft: "8px" }}
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        handleActionAfter(actionAfter as keyof typeof statusHandlers);
                        setRemarks("");
                        setOpenHandledRemarks(false);
                      }}
                      data-testmation="bookingHandleAfterAction"
                    >
                      {tl(actionAfter)}
                      <DoneIcon />
                    </Button>
                  </Box>
                </div>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
}
