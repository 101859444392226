import React from "react";
import { Box, Typography } from "@mui/material";
import styles from "./Assessment.module.css";
import { tl, t } from "../../../components/translate";
import TextBox from "../../../components/TextBox/TextBox";

interface PropTypes {
  assessmentHOPI: string;
  onChange: (value: string) => void;
}

const HOPI = ({ assessmentHOPI, onChange }: PropTypes): JSX.Element => (
  <Box className={styles.assessmentRow}>
    <Box className={styles.assessmentLabel}>
      <Typography>
        <Box component="span" fontWeight="600">
          {tl("assessment.hopi")}
        </Box>
      </Typography>
    </Box>
    <Box className={styles.assessmentField}>
      <TextBox
        onFocus={(e) => e.target.select()}
        margin="dense"
        id="outlined-basic"
        data-testmation="hopiTextField"
        fullWidth
        multiline
        variant="outlined"
        rows="3"
        rowsMax="3"
        placeholder={t("assessment.typeHOPI")}
        value={assessmentHOPI}
        onSave={(value) => onChange(value)}
        showTextCounter
        slotProps={{
          htmlInput: { maxLength: 255 },
          formHelperText: { style: { position: "absolute", right: 0, bottom: 0, margin: 5 } }
        }}
      />
    </Box>
  </Box>
);

export default HOPI;
