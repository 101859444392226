import React, { useRef } from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Menu, MenuItem, Button, Divider } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import ClientSearch from "../../components/ClientSearch";
import { clientSearchSecondaryLabel } from "../../models/Client";
import { tl, t } from "../../components/translate";
import DebouncedTextField from "../../components/DebouncedTextField";
import { Client } from "../../interfaces/ClientInterface";

const useStyles = makeStyles((theme) => ({
  buttonController: {
    paddingLeft: "0px",
    whiteSpace: "pre"
  },
  header: {
    position: "relative",
    borderRadius: "5px",
    width: "30px",
    height: "35px",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    transition: "width .5s, background 1s ease",
    [theme.breakpoints.down(undefined)]: {
      marginBottom: "10px",
      marginTop: "5px"
    }
  },
  focused: {
    backgroundColor: "#ececec",
    width: "280px",
    [theme.breakpoints.down("sm")]: {
      width: "240px"
    }
  },
  subheader: {
    [theme.breakpoints.down(undefined)]: {
      marginTop: "16px"
    }
  },
  searchIcon: {
    position: "absolute",
    width: "35px"
  },
  divider: {
    height: 20,
    marginRight: 15
  },
  billSearch: {
    position: "relative"
  },
  billSearchFocused: {
    marginLeft: "35px"
  },
  focusedBarMenu: {
    marginRight: "-300px"
  }
}));

interface Props {
  onQueryChange: (v: string) => void;
  selectedClient: Client;
  setSearchParams: React.Dispatch<React.SetStateAction<{ client: Client; searchQuery: string }>>;
}
const SearchInput = ({
  focused,
  onQueryChange
}: {
  focused: boolean;
  onQueryChange: (v: string) => void;
}) => {
  const classes = useStyles();
  return (
    <DebouncedTextField
      debounceAt={700}
      className={`${focused ? classes.billSearchFocused : ""}`}
      placeholder={`${focused ? "Search..." : ""}`}
      size="small"
      fullWidth
      onChange={(e) => onQueryChange(e.target.value)}
      slotProps={{
        input: { disableUnderline: true }
      }}
    />
  );
};

const AssessmentListSearch = ({
  selectedClient,
  setSearchParams,
  onQueryChange
}: Props): JSX.Element => {
  const toggleRef = useRef();
  const [focused, setFocused] = React.useState(true);

  const searchComponentMapper = {
    CLIENT: {
      label: tl("billing.client"),
      component: (
        <ClientSearch
          autoFocus
          style={{ paddingLeft: `${focused ? "35px" : ""}` }}
          client={selectedClient}
          setClient={(value: Client) => {
            setSearchParams((prevState) => ({ ...prevState, client: value }));
          }}
          margin="none"
          placeholder={`${focused ? "Search..." : ""}`}
          secondaryText={[
            {
              type: clientSearchSecondaryLabel.CUSTOMER_NUMBER
            },
            {
              type: clientSearchSecondaryLabel.EXTERNAL_IDENTIFIER,
              label: t("client.externalIdentifier.short")
            }
          ]}
        />
      )
    },
    ASSESSMENTID: {
      label: "Opd Id",
      component: <SearchInput focused={focused} onQueryChange={onQueryChange} />
    }
  };

  const menuItems = Object.keys(searchComponentMapper);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [active, setActive] = React.useState(menuItems[0]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const classes = useStyles();

  const toggleMenu = (
    <div>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        className={classes.buttonController}
      >
        {searchComponentMapper[active].label} <ExpandMoreIcon />
      </Button>
      <Menu
        ref={toggleRef}
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item}
            onClick={() => {
              setAnchorEl(null);
              setActive(item);
            }}
          >
            {searchComponentMapper[item].label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  return (
    <Box
      className={`${classes.header} ${focused ? classes.focused : ""}`}
      onFocus={() => setFocused(true)}
    >
      <SearchIcon className={classes.searchIcon} />
      <Box style={{ marginTop: "5px" }} width="220px">
        {searchComponentMapper[active].component}
      </Box>
      <Divider
        className={`${classes.divider} ${focused ? "" : classes.focusedBarMenu}`}
        orientation="vertical"
      />
      <Box className={`${focused ? "" : classes.focusedBarMenu}`}>{toggleMenu}</Box>
    </Box>
  );
};

export default AssessmentListSearch;
