import React, { useState, useEffect } from "react";
import { Box, TextField, Typography, Grid2 as Grid, Icon, Autocomplete } from "@mui/material";

import { withStyles } from "@mui/styles";
import cloneDeep from "lodash/cloneDeep";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import styles from "../Assessment.module.css";
import { tl, t } from "../../../components/translate";
import { getMedicineSearch } from "../../../api/prescrption";
import { medicineType, countableTypes } from "./medicineType";
import MedicationDialog, { DaysOfWeek } from "./MedicationDialog";
import useMobileScreen from "../../../hooks/useMobileScreen";
import { Medicine as MedicineInterface } from "../../../interfaces/AssessmentInterfaces";
import useAssessmentLabel from "../../../hooks/useAssessmentLabel";

interface MedicationPropsInterface {
  medication: MedicineInterface[];
  onChange: (med) => void;
  spID?: number;
  isOpd?: boolean;
  hideDefaultLabel?: boolean;
  customLabel?: string;
}

const StyledAutocomplete = withStyles({ option: { padding: 0 } })(Autocomplete);

const MedicationListView = (props) => {
  const { form, brand, strength, genericName, handleOpen } = props;
  return (
    <Box
      alignItems="center"
      display="flex"
      onClick={handleOpen}
      width="100%"
      pt="1rem"
      pb="1rem"
      pl="1rem"
    >
      <Box>{medicineType[form] && medicineType[form]()}</Box>
      <Typography component="span" variant="body2">
        <Box pl={1} display="inline">
          {brand}
        </Box>
        <Box pl={0.5} display="inline">
          {strength}
        </Box>
        <Box pl={1}>{genericName}</Box>
      </Typography>
    </Box>
  );
};

const convertMedicineItemToFormattedObject = (item) => ({
  id: item?.id ? item.id : "",
  duration: item.duration ? item.duration : 7,
  form: item.form ? item.form : "Tablet",
  meal: item.meal ? item.meal : "",
  brand: item.brand ? item.brand : item,
  remark: item.remark ? item.remark : "",
  strength: item.strength ? item.strength : "",
  frequency: item.frequency ? item.frequency : 0,
  genericName: item.genericName ? item.genericName : "",
  frequencyType: item.frequencyType ? item.frequencyType : "day",
  times: item.times ? item.times : "day"
});

export const getMedicineMultiplier = (
  times: string,
  duration: number,
  frequencyType: string
): number => {
  if (times === frequencyType) {
    return duration;
  }
  if (Object.values(DaysOfWeek).includes(times as DaysOfWeek) && frequencyType === "week") {
    return duration;
  }
  if (times === "day" && frequencyType === "week") {
    return duration * 7;
  }
  if (times === "day" && frequencyType === "month") {
    return duration * 30;
  }
  if (times === "week" && frequencyType === "month") {
    return Math.round((duration * 30) / 7);
  }
  return 1;
};

export const showTimeUnit = (times: string): JSX.Element | string => {
  if (times === "day") return tl("medication.day");
  if (times === "week") return tl("medication.week");
  if (times === "month") return tl("medication.month");
  return times || "";
};

export const showFrequencyUnit = (frequencyType: string, duration: number): string => {
  const isPlural = duration < 2 ? "" : "s";
  switch (frequencyType) {
    case "days":
      return `day${isPlural}.`;
    case "weeks":
      return `week${isPlural}.`;
    case "months":
      return `month${isPlural}.`;
    default:
      return frequencyType;
  }
};

export const showMeal = (meal: string): string => {
  if (meal === "before") return t("assessment.beforeMeal");
  if (meal === "after") return t("assessment.afterMeal");
  return "";
};
const Medication = ({
  medication,
  onChange,
  spID,
  isOpd = false,
  hideDefaultLabel = false,
  customLabel
}: MedicationPropsInterface): React.ReactElement => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [medicineData, setMedicineData] = useState([]);
  const [currentMedicineData, setCurrentMedicineData] = useState({});

  const isMobileScreen = useMobileScreen();
  useEffect(() => {
    if (medication) {
      setMedicineData(medication);
    }
  }, [medication]);
  const assessmentLabels = useAssessmentLabel();
  const handleModalSave = (data) => {
    const formattedData = convertMedicineItemToFormattedObject(data);
    const updatedMedicineData = medicineData.slice(0, -1);
    updatedMedicineData.push(formattedData);
    onChange(updatedMedicineData);
    setModalIsOpen(false);
  };

  useEffect(() => {
    setCurrentMedicineData(medicineData[medicineData.length - 1]);
  }, [medicineData]);

  return (
    <>
      <>
        <Box className={styles.assessmentRow}>
          {!hideDefaultLabel && (
            <Box className={styles.assessmentLabel}>
              <Typography component="span" fontWeight="600">
                {isOpd ? assessmentLabels.medication : "Medication"}
              </Typography>
            </Box>
          )}
          <Box className={styles.assessmentField}>
            <StyledAutocomplete
              multiple
              freeSolo
              closeIcon={null}
              value={medicineData}
              onKeyDown={(event) => {
                if (event.key === "Enter") {
                  setModalIsOpen(true);
                  setSuggestions([]);
                }
              }}
              filterOptions={(options, params) => {
                if (params.inputValue !== "") {
                  options.push({
                    inputValue: params.inputValue,
                    brand: `Add "${params.inputValue}"`,
                    form: "",
                    strength: "",
                    genericName: ""
                  });
                }
                return options;
              }}
              filterSelectedOptions
              onChange={(
                event,
                newValue: string | Array<MedicineInterface & { inputValue: string }>
              ): void => {
                if (typeof newValue === "string") {
                  // timeout to avoid instant validation of the dialog's form.
                  setTimeout(() => {
                    setModalIsOpen(true);
                    setCurrentMedicineData(medicineData[medicineData.length - 1]);
                  });
                } else if (
                  newValue[newValue.length - 1] &&
                  newValue[newValue.length - 1]?.inputValue
                ) {
                  setModalIsOpen(true);

                  const tempArr = [];
                  for (let i = 0; i < newValue.length; i += 1) {
                    if (newValue[i].inputValue) {
                      const formattedV = convertMedicineItemToFormattedObject({
                        ...newValue[i],
                        brand: newValue[i].inputValue
                      });
                      tempArr.push(formattedV);
                    } else {
                      const formattedV = convertMedicineItemToFormattedObject(newValue[i]);
                      tempArr.push(formattedV);
                    }
                  }
                  onChange(tempArr);
                } else if (newValue.length >= medicineData.length) {
                  const tempArr = [];
                  for (let i = 0; i < newValue.length; i += 1) {
                    const formattedV = convertMedicineItemToFormattedObject(newValue[i]);
                    tempArr.push(formattedV);
                  }
                  onChange(tempArr);
                }
              }}
              onClose={() => {
                setSuggestions([]);
              }}
              options={suggestions}
              renderOption={(props, option: MedicineInterface) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <li {...props} key={option.id}>
                  <MedicationListView
                    form={option?.form}
                    brand={option?.brand}
                    strength={option?.strength}
                    genericName={option?.genericName}
                    handleOpen={() => setModalIsOpen(true)}
                    times={option?.times}
                  />
                </li>
              )}
              renderTags={() => null} // doesnt render tags(chips)
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  fullWidth
                  variant="outlined"
                  margin="dense"
                  label={customLabel}
                  slotProps={{
                    inputLabel: { shrink: true }
                  }}
                  data-testmation="medicationMainTextField"
                  placeholder={t("assessment.typeMedicationHere")}
                  onChange={(e) => {
                    if (e.target.value.length > 2) {
                      getMedicineSearch(e.target.value).then((res) => setSuggestions(res));
                    }
                  }}
                />
              )}
            />
          </Box>
        </Box>
      </>

      <>
        {currentMedicineData && (
          <MedicationDialog
            handleCancel={() => {
              const updatedMedicineData = medicineData.slice(0, -1);
              onChange(updatedMedicineData);

              setModalIsOpen(false);
            }}
            handleModalSave={handleModalSave}
            modalIsOpen={modalIsOpen}
            data={currentMedicineData}
            spID={spID}
          />
        )}
      </>

      <>
        <Box className={styles.medicationRow}>
          {!customLabel && !hideDefaultLabel && <Box className={styles.medicationLabel} />}
          <Box className={customLabel ? "" : styles.medicationField} width={customLabel && "100%"}>
            {medicineData?.map((item, index) => (
              <div key={item?.id + item?.duration}>
                <Grid container>
                  <Grid item size={{ xs: 12, sm: 4 }}>
                    <Box display="flex" alignItems="center" style={{ wordBreak: "break-word" }}>
                      <Box pt={1}>
                        <Icon style={{ color: "black" }}>
                          {medicineType[item.form]
                            ? medicineType[item.form]()
                            : medicineType.Tablet()}
                        </Icon>
                      </Box>
                      <Box pl={2}>
                        <Box fontSize="1.02rem" fontWeight="600">
                          {`${item.brand} ${item.strength} `}
                        </Box>
                        <Box>{item.genericName}</Box>
                        {item.remark && (
                          <Typography>
                            <Box component="span">
                              <b>{tl("assessment.medication.remark")}</b> {item.remark}
                            </Box>
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  {isMobileScreen && (
                    <Grid size={2}>
                      <Box pt={1}>
                        <HighlightOffIcon
                          style={{
                            padding: 0,
                            float: "right",
                            cursor: "pointer",
                            marginRight: 8
                          }}
                          onClick={() => {
                            const updatedMedicineData = cloneDeep(medicineData);
                            updatedMedicineData.splice(index, 1);
                            onChange(updatedMedicineData);
                          }}
                        >
                          X
                        </HighlightOffIcon>
                      </Box>
                    </Grid>
                  )}
                  <Grid size={{ xs: 12, sm: 3 }}>
                    <Box pt={1}>
                      <Box>
                        {item.frequency === 0
                          ? ""
                          : `${item.frequency || ""} ${t("assessment.times")}`}
                        {item.frequency !== 0 && showTimeUnit(item.times)}
                        <br />
                        {showMeal(item.meal)}
                      </Box>
                    </Box>
                  </Grid>
                  <Grid size={{ xs: 12, sm: 3 }}>
                    <Box pt={1}>
                      {item.frequency !== 0 &&
                        `${
                          countableTypes.includes(item.form)
                            ? item.frequency ||
                              0 *
                                getMedicineMultiplier(
                                  item.times,
                                  item.duration || 0,
                                  item.frequencyType
                                )
                            : ""
                        } ${item.form} (${item.duration || 0} ${showFrequencyUnit(
                          item.frequencyType,
                          item.duration
                        )})`}
                    </Box>
                  </Grid>
                  {!isMobileScreen && (
                    <Grid item size={2}>
                      <Box pt={1}>
                        <HighlightOffIcon
                          style={{
                            padding: 0,
                            float: "right",
                            cursor: "pointer",
                            marginRight: 8
                          }}
                          onClick={() => {
                            const updatedMedicineData = cloneDeep(medicineData);
                            updatedMedicineData.splice(index, 1);
                            onChange(updatedMedicineData);
                          }}
                        >
                          X
                        </HighlightOffIcon>
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </div>
            ))}
          </Box>
        </Box>
      </>
    </>
  );
};

export default Medication;
