import React from "react";
import { Box, Typography, Button, TextField, ButtonGroup } from "@mui/material";
import { Remove, Add } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { tl } from "../../components/translate";
import { RootState } from "../../store";
import { BillDocumentType } from "../../interfaces/BillInterfaces";

interface RemindOnProps {
  draft: BillDocumentType;
  updateDraft: (bill: BillDocumentType) => void;
  isCreditNote: boolean;
}

export default function RemindOn({ draft, updateDraft, isCreditNote }: RemindOnProps): JSX.Element {
  const showReminder = useSelector(
    (s: RootState) => !!s.resources.resourceCentres[0]?.settings?.billingSettings?.showReminder
  );

  if (!showReminder) return null;

  return (
    <Box paddingBottom={1}>
      <Typography>{tl("billing.remindOn")}</Typography>
      <Box
        sx={{
          pr: 2,
          display: "flex",
          gap: 1,
          flexWrap: "wrap"
        }}
      >
        <Button
          data-testmation="followUpMinusButton"
          style={{ minWidth: "10px" }}
          size="small"
          variant="outlined"
          disabled={isCreditNote || draft?.remindOn?.duration <= 0}
          onClick={() => {
            updateDraft({
              ...draft,
              remindOn: {
                ...draft?.remindOn,
                duration: Number(draft?.remindOn?.duration) - 1
              }
            });
          }}
        >
          <Remove />
        </Button>
        <TextField
          data-testmation="followUpNumberTextField"
          type="number"
          size="small"
          variant="outlined"
          color="primary"
          value={draft?.remindOn?.duration}
          disabled={isCreditNote}
          onChange={(e) => {
            updateDraft({
              ...draft,
              remindOn: {
                ...draft?.remindOn,
                duration: Number(e.target.value)
              }
            });
          }}
          slotProps={{
            htmlInput: { style: { textAlign: "center", width: "20px", height: "11px" } }
          }}
        />
        <Button
          data-testmation="followUpPlusButton"
          style={{ minWidth: "10px" }}
          size="small"
          variant="outlined"
          disabled={isCreditNote}
          onClick={() => {
            updateDraft({
              ...draft,
              remindOn: {
                ...draft?.remindOn,
                duration: Number(draft?.remindOn?.duration) + 1
              }
            });
          }}
        >
          <Add />
        </Button>

        <ButtonGroup size="small" aria-label="small outlined button group">
          <Button
            data-testmation="followUpFormatDaysButton"
            size="small"
            variant="outlined"
            color={draft?.remindOn?.format === "days" ? "primary" : "inherit"}
            disabled={isCreditNote}
            onClick={() => {
              updateDraft({
                ...draft,
                remindOn: {
                  ...draft?.remindOn,
                  format: "days"
                }
              });
            }}
          >
            <Typography>
              <Box component="span" fontWeight="600" fontSize="12px">
                {tl("assessment.days")}
              </Box>
            </Typography>
          </Button>
          <Button
            data-testmation="followUpFormatWeeksButton"
            size="small"
            variant="outlined"
            color={draft?.remindOn?.format === "weeks" ? "primary" : "inherit"}
            disabled={isCreditNote}
            onClick={() => {
              updateDraft({
                ...draft,
                remindOn: {
                  ...draft?.remindOn,
                  format: "weeks"
                }
              });
            }}
          >
            <Typography>
              <Box component="span" fontWeight="600" fontSize="12px">
                {tl("assessment.week")}
              </Box>
            </Typography>
          </Button>
          <Button
            data-testmation="followUpFormatWeeksButton"
            size="small"
            variant="outlined"
            disabled={isCreditNote}
            color={draft?.remindOn?.format === "months" ? "primary" : "inherit"}
            onClick={() => {
              updateDraft({
                ...draft,
                remindOn: {
                  ...draft?.remindOn,
                  format: "months"
                }
              });
            }}
          >
            <Typography>
              <Box component="span" fontWeight="600" fontSize="12px">
                {tl("assessment.month")}
              </Box>
            </Typography>
          </Button>

          <Button
            data-testmation="followUpFormatYearsButton"
            size="small"
            variant="outlined"
            disabled={isCreditNote}
            color={draft?.remindOn?.format === "years" ? "primary" : "inherit"}
            onClick={() => {
              updateDraft({
                ...draft,
                remindOn: {
                  ...draft?.remindOn,
                  format: "years"
                }
              });
            }}
          >
            <Typography>
              <Box component="span" fontWeight="600" fontSize="12px">
                {tl("assessment.years")}
              </Box>
            </Typography>
          </Button>
        </ButtonGroup>
      </Box>
    </Box>
  );
}
