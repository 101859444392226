import CloseIcon from "@mui/icons-material/Close";
import InfoIcon from "@mui/icons-material/Info";
import ListIcon from "@mui/icons-material/List";
import VaccinesIcon from "@mui/icons-material/Vaccines";
import { Autocomplete, Box, Button, Checkbox, TextField, Tooltip, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import produce from "immer";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { tl } from "../../../components/translate";
import { notificationAdd } from "../../../actions/notification";
import { getLabTestGroupById as getLabTestById } from "../../../api/labTestSettings";
import { getStockProductByIds } from "../../../api/stock";
import { Test } from "../../../interfaces/Lab";
import { SampleDetails } from "../SampleTaken";
import { ReagentProps } from "../SettingsV2/StockReagent";
import { MODE } from "./LabTestCreate";
import { LabTestCreateDialog } from "./LabTestCreateDialog";
import ReagentDialog from "./ReagentDialog";
import useCurrentResourceCentre from "../../../hooks/useCurrentResourceCentre";

const useStyles = makeStyles(() => ({
  listButton: {
    display: "flex",
    alignItems: "center",
    height: "36px",
    width: "36px",
    marginTop: "2px",
    marginLeft: "8px",
    border: "1px solid #707070",
    justifyContent: "center",
    borderRadius: "4px",
    cursor: "pointer"
  }
}));

export const infoIconColor = "#676766";

interface Props {
  labTests: Array<Test>;
  tests: Array<Test>;
  handleRemoveLabtest: (i: number) => void;
  isMedicalTest: boolean;
  handleModifyLabtests: (v: Test[]) => void;
  mode?: string;
  isDisabled?: boolean;
  allowRemovingTest?: boolean;
  width?: string;
}

const AddLabtests = (props: Props): JSX.Element => {
  const {
    labTests,
    tests,
    handleRemoveLabtest,
    isMedicalTest,
    handleModifyLabtests,
    mode,
    isDisabled,
    allowRemovingTest,
    width
  } = props;
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [selectedLabGroup, setSelectedLabGroup] = React.useState<Test | null>({} as Test);
  const currentRC = useCurrentResourceCentre();

  const dispatch = useDispatch();
  const handleStockReagent = async (labTestData: Test[]) => {
    const selectedLabIds = tests.map((test) => test.id);
    const newAdded = labTestData.find((test) => !selectedLabIds.includes(test.id));
    let newlyAddedLabTestGroup;
    let stocks;
    let updatedLabTestData = labTestData;
    if (newAdded?.id) {
      try {
        newlyAddedLabTestGroup = await getLabTestById(newAdded.id);
        updatedLabTestData = labTestData.map((item) => {
          if (item.id === newlyAddedLabTestGroup.id) {
            return {
              ...item,
              sampleContainerInfo: newlyAddedLabTestGroup?.documentRecord?.sampleInfo
            };
          }
          return item;
        });
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            variant: "error",
            message: "Sorry!, Something went wrong while fetching Lab test.",
            autoTimeout: true
          })
        );
        return;
      }
      const { stockReagent } = newlyAddedLabTestGroup.documentRecord;
      if (stockReagent?.length) {
        const productIds = stockReagent.map((item) => item.productId);
        try {
          stocks = await getStockProductByIds(productIds);
        } catch (error) {
          dispatch(
            notificationAdd({
              id: new Date().getTime(),
              variant: "error",
              message: "Sorry!, Something went wrong while fetching stock products.",
              autoTimeout: true
            })
          );
          return;
        }
        const updatedStockReagent = stockReagent.map((reagent) => {
          const foundStock = stocks.find(
            (stock) => stock.productId === reagent.productId && stock.batchId === reagent.batchId
          );
          return { ...reagent, availableQty: Number(foundStock?.quantity || 0) };
        });
        const errorAddedData = produce(updatedLabTestData, (draft) => {
          const index = draft.findIndex((labTest) => labTest.id === newAdded.id);
          draft[index].stockReagent = updatedStockReagent;
        });
        handleModifyLabtests(errorAddedData as Test[]);
        return;
      }
      handleModifyLabtests(updatedLabTestData as Test[]);
    }
  };

  const removeItem = (index: number, labTest: Test) => {
    const updatedLabTests = produce(tests, (draft) => {
      const indx = draft.findIndex((test) => test.id === labTest.id);
      if (indx !== -1) {
        draft[indx].stockReagent.splice(index, 1);
      }
    });
    handleModifyLabtests(updatedLabTests);
  };

  const addNewInternalStock = (newItem: ReagentProps, labTest: Test) => {
    const updatedLabTests = produce(tests, (draft) => {
      const indx = draft.findIndex((test) => test.id === labTest.id);
      if (indx !== -1) {
        if (draft[indx].stockReagent) {
          draft[indx].stockReagent.push(newItem);
        } else {
          draft[indx].stockReagent = [newItem];
        }
      }
    });
    handleModifyLabtests(updatedLabTests);
  };

  return (
    <>
      {!isMedicalTest && (
        <Box display="flex" alignItems="center">
          <Autocomplete
            sx={{ width: width || "100%" }}
            disabled={isDisabled}
            data-testmation="labTestName"
            id="combo-box-demo"
            options={labTests}
            multiple
            getOptionLabel={(option) => option.name || ""}
            renderOption={(params, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...params} key={option.id}>
                {option.name || ""}
              </li>
            )}
            value={tests}
            onChange={(e, v) => {
              if (typeof v === "string") return;
              if (v !== null) {
                // Initialize selected test with sample taken info with default as true
                const data = v.map((item) => ({
                  ...item,
                  sampleTaken: true,
                  sampleInfo: { collectionDate: new Date(), remarks: "" }
                }));
                handleStockReagent(data);
              }
            }}
            renderTags={() => null}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                placeholder="Search and select test/test groups"
                variant="outlined"
                margin="dense"
                label={tl("assessment.labTest")}
                slotProps={{
                  inputLabel: { shrink: true }
                }}
              />
            )}
          />

          {!isDisabled && (
            <Box
              data-testmation="testlistIcon"
              className={classes.listButton}
              onClick={() => setOpen(true)}
            >
              <ListIcon />
            </Box>
          )}
        </Box>
      )}

      <Box mt="16px">
        {tests?.map((item, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={`${i}${item.name}`}>
            <Box display="flex" mt="6px" alignItems="center">
              <Typography style={{ width: "40px" }}>{i + 1}. </Typography>
              <Typography>{item.name}</Typography>
              <Tooltip
                title={`Sample Type: ${item.sampleContainerInfo?.sampleType || "N/A"} Container: ${
                  item.sampleContainerInfo?.sampleContainer || "N/A"
                }`}
              >
                <InfoIcon
                  sx={{
                    marginLeft: "5px",
                    cursor: "pointer",
                    marginRight: "10px",
                    marginTop: "-5px",
                    color: infoIconColor
                  }}
                />
              </Tooltip>
              {item.sampleTaken && (
                <Box display="flex" alignItems="center" sx={{ marginTop: "-15px" }}>
                  <SampleDetails
                    values={item?.sampleInfo}
                    maxValidDate={null}
                    onChange={(values) => {
                      const updatedTests = produce(tests, (draft) => {
                        draft[i] = {
                          ...item,
                          sampleInfo: values
                        };
                      });
                      handleModifyLabtests(updatedTests as Test[]);
                    }}
                  />
                </Box>
              )}
              <Tooltip title="Sample taken for this test" arrow>
                <Checkbox
                  checked={item.sampleTaken}
                  onChange={() => {
                    const updatedTests = produce(tests, (draft) => {
                      draft[i] = {
                        ...item,
                        sampleTaken: !item.sampleTaken
                      };
                    });
                    handleModifyLabtests(updatedTests as Test[]);
                  }}
                  sx={{
                    marginTop: "-5px"
                  }}
                />
              </Tooltip>
              {mode === MODE.CREATE &&
                item.stockReagent &&
                currentRC.labSettings.enableInternalStock && (
                  <Tooltip arrow title="Stock Reagent">
                    <Button
                      onClick={() => setSelectedLabGroup(item)}
                      size="small"
                      variant="outlined"
                      sx={{ padding: "2px" }}
                    >
                      <VaccinesIcon
                        color={`${
                          item.stockReagent?.some(
                            (stockItem) =>
                              (stockItem?.availableQty || 0) < stockItem.quantity ||
                              !stockItem.availableQty
                          )
                            ? "error"
                            : "primary"
                        }`}
                      />
                    </Button>
                  </Tooltip>
                )}
              {allowRemovingTest && (
                <CloseIcon style={{ cursor: "pointer" }} onClick={() => handleRemoveLabtest(i)} />
              )}
            </Box>
          </Box>
        ))}
      </Box>
      {selectedLabGroup && (
        <ReagentDialog
          selectedLabGroup={tests.find((labTest) => labTest.id === selectedLabGroup.id) as Test}
          onClose={() => setSelectedLabGroup(null)}
          cancelledProductIdx={(index) => removeItem(index, selectedLabGroup)}
          onAddNewInternalStock={(newItem) => addNewInternalStock(newItem, selectedLabGroup)}
        />
      )}
      <LabTestCreateDialog
        open={open}
        setOpen={setOpen}
        tests={tests}
        handleModifyLabtests={handleModifyLabtests}
        labTests={labTests}
      />
    </>
  );
};

export default AddLabtests;

AddLabtests.defaultProps = {
  mode: "",
  isDisabled: false,
  allowRemovingTest: false
};
