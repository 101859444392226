import React from "react";
import { Box, Typography } from "@mui/material";
import { t } from "../../components/translate";
import styles from "./Assessment.module.css";
import DebouncedTextField from "../../components/DebouncedTextField";
import useAssessmentLabel from "../../hooks/useAssessmentLabel";

const TreatmentGiven = ({
  assessmentTreatmentGiven,
  onChange
}: {
  assessmentTreatmentGiven: string;
  onChange: (value) => void;
}): JSX.Element => {
  const assessmentLabels = useAssessmentLabel();
  return (
    <Box className={styles.assessmentRow}>
      <Box className={styles.assessmentLabel}>
        <Typography component="span" fontWeight="600">
          {assessmentLabels.treatmentGiven}
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        <DebouncedTextField
          data-testmation="treatnmentGiven"
          margin="dense"
          variant="outlined"
          fullWidth
          multiline
          rowsMax={3}
          slotProps={{
            inputLabel: { shrink: true },
            htmlInput: { maxLength: 255 },
            formHelperText: { style: { position: "absolute", right: 0, bottom: -5, margin: 5 } }
          }}
          value={assessmentTreatmentGiven}
          placeholder={t("assessment.typeTreatmentGiven")}
          showTextCounter
          onChange={(e) => onChange(e.target.value)}
          debounceAt={400}
        />
      </Box>
    </Box>
  );
};

export default TreatmentGiven;
