/* eslint-disable react/destructuring-assignment */
import "./FilesInput.scss";

import * as React from "react";
import FileInput from "react-fine-uploader/file-input";
import Dropzone from "react-fine-uploader/dropzone";
import Filesize from "react-fine-uploader/filesize";
import ProgressBar from "react-fine-uploader/progress-bar";
import FineUploaderTraditional from "fine-uploader-wrappers";
import cloneDeep from "lodash/cloneDeep";
import Link from "@mui/material/Link";
import AttachmentIcon from "@mui/icons-material/AttachFile";
import CheckIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Clear";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Modal } from "@mui/material";
import { connect } from "react-redux";
import FileProgress from "./FileProgress";
import { FilePreview } from "./FilePreview";
import { RootState } from "../../store";

// FIXME checkout https://mui.com/components/use-media-query/#migrating-from-withwidth
// eslint-disable-next-line react/jsx-props-no-spreading
const withWidth = () => (WrappedComponent) => (props) => <WrappedComponent {...props} width="xs" />;

interface FilesInputInterface {
  label?: string;
  chooseFileLabel?: string;
  endpoint: string;
  // eslint-disable-next-line react/no-unused-prop-types
  deleteEndpoint: string;
  className?: string;
  /* eslint-disable  @typescript-eslint/no-explicit-any */
  onfilesChange?: (uuids: []) => any;
  // eslint-disable-next-line react/no-unused-prop-types
  authToken: string;
  defaultFiles: Array<{ id: number; name: string }>;
  onDefaultFileClick: (id: number) => void;
  sizeLimitLabel?: string;
  attachmentDisabled: boolean;
  width: string;
  attachmentsCount: number;
  maxString: string;
}

export interface FileInputState {
  fileStatus: any;
  submittedFiles: any;
  hasFileSizeExcededError: boolean;
  previewIndex: number;
}

const ALLOWED_EXTENSIONS = [
  "bmp",
  "csv",
  "dat",
  "doc",
  "docx",
  "gif",
  "ico",
  "jpg",
  "jpeg",
  "numbers",
  "odt",
  "pages",
  "pdf",
  "png",
  "ppt",
  "pptx",
  "psd",
  "txt",
  "xls",
  "xlsx",
  "zip",
  "PNG"
];
class FileInputs extends React.Component<FilesInputInterface, FileInputState> {
  private statusEnum: any;

  private uploader: any;

  private statusClass: any;

  // eslint-disable-next-line react/static-property-placement
  static defaultProps: {
    label: string;
    chooseFileLabel: string;
    className: string;
    onfilesChange: undefined;
    sizeLimitLabel: string;
  };

  constructor(props) {
    super(props);
    this.onStatusChanged = this.onStatusChanged.bind(this);
    this.configureUploader(props);
    this.state = {
      hasFileSizeExcededError: false,
      submittedFiles: [],
      fileStatus: {},
      previewIndex: -1
    };
    this.statusEnum = this.uploader.qq.status;
    this.statusClass = {
      [this.statusEnum.UPLOAD_SUCCESSFUL]: "successful",
      [this.statusEnum.UPLOAD_FAILED]: "failed"
    };
  }

  public componentDidMount() {
    this.onListenerChange();
  }

  componentDidUpdate(oldProps) {
    if (this.props.endpoint !== oldProps.endpoint) {
      this.configureUploader(this.props);
      this.onListenerChange();
    }
  }

  private onStatusChanged(idx, oldStatus, newStatus) {
    const { DELETED, CANCELED, UPLOAD_SUCCESSFUL, UPLOAD_FAILED, UPLOADING } = this.statusEnum;
    const { fileStatus } = cloneDeep(this.state);
    if ([DELETED, CANCELED, UPLOAD_SUCCESSFUL, UPLOAD_FAILED, UPLOADING].includes(newStatus)) {
      fileStatus[idx] = newStatus;
    }
    if (this.state.fileStatus[idx] !== fileStatus[idx]) {
      this.setState({ fileStatus }, () => this.propagateFilesChange());
    }
  }

  private setMaxFileSizeLimitError() {
    this.setState({ hasFileSizeExcededError: true });
    setTimeout(() => {
      this.setState({ hasFileSizeExcededError: false });
    }, 7000);
  }

  private configureUploader = (props) => {
    this.uploader = new FineUploaderTraditional({
      options: {
        validation: {
          itemLimit: this.props.attachmentsCount || 6,
          allowedExtensions: ALLOWED_EXTENSIONS
        },
        deleteFile: {
          enabled: true,
          endpoint: props.deleteEndpoint,
          ...(props.authToken
            ? {
                customHeaders: {
                  authorization: props.authToken
                }
              }
            : {})
        },
        request: {
          endpoint: props.endpoint,
          ...(props.authToken
            ? {
                customHeaders: {
                  authorization: props.authToken
                }
              }
            : {})
        },
        session: {
          endpoint: props.endpoint,
          ...(props.authToken
            ? {
                customHeaders: {
                  authorization: props.authToken
                }
              }
            : {})
        }
      }
    });
  };

  private onListenerChange = () => {
    this.uploader.on("statusChange", this.onStatusChanged);
    this.uploader.on("complete", (id, name, fileData) => {
      if (fileData.success) {
        // eslint-disable-next-line react/no-access-state-in-setstate
        const submittedFiles = this.state.submittedFiles.concat([]);
        submittedFiles[id] = fileData;
        this.setState({ submittedFiles }, () => this.propagateFilesChange());
      }
    });
    this.uploader.on("error", (errorCode, file, errorType, res = { responseText: "" }) => {
      if (errorType === "maxTotalSizeExceeded" || res.responseText.includes("max request")) {
        this.setMaxFileSizeLimitError();
      }
    });
    this.uploader.on("submitted", (id, name) => {
      // eslint-disable-next-line , react/no-access-state-in-setstate
      const submittedFiles = this.state.submittedFiles.concat([
        { name, canDelete: true, hover: false }
      ]);
      this.setState({ submittedFiles });
    });
    this.uploader.on("sessionRequestComplete", (submittedFiles) => {
      this.setState({ submittedFiles }, () => this.propagateFilesChange());
    });
  };

  // eslint-disable-next-line class-methods-use-this
  private isWidthSMorXS = (width) => {
    if (width === "sm" || width === "xs") {
      return true;
    }
    return false;
  };

  private handleClose = () => {
    this.setState({ previewIndex: -1 });
  };

  private propagateFilesChange() {
    const { submittedFiles, fileStatus } = this.state;
    const uuidsAndLink = submittedFiles
      .map((sf: any, id) => {
        if (this.shouldShow(fileStatus[id])) {
          return { uuid: sf.uuid, downloadLink: sf.s3ResourceURL, fileName: sf.fileName };
        }
        return undefined;
      })
      .filter(({ uuid }) => uuid);
    if (this.props.onfilesChange) {
      this.props.onfilesChange(uuidsAndLink);
    }
  }

  private canRetry(status) {
    return status === this.statusEnum.UPLOAD_FAILED;
  }

  private shouldShow(status) {
    return ![this.statusEnum.DELETED, this.statusEnum.CANCELED].includes(status);
  }

  private deleteFile(idx) {
    if (this.state.fileStatus[idx] === this.statusEnum.UPLOAD_SUCCESSFUL) {
      this.uploader.methods.deleteFile(idx);
    } else {
      this.uploader.methods.cancel(idx);
    }
  }

  private renderSubmittedFiles() {
    const { submittedFiles, fileStatus } = this.state;
    return submittedFiles.map((item, idx) => {
      const status = fileStatus[idx];
      let name = item.fileName || item.name;
      const extension = name?.substr(name.lastIndexOf("."));
      name = name?.replace(extension, "");

      const { attachmentDisabled } = this.props;

      return (
        this.shouldShow(status) && (
          // eslint-disable-next-line react/no-array-index-key
          <Box key={`${idx}`} className={`attachmentRow ${this.statusClass[status]}`}>
            <Box display="flex" style={{ position: "relative" }}>
              {status === this.statusEnum.UPLOAD_SUCCESSFUL ? (
                <CheckIcon className="sideIcon success" />
              ) : (
                <CancelIcon className="sideIcon failure" />
              )}
              <Box
                onClick={() => {
                  this.setState({ previewIndex: idx });
                }}
              >
                <img
                  id={idx}
                  src={item.downloadLink}
                  style={{
                    width: "40px",
                    height: "50px",
                    objectFit: "cover",
                    boxShadow: "1px 1px 2px 1px gray"
                  }}
                  alt=""
                />
              </Box>
            </Box>
            <Box
              style={{ display: "flex" }}
              onMouseEnter={() => {
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/no-shadow, react/no-access-state-in-setstate
                const submittedFiles = cloneDeep(this.state.submittedFiles);
                submittedFiles[idx] = { ...submittedFiles[idx], hover: true };
                this.setState({ submittedFiles });
              }}
              onMouseLeave={() => {
                // eslint-disable-next-line max-len
                // eslint-disable-next-line @typescript-eslint/no-shadow, react/no-access-state-in-setstate
                const submittedFiles = cloneDeep(this.state.submittedFiles);
                submittedFiles[idx] = { ...submittedFiles[idx], hover: false };
                this.setState({ submittedFiles });
              }}
            >
              <Link href={item.s3ResourceURL} target="_blank">
                <Box className="fileName">
                  <p className="name">{name}</p>
                  <p>{extension}</p>
                </Box>
              </Link>

              {!attachmentDisabled && (
                <span
                  style={{
                    visibility: submittedFiles[idx].hover ? "visible" : "hidden"
                  }}
                  className="deleteIcon"
                  onClick={() => this.deleteFile(idx)}
                  role="presentation"
                >
                  <DeleteIcon className="delete" />
                </span>
              )}
            </Box>

            {status !== this.statusEnum.UPLOAD_SUCCESSFUL && (
              <div className="options">
                <Filesize id={idx} uploader={this.uploader} />
                <FileProgress id={idx} uploader={this.uploader} />
                {this.canRetry(idx) && (
                  <span
                    className="retryUpload"
                    onClick={() => this.uploader.methods.retry(idx)}
                    role="presentation"
                  />
                )}
              </div>
            )}
          </Box>
        )
      );
    });
  }

  public render(): JSX.Element {
    return (
      <div
        className="Controlled Input FilesInputContainer"
        style={{
          display: `${this.isWidthSMorXS(this.props.width) ? "block" : "flex"}`,
          alignItems: "flex-start"
        }}
      >
        <Modal open={this.state.previewIndex >= 0}>
          <FilePreview
            onClose={this.handleClose}
            fileDetails={{
              name:
                this.state.submittedFiles[this.state.previewIndex]?.fileName ||
                this.state.submittedFiles[this.state.previewIndex]?.name,
              url: this.state.submittedFiles[this.state.previewIndex]?.s3ResourceURL
            }}
          />
        </Modal>
        {this.props.label && !this.props.attachmentDisabled && (
          <Box fontSize="14px" fontWeight={600} width="15%" pt="14px">
            {this.props.label}
            <br />
            {this.props.sizeLimitLabel && (
              <span className={`subLabel ${this.state.hasFileSizeExcededError && "hasError"}`}>
                {this.props.sizeLimitLabel}
              </span>
            )}
          </Box>
        )}
        <div className={`Controlled Input ${this.props.className || ""}`}>
          {this.props.defaultFiles &&
            this.props.defaultFiles.map(({ id, name }) => (
              <div key={id} className="attachmentRow successful">
                <span>
                  <a
                    target="_blank"
                    onClick={() => this.props.onDefaultFileClick(id)}
                    role="presentation"
                  >
                    {name}
                  </a>
                </span>
              </div>
            ))}
          <Dropzone
            style={{ border: "1px dotted", height: 200, width: 200 }}
            uploader={this.uploader}
            data-tesmation="attachments"
          >
            <Box
              style={{
                marginLeft: `${this.isWidthSMorXS(this.props.width) ? 0 : "16px"}`
              }}
            >
              <Box className="grid">{this.renderSubmittedFiles()}</Box>

              {!this.props.attachmentDisabled && (
                <FileInput
                  uploader={this.uploader}
                  multiple
                  accept={ALLOWED_EXTENSIONS.map((e) => `.${e}`).join(",")}
                >
                  <span style={{ display: "flex" }}>
                    <AttachmentIcon />
                    {this.props.chooseFileLabel}({this.props.maxString}
                    {this.props.attachmentsCount})
                  </span>
                </FileInput>
              )}
            </Box>
          </Dropzone>
          <ProgressBar uploader={this.uploader} />
        </div>
      </div>
    );
  }
}

FileInputs.defaultProps = {
  label: "",
  chooseFileLabel: "",
  className: "",
  onfilesChange: undefined,
  sizeLimitLabel: ""
};

function mapStateToProps(state: RootState) {
  return {
    // eslint-disable-next-line max-len
    attachmentsCount:
      state.userContext.resourceCentre.settings.assessmentSettings?.attachmentCount || 6
  };
}

const withWidthFileInputs = withWidth()(FileInputs);
export const FilesInput = connect(mapStateToProps)(withWidthFileInputs);
