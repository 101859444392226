import React, { JSX, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  Typography
} from "@mui/material";
import produce from "immer";
import { startCase } from "lodash";
import { resourceCentreActions as actions } from "../../../../actions";
import Panel from "../../../../components/Panel";
import BillingSetting from "../../Settings/BillingSettings";
import DiscountSetting from "../../ClinicSettings/DiscountSettings";
import Can from "../../../Policy/Can";
import ContentEditableWithTextField from "../../../../components/ContentEditableWithTextField/ContentEditableWithTextField";
import { tl } from "../../../../components/translate";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import DiscountTypeSelect from "../../../Billing/Editor/DiscountTypeSelect";
import { BillCounters } from "../../../Billing/Editor/BillSettings";
import { RootState } from "../../../../store";
import { BillDocumentSettingsType, BillDocumentType } from "../../../../interfaces/BillInterfaces";
import QrPaymentInfoForm from "../QrPayment/QrPaymentInfoForm";
import { useParsedProseMirror } from "../../../Lab/LabEntry/LabTestSubRow";
import {
  deserializeNode,
  proseMirrorOptions,
  RichTextfield
} from "../../../../components/RichTextfield";

interface Props {
  onClose: () => void;
  billDraft?: BillDocumentType;
  updateSettings?: (value: BillDocumentSettingsType) => void;
  updateBillCounter?: (value) => void;
  onExtraReferrerSettingChange?: (value: boolean) => void;
  isExtraReferrerEnabled?: boolean;
  showLocalisedSettings?: boolean;
}

enum TABS {
  MAIN = "settings",
  LOCALISED = "localisedSettings"
}

const BillingSettingsPanel = ({
  onClose,
  billDraft,
  updateSettings,
  updateBillCounter,
  onExtraReferrerSettingChange,
  isExtraReferrerEnabled,
  showLocalisedSettings = false
}: Props): JSX.Element => {
  const [selectedTab, setSelectedTab] = React.useState(
    showLocalisedSettings ? TABS.LOCALISED : TABS.MAIN
  );
  const [isDirty, setIsDirty] = React.useState(false);

  const debounceTimeout = React.useRef<NodeJS.Timeout | null>(null);

  const [extraReferrer, toggleExtraReferrer] = useState(isExtraReferrerEnabled);

  const resourceCentre = useAppSelector((s: RootState) => s.resources.resourceCentres[0] || {});
  const [settings, setSettings] = React.useState(billDraft?.settings);

  const [currentCounter, setCurrentCounter] = React.useState(
    localStorage.getItem("billCounter") || ""
  );

  const isCounterBillingEnabled =
    resourceCentre?.settings?.billingSettings?.enableCounterBilling || false;
  const updateSetting = (key, val) => {
    setIsDirty(true);
    setSettings({ ...settings, [key]: val });
  };

  const departmentSubscription = useAppSelector(
    (state: RootState) =>
      state.userContext.resourceCentre?.subscriptions.features.department?.subscribed
  );

  const onSave = () => {
    setIsDirty(false);
    updateSettings({ ...settings });
    if (currentCounter) {
      localStorage.setItem("billCounter", currentCounter);
      updateBillCounter(currentCounter);
    }
    onExtraReferrerSettingChange(extraReferrer);
    actions.putResourceCentre({
      ...resourceCentre,
      settings: {
        ...resourceCentre.settings,
        billingSettings: {
          ...resourceCentre.settings.billingSettings,
          extraColumns: settings?.extraColumnSettings,
          discountSettings: settings?.discountSettings
        }
      }
    });
    onClose();
  };

  const dispatch = useAppDispatch();

  const [billDefaultRemarks, setBillDefaultRemarks] = useParsedProseMirror(
    resourceCentre.settings.billingSettings.defaultRemarks || "",
    proseMirrorOptions
  );

  return (
    <Panel onClose={onClose} title="Billing Settings">
      <Box px={4} height="calc(100% - 100px)">
        <Tabs value={selectedTab} onChange={(_, newValue) => setSelectedTab(newValue)}>
          <Tab label={startCase(TABS.MAIN)} value={TABS.MAIN} />
          {showLocalisedSettings && (
            <Tab label={startCase(TABS.LOCALISED)} value={TABS.LOCALISED} />
          )}
        </Tabs>
        {selectedTab === TABS.MAIN && (
          <Box py={2}>
            <Box display="flex" alignItems="center">
              <BillingSetting
                billingSettings={resourceCentre.settings.billingSettings}
                onChange={(billingSettings) => {
                  const updatedResourceCenter = produce(resourceCentre, (draft) => {
                    draft.settings.billingSettings = billingSettings;
                  });
                  dispatch(actions.putResourceCentre(updatedResourceCenter));
                }}
              />
            </Box>
            <Box display="flex" alignItems="center" width={0.3}>
              <DiscountSetting
                discountSettings={resourceCentre.settings.billingSettings.discountSettings}
                onChange={(discountSettings) => {
                  const newRc = produce(resourceCentre, (draft) => {
                    draft.settings.billingSettings.discountSettings = discountSettings;
                  });
                  dispatch(actions.putResourceCentre(newRc));
                }}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={resourceCentre.settings.billingSettings.syncWithIRD}
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.billingSettings.syncWithIRD =
                          !draft.settings.billingSettings.syncWithIRD;
                      });
                      dispatch(actions.putResourceCentre(newRc));
                    }}
                  />
                }
                label={tl("settings.billSyncWithIRD")}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={resourceCentre.settings.billingSettings.enableLabTestCreation}
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.billingSettings.enableLabTestCreation =
                          !draft.settings.billingSettings.enableLabTestCreation;
                      });
                      dispatch(actions.putResourceCentre(newRc));
                    }}
                  />
                }
                label={tl("settings.enableLabTestCreationFromBill")}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={resourceCentre.settings.billingSettings.enableCounterBilling}
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.billingSettings.enableCounterBilling =
                          !draft.settings.billingSettings.enableCounterBilling;
                      });
                      dispatch(actions.putResourceCentre(newRc));
                    }}
                  />
                }
                label={tl("settings.enableCounterBilling")}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={
                      resourceCentre.settings.billingSettings.remarksCompulsoryForDiscountedBill
                    }
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.billingSettings.remarksCompulsoryForDiscountedBill =
                          !draft.settings.billingSettings.remarksCompulsoryForDiscountedBill;
                      });
                      dispatch(actions.putResourceCentre(newRc));
                    }}
                  />
                }
                label={tl("settings.remarksCompulsoryForDiscountedBill")}
              />
            </Box>
            <Box mt="8px">
              <Typography fontWeight={600} fontSize="14px">
                {tl("module.remindOn")}
              </Typography>
              <Box display="flex" width="100%" alignItems="center">
                <Checkbox
                  size="small"
                  checked={resourceCentre.settings.billingSettings.showReminder}
                  value={resourceCentre.settings.billingSettings.showReminder}
                  onChange={async () => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.settings.billingSettings.showReminder =
                        !resourceCentre.settings.billingSettings.showReminder;
                    });
                    dispatch(actions.putResourceCentre(newRc));
                  }}
                />
                <Typography>Show Remind On</Typography>
              </Box>
            </Box>
            <Box mt="8px">
              <Typography fontWeight={600} fontSize="14px">
                {tl("billing.defaultRemarks")}
              </Typography>
              <Box width="400px" ml={2} mt={0.5}>
                <RichTextfield
                  state={billDefaultRemarks}
                  setState={setBillDefaultRemarks}
                  minHeight={70}
                  showBoldItalicUnderlineOnly
                  onChange={() => {
                    if (debounceTimeout.current) {
                      clearTimeout(debounceTimeout.current);
                    }

                    debounceTimeout.current = setTimeout(() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.billingSettings.defaultRemarks = deserializeNode(
                          billDefaultRemarks.doc
                        );
                      });

                      dispatch(actions.putResourceCentre(newRc));
                    }, 1000);
                  }}
                />
              </Box>
            </Box>
            <Box mt="8px">
              <Typography style={{ fontWeight: 600 }}>{tl("settings.billPrint")}</Typography>
              <Box pl="16px">
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={
                          resourceCentre.settings.printSettings.billPrintSettings.includeLetterhead
                        }
                        onChange={() => {
                          const newRc = produce(resourceCentre, (draft) => {
                            draft.settings.printSettings.billPrintSettings.includeLetterhead =
                              !resourceCentre.settings.printSettings.billPrintSettings
                                .includeLetterhead;
                          });
                          dispatch(actions.putResourceCentre(newRc));
                        }}
                      />
                    }
                    label={tl("settings.includeLetterhead")}
                  />
                </Box>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={
                          resourceCentre.settings.printSettings.billPrintSettings.includeFooter
                        }
                        onChange={() => {
                          const newRc = produce(resourceCentre, (draft) => {
                            draft.settings.printSettings.billPrintSettings.includeFooter =
                              !resourceCentre.settings.printSettings.billPrintSettings
                                .includeFooter;
                          });
                          dispatch(actions.putResourceCentre(newRc));
                        }}
                      />
                    }
                    label={tl("settings.includeFooter")}
                  />
                  {!resourceCentre.settings.printSettings.billPrintSettings.includeFooter && (
                    <Can policyAccessKey="deny" superAdminPass>
                      <FormControlLabel
                        control={
                          <Checkbox
                            size="small"
                            checked={
                              resourceCentre.settings.printSettings.billPrintSettings
                                .hideFooterLogoCompletely
                            }
                            onChange={() => {
                              const newRc = produce(resourceCentre, (draft) => {
                                draft.settings.printSettings.billPrintSettings.hideFooterLogoCompletely =
                                  !resourceCentre.settings.printSettings.billPrintSettings
                                    .hideFooterLogoCompletely;
                              });
                              dispatch(actions.putResourceCentre(newRc));
                            }}
                          />
                        }
                        label={tl("settings.hideFooterCompletely")}
                      />
                    </Can>
                  )}
                </Box>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={resourceCentre.settings.printSettings.includeReferrer}
                        onChange={() => {
                          const newRc = produce(resourceCentre, (draft) => {
                            draft.settings.printSettings.includeReferrer =
                              !draft.settings.printSettings.includeReferrer;
                          });
                          dispatch(actions.putResourceCentre(newRc));
                        }}
                      />
                    }
                    label={tl("settings.includeReferrer")}
                  />
                </Box>
              </Box>
              <Box mt={2} border="1px solid #E0E0E0" p={1} pb={0} borderRadius={2} maxWidth={600}>
                <Typography fontWeight={600} fontSize={15} mb={1}>
                  A5 Template Settings
                </Typography>
                <ContentEditableWithTextField
                  label={tl("settings.customHeaderTitle")}
                  placeholder="Custom bill print title"
                  saveOnFocusOut
                  value={resourceCentre.settings.printSettings.billPrintSettings.customTitle}
                  onSave={(val) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.settings.printSettings.billPrintSettings.customTitle = val || "";
                    });
                    dispatch(actions.putResourceCentre(newRc));
                  }}
                  width="300px"
                />
                <ContentEditableWithTextField
                  label={tl("settings.customHeaderSubTitle")}
                  placeholder="Custom bill sub title"
                  saveOnFocusOut
                  value={resourceCentre.settings.printSettings.billPrintSettings.customSubtitle}
                  onSave={(val) => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.settings.printSettings.billPrintSettings.customSubtitle = val || "";
                    });
                    dispatch(actions.putResourceCentre(newRc));
                  }}
                  width="300px"
                />
              </Box>
            </Box>
            <Box mt="20px">
              <QrPaymentInfoForm resourceCentre={resourceCentre} />
            </Box>
          </Box>
        )}
        {selectedTab === TABS.LOCALISED && (
          <Box pt={2} display="flex" flexDirection="column" height="100%">
            <Box flex={1}>
              <div>
                <Typography component="span" fontSize="16px" fontWeight={600}>
                  Discount Methods
                </Typography>

                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={settings?.discountSettings?.discountBasis === "inline"}
                    onChange={() => {
                      updateSetting("discountSettings", {
                        ...settings?.discountSettings,
                        discountBasis:
                          settings?.discountSettings?.discountBasis === "invoice"
                            ? "inline"
                            : "invoice"
                      });
                    }}
                  />
                  <Typography>Inline Discount</Typography>
                  <Box
                    sx={{
                      marginLeft: "10px",
                      marginTop: "5px"
                    }}
                  >
                    <DiscountTypeSelect
                      fieldValue={settings?.discountSettings?.discountType}
                      onChange={(value) => {
                        updateSetting("discountSettings", {
                          ...settings?.discountSettings,
                          discountType: value
                        });
                      }}
                    />
                  </Box>
                </Box>

                <Box display="flex" alignItems="center">
                  <Checkbox
                    checked={settings?.discountSettings?.discountBasis === "invoice"}
                    onChange={() => {
                      updateSetting("discountSettings", {
                        ...settings?.discountSettings,
                        discountBasis:
                          settings?.discountSettings?.discountBasis === "invoice"
                            ? "inline"
                            : "invoice"
                      });
                    }}
                  />
                  <Typography>Total Discount</Typography>
                  <Box
                    sx={{
                      marginLeft: "10px",
                      marginTop: "5px"
                    }}
                  >
                    <DiscountTypeSelect
                      fieldValue={settings?.discountSettings?.discountType}
                      onChange={(value) => {
                        updateSetting("discountSettings", {
                          ...settings?.discountSettings,
                          discountType: value
                        });
                      }}
                    />
                  </Box>
                </Box>
              </div>

              <Box mt="20px">
                <Typography component="span" fontSize="16px" fontWeight={600}>
                  Show/Hide Columns
                </Typography>

                {Object.keys(settings?.extraColumnSettings).map((colName) => (
                  <Box display="flex" alignItems="center" key={colName}>
                    <Checkbox
                      checked={settings?.extraColumnSettings[colName]}
                      disabled={colName === "department" && !departmentSubscription}
                      onChange={() => {
                        updateSetting("extraColumnSettings", {
                          ...settings?.extraColumnSettings,
                          [colName]: !settings?.extraColumnSettings[colName]
                        });
                      }}
                    />
                    <Typography>{startCase(colName)}</Typography>
                  </Box>
                ))}
                {isCounterBillingEnabled && (
                  <Box display="flex" alignItems="center" px={2}>
                    <Typography>Set Counter</Typography>
                    <Select
                      variant="outlined"
                      id="counterSelect"
                      value={currentCounter}
                      sx={{
                        marginLeft: "5px",
                        height: "40px",
                        width: "180px"
                      }}
                      error={!currentCounter}
                      onChange={(e) => {
                        setIsDirty(true);
                        setCurrentCounter(e.target.value);
                      }}
                    >
                      {Object.keys(BillCounters).map((option) => (
                        <MenuItem key={option} value={option}>
                          {BillCounters[option]}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                )}
              </Box>

              <Box mt="20px">
                <Typography>
                  <Box component="span" fontSize="16px" fontWeight={600}>
                    Extra Referrer
                  </Box>
                </Typography>
                <Box display="flex" alignItems="center" p={0}>
                  <Checkbox
                    checked={extraReferrer}
                    onChange={() => {
                      setIsDirty(true);
                      toggleExtraReferrer(!extraReferrer);
                    }}
                  />
                  <Typography>Enable Extra Referrer</Typography>
                </Box>
              </Box>
            </Box>

            <Box display="flex" justifyContent="flex-end" marginTop="16px" py={2} gap={4}>
              <Button onClick={onClose} data-testmation="billingSettingCloseButton">
                Close
              </Button>
              <Button
                disabled={!isDirty}
                variant="contained"
                color="primary"
                onClick={onSave}
                data-testmation="billingSettingsSaveButton"
              >
                {tl("billing.save")}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Panel>
  );
};

export default BillingSettingsPanel;
