import React from "react";
import { useDispatch } from "react-redux";
import { Box, Button, Checkbox, Tab, Tabs, Typography } from "@mui/material";

import List from "../../../components/List";
import Panel from "../../../components/Panel";
import { t, tl } from "../../../components/translate";
import { rupeeDisplay } from "../../../helpers/rupee";
import IpdBillPrintBtnGroup from "../PrintButtonGroup";
import BillShow from "../../Billing/BillShow/BillShow";
import { getClientDetails } from "../../../api/client";
import ClientInfo from "../../Client/ClientInfo/ClientInfo";
import OkhatiDialog from "../../../components/Dialog/Dialog";
import { Client } from "../../../interfaces/ClientInterface";
import { notificationAdd } from "../../../actions/notification";
import { CalendarFunctions } from "../../../components/Calendar";
import { clientFullNameSelector } from "../../../reducers/client";
import { finaliseBill, getBills, getClientBalance } from "../../../api/bill";
import { BillStatus, BillType, DocumentTypes, VisitType } from "../../../interfaces/BillInterfaces";
import RecordRefundPayment from "../../Billing/RecordPayment/RecordRefundPayment";
import mixpanelAnalytics from "../../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../../mixpanel-analytics/event";
import useCurrentResourceCentre from "../../../hooks/useCurrentResourceCentre";
import PrintBill from "../Print/Bill/ItemWisePrint/IpdBillPrint";

// Summary info row component with optional highlight
const SummaryInfoRow = ({
  label,
  value,
  highlight = false
}: {
  label: string;
  value: string | number;
  highlight?: boolean;
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "space-between"
    }}
  >
    <Typography fontWeight={highlight ? 600 : 400}>{label}</Typography>
    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
      <Typography
        fontWeight={highlight ? 600 : 400}
        data-testmation={`summary-value-${label.replace(/ /g, "-").toLowerCase()}`}
      >
        Rs {value}
      </Typography>
    </Box>
  </Box>
);

enum RefundableReceivable {
  REFUNDABLE = "refundable",
  RECEIVABLE = "receivable"
}

interface PropType {
  clientId: number | string;
  handleClose: () => void;
}

export default function ClientProformaBillsPanel({ clientId, handleClose }: PropType): JSX.Element {
  const dispatch = useDispatch();
  const rc = useCurrentResourceCentre();
  const [, setShowMessagePanel] = React.useState(false);
  const [balance, setBalance] = React.useState<number>(0);
  const [processing, setIsProcessing] = React.useState(false);
  const [client, setClient] = React.useState<Client | null>(null);
  const [checkedBills, setCheckedBills] = React.useState<BillType[]>([]);
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);
  const [proformaBills, setProformaBills] = React.useState<BillType[]>([]);
  const [filteredBills, setFilteredBills] = React.useState<BillType[]>([]);
  const [selectedBill, setSelectedBill] = React.useState<BillType | null>(null);
  const [openClientDetailPanel, setOpenClientDetailPanel] = React.useState(false);
  const [openReceiptRefundPanel, setOpenReceiptRefundPanel] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState<string>(BillStatus.proformaDraft);
  const [proformaBillsTotalAmt, setProformaBillsTotalAmt] = React.useState<number>(0);
  const [balanceStatus, setBalanceStatus] = React.useState({
    label: "",
    value: 0,
    type: ""
  });

  // handle checkbox change
  const handleCheckChange = (ipdId) => {
    if (checkedBills.length && checkedBills.some((bill) => bill.ipdId === ipdId)) {
      setCheckedBills([]);
    } else {
      setCheckedBills(filteredBills.filter((bill) => bill.ipdId === ipdId));
    }
  };

  // fetch client details and proforma bills
  React.useEffect(() => {
    (async () => {
      try {
        const clientRes = await getClientDetails(Number(clientId));
        const res = await getBills({
          clientId: String(clientId),
          isProformaBill: true,
          visitType: VisitType.IPD
        });

        setClient(clientRes);
        setProformaBills(res);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to fetch client details or proforma bills"
          })
        );
      }
    })();
  }, [clientId]);

  // get client balance
  React.useEffect(() => {
    (async () => {
      try {
        const balanceRes = await getClientBalance(Number(clientId));
        setBalance(balanceRes?.balance || 0);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: err.data?.message || "Failed to fetch client balance"
          })
        );
      }
    })();
  }, [clientId, dispatch, openClientDetailPanel, openReceiptRefundPanel]);

  // filter proforma bills based on active tab
  React.useEffect(() => {
    if (proformaBills.length) {
      setFilteredBills(proformaBills.filter((bill) => bill.status === activeTab));
    }
  }, [activeTab, proformaBills]);

  // calculate the total amount of proforma bills
  React.useEffect(() => {
    let totalAmt = 0;
    if (filteredBills.length) {
      filteredBills.forEach((bill) => {
        const dueAmt = bill.summary?.totalAmount;
        totalAmt += dueAmt;
      });
    }
    setProformaBillsTotalAmt(totalAmt);
  }, [filteredBills]);

  React.useEffect(() => {
    (() => {
      const getBalanceStatus = () => {
        const difference = proformaBillsTotalAmt - balance;
        if (difference > 0) {
          return {
            label: "Receivable Amount",
            value: difference,
            type: RefundableReceivable.RECEIVABLE
          };
        }

        return {
          label: "Refundable Amount",
          value: Math.abs(difference),
          type: RefundableReceivable.REFUNDABLE
        };
      };

      const status = getBalanceStatus();
      setBalanceStatus(status);
    })();
  }, [proformaBillsTotalAmt, balance]);

  // finalise proforma bills
  const finaliseProformaBills = async () => {
    try {
      setIsProcessing(true);
      const invoices = filteredBills.filter((bill) => bill.type === DocumentTypes.INVOICE);
      const creditNotes = filteredBills.filter((bill) => bill.type === DocumentTypes.CREDITNOTE);

      // finalise invoices first
      await Promise.all(invoices.map((bill) => finaliseBill(bill.id)));

      // finalise creaditNotes at last
      await Promise.all(creditNotes.map((cn) => finaliseBill(cn.id)));

      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: `Successfully finalized ${filteredBills.length} bills`
        })
      );
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: err.data?.message || "Failed to finalize proforma bills"
        })
      );
    } finally {
      setIsProcessing(false);
      handleClose?.();
      setOpenConfirmDialog(false);
    }
  };

  const footerComponent = (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        width: "100%",
        marginRight: "32px",
        justifyContent: "flex-end",
        gap: "10px"
      }}
    >
      {balanceStatus.value !== 0 && (
        <Button
          disabled={processing}
          variant="contained"
          onClick={() => setOpenReceiptRefundPanel(!openReceiptRefundPanel)}
          data-testmation={
            balanceStatus.type === RefundableReceivable.RECEIVABLE
              ? "proformaRecordReceipt"
              : "proformaCreateRefund"
          }
        >
          {balanceStatus.type === RefundableReceivable.RECEIVABLE
            ? tl("billing.recordReceipt")
            : tl("billing.createRefund")}
        </Button>
      )}
      <Button
        disabled={processing}
        variant="contained"
        onClick={() => {
          if (balance < proformaBillsTotalAmt) {
            dispatch(
              notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: `Balance must be equal to total amount to finalise proforma bills. Please, record a payment of ${balanceStatus.value} before finalise proforma bills`
              })
            );

            return;
          }

          if (balance > proformaBillsTotalAmt) {
            dispatch(
              notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "warning",
                message: `Balance must be equal to total amount to finalise proforma bills. Please, create a refund of ${balanceStatus.value} before finalise proforma bills`
              })
            );

            return;
          }

          setOpenConfirmDialog(true);
        }}
        data-testmation="proformaFinaliseBillsButton"
      >
        {t("billing.finaliseProformaBills")}
      </Button>
    </Box>
  );

  return (
    <Panel
      onClose={handleClose}
      title="Proforma Bills"
      footer={activeTab === BillStatus.proformaDraft && filteredBills.length > 0 && footerComponent}
    >
      {openConfirmDialog && (
        <OkhatiDialog
          title={tl("billing.finaliseProformaBills")}
          description="Are you sure you want to finalise all the proforma bills of this client?"
          next={finaliseProformaBills}
          cancel={() => setOpenConfirmDialog(false)}
          readMode={false}
        />
      )}

      {openClientDetailPanel && clientId && (
        <ClientInfo
          wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
          id={Number(clientId)}
          handleViewClose={() => setOpenClientDetailPanel(false)}
          stayOnCurrentPage
        />
      )}

      {selectedBill && (
        <BillShow
          billId={selectedBill.id}
          wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
          hasCreditNote={false}
          setShowMessagePanel={setShowMessagePanel}
          handleViewClose={() => setSelectedBill(null)}
        />
      )}

      {openReceiptRefundPanel && clientId && (
        <RecordRefundPayment
          clientId={Number(clientId || client?.id)}
          client={client || undefined}
          onClose={() => {
            setOpenReceiptRefundPanel(false);
          }}
          refundMode={balanceStatus.type === RefundableReceivable.REFUNDABLE}
        />
      )}

      <Box
        p={5}
        width="100%"
        height="calc(100vh - 125px)"
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end"
          }}
        >
          {/* client info */}
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            {client && (
              <Box display="flex">
                <Typography sx={{ fontWeight: 600, width: "140px" }}>Name</Typography>
                <Typography
                  sx={{
                    color: "black",
                    fontSize: "14px",
                    textDecoration: "underline",
                    cursor: "pointer"
                  }}
                  onClick={() => setOpenClientDetailPanel(true)}
                >
                  {clientFullNameSelector(client)}
                </Typography>
              </Box>
            )}

            {client?.phone && (
              <Box display="flex">
                <Typography sx={{ fontWeight: 600, width: "140px" }}>Phone</Typography>
                <Typography>{client.phone}</Typography>
              </Box>
            )}
            {client?.email && (
              <Box display="flex">
                <Typography sx={{ fontWeight: 600, width: "140px" }}>Email</Typography>
                <Typography>{client.email}</Typography>
              </Box>
            )}
          </Box>

          {/* show this for finalised bills */}
          {activeTab === BillStatus.billed && filteredBills.length > 0 && (
            <IpdBillPrintBtnGroup
              clientId={Number(clientId)}
              bills={checkedBills.length ? checkedBills : filteredBills}
            />
          )}

          {activeTab === BillStatus.proformaDraft && filteredBills.length > 0 && (
            // leaviing this commented here because it shows bill items categroised by bill id
            // if we decide to use that one then we can uncomment

            // <StatementOfAccount
            //   bills={filteredBills}
            //   labelStyle={{
            //     fontSize: "0.95em",
            //     textTransform: "none"
            //   }}
            //   clientBalance={balance}
            //   billsTotalAmt={proformaBillsTotalAmt}
            //   onPrintClick={() =>
            //     mixpanelAnalytics.track(EVENT.PRINT_PROFORMA_DRRAFTS_STATEMENT, {
            //       rcId: rc.id,
            //       rcName: rc.name
            //     })
            //   }
            // />

            // this one shows bill items all in a single group, makes use of existing template
            <PrintBill
              bills={filteredBills}
              buttonText={t("print.printStatementOfAccount")}
              labelStyle={{
                fontSize: "0.95em",
                textTransform: "none"
              }}
              onPrintClick={() =>
                mixpanelAnalytics.track(EVENT.PRINT_PROFORMA_DRRAFTS_STATEMENT, {
                  rcId: rc.id,
                  rcName: rc.name
                })
              }
              isUsedAsStatementOfAccount
              clientBalance={balance}
              billsTotalAmt={proformaBillsTotalAmt}
            />
          )}
        </Box>

        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs value={activeTab} onChange={(_, tab) => setActiveTab(tab)}>
            <Tab label={t("billing.proformaDraft")} value={BillStatus.proformaDraft} />
            <Tab label={t("billing.billed")} value={BillStatus.billed} />
          </Tabs>
        </Box>

        <Box sx={{ flex: 1, overflow: "auto" }}>
          <List
            rowHeight={50}
            withoutSearch
            isResponsive
            hideCreateButton
            data={filteredBills}
            defaultSortColumn="ipdId"
            activeRow={selectedBill && selectedBill.id}
            onRowClick={(row) => {
              if (selectedBill?.id === row.id) {
                setSelectedBill(null);
              } else {
                setSelectedBill(row);
              }
            }}
            columns={[
              {
                key: "ipdId",
                label: "IPD ID",
                segmentable: activeTab !== BillStatus.proformaDraft,
                segmentBy: (row) => row.ipdId,
                formatter: (row) => (
                  <Typography component="div" variant="body2">
                    {row.ipdId}
                  </Typography>
                )
              },
              {
                key: "issueDate",
                label: "Issue Date",
                formatter: (row) => (
                  <Typography>{CalendarFunctions.bsFullDate(row.transactionDate)}</Typography>
                )
              },
              {
                key: "status",
                label: "Status",
                formatter: (row) => (
                  <Typography component="div" variant="body2">
                    <Box>{row.status}</Box>
                  </Typography>
                )
              },
              {
                key: "total",
                label: "Total",
                formatter: (row) => (
                  <Typography component="div" variant="body2">
                    <Box>{rupeeDisplay(row.total)}</Box>
                  </Typography>
                )
              }
            ]}
            segementSummaryRenderer={(acc) => (
              <Box style={{ background: "#e6e6e6" }} display="flex" flexGrow={1}>
                <Box display="flex" flexGrow={1} paddingLeft="10px">
                  <Checkbox
                    checked={checkedBills.some((b) => b?.ipdId === acc.segment)}
                    onChange={() => handleCheckChange(acc.segment)}
                  />
                  <Typography
                    component="span"
                    flexGrow={1}
                    display="flex"
                    alignItems="center"
                    padding="0 10px 0"
                    fontWeight={500}
                  >
                    {`Bills of IPD ID: ${acc.segment}`}
                  </Typography>
                </Box>
              </Box>
            )}
          />
        </Box>

        {activeTab === BillStatus.proformaDraft && filteredBills?.length > 0 && (
          <Box
            sx={{
              paddingY: "15px",
              borderTop: "1px solid #ededed",
              borderBottom: "1px solid #ededed"
            }}
          >
            <Box
              sx={{
                width: "100%",
                // maxWidth: "400px",
                display: "flex",
                gap: "10px",
                marginLeft: "auto",
                flexDirection: "column"
              }}
            >
              <SummaryInfoRow label="Proforma Bills Total Amount" value={proformaBillsTotalAmt} />
              <SummaryInfoRow label="Balance Amount" value={balance} />
              {balanceStatus.value !== 0 && (
                <SummaryInfoRow label={balanceStatus.label} value={balanceStatus.value} highlight />
              )}
            </Box>
          </Box>
        )}
      </Box>
    </Panel>
  );
}
