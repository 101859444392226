import React from "react";
import { Box, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import * as moment from "moment";
import cloneDeep from "lodash/cloneDeep";
import { connect, useSelector } from "react-redux";
import VitalRow, { vitals as vitalsModel } from "./VitalRow";
import { tl } from "../../../components/translate";
import { RootState } from "../../../store";

interface VitalsPlainInteraface {
  aVitals?: [];
  onSave: (data) => void;
  vitalSettings: any;
}

const VitalsPlain: React.FC<VitalsPlainInteraface> = ({ aVitals, onSave, vitalSettings }) => {
  const [saving, setSaving] = React.useState(false);
  const vitalsOrder =
    useSelector(
      (state: RootState) =>
        state.userContext?.resourceCentre?.settings?.assessmentSettings?.vitalsOrder
    ) || [];
  const defaultVitals = [];
  if (vitalSettings) {
    vitalsOrder.forEach((item) => {
      if (vitalSettings[item]) {
        const vitalInfo = vitalsModel.find((vt) => vt.dbKey === item);
        const formattedDefaultVital = {
          name: vitalInfo.name,
          reading: vitalInfo.defaultValue,
          type: vitalInfo.type,
          unit: vitalInfo.unit,
          extraInfo: null,
          date: moment()
        };
        if (vitalInfo) defaultVitals.push(formattedDefaultVital);
      }
    });
  }

  const [vitals, setVitals] = React.useState(null);

  React.useEffect(() => {
    setVitals(aVitals?.length > 0 ? aVitals : defaultVitals);
  }, [aVitals]);

  return (
    <>
      <Box style={{ width: "100%", display: "flex" }}>
        <Box width="30%">
          <Typography style={{ fontWeight: 600 }}>{tl("assessment.vital")}</Typography>
        </Box>
        <Box width="30%" pl="20px">
          <Typography style={{ fontWeight: 600 }}>{tl("assessment.reading")}</Typography>
        </Box>
        <Box width="40%" pl="42px">
          <Typography style={{ fontWeight: 600 }}>{tl("assessment.extraInfo")}</Typography>
        </Box>
      </Box>
      <Box style={{ width: "100%" }}>
        {vitals?.map((vi, index) => (
          <VitalRow
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            vital={vi}
            onChange={(newObj) => {
              const newArr = vitals;
              newArr[index] = newObj;
              setVitals(newArr);
            }}
            onRemove={() => {
              const newArr = cloneDeep(vitals);
              newArr.splice(index, 1);
              setVitals(newArr);
            }}
          />
        ))}
      </Box>
      <Box
        data-testmation="vitals.AddNewButton"
        onClick={() => {
          setVitals([
            ...vitals,
            {
              name: null,
              reading: null,
              unit: null,
              extraInfo: null,
              date: moment()
            }
          ]);
        }}
        style={{ cursor: "pointer", width: "80px" }}
      >
        <Typography>
          <u>{tl("assessment.addNew")}</u>
        </Typography>
      </Box>
      <Box
        width="100%"
        display="flex"
        justifyContent="space-between"
        padding="0px 24px"
        alignContent="center"
      >
        <Box />
        <Box>
          <LoadingButton
            data-testmation="vitalsSaveButton"
            variant="contained"
            color="primary"
            disabled={saving}
            loading={saving}
            onClick={async () => {
              setSaving(true);
              const filteredVitals = vitals.filter((vi) => vi.name && vi.reading);
              filteredVitals.forEach((element) => {
                // eslint-disable-next-line no-param-reassign
                delete element.type;
              });
              await onSave(filteredVitals);
              await setVitals(defaultVitals);
              setSaving(false);
            }}
          >
            {tl("assessment.save")}
          </LoadingButton>
        </Box>
      </Box>
    </>
  );
};

VitalsPlain.defaultProps = {
  aVitals: []
};

const mapStateToProps = (state) => {
  const rc =
    state.resources.resourceCentres.find((rC) => rC.id === state.userContext.resourceCentreId) ||
    state.userContext.resourceCentre;
  return {
    vitalSettings: rc?.settings.vitalSettings
  };
};

export default connect(mapStateToProps, null)(VitalsPlain);
