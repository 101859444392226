import React, { useState } from "react";
import { Autocomplete, Box, MenuItem, TextField, Typography } from "@mui/material";
import { useAppSelector } from "../../../store/hooks";
import { ServiceInterface } from "../../../interfaces/ServiceInterface";

interface RangeFieldProps {
  onChangeLTE: (any) => any;
  onChangeGTE: (any) => any;
  lteVal?: number;
  gteVal?: number;
  text: string;
}

export const RangeField: React.FC<RangeFieldProps> = ({
  onChangeLTE,
  onChangeGTE,
  text,
  lteVal = null,
  gteVal = null
}) => {
  const [lte, setLte] = useState(lteVal);
  const [gte, setGte] = useState(gteVal);
  return (
    <Box display="flex" alignItems="center">
      <Typography className="noWrap">{text}</Typography>
      <TextField
        data-testmation="smallValue"
        size="small"
        type="number"
        value={gte || null}
        style={{ width: "40px" }}
        onChange={(e) => {
          setGte(parseInt(e.target.value, 2));
          onChangeGTE(e.target.value);
        }}
      />
      <Typography className="noWrap">and</Typography>
      <TextField
        data-testmation="bigValue"
        size="small"
        type="number"
        value={lte || null}
        style={{ width: "40px" }}
        onChange={(e) => {
          setLte(parseInt(e.target.value, 2));
          onChangeLTE(e.target.value);
        }}
      />
    </Box>
  );
};

interface GenderSelectionFieldProps {
  onSelect: (v) => void;
  val: number;
}

export const GenderSelectionField = ({ onSelect, val }: GenderSelectionFieldProps): JSX.Element => {
  const [gender, setGender] = useState(val);
  const options = [
    { value: 1, label: "Male" },
    { value: 2, label: "Female" },
    { value: 3, label: "Other" }
  ];
  return (
    <Box display="flex" alignItems="center">
      <Typography className="noWrap">who is</Typography>
      <TextField
        size="small"
        slotProps={{
          inputLabel: { shrink: true }
        }}
        select
        value={gender || 1}
        className="noWrap"
        onChange={(e) => {
          setGender(e.target.value);
          onSelect(e.target.value);
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};

interface BirthdaySelectionFieldProps {
  onSelect: (v) => void;
  val: string;
}

export const BirthdaySelectionField = ({
  onSelect,
  val
}: BirthdaySelectionFieldProps): JSX.Element => {
  const [birthday, setBirthday] = useState(val);
  const options = [{ value: "today", label: "Today" }];
  return (
    <Box display="flex" alignItems="center">
      <Typography className="noWrap">whose birthday is</Typography>
      <TextField
        size="small"
        slotProps={{
          inputLabel: { shrink: true }
        }}
        select
        value={birthday}
        className="noWrap"
        onChange={(e) => {
          setBirthday(e.target.value);
          onSelect(e.target.value);
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};
interface ServiceValue {
  id: number;
  duration: number;
}

interface ServiceFieldProps {
  onSelect: (v: ServiceValue) => void;
  val: ServiceValue;
}

export const ServiceSelectionField = ({ onSelect, val }: ServiceFieldProps): JSX.Element => {
  const services = useAppSelector((state) =>
    state.services.collection.filter(({ active }) => active)
  );
  const [value, setValue] = useState<{
    service: ServiceInterface;
    duration: number;
  }>({
    service: (services || []).find((item) => item.id === val.id),
    duration: val.duration || 1
  });

  return (
    <Box display="flex" alignItems="center">
      <Typography className="noWrap">who has received</Typography>
      <Autocomplete
        size="small"
        disableClearable
        options={services || []}
        value={value.service}
        onChange={(_, newValue) => {
          setValue((prevState) => ({ ...prevState, service: newValue }));
          onSelect({ id: newValue.id, duration: value.duration });
        }}
        getOptionLabel={(option) => option.name}
        renderOption={(props, item) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={item.id}>
            {item.name}
          </li>
        )}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            className="noWrap"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            sx={{ minWidth: 150, margin: 0 }}
            placeholder="select service"
            margin="dense"
            fullWidth
          />
        )}
      />
      <Typography className="noWrap">service in last </Typography>
      <TextField
        size="small"
        slotProps={{
          inputLabel: { shrink: true }
        }}
        select
        value={value.duration}
        className="noWrap"
        onChange={(e) => {
          setValue((prevState) => ({ ...prevState, duration: e.target.value }));
          onSelect({ id: value.service?.id, duration: e.target.value });
        }}
      >
        {Array.from({ length: 12 }, (_, i) => i + 1).map((option) => (
          <MenuItem key={option} value={option}>
            {`${option} months`}
          </MenuItem>
        ))}
      </TextField>
    </Box>
  );
};
