import React from "react";
import { Box } from "@mui/material";
import Panel from "../../../../components/Panel";
import LabSettings from "../LabSettings";
import { useAppSelector } from "../../../../store/hooks";
import { ServiceProvider } from "../../../../interfaces/ServiceProvidersInterface";
import { RootState } from "../../../../store";

interface Props {
  onClose: () => void;
}

const LabSettingsPanel = ({ onClose }: Props): JSX.Element => {
  const resourceCentre = useAppSelector((state) => state.resources.resourceCentres[0]);
  const userGroup: string = useAppSelector((state) => state.userContext?.userCreds?.userGroups[0]);
  const serviceProviders: ServiceProvider[] = useAppSelector(
    (state: RootState) => state.resources.resourceCentreServiceProviders
  );

  return (
    <Panel onClose={onClose} title="Lab Settings">
      <Box px={4}>
        <LabSettings
          resourceCentre={resourceCentre}
          serviceProviders={serviceProviders}
          userGroup={userGroup}
        />
      </Box>
    </Panel>
  );
};

export default LabSettingsPanel;
