import React, { JSX, useRef } from "react";
import ReactToPrint from "react-to-print";
import { Box, Typography } from "@mui/material";
import {
  ClientIllnessesDiv,
  ClientInfoDiv,
  ClientVitalsDiv,
  MainLabDataDisplayDiv,
  AuthorizedSpInfo,
  ClientProfileImage
} from "../MedicalPrintComponents/DefaultPrintComponents";
import { LabRecord } from "../../../../interfaces/Lab";
import usePrintStyles from "../../../../hooks/usePrintStyles";
import CustomHeader from "../../../Lab/LabPrint/CustomHeader";
import Logo from "../../../../../assets/images/poweredByOkhati.png";
import { ResourceCentre } from "../../../../interfaces/ResourceCentreInterface";
import { CenteredLetterHead, DefaultLetterHead } from "../../../../components/Print/Print";
import useCurrentResourceCentre from "../../../../hooks/useCurrentResourceCentre";

interface MedicalPrintViewPropTypes {
  resourceCentre?: ResourceCentre;
  labRecord: LabRecord;
  assessment: any;
  forwardRef: React.RefObject<HTMLDivElement | null>;
  centralizedHeader?: boolean;
  showBarCode?: boolean;
  showQrCode?: boolean;
  showReferrer?: boolean;
  showMedicalDisclaimerOnFooter?: boolean;
}

export const MedicalPrintView = ({
  resourceCentre,
  labRecord,
  showBarCode,
  showQrCode,
  centralizedHeader,
  assessment,
  forwardRef,
  showReferrer,
  showMedicalDisclaimerOnFooter
}: MedicalPrintViewPropTypes): JSX.Element | null => {
  const { client } = labRecord;
  const { authorizedSP1, authorizedSP2 } = labRecord?.results?.approvedBy || {};
  const profileImage = labRecord.customer.customerDetails?.profileImageS3Url || null;
  const includeLetterhead = resourceCentre?.settings?.medicalSettings?.includeLetterhead;
  const customHeaderImage = resourceCentre?.settings?.medicalSettings?.customHeaderImage;
  const stampImage = resourceCentre?.settings?.medicalSettings?.stampImage;
  const recentAssessment = assessment?.sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )?.[0];

  if (!recentAssessment || !labRecord) return null;

  return (
    <div ref={forwardRef}>
      <Box component="table" width="100%" sx={{ position: "relative" }}>
        {!includeLetterhead && <Box sx={{ height: "2.5cm" }} />}
        <tbody>
          <tr>
            <td>
              {/* letter head */}
              {includeLetterhead && (
                <div>
                  {customHeaderImage ? (
                    <CustomHeader imageUrl={customHeaderImage} />
                  ) : (
                    <>
                      {centralizedHeader ? (
                        <CenteredLetterHead resourceCentre={resourceCentre} />
                      ) : (
                        <DefaultLetterHead resourceCentre={resourceCentre} />
                      )}
                    </>
                  )}
                </div>
              )}

              {/* title */}
              <Typography
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  fontSize: "0.35cm",
                  fontWeight: 600,
                  textTransform: "uppercase",
                  whiteSpace: "nowrap"
                }}
              >
                {resourceCentre?.settings?.medicalSettings?.customPrintTitle || ""}
              </Typography>

              {/* client info and illness div */}
              <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                <Box sx={{ width: "calc(100% - 2.5cm - 8px)" }}>
                  <ClientInfoDiv
                    labRecord={labRecord}
                    showReferrer={Boolean(showReferrer)}
                    showBarCode={showBarCode}
                  />
                  <ClientIllnessesDiv recentAssessment={recentAssessment} />
                </Box>

                <ClientProfileImage profileImage={profileImage} />
              </Box>

              {/* client vitals div */}
              <ClientVitalsDiv labRecord={labRecord} recentAssessment={recentAssessment} />

              {/* present health status */}
              <Typography sx={{ display: "flex", justifyContent: "center", fontWeight: 600 }}>
                {client?.presentHealthStatus}
              </Typography>

              {/* main lab data display div */}
              <MainLabDataDisplayDiv
                labRecord={labRecord}
                recentAssessment={recentAssessment}
                showQrCode={Boolean(showQrCode)}
                showMedicalDisclaimerOnFooter={showMedicalDisclaimerOnFooter}
              />

              {/* stamp image */}
              {stampImage && (
                <Box position="absolute" left={0} bottom={22}>
                  <img
                    src={stampImage}
                    alt="stamp"
                    style={{
                      display: "block",
                      width: 100,
                      height: 100
                    }}
                  />
                </Box>
              )}

              {/* sps */}
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-end",
                  gap: "8px"
                }}
              >
                <Typography
                  sx={{
                    fontSize: "0.28cm",
                    fontWeight: 600
                  }}
                >
                  (Official Stamp)
                </Typography>

                {authorizedSP1 && <AuthorizedSpInfo sp={authorizedSP1} />}
                {authorizedSP2 && <AuthorizedSpInfo sp={authorizedSP2} />}
              </Box>
            </td>
          </tr>
        </tbody>
      </Box>
      <Box
        display="flex"
        width="100%"
        className="borderTopBlack1"
        justifyContent="space-between"
        paddingTop="0.1cm"
      >
        {showMedicalDisclaimerOnFooter && (
          <Typography
            sx={{
              fontSize: "0.25cm"
            }}
          >
            Recommended by Ministry of Health and Population, Government of Nepal. This report is
            valid for two months from the date of Medical Examination.
          </Typography>
        )}
        <Box component="span" marginLeft={2} flexGrow={1} />
        <Box className="FooterPrintOkhatiLogo">
          <img style={{ display: "block" }} src={Logo} alt="logo" height="100%" width="100%" />
        </Box>
      </Box>
    </div>
  );
};

const ForeignEmploymentPrint = ({
  labRecord,
  buttonText,
  assessment = null,
  showMedicalDisclaimerOnFooter
}: {
  labRecord: LabRecord;
  buttonText?: string | React.ReactNode;
  assessment?: any[] | null;
  showMedicalDisclaimerOnFooter?: boolean;
}): JSX.Element => {
  usePrintStyles({ pageStyle: "size: A4; margin: 1mm 10mm" }).runPrintStyles();
  const printRef = useRef(null);
  const resourceCentre = useCurrentResourceCentre();
  const centralizedHeader = resourceCentre?.settings?.printSettings.centralizedHeader;
  const showBarCode = resourceCentre?.settings?.medicalSettings.showBarCode;
  const showQrCode = resourceCentre?.settings?.medicalSettings.showQrCode;
  const showReferrer = resourceCentre?.settings?.medicalSettings.showReferrer;

  return (
    <div style={{ width: "100%" }}>
      <ReactToPrint
        trigger={() => (
          <Typography
            component="span"
            sx={{
              width: "100%",
              height: "100%",
              padding: "5px 15px",
              fontSize: "0.95em",
              display: "flex",
              alignItems: "center",
              textTransform: "none"
            }}
          >
            {buttonText || "Print"}
          </Typography>
        )}
        content={() => printRef.current}
      />
      <iframe className="displayContents" title="printContainer">
        <MedicalPrintView
          forwardRef={printRef}
          resourceCentre={resourceCentre}
          labRecord={labRecord}
          showBarCode={showBarCode}
          showQrCode={showQrCode}
          centralizedHeader={centralizedHeader}
          assessment={assessment}
          showReferrer={showReferrer}
          showMedicalDisclaimerOnFooter={showMedicalDisclaimerOnFooter}
        />
      </iframe>
    </div>
  );
};

export default ForeignEmploymentPrint;
