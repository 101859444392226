import React from "react";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { getFullName } from "../../../helpers/formatters";
import { canUseSpecialities } from "../../ServiceProvider/ServiceproviderShow";
import { getSpeciality } from "../../../reducers/serviceTags";
import { RootState } from "../../../store";
import Panel from "../../../components/Panel";
import { EmployeeInterface } from "./AllUsers";
import { AdminPermissionGroup } from "../../../interfaces/User";

export default function UserDetails({
  user,
  onClose
}: {
  user: EmployeeInterface & { nonUserEmployee: boolean; isSp: boolean };
  onClose: () => void;
}): JSX.Element | null {
  const serviceTags = useSelector((state: RootState) => state.bookableResources.serviceTags);
  const permissionGroup = useSelector(
    (state: RootState) => state.userContext?.userCreds?.userGroups[0]
  );

  if (!user) return null;
  const showUserNameAndPassword = !user.nonUserEmployee;
  const { isSp } = user;
  const isAdmin = AdminPermissionGroup.includes(permissionGroup);

  const userGroup = user?.user?.userGroups?.length ? user?.user?.userGroups[0]?.name || "" : "";
  const labelValues = [
    { label: "Full Name", value: getFullName(user) },
    { label: "Email", value: user.email },
    { label: "Phone", value: user.phone },
    ...(showUserNameAndPassword && isAdmin
      ? [
          { label: "User Name", value: user.user.username },
          { label: "Temporary Password", value: user.user.tempPassword }
        ]
      : []),
    ...(isSp
      ? [
          {
            label: "Speciality",
            value: canUseSpecialities(userGroup, user.specialities)
              ? getSpeciality(serviceTags, user.specialities[0])
              : user.speciality
          },
          { label: "Registration Number", value: user.registrationNumber },
          { label: "Qualification", value: user.qualification }
        ]
      : [])
  ];

  return (
    <div>
      <Panel onClose={onClose}>
        <Box pl={5} pr={5} width="100%">
          {labelValues.map((item) => (
            <Grid key={item.label} container>
              <Grid size={4}>
                <Typography variant="body1">{item.label}</Typography>
              </Grid>
              <Grid size={8}>
                <Typography variant="body2">
                  <Typography variant="body1">{item.value}</Typography>
                </Typography>
              </Grid>
            </Grid>
          ))}
        </Box>
      </Panel>
    </div>
  );
}
