import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import { styled } from "@mui/material/styles";
import { pick } from "lodash";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { notificationAdd } from "../../../actions/notification";
import { postPublicBooking } from "../../../api/publicBooking";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import Modal from "../../../components/Modal/Modal";
import { t } from "../../../components/translate";
import { Client } from "../../../interfaces/ClientInterface";
import { PaymentMethodsType, PublicService } from "../../../interfaces/PublicLabInterface";
import { getPublicEvents } from "../../../slices/publicLab";
import { isValidData } from "../helpers";
import PaymentDetails from "./PaymentDetails";
import PersonalDetails from "./PersonalDetails";
import ScheduleList from "./ScheduleList";

interface Props {
  selectedService: PublicService;
  onSave: () => void;
}

export interface StateProps {
  end: string | null;
  resourceCentreId: number;
  serviceId: number;
  start: string | null;
  client: Partial<Client> | null;
  paymentMethod: PaymentMethodsType | null;
}

const getInitialState = (selectedService: PublicService): StateProps => ({
  start: null,
  end: null,
  resourceCentreId: selectedService.resourceCentreId,
  serviceId: selectedService.id,
  client: null,
  paymentMethod: null
});

const requiredValues = ["start", "end", "resourceCentreId", "serviceId", "client", "paymentMethod"];

const showSubDetail = (currentState: StateProps, row: string): JSX.Element | string => {
  const { start, client, paymentMethod } = currentState;
  let text = "";
  if (row === "timing") {
    if (!start) {
      return text;
    }
    const time = moment(start).format("hh:mm a");
    text = `${convertADtoBS(new Date(start)).formatted4} ${time}`;
  }
  if (row === "personalDetail") {
    if (!client) {
      return text;
    }
    text = `${client.firstName} ${client.lastName}`;
  }
  if (row === "paymentMethod") {
    if (!paymentMethod) {
      return text;
    }
    text = t(`${paymentMethod}`);
  }
  return <Typography fontWeight="normal">{text}</Typography>;
};

const Accordion = styled((props: AccordionProps) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0
  },
  "&:before": {
    display: "none"
  }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: "rgba(255, 255, 255, .05)",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(180deg)"
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1)
  }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)"
}));

export default function ScheduleDetails({ selectedService, onSave }: Props): JSX.Element | null {
  const dispatch = useDispatch();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (selectedService?.resourceCentreId) {
      dispatch(getPublicEvents(selectedService.resourceCentreId));
    }
  }, [selectedService?.resourceCentreId, dispatch]);
  const [state, setState] = React.useState<StateProps>(getInitialState(selectedService));
  if (!selectedService) return null;

  const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { resourceCentre } = selectedService;

  const showNotification = ({
    message,
    variant
  }: {
    message: string;
    variant: "success" | "warning" | "error" | "info";
  }) => {
    dispatch(
      notificationAdd({
        id: new Date().getUTCMilliseconds(),
        variant,
        message,
        autoTimeout: true
      })
    );
  };

  return (
    <>
      <Grid mb={2} mt={2} container spacing={2}>
        <Grid size={{ sm: 6 }}>
          <Typography fontSize="1.3rem" fontWeight="bold">
            {selectedService.name}
          </Typography>
          <Box mt="10px" alignItems="center" display="flex">
            <Typography mr="10px">{resourceCentre.name}</Typography>
            <LocationOnIcon fontSize="small" color="primary" />
            <Typography>{resourceCentre.city}</Typography>
          </Box>
        </Grid>
        <Grid size={{ sm: 2 }}>
          <Typography
            fontSize="1rem"
            fontWeight="bold"
          >{`Rs. ${selectedService.grossTotalPrice}/-`}</Typography>
        </Grid>
        <Grid mt={2} size={{ sm: 12, md: 10 }}>
          <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Box alignItems="center" display="flex">
                <Typography fontWeight="medium" mr="10px">
                  Choose Timing
                </Typography>
                {showSubDetail(state, "timing")}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <ScheduleList
                currentState={state}
                onChange={(newState) => {
                  setState(newState);
                  setExpanded("panel2");
                }}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            disabled={!state.start}
            expanded={expanded === "panel2"}
            onChange={handleChange("panel2")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel2bh-content"
              id="panel2bh-header"
            >
              <Box alignItems="center" display="flex">
                <Typography mr="10px" fontWeight="medium">
                  Personal Details
                </Typography>
                {showSubDetail(state, "personalDetail")}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <PersonalDetails
                resourceCentreId={resourceCentre.id}
                currentState={state}
                onChange={(newState) => setState(newState)}
                onContinue={() => setExpanded("panel3")}
              />
            </AccordionDetails>
          </Accordion>

          <Accordion
            disabled={!state.client?.id}
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3bh-content"
              id="panel3bh-header"
            >
              <Box alignItems="center" display="flex">
                <Typography mr="10px" fontWeight="medium">
                  Payment
                </Typography>
                {showSubDetail(state, "paymentMethod")}
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <PaymentDetails
                currentState={state}
                onChange={(updatedState) => {
                  setState(updatedState);
                  setExpanded(false);
                }}
              />
            </AccordionDetails>
          </Accordion>
        </Grid>
      </Grid>
      <Button
        data-testmation="createBookingSaveBtn"
        disabled={!state.paymentMethod}
        variant="contained"
        onClick={() => setModalOpen(true)}
      >
        Save
      </Button>
      <Modal
        open={modalOpen}
        saveAndClose
        onClose={() => setModalOpen(false)}
        onSave={async () => {
          if (isValidData<StateProps>(state, requiredValues)) {
            const response = await postPublicBooking({
              ...pick(state, requiredValues),
              clientId: state.client.id
            });
            if (response?.id) {
              showNotification({ message: "Successfully Booked.", variant: "success" });
              onSave();
            } else {
              showNotification({ message: "Sorry, Something Went Wrong!.", variant: "error" });
            }
          } else {
            showNotification({ message: "Invalid Data", variant: "error" });
          }
        }}
      >
        <Typography>Are You Sure You Want to Confirm this Booking ?</Typography>
      </Modal>
    </>
  );
}
