import React, { useState, useEffect } from "react";
import { Box, Tooltip, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import startCase from "lodash/startCase";
import Filters from "./Filters";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import useMobileScreen from "../../hooks/useMobileScreen";
import List, { EmptyView } from "../../components/OList";
import { getPublicSmsReport } from "../../api/reports";
import "./Reports.scss";
import PageControl from "../../components/PageControl";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";
import { t } from "../../components/translate";
import { notificationAdd } from "../../actions/notification";

export enum MESSAGE_TYPE {
  OTP = "OTP",
  APP_LINK = "APP_LINK",
  PUBLIC_BOOKING = "PUBLIC_BOOKING"
}

const PublicSmsReport = (): JSX.Element => {
  const dispatch = useDispatch();
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);
  const [filters, setFilters] = useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    publicSmsType: null
  });
  const [reportData, setReportData] = useState([]);
  const [page, setPage] = useState(0);
  const pageSize = 50;
  const [total, setTotal] = useState(0);

  useEffect(() => {
    (async () => {
      try {
        setIsRequestLoading(true);
        const data = await getPublicSmsReport({
          ...filters,
          page,
          pageSize,
          type: filters.publicSmsType
        });
        setTotal(data.total);
        setReportData(data.results);
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: err?.data?.message || "Failed to fetch SMS report",
            autoTimeout: true
          })
        );
      } finally {
        setIsRequestLoading(false);
      }
    })();
  }, [filters, page]);

  const isMobileScreen = useMobileScreen();
  const { isRnWebView } = useIsReactNativeWebView();
  return (
    <>
      <Box overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1000px" : "auto"}>
          <Box mx="32px">
            <Filters
              filters={filters}
              onSetFilters={(newfilters) => {
                setFilters(newfilters);
                setPage(0);
              }}
              isRequestLoading={isRequestLoading}
            />
          </Box>
          <Box width={isMobileScreen ? "calc(100vw - 16px)" : "auto"}>
            <Box
              marginTop="32px"
              width={isMobileScreen ? "960px" : "auto"}
              height="calc(100vh - 260px)"
              className="smsReportList"
            >
              <List
                automation="smsReportList"
                data={reportData}
                rowHeight={40}
                defaultSortColumn="clientName"
                defaultSortOrder={-1}
                activeRow={0}
                adjustHeightToContents
                columns={[
                  {
                    key: "createdDate",
                    label: `${t("msgReport.Date")}`,
                    formatter: (row) => (
                      <Typography>
                        {calFns.convertADtoBS(new Date(row.created_at)).formatted}
                      </Typography>
                    )
                  },
                  {
                    key: "phone",
                    label: `${t("msgReport.PhNo.")}`,
                    formatter: (row) => <Typography>{row.phone}</Typography>
                  },
                  {
                    key: "status",
                    label: "SMS Status",
                    formatter: (row) => (
                      <Typography>{row.smsStatus === 200 ? "Success" : "Fail"}</Typography>
                    )
                  },
                  {
                    key: "type",
                    label: `${t("msgReport.msgType")}`,
                    formatter: (row) => <Typography>{startCase(row.type)}</Typography>
                  },
                  {
                    key: "sms",
                    label: `${t("msgReport.smsContent")}`,
                    formatter: (row) => (
                      <Tooltip title={row.sms || ""}>
                        <Typography>{(row.sms || "").substring(0, 20)}...</Typography>
                      </Tooltip>
                    )
                  }
                ]}
              >
                <EmptyView>
                  <Box textAlign="center" padding="50px">
                    {t("msgReport.noItemsToShow")}
                  </Box>
                </EmptyView>
              </List>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box className="labNavigatePage">
        <PageControl
          page={page}
          pageSize={pageSize}
          onSetPage={(v) => {
            setPage(v);
          }}
          maximumDataCount={total}
        />
      </Box>
    </>
  );
};

export default PublicSmsReport;
