import PrintIcon from "@mui/icons-material/Print";
import { Box, Link, Typography } from "@mui/material";
import { push } from "connected-react-router";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import * as assessmentActions from "../../../actions/assessments";
import { getLabTestRecordsByClientId } from "../../../api/labTest";
import List, { EmptyView } from "../../../components/OList";
import { tl } from "../../../components/translate";
import { LabRecord, LabTestRecordResultData } from "../../../interfaces/Lab";
import EVENT from "../../../mixpanel-analytics/event";
import mixpanelAnalytics from "../../../mixpanel-analytics/mixpanelAnalytics";
import LabPrint from "../../Lab/LabPrint/index";
import RelatedLabTests from "./RelatedLabTests";
import "./styles.scss";
import useCurrentResourceCentre from "../../../hooks/useCurrentResourceCentre";
import ForeignEmploymentPrint from "../../Medical/Print/Templates/ForeignEmploymentPrint";

const ClientLabs = ({ clientId, navigateTo, getClientAssessments, clientAssessments }) => {
  const dispatch = useDispatch();
  const rc = useCurrentResourceCentre();
  const [data, setData] = useState<LabRecord[]>([]);
  useEffect(() => {
    (async () => {
      const labData = await getLabTestRecordsByClientId(clientId, { page: 0, pageSize: 10 });
      setData(labData.results);
    })();
    if (clientId) getClientAssessments(clientId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId]);
  const [selectedLabTest, setSelectedLabTest] = React.useState<LabTestRecordResultData | null>(
    null
  );
  const rowStyles = { fontSize: "0.7rem" };

  return (
    <div className="clientLabs">
      <List
        automation="clientLabs"
        rowHeight={40}
        data={data}
        adjustHeightToContents
        onRowClick={({ id, status }) => navigateTo(`/lab/labRecords/${id}?status=${status}`)}
        columns={[
          {
            key: "labId",
            label: tl("lab.testId"),
            sortable: true,
            formatter: ({ id }) => <Typography style={rowStyles}>{id}</Typography>
          },
          {
            key: "labTests",
            label: tl("lab.tests"),
            formatter: (row) => (
              <Typography style={rowStyles}>
                {row.results.data.map((item, i) => (
                  <Link
                    // eslint-disable-next-line react/no-array-index-key
                    key={i}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedLabTest(item);
                    }}
                  >
                    {i !== 0 && ", "}
                    {item.name}
                  </Link>
                ))}
              </Typography>
            )
          },
          {
            key: "resultDate",
            label: "Result Date",
            formatter: ({ resultDate }) => (
              <Typography style={rowStyles}>{moment(resultDate).format("LL")}</Typography>
            )
          },
          {
            key: "",
            label: "",
            formatter: (row) => {
              const isMedicalTest =
                row?.results?.data.length > 0 && row.results.data[0]?.id === 705;
              return (
                <Box
                  sx={{
                    color: "green",
                    cursor: "pointer",
                    padding: "8px"
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {isMedicalTest ? (
                    <ForeignEmploymentPrint
                      labRecord={row}
                      assessment={clientAssessments}
                      buttonText={<PrintIcon htmlColor="black" />}
                    />
                  ) : (
                    <LabPrint
                      labData={row}
                      buttonText={<PrintIcon htmlColor="black" />}
                      onPrintClick={() =>
                        mixpanelAnalytics.track(EVENT.PRINT_LAB_DEFAULT, {
                          rcId: rc.id,
                          rcName: rc.name
                        })
                      }
                    />
                  )}
                </Box>
              );
            }
          }
        ]}
      >
        <EmptyView>
          <Box fontSize="0.7rem" marginTop="16px" display="flex" justifyContent="center">
            No labs created yet for the client...
          </Box>
        </EmptyView>
      </List>
      {data.length > 0 && (
        <Box display="flex" flexDirection="row-reverse">
          <Link
            component="button"
            onClick={() => dispatch(push(`/reports/labReport?clientId=${clientId}`))}
          >
            Show More
          </Link>
        </Box>
      )}
      {selectedLabTest && (
        <RelatedLabTests
          onClose={() => setSelectedLabTest(null)}
          data={data}
          selectedLabTest={selectedLabTest}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  resourceCentre: state.userContext.resourceCentre,
  clientAssessments: state.assessments.clientSpecific
});

export default connect(mapStateToProps, (dispatch) => ({
  navigateTo: (url) => dispatch(push(url)),
  getClientAssessments: (id) => dispatch(assessmentActions.getClientAssessments(id))
}))(ClientLabs);
