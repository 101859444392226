/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import { TextField, TextFieldProps } from "@mui/material";
import PhoneNumber from "awesome-phonenumber";
import ContentEditable from "../ContentEditable/ContentEditable";
import MuiPhoneNumber from "../PhoneNumber";

interface ContentEditableWithTextFieldPropsInterface {
  label: string | JSX.Element;
  placeholder: string;
  saveOnFocusOut?: boolean;
  value: string | number;
  onSave: (val) => void;
  textArea?: boolean;
  maxLength?: number;
  rows?: number;
  width: string;
  isMobile?: boolean;
  hidelabel?: boolean;
  disabled?: boolean;
  maxValue?: number;
  type?: string;
}

const getValue = (maxValue: number | undefined, value: number | string) => {
  if (maxValue) {
    return value > maxValue ? maxValue : value;
  }
  return value;
};

const ContentEditableWithTextField: React.FC<
  ContentEditableWithTextFieldPropsInterface & TextFieldProps
> = (props) => {
  const {
    label,
    placeholder,
    textArea,
    maxLength,
    width,
    rows,
    hidelabel,
    disabled,
    value,
    onSave,
    saveOnFocusOut,
    isMobile,
    maxValue,
    type,
    ...inputProps
  } = props;
  const [editMode, setEditMode] = useState(false);
  const [state, setState] = useState(value);
  const [isDirty, setIsDirty] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    setState(value);
  }, [value]);

  const cancelEdit = () => {
    setState(value);
    setEditMode(false);
    setIsDirty(false);
  };

  return (
    <ContentEditable
      disabled={disabled}
      hidelabel={hidelabel}
      editMode={editMode}
      setEditMode={setEditMode}
      label={label}
      placeholder={placeholder}
      displayText={state}
      showActions={isDirty}
      onCancelEdit={() => cancelEdit()}
      onConfirmEdit={() => {
        setEditMode(false);
        setIsDirty(false);
        onSave(state);
      }}
      onBlur={() => {
        if (saveOnFocusOut) {
          onSave(state);
          setIsDirty(false);
          setEditMode(false);
        } else if (!isDirty) {
          cancelEdit();
        }
      }}
      textArea={textArea}
    >
      {isMobile ? (
        <MuiPhoneNumber
          fullWidth
          disableAreaCodes
          variant="standard"
          defaultCountry="np"
          {...inputProps}
          value={state}
          autoFocus
          autoFormat
          style={{ width }}
          inputProps={{
            style: {
              paddingTop: 0
            }
          }}
          error={error}
          onClick={() => setIsDirty(true)}
          onChange={(phone, val) => {
            setIsDirty(true);
            const ph = new PhoneNumber(phone, val.countryCode);
            setState(phone);
            setError(!(ph.isValid() && ph.isMobile()));
          }}
        />
      ) : (
        <TextField
          disabled={disabled}
          {...inputProps}
          value={state}
          onChange={({ target }) => {
            const { value: val } = target;
            setIsDirty(true);
            setState(getValue(maxValue, val));
          }}
          type={type}
          autoFocus
          placeholder={placeholder}
          style={{ width }}
          multiline={textArea}
          rows={rows || 3}
          slotProps={{
            htmlInput: {
              maxLength,
              style: {
                paddingTop: 0
              }
            }
          }}
          helperText={maxLength && `${state ? state.length : 0}/${maxLength}`}
        />
      )}
    </ContentEditable>
  );
};

ContentEditableWithTextField.defaultProps = {
  saveOnFocusOut: false,
  textArea: false,
  maxLength: undefined,
  rows: 1,
  isMobile: false,
  hidelabel: false,
  disabled: false,
  maxValue: undefined,
  type: "string"
};

export default ContentEditableWithTextField;
