import React, { ReactElement, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { Grid2 as Grid } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import moment from "moment-timezone";
import { tl, t } from "../translate";
import { isISODateString } from "../../helpers/validators";
import hasOwnProperty from "../../helpers/object";
import { ReverseMap } from "../../helpers/types";

interface ToggleInputDateProps {
  data: { [key: string]: string };
  field: {
    id?: number;
    key: string;
    label?: string;
    inputType: string;
    editable: boolean;
    creatable: boolean;
    required: boolean;
    formatter: (dateString: string) => string;
    reverseFormatter: (dateString: string) => string;
    ADFormatter: (dateString: string) => string;
    showAgeField: boolean;
  };
  error: boolean;
  helperText: string | ReactElement;
  changeDate: (date: string) => void;
  isFocused?: () => void;
  isBlurred?: () => void;
  showAgeField?: boolean;
  label?: string;
  key?: string;
  disabled?: boolean;
  monthLabel?: string;
  yearLabel?: string;
  triggerInternalStateUpdateOnDateChange?: boolean;
}

enum DATE_FORMAT {
  AD = "AD",
  BS = "BS"
}

type DateFormatType = ReverseMap<typeof DATE_FORMAT>;

const getFullDate = ({
  type,
  totalMonths,
  formatter,
  reverseFormatter,
  ADFormatter
}: {
  type: DateFormatType;
  totalMonths: number;
  formatter: (dateString: string) => string;
  reverseFormatter: (dateString: string) => string;
  ADFormatter: (dateString: string) => string;
}) => {
  const adDate = ADFormatter(moment.utc().subtract(totalMonths, "month").format("YYYY-M-D"));
  if (type === DATE_FORMAT.BS) {
    return formatter(adDate);
  }
  return reverseFormatter(adDate).slice(0, 10);
};

const ToggleInputDate = ({
  data,
  field,
  error,
  helperText,
  changeDate,
  isFocused,
  isBlurred,
  showAgeField,
  label,
  key,
  monthLabel = `${t("CreateClient.Month")}`,
  yearLabel = `${t("CreateClient.Year")}`,
  disabled = false,
  triggerInternalStateUpdateOnDateChange = false
}: ToggleInputDateProps): JSX.Element => {
  const propDate = data[field.key];
  const [state, setState] = React.useState<{
    selected: DateFormatType;
    date: string;
    age: number | null;
    month: number | null;
  }>({
    selected: DATE_FORMAT.BS,
    date: field.formatter(data[field.key]),
    age: propDate ? moment().diff(moment(propDate), "year") : null,
    month: propDate ? moment().diff(moment(propDate), "month") % 12 : null
  });

  const ageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredAgeInYear = e.target.value;
    if (+enteredAgeInYear <= 200) {
      const { selected, month } = state;
      const totalMonths = (month || 0) + +enteredAgeInYear * 12;
      const fullDate = getFullDate({
        type: selected,
        totalMonths,
        formatter: field.formatter,
        ADFormatter: field.ADFormatter,
        reverseFormatter: field.reverseFormatter
      });
      setState({ selected, date: fullDate, age: Number(e.target.value), month });
    }
  };

  const monthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { selected, age } = state;
    const enteredMonths = Number(e.target.value);
    let newMonth = enteredMonths;
    let newAge = 0;
    if (enteredMonths > 11) {
      newMonth = enteredMonths % 12;
    }
    // calculate age using entered months and already entered age
    newAge = Math.floor(enteredMonths / 12) + (age || 0);
    // get total months to get dob by subtracting from today
    const totalMonths = enteredMonths + newAge * 12;
    const fullDate = getFullDate({
      type: selected,
      totalMonths,
      formatter: field.formatter,
      ADFormatter: field.ADFormatter,
      reverseFormatter: field.reverseFormatter
    });
    setState({ selected, date: fullDate, age: newAge, month: newMonth });
  };

  const dobChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { selected } = state;
    const isoDate =
      state.selected === DATE_FORMAT.BS
        ? field.reverseFormatter(e.target.value)
        : field.ADFormatter(e.target.value);
    const isDateValid = isISODateString(isoDate);
    setState({
      selected,
      date: e.target.value,
      age: isDateValid ? moment().diff(isoDate, "years") : null,
      month: isDateValid ? moment().diff(isoDate, "month") % 12 : null
    });
  };

  useEffect(() => {
    if (triggerInternalStateUpdateOnDateChange) {
      const isoDateString =
        state.selected === DATE_FORMAT.BS
          ? field.reverseFormatter(field.formatter(propDate))
          : propDate || "";

      let inputDateString = "";
      if (propDate) {
        if (state.selected === DATE_FORMAT.BS) {
          inputDateString = field.formatter(propDate);
        } else {
          inputDateString = moment(propDate).format("YYYY-MM-DD");
        }
      }

      setState({
        selected: state.selected,
        date: inputDateString,
        age: propDate ? moment().diff(moment(isoDateString), "year") : null,
        month: propDate ? moment().diff(moment(isoDateString), "month") % 12 : null
      });
    }
  }, [propDate]);

  return (
    <Grid
      container
      columnSpacing={1}
      margin="dense"
      onBlur={() => {
        changeDate(
          state.selected === DATE_FORMAT.BS
            ? field.reverseFormatter(state.date)
            : field.ADFormatter(state.date)
        );
      }}
    >
      {showAgeField && (
        <>
          <Grid size={{ xs: 2.5 }}>
            <TextField
              data-testmation="ageInput"
              variant="outlined"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              autoFocus={false}
              onFocus={() => isFocused && isFocused()}
              onBlur={() => isBlurred && isBlurred()}
              label={yearLabel}
              placeholder={t("CreateClient.AgeinYears")}
              error={error}
              required={hasOwnProperty(field, "required") ? field.required : true}
              value={state.age}
              onChange={ageChange}
              type="number"
            />
          </Grid>
          <Grid size={{ xs: 2.5 }}>
            <TextField
              data-testmation="monthInput"
              variant="outlined"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              autoFocus={false}
              onFocus={() => isFocused && isFocused()}
              onBlur={() => isBlurred && isBlurred()}
              label={monthLabel}
              placeholder={t("CreateClient.AgeinMonths")}
              error={error}
              required={hasOwnProperty(field, "required") ? field.required : true}
              value={state.month}
              onChange={monthChange}
              type="number"
            />
          </Grid>
        </>
      )}
      <Grid size={{ xs: showAgeField ? 7 : 12 }}>
        <TextField
          disabled={disabled}
          variant="outlined"
          autoFocus={false}
          sx={{ width: "100%", marginTop: `${!showAgeField ? "10px" : ""}` }}
          id={field.id ? field.id.toString() : ""}
          data-testmation={field.key}
          key={key || field.key}
          label={
            label
              ? tl(state.selected.toLowerCase() + label)
              : tl(state.selected.toLowerCase() + field.label)
          }
          placeholder="YYYY-MM-DD"
          value={state.date}
          error={error}
          helperText={helperText}
          type={field.inputType || ""}
          required={hasOwnProperty(field, "required") ? field.required : true}
          onFocus={() => isFocused && isFocused()}
          onBlur={() => isBlurred && isBlurred()}
          onChange={dobChange}
          slotProps={{
            inputLabel: { shrink: true },
            input: {
              endAdornment: (
                <ButtonGroup
                  color="primary"
                  aria-label="text primary button group"
                  variant="text"
                  size="small"
                  style={{ position: "absolute", right: "0" }}
                >
                  <Button
                    sx={{
                      color: state.selected !== DATE_FORMAT.AD ? "lightgray" : "",
                      paddingTop: "0",
                      paddingBottom: "0",
                      fontWeight: state.selected === DATE_FORMAT.AD ? "bold" : "normal"
                    }}
                    onClick={() => {
                      const date = data[field.key];
                      setState({
                        selected: DATE_FORMAT.AD,
                        date: date ? date.split("T")[0] : "",
                        age: state.age,
                        month: state.month
                      });
                    }}
                  >
                    AD
                  </Button>
                  <Button
                    sx={{
                      color: state.selected !== DATE_FORMAT.BS ? "lightgray" : "",
                      paddingTop: "0",
                      paddingBottom: "0",
                      fontWeight: state.selected === DATE_FORMAT.BS ? "bold" : "normal"
                    }}
                    onClick={() => {
                      const { date } = state;
                      setState({
                        selected: DATE_FORMAT.BS,
                        date: field.formatter(field.ADFormatter(date)),
                        age: state.age,
                        month: state.month
                      });
                    }}
                  >
                    BS
                  </Button>
                </ButtonGroup>
              )
            }
          }}
        />
      </Grid>
    </Grid>
  );
};

ToggleInputDate.defaultProps = {
  showAgeField: true,
  label: "",
  key: "",
  isFocused: null,
  isBlurred: null,
  disabled: false
};

export default ToggleInputDate;
