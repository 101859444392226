import moment from "moment";
import React, { JSX } from "react";
import Barcode from "react-barcode";
import { QRCodeSVG } from "qrcode.react";
import { capitalize, startCase } from "lodash";
import { Box, Typography } from "@mui/material";
import hasOwnProperty from "../../../../helpers/object";
import { LabRecord, LabTest } from "../../../../interfaces/Lab";
import { getTestReportBaseUrl } from "../../../../helpers/urls";
import { InfoField, scaleFont } from "../../../../components/Print/Print";
import { Vaccination } from "../../../../interfaces/AssessmentInterfaces";
import { getGender, rangeSwitchAccToGender } from "../../../Lab/LabPrint/LabPrintFunctions";

// sub row
export const SubRow = ({
  lt,
  children = <></>,
  isChild = false,
  showRanges = true,
  isLast = false,
  gender
}: {
  lt: LabTest;
  children?: React.ReactNode;
  isChild?: boolean;
  showRanges?: boolean;
  isLast?: boolean;
  gender: "1" | "2" | null;
}): JSX.Element => {
  const clientGender = getGender(lt, gender ?? "1");
  return (
    <>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between"
        }}
      >
        <Box
          sx={{
            padding: "0cm 0.1cm",
            border: "1px solid black",
            borderTop: isChild ? "1px solid grey" : "1px solid black",
            borderBottom: isLast ? "1px solid black" : "0px",
            borderRight: "0px",
            width: showRanges ? "50%" : "75%"
          }}
        >
          <Typography
            sx={{
              fontSize: scaleFont("0.33cm", 0.8),
              paddingLeft: isChild ? "0.2cm" : "1px"
            }}
          >
            {lt.name}
          </Typography>
        </Box>
        <Box
          sx={{
            padding: "0cm 0.1cm",
            border: "1px solid black",
            borderTop: isChild ? "1px solid grey" : "1px solid black",
            borderBottom: isLast ? "1px solid black" : "0px",
            width: "25%",
            borderRight: showRanges ? "0px" : "1px solid black"
          }}
        >
          <Typography
            sx={{
              fontSize: scaleFont("0.33cm", 0.8)
            }}
          >
            {hasOwnProperty(lt, "formData") && lt.formData.reading}
          </Typography>
        </Box>
        {showRanges && (
          <Box
            sx={{
              padding: "0cm 0.1cm",
              border: "1px solid black",
              borderTop: isChild ? "1px solid grey" : "1px solid black",
              borderBottom: isLast ? "1px solid black" : "0px",
              width: "25%",
              display: "flex",
              alignItems: "center"
            }}
          >
            <Typography
              sx={{
                fontSize: scaleFont("0.33cm", 0.8)
              }}
            >
              {!(lt.subTests?.length > 0) && hasOwnProperty(lt, "ranges") && (
                <>{rangeSwitchAccToGender(Number(clientGender), lt.ranges)}</>
              )}
            </Typography>
            <Typography
              sx={{
                fontSize: scaleFont("0.32cm", 0.8)
              }}
            >
              {lt.unit && lt.unit}
            </Typography>
          </Box>
        )}
      </Box>
      {children}
    </>
  );
};

// customized info field
export const CustomizedInfoField = ({
  label,
  data,
  smallWidth = false,
  valueStyle = {},
  labelStyle = {},
  containerStyle = {}
}: {
  label: string;
  data: string | number | null;
  smallWidth?: boolean;
  valueStyle?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}): JSX.Element => (
  <Box>
    {data && (
      <InfoField
        label={label}
        data={data}
        labelStyle={{
          ...labelStyle,
          width: smallWidth ? "88px" : "130px",
          fontSize: scaleFont("0.28cm", 1)
        }}
        valueStyle={{ fontSize: scaleFont("0.28cm", 1), ...valueStyle }}
        containerStyle={containerStyle}
      />
    )}
  </Box>
);

// client info div
export const ClientInfoDiv = ({
  labRecord,
  showReferrer,
  showBarCode
}: {
  labRecord: LabRecord;
  showReferrer: boolean;
  showBarCode;
}): JSX.Element => {
  const { client } = labRecord;
  const clientName = `${capitalize(client?.firstName)} ${capitalize(client?.lastName)}`;
  const getFormattedDateInAD = (date: string): string => `${moment(date).format("YYYY-MM-DD")}`;

  const gender = (() => {
    if (client?.gender === "1") {
      return "m";
    }
    if (client?.gender === "2") {
      return "f";
    }
    return "-";
  })();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid black",
        p: "0.2cm",
        minHeight: "2.3cm",
        width: "100%"
      }}
    >
      <Box sx={{ maxWidth: "6cm" }}>
        <CustomizedInfoField label="S.N." data={labRecord.labIdentifier || ""} smallWidth />
        <InfoField
          label="Name:"
          data={clientName}
          labelStyle={{ width: "2.3cm", fontSize: scaleFont("0.28cm", 1), paddingTop: "0.1cm" }}
          valueStyle={{
            fontSize: scaleFont("0.34cm", 1),
            fontWeight: "700",
            lineHeight: "1"
          }}
        />
        <CustomizedInfoField label="Marital status:" data={client.maritalStatus} smallWidth />
        <CustomizedInfoField label="Address:" data={client.address} smallWidth />
        {showBarCode && (
          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <CustomizedInfoField label="Test Id:" data={labRecord.id} smallWidth />
            <Barcode
              value={String(labRecord.id)}
              width={2}
              displayValue={false}
              fontSize={5}
              height={20}
              margin={2}
              font="arial"
            />
          </Box>
        )}
      </Box>
      <Box>
        <CustomizedInfoField
          label="Age:"
          data={client.dob ? moment().diff(client.dob, "years") : ""}
          smallWidth
        />
        <CustomizedInfoField
          label="Passport no:"
          data={client?.passportInfo && client?.passportInfo?.passportNumber}
          smallWidth
        />
        <CustomizedInfoField label="Nationality:" data={client.nationality} smallWidth />
        <CustomizedInfoField
          label="Applied to:"
          data={client.appliedCountry}
          smallWidth
          valueStyle={{ fontSize: scaleFont("0.34cm", 1), fontWeight: "700" }}
        />
        <CustomizedInfoField
          label="Exam date:"
          data={getFormattedDateInAD(labRecord?.results?.examDate || new Date().toISOString())}
          smallWidth
        />
      </Box>
      <Box>
        <CustomizedInfoField label="Sex:" data={gender} />
        <CustomizedInfoField label="Occupation:" data={client.occupation} />
        <CustomizedInfoField
          label="Passport issue place:"
          data={client.passportInfo?.issuingAuthority || ""}
        />
        <CustomizedInfoField
          label="Passport issue date:"
          data={moment(client.passportInfo?.issueDate).format("YYYY-MM-DD") || ""}
        />
        <CustomizedInfoField
          label="Passport expiration date:"
          data={moment(client.passportInfo?.expiryDate).format("YYYY-MM-DD") || ""}
        />
        {showReferrer && (
          <CustomizedInfoField label="Recruiting agency:" data={labRecord.referrers || ""} />
        )}
      </Box>
    </Box>
  );
};

// client image
export const ClientProfileImage = ({
  profileImage
}: {
  profileImage: string | null;
}): JSX.Element => (
  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    {profileImage ? (
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end"
        }}
      >
        <img
          src={profileImage}
          style={{
            height: "3cm",
            width: "2.5cm",
            objectFit: "cover"
          }}
          alt="profileImage"
        />
      </Box>
    ) : (
      <Box
        sx={{
          height: "3cm",
          width: "2.5cm",
          border: "1px solid lightgrey",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Typography sx={{ fontSize: "0.2cm", textAlign: "center" }}>
          Photo
          <br />
          2.5*3cm
        </Typography>
      </Box>
    )}
  </Box>
);

// present health status
export const PresentHealthStatus = ({
  recentAssessment
}: {
  recentAssessment: any;
}): JSX.Element => (
  <Typography
    sx={{
      textAlign: "center",
      fontWeight: 900,
      fontSize: "0.40cm"
    }}
  >
    {recentAssessment?.presentHealthStatus && (
      <Box component="span" sx={{ p: "0 0.1cm", border: "0.5px solid black" }}>
        {recentAssessment?.presentHealthStatus.toUpperCase()}
      </Box>
    )}
  </Typography>
);

// client illness div
export const ClientIllnessesDiv = ({
  recentAssessment,
  hidePresentHealthStatus = false
}: {
  recentAssessment: any;
  hidePresentHealthStatus?: boolean;
}): JSX.Element => (
  <>
    {recentAssessment && (
      <Box sx={{ width: "100%", mt: "0.42cm" }}>
        {!hidePresentHealthStatus && (
          <Box sx={{ marginLeft: "2.5cm", marginTop: "-0.2cm" }}>
            <PresentHealthStatus recentAssessment={recentAssessment} />
          </Box>
        )}

        <Typography
          sx={{
            fontSize: scaleFont("0.28cm"),
            fontWeight: 600,
            marginTop: `${recentAssessment?.presentHealthStatus ? "-0.4cm" : "10px"}`
          }}
        >
          General Examination
        </Typography>

        <Box>
          <Typography sx={{ fontSize: "0.28cm", fontWeight: 600, width: "115%" }}>
            Past history of serious illness, major surgery and significant psychological problem
            including (Epilepsy and Depression):{" "}
            <span style={{ fontWeight: 400 }}>
              {recentAssessment?.hopi && recentAssessment.hopi}
            </span>
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ fontSize: "0.28cm", fontWeight: 600 }}>
            Past history of allergy :{" "}
            <span style={{ fontWeight: 400 }}>
              {recentAssessment?.pastHistoryOfAllergy && recentAssessment.pastHistoryOfAllergy}
            </span>
          </Typography>
        </Box>
      </Box>
    )}
  </>
);

// client vitals div
export const ClientVitalsDiv = ({
  labRecord,
  recentAssessment
}: {
  labRecord: LabRecord;
  recentAssessment: any;
}): JSX.Element => {
  const vitalsObj: any = {};

  (labRecord?.results?.vitals || recentAssessment?.vitals)?.forEach((vital) => {
    vitalsObj[(vital.name || "").replace(/\s/g, "").toLowerCase()] = vital;
  });

  const {
    height,
    bloodpressure,
    clubbing,
    weight,
    jaundice,
    oedema,
    pulse,
    pallor,
    ascites,
    temperature,
    cyanosis,
    lymphnode
  } = vitalsObj;

  const showVital = (vital) => {
    if (vital) return `${vital.reading} ${vital.unit && vital.unit}`;
    return "-";
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        border: "1px solid black",
        p: "0.1cm"
      }}
    >
      <Box>
        <CustomizedInfoField label="Height" data={showVital(height)} smallWidth />
        <CustomizedInfoField label="BP" data={showVital(bloodpressure)} smallWidth />
        <CustomizedInfoField label="Clubbing" data={showVital(clubbing)} smallWidth />
      </Box>
      <Box>
        <CustomizedInfoField label="Weight" data={showVital(weight)} smallWidth />
        <CustomizedInfoField label="Jaundice" data={showVital(jaundice)} smallWidth />
        <CustomizedInfoField label="Oedema" data={showVital(oedema)} smallWidth />
      </Box>
      <Box>
        <CustomizedInfoField label="Pulse" data={showVital(pulse)} smallWidth />
        <CustomizedInfoField label="Pallor" data={showVital(pallor)} smallWidth />
        <CustomizedInfoField label="Ascities" data={showVital(ascites)} smallWidth />
      </Box>
      <Box>
        <CustomizedInfoField label="Temperature" data={showVital(temperature)} smallWidth />
        <CustomizedInfoField label="Cyanosis" data={showVital(cyanosis)} smallWidth />
        <CustomizedInfoField label="Lymphnode" data={showVital(lymphnode)} smallWidth />
      </Box>
    </Box>
  );
};

// vaccination sub row
export const VaccinationSubRow = ({
  vaccination,
  isLast
}: {
  vaccination: Vaccination;
  isLast: boolean;
}): JSX.Element => (
  <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
    <Box
      sx={{
        padding: "0cm 0.1cm",
        border: "1px solid black",
        borderTop: "1px solid black",
        borderBottom: isLast ? "1px solid black" : "0px",
        borderRight: "0px",
        width: "50%"
      }}
    >
      <Typography sx={{ fontSize: scaleFont("0.33cm", 0.8) }}>{vaccination.name}</Typography>
    </Box>
    <Box
      sx={{
        padding: "0cm 0.1cm",
        border: "1px solid black",
        borderTop: "1px solid black",
        borderBottom: isLast ? "1px solid black" : "0px",
        borderRight: "0px",
        width: "25%"
      }}
    >
      <Typography sx={{ fontSize: scaleFont("0.33cm", 0.8) }}>
        {vaccination.yesno ? "Yes" : "No"}
      </Typography>
    </Box>
    <Box
      padding="0cm 0.1cm"
      border="1px solid black"
      borderTop="1px solid black"
      borderBottom={isLast ? "1px solid black" : "0px"}
      width="25%"
      borderRight="1px solid black"
    >
      <Typography sx={{ fontSize: scaleFont("0.33cm", 0.8) }}>
        {moment(vaccination.date).format("YYYY-MM-DD")}
      </Typography>
    </Box>
  </Box>
);

// Authorized sp info
export const AuthorizedSpInfo = ({ sp }: { sp: any }): JSX.Element => (
  <Box sx={{ marginTop: "0.2cm" }}>
    <Box sx={{ height: "1.1cm", marginBottom: "0.2cm" }}>
      {sp?.signature && (
        <img
          style={{ display: "block" }}
          src={sp.signature}
          height="40px"
          width="80px"
          alt="sp-signature"
        />
      )}
    </Box>
    <div>
      <Typography
        sx={{
          fontSize: "0.28cm",
          textAlign: "left",
          fontWeight: 600
        }}
      >
        {startCase(sp.title)} {startCase(sp.name)}
      </Typography>
      {sp.designation && (
        <Typography sx={{ whiteSpace: "pre-wrap", fontSize: "0.25cm" }}>
          {sp.designation}
        </Typography>
      )}
    </div>
  </Box>
);

export const RenderNamedBlockTestGroup = ({
  blockName,
  testGroupData,
  gender,
  namePlacement = "top"
}: {
  blockName: string;
  testGroupData: any;
  gender: "1" | "2" | null;
  namePlacement?: "top" | "left";
}): JSX.Element => (
  <Box
    sx={{
      width: "100%",
      marginTop: "0.10cm",
      display: "flex",
      flexDirection: namePlacement === "top" ? "column" : "row"
    }}
  >
    <Box
      sx={{
        ...(namePlacement === "left"
          ? {
              width: "30px",
              writingMode: "vertical-rl",
              transform: "rotate(180deg)",
              border: "1px solid black",
              borderLeft: 0,
              display: "inline-flex",
              alignItems: "center",
              justifyContent: "center"
            }
          : {
              border: "none"
            }),
        textTransform: "uppercase",
        fontWeight: 600,
        fontSize: "0.28cm"
      }}
    >
      {blockName}
    </Box>
    <Box
      sx={{
        height: "100%",
        width: "100%",
        flex: 1
      }}
    >
      {testGroupData.map((lt, index, array) => {
        const isLastGroup = index === array.length - 1;
        const isLast = isLastGroup && lt.subTests?.length === 0;
        return (
          <SubRow key={lt.id} lt={lt} isLast={isLast} gender={gender}>
            {lt.subTests?.map((sT, i, arr) => (
              <SubRow
                key={sT.id}
                lt={sT}
                isChild
                isLast={isLastGroup && i === arr.length - 1}
                gender={gender}
              />
            ))}
          </SubRow>
        );
      })}
    </Box>
  </Box>
);

// main lab data display div
export const MainLabDataDisplayDiv = ({
  labRecord,
  recentAssessment,
  showQrCode,
  showMedicalDisclaimerOnFooter
}: {
  labRecord: LabRecord;
  recentAssessment: any;
  showQrCode: boolean;
  showMedicalDisclaimerOnFooter?: boolean;
}): JSX.Element => {
  const { client } = labRecord;

  const getClientGender = (clientGender) => {
    if (clientGender === "1") {
      return "m";
    }
    if (clientGender === "2") {
      return "f";
    }
    return "-";
  };

  const gender = getClientGender(client.gender);

  // eslint-disable-next-line no-nested-ternary
  const greeting = gender === "m" ? "Sir" : gender === "f" ? "Madam" : "Sir/Madam";

  const clientName = `${capitalize(client.firstName)} ${capitalize(client.lastName)}`;
  const labTests = (labRecord.results?.data.length && labRecord.results.data[0]?.labTests) || [];
  const getRequiredCategoryTests = (cName) => labTests.filter((item) => item.category === cName);

  const [
    systemeticGroup,
    hematologyGroup,
    bioChemGroup,
    urineGroup,
    serologyGroup,
    radiologyGroup,
    specialGroup,
    others
  ] = [
    "Systemic",
    "Haematology",
    "Biochemistry",
    "Urine Examination",
    "Serology",
    "Radiology",
    "Special",
    "Others"
  ].map((item) => getRequiredCategoryTests(item));
  const clinicalImpression = specialGroup.filter((item) => item.name === "Clinical impression");
  radiologyGroup.push(clinicalImpression[0]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        marginTop: "0.07cm"
      }}
    >
      <Box sx={{ width: "50%" }}>
        <Typography sx={{ fontWeight: 600, fontSize: scaleFont("0.28cm") }}>
          Systemic Examination
        </Typography>
        <Box display="flex">
          <Typography sx={{ width: "75%", fontWeight: 700, fontSize: "0.28cm" }}>
            Examination
          </Typography>
          <Typography sx={{ width: "25%", fontWeight: 700, fontSize: "0.28cm" }}>
            Reading
          </Typography>
        </Box>
        <Box
          sx={{
            marginTop: "0.04cm",
            marginRight: "4px"
          }}
        >
          {systemeticGroup.map((lt, index, array) => {
            const isLastGroup = index === array.length - 1;
            const isLast = isLastGroup && lt.subTests?.length === 0;
            return (
              <SubRow key={lt.id} lt={lt} showRanges={false} isLast={isLast} gender={client.gender}>
                {lt.subTests?.map((sT, i, arr) => (
                  <Box key={sT.id}>
                    <SubRow
                      lt={sT}
                      isChild
                      showRanges={false}
                      isLast={isLastGroup && i === arr.length - 1}
                      gender={client.gender}
                    />
                  </Box>
                ))}
              </SubRow>
            );
          })}
        </Box>

        <Typography sx={{ fontWeight: 600, fontSize: "0.28cm", marginTop: "0.2cm" }}>
          RADIOLOGY
        </Typography>
        <Box mt="0.06cm" mr="4px">
          {radiologyGroup.map((lt, index, array) => (
            <SubRow
              key={lt.id}
              lt={lt}
              showRanges={false}
              isLast={index === array.length - 1}
              gender={client.gender}
            >
              {lt.subTests?.map((sT, i, arr) => (
                <SubRow
                  key={sT.id}
                  lt={sT}
                  isChild
                  showRanges={false}
                  isLast={i === arr.length - 1}
                  gender={client.gender}
                />
              ))}
            </SubRow>
          ))}
        </Box>

        {labRecord?.results?.vaccinations?.length > 0 && (
          <>
            <Typography sx={{ fontWeight: 600, fontSize: "0.28cm", marginTop: "0.2cm" }}>
              VACCINATION STATUS
            </Typography>

            <Box
              sx={{
                mt: "0.06cm",
                mr: "4px"
              }}
            >
              {labRecord?.results?.vaccinations?.map((vaccination, index, array) => (
                <VaccinationSubRow
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  vaccination={vaccination}
                  isLast={index === array.length - 1}
                />
              ))}
            </Box>
          </>
        )}

        <Box sx={{ marginTop: "0.25cm" }}>
          <Typography sx={{ fontSize: "0.3cm" }}>Dear {greeting},</Typography>
          <Typography sx={{ fontSize: "0.3cm", width: "92%" }}>
            The above mentioned medical report of {clientName} is{" "}
            <b>{recentAssessment.presentHealthStatus}</b> for the mentioned job.
          </Typography>
        </Box>

        <Box sx={{ marginTop: "0.25cm", display: "flex", gap: "0.25cm" }}>
          <div>
            {showQrCode && (
              <QRCodeSVG size={70} value={`${getTestReportBaseUrl()}/${labRecord.uuid}`} />
            )}
          </div>

          {!showMedicalDisclaimerOnFooter && (
            <Typography
              sx={{
                fontSize: "0.25cm",
                border: "1px solid #f0f0f0",
                padding: "0.15cm",
                marginRight: "0.25cm"
              }}
            >
              Recommended by Ministry of Health and Population, Government of Nepal. This report is
              valid for two months from the date of Medical Examination.
            </Typography>
          )}
        </Box>
      </Box>
      <Box width="50%">
        <Typography sx={{ fontWeight: 600, fontSize: scaleFont("0.28cm") }}>
          Laboratory examination
        </Typography>
        <Box width="100%" display="flex">
          <Box width="100%" display="flex">
            <Typography sx={{ width: "50%", fontWeight: 700, fontSize: "0.28cm" }}>
              Examination
            </Typography>
            <Typography sx={{ width: "25%", fontWeight: 700, fontSize: "0.28cm" }}>
              Reading
            </Typography>
            <Typography sx={{ width: "25%", fontWeight: 700, fontSize: "0.28cm" }}>
              Range
            </Typography>
          </Box>
        </Box>
        <RenderNamedBlockTestGroup
          blockName="Hematology"
          testGroupData={hematologyGroup}
          gender={client.gender}
        />
        <RenderNamedBlockTestGroup
          blockName="Biochemistry"
          testGroupData={bioChemGroup}
          gender={client.gender}
        />
        <RenderNamedBlockTestGroup
          blockName="Serology"
          testGroupData={serologyGroup}
          gender={client.gender}
        />
        <RenderNamedBlockTestGroup
          blockName="Urine"
          testGroupData={urineGroup}
          gender={client.gender}
        />
        <RenderNamedBlockTestGroup
          blockName="Others"
          testGroupData={others}
          gender={client.gender}
        />
      </Box>
    </Box>
  );
};
