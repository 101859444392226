import { useQuery, UseQueryResult } from "react-query";
import { getPrismicDocuments, getPrismicRef, prismicKnowledgeBaseRootUrl } from "./prismicHelper";
import { KnowledgeBaseDocument } from "../interfaces/KnowledgeBase";
import { queryKeys } from "../react-query/constants";
import { getLatestReleaseNotes, ReleaseNote } from "./prismicQuery";
import { Post } from "./apiHelper";

export const enum KnowledgeBaseDocumentTag {
  dashboard = "dashboard",
  calendar = "calendar",
  billing = "billing",
  opd = "opd",
  lab = "lab",
  services = "services",
  clients = "clients",
  stock = "stock",
  messaging = "messaging",
  medical = "medical",
  reports = "reports",
  ipd = "ipd",
  hmis = "hmis",
  faq = "faq",
  daybook = "daybook"
}

export const enum KnowledgeBaseExtraTag {
  definition = "definition",
  faq = "faq"
}

export type KnowledgeBaseDocumentTags = Array<keyof typeof KnowledgeBaseDocumentTag>;

async function getKnowledgeBase(
  searchText: string = "",
  tags: KnowledgeBaseDocumentTags = []
): Promise<Array<KnowledgeBaseDocument>> {
  const ref = await getPrismicRef(prismicKnowledgeBaseRootUrl);
  const searchTextParams = searchText ? `[fulltext(document,${JSON.stringify(searchText)})]` : "";
  const tagParams = tags.length > 0 ? `[any(document.tags, ${JSON.stringify(tags)})]` : "";
  return getPrismicDocuments(
    prismicKnowledgeBaseRootUrl,
    `/documents/search?q=[[at(document.type,"articles")]${searchTextParams}${tagParams}]`,
    ref
  );
}

export function useKnowledgeBaseBySearchText(
  searchText: string = ""
): UseQueryResult<KnowledgeBaseDocument[]> {
  const queryResponse = useQuery(
    [queryKeys.knowledgeBase, "knowledgeBaseByTextSearch", { searchText }],
    () => getKnowledgeBase(searchText),
    {
      enabled: searchText.length > 2
    }
  );
  return queryResponse;
}

export function useKnowledgeBaseByTags(
  tags: KnowledgeBaseDocumentTags = []
): UseQueryResult<KnowledgeBaseDocument[]> {
  const queryResponse = useQuery(
    [queryKeys.knowledgeBase, "knowledgeBaseByTags", { tags }],
    () => getKnowledgeBase(undefined, tags),
    {
      enabled: tags.length > 0
    }
  );
  return queryResponse;
}

export const usePreviousReleaseNotes = (): UseQueryResult<ReleaseNote[], Error> =>
  useQuery<ReleaseNote[], Error>("previousReleaseNotes", getLatestReleaseNotes);

export const postKnowledgeBaseFeedback = async (
  feedback: Record<string, string>
): Promise<void> => {
  await Post("/api/knowledgebase/feedback", feedback);
};
