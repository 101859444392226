import { Dispatch, SetStateAction, useCallback, useState, useRef, useLayoutEffect } from "react";

type parserOptions<T> =
  | {
      raw: true;
    }
  | {
      raw: false;
      serializer: (value: T) => string;
      deserializer: (value: string) => T;
    };

const useLocalStorage = <T>({
  key,
  initialValue,
  options
}: {
  key: string;
  initialValue?: T;
  options?: parserOptions<T>;
}): [T | undefined, Dispatch<SetStateAction<T | undefined>>, () => void] => {
  if (!(typeof window !== "undefined")) {
    return [initialValue as T, () => undefined, () => undefined];
  }
  if (!key) {
    throw new Error("useLocalStorage key may not be falsy");
  }

  // eslint-disable-next-line no-nested-ternary
  const deserializer = options
    ? options.raw
      ? (value: string) => value
      : options.deserializer
    : JSON.parse;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const initializer = useRef((itemKey: string) => {
    try {
      // eslint-disable-next-line no-nested-ternary
      const serializer = options ? (options.raw ? String : options.serializer) : JSON.stringify;

      const localStorageValue = localStorage.getItem(itemKey);
      if (localStorageValue !== null) {
        return deserializer(localStorageValue);
      }
      if (initialValue) {
        localStorage.setItem(itemKey, serializer(initialValue));
      }
      return initialValue;
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw. JSON.parse and JSON.stringify
      // can throw, too.
      return initialValue;
    }
  });

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [storedValue, setStoredValue] = useState<T | undefined>(() => initializer.current(key));

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useLayoutEffect(() => setStoredValue(initializer.current(key)), [key]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const setItem: Dispatch<SetStateAction<T | undefined>> = useCallback(
    (valOrFunc) => {
      try {
        const newState =
          typeof valOrFunc === "function"
            ? (valOrFunc as (prev: typeof storedValue) => typeof storedValue)(storedValue)
            : valOrFunc;
        if (typeof newState === "undefined") return;
        let value: string;

        if (options)
          if (options.raw)
            if (typeof newState === "string") value = newState;
            else value = JSON.stringify(newState);
          else if (options.serializer) value = options.serializer(newState);
          else value = JSON.stringify(newState);
        else value = JSON.stringify(newState);

        localStorage.setItem(key, value);
        setStoredValue(deserializer(value));
      } catch {
        // If user is in private mode or has storage restriction
        // localStorage can throw. Also, JSON.stringify can throw.
      }
    },
    [key, setStoredValue]
  );

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const removeItem = useCallback(() => {
    try {
      localStorage.removeItem(key);
      setStoredValue(undefined);
    } catch {
      // If user is in private mode or has storage restriction
      // localStorage can throw.
    }
  }, [key, setStoredValue]);

  return [storedValue, setItem, removeItem];
};

export default useLocalStorage;
