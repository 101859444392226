import React, { useEffect } from "react";

const KEY = "showMedicalDisclaimerOnFooter";

export default function useShowMedicalDisclaimerOnFooter(): {
  showMedicalDisclaimerOnFooter: boolean;
  toggleShowMedicalDisclaimerOnFooter: () => void;
} {
  const [showMedicalDisclaimerOnFooter, setShowMedicalDisclaimerOnFooter] = React.useState(false);

  useEffect(() => {
    const value = localStorage.getItem(KEY);
    if (value) {
      setShowMedicalDisclaimerOnFooter(value === "true");
    }
  }, []);

  const toggleShowMedicalDisclaimerOnFooter = () => {
    setShowMedicalDisclaimerOnFooter((prev) => {
      const newValue = !prev;
      localStorage.setItem(KEY, newValue.toString());
      return newValue;
    });
  };

  return { showMedicalDisclaimerOnFooter, toggleShowMedicalDisclaimerOnFooter };
}
