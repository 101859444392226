import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import cloneDeep from "lodash/cloneDeep";
import Form from "../../../components/Form";
import Panel from "../../../components/Panel";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { tl } from "../../../components/translate";
import { TemplateData } from "./TemplateHelpers";
import { fields } from "../../../models/Template";
import * as templateActions from "../../../actions/template";
import "./Template.scss";
import { keywords, SmsTemplateBox } from "./SmsTemplateBox";
import {
  deserializeNode,
  proseMirrorOptions,
  RichTextfield,
  serializeContent
} from "../../../components/RichTextfield";
import useProseMirror from "../../../components/RichTextfield/useProsemirror";
import { IThunkDispatch, RootState } from "../../../store";

interface TemplateCreateEditProps {
  mode: string;
  template: TemplateData;
  onCancel: () => any;
  actions: any;
}

const TemplateCreateEdit: React.FC<TemplateCreateEditProps> = ({
  onCancel,
  mode,
  template,
  actions
}) => {
  const [saving, setSaving] = React.useState(false);
  const [formData, setFormData] = React.useState<TemplateData>(
    template || {
      name: "",
      mediaType: "sms",
      message: ""
    }
  );
  const [state, setState] = useProseMirror({
    ...proseMirrorOptions,
    doc: serializeContent(formData.message || "")
  });
  return (
    <Panel
      onClose={onCancel}
      title={mode === "edit" ? tl("campaign.updateTemplate") : tl("campaign.createTemplate")}
      footer={
        <Box display="flex" justifyContent="space-between" width="100%" marginRight="32px">
          <Box />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button
              data-testmation="campaignCancel"
              onClick={onCancel}
              style={{ marginRight: "32px" }}
            >
              <Typography>{tl("client.cancel")}</Typography>
            </Button>
            <StatefulButton
              form="templateForm"
              data-testmation="campaignSave"
              variant="contained"
              type="submit"
              color="primary"
              disabled={saving}
              onClick={async () => {
                setSaving(true);
                if (formData.mediaType === "email") {
                  formData.message = deserializeNode(state.doc);
                }
                await actions.onSave(formData).then(() => {
                  setSaving(false);
                  actions.navigateTo("/campaign/template");
                });
              }}
              isLoading={saving}
              circularProgressProps={{ size: 16 }}
            >
              <Typography>{tl("client.save")}</Typography>
            </StatefulButton>
          </Box>
        </Box>
      }
    >
      <Box pl={5} pr={5} className="templateForm">
        <Form
          name="templateForm"
          fields={fields}
          data={{ mediaType: formData.mediaType, name: formData.name }}
          onChange={(data) => {
            const newFormData: TemplateData = cloneDeep(formData);
            newFormData.mediaType = data.mediaType;
            newFormData.name = data.name;
            setFormData(newFormData);
          }}
          showErrors
        />
        {formData.mediaType === "sms" && (
          <SmsTemplateBox
            showCustomKeywords
            content={formData.message}
            onChange={(content) => {
              const newFormData: TemplateData = cloneDeep(formData);
              newFormData.message = content;
              setFormData(newFormData);
            }}
          />
        )}
        {formData.mediaType === "email" && (
          <RichTextfield customKeyWords={keywords} state={state} setState={setState} />
        )}
      </Box>
    </Panel>
  );
};

export default connect(
  (state: RootState, { template, templateId }) => ({
    template: template || state.template.collection.find(({ id }) => Number(templateId) === id)
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      onSave: async (templateData) => {
        await dispatch(templateActions.saveTemplate(templateData));
      },
      navigateTo: (url) => dispatch(push(url))
    }
  })
)(TemplateCreateEdit);
