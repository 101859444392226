import { Get, Post, Delete, Put, Patch } from "./apiHelper";
import { ServiceInterface } from "../interfaces/ServiceInterface";
import { QueryProps } from "../interfaces/ProductInterface";

const servicesRoot = "/api/services";
const publicServiceRoot = "/api/publicServices";

export async function postBatchService(services: ServiceInterface): Promise<ServiceInterface> {
  const response = await Post(`${servicesRoot}`, services, true);
  return response.data as ServiceInterface;
}

export async function putBatchService(services: ServiceInterface): Promise<ServiceInterface> {
  const response = await Put(`${servicesRoot}/${services.id}`, services, true);
  return response.data as ServiceInterface;
}

export async function deleteBatchService(services: number): Promise<string> {
  const response = await Delete(`${servicesRoot}/${services}`, true);
  return response.data as string;
}

export async function batchDeleteServices(ids: number[]): Promise<string> {
  const response = await Delete(`${servicesRoot}/batchDelete`, true, { ids });
  return response.data as string;
}

export async function getBatchService(): Promise<ServiceInterface[]> {
  const response = await Get(`${servicesRoot}`, true);
  return response.data as ServiceInterface[];
}
export async function getService(id: number): Promise<ServiceInterface> {
  const response = await Get(`${servicesRoot}/${id}`, true);
  return response.data as ServiceInterface;
}

export async function servicesSearch(data: string): Promise<ServiceInterface[]> {
  const response = await Get(`${servicesRoot}/search?q=${data}`, true);
  return response.data as ServiceInterface[];
}

export const getPublicServices = async (): Promise<ServiceInterface[]> => {
  const response = await Get(`${publicServiceRoot}`, true);
  return response.data as ServiceInterface[];
};

export const publicServiceDelete = async (id: number): Promise<string> => {
  const response = await Delete(`${publicServiceRoot}/${id}`, true);
  return response.data as string;
};

export const batchUploadService = async (data: Array<ServiceInterface>): Promise<string> => {
  const response = await Post(`${servicesRoot}/batch`, { services: data }, true);
  return response.data as string;
};

export async function updateServiceUnderPackage({
  data,
  packageId
}: {
  data: ServiceInterface;
  packageId: string;
}): Promise<ServiceInterface> {
  const response = await Put(`${servicesRoot}/serviceUnderPackage/${packageId}`, data, true);
  return response.data as ServiceInterface;
}

export async function getServicesForMassLinking({
  page = 0,
  pageSize = 50,
  search = ""
}: Partial<QueryProps>): Promise<{ results: ServiceInterface[]; total: number }> {
  const query = new URLSearchParams({
    page: page.toString(),
    pageSize: pageSize.toString(),
    search
  });
  const response = await Get(`${servicesRoot}/massLink?${query}`, true);
  return response.data as { results: ServiceInterface[]; total: number };
}

export async function linkServicesAndLabTests(
  servicesAndLabTests: Array<{ serviceId: number; labTestId: number | null }>
): Promise<Record<string, any>> {
  const response = await Patch(`${servicesRoot}/linkLabTest`, { servicesAndLabTests }, true);
  return response.data as Record<string, any>;
}
