import React from "react";
import { Box, Typography } from "@mui/material";
import Barcode from "react-barcode";
import LabPrintLetterHead, { ResourceCentre } from "../../LabprintComponents/LabPrintLetterhead";
import LabPrintFooter from "../../LabprintComponents/LabPrintFooter";
import LabPrintLabInfo from "../../LabprintComponents/LabPrintLabInfo";
import AuthorizedSPInfo from "../FixedFooter/AuthorizedSPInfo";
import LabPrintCoreData from "../FixedFooter/FixedFooterTemplateCore";
import {
  getSectionStyle,
  getSettingBasedDate,
  groupLabTestByCategory,
  showDynamicSignature
} from "../../LabPrintFunctions";
import { LabObject, LabPrintSettings } from "../../../../../interfaces/LabInterfaces";
import { AuthorizedSp } from "../../LabprintComponents/AuthorizedSPInfo";
import LabPrintLetterHeadCentered from "../../LabprintComponents/LabPrintLetterheadCentered";
import { clientFullNameSelector } from "../../../../../reducers/client";
import { scaleFont } from "../../../../../components/Print/Print";
import { CustomEl } from "../../../LabEntry/LabTestSubRow";
import { showDOBAndGender } from "../../../../Billing/PrintBill/EightyMmPrint/ClientInfo";
import { getCustomerNumber } from "../../../../Client/ClientList";
import CustomHeader from "../../CustomHeader";
import { t } from "../../../../../components/translate";
import { spFullNameSelector } from "../../../../../reducers/serviceProvider";

interface ExtraProps {
  unfixFooter?: boolean;
}

interface A5TemplateProps {
  resourceCentre: ResourceCentre;
  labData: LabObject;
  settings: LabPrintSettings;
  otherProps?: ExtraProps;
  showAdditionalLabData: boolean;
  showDobOnLabPrint?: boolean;
}

function LabelledField({ label, value }: { label: string; value: string | number }) {
  return (
    <Box display="flex" alignItems="flex-start">
      <Typography style={{ width: "2.9cm", fontSize: scaleFont("0.28cm", 1.2) }}>
        {label}
      </Typography>
      <Typography
        style={{
          flex: 1,
          fontSize: scaleFont("0.28cm", 1.2)
        }}
      >
        {value}
      </Typography>
    </Box>
  );
}

export const getLabHeader = (
  centralizedHeader: boolean,
  resourceCentre: ResourceCentre,
  clinicNameFontScale: number
): JSX.Element =>
  centralizedHeader ? (
    <LabPrintLetterHeadCentered
      resourceCentre={resourceCentre}
      titleFontScale={clinicNameFontScale}
    />
  ) : (
    <LabPrintLetterHead resourceCentre={resourceCentre} titleFontScale={clinicNameFontScale} />
  );

const A5Template: React.FC<A5TemplateProps> = ({
  settings,
  resourceCentre,
  labData,
  otherProps,
  showAdditionalLabData,
  showDobOnLabPrint
}) => {
  const authorizedLabSp1: AuthorizedSp = labData.results?.approvedBy?.authorizedSP1 || null;
  const authorizedLabSp2: AuthorizedSp = labData.results?.approvedBy?.authorizedSP2 || null;
  const authorizedLabSp3: AuthorizedSp = labData.results?.approvedBy?.authorizedSP3 || null;
  const authorizedLabSp4: AuthorizedSp = labData.results?.approvedBy?.authorizedSP4 || null;

  const {
    showLetterhead,
    showFooter,
    headerColor,
    dateSetting,
    showPrintedOnTime,
    showReportOnTime,
    showSampleTakenTime,
    showSampleTakenDate,
    showPrintedOnDate,
    showReportOnDate,
    centralizedHeader,
    clinicNameFontScale,
    showLabRemarks,
    labRemarks,
    showMethods,
    showRanges,
    showUnit,
    showReading,
    hideFooterLogoCompletely,
    showRangeFlag,
    useCustomLabTestNumber,
    showSampleCollectedBy,
    customHeaderImage
  } = settings;

  const { client } = labData;

  const letterHeadClientInfo = (
    <Box
      display="flex"
      mt="0.1cm"
      border="1px solid #bbb"
      p="0.03cm 0.3cm"
      justifyContent="space-between"
    >
      <Box width="50%">
        <Typography
          style={{
            fontSize: scaleFont("0.3cm", 1.3)
          }}
        >
          {clientFullNameSelector(client)}
          <span style={{ marginLeft: "5px" }}>
            {showDOBAndGender(client.gender, client.dob, true)}
          </span>
        </Typography>
        {labData.customer?.customerNumber && (
          <LabelledField
            label="Patient ID"
            value={getCustomerNumber(labData.customer?.customerNumber)}
          />
        )}
        {labData.ipd?.ipdNumber && <LabelledField label="Ipd No." value={labData.ipd.ipdNumber} />}
        {labData.results?.bed && (
          <LabelledField label="Bed No." value={labData.results?.bed?.name} />
        )}
        {showDobOnLabPrint && client && client.dob && (
          <LabelledField label="Date of Birth" value={getSettingBasedDate("BS", client.dob)} />
        )}
        {client.phone && <LabelledField label="Phone" value={client.phone} />}
        {client.address && <LabelledField label="Address" value={client.address} />}
        {labData.client.passportInfo?.passportNumber && (
          <LabelledField label="Passport no" value={labData.client.passportInfo.passportNumber} />
        )}
        <LabelledField
          label="Ordered By"
          value={
            labData?.serviceProvider
              ? spFullNameSelector(labData.serviceProvider)
              : t("labTest.orderBy.self")
          }
        />
        {labData.referrers && (
          <LabelledField label="Referred By" value={labData.referrers.toUpperCase()} />
        )}
        {labData.extraReferrer?.name && (
          <LabelledField label="Extra Referrer" value={labData.extraReferrer.name.toUpperCase()} />
        )}
      </Box>
      <Box ml="0.2cm">
        <LabPrintLabInfo
          dateSetting={dateSetting}
          showPrintedOnTime={showPrintedOnTime}
          showReportOnTime={showReportOnTime}
          showSampleTakenTime={showSampleTakenTime}
          showSampleTakenDate={showSampleTakenDate}
          showPrintedOnDate={showPrintedOnDate}
          showReportOnDate={showReportOnDate}
          sampleRemarks={
            Array.isArray(labData.remarks) && labData.remarks.length > 0
              ? labData.remarks[0].remark
              : null
          }
          collectionDate={labData.collectionDate}
          resultDate={labData.resultDate}
          labIdentifier={labData.labIdentifier || ""}
          modifiedDate={labData.results.modifiedDate || null}
          mfField="mf1"
        />
      </Box>
    </Box>
  );

  return (
    <table>
      <thead>
        <tr>
          <td>
            {showLetterhead && (
              <Box
                width="100%"
                style={{
                  ...getSectionStyle(headerColor).headerStyle,
                  paddingTop: "1mm",
                  paddingBottom: "3mm",
                  marginBottom: "0mm"
                }}
              >
                {customHeaderImage ? (
                  <CustomHeader imageUrl={customHeaderImage} />
                ) : (
                  getLabHeader(centralizedHeader, resourceCentre, clinicNameFontScale)
                )}
              </Box>
            )}

            <hr style={{ border: "1px solid black" }} />
          </td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <Box style={{ ...getSectionStyle(headerColor).bodyStyle }}>
              <>
                {letterHeadClientInfo}
                <LabelledField
                  label="Test ID"
                  value={useCustomLabTestNumber ? labData.labTestNumber || labData.id : labData.id}
                />
                <Barcode
                  value={labData.id}
                  width={2}
                  displayValue={false}
                  fontSize={5}
                  height={20}
                  margin={2}
                  font="arial"
                />
              </>

              <Box mt="0.3cm" width="calc(100vw - 25mm)">
                <LabPrintCoreData
                  showAdditionalLabData={showAdditionalLabData}
                  labData={groupLabTestByCategory(labData)}
                  headerColor={headerColor}
                  showMethods={showMethods}
                  showRanges={showRanges}
                  showUnit={showUnit}
                  showReading={showReading}
                  showRangeFlag={showRangeFlag}
                  showSampleCollectedBy={showSampleCollectedBy}
                />
              </Box>

              <Box
                style={{ pageBreakInside: "avoid" }}
                display="flex"
                width="100%"
                right="0px"
                mt="0.22cm"
                justifyContent="space-between"
                px="1cm"
              >
                {labData.results?.currentApprovedUser ? (
                  <AuthorizedSPInfo
                    sp={labData.results?.currentApprovedUser}
                    imageWrapperStyles={{ marginTop: "0px", minHeight: "1cm" }}
                    imageStyles={{ height: "1cm" }}
                  />
                ) : (
                  <Box
                    style={{ pageBreakInside: "avoid" }}
                    gap="30px"
                    display="flex"
                    width="100%"
                    justifyContent="space-between"
                  >
                    {showDynamicSignature(authorizedLabSp1) && (
                      <Box>
                        <AuthorizedSPInfo
                          sp={authorizedLabSp1}
                          imageWrapperStyles={{ marginTop: "0px", minHeight: "1cm" }}
                          imageStyles={{ height: "1cm" }}
                        />
                      </Box>
                    )}
                    {showDynamicSignature(authorizedLabSp2, authorizedLabSp1) && (
                      <Box>
                        <AuthorizedSPInfo
                          sp={authorizedLabSp2}
                          imageWrapperStyles={{ marginTop: "0px", minHeight: "1cm" }}
                          imageStyles={{ height: "1cm" }}
                        />
                      </Box>
                    )}
                    {showDynamicSignature(authorizedLabSp3, authorizedLabSp1, authorizedLabSp2) && (
                      <Box>
                        <AuthorizedSPInfo
                          sp={authorizedLabSp3}
                          imageWrapperStyles={{ marginTop: "0px", minHeight: "1cm" }}
                          imageStyles={{ height: "1cm" }}
                        />
                      </Box>
                    )}
                    {showDynamicSignature(
                      authorizedLabSp4,
                      authorizedLabSp1,
                      authorizedLabSp2,
                      authorizedLabSp3
                    ) && (
                      <Box>
                        <AuthorizedSPInfo
                          sp={authorizedLabSp4}
                          imageWrapperStyles={{ marginTop: "0px", minHeight: "1cm" }}
                          imageStyles={{ height: "1cm" }}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
              {showLabRemarks && (
                <Typography style={{ marginTop: "16px" }}>
                  <CustomEl data={labRemarks} />
                </Typography>
              )}
            </Box>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <Box height="3.6cm" bottom="0px" />
            <LabPrintFooter
              uuid={labData.uuid}
              headerColor={headerColor}
              id={labData.id}
              unfixFooter={otherProps?.unfixFooter}
              showFooter={showFooter}
              hideFooterLogoCompletely={hideFooterLogoCompletely}
            />
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

A5Template.defaultProps = {
  otherProps: {}
};

export default A5Template;
