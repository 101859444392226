import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import produce from "immer";
import Modal from "../../components/Modal/Modal";
import { tl } from "../../components/translate";
import { resourceCentreActions } from "../../actions";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import { notificationAdd } from "../../actions/notification";

interface Category {
  name: string;
  inputValue?: string;
}

interface ProductCategoryAdditionProps {
  resourceCentre: ResourceCentre;
  isServiceCreation?: boolean;
}
const filter = createFilterOptions<Category | string>();
const getType = (isInternal, isServiceCreation) => {
  if (isServiceCreation) return "Service Type";
  if (isInternal) return "Internal";
  return "Sellable";
};

const getConfirmationMessage = (newCategory, isInternal, isServiceCreation) =>
  `Are you sure, Do you want to create "${newCategory}" ${getType(isInternal, isServiceCreation)} ${
    !isServiceCreation ? "category" : ""
  } ?`;

export default function ProductCategoryAddition({
  resourceCentre,
  isServiceCreation
}: ProductCategoryAdditionProps): JSX.Element {
  const dispatch = useDispatch();
  const [openModal, setOpenModal] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState<Category | string | null>(null);
  const [isInternal, setIsInternal] = React.useState<boolean>(false);
  const sellable = resourceCentre.settings.stockSettings?.productCategory?.sellable;
  const internal = resourceCentre.settings.stockSettings?.productCategory?.internal;
  const serviceTypes = resourceCentre.settings.serviceSettings?.serviceTypes;
  const [newCategory, setNewCategory] = React.useState("");

  const getOptions = (serviceCreation: boolean, internalCategory: boolean) => {
    if (serviceCreation) return serviceTypes;
    return internalCategory ? internal : sellable;
  };

  const showSuccessMsg = (rcId, isServiceType) => {
    if (rcId) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: `Successfully Added ${isServiceType ? "Service Type" : "Category"}.`,
          autoTimeout: true
        })
      );
    }
  };

  return (
    <div>
      <Typography m="10px 0" fontWeight={700}>
        {isServiceCreation ? tl("service.serviceTypes") : tl("stock.productCategory")}
      </Typography>
      <Typography m="10px" fontWeight={600}>
        {`Create Custom ${isServiceCreation ? "Service Types" : "Category"}`}
      </Typography>
      <Box display="flex" ml="10px" alignItems="center">
        <Autocomplete
          value={selectedCategory}
          onChange={(e, newValue: Category) => {
            if (newValue && newValue.inputValue) {
              setNewCategory(newValue.inputValue);
              setOpenModal(true);
            } else {
              setSelectedCategory(newValue);
            }
          }}
          filterOptions={(options, params) => {
            const filtered = filter(options, params);
            if (params.inputValue !== "") {
              filtered.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`
              });
            }
            return filtered;
          }}
          options={getOptions(isServiceCreation, isInternal)}
          getOptionLabel={(option) => {
            if (typeof option === "string") {
              return option;
            }
            return option.name;
          }}
          sx={{ width: "400px" }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label={`${getType(isInternal, isServiceCreation)}`}
              placeholder={`${getType(isInternal, isServiceCreation)}`}
              variant="outlined"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              // eslint-disable-next-line react/jsx-props-no-spreading
            />
          )}
        />
        {!isServiceCreation && (
          <>
            <Typography m="0 10px">Is Internal Category</Typography>
            <Checkbox
              checked={isInternal}
              onChange={({ target }) => setIsInternal(target.checked)}
            />
          </>
        )}
      </Box>
      <Modal
        open={openModal && Boolean(newCategory)}
        title="Add Custom Category"
        footer={
          <Button
            onClick={() => {
              if (!newCategory) return;
              let updatedRc;
              if (isServiceCreation) {
                updatedRc = produce(resourceCentre, (draft) => {
                  draft.settings.serviceSettings.serviceTypes.push(newCategory);
                });
              }
              if (isInternal && !isServiceCreation) {
                updatedRc = produce(resourceCentre, (draft) => {
                  draft.settings.stockSettings.productCategory.internal.push(newCategory);
                });
              }
              if (!isInternal && !isServiceCreation) {
                updatedRc = produce(resourceCentre, (draft) => {
                  draft.settings.stockSettings.productCategory.sellable.push(newCategory);
                });
              }
              dispatch(
                resourceCentreActions.putResourceCentre(updatedRc, (data) => {
                  showSuccessMsg(data?.id, isServiceCreation);
                })
              );
              setOpenModal(false);
            }}
            variant="outlined"
          >
            Save
          </Button>
        }
        onClose={() => setOpenModal(false)}
      >
        {getConfirmationMessage(newCategory, isInternal, isServiceCreation)}
      </Modal>
    </div>
  );
}

ProductCategoryAddition.defaultProps = {
  isServiceCreation: false
};
