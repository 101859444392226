import * as React from "react";
import { Moment } from "moment";
import { FormGroup, FormControlLabel, Checkbox, Grid2 as Grid, TextField } from "@mui/material";
import { tl } from "../../components/translate";
import CalendarDropdown from "../../components/CalendarDropdown/CalendarDropdown";
import styles from "./BookingCreateEdit.module.css";

interface FollowUp {
  value: boolean;
  reminders: Array<Record<string, never>>;
  // defaultDate: Date;
  maxValidDate: Moment;
  editable: boolean;
  onChange: (value, reminders) => void;
}

const Reminder: React.FC<{ values: any; maxValidDate: Moment; onChange: () => void }> = ({
  values,
  maxValidDate,
  onChange
}) => (
  <Grid container>
    <Grid size={4}>
      <CalendarDropdown
        placeholder="Remind on"
        label="Remind on"
        maxValidDate={maxValidDate}
        date={new Date(values.on)}
        onChange={(on) => onChange({ ...values, on })}
        format="formatted3"
      />
    </Grid>
    <Grid size={8}>
      <TextField
        className={styles.followUpRemark}
        label="Remark"
        value={values.remark}
        onChange={({ target }) => onChange({ ...values, remark: target.value })}
      />
    </Grid>
  </Grid>
);

const FollowUp: React.FC<FollowUp> = ({ value, reminders, maxValidDate, onChange, editable }) => (
  <FormGroup row classes={{ root: styles.followUpContainer }}>
    <FormControlLabel
      control={
        <Checkbox
          disabled={!editable}
          checked={value}
          onChange={() => onChange(!value, reminders)}
        />
      }
      label={tl("booking.isFollowUp")}
      color="primary"
    />
    {value &&
      reminders &&
      reminders.map((rem, i) => (
        <Reminder
          // eslint-disable-next-line react/no-array-index-key
          key={i}
          values={rem}
          onChange={(updatedRem) => onChange(value, [updatedRem])}
          maxValidDate={maxValidDate}
        />
      ))}
  </FormGroup>
);

export default FollowUp;
