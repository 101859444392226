import { TextField } from "@mui/material";
import * as React from "react";
import { useDispatch } from "react-redux";
import { TransactionType } from "../../../../interfaces/StockInterfaces";
import { notificationAdd } from "../../../../actions/notification";
import { tl } from "../../../../components/translate";

interface Props {
  value: number;
  onFreeQtyUpdate: (v) => void;
  transactionType: TransactionType;
}

export default function StockFreeQuantity(props: Props): JSX.Element {
  const { value, onFreeQtyUpdate, transactionType } = props;
  const dispatch = useDispatch();
  return (
    <div>
      <TextField
        style={{ marginTop: "10px" }}
        label={tl("StockEntry.FreeQty")}
        fullWidth
        disabled={transactionType === TransactionType.ADJUSTMENT}
        slotProps={{
          inputLabel: { shrink: true }
        }}
        type="number"
        value={value}
        margin="none"
        variant="outlined"
        onFocus={(e) => e.target.select()}
        onChange={(e) => {
          const quantity = Number(e.target.value);
          if (quantity % 1 !== 0) {
            dispatch(
              notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: "Quantity can't have decimal values.",
                autoTimeout: true
              })
            );
            return;
          }
          onFreeQtyUpdate(quantity);
        }}
      />
    </div>
  );
}
