import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { Button, Box, Typography, FormControlLabel, Checkbox, Tooltip } from "@mui/material";
import { push } from "connected-react-router";
import { useForm } from "react-hook-form";
import { omitBy, isNil } from "lodash";
import InfoIcon from "@mui/icons-material/Info";
import { useLocation } from "react-router";
import * as clientActions from "../../actions/client";
import StatefulButton from "../../components/StatefulButton/StatefulButton";
import { tl } from "../../components/translate";
import Panel from "../../components/Panel";
import Can from "../Policy/Can";
import { IThunkDispatch, RootState } from "../../store";
import style from "./style.module.css";
import { Client } from "../../interfaces/ClientInterface";
import { CustomerType } from "../../interfaces/Accounts";
import Form from "./Form";
import { ledgerFindBySubLedgerType } from "../accounts/hooks";
import { notificationAdd } from "../../actions/notification";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";
import { isValidMobileNumber } from "../../helpers/validators";
import { getAgeFromDOB } from "../../helpers/formatters";

export const enum Mode {
  EDIT = "edit",
  CREATE = "create"
}

interface Props {
  afterSave: () => void;
  clientDefaultName: string;
  client: Client;
  onSave: (
    data: Partial<Client>,
    stayOnCurrentPage: boolean,
    searchQuery?: string
  ) => Promise<Client>;
  onCancel: () => void;
  showFooter: boolean;
  mode: string;
  navigateTo: (v: string) => void;
  setEditMode: () => void;
  stayOnCurrentPage: boolean;
  wrapperStyle: {
    [key: string]: string;
  };
  resourceCentreId?: number;
  fromBill?: boolean;
  isCorporateClient?: boolean;
}

const initialFormState = {
  fullName: null,
  phone: null,
  secondaryPhone: null,
  email: null,
  gender: null,
  dob: null,
  address: null,
  city: null,
  occupation: null,
  insuranceNumber: null,
  bloodGroup: null,
  maritalStatus: null,
  ethnicity: null,
  palikaId: null,
  nationality: null,
  appliedCountry: null,
  passportInfo: {
    passportNumber: null,
    issueDate: null,
    expiryDate: null,
    issuingAuthority: null
  },
  image: null,
  ipdNo: null,
  wardNo: null,
  registrationNo: null,
  transactionType: null,
  openingBalance: 0,
  associatedCompanyId: null,
  nationalIdentityNumber: null,
  panVatNumber: null
};

const initialCorporateFormState = {
  fullName: null,
  phone: null,
  secondaryPhone: null,
  email: null,
  address: null,
  city: null,
  openingBalance: 0,
  paymentTerm: null,
  panVatNumber: null,
  transactionType: null
};

const ClientCreateEdit = ({
  afterSave,
  clientDefaultName,
  client,
  onSave,
  onCancel,
  showFooter = true,
  mode,
  navigateTo,
  setEditMode,
  stayOnCurrentPage,
  wrapperStyle,
  resourceCentreId,
  fromBill = false,
  isCorporateClient = false
}: Props) => {
  const [saving, setSaving] = React.useState(false);
  const isAccountSubscribed = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription?.features?.account?.subscribed
  );
  const location = useLocation();

  const [clientWithSamePhoneAndName, setClientWithSamePhoneAndName] = React.useState(null);
  const currentResourceCentre = useCurrentResourceCentre();

  const {
    handleSubmit,
    control,
    watch,
    formState: { errors },
    setError,
    clearErrors
  } = useForm<Client>({
    defaultValues:
      mode === Mode.CREATE
        ? {
            ...(isCorporateClient ? initialFormState : initialCorporateFormState),
            fullName: clientDefaultName || ""
          }
        : {
            ...client,
            fullName: `${client?.firstName || ""} ${client?.lastName || ""}`
          }
  });
  const formState = watch();

  let hasValidPhoneAndAge = isValidMobileNumber({ msg: "" })(formState.phone);
  if (formState.dob) {
    hasValidPhoneAndAge = hasValidPhoneAndAge && getAgeFromDOB(formState.dob) > 15;
  }
  const [sendAppLinkToClient, setSendAppLinkToClient] = React.useState(
    mode === Mode.CREATE && hasValidPhoneAndAge
  );
  const clientLedger = ledgerFindBySubLedgerType(CustomerType.CLIENT);

  React.useEffect(() => {
    setSendAppLinkToClient(hasValidPhoneAndAge);
  }, [hasValidPhoneAndAge]);

  const dispatch = useDispatch();
  const handleFormSubmit = async (submittedData) => {
    if (clientWithSamePhoneAndName) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message:
            "Client with same name and phone number found. Please change name or phone number before continuing.",
          autoTimeout: true
        })
      );
      return;
    }
    setSaving(true);
    const names = submittedData.fullName.trim().split(" ");
    // fullName is set as firstName for corporate client on db
    const formData = omitBy(
      {
        ...submittedData,
        sendAppLinkToClient,
        ...(resourceCentreId && { resourceCentreId }),
        ...(isCorporateClient && {
          firstName: submittedData.fullName,
          lastName: "",
          paymentTerm: Number(submittedData.paymentTerm),
          isCorporateClient: true
        }),
        ...(!isCorporateClient && {
          gender: parseInt(submittedData.gender, 10),
          lastName: names.length > 1 ? names.pop() : "",
          firstName: names.join(" ")
        }),
        ...(submittedData.openingBalance !== null && submittedData.openingBalance !== undefined
          ? { openingBalance: Number(submittedData.openingBalance) }
          : {})
      },
      isNil
    );

    if (mode === "create" && isAccountSubscribed && clientLedger) {
      formData.ledgerId = clientLedger.id;
    }

    onSave(formData, stayOnCurrentPage, location.search).then(() => {
      setSaving(false);
      if (afterSave) {
        afterSave();
      }
      if (setEditMode) {
        setEditMode(false);
      }
    });
  };

  return (
    <Can
      policyAccessKey={["clients:createClient", "clients:editClient"]}
      superAdminPass
      supportAccountAdminPass
      partialCheck
    >
      <Panel
        wrapperStyle={wrapperStyle}
        onClose={onCancel}
        title={mode === "edit" ? tl("client.updateClient") : tl("client.createNewClient")}
        footer={
          showFooter && (
            <Box display="flex" justifyContent="space-between" width="100%" marginRight="32px">
              <Box display="flex" alignItems="center" gap={0.5}>
                {currentResourceCentre?.settings.clientSettings.sendAppLinkToClient &&
                  mode === Mode.CREATE && (
                    <>
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled={!hasValidPhoneAndAge}
                            checked={sendAppLinkToClient}
                            onChange={() => setSendAppLinkToClient(!sendAppLinkToClient)}
                          />
                        }
                        label="Send app link to client"
                      />
                      <Tooltip
                        title="Send Okhati patient app link to client. No sms credit needed. Sms will be sent if patient is above 16."
                        arrow
                      >
                        <InfoIcon color="info" />
                      </Tooltip>
                    </>
                  )}
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Button
                  data-testmation="client.cancel"
                  onClick={() => {
                    if (mode === "edit") {
                      onCancel();
                    } else {
                      navigateTo(`/clients${location.search}`);
                    }
                    if (setEditMode) {
                      setEditMode(false);
                    }
                  }}
                  style={{ marginRight: "32px" }}
                >
                  <Typography>{tl("client.cancel")}</Typography>
                </Button>
                <StatefulButton
                  data-testmation="clientSave"
                  variant="contained"
                  color="primary"
                  disabled={saving}
                  onClick={handleSubmit(handleFormSubmit)}
                  isLoading={saving}
                  circularProgressProps={{ size: 16 }}
                >
                  <Typography>{tl("client.save")}</Typography>
                </StatefulButton>
              </Box>
            </Box>
          )
        }
      >
        <Box sx={{ pl: 5, pr: 5, width: "100%" }} className={style.clientForm}>
          <Form
            isCorporateClient={isCorporateClient}
            fromBill={fromBill}
            clearErrors={clearErrors}
            setError={setError}
            client={client}
            mode={mode}
            formState={formState}
            control={control}
            errors={errors}
            sameClient={clientWithSamePhoneAndName}
            setSameClient={setClientWithSamePhoneAndName}
          />
        </Box>
      </Panel>
    </Can>
  );
};

ClientCreateEdit.defaultProps = {
  resourceCentreId: null,
  fromBill: false
};

export default connect(
  (state: RootState, { client, clientId }: { client: Client; clientId: number }) => {
    const getResourceCentre = (id, resourceCentres) =>
      resourceCentres.find((rc) => Number(rc.id) === Number(id));
    const resourceCentre = getResourceCentre(
      state.userContext.resourceCentreId,
      state.resources.resourceCentres
    );

    return {
      client: client || state.clients.collection?.find(({ id }) => Number(clientId) === id),
      formSettings:
        resourceCentre?.settings.formSettings ||
        state.userContext.resourceCentre?.settings.formSettings
    };
  },
  (dispatch: IThunkDispatch) => ({
    onSave: async (clientData, stayOnCurrentPage, searchQuery) => {
      await dispatch(clientActions.saveClient(clientData, stayOnCurrentPage, searchQuery));
    },
    navigateTo: (url) => dispatch(push(url))
  })
)(ClientCreateEdit);
