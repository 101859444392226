import React from "react";
import { Chip, TextField, Autocomplete } from "@mui/material";

interface Props {
  serviceTag: { id: number; name: string; mappedName: string };
  onChange: (v: Array<string>) => void;
  addedMappedNames: Array<string>;
}

function getDefaultOptions({
  serviceTag
}: Omit<Props, "onChange" | "addedMappedNames">): Array<string> {
  if (serviceTag) {
    return serviceTag.mappedName?.split(";").map((item) => item.trim()) || [];
  }
  return [];
}

function MappedNameAdder(props: Props) {
  const { serviceTag, onChange, addedMappedNames = [] } = props;
  const defaultOpts = getDefaultOptions({ serviceTag });

  return (
    <Autocomplete<string, true, true, true>
      multiple
      freeSolo
      value={defaultOpts.concat(addedMappedNames)}
      onChange={(_, v) => {
        onChange(v.filter((item) => !defaultOpts.includes(item)));
      }}
      options={[]}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Missing Search Tags"
          placeholder="Add missing search tag"
          margin="dense"
          variant="outlined"
          slotProps={{
            inputLabel: { shrink: true }
          }}
        />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            label={option}
            size="small"
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...getTagProps({ index })}
            disabled={defaultOpts.includes(option)}
          />
        ))
      }
    />
  );
}

export default React.memo(MappedNameAdder);
