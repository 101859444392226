import * as React from "react";
import { Box, Typography } from "@mui/material";
import styles from "./Assessment.module.css";
import DebouncedTextField from "../../components/DebouncedTextField";
import useAssessmentLabel from "../../hooks/useAssessmentLabel";

interface OthersInterface {
  assessmentOthers: string;
  onChange: (value) => void;
}

const Others: React.FC<OthersInterface> = (props) => {
  const { assessmentOthers, onChange } = props;
  const assessmentLabels = useAssessmentLabel();
  return (
    <Box className={styles.assessmentRow}>
      <Box className={styles.assessmentLabel}>
        <Typography component="span" fontWeight="600">
          {assessmentLabels.others}
        </Typography>
      </Box>
      <Box className={styles.assessmentField} display="flex">
        <DebouncedTextField
          data-testmation="othersTextField"
          fullWidth
          variant="outlined"
          margin="dense"
          slotProps={{
            inputLabel: { shrink: true }
          }}
          placeholder="Others"
          value={assessmentOthers}
          onChange={(e) => onChange(e.target.value)}
          debounceAt={400}
        />
      </Box>
    </Box>
  );
};

export default Others;
