import AccessTimeIcon from "@mui/icons-material/AccessTime";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Button, Grid2 as Grid, Typography } from "@mui/material";
import moment from "moment";
import React from "react";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import ClientSearch from "../../../components/ClientSearch";
import { t } from "../../../components/translate";
import { StateProps } from "./ScheduleDetails";

interface Props {
  currentState: StateProps;
  onChange: (newState: StateProps) => void;
  resourceCentreId: number;
  onContinue: () => void;
}

export default function PersonalDetails({
  resourceCentreId,
  currentState,
  onChange,
  onContinue
}: Props): JSX.Element {
  const { client } = currentState;
  return (
    <Grid gap={2} container>
      <Grid size={{ sm: 12 }}>
        <ClientSearch
          data-testmation="searchClientInput"
          fromSuperAdmin
          label="Client Search or Create"
          resourceCentreId={resourceCentreId}
          variant="outlined"
          margin="dense"
          client={client || null}
          autoFocus
          secondaryText={[
            {
              type: "customerNumber"
            },
            {
              type: "externalIdentifier",
              label: t("client.externalIdentifier.short")
            }
          ]}
          setClient={(selectedClient) => {
            if (selectedClient) {
              onChange({ ...currentState, client: selectedClient });
            }
          }}
        />
      </Grid>
      {currentState.client?.id && (
        <Grid container>
          <Grid size={{ sm: 12, md: 3 }}>
            <Box gap={2} display="flex">
              <PersonIcon />
              <Typography>{`${client?.firstName} ${client?.lastName}`}</Typography>
            </Box>
          </Grid>
          <Grid size={{ sm: 12, md: 3 }}>
            <Box gap={2} display="flex">
              <PhoneIcon />
              <Typography>{`${client?.phone}`}</Typography>
            </Box>
          </Grid>
          <Grid size={{ sm: 12, md: 3 }}>
            <Box gap={2} display="flex">
              <AccessTimeIcon />
              <Typography>
                {`${convertADtoBS(moment(currentState.start)).formatted4}`}
                {` ${moment(currentState.start).format("hh:mm A")}`}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      )}
      <Button
        disabled={!client?.id}
        onClick={onContinue}
        variant="contained"
        data-testmation="personalDetailDoneBtn"
      >
        Continue
      </Button>
    </Grid>
  );
}
