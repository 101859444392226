import {
  Dialog,
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Button,
  TextField,
  MenuItem,
  Box,
  FormControlLabel,
  Checkbox,
  Alert,
  Switch
} from "@mui/material";
import moment from "moment";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { unwrapResult } from "@reduxjs/toolkit";
import { useSelector, useDispatch } from "react-redux";
import produce from "immer";
import { startCase } from "lodash";
import { RootState } from "../../../store";
import {
  patchSubscription,
  postSubscription,
  updateDraftSubscription
} from "../../../slices/subscriptionSlice";
import hasOwnProperty from "../../../helpers/object";
import { PaymentTypes, Subscription, SubscriptionFeatures } from "../../../interfaces/Subscription";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";
import { notificationAdd } from "../../../actions/notification";
import { commonErrorMessage } from "../../../helpers/messages";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { resourceCentreActions } from "../../../actions";

interface Props {
  showModal: boolean;
  setShowModal: (v: boolean) => void;
  templateChanged: boolean;
  resourceCentre: ResourceCentre;
}

function hasReachedMaxModuleLimit(features) {
  const limit = features.choiceModules;
  const count = Object.keys(features).reduce((acc, cur) => {
    if (features[cur].subscribed && !features[cur].nonModule) {
      return acc + 1;
    }
    return acc;
  }, 0);
  return count >= limit;
}

type ValueOf<T> = T[keyof T];

const hideModules = ["doctors", "clinic", "campaign", "services", "clients"];

enum RequiredFields {
  SALES_PERSON = "salesPerson",
  LEAD_BY = "leadBy",
  MOU_LINK = "mouLink"
}

export default function ChooseSubscriptionDialog({
  showModal,
  setShowModal,
  templateChanged,
  resourceCentre
}: Props): JSX.Element {
  const dispatch = useDispatch();

  const draftSubscription = useSelector(
    (state: RootState) => state.subscriptions.draftSubscription
  );
  const [errors, setErrors] = React.useState<{ [key: string]: boolean }>(
    Object.values(RequiredFields).reduce((values, field) => ({ ...values, [field]: false }), {})
  );

  if (!draftSubscription) return <></>;

  const isRequiredFieldEmpty = () => {
    const errorData = Object.values(RequiredFields).reduce(
      (err, field) => ({ ...err, [field]: !draftSubscription[field] }),
      {}
    );
    setErrors(errorData);
    return Object.values(errorData).includes(true);
  };

  const showErrorNotification = (error) => {
    dispatch(
      notificationAdd({
        id: new Date().getTime(),
        message: error.message || commonErrorMessage,
        autoTimeout: true,
        variant: "error"
      })
    );
  };

  return (
    <Dialog fullScreen open={showModal} onClose={() => setShowModal(false)}>
      <AppBar>
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => setShowModal(false)}
            aria-label="close"
            size="large"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6">Choose New Subscription</Typography>
          <Button
            data-testmation="save"
            autoFocus
            color="inherit"
            onClick={async () => {
              if (templateChanged) {
                if (isRequiredFieldEmpty()) {
                  return;
                }
                try {
                  const resultAction = await dispatch(
                    postSubscription({ ...draftSubscription, resourceCentreId: resourceCentre.id })
                  );
                  unwrapResult(resultAction);
                  setShowModal(false);
                } catch (error) {
                  showErrorNotification(error);
                }
              } else {
                if (isRequiredFieldEmpty()) {
                  return;
                }
                try {
                  const resultAction = await dispatch(patchSubscription(draftSubscription));
                  unwrapResult(resultAction);
                  dispatch(resourceCentreActions.getResourceCentre(resourceCentre.id));
                  setShowModal(false);
                } catch (error) {
                  showErrorNotification(error);
                }
              }
            }}
            disabled={
              draftSubscription.isTrial
                ? !draftSubscription.subscriptionEnd
                : !draftSubscription.subscriptionEnd || !draftSubscription.paymentPrice
            }
          >
            save
          </Button>
        </Toolbar>
      </AppBar>
      <Box p={5} mt="32px" display="flex" flexDirection="column" rowGap="8px">
        <Typography variant="h4">Plan Info</Typography>
        <Box display="flex" alignItems="center" columnGap="16px">
          <Box>
            <CalendarDropdown
              placeholder="Subscription Start Date"
              label="Subscription Start Date"
              minValidDate={moment()}
              disable
              date={new Date()}
              format="formatted4"
              TextFieldInputProps={{ disableUnderline: true }}
            />
          </Box>
          <Box>
            <CalendarDropdown
              placeholder="Invoice Start Date"
              label="Invoice Start Date"
              minValidDate={moment()}
              date={
                draftSubscription.invoiceGenerationStartDate
                  ? new Date(draftSubscription.invoiceGenerationStartDate)
                  : new Date()
              }
              onChange={(collectionDate) =>
                dispatch(
                  updateDraftSubscription({
                    ...draftSubscription,
                    invoiceGenerationStartDate: collectionDate
                  })
                )
              }
              format="formatted4"
              TextFieldInputProps={{ disableUnderline: true }}
            />
          </Box>

          <Box>
            <CalendarDropdown
              placeholder="Subscription End Date"
              label="Subscription End Date"
              minValidDate={moment()}
              date={
                draftSubscription.subscriptionEnd
                  ? new Date(draftSubscription.subscriptionEnd)
                  : new Date(moment().add(1, "year").toISOString())
              }
              onChange={(endDate) =>
                dispatch(
                  updateDraftSubscription({
                    ...draftSubscription,
                    subscriptionEnd: endDate
                  })
                )
              }
              format="formatted4"
              TextFieldInputProps={{ disableUnderline: true }}
            />
          </Box>

          <TextField
            type="number"
            margin="dense"
            label="Initial Amount"
            variant="outlined"
            slotProps={{
              inputLabel: { shrink: true },
              input: {
                startAdornment: <Box pr="8px">Rs</Box>
              }
            }}
            style={{ marginLeft: "32px", width: "160px", marginTop: "16px" }}
            value={draftSubscription.initialAmount || ""}
            onChange={(e) => {
              dispatch(
                updateDraftSubscription({
                  ...draftSubscription,
                  initialAmount: Number(e.target.value)
                })
              );
            }}
          />

          <TextField
            type="number"
            margin="dense"
            label="Price"
            variant="outlined"
            slotProps={{
              inputLabel: { shrink: true },
              input: {
                startAdornment: <Box pr="8px">Rs</Box>
              }
            }}
            style={{ marginLeft: "32px", width: "160px", marginTop: "16px" }}
            value={draftSubscription.paymentPrice || ""}
            onChange={(e) => {
              dispatch(
                updateDraftSubscription({
                  ...draftSubscription,
                  paymentPrice: Number(e.target.value)
                })
              );
            }}
          />

          <TextField
            select
            label="Select Payment Plan"
            margin="dense"
            style={{ margin: "0 0 -4px 16px", width: "200px" }}
            value={draftSubscription.paymentType || "yearly"}
            onChange={(e) => {
              dispatch(
                updateDraftSubscription({
                  ...draftSubscription,
                  paymentType: e.target.value as PaymentTypes
                })
              );
            }}
          >
            <MenuItem value="monthly">Monthly</MenuItem>
            <MenuItem value="quarterly">Quarterly</MenuItem>
            <MenuItem value="yearly">Yearly</MenuItem>
          </TextField>

          <FormControlLabel
            control={
              <Checkbox
                checked={draftSubscription.isTrial}
                onChange={() => {
                  const updatedDraft = produce(draftSubscription as Subscription, (draft) => {
                    if (draft.isTest && !draft.isTrial) {
                      draft.isTest = false;
                    }
                    draft.isTrial = !draft.isTrial;
                  });
                  dispatch(updateDraftSubscription(updatedDraft));
                }}
              />
            }
            style={{ marginTop: "8px", marginLeft: "16px" }}
            label="Trial Mode"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={draftSubscription.isTest}
                onChange={() => {
                  const updatedDraft = produce(draftSubscription as Subscription, (draft) => {
                    if (draft.isTrial && !draft.isTest) {
                      draft.isTrial = false;
                    }
                    draft.isTest = !draft.isTest;
                  });
                  dispatch(updateDraftSubscription(updatedDraft));
                }}
              />
            }
            style={{ marginTop: "8px", marginLeft: "16px" }}
            label="Test Mode"
          />
        </Box>

        <Box display="flex" alignItems="center" columnGap="16px">
          <FormControlLabel
            control={
              <Switch
                checked={draftSubscription.isInvoicePaused || false}
                value={draftSubscription.isInvoicePaused || false}
                onChange={(e) => {
                  dispatch(
                    updateDraftSubscription({
                      ...draftSubscription,
                      isInvoicePaused: e.target.checked
                    })
                  );
                }}
              />
            }
            label="Paused"
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={Boolean(draftSubscription.requireInvoiceVerification)}
                onChange={() => {
                  const updatedDraft = produce(draftSubscription as Subscription, (draft) => {
                    draft.requireInvoiceVerification = !draft.requireInvoiceVerification;
                  });
                  dispatch(updateDraftSubscription(updatedDraft));
                }}
              />
            }
            label="Require Invoice Verification"
          />
        </Box>

        <Box display="flex" alignItems="center" columnGap="16px">
          <TextField
            data-testmation="salesPersonInput"
            error={errors.salesPerson}
            margin="dense"
            label="Sales Person *"
            variant="outlined"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            value={draftSubscription.salesPerson || ""}
            onChange={(e) => {
              dispatch(
                updateDraftSubscription({
                  ...draftSubscription,
                  salesPerson: e.target.value
                })
              );
              if (errors.salesPerson) {
                setErrors({ ...errors, salesPerson: false });
              }
            }}
          />
          <TextField
            data-testmation="leadByInput"
            error={errors.leadBy}
            margin="dense"
            label="Lead By *"
            variant="outlined"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            value={draftSubscription.leadBy || ""}
            onChange={(e) => {
              dispatch(
                updateDraftSubscription({
                  ...draftSubscription,
                  leadBy: e.target.value
                })
              );
              if (errors.leadBy) {
                setErrors({ ...errors, leadBy: false });
              }
            }}
          />

          <TextField
            data-testmation="mouLinkInput"
            error={errors.mouLink}
            margin="dense"
            label="MOU Link *"
            variant="standard"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            value={draftSubscription.mouLink || ""}
            fullWidth
            style={{ marginBottom: "8px" }}
            onChange={(e) => {
              dispatch(
                updateDraftSubscription({
                  ...draftSubscription,
                  mouLink: e.target.value
                })
              );
              if (errors.mouLink) {
                setErrors({ ...errors, mouLink: false });
              }
            }}
          />
        </Box>
      </Box>

      {draftSubscription.features.choiceModules < 1000 && (
        <Alert severity="warning">
          Total Extra Modules : {draftSubscription.features.choiceModules - 2}
        </Alert>
      )}

      <Box px={5} display="flex" flexDirection="column" mt="16px">
        <Typography variant="h4">Plan Features</Typography>
        {draftSubscription &&
          Object.keys(draftSubscription.features)
            .filter((k) => !hideModules.includes(k))
            .filter((k) => k !== "choiceModules")
            .filter((k) => !hasOwnProperty(draftSubscription.features[k], "limit"))
            .map((module) => {
              const relatedModule = draftSubscription.features[module] as ValueOf<
                Omit<SubscriptionFeatures, "choiceModules">
              >;

              return (
                <>
                  <Box borderBottom="1px solid lightgrey" key={module}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={relatedModule.subscribed}
                          onChange={(e) => {
                            if (
                              !hasReachedMaxModuleLimit(draftSubscription.features) ||
                              !e.target.checked
                            ) {
                              const updatedDraft = produce(
                                draftSubscription as Subscription,
                                (draft) => {
                                  draft.features[module].subscribed = e.target.checked;
                                }
                              );
                              dispatch(updateDraftSubscription(updatedDraft));
                            }
                          }}
                        />
                      }
                      data-testmation={startCase(module)}
                      label={startCase(module)}
                      disabled={
                        hasOwnProperty(relatedModule, "nonModule") && relatedModule.nonModule
                      }
                    />
                  </Box>
                </>
              );
            })}

        <Box my="32px">
          {draftSubscription &&
            Object.keys(draftSubscription.features)
              .filter((k) => k !== "choiceModules")
              .filter((k) => hasOwnProperty(draftSubscription.features[k], "limit"))
              .map((module) => {
                const relatedModule = draftSubscription.features[module] as ValueOf<
                  Omit<SubscriptionFeatures, "choiceModules">
                >;
                return (
                  <TextField
                    key={module}
                    label={`${startCase(module)} Limit`}
                    style={{ marginLeft: "16px" }}
                    value={hasOwnProperty(relatedModule, "limit") && relatedModule.limit}
                    onChange={(e) => {
                      const updatedDraft = produce(draftSubscription as Subscription, (draft) => {
                        draft.features[module].limit = e.target.value;
                      });
                      dispatch(updateDraftSubscription(updatedDraft));
                    }}
                  />
                );
              })}
        </Box>
        {draftSubscription && (
          <Box display="flex" alignItems="center" gap={2} ml="10px">
            <Typography>Accounting Opening Date:</Typography>
            <CalendarDropdown
              placeholder="Migration Date"
              TextFieldProps={{ variant: "outlined" }}
              date={
                resourceCentre.accountsOpeningDate
                  ? new Date(moment(resourceCentre.accountsOpeningDate).startOf("day"))
                  : new Date()
              }
              format="formatted4"
              TextFieldInputProps={{ disableUnderline: true }}
              onChange={(newDate) => {
                dispatch(
                  updateDraftSubscription({
                    ...draftSubscription,
                    migrationDate: moment(newDate).tz("Asia/Kathmandu").startOf("day").toISOString()
                  })
                );
              }}
            />
          </Box>
        )}
      </Box>
    </Dialog>
  );
}
