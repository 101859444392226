import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import produce from "immer";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { Grid2 as Grid, Box, Select, MenuItem } from "@mui/material";
import FeedActions from "./FeedActions";
import * as feedActions from "../../../actions/feed";
import SelectFeedType from "./SelectFeedType";
import SetFeedDate from "./SetFeedDate";
import NativeViewFields from "./NativeViewFields";
import GenericTextField, { Label } from "./GenericTextField";
import BannerImageInput from "./BannerImageInput";

const FEED_ACTION_OPTIONS = [
  { label: "Open in web view", value: feedActions.Actions.OPEN_WEB_VIEW },
  { label: "Open in mobile app", value: feedActions.Actions.OPEN_NATIVE_VIEW },
  { label: "Open appstore", value: feedActions.Actions.OPEN_APP_STORE }
];

const FeedEditor = ({ actions, mode, feedId, draft }) => {
  React.useEffect(() => {
    if (!feedId && !draft) {
      actions.initDraft();
    }
    if (feedId && mode === "edit") {
      actions.loadFeedForEdit(feedId);
    }
  }, []);

  const handleOnChange = (values: any) => {
    const updatedFeed = produce(draft, (newDraft) => ({
      ...newDraft,
      ...values
    }));
    actions.updateDraft(updatedFeed);
  };

  if (!draft) {
    return null;
  }
  return (
    <Box component="div">
      <Box paddingLeft="32px" paddingRight="32px" paddingTop="24px" paddingBottom="64px">
        <Breadcrumbs aria-label="breadcrumb">
          <Box fontWeight={700} color="textPrimary">
            New feed
          </Box>
        </Breadcrumbs>
        <Box mt="32px">
          <Grid container>
            <Grid size={{ xs: 12, md: 8 }}>
              <SelectFeedType
                selectdType={draft?.type}
                onChange={({ target: { name, value } }) => {
                  handleOnChange({ [name]: value });
                }}
              />
              <Box
                display="grid"
                columnGap={48}
                gridTemplateColumns="repeat(auto-fit,minmax(15rem, 1fr))"
              >
                <SetFeedDate
                  label="Start date"
                  selectedDate={draft.startDate}
                  onChange={(startDate) => {
                    handleOnChange({ startDate });
                  }}
                />
                <SetFeedDate
                  label="End date"
                  selectedDate={draft.endDate}
                  onChange={(endDate) => {
                    handleOnChange({ endDate });
                  }}
                />
              </Box>
              <Box component="div" display="flex">
                <Label>Title</Label>
                <GenericTextField
                  dataTestmation="feedTitle"
                  value={draft.title}
                  placeholderText="Feed title"
                  maxLength={1024}
                  onChange={(e) => {
                    handleOnChange({ title: e.target.value });
                  }}
                />
              </Box>
              <Box component="div" display="flex" mb={1}>
                <Label>Description</Label>
                <GenericTextField
                  dataTestmation="feedDescription"
                  rows={2}
                  rowsMax={4}
                  value={draft.description}
                  placeholderText="Feed description"
                  maxLength={280}
                  onChange={(e) => {
                    handleOnChange({ description: e.target.value });
                  }}
                />
              </Box>
              <BannerImageInput
                draft={draft}
                onChange={(value = {}) => {
                  handleOnChange(value);
                }}
              />
              <Box
                display="grid"
                columnGap={48}
                gridTemplateColumns="repeat(auto-fit,minmax(15rem, 1fr))"
              >
                <Box component="div" display="flex" maxWidth={240} mb={1}>
                  <Label>Action</Label>
                  <Select
                    autoWidth
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    value={draft.action}
                    onChange={(e) => {
                      handleOnChange({
                        action: e.target.value,
                        actionProps: {
                          actionLabel: draft.actionProps.actionLabel || ""
                        }
                      });
                    }}
                  >
                    {FEED_ACTION_OPTIONS.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box component="div" display="flex">
                  <Label>
                    <>Action label</>
                  </Label>
                  <GenericTextField
                    dataTestmation="Action label"
                    value={draft.actionProps?.actionLabel}
                    placeholderText="Action label"
                    maxLength={66}
                    onChange={(e) => {
                      handleOnChange({
                        actionProps: {
                          ...draft.actionProps,
                          actionLabel: e.target.value
                        }
                      });
                    }}
                  />
                </Box>
              </Box>
              {draft.action === feedActions.Actions.OPEN_NATIVE_VIEW && (
                <NativeViewFields draft={draft} handleOnChange={handleOnChange} />
              )}
              {draft.action === feedActions.Actions.OPEN_WEB_VIEW && (
                <Box component="div" display="flex">
                  <Label>
                    <></>
                  </Label>
                  <GenericTextField
                    dataTestmation="Web view url"
                    value={draft.actionProps?.actionWebUrl}
                    placeholderText="Web view url"
                    maxLength={1024}
                    onChange={(e) => {
                      handleOnChange({
                        actionProps: {
                          ...draft.actionProps,
                          actionWebUrl: e.target.value
                        }
                      });
                    }}
                  />
                </Box>
              )}

              {draft.action === feedActions.Actions.OPEN_APP_STORE && (
                <>
                  <Box component="div" display="flex">
                    <Label>
                      <></>
                    </Label>
                    <GenericTextField
                      dataTestmation="Android app store url"
                      value={draft.actionProps?.actionAndroidUrl}
                      placeholderText="Android app store url"
                      maxLength={1024}
                      onChange={(e) => {
                        handleOnChange({
                          actionProps: {
                            ...draft.actionProps,
                            actionAndroidUrl: e.target.value
                          }
                        });
                      }}
                    />
                  </Box>
                  <Box component="div" display="flex">
                    <Label>
                      <></>
                    </Label>
                    <GenericTextField
                      dataTestmation="Apple app store url"
                      value={draft.actionProps?.actionIosUrl}
                      placeholderText="IOS app store url"
                      maxLength={1024}
                      onChange={(e) => {
                        handleOnChange({
                          actionProps: {
                            ...draft.actionProps,
                            actionIosUrl: e.target.value
                          }
                        });
                      }}
                    />
                  </Box>
                </>
              )}
            </Grid>
          </Grid>
        </Box>
      </Box>
      <FeedActions draft={draft} />
    </Box>
  );
};

function mapStateToProps({ feeds }) {
  return {
    draft: feeds.draft
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: {
      clearDraft: () => dispatch(feedActions.clearDraft()),
      initDraft: () => {
        dispatch(feedActions.initDraft());
      },
      loadFeedForEdit: (feedId) => dispatch(feedActions.loadFeedForEdit(feedId)),
      updateDraft: (draft) => {
        dispatch(feedActions.updateDraft(draft));
      },
      navigateTo: (url) => dispatch(push(url))
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedEditor);
