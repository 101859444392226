import { useTheme, alpha } from "@mui/material/styles";
import { buildChartTheme } from "@visx/xychart";

const useChartTheme = (): ReturnType<typeof buildChartTheme> => {
  const theme = useTheme();

  return buildChartTheme({
    backgroundColor: "#fff",
    colors: [
      theme.palette.primary.main || "#009654",
      alpha(theme.palette.secondary.main, 0.5) || "rgba(0, 150, 84, 0.5)"
    ],
    gridColor: "#336d88",
    gridColorDark: "#1d1b38",
    tickLength: 8
  });
};

export default useChartTheme;
