import React from "react";
import { Box } from "@mui/material";
import CalendarSettings from "../../Settings/CalendarSettings";
import { ResourceCentre } from "../../../../interfaces/ResourceCentreInterface";
import Panel from "../../../../components/Panel";

interface Props {
  resourceCentre: ResourceCentre;
  updateRc: (rc: ResourceCentre) => void;
  onClose: () => void;
}

const CalendarSettingPanel = ({ resourceCentre, updateRc, onClose }: Props): JSX.Element => (
  <Panel onClose={onClose} title="Calendar Settings">
    <Box px={4}>
      <CalendarSettings resourceCentre={resourceCentre} updateRc={updateRc} />
    </Box>
  </Panel>
);

export default CalendarSettingPanel;
