import React from "react";
import { Box, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import List from "../../../components/List";
import { Client } from "../../../interfaces/ClientInterface";
import { notificationAdd } from "../../../actions/notification";
import { getClientsWithUnsettelledProformaBills } from "../../../api/bill";
import ClientProformaBillsPanel from "./ClientProformaBillsPanel";

export default function ClientsWithProformaBills(): JSX.Element {
  const dispatch = useDispatch();
  const [clientId, setClientId] = React.useState<number | null>(null);
  const [clients, setClients] = React.useState<Partial<Partial<Client>>[]>([]);

  React.useEffect(() => {
    (async () => {
      try {
        const result = await getClientsWithUnsettelledProformaBills();
        setClients(result.map((bill) => bill.client).filter(Boolean));
      } catch (err) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to fetch clients with proforma bills",
            autoTimeout: true
          })
        );
      }
    })();
  }, []);

  return (
    <Box pt={3} height="100%" minWidth={900} data-testmation="clientsWithProformaBills">
      <List
        withoutSearch
        data={clients}
        rowHeight={50}
        hideCreateButton
        onRowClick={(row) => {
          if (clientId && clientId === row.id) {
            setClientId(null);
          } else {
            setClientId(row.id);
          }
        }}
        columns={[
          {
            key: "clientId",
            label: "Client ID",
            formatter: (row) => (
              <Typography component="div" variant="body2">
                {row.id}
              </Typography>
            )
          },
          {
            key: "clientName",
            label: "Client Name",
            formatter: (row) => (
              <Typography component="div" variant="body2" sx={{ textTransform: "uppercase" }}>
                {row?.firstName || ""} {row?.lastName || ""}
              </Typography>
            )
          }
        ]}
      />

      {clientId && (
        <ClientProformaBillsPanel clientId={clientId} handleClose={() => setClientId(null)} />
      )}
    </Box>
  );
}
