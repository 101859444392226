import React, { JSX } from "react";
import { Box, IconButton, Tooltip, Typography } from "@mui/material";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import InfoIcon from "@mui/icons-material/Info";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import styles from "../Assessment.module.css";
import useAssessmentLabel from "../../../hooks/useAssessmentLabel";
import { getAssessments } from "../../../api/assessment";
import { Assessment } from "../../../interfaces/AssessmentInterfaces";
import { notificationAdd as addNotification } from "../../../actions/notification";
import { errorFetchMessage, MODULE } from "../../../helpers/messages";
import { useAppDispatch } from "../../../store/hooks";
import { AssessmentSelector } from "../ComparePanel";

interface Props {
  data: Assessment;
  clientId?: number;
}

interface CompareProps {
  assessmentsWithAttachments: Assessment[];
  setAssessmentToCompare: (assessment?: Assessment) => void;
  assessmentToCompare?: Assessment;
  setImage: (image: {
    current: { fileName: string; downloadLink: string };
    compareWith: { fileName: string; downloadLink: string };
  }) => void;
  image: {
    current: { fileName: string; downloadLink: string };
    compareWith: { fileName: string; downloadLink: string };
  };
  data: Assessment;
}

const Comparision = ({
  assessmentsWithAttachments,
  setAssessmentToCompare,
  assessmentToCompare,
  setImage,
  image,
  data
}: CompareProps): JSX.Element => (
  <Box px={4} pb={4} pt={2} flex={1}>
    <Box display="flex" gap={1}>
      <AssessmentSelector
        label="Select Assessment By Date To Compare"
        options={assessmentsWithAttachments}
        onSelectAssessment={setAssessmentToCompare}
        onSelectImage={(link) =>
          setImage({
            ...image,
            compareWith: { fileName: link.fileName, downloadLink: link.downloadLink }
          })
        }
        selectedAssessment={assessmentToCompare}
        selectedImage={image.compareWith}
      />
      <CompareArrowsIcon sx={{ alignSelf: "center" }} />
      <AssessmentSelector
        isForCurrentAssessment
        label="Current Assessment"
        options={[data]}
        onSelectImage={(link) =>
          setImage({
            ...image,
            current: { fileName: link.fileName, downloadLink: link.downloadLink }
          })
        }
        selectedAssessment={data}
        selectedImage={image.current}
      />
    </Box>
  </Box>
);

const ValidImageFormats = ["png", "jpg", "jpeg", "bmp"];

export const isValidImageFormat = (fileName: string): boolean => {
  const ext = fileName.split(".").pop()?.toLowerCase();
  return !!ext && ValidImageFormats.includes(ext);
};

export const getOpdsToCompare = (currentOpds: Assessment, opds: Assessment[]): Assessment[] =>
  opds
    .map((item) => ({
      ...item,
      attachments: item.attachments.filter((attachment) => isValidImageFormat(attachment.fileName))
    }))
    .filter(
      (item) =>
        item.id !== currentOpds.id &&
        new Date(currentOpds.createdDate) > new Date(item.createdDate) &&
        item.attachments?.length > 0
    );

const Compare = ({ data, clientId }: Props): JSX.Element => {
  const assessmentLabels = useAssessmentLabel();
  const [assessmentsWithAttachments, setAssessmentsWithAttachments] = React.useState<Assessment[]>(
    []
  );
  const [isFullScreenView, setIsFullScreenView] = React.useState(false);
  const [assessmentToCompare, setAssessmentToCompare] = React.useState<Assessment>();
  const [image, setImage] = React.useState({
    current: { fileName: "", downloadLink: "" },
    compareWith: { fileName: "", downloadLink: "" }
  });
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (clientId) {
      (async () => {
        try {
          const res = await getAssessments({ clientId, limit: 10 });
          const opds = getOpdsToCompare(data, res);
          setAssessmentsWithAttachments(opds);
          if (opds.length) {
            setAssessmentToCompare(opds[0]);
            setImage({
              ...image,
              compareWith: {
                fileName: opds[0].attachments[0].fileName,
                downloadLink: opds[0].attachments[0].downloadLink
              }
            });
          }
        } catch (e) {
          dispatch(
            addNotification({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: errorFetchMessage(MODULE.ASSESSMENT),
              autoTimeout: true
            })
          );
        }
      })();
    }
  }, [clientId]);

  React.useEffect(() => {
    if (data?.attachments?.length > 0 && isValidImageFormat(data.attachments[0].fileName)) {
      setImage({
        ...image,
        current: {
          fileName: data.attachments[0].fileName,
          downloadLink: data.attachments[0].downloadLink
        }
      });
    }
  }, [data?.attachments]);

  return (
    <div>
      <Box className={styles.assessmentRow}>
        <Box className={styles.assessmentLabel}>
          <Typography component="span" fontWeight="600">
            {assessmentLabels.compare}
          </Typography>
        </Box>
        <Box className={styles.assessmentField}>
          {!assessmentToCompare ? (
            <Box display="flex" gap={1}>
              <InfoIcon color="info" />
              <Typography>
                There are no previous assessments with attachments to compare.
              </Typography>
            </Box>
          ) : (
            <Box display="flex" justifyContent="space-between" alignItems="flex-start">
              <Comparision
                assessmentToCompare={assessmentToCompare}
                assessmentsWithAttachments={assessmentsWithAttachments}
                setAssessmentToCompare={setAssessmentToCompare}
                image={image}
                setImage={setImage}
                data={data}
              />
              <Tooltip arrow title="Enter full screen view">
                <IconButton onClick={() => setIsFullScreenView(!isFullScreenView)}>
                  <FullscreenIcon />
                </IconButton>
              </Tooltip>
            </Box>
          )}
        </Box>
      </Box>
      {isFullScreenView && (
        <Box
          position="absolute"
          top={0}
          bottom={0}
          right={0}
          left={0}
          bgcolor="white"
          zIndex={9999999}
        >
          <Box display="flex" gap={2} alignItems="flex-start" p={4}>
            <Comparision
              assessmentToCompare={assessmentToCompare}
              assessmentsWithAttachments={assessmentsWithAttachments}
              setAssessmentToCompare={setAssessmentToCompare}
              image={image}
              setImage={setImage}
              data={data}
            />
            <Tooltip arrow title="Exit full screen view">
              <IconButton onClick={() => setIsFullScreenView(!isFullScreenView)}>
                <FullscreenExitIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Box>
      )}
    </div>
  );
};

export default Compare;
