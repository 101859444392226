import React from "react";
import startCase from "lodash/startCase";
import { Box, Divider, Grid2 as Grid, Typography } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { acceptStatement, ConsentData, StyledTypography } from "./ConsentCreateEditForm";

const ConsentFormContent = ({ data }: { data: ConsentData }): JSX.Element => {
  const { signature, allergies, currentMedications, medicalConditions, isAccepted } = data;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2
      }}
    >
      <div>
        <StyledTypography text="Do you have any of the problems mentioned below ?" />
        <Grid container columnSpacing={7} rowSpacing={1}>
          {Object.keys(medicalConditions).map((item) => {
            if (item === "others") return null;
            return (
              <Grid
                item
                xs={6}
                key={item}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  mt: 1
                }}
              >
                <Typography>{startCase(item)}</Typography>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "3.25rem",
                      justifyContent: "flex-end"
                    }}
                  >
                    {medicalConditions[item] && <CheckIcon sx={{ mr: 1 }} />}
                    Yes
                  </Typography>
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "3.25rem",
                      justifyContent: "flex-end"
                    }}
                  >
                    {!medicalConditions[item] && <CloseIcon sx={{ mr: 1 }} />}
                    No
                  </Typography>
                </Box>
              </Grid>
            );
          })}
          <Grid size={12} mt={1}>
            <Typography whiteSpace="pre" gutterBottom>
              Other health issues:
            </Typography>
            <Typography whiteSpace="pre">{medicalConditions.others || "None"}</Typography>
          </Grid>
        </Grid>
      </div>
      <div>
        <StyledTypography text="Are you currently taking any medications ?" />
        <Typography whiteSpace="pre" mt={1}>
          {currentMedications || "None"}
        </Typography>
      </div>
      <div>
        <StyledTypography text="Are you allergic to any medications ?" />
        <Typography whiteSpace="pre" my={1}>
          {allergies || "None"}
        </Typography>
      </div>
      <Box display="flex" gap={1}>
        {isAccepted && <CheckIcon />}
        <Typography>{` ${acceptStatement}`}</Typography>
      </Box>
      <div>
        {signature ? (
          <img src={signature} alt="patients signature" height={100} width={100} />
        ) : (
          <Box height={100} width={100} />
        )}

        <Divider sx={{ width: "100px", my: 1 }} />
        <Typography>Patient&apos;s Signature</Typography>
      </div>
    </Box>
  );
};

export default ConsentFormContent;
