import React from "react";
import { push } from "connected-react-router";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import moment from "moment";
import {
  Box,
  Checkbox,
  CircularProgress,
  FormControlLabel,
  FormGroup,
  IconButton,
  Menu,
  MenuItem,
  Radio,
  Typography
} from "@mui/material";
import { Booking } from "../../interfaces/BookingInterfaces";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { BookingStatus } from "../Bookings/ManageBooking";
import { spFullNameSelector } from "../../reducers/serviceProvider";
import { PermissionGroups } from "../../interfaces/User";
import EmptyListIcon from "../../../assets/icons/EmptyListIcon";
import { tl } from "../../components/translate";

export interface SummaryInterface {
  handled: number;
  reserved: number;
  cancelled: number;
  empty: number;
  referred: number;
  confirmed: number;
  engaged: number;
  waiting: number;
  total: number;
  upComing: number;
}

const isUnhandled = (status: BookingStatus) => ![BookingStatus.cancelled].includes(status);

const isServiceProvider = (permissionGroup: PermissionGroups) =>
  [PermissionGroups.ServiceProviderLimited, PermissionGroups.ServiceProviderFull].includes(
    permissionGroup
  );

const hasBookings = (summary: SummaryInterface): boolean =>
  summary && Object.values(summary).some(Boolean);

const Appointments = ({
  bookings,
  selectedDepartmentId,
  selectedDate,
  isBookingFetching
}: {
  bookings: Array<Booking>;
  selectedDepartmentId: number | null;
  selectedDate: moment.Moment;
  isBookingFetching: boolean;
}): React.ReactElement | null => {
  const { permissionGroup, authenticableId, serviceProviders } = useAppSelector((state) => ({
    permissionGroup: state?.userContext.userCreds.userGroups[0],
    authenticableId: state?.userContext.userCreds,
    serviceProviders: state?.resources.resourceCentreServiceProviders
  }));
  const dispatch = useAppDispatch();
  const [sortBy, setSortBy] = React.useState("none");
  const [showFollowUpsOnly, setShowFollowUpsOnly] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const activeCurrentSps = (serviceProviders || []).filter(
    (sp) => (isServiceProvider(permissionGroup) ? sp.id === authenticableId : true) && sp.active
  );

  const summary = React.useMemo(() => {
    const currentData = showFollowUpsOnly ? bookings.filter((b) => b.followUp) : bookings;

    return [...currentData]
      .filter((book) => {
        if (selectedDepartmentId) {
          return book.departmentId === selectedDepartmentId;
        }
        return true;
      })
      .reduce((acc, { id, serviceProviderId, status, start }) => {
        const spSummary = acc[serviceProviderId] || {
          handled: 0,
          total: 0,
          upComing: 0,
          spId: serviceProviderId,
          spName: spFullNameSelector(
            activeCurrentSps.filter((item) => item.id === serviceProviderId)[0]
          ),
          id
        };
        spSummary[status || "reserved"] += 1;
        spSummary.total += 1;

        if (moment(start) > moment() && isUnhandled(status)) {
          spSummary.upComing += 1;
        }

        return {
          ...acc,
          [serviceProviderId]: spSummary
        };
      }, {});
  }, [showFollowUpsOnly, bookings, selectedDepartmentId, activeCurrentSps]);

  const summaryList = Object.values(summary);

  return (
    <Box
      sx={{
        background: "#fff",
        boxShadow: "0px 4px 20px rgba(0, 150, 84, 0.12)",
        borderRadius: "8px",
        height: "100%",
        minHeight: "25rem"
      }}
    >
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography sx={{ p: 2, fontSize: 20, fontWeight: 700 }}>Appointments</Typography>

        <FormGroup
          sx={{
            marginLeft: "auto",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center"
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={showFollowUpsOnly}
                onChange={() => setShowFollowUpsOnly(!showFollowUpsOnly)}
              />
            }
            label={<Typography>{tl("dashboard.showFollowUpOnly")}</Typography>}
          />
        </FormGroup>

        <IconButton onClick={handleClick} size="small">
          <MoreVertIcon />
        </IconButton>

        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right"
          }}
        >
          <MenuItem
            onClick={() => {
              handleClose();
              setSortBy("none");
            }}
          >
            <FormControlLabel
              label="Sort by none"
              control={<Radio size="small" checked={sortBy === "none"} />}
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              setSortBy("asc");
            }}
          >
            <FormControlLabel
              label="Sort by Doctor (Asc)"
              control={<Radio size="small" checked={sortBy === "asc"} />}
            />
          </MenuItem>
          <MenuItem
            onClick={() => {
              handleClose();
              setSortBy("desc");
            }}
          >
            <FormControlLabel
              label="Sort by Doctor (Desc)"
              control={<Radio size="small" checked={sortBy === "desc"} />}
            />
          </MenuItem>
        </Menu>
      </Box>
      <Box height="100%">
        {summaryList.length ? (
          summaryList
            .sort((a, b) => {
              if (sortBy === "none") {
                return a.total > b.total ? -1 : 1;
              }
              if (sortBy === "asc") {
                return a.spName.localeCompare(b.spName);
              }
              return b.spName.localeCompare(a.spName);
            })
            .map(({ handled, total, upComing, spId, spName, id }) => {
              if (!hasBookings(summary[spId])) return null;
              return (
                <Box
                  onClick={() =>
                    dispatch(
                      push(
                        `/calendar/?serviceProviderId=${spId}&date=${selectedDate.format(
                          "YYYY-MM-DD"
                        )}`
                      )
                    )
                  }
                  key={id}
                  sx={{
                    borderBottom: "1px solid #e5e5e5",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    px: 3,
                    py: 1,
                    "&:hover": {
                      backgroundColor: "#F1FFF3",
                      cursor: "pointer"
                    }
                  }}
                >
                  <Typography>{spName}</Typography>
                  <div>
                    <Typography
                      variant="h5"
                      textAlign="right"
                      gutterBottom
                    >{`${total} Total`}</Typography>
                    <Box display="flex" gap={4}>
                      <Typography
                        sx={{ color: "#009654" }}
                        fontSize={12}
                      >{`${handled} Handled`}</Typography>
                      <Typography
                        sx={{ color: "#5b5959" }}
                        fontSize={12}
                      >{`${upComing} Remaining`}</Typography>
                    </Box>
                  </div>
                </Box>
              );
            })
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            height="80%"
            flexDirection="column"
            gap={1}
          >
            {isBookingFetching ? (
              <CircularProgress />
            ) : (
              <>
                <EmptyListIcon />
                <Typography>
                  {selectedDate.isSame(moment(), "day")
                    ? "No appointments today"
                    : "No appointments"}
                </Typography>
              </>
            )}
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default Appointments;
