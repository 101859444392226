import { TextField } from "@mui/material";
import React from "react";

interface ipdInputField {
  label: string;
  onChange: (e) => void;
  placeholder: string;
  testmationLabel: string;
}

const IpdInputField: React.FC<ipdInputField> = ({
  label,
  onChange,
  placeholder,
  testmationLabel
}) => (
  <TextField
    data-testmation={testmationLabel}
    variant="outlined"
    label={label}
    multiline
    size="small"
    rows={3}
    placeholder={`${placeholder}`}
    fullWidth
    slotProps={{
      inputLabel: { shrink: true }
    }}
    onChange={onChange}
  />
);

export default IpdInputField;
