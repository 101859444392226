import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Typography
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React from "react";
import produce from "immer";
import { EditorState } from "prosemirror-state";
import { tl } from "../../../components/translate";
import Can from "../../Policy/Can";
import { RichTextfield } from "../../../components/RichTextfield";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import AssessmentLabels from "./AssessmentLabels";
import AssessmentVitals from "./AssessmentVitals";

interface AssessmentSettingsProps {
  resourceCentre: ResourceCentre;
  onChange: (rc: ResourceCentre) => void;
  assessmentRemarksState: EditorState;
  setAssessmentRemarksState: (value: EditorState) => void;
}

export default function AssessmentSettings({
  resourceCentre,
  onChange,
  assessmentRemarksState,
  setAssessmentRemarksState
}: AssessmentSettingsProps): JSX.Element {
  return (
    <Box>
      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Print
        </AccordionSummary>
        <AccordionDetails>
          <Box pl="16px">
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={
                      resourceCentre.settings.printSettings.assessmentPrintSettings
                        .includeLetterhead
                    }
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.assessmentPrintSettings.includeLetterhead =
                          !resourceCentre.settings.printSettings.assessmentPrintSettings
                            .includeLetterhead;
                      });
                      onChange(newRc);
                    }}
                  />
                }
                label={tl("settings.includeLetterhead")}
              />
            </Box>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={
                      resourceCentre.settings.printSettings.assessmentPrintSettings.includeFooter
                    }
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.assessmentPrintSettings.includeFooter =
                          !resourceCentre.settings.printSettings.assessmentPrintSettings
                            .includeFooter;
                      });
                      onChange(newRc);
                    }}
                  />
                }
                label={tl("settings.includeFooter")}
              />
              {!resourceCentre.settings.printSettings.assessmentPrintSettings.includeFooter && (
                <Can policyAccessKey="deny" superAdminPass>
                  <FormControlLabel
                    control={
                      <Checkbox
                        size="small"
                        checked={
                          resourceCentre.settings.printSettings.assessmentPrintSettings
                            .hideFooterLogoCompletely
                        }
                        onChange={() => {
                          const newRc = produce(resourceCentre, (draft) => {
                            draft.settings.printSettings.assessmentPrintSettings.hideFooterLogoCompletely =
                              !resourceCentre.settings.printSettings.assessmentPrintSettings
                                .hideFooterLogoCompletely;
                          });
                          onChange(newRc);
                        }}
                      />
                    }
                    label={tl("settings.hideFooterCompletely")}
                  />
                </Can>
              )}
            </Box>
            <Box display="flex" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={
                      resourceCentre.settings.printSettings.assessmentPrintSettings
                        .showAssessmentRemarks
                    }
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.assessmentPrintSettings.showAssessmentRemarks =
                          !draft.settings.printSettings.assessmentPrintSettings
                            .showAssessmentRemarks;
                      });
                      onChange(newRc);
                    }}
                  />
                }
                label={tl("settings.showAssessmentRemarks")}
              />
            </Box>
            {resourceCentre.settings.printSettings.assessmentPrintSettings
              .showAssessmentRemarks && (
              <Box display="flex" mt="16px" flexWrap="wrap">
                <Typography pr={2}>{tl("settings.assessmentRemarks")}</Typography>
                <Box width="400px">
                  <RichTextfield
                    state={assessmentRemarksState}
                    setState={setAssessmentRemarksState}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Manage Components
        </AccordionSummary>
        <AccordionDetails>
          <AssessmentLabels resourceCentre={resourceCentre} />
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          Manage Vitals
        </AccordionSummary>
        <AccordionDetails>
          <AssessmentVitals resourceCentre={resourceCentre} />
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}
