import * as React from "react";
import cloneDeep from "lodash/cloneDeep";
import differenceBy from "lodash/differenceBy";
import { Box, TextField, InputAdornment, Typography } from "@mui/material";
import Close from "@mui/icons-material/Close";
import Autocomplete from "@mui/material/Autocomplete";
import { spFullNameSelector } from "../../reducers/serviceProvider";
import { ServiceProviderRate } from "../../interfaces/ProductInterface";

interface RateRowInterface {
  serviceProviders: [];
  usedServiceProviders: any;
  rate:
    | ServiceProviderRate
    | {
        serviceProviderId: string;
        rate: string;
      };
  onChange: (data) => void;
  onRemove: () => void;
  serviceProviderRateUnit: string;
}

const RateRow: React.FC<RateRowInterface> = (props) => {
  const {
    serviceProviders = [],
    usedServiceProviders = [],
    rate,
    onChange,
    onRemove,
    serviceProviderRateUnit = ""
  } = props;
  const serviceProvidersMap = React.useMemo(() => {
    const usedSP = usedServiceProviders.reduce((acc, spId) => {
      if (rate.serviceProviderId !== spId) {
        acc.push({ id: spId });
      }
      return acc;
    }, []);
    const filteredServiceProviders = differenceBy(serviceProviders, usedSP, "id");
    return filteredServiceProviders.map((sp) => ({
      label: spFullNameSelector(sp),
      value: sp.id
    }));
  }, [serviceProviders, usedServiceProviders]);

  return (
    <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
      <Box width="50%">
        <Autocomplete
          id="combo-box"
          options={serviceProvidersMap}
          getOptionLabel={(option) => option.label}
          renderOption={(renderProps, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...renderProps} key={option.value}>
              {option.label}
            </li>
          )}
          data-testmation="productCreateSPDropdown"
          value={serviceProvidersMap.find(({ value }) => value === rate.serviceProviderId)}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              margin="dense"
              fullWidth
              variant="outlined"
              label="Service Provider"
            />
          )}
          onChange={(e, v) => {
            const newRate = cloneDeep(rate);
            newRate.serviceProviderId = v?.value;
            onChange(newRate);
          }}
        />
      </Box>
      <Box width="20%" />
      <Box width="20%">
        <TextField
          margin="dense"
          fullWidth
          type="number"
          data-testmation="productCreateSPRate"
          slotProps={{
            input:
              serviceProviderRateUnit === "rupee"
                ? {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography>Rs.</Typography>
                      </InputAdornment>
                    )
                  }
                : {
                    endAdornment: (
                      <InputAdornment position="start">
                        <Typography>%</Typography>
                      </InputAdornment>
                    )
                  }
          }}
          value={rate.rate}
          onChange={(e) => {
            const newRate = cloneDeep(rate);
            newRate.rate = e.target.value;
            onChange(newRate);
          }}
          variant="outlined"
          label="Rate"
        />
      </Box>
      <Box width="10%" textAlign="right" marginTop="5px" style={{ cursor: "pointer" }}>
        <Close data-testmation="productCreateSPRemove" onClick={() => onRemove()} />
      </Box>
    </Box>
  );
};

export default RateRow;
