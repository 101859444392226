import produce from "immer";
import React, { JSX } from "react";
import UploadIcon from "@mui/icons-material/Upload";
import { Box, Button, Typography } from "@mui/material";

import { useAppDispatch } from "../../../store/hooks";
import CustomHeader from "../../Lab/LabPrint/CustomHeader";
import ImageCrop from "../../../components/ImageCrop/ImageCrop";
import { notificationAdd } from "../../../actions/notification";
import { resourceCentreActions as actions } from "../../../actions";
import { customHeaderImagePresignedS3 } from "../../../api/resourceCentre";
import useCurrentResourceCentre from "../../../hooks/useCurrentResourceCentre";

const MedicalLabHeader = ({
  fileUploaderRef,
  onImageChange,
  isCropperOpen,
  setIsCropperOpen,
  tempImg
}: {
  fileUploaderRef: React.RefObject<HTMLInputElement>;
  onImageChange: (e) => void;
  isCropperOpen: boolean;
  setIsCropperOpen: (data) => void;
  tempImg: Blob | null;
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const resourceCentre = useCurrentResourceCentre();
  const { medicalSettings } = resourceCentre.settings;

  return (
    <Box>
      <Box sx={{ mb: 2, display: "flex", flexDirection: "column" }}>
        <Typography sx={{ fontWeight: 700 }}>Custom Print Header (894px * 112px)</Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          This image will appear at the top of all medical reports
        </Typography>
      </Box>
      {medicalSettings.customHeaderImage && (
        <CustomHeader imageUrl={medicalSettings.customHeaderImage} />
      )}

      {!medicalSettings.customHeaderImage && (
        <div>
          <input
            className="imageUpload"
            data-testmation="uploadBannerInput"
            type="file"
            accept=".jpg,.jpeg,.gif,.png"
            ref={fileUploaderRef}
            name="fileUploader"
            onChange={onImageChange}
            id="fileUploader"
            style={{ display: "none" }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "112px",
              border: "1px dashed",
              borderColor: "divider",
              borderRadius: 1,
              backgroundColor: "action.hover"
            }}
          >
            <Button
              variant="outlined"
              onClick={(e) => {
                e.preventDefault();
                fileUploaderRef.current.click();
              }}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "8px"
              }}
            >
              <UploadIcon sx={{ height: 16, width: 16 }} />
              Upload Custom Header
            </Button>
          </Box>
        </div>
      )}

      {medicalSettings.customHeaderImage && (
        <Button
          variant="contained"
          sx={{ backgroundColor: "red" }}
          onClick={() => {
            const newRc = produce(resourceCentre, (draft) => {
              draft.settings.medicalSettings.customHeaderImage = "";
            });
            dispatch(actions.putResourceCentre(newRc));
          }}
        >
          Remove Header Image
        </Button>
      )}

      {/* crop image */}
      <ImageCrop
        image={tempImg}
        dynamicRatio={false}
        defaultRatio={{ x: 8, y: 1 }}
        isOpen={isCropperOpen}
        onClose={() => setIsCropperOpen(false)}
        onSave={async (img) => {
          try {
            const data = await customHeaderImagePresignedS3(img);
            if (!data) {
              return;
            }
            const newRc = produce(resourceCentre, (draft) => {
              draft.settings.medicalSettings.customHeaderImage = data;
            });
            dispatch(actions.putResourceCentre(newRc));
          } catch (err) {
            dispatch(
              notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: "Failed to upload custom header",
                autoTimeout: true
              })
            );
          }
        }}
      />
    </Box>
  );
};

export default MedicalLabHeader;
