import moment from "moment";
import React, { JSX } from "react";
import { capitalize } from "lodash";
import { QRCodeSVG } from "qrcode.react";
import { Box, Grid, Typography } from "@mui/material";
import { LabRecord } from "../../../../interfaces/Lab";
import { scaleFont } from "../../../../components/Print/Print";
import {
  AuthorizedSpInfo,
  RenderNamedBlockTestGroup,
  SubRow,
  VaccinationSubRow
} from "./DefaultPrintComponents";
import { getTestReportBaseUrl } from "../../../../helpers/urls";

// bordered info field
export const BorderedInfoField = ({
  label,
  data,
  labelStyle = {},
  valueStyle = {},
  containerStyle = {}
}: {
  label: JSX.Element | string;
  data: React.ReactNode;
  labelStyle?: React.CSSProperties;
  valueStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
}): JSX.Element | null => {
  const baseLabelStyle = {
    fontSize: scaleFont("0.28cm", 1),
    color: "black",
    fontWeight: 400,
    width: "140px",
    padding: "0 5px"
  };
  const baseValueStyle = {
    width: "100%",
    fontSize: scaleFont("0.28cm", 1),
    fontWeight: 500,
    padding: "0 5px"
  };

  return (
    <Box sx={{ display: "flex" }} style={{ ...containerStyle }}>
      <Typography sx={{ ...baseLabelStyle, ...labelStyle }}>{label}</Typography>
      <Typography sx={{ ...baseValueStyle, ...valueStyle }}>{data}</Typography>
    </Box>
  );
};

// bordered client info div
export const BorderedClientInfoDiv = ({ labRecord }: { labRecord: LabRecord }): JSX.Element => {
  const { client } = labRecord;
  const clientName = `${capitalize(client?.firstName)} ${capitalize(client?.lastName)}`;
  const getFormattedDateInAD = (date: string): string => `${moment(date).format("YYYY-MM-DD")}`;

  const gender = (() => {
    if (client?.gender === "1") {
      return "m";
    }
    if (client?.gender === "2") {
      return "f";
    }
    return "-";
  })();

  // some info are commented out as we are only trying to recreate template from provided sample
  // and that sample does not have all the fields, but if we want to add them, we can uncomment them

  return (
    <Grid container sx={{ border: "1px solid black", width: "100%" }}>
      <Grid item xs={4}>
        <Box sx={{ borderRight: "1px solid black" }}>
          {/* <BorderedInfoField
            label="S.N."
            data={labRecord.labIdentifier || ""}
            labelStyle={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}
            valueStyle={{ borderBottom: "1px solid black" }}
          /> */}
          <BorderedInfoField
            label="Name:"
            data={clientName || "-"}
            labelStyle={{
              width: "88px",
              borderRight: "1px solid black",
              borderBottom: "1px solid black"
            }}
            valueStyle={{
              borderBottom: "1px solid black",
              fontWeight: "700"
            }}
          />
          <BorderedInfoField
            label="Age:"
            data={client.dob ? moment().diff(client.dob, "years") : "-"}
            labelStyle={{
              width: "88px",
              borderRight: "1px solid black",
              borderBottom: "1px solid black"
            }}
            valueStyle={{ borderBottom: "1px solid black" }}
          />
          <BorderedInfoField
            label="Sex:"
            data={gender || "-"}
            labelStyle={{ width: "88px", borderRight: "1px solid black" }}
          />
          {/* <BorderedInfoField
            label="Address:"
            data={client.address}
            labelStyle={{
              borderRight: "1px solid black",
              borderBottom: showBarCode ? "1px solid black" : "none"
            }}
            valueStyle={{ borderBottom: showBarCode ? "1px solid black" : "none" }}
          /> */}

          {/* {showBarCode && (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <BorderedInfoField
                label="Test ID:"
                data={labRecord.id}
                labelStyle={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}
                valueStyle={{ borderBottom: "1px solid black" }}
              />
              <Barcode
                value={String(labRecord.id)}
                width={2}
                displayValue={false}
                fontSize={5}
                height={20}
                margin={2}
                font="arial"
              />
            </Box>
          )} */}
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Box sx={{ borderRight: "1px solid black" }}>
          {/* <BorderedInfoField
            label="Marital Status:"
            data={client.maritalStatus}
            labelStyle={{
              width: "180px",
              borderRight: "1px solid black",
              borderBottom: "1px solid black"
            }}
            valueStyle={{ borderBottom: "1px solid black" }}
          /> */}

          <BorderedInfoField
            label="Exam date:"
            data={
              getFormattedDateInAD(labRecord?.results?.examDate || new Date().toISOString()) || "-"
            }
            labelStyle={{
              width: "180px",
              borderRight: "1px solid black",
              borderBottom: "1px solid black"
            }}
            valueStyle={{ borderBottom: "1px solid black" }}
          />

          <BorderedInfoField
            label="Applied to:"
            data={client?.appliedCountry || "-"}
            labelStyle={{
              width: "180px",
              borderRight: "1px solid black",
              borderBottom: "1px solid black"
            }}
            valueStyle={{ borderBottom: "1px solid black", fontWeight: "700" }}
          />

          <BorderedInfoField
            label="Nationality:"
            data={client?.nationality || "-"}
            labelStyle={{
              width: "180px",
              borderRight: "1px solid black"
            }}
          />

          {/* {showReferrer && (
            <BorderedInfoField
              label="Recruiting agency:"
              data={labRecord.referrers || "-"}
              labelStyle={{
                width: "180px",
                borderRight: "1px solid black",
                borderBottom: "1px solid black"
              }}
              valueStyle={{ borderBottom: "1px solid black" }}
            />
          )} */}
        </Box>
      </Grid>

      <Grid item xs={4}>
        <Box>
          <BorderedInfoField
            label="Passport no:"
            data={(client?.passportInfo && client?.passportInfo?.passportNumber) || "-"}
            labelStyle={{
              width: "280px",
              borderRight: "1px solid black",
              borderBottom: "1px solid black"
            }}
            valueStyle={{ borderBottom: "1px solid black" }}
          />

          <BorderedInfoField
            label="Passport issue date:"
            data={moment(client.passportInfo?.issueDate).format("YYYY-MM-DD") || "-"}
            labelStyle={{
              width: "280px",
              borderRight: "1px solid black",
              borderBottom: "1px solid black"
            }}
            valueStyle={{ borderBottom: "1px solid black" }}
          />

          <BorderedInfoField
            label="Passport issue place:"
            data={client?.passportInfo?.issuingAuthority || "-"}
            labelStyle={{
              width: "280px",
              borderRight: "1px solid black"
            }}
          />

          {/* <BorderedInfoField
            label="Passport expiration date:"
            data={moment(client.passportInfo?.expiryDate).format("YYYY-MM-DD") || "-"}
            labelStyle={{
              width: "280px",
              borderRight: "1px solid black",
              borderBottom: "1px solid black"
            }}
            valueStyle={{ borderBottom: "1px solid black" }}
          /> */}
        </Box>
      </Grid>
    </Grid>
  );
};

// bordered client vitals
export const BorderedClientVitalsDiv = ({
  labRecord,
  recentAssessment
}: {
  labRecord: LabRecord;
  recentAssessment: Record<string, any>;
}): JSX.Element => {
  const vitalsObj: any = {};

  (labRecord?.results?.vitals || recentAssessment?.vitals)?.forEach((vital) => {
    vitalsObj[(vital.name || "").replace(/\s/g, "").toLowerCase()] = vital;
  });

  const {
    height,
    bloodpressure,
    clubbing,
    weight,
    jaundice,
    oedema,
    pulse,
    pallor,
    ascites,
    temperature,
    cyanosis,
    lymphnode
  } = vitalsObj;

  const showVital = (vital) => {
    if (vital) return `${vital.reading} ${vital.unit && vital.unit}`;
    return "-";
  };

  return (
    <Grid container sx={{ border: "1px solid black" }}>
      <Grid item xs={3}>
        <Box sx={{ borderRight: "1px solid black" }}>
          <BorderedInfoField
            label="Height"
            data={showVital(height)}
            labelStyle={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}
            valueStyle={{ borderBottom: "1px solid black" }}
          />
          <BorderedInfoField
            label="BP"
            data={showVital(bloodpressure)}
            labelStyle={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}
            valueStyle={{ borderBottom: "1px solid black" }}
          />
          <BorderedInfoField
            label="Clubbing"
            data={showVital(clubbing)}
            labelStyle={{ borderRight: "1px solid black" }}
          />
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box sx={{ borderRight: "1px solid black" }}>
          <BorderedInfoField
            label="Weight"
            data={showVital(weight)}
            labelStyle={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}
            valueStyle={{ borderBottom: "1px solid black" }}
          />
          <BorderedInfoField
            label="Jaundice"
            data={showVital(jaundice)}
            labelStyle={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}
            valueStyle={{ borderBottom: "1px solid black" }}
          />
          <BorderedInfoField
            label="Oedema"
            data={showVital(oedema)}
            labelStyle={{ borderRight: "1px solid black" }}
          />
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box sx={{ borderRight: "1px solid black" }}>
          <BorderedInfoField
            label="Pulse"
            data={showVital(pulse)}
            labelStyle={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}
            valueStyle={{ borderBottom: "1px solid black" }}
          />
          <BorderedInfoField
            label="Pallor"
            data={showVital(pallor)}
            labelStyle={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}
            valueStyle={{ borderBottom: "1px solid black" }}
          />
          <BorderedInfoField
            label="Ascities"
            data={showVital(ascites)}
            labelStyle={{ borderRight: "1px solid black" }}
          />
        </Box>
      </Grid>

      <Grid item xs={3}>
        <Box>
          <BorderedInfoField
            label="Temperature"
            data={showVital(temperature)}
            labelStyle={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}
            valueStyle={{ borderBottom: "1px solid black" }}
          />
          <BorderedInfoField
            label="Cyanosis"
            data={showVital(cyanosis)}
            labelStyle={{ borderRight: "1px solid black", borderBottom: "1px solid black" }}
            valueStyle={{ borderBottom: "1px solid black" }}
          />
          <BorderedInfoField
            label="Lymphnode"
            data={showVital(lymphnode)}
            labelStyle={{ borderRight: "1px solid black" }}
          />
        </Box>
      </Grid>
    </Grid>
  );
};

// client illness div
export const BorderedClientIllnessesDiv = ({
  recentAssessment
}: {
  recentAssessment: any;
}): JSX.Element => (
  <Box>
    {recentAssessment && (
      <>
        <Typography
          sx={{
            padding: "2px 8px",
            marginBottom: "4px",
            fontSize: scaleFont("0.28cm"),
            fontWeight: 600,
            textTransform: "uppercase",
            whiteSpace: "nowrap",
            background: "black",
            color: "white",
            width: "fit-content"
          }}
        >
          General Examination
        </Typography>

        <div>
          <Typography sx={{ fontSize: "0.28cm", fontWeight: 600, width: "100%" }}>
            Past history of serious illness, major surgery and significant psychological problem
            including (Epilepsy and Depression):{" "}
            <span style={{ fontWeight: 400 }}>
              {recentAssessment?.hopi && recentAssessment.hopi}
            </span>
          </Typography>
        </div>
        <div>
          <Typography sx={{ fontSize: "0.28cm", fontWeight: 600 }}>
            Past history of allergy :{" "}
            <span style={{ fontWeight: 400 }}>
              {recentAssessment?.pastHistoryOfAllergy && recentAssessment.pastHistoryOfAllergy}
            </span>
          </Typography>
        </div>
      </>
    )}
  </Box>
);

// bordered main data display div
export const BorderedMainLabData = ({
  labRecord,
  recentAssessment,
  showQrCode,
  showMedicalDisclaimerOnFooter
}: {
  labRecord: LabRecord;
  recentAssessment: any;
  showQrCode: boolean;
  showMedicalDisclaimerOnFooter?: boolean;
}): JSX.Element => {
  const { client } = labRecord;

  const getClientGender = (clientGender) => {
    if (clientGender === "1") {
      return "m";
    }
    if (clientGender === "2") {
      return "f";
    }
    return "-";
  };

  const gender = getClientGender(client.gender);
  const { authorizedSP1, authorizedSP2 } = labRecord?.results?.approvedBy || {};

  // eslint-disable-next-line no-nested-ternary
  const greeting = gender === "m" ? "Sir" : gender === "f" ? "Madam" : "Sir/Madam";
  const clientName = `${capitalize(client.firstName)} ${capitalize(client.lastName)}`;
  const labTests = (labRecord.results?.data.length && labRecord.results.data[0]?.labTests) || [];
  const getRequiredCategoryTests = (cName) => labTests.filter((item) => item.category === cName);

  const [
    systemeticGroup,
    hematologyGroup,
    bioChemGroup,
    urineGroup,
    serologyGroup,
    radiologyGroup,
    specialGroup,
    others
  ] = [
    "Systemic",
    "Haematology",
    "Biochemistry",
    "Urine Examination",
    "Serology",
    "Radiology",
    "Special",
    "Others"
  ].map((item) => getRequiredCategoryTests(item));
  const clinicalImpression = specialGroup.filter((item) => item.name === "Clinical impression");
  radiologyGroup.push(clinicalImpression[0]);

  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ width: "100%", display: "flex" }}>
        <Box sx={{ width: "50%" }}>
          <Typography
            sx={{
              padding: "2px 8px",
              fontSize: scaleFont("0.28cm"),
              fontWeight: 600,
              textTransform: "uppercase",
              whiteSpace: "nowrap",
              background: "black",
              color: "white",
              width: "fit-content"
            }}
          >
            Systemic Examination
          </Typography>
        </Box>

        <Box sx={{ width: "50%" }}>
          <Typography
            sx={{
              padding: "2px 8px",
              fontSize: scaleFont("0.28cm"),
              fontWeight: 600,
              textTransform: "uppercase",
              whiteSpace: "nowrap",
              background: "black",
              color: "white",
              width: "fit-content"
            }}
          >
            Laboratory Examination
          </Typography>
        </Box>
      </Box>

      <Box sx={{ display: "flex", border: "2px solid black" }}>
        {/* systemic examination and signatures */}
        <Box sx={{ width: "50%", padding: "8px", borderRight: "2px solid black" }}>
          <Box>
            <Box sx={{ display: "flex" }}>
              <Typography sx={{ width: "75%", fontWeight: 700, fontSize: "0.28cm" }}>
                Type of Medical Examination
              </Typography>
              <Typography sx={{ width: "25%", fontWeight: 700, fontSize: "0.28cm" }}>
                Findings
              </Typography>
            </Box>
            <Box>
              {systemeticGroup.map((lt, index, array) => {
                const isLastGroup = index === array.length - 1;
                const isLast = isLastGroup && lt.subTests?.length === 0;
                return (
                  <SubRow
                    key={lt.id}
                    lt={lt}
                    showRanges={false}
                    isLast={isLast}
                    gender={client.gender}
                  >
                    {lt.subTests?.map((sT, i, arr) => (
                      <Box key={sT.id}>
                        <SubRow
                          lt={sT}
                          isChild
                          showRanges={false}
                          isLast={isLastGroup && i === arr.length - 1}
                          gender={client.gender}
                        />
                      </Box>
                    ))}
                  </SubRow>
                );
              })}
            </Box>

            <Typography sx={{ fontWeight: 600, fontSize: "0.28cm", marginTop: "0.2cm" }}>
              RADIOLOGY
            </Typography>
            <Box>
              {radiologyGroup.map((lt, index, array) => (
                <SubRow
                  key={lt.id}
                  lt={lt}
                  showRanges={false}
                  isLast={index === array.length - 1}
                  gender={client.gender}
                >
                  {lt.subTests?.map((sT, i, arr) => (
                    <SubRow
                      key={sT.id}
                      lt={sT}
                      isChild
                      showRanges={false}
                      isLast={i === arr.length - 1}
                      gender={client.gender}
                    />
                  ))}
                </SubRow>
              ))}
            </Box>

            {labRecord?.results?.vaccinations?.length > 0 && (
              <>
                <Typography sx={{ fontWeight: 600, fontSize: "0.28cm", marginTop: "0.2cm" }}>
                  VACCINATION STATUS
                </Typography>

                <Box>
                  {labRecord?.results?.vaccinations?.map((vaccination, index, array) => (
                    <VaccinationSubRow
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                      vaccination={vaccination}
                      isLast={index === array.length - 1}
                    />
                  ))}
                </Box>
              </>
            )}
          </Box>

          <Box sx={{ marginTop: "0.25cm" }}>
            <Typography sx={{ fontSize: "0.3cm" }}>Dear {greeting},</Typography>
            <Typography sx={{ fontSize: "0.3cm", width: "100%" }}>
              The above mentioned medical report of {clientName} is{" "}
              <b>{recentAssessment.presentHealthStatus}</b> for the mentioned job.
            </Typography>
          </Box>

          <Box sx={{ marginTop: "0.25cm" }}>
            {showQrCode && (
              <QRCodeSVG size={70} value={`${getTestReportBaseUrl()}/${labRecord.uuid}`} />
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
              gap: "8px"
            }}
          >
            <Typography
              sx={{
                fontSize: "0.28cm",
                fontWeight: 600,
                flex: 1,
                textAlign: "center"
              }}
            >
              (Official Stamp)
            </Typography>

            {authorizedSP1 && (
              <Box sx={{ flex: 1, textAlign: "center" }}>
                <AuthorizedSpInfo sp={authorizedSP1} />
              </Box>
            )}
            {authorizedSP2 && (
              <Box sx={{ flex: 1, textAlign: "center" }}>
                <AuthorizedSpInfo sp={authorizedSP2} />
              </Box>
            )}
          </Box>
        </Box>

        {/* lab examinations */}
        <Box
          sx={{
            width: "50%",
            padding: "8px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "8px"
          }}
        >
          <Box sx={{ display: "flex", flexDirection: "column", gap: "4px" }}>
            <Box sx={{ width: "100%", display: "flex", paddingLeft: "30px" }}>
              <Typography sx={{ width: "50%", fontWeight: 700, fontSize: "0.28cm" }}>
                Examination
              </Typography>
              <Typography sx={{ width: "25%", fontWeight: 700, fontSize: "0.28cm" }}>
                Reading
              </Typography>
              <Typography sx={{ width: "25%", fontWeight: 700, fontSize: "0.28cm" }}>
                Range
              </Typography>
            </Box>
            <RenderNamedBlockTestGroup
              blockName="Hematology"
              testGroupData={hematologyGroup}
              gender={client.gender}
              namePlacement="left"
            />
            <RenderNamedBlockTestGroup
              blockName="Biochemistry"
              testGroupData={bioChemGroup}
              gender={client.gender}
              namePlacement="left"
            />
            <RenderNamedBlockTestGroup
              blockName="Serology"
              testGroupData={serologyGroup}
              gender={client.gender}
              namePlacement="left"
            />
            <RenderNamedBlockTestGroup
              blockName="Urine"
              testGroupData={urineGroup}
              gender={client.gender}
              namePlacement="left"
            />
            <RenderNamedBlockTestGroup
              blockName="Others"
              testGroupData={others}
              gender={client.gender}
              namePlacement="left"
            />
          </Box>
          {!showMedicalDisclaimerOnFooter && (
            <Typography
              sx={{
                fontSize: "0.25cm",
                backgroundColor: "#ededed",
                padding: "0.15cm"
              }}
            >
              Recommended by Ministry of Health and Population, Government of Nepal. This report is
              valid for two months from the date of Medical Examination.
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};
