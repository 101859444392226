import { Box, Button, FormControlLabel, Switch, TextField, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import Delete from "@mui/icons-material/Delete";
import moment from "moment";
import {
  postOnDemandProduct,
  patchOnDemandProduct,
  deleteOnDemandProduct
} from "../../actions/onDemandProducts";
import Panel from "../../components/Panel";
import { ListItemState as SelectedItemState } from "./index";
import ServiceTagsSelect from "./ServiceTagsSelect";
import { IServiceTag } from "../../interfaces/ServiceTagsInterface";

const { useState, useEffect } = React;

interface Props {
  mode: "create" | "edit";
  state?: SelectedItemState;
  handleClose: () => void;
  postOnDemandProduct: (data) => void;
  patchOnDemandProduct: (data, id) => void;
  deleteOnDemandProduct: (id) => void;
  serviceTags: Array<IServiceTag>;
}

type State = Omit<SelectedItemState, "id">;

function CreatePanel(props: Props) {
  const {
    mode,
    state: selectedItemState,
    handleClose,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    postOnDemandProduct,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    patchOnDemandProduct,
    // eslint-disable-next-line @typescript-eslint/no-shadow
    deleteOnDemandProduct,
    serviceTags
  } = props;

  const [formState, setFormState] = useState<State>({
    grossTotalPrice: null,
    discount: "0",
    serviceTagId: null,
    active: true,
    serviceTagName: "",
    validDays: null
  });

  useEffect(() => {
    if (mode === "edit") {
      setFormState(selectedItemState);
    }
  }, [selectedItemState]);

  const createButton = (
    <Button
      onClick={() => {
        postOnDemandProduct(formState);
        handleClose();
      }}
      variant="contained"
      color="primary"
      disabled={
        !formState.serviceTagName ||
        !formState.discount ||
        !formState.grossTotalPrice ||
        !formState.serviceTagId ||
        !formState.validDays
      }
    >
      Create
    </Button>
  );
  const updateButton = (
    <Button
      onClick={() => {
        patchOnDemandProduct(formState, selectedItemState.id);
        handleClose();
      }}
      variant="contained"
      color="primary"
      disabled={
        !formState.serviceTagName ||
        !formState.discount ||
        !formState.grossTotalPrice ||
        !formState.serviceTagId ||
        !formState.validDays
      }
    >
      Update
    </Button>
  );

  return (
    <Panel
      onClose={handleClose}
      title={mode === "edit" ? "Edit On Demand Product" : "Create On Demand Product"}
      deleteButton={
        mode === "edit" && (
          <Delete
            onClick={() => {
              deleteOnDemandProduct(selectedItemState.id);
              handleClose();
            }}
            style={{ cursor: "pointer" }}
          />
        )
      }
    >
      <Box height="calc(100vh - 100px)" p={5}>
        <Box>
          <TextField
            variant="outlined"
            label="Gross Total Price"
            type="number"
            value={formState.grossTotalPrice}
            onChange={(e) => setFormState({ ...formState, grossTotalPrice: e.target.value })}
            slotProps={{
              input: {
                startAdornment: <Box mr="8px">Rs</Box>
              }
            }}
            margin="dense"
          />
        </Box>
        <Box mt="8px">
          <TextField
            variant="outlined"
            label="Discount"
            type="number"
            onChange={(e) => setFormState({ ...formState, discount: e.target.value })}
            value={formState.discount}
            slotProps={{
              input: {
                startAdornment: <Box mr="8px">Rs</Box>
              }
            }}
            margin="dense"
          />
        </Box>
        <Box mt="8px" display="flex">
          <ServiceTagsSelect
            value={formState.serviceTagId}
            onChange={(serviceTagId, serviceTagName) => {
              setFormState({ ...formState, serviceTagId, serviceTagName });
            }}
          />
          {formState.serviceTagId && (
            <img
              src={serviceTags.find((item) => formState.serviceTagId === item.id)?.details?.iconKey}
              style={{
                width: "52px",
                height: "52px",
                objectFit: "cover",
                marginLeft: "32px",
                border: "1px solid lightgrey"
              }}
              alt="service-tag-icon"
            />
          )}
        </Box>
        {formState.serviceTagId && (
          <Typography style={{ fontSize: "0.75rem" }}>
            Service Tag ID : {formState.serviceTagId}
          </Typography>
        )}
        <Box mt="8px" display="flex" alignItems="center">
          <TextField
            variant="outlined"
            label="Valid Days"
            type="number"
            onChange={(e) => setFormState({ ...formState, validDays: e.target.value })}
            style={{ width: "180px" }}
            value={formState.validDays}
            slotProps={{
              input: {
                endAdornment: <Box ml="8px">Days</Box>
              }
            }}
            margin="dense"
          />
          {selectedItemState && (
            <Typography style={{ fontSize: "0.75rem", marginLeft: "32px" }}>
              Created at {moment(selectedItemState.created_at).format("MMMM Do YYYY")}
            </Typography>
          )}
        </Box>
        <Box mt="32px">
          <FormControlLabel
            control={
              <Switch
                checked={formState.active}
                onChange={(e) => setFormState({ ...formState, active: e.target.checked })}
                color="primary"
              />
            }
            label="Active"
          />
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" mr="16px">
        <Button onClick={handleClose}>Cancel</Button>
        <Box ml="8px">{mode === "edit" ? updateButton : createButton}</Box>
      </Box>
    </Panel>
  );
}

const mapStateToProps = (state) => ({ serviceTags: state.serviceTags.collection });

export default connect(mapStateToProps, {
  postOnDemandProduct,
  patchOnDemandProduct,
  deleteOnDemandProduct
})(CreatePanel);
