import {
  Button,
  ButtonGroup,
  Menu as MuiMenu,
  MenuItem as MuiMenuItem,
  Link,
  Typography
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Box from "@mui/material/Box";
import { push } from "connected-react-router";
import { startCase } from "lodash";
import moment from "moment";
import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { match as matchInterface, useLocation } from "react-router";
import { serviceProviderActions } from "../../actions";
import * as clientActions from "../../actions/client";
import * as serviceActions from "../../actions/services";
import { batchUploadClient } from "../../api/client";
import {
  clientUploadSchema,
  sampleClientUploadData
} from "../../components/CsvUploader/ClientUploadSchema";
import CsvUploader, { MenuItem } from "../../components/CsvUploader/CsvUploader";
import OkhatiDialog from "../../components/Dialog/Dialog";
import List, { Filters } from "../../components/List";
import { ListActions, Menu } from "../../components/OList";
import PageControl from "../../components/PageControl";
import { t, tl } from "../../components/translate";
import { downloadCSV } from "../../helpers/files";
import { ageFormatter } from "../../helpers/formatters";
import { ReverseMap } from "../../helpers/types";
import useDebounce from "../../hooks/useDebounce";
import useMobileScreen from "../../hooks/useMobileScreen";
import useQueryParams from "../../hooks/useQueryParams";
import { Client } from "../../interfaces/ClientInterface";
import { clientFullNameSelector } from "../../reducers/client";
import { getCurrentSubscription } from "../../slices/subscriptionSlice";
import { IThunkDispatch, RootState } from "../../store";
import Can from "../Policy/Can";
import ClientCreateEdit from "./ClientCreateEdit";
import ClientInfo from "./ClientInfo/ClientInfo";
import "./ClientList.scss";
import style from "./style.module.css";
import useAssociateCompanies from "../../hooks/useAssociateCompanies";
import ClientSearchComponents, {
  CLIENT_SEARCH_BY,
  ClientSearchByType
} from "./ClientSearchComponents";

interface QueryTypes {
  q: string;
  filter: string;
  subFilter?: string;
  page: number;
  pageSize: number;
  searchBy: ClientSearchByType;
}

interface ClientListInterface {
  actions: {
    goto: (url: string) => void;
    loadClients: (query?: QueryTypes) => Promise<void>;
    loadFullClient: (id) => void;
    loadServiceProviders: () => void;
    loadServices: () => void;
  };
  clients: Array<Client>;
  mode?: string;
  clientId?: string | number;
  client?: Client;
  match?: matchInterface<{ [x: string]: string | undefined }>;
  totalClients: number;
  isCorporateClient?: boolean;
}

enum ClientFilterType {
  ALL = "all",
  VISITED_TODAY = "visitedToday",
  VISITED_LAST_SEVEN_DAYS = "visitedLastSevenDays",
  VISITED_LAST_180_DAYS = "visitedLast180Days"
}

enum ClientVisitedTodayFilterType {
  NEW = "new",
  OLD = "old"
}

const visitedTodayfilterData = {
  filters: [
    { key: ClientVisitedTodayFilterType.NEW, title: "New" },
    { key: ClientVisitedTodayFilterType.OLD, title: "Old" }
  ]
};

type ClientFilter = ReverseMap<typeof ClientFilterType>;
export const CLIENT_BATCH_UPLOAD_LIMIT = 1000;
const pageSize = 100;
const pageLimit = 9;

export const getCustomerNumber = (number: string): string =>
  number && number.length < 5 ? number.padStart(5, "0") : number || "";

export const ClientList = ({
  actions,
  clients,
  mode,
  clientId,
  client,
  match,
  totalClients,
  isCorporateClient
}: ClientListInterface): JSX.Element => {
  const query = useQueryParams();
  const filterParam = query.get("filter") as ClientFilterType;
  const subFilterParam = query.get("subFilter") as ClientVisitedTodayFilterType;
  const [selectedClient, setSelectedClient] = React.useState<Client | null>(null);
  const [filter, setFilter] = React.useState<ClientFilter>(() =>
    Object.values(ClientFilterType).indexOf(filterParam) !== -1 ? filterParam : ClientFilterType.ALL
  );
  const [subFilter, setSubFilter] = React.useState<ClientVisitedTodayFilterType>(() =>
    Object.values(ClientVisitedTodayFilterType).indexOf(subFilterParam) !== -1
      ? subFilterParam
      : ClientVisitedTodayFilterType.NEW
  );
  const [loading, setLoading] = React.useState<boolean>(false);
  const [debounceLoading, setDebounceLoading] = React.useState<boolean>(false);
  const currentSubscription = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription
  );
  const resourceCentre = useSelector((state: RootState) => state.userContext.resourceCentre);
  const dispatch = useDispatch();
  const clientsMemo: Client[] = React.useMemo(
    () =>
      (clients || []).map((draft) => ({
        ...draft,
        fullName: [draft.firstName, draft.lastName].join(" ")
      })),
    [clients]
  );
  const [page, setPage] = React.useState<number>(0);
  const [isPageChanged, setIsPageChanged] = React.useState(false);
  const [text, setText] = React.useState<string>("");
  const [showWarningModal, setShowWarningModal] = React.useState(false);
  const [searchBy, setSearchBy] = React.useState<ClientSearchByType>(CLIENT_SEARCH_BY.NAME);
  const location = useLocation<{ search: string }>();

  const delay = isPageChanged ? 300 : 0;
  const dependencies = React.useMemo(
    () => ({
      delay,
      page,
      text,
      filter,
      searchBy
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [page, text, filter, searchBy]
  );

  const debouncedCallback = React.useCallback(async () => {
    if (!mode) {
      await actions.loadClients({ q: text, filter, page, pageSize, searchBy, subFilter });
      setLoading(false);
      setIsPageChanged(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text, filter, page, mode, searchBy, subFilter]);

  useDebounce(dependencies, debouncedCallback);

  React.useEffect(() => {
    const cId = match.params.id;
    if (cId && clients.length > 0) {
      let queryClient;
      if (cId.length === 12) {
        queryClient = clients.find((c) => c.uniqueId === cId);
      } else {
        queryClient = clients.find((c) => c.id === Number(cId));
        if (!queryClient) {
          actions.loadFullClient(cId);
        }
      }
      setSelectedClient(queryClient);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match.params.id, clients]);

  React.useEffect(() => {
    if (mode === "read" && clientId && client) {
      if (client.active) {
        setSelectedClient(client);
      }
    } else if (clientId && !client) actions.loadClients();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, mode]);

  const handleViewClose = () => {
    setSelectedClient(null);
    actions.goto(`/clients${location.search || ""}`);
  };

  React.useEffect(() => {
    if (!currentSubscription) {
      dispatch(getCurrentSubscription(resourceCentre.id));
    }
  }, [currentSubscription, dispatch, resourceCentre.id]);

  useAssociateCompanies();

  const filterData = {
    filters: [
      { key: ClientFilterType.ALL, title: tl(`client.filter.all`) },
      { key: ClientFilterType.VISITED_TODAY, title: tl(`client.filter.visitedToday`) },
      {
        key: ClientFilterType.VISITED_LAST_SEVEN_DAYS,
        title: tl(`client.filter.visitedLastSevenDays`)
      },
      { key: ClientFilterType.VISITED_LAST_180_DAYS, title: tl(`client.filter.visitedLast180Days`) }
    ]
  };

  const isMobileScreen = useMobileScreen();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };

  const dropdownMenuItems = (
    <ButtonGroup
      color="primary"
      variant="contained"
      ref={anchorEl}
      aria-label="split button"
      style={{ marginLeft: "20px" }}
    >
      <Button
        onClick={() => {
          handleDropdownClose();
          actions.goto(`/clients/new${location.search || ""}`);
        }}
        data-testmation="createClient"
      >
        {isMobileScreen ? tl("create") : tl("clients.createClient")}
      </Button>
      <Button onClick={handleDropdownClick} size="small" data-testmation="clientListCreateDropdown">
        <ArrowDropDownIcon />
      </Button>
      <MuiMenu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleDropdownClose}
      >
        <MuiMenuItem
          data-testmation="createCorporateClient"
          onClick={() => {
            handleDropdownClose();
            actions.goto(`/clients/new/corporate${location.search || ""}`);
          }}
        >
          Create Corporate Client
        </MuiMenuItem>
      </MuiMenu>
    </ButtonGroup>
  );

  return (
    <Can policyAccessKey="clients:listClient">
      <OkhatiDialog
        title="Warning"
        description="Client list is limited to 10 pages only. Please try searching from the search box."
        readMode={false}
        open={showWarningModal}
        next={() => setShowWarningModal(false)}
        cancel={() => setShowWarningModal(false)}
      />
      <div className={`clientList ${style.root}`}>
        <Box height="calc(100% - 44px)">
          <Box display="flex" mt={2} mx={2}>
            <Box flex={1}>
              <Filters
                filter={filter}
                onFilter={(newFilter) => {
                  const newSubFilter =
                    newFilter === ClientFilterType.VISITED_TODAY
                      ? ClientVisitedTodayFilterType.NEW
                      : null;
                  setFilter(newFilter);
                  dispatch(
                    push(
                      `/clients?filter=${newFilter}${
                        newSubFilter ? `&subFilter=${newSubFilter}` : ""
                      }`
                    )
                  );
                }}
                filterData={filterData}
              />
            </Box>
            <Can policyAccessKey="clients:searchClient">
              <ClientSearchComponents
                value={text}
                loading={loading}
                debounceLoading={debounceLoading}
                onDebounceLoadingChange={(value) => setDebounceLoading(value)}
                onInputClear={() => setText("")}
                onSearchByChange={(value) => {
                  setSearchBy(value);
                  setPage(0);
                }}
                onInputChange={(value) => {
                  if (value?.length > 0) {
                    setLoading(true);
                    setText(value);
                    setPage(0);
                  }
                }}
              />
            </Can>
            {dropdownMenuItems}
          </Box>
          {(location?.search || "").includes(ClientFilterType.VISITED_TODAY) && (
            <Box pl={2} flex={1}>
              <Filters
                filter={subFilter}
                onFilter={(newFilter) => {
                  setSubFilter(newFilter);
                  dispatch(push(`/clients?filter=${filter}&subFilter=${newFilter}`));
                }}
                filterData={visitedTodayfilterData}
              />
            </Box>
          )}
          <List
            testmationLabel="createClient"
            automation="ClientList"
            createDropdownLabel={isMobileScreen ? "Create" : tl("clients.createClient")}
            hideCreateButton
            rowHeight={50}
            isResponsive
            withoutSearch
            columns={[
              {
                key: "name",
                label: tl("clients.name"),
                sortable: true,
                sortFunction: (a, b) =>
                  `${a.firstName}${a.lastName}`.toLowerCase() >
                  `${b.firstName}${b.lastName}`.toLowerCase()
                    ? 1
                    : -1,
                formatter: (row) => (
                  <Typography
                    component="div"
                    sx={{
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start"
                    }}
                    title={startCase(clientFullNameSelector(row))}
                  >
                    <Box>{clientFullNameSelector(row).toUpperCase()}</Box>
                    <Box fontSize="small" color="gray">
                      {getCustomerNumber(row.customerNumber)}
                      {row.externalIdentifier
                        ? ` (${t("labPrint.customerIdentifier")}: ${row.externalIdentifier})`
                        : ""}
                      {!!row.registrationNo && ` (Reg. No: ${row.registrationNo})`}
                    </Box>
                  </Typography>
                )
              },
              {
                key: "dob",
                label: tl("clients.age"),
                formatter: ({ dob }) => (
                  <Typography className="textEllipsis" title={ageFormatter(dob)}>
                    {ageFormatter(dob)}
                  </Typography>
                ),
                hideInNarrowView: true
              },
              {
                key: "gender",
                label: tl("clients.gender"),
                formatter: (c) => (
                  <Typography>{c.gender ? tl(`clients.gender.${c.gender}`) : ""}</Typography>
                ),
                hideInNarrowView: true
              },
              {
                key: "email",
                label: tl("clients.email"),
                formatter: (c) => (
                  <Typography className="textEllipsis" title={c.email}>
                    {c.email}
                  </Typography>
                ),
                hideInNarrowView: true
              },
              {
                key: "internalNotes",
                label: `${t("clients.InternalNotes")}`,
                sortable: true,
                formatter: ({ internalNotes }) => (
                  <Typography className="textEllipsis" title={internalNotes}>
                    {internalNotes}
                  </Typography>
                ),
                hideInNarrowView: true
              },
              {
                key: "isCorporateClient",
                label: tl("clients.isCorporate"),
                sortable: true,
                formatter: (row) => <Typography>{row.isCorporateClient ? "Yes" : "No"}</Typography>,
                hideInNarrowView: true
              },
              {
                key: "phone",
                label: tl("clients.phone"),
                formatter: (c) => (
                  <Typography className="textEllipsis" title={c.phone}>
                    {c.phone}
                  </Typography>
                )
              },
              {
                key: "created_at",
                label: tl("clients.created_at"),
                sortable: true,
                sortFunction: (a, b) =>
                  +new Date(a.created_at) < +new Date(b.created_at) ? 1 : -1,
                formatter: (c) => (
                  <Typography>{moment(c.created_at).format("YYYY-MM-DD")}</Typography>
                ),
                hideInNarrowView: true
              },
              {
                key: "lastVisitedAt",
                label: tl("clients.lastVisitedAt"),
                sortable: true,
                sortFunction: (a, b) =>
                  +new Date(a.lastVisited_at) < +new Date(b.lastVisited_at) ? 1 : -1,
                formatter: (c) => (
                  <Typography>
                    {c.lastVisitedAt ? moment(c.lastVisitedAt).format("YYYY-MM-DD") : "-"}
                  </Typography>
                ),
                hideInNarrowView: true
              },
              {
                key: "customerNumber",
                label: tl("customerNumber"),
                alwaysHidden: true,
                sortable: true,
                sortFunction: (a, b) => {
                  if (a.customerNumber && b.customerNumber) {
                    return a.customerNumber > b.customerNumber ? 1 : -1;
                  }
                  return `${a.customerNumber}`.toLowerCase() > `${b.customerNumber}`.toLowerCase()
                    ? -1
                    : 1;
                }
              }
            ]}
            data={clientsMemo || []}
            isLoading={!clients}
            emptyViewContent={
              <div>
                No items to show. The clients shown here are created in past 30 days. <br /> If you
                want to view older clients, Please{" "}
                <Link onClick={() => dispatch(push("/reports/patient/clientReport"))}>
                  visit Client Report.
                </Link>
              </div>
            }
            activeRow={selectedClient && selectedClient.id}
            onRowClick={async (c: Client) => {
              const notSelected = !selectedClient || selectedClient.id !== c.id;
              setSelectedClient(notSelected ? c : null);
              actions.goto(
                notSelected
                  ? `/clients/${c.id}${location.search || ""}`
                  : `/clients${location.search || ""}`
              );
            }}
          >
            <ListActions>
              {() => (
                <Menu>
                  <CsvUploader
                    buttonText="Upload clients (.csv)"
                    columns={clientUploadSchema}
                    requiredFieldInfoText="Note: First name, Last Name and Phone number are mandatory information for importing."
                    createDataApi={batchUploadClient}
                    useBatchUpload
                    limit={CLIENT_BATCH_UPLOAD_LIMIT}
                    maxUploadLimitText={`Maximum upload limit for clients is ${CLIENT_BATCH_UPLOAD_LIMIT}.`}
                    runAfterSave={() => actions.loadClients()}
                    renderAdditionalInfo={() => (
                      <Typography
                        sx={{
                          cursor: "pointer",
                          position: "fixed",
                          right: 20
                        }}
                      >
                        <Link
                          onClick={() => {
                            downloadCSV(
                              "Clients Upload Sample",
                              sampleClientUploadData.data,
                              sampleClientUploadData.headers,
                              (row) => row
                            );
                          }}
                        >
                          View Sample
                        </Link>
                      </Typography>
                    )}
                  />
                  <MenuItem
                    onClick={() =>
                      window.open(
                        "https://docs.google.com/spreadsheets/d/1z5QsYWEaX4ifouIOzsatXcahu_t6BKDZ",
                        "_blank"
                      )
                    }
                  >
                    {tl("viewSample")}
                  </MenuItem>
                </Menu>
              )}
            </ListActions>
          </List>
        </Box>
        <Box className="navigatePage">
          <PageControl
            page={page}
            pageSize={pageSize}
            onSetPage={(v) => {
              if (v > pageLimit) {
                setShowWarningModal(true);
                return;
              }
              setPage(v);
              setIsPageChanged(true);
            }}
            maximumDataCount={totalClients}
            allowNextAtEnd
            endAlertMessage="The clients shown here are created in past 30 days. If you want to view older clients, Please visit Client Report."
            pushTo="reports/patient/clientReport"
          />
        </Box>

        {selectedClient && <ClientInfo id={selectedClient.id} handleViewClose={handleViewClose} />}
        {mode && ["create", "edit"].includes(mode) && (
          <ClientCreateEdit
            clientId={clientId}
            mode={mode}
            onCancel={() => actions.goto(`/clients${location.search || ""}`)}
            showLastCreated={() => actions.goto("/clients")}
            isCorporateClient={isCorporateClient || Boolean(selectedClient?.isCorporateClient)}
          />
        )}
      </div>
    </Can>
  );
};

ClientList.defaultProps = {
  mode: undefined,
  clientId: undefined,
  client: undefined,
  match: undefined
};

export default connect(
  (state: RootState, { clientId }: { clientId?: number }) => ({
    clients: state.clients.collection,
    client: state.clients.collection?.find(({ id }) => id === Number(clientId)),
    totalClients: state.clients.total
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      loadServiceProviders: () =>
        dispatch(serviceProviderActions.getResourceCentreServiceProviders({})),
      loadServices: () => dispatch(serviceActions.getServices()),
      goto: (url) => dispatch(push(url)),
      loadClients: (query) => dispatch(clientActions.getClients(query)),
      loadFullClient: (id) => dispatch(clientActions.getClientById(id))
    }
  })
)(ClientList);
