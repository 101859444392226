import React from "react";
import produce from "immer";
import { Box, Typography, TextField, Button, Autocomplete } from "@mui/material";
import { connect, useDispatch } from "react-redux";
import DebouncedTextField from "../../../components/DebouncedTextField";
import { getHmisFormData } from "../../../actions/hmis";
import { postGenerateTestReport } from "../../../api/hmis";
import * as NotificationActions from "../../../actions/notification";
import { commonErrorMessage } from "../../../helpers/messages";

const HmisSettings = ({ state, actions, getFormData, formData }) => {
  React.useEffect(() => {
    getFormData();
  }, []);
  const dispatch = useDispatch();

  const formDataDetails = state.settings?.hmisSettings.hmisFormDataIds
    .map((id) => formData.find((item) => item.id === id))
    .filter(Boolean);

  const generateTestReport = () => {
    try {
      postGenerateTestReport(state.id);
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Report Generated Successfully",
          autoTimeout: true
        })
      );
    } catch (error) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: error?.data?.message || commonErrorMessage,
          autoTimeout: true
        })
      );
    }
  };

  return (
    <>
      <Box ml="10px" width="100%" display="flex" alignItems="center">
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <Box display="flex" alignItems="center" my={1}>
              <Typography>Username</Typography>
              <DebouncedTextField
                variant="outlined"
                label="Username"
                placeholder="Enter username"
                slotProps={{
                  inputLabel: { shrink: true }
                }}
                style={{ marginLeft: "16px" }}
                value={state.settings.hmisSettings.username || ""}
                debounceAt={1000}
                onChange={(e) => {
                  const newRc = produce(state, (draft) => {
                    draft.settings.hmisSettings.username = e.target.value;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
            </Box>

            <Box display="flex" alignItems="center" my={1} ml={1}>
              <Typography>Password </Typography>
              <DebouncedTextField
                type="password"
                variant="outlined"
                label="Password"
                placeholder="Enter password"
                slotProps={{
                  inputLabel: { shrink: true }
                }}
                style={{ marginLeft: "16px" }}
                value={state.settings.hmisSettings.password || ""}
                debounceAt={1000}
                onChange={(e) => {
                  const newRc = produce(state, (draft) => {
                    draft.settings.hmisSettings.password = e.target.value;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
            </Box>
            <Box display="flex" alignItems="center" my={1} ml={1}>
              <Typography>OrgUnit</Typography>
              <DebouncedTextField
                style={{ marginLeft: "16px" }}
                variant="outlined"
                label="OrgUnit"
                placeholder="Enter org unit"
                slotProps={{
                  inputLabel: { shrink: true }
                }}
                value={state.settings.hmisSettings.orgUnit || ""}
                debounceAt={1000}
                onChange={(e) => {
                  const newRc = produce(state, (draft) => {
                    draft.settings.hmisSettings.orgUnit = e.target.value;
                  });
                  actions.putResourceCentre(newRc);
                }}
              />
            </Box>
          </Box>

          <Box display="flex" alignItems="center" my={2}>
            <Typography>Hmis Elements</Typography>
            <Autocomplete
              multiple
              id="tags-outlined"
              options={formData}
              getOptionLabel={(option) => option?.categoryName}
              filterSelectedOptions
              value={formDataDetails}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  variant="outlined"
                  label="Form Data"
                  placeholder="Type form data"
                  slotProps={{
                    inputLabel: { shrink: true }
                  }}
                  style={{ marginLeft: "16px", minWidth: "300px" }}
                />
              )}
              // value={formDataDetails}
              size="small"
              onChange={(_, e) => {
                const dataIds = e.map((el) => el?.id);
                const newRc = produce(state, (draft) => {
                  draft.settings.hmisSettings.hmisFormDataIds = dataIds;
                });
                actions.putResourceCentre(newRc);
              }}
            />
          </Box>
          <Box my={1}>
            <Button variant="contained" color="primary" onClick={() => generateTestReport()}>
              Generate Test Report
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => {
  const formData = state.hmis?.formData;
  return { formData };
};

const mapDispatchToProps = (dispatch) => ({
  getFormData: () => dispatch(getHmisFormData())
});
export default connect(mapStateToProps, mapDispatchToProps)(HmisSettings);
