import * as React from "react";
import { connect } from "react-redux";
import {
  Box,
  Typography,
  Button,
  TextField,
  Chip,
  Checkbox,
  Select,
  MenuItem
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import ScheduleIcon from "@mui/icons-material/Schedule";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import moment, { Moment } from "moment";
import AddBoxIcon from "@mui/icons-material/AddBox";
import produce from "immer";
import HomeIcon from "@mui/icons-material/Home";
import { Schedule } from "../../../interfaces/ScheduleInterface";
import { tl, t } from "../../../components/translate";
import IconWithField from "../../../components/IconWithField";
import ServiceProviderWithAvatar from "../../../components/ServiceProviderWithAvatar";
import Recurrence from "./Recurrence";
import Modal from "../../../components/Modal/Modal";
import styles from "./styles.module.css";
import { CalendarFunctions as calFns } from "../../../components/Calendar";
import { ProductInterface } from "../../../interfaces/ProductInterface";
import StrikeThroughPriceInput from "./StrikeThroughPriceInput";

interface SlotsInterface {
  serviceProvider: {
    firstName: string;
    lastName: string;
    speciality: string;
    publicBooking: boolean;
  };
  currentBookableResource: Array<any>;
  resourceCentre: Record<string, unknown>;
  schedule: Schedule & { onHome: boolean };
  services: Array<any>;
  onChange: (eventData) => void;
  onClose: () => void;
  onDelete: () => void;
  onShowBookingHistory: () => void;
  onSave: (slot: Schedule) => void;
  scheduleState: Schedule;
}

const slotDuration = [10, 15, 20, 30, 60];

const numAndDays = [
  { value: 0, label: "Sunday" },
  { value: 1, label: "Monday" },
  { value: 2, label: "Tuesday" },
  { value: 3, label: "Wednesday" },
  { value: 4, label: "Thursday" },
  { value: 5, label: "Friday" },
  { value: 6, label: "Saturday" }
];

const SlotCreateEdit: React.FC<SlotsInterface> = (props) => {
  const {
    serviceProvider,
    resourceCentre,
    services,
    schedule,
    currentBookableResource,
    onClose,
    onChange,
    onSave
  } = props;
  const [recurrencePopUp, setRecurrencePopUp] = React.useState(false);
  const [scheduleState, setScheduleState] = React.useState<Schedule>(schedule);

  React.useEffect(() => {
    if (schedule) {
      setScheduleState(schedule);
    }
  }, [schedule]);

  const [editMode, setEditMode] = React.useState(false);
  React.useEffect(() => {
    if (schedule.id) setEditMode(true);
  }, [schedule.id]);

  const [scheduleService, setScheduleService] = React.useState(null);
  const [videoService, setVideoService] = React.useState(null);

  React.useEffect(() => {
    const service = services.filter(
      (serv: { id: number }) => serv.id === scheduleState.consultServiceId
    );
    const filteredVideoService = services.filter(
      (item: { id: number }) => item.id === scheduleState.videoServiceId
    );
    setScheduleService(service[0]);
    setVideoService(filteredVideoService[0]);
  }, [scheduleState.consultServiceId, scheduleState.videoServiceId, services]);

  React.useEffect(() => {
    // sets videoServiceId/consultServiceId to null if video/onSite is unchecked
    if (!scheduleState.video) {
      setScheduleState({ ...scheduleState, videoServiceId: null, oVideoServicePrice: 0 });
    }
    if (!scheduleState.onSite) {
      setScheduleState({ ...scheduleState, consultServiceId: null, oConsultServicePrice: 0 });
    }
    if (scheduleState.video) {
      setScheduleState({ ...scheduleState, payLater: false });
    }
  }, [scheduleState.video, scheduleState.onSite]);

  const [repeat, setRepeat] = React.useState("");

  React.useEffect(() => {
    const dateNum = new Date(scheduleState.start).getDay().toString();

    if (!repeat) {
      setScheduleState({
        ...scheduleState,
        daysInWeek: [Number(new Date(scheduleState.start).getDay())],
        eEnd: scheduleState.end,
        eStart: scheduleState.start
      });
    } else if (!Number.isNaN(repeat)) {
      setScheduleState({
        ...scheduleState,
        daysInWeek: [Number(repeat)],
        eEnd: null
      });
    } else if (repeat === "weekdays") {
      setScheduleState({
        ...scheduleState,
        daysInWeek: [0, 1, 2, 3, 4, 5],
        eEnd: null
      });
    } else if (repeat === "custom") {
      setScheduleState({
        ...scheduleState,
        daysInWeek: [Number(dateNum)]
      });
      setRecurrencePopUp(true);
    }
  }, [repeat]);

  React.useEffect(() => {
    if (!repeat) {
      setScheduleState({
        ...scheduleState,
        eEnd: scheduleState.end,
        eStart: scheduleState.start,
        daysInWeek: [Number(new Date(scheduleState.start).getDay())]
      });
    }
  }, [scheduleState.end, scheduleState.start]);

  const [recProps, setRecProps] = React.useState({
    daysInWeek: null,
    eEnd: null
  });

  React.useEffect(() => {
    setRecProps({
      daysInWeek: scheduleState.daysInWeek,
      eEnd: moment(scheduleState.eEnd).add(1, "month").toDate()
    });
  }, [scheduleState]);

  const endTimeReconciliation = () => {
    const difference = moment(scheduleState.end).diff(scheduleState.start, "minutes");
    let { end } = scheduleState;
    const modulo = difference % scheduleState.duration;
    if (modulo !== 0) {
      end = moment(scheduleState.end)
        .add(scheduleState.duration - modulo, "minutes")
        .toDate();
    }
    setScheduleState({ ...scheduleState, end });
    onChange({ ...scheduleState, end });
  };

  const publicBookingSubscription = resourceCentre?.subscriptions.features.publicBooking.subscribed;

  const allowPublicBooking = (serviceId, publicBooking) => {
    if (!publicBooking) return true;
    if (!serviceId) return false;
    const service = services.find((s) => s.id === serviceId);
    if (service?.grossTotalPrice > 0) {
      return true;
    }
    return false;
  };

  if (!scheduleState) return null;

  return (
    <Box className={styles.scheduleCreateEdit} sx={{ p: 3 }}>
      <Box className={styles.createEditBody}>
        {scheduleState.bookableResource && (
          <Box sx={{ my: "16px", pl: "4px" }}>
            <IconWithField LabelIcon={<AddBoxIcon />}>
              <Typography style={{ fontSize: "14px", paddingLeft: "2px" }}>
                {scheduleState.bookableResource.name}
              </Typography>
            </IconWithField>
          </Box>
        )}
        <ServiceProviderWithAvatar
          serviceProvider={serviceProvider}
          onServiceProviderChange={(id) => {
            setScheduleState({ ...scheduleState, serviceProviderId: id });
          }}
        />
        <Box sx={{ pt: 3 }} />
        <IconWithField
          LabelIcon={<ScheduleIcon />}
          style={{
            display: "flex",
            marginBottom: "8px",
            minHeight: "32px"
          }}
          iconStyle={{
            alignItems: "flex-start"
          }}
        >
          <Box>
            <Box sx={{ height: "18px", mb: "8px", display: "flex", alignItems: "center" }}>
              <Box sx={{ width: "100px" }}>
                {scheduleState.start && (
                  <Typography style={{ fontSize: "12px" }}>
                    {calFns.convertADtoBS(scheduleState.start).formatted4}
                  </Typography>
                )}
              </Box>

              <TextField
                data-testmation="scheduleStartDate"
                id="time"
                type="time"
                margin="dense"
                size="small"
                value={moment(scheduleState.start).format("HH:mm")}
                slotProps={{
                  input: {
                    disableUnderline: true,
                    style: {
                      fontSize: "12px"
                    }
                  },
                  htmlInput: {
                    step: 600 // 5 min
                  },
                  inputLabel: { shrink: true }
                }}
                onBlur={() => {
                  endTimeReconciliation();
                }}
                onChange={({ target }) => {
                  const [hour, minute] = target.value.split(":").map(Number);
                  let date: Moment | Date = moment(scheduleState.start).set("hour", hour);
                  date = minute ? date.set("minute", minute) : date.set("minute", 0);
                  date = date.toDate();
                  setScheduleState({ ...scheduleState, start: date });
                  onChange({ ...scheduleState, start: date });
                }}
              />

              <Typography style={{ marginLeft: "8px", marginRight: "8px" }}>-</Typography>

              <TextField
                data-testmation="scheduleEndDate"
                id="time"
                type="time"
                margin="dense"
                size="small"
                value={moment(scheduleState.end).format("HH:mm")}
                slotProps={{
                  inputLabel: { shrink: true },
                  htmlInput: {
                    step: 600, // 5 min,
                    min: `${moment(scheduleState.start).format("HH:mm")}`
                  },
                  input: {
                    disableUnderline: true,
                    style: {
                      fontSize: "12px"
                    }
                  }
                }}
                onBlur={() => {
                  endTimeReconciliation();
                }}
                onChange={({ target }) => {
                  const [hour, minute] = target.value.split(":").map(Number);
                  let date: Moment | Date = moment(scheduleState.end).set("hour", hour);
                  date = minute ? date.set("minute", minute) : date.set("minute", 0);
                  date = date.toDate();

                  if (moment(scheduleState.start).isBefore(date)) {
                    setScheduleState({ ...scheduleState, end: date });
                    onChange({ ...scheduleState, end: date });
                  }
                }}
              />
            </Box>

            <Box sx={{ height: "18px", display: "flex", alignItems: "center" }}>
              <Box sx={{ width: "100px" }}>
                <Typography style={{ color: "gray", fontSize: "12px" }}>
                  {tl("slot.slotDuration")}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                <Select
                  data-testmation="scheduleDuration"
                  disableUnderline
                  value={scheduleState.duration}
                  onChange={(e) => {
                    const duration = Number(e.target.value);
                    const difference = moment(scheduleState.end).diff(
                      scheduleState.start,
                      "minutes"
                    );
                    let { end } = scheduleState;
                    const modulo = difference % duration;
                    if (modulo !== 0) {
                      end = moment(scheduleState.end)
                        .add(duration - modulo, "minutes")
                        .toDate();
                    }
                    setScheduleState({ ...scheduleState, duration, end });
                    onChange({ ...scheduleState, end });
                  }}
                  style={{ fontSize: "12px" }}
                >
                  {slotDuration.map((sd, idx) => (
                    <MenuItem
                      // eslint-disable-next-line react/no-array-index-key
                      key={idx}
                      value={sd}
                      sx={{ fontSize: "12px" }}
                      data-testmation={sd}
                    >{`${sd} minutes`}</MenuItem>
                  ))}
                </Select>
                <Select
                  disableUnderline
                  displayEmpty
                  value={repeat}
                  onChange={(e) => {
                    setRepeat(e.target.value.toString());
                  }}
                  sx={{ fontSize: "12px" }}
                >
                  <MenuItem value="" data-testmation="doesntRepeat">
                    <Typography sx={{ fontSize: "12px" }}>Doesn&apos; repeat</Typography>
                  </MenuItem>
                  <MenuItem
                    value={new Date(scheduleState.start).getDay()}
                    data-testmation="everyWeekToday"
                  >
                    <Typography sx={{ fontSize: "12px" }}>
                      {`Every week on ${new Date(scheduleState.start)
                        .toLocaleString("en-us", { weekday: "long" })
                        .toLocaleLowerCase()}`}
                    </Typography>
                  </MenuItem>
                  <MenuItem value="weekdays" data-testmation="everyWeekDays">
                    <Typography sx={{ fontSize: "12px" }}>Every week on weekdays</Typography>
                  </MenuItem>
                  <MenuItem value="custom" data-testmation="custom">
                    <Typography
                      sx={{ fontSize: "12px" }}
                      onClick={() => {
                        if (repeat === "custom") {
                          setRecurrencePopUp(true);
                        }
                      }}
                    >
                      {`${repeat === "custom" ? "Every week on " : "Custom"} ${
                        repeat === "custom"
                          ? scheduleState?.daysInWeek?.map((diw) => {
                              const filteredDay = numAndDays.filter((nad) => nad.value === diw);
                              return ` ${filteredDay[0].label}`;
                            })
                          : ""
                      } ${
                        repeat === "custom" &&
                        scheduleState &&
                        scheduleState.eEnd &&
                        scheduleState.eEnd.toISOString()
                          ? `stops on ${calFns.convertADtoBS(scheduleState.eEnd).formatted4}`
                          : ""
                      }`}
                    </Typography>
                  </MenuItem>
                </Select>
              </Box>
            </Box>
          </Box>
        </IconWithField>

        <IconWithField
          LabelIcon={<LocationOnIcon />}
          style={{
            display: "flex",
            marginBottom: "8px",
            minHeight: "32px"
          }}
          iconStyle={{
            alignItems: "flex-start"
          }}
        >
          {resourceCentre && (
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  height: "18px",
                  width: "100%",
                  mb: "8px",
                  display: "flex",
                  alignItems: "center"
                }}
              >
                <Typography sx={{ fontSize: "12px", minWidth: "30%" }}>
                  {`${t("slot.onSite")} (${resourceCentre.address})`}
                </Typography>
                <Checkbox
                  data-testmation="onSiteCheck"
                  checked={scheduleState.onSite}
                  onChange={() => {
                    if (serviceProvider) {
                      setScheduleState({
                        ...scheduleState,
                        onSite: !scheduleState.onSite,
                        video: scheduleState.onSite
                      });
                    } else {
                      setScheduleState({
                        ...scheduleState,
                        onSite: !scheduleState.onSite
                      });
                    }
                  }}
                />
              </Box>
              {scheduleState.onSite && (
                <>
                  <IconWithField
                    LabelIcon={<CardGiftcardIcon />}
                    style={{
                      display: "flex",
                      marginBottom: "8px",
                      minHeight: "18px"
                    }}
                    iconStyle={{
                      paddingTop: "8px"
                    }}
                  >
                    <Autocomplete
                      data-testmation="scheduleServicesSelect"
                      value={scheduleService || null}
                      options={
                        services.filter((item: { category: string }) => item.category !== "home") ||
                        []
                      }
                      getOptionLabel={(option) => option.name}
                      // eslint-disable-next-line @typescript-eslint/no-shadow
                      renderOption={(renderProps, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...renderProps} key={option.id}>
                          {option.name}
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          // eslint-disable-next-line react/jsx-key, react/jsx-props-no-spreading
                          <Chip label={option.name} size="small" {...getTagProps({ index })} />
                        ))
                      }
                      filterSelectedOptions
                      disableClearable
                      onChange={(e, v) => {
                        setScheduleState({
                          ...scheduleState,
                          consultServiceId: v.id,
                          videoServiceId: null
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          placeholder="Select onsite service"
                          margin="dense"
                          size="small"
                          slotProps={{
                            input: {
                              ...params.InputProps,
                              disableUnderline: true,
                              sx: {
                                padding: 0,
                                fontSize: "12px",
                                width: "180px"
                              }
                            }
                          }}
                        />
                      )}
                    />
                    {scheduleState.consultServiceId && (
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          marginLeft: "16px",
                          color: "grey",
                          marginTop: "8px"
                        }}
                      >
                        Rs{" "}
                        {
                          services.find((s: any) => scheduleState.consultServiceId === s.id)
                            ?.grossTotalPrice
                        }
                      </Typography>
                    )}
                  </IconWithField>
                  {scheduleState.consultServiceId && (
                    <StrikeThroughPriceInput
                      minValue={
                        services.find((s: any) => scheduleState.consultServiceId === s.id)
                          ?.grossTotalPrice
                      }
                      value={scheduleState.oConsultServicePrice}
                      onChange={(value) =>
                        setScheduleState({ ...scheduleState, oConsultServicePrice: value })
                      }
                    />
                  )}
                </>
              )}

              {serviceProvider && (
                <Box sx={{ height: "18px", display: "flex" }}>
                  <Typography sx={{ fontSize: "12px", minWidth: "30%" }}>
                    {tl("slot.onlineVideo")}
                  </Typography>
                  <Checkbox
                    data-testmation="onlineVideoCheck"
                    checked={scheduleState.video}
                    onChange={() => {
                      setScheduleState({
                        ...scheduleState,
                        video: !scheduleState.video,
                        onSite: scheduleState.video,
                        consultServiceId: null,
                        oConsultServicePrice: 0
                      });
                    }}
                  />
                </Box>
              )}

              {scheduleState.video && (
                <>
                  <IconWithField
                    LabelIcon={<CardGiftcardIcon />}
                    style={{
                      display: "flex",
                      marginBottom: "8px",
                      minHeight: "18px"
                    }}
                    iconStyle={{
                      paddingTop: "8px"
                    }}
                  >
                    <Autocomplete
                      data-testmation="scheduleServicesSelect"
                      value={videoService || null}
                      options={services.filter(
                        (item: { category: string }) => item.category === "video"
                      )}
                      getOptionLabel={(option) => option.name}
                      renderOption={(renderProps, option) => (
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        <li {...renderProps} key={option.id}>
                          {option.name}
                        </li>
                      )}
                      isOptionEqualToValue={(option, value) => option.name === value.name}
                      renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                          // eslint-disable-next-line react/jsx-key, react/jsx-props-no-spreading
                          <Chip label={option.name} size="small" {...getTagProps({ index })} />
                        ))
                      }
                      filterSelectedOptions
                      disableClearable
                      onChange={(e, v) => {
                        setScheduleState({
                          ...scheduleState,
                          videoServiceId: v.id,
                          homeServiceId: null,
                          consultServiceId: null,
                          oConsultServicePrice: 0
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          placeholder="Select video service"
                          margin="dense"
                          size="small"
                          slotProps={{
                            input: {
                              ...params.InputProps,
                              disableUnderline: true,
                              sx: {
                                padding: 0,
                                fontSize: "12px",
                                width: "180px"
                              }
                            }
                          }}
                          helperText={!scheduleState.videoServiceId && "Video service is required"}
                          error={!scheduleState.videoServiceId}
                        />
                      )}
                    />
                    {scheduleState.videoServiceId && (
                      <Typography
                        sx={{
                          fontSize: "0.75rem",
                          marginLeft: "16px",
                          color: "grey",
                          marginTop: "8px"
                        }}
                      >
                        Rs{" "}
                        {
                          services.find((s) => scheduleState.videoServiceId === s.id)
                            ?.grossTotalPrice
                        }
                      </Typography>
                    )}
                  </IconWithField>
                  {scheduleState.videoServiceId && (
                    <StrikeThroughPriceInput
                      minValue={
                        services.find((s) => scheduleState.videoServiceId === s.id)?.grossTotalPrice
                      }
                      value={scheduleState.oVideoServicePrice}
                      onChange={(value) =>
                        setScheduleState({ ...scheduleState, oVideoServicePrice: value })
                      }
                    />
                  )}
                </>
              )}

              {!serviceProvider && (
                <>
                  <Box
                    sx={{
                      height: "18px",
                      width: "100%",
                      mb: "8px",
                      display: "flex",
                      alignItems: "center"
                    }}
                  >
                    <Typography sx={{ fontSize: "12px", minWidth: "30%" }}>
                      {`${t("slot.atHome")}`}
                    </Typography>
                    <Checkbox
                      data-testmation="atHomeCheck"
                      checked={scheduleState.onHome}
                      onChange={() => {
                        setScheduleState({
                          ...scheduleState,
                          onHome: !scheduleState.onHome,
                          homeServiceId: null,
                          oHomeServicePrice: 0
                        });
                      }}
                    />
                  </Box>

                  {scheduleState.onHome && (
                    <>
                      <IconWithField
                        LabelIcon={<HomeIcon />}
                        style={{
                          display: "flex",
                          marginBottom: "8px",
                          minHeight: "18px"
                        }}
                        iconStyle={{
                          paddingTop: "8px"
                        }}
                      >
                        <Autocomplete
                          data-testmation="homeServiceSelect"
                          value={
                            services.find(
                              (item: ProductInterface) => item.id === scheduleState.homeServiceId
                            ) || null
                          }
                          options={
                            services.filter(
                              (item: { category: string }) => item.category === "home"
                            ) || []
                          }
                          getOptionLabel={(option) => option.name}
                          renderOption={(renderProps, option) => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <li {...renderProps} key={option.id}>
                              {option.name}
                            </li>
                          )}
                          isOptionEqualToValue={(option, value) => option.name === value.name}
                          renderTags={(value, getTagProps) =>
                            value.map((option, index) => (
                              // eslint-disable-next-line react/jsx-key, react/jsx-props-no-spreading
                              <Chip label={option.name} size="small" {...getTagProps({ index })} />
                            ))
                          }
                          filterSelectedOptions
                          disableClearable
                          onChange={(e, v) => {
                            setScheduleState({
                              ...scheduleState,
                              homeServiceId: v.id,
                              videoServiceId: null,
                              oVideoServicePrice: 0
                            });
                          }}
                          renderInput={(params) => (
                            <TextField
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...params}
                              placeholder="Select home service"
                              margin="dense"
                              size="small"
                              slotProps={{
                                input: {
                                  ...params.InputProps,
                                  disableUnderline: true,
                                  sx: {
                                    padding: 0,
                                    fontSize: "12px",
                                    width: "180px"
                                  }
                                }
                              }}
                              error={scheduleState.onHome && !scheduleState.homeServiceId}
                              helperText={
                                (scheduleState.onHome &&
                                  !scheduleState.homeServiceId &&
                                  "Please select a home service!") ||
                                ""
                              }
                            />
                          )}
                        />
                        {scheduleState.homeServiceId && (
                          <Typography
                            sx={{
                              fontSize: "0.75rem",
                              marginLeft: "16px",
                              color: "grey",
                              marginTop: "8px"
                            }}
                          >
                            Rs{" "}
                            {
                              services.find((s: any) => scheduleState.homeServiceId === s.id)
                                ?.grossTotalPrice
                            }
                          </Typography>
                        )}
                      </IconWithField>
                      {scheduleState.homeServiceId && (
                        <StrikeThroughPriceInput
                          minValue={
                            services.find((s: any) => scheduleState.homeServiceId === s.id)
                              ?.grossTotalPrice
                          }
                          value={scheduleState.oHomeServicePrice}
                          onChange={(value) =>
                            setScheduleState({ ...scheduleState, oHomeServicePrice: value })
                          }
                        />
                      )}
                    </>
                  )}
                </>
              )}

              {publicBookingSubscription &&
                (serviceProvider
                  ? serviceProvider?.publicBooking
                  : currentBookableResource[0]?.publicBooking) && (
                  <Box sx={{ height: "18px", mt: "8px", display: "flex" }}>
                    <Typography style={{ fontSize: "12px", minWidth: "30%" }}>
                      Public booking
                    </Typography>
                    <Checkbox
                      data-testmation="publicBookingCheck"
                      checked={scheduleState.publicBooking}
                      onChange={() => {
                        setScheduleState({
                          ...scheduleState,
                          publicBooking: !scheduleState.publicBooking
                        });
                      }}
                    />
                  </Box>
                )}
              {!scheduleState.video &&
                (serviceProvider ? serviceProvider?.publicBooking : true) && (
                  <Box sx={{ height: "18px", mt: "8px", display: "flex" }}>
                    <Typography sx={{ fontSize: "12px", minWidth: "30%" }}>
                      Allow Pay Later
                    </Typography>
                    <Checkbox
                      data-testmation="payLaterCheck"
                      checked={scheduleState.payLater}
                      onChange={() => {
                        setScheduleState({
                          ...scheduleState,
                          payLater: !scheduleState.payLater
                        });
                      }}
                    />
                  </Box>
                )}
            </Box>
          )}
        </IconWithField>
      </Box>
      <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <Box />
        <Box>
          <Button
            color="primary"
            onClick={() => onClose()}
            sx={{ paddingRight: "16px" }}
            data-testmation="scheduleCancel"
          >
            {tl("booking.cancel")}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              const formattedData = produce(scheduleState, (draft) => {
                draft.bookableResourceId = draft.bookableResource?.id || null;
                delete draft.bookableResource;
              });
              onSave(formattedData);
            }}
            data-testmation="scheduleCreateSlots"
            disabled={
              (scheduleState.video && !scheduleState.videoServiceId) ||
              (scheduleState.onHome && !scheduleState.homeServiceId) ||
              (scheduleState.onSite && !scheduleState.consultServiceId) ||
              (!serviceProvider &&
                !scheduleState.homeServiceId &&
                !scheduleState.consultServiceId) ||
              (scheduleState.onSite &&
                !allowPublicBooking(
                  scheduleState.consultServiceId,
                  currentBookableResource[0]?.publicBooking
                )) ||
              (scheduleState.onHome &&
                !allowPublicBooking(
                  scheduleState.homeServiceId,
                  currentBookableResource[0]?.publicBooking
                ))
            }
          >
            {editMode ? tl("booking.updateSlots") : tl("booking.createSlots")}
          </Button>
        </Box>
      </Box>
      <Modal
        open={recurrencePopUp}
        onClose={() => {
          setRecurrencePopUp(false);
          setRepeat("");
        }}
        title={tl("slot.createRecurrence")}
        onSave={() => {
          setScheduleState({
            ...scheduleState,
            eEnd: recProps.eEnd,
            daysInWeek: recProps.daysInWeek
          });
          setRecurrencePopUp(false);
        }}
        saveLabel={tl("slot.done")}
      >
        <Recurrence
          repeatsEvery="week"
          startDate={scheduleState.start}
          repeatsOnDays={recProps.daysInWeek}
          repeatsUntil={moment(recProps.eEnd)}
          onChange={(newRecState) => {
            setRecProps({
              daysInWeek: newRecState.repeatsOnDays,
              eEnd: moment(newRecState.repeatsUntil)
            });
          }}
        />
      </Modal>
    </Box>
  );
};

export default connect((state, ownProps) => {
  const { serviceProviderId, bookableResource } = ownProps.schedule;
  const serviceProvider = state.resources.resourceCentreServiceProviders.find(
    (sp) => sp.id === serviceProviderId
  );
  const services = state.services.collection.filter(({ active }) => active);
  const { resourceCentre } = state.userContext;
  const currentBookableResource = state.bookableResources.collection.filter(
    (item) => item.id === bookableResource?.id
  );
  return { serviceProvider, services, resourceCentre, currentBookableResource };
})(SlotCreateEdit);
