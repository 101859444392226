import * as React from "react";
import Typography from "@mui/material/Typography";
import { Box, Link } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { LoadingButton } from "@mui/lab";
import Form from "../../components/Form";
import { fields } from "../../models/Login";
import * as AuthenicationActions from "../../actions/authentication";
import { tl } from "../../components/translate";
import style from "./style.module.css";
import logo from "../../../assets/images/okhati_full_green.png";
import { RootState } from "../../store";
import { sessionOrLocalStorageUserData } from "../../auth/authentication";
import { isRequired, isValidEmail } from "../../helpers/validators";
import TwoFactorAuthQrScanModal from "./TwoFactorAuthQrScanModal";
import { resetUserPassword } from "../../api/user";
import { notificationAdd } from "../../actions/notification";
import ForgotPasswordModal from "./ForgotPasswordModal";

interface loginProps {
  login: (creds) => void;
}

const Login: React.FC<loginProps> = () => {
  const { required2FAToken, imageUrl } = useSelector((s: RootState) => s.twoFactorAuthInfo);
  const isLoggingIn = useSelector((state: RootState) => state.ongoing.LOGIN);
  const [state, setState] = React.useState<{
    credentials: { username: string; password: string; token: string } | null;
    errorsCount: number;
    showErrors: boolean;
  }>({
    // eslint-disable-next-line no-bitwise
    credentials: { username: "", password: "", token: "" } || null,
    errorsCount: 0,
    showErrors: false
  });
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = React.useState(false);
  const [emailForPwReset, setEmailForPwReset] = React.useState("");
  const onChange = (credentials, errorsCount) => {
    setState({ ...state, credentials, errorsCount });
  };
  const dispatch = useDispatch();
  const onResetPassword = async (data = state.credentials) => {
    if (!data?.email || !emailForPwReset) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: "Email or Username is missing. Please check and try again!",
          variant: "error",
          autoTimeout: true
        })
      );
      return;
    }

    if (!isValidEmail(emailForPwReset)) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: "Email is invalid!",
          variant: "error",
          autoTimeout: true
        })
      );
      return;
    }
    try {
      await resetUserPassword({ username: data.email, email: emailForPwReset });
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: "Password reset successful. Please check your email.",
          variant: "success",
          autoTimeout: true
        })
      );
      setEmailForPwReset("");
      setOpenForgotPasswordModal(false);
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: err.data?.message || "Something went wrong!",
          variant: "error",
          autoTimeout: true
        })
      );
    }
  };
  const isFromClinicsApp = window.isRnWebView || false;
  const { expoPushToken } = window;
  const isUserAuthenticated = useSelector((s: RootState) => s.authenticated);
  const isTokenRequired = required2FAToken && !imageUrl;
  const user = sessionOrLocalStorageUserData();
  const logIn = async (data = state.credentials) => {
    if (!state.credentials?.username && !state.credentials?.password) {
      return;
    }
    if (state.errorsCount) {
      setState({ ...state, showErrors: true });
    } else {
      dispatch(async (_, getState) => {
        await dispatch(
          AuthenicationActions.login({
            ...data,
            ...(isFromClinicsApp ? { isFromClinicsApp, expoPushToken } : {})
          })
        );
        const { twoFactorAuthInfo } = getState();
        if (!twoFactorAuthInfo.required2FAToken) {
          setState({ ...state, credentials: null });
        }
      });
    }
  };
  if (isUserAuthenticated && user) {
    return <Redirect to="/dashboard" />;
  }

  const updatedFields = [
    ...fields,
    ...(isTokenRequired
      ? [
          {
            key: "token",
            label: "token",
            value: "",
            inputType: "text",
            editable: true,
            creatable: true,
            required: true,
            validators: [isRequired({ msg: "Token is Required" })]
          }
        ]
      : [])
  ];

  return (
    <div className={style.root}>
      <div className={style.loginPanelBody}>
        <Form
          name="loginForm"
          title={
            <div className={style.loginHeader}>
              <div className="login-logo-img">
                <img className={style.logo} src={logo} alt="Okhati Logo" />
              </div>
              <Typography variant="caption" gutterBottom className={style.subText}>
                आफ्नो Okhati खाता प्रयोग गरी भित्र पस्नुहोस
              </Typography>
            </div>
          }
          classNames={{
            root: style.loginFormRoot,
            paper: style.loginPanel
          }}
          onChange={(data, errors, errorsCount) => onChange(data, errorsCount)}
          data={{}}
          fields={updatedFields}
          hideSaveButton
          showErrors={state.showErrors}
          onSave={() => logIn()}
          footer={
            <div>
              <Typography
                sx={{ cursor: "pointer" }}
                onClick={() => {
                  if (!state.credentials?.email) {
                    dispatch(
                      notificationAdd({
                        id: new Date().getTime(),
                        message: "Please enter username to continue!",
                        variant: "error",
                        autoTimeout: true
                      })
                    );
                  } else {
                    setOpenForgotPasswordModal(true);
                  }
                }}
              >
                Forgot Password?
              </Typography>
              <Box sx={{ my: 1, display: "flex", gap: 2, flexWrap: "wrap" }}>
                <Link
                  title="Download Okhati Clinics App From Playstore"
                  target="_blank"
                  href="https://play.google.com/store/apps/details?id=com.okhati.appforclinics&hl=en&pli=1"
                  variant="caption"
                  sx={{ display: "flex", alignItems: "flex-start", fontWeight: "bold" }}
                >
                  Download Okhati Clinics App
                  <OpenInNewIcon sx={{ fontSize: 8 }} />
                </Link>
                <Link
                  title="Free ICD-11 Brower With Treeview And Search"
                  target="_blank"
                  href="https://okhati.com.np/icd"
                  variant="caption"
                  sx={{ display: "flex", alignItems: "flex-start", fontWeight: "bold" }}
                >
                  ICD-11 Browser
                  <OpenInNewIcon sx={{ fontSize: 8 }} />
                </Link>
              </Box>

              <div className={style.loginButton}>
                <LoadingButton
                  loading={isLoggingIn}
                  variant="contained"
                  aria-label="Login"
                  type="submit"
                  form="loginForm"
                  color="primary"
                  className={style.loginButton}
                  onClick={() => logIn()}
                  disabled={isTokenRequired && !state.credentials?.token}
                >
                  {isTokenRequired ? tl("main.verify") : tl("main.login")}
                </LoadingButton>
              </div>
            </div>
          }
        />
        <ForgotPasswordModal
          isOpen={openForgotPasswordModal}
          onClose={() => {
            setOpenForgotPasswordModal(false);
            setEmailForPwReset("");
          }}
          email={emailForPwReset}
          setEmail={setEmailForPwReset}
          onResetPassword={onResetPassword}
        />
        <TwoFactorAuthQrScanModal
          isLoading={isLoggingIn}
          onSave={(token) => logIn({ ...state.credentials, token })}
        />
      </div>
    </div>
  );
};

export default Login;
