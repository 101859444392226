import * as React from "react";
import { Box } from "@mui/material";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";

const EnsureUser = ({ user, component: Component, ...rest }) =>
  user ? (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Component {...rest} />
  ) : (
    <Box component="div" sx={{ textAlign: "center", width: "100%" }}>
      <CircularProgress />
    </Box>
  );

export default connect(
  ({ userContext }) => ({ user: userContext.userCreds }),
  () => ({})
)(EnsureUser);
