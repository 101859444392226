import { Box, Chip } from "@mui/material";
import { startCase } from "lodash";
import queryString from "query-string";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route } from "react-router";
import { push } from "connected-react-router";
import { useState } from "react";
import { clearDraft } from "../../actions/bill";
import { Filters, MultipleHeader } from "../../components/List";
import useCan from "../../hooks/useCan";
import { DocumentTypes, VisitType } from "../../interfaces/BillInterfaces";
import { RootState } from "../../store";
import BillList, { Mode } from "./BillList";
import BillEditor, { LOCALSTORAGE_BILL } from "./Editor/BillEditor";
import useMobileScreen from "../../hooks/useMobileScreen";
import { tl } from "../../components/translate";
import LabRequestList from "../IPD/LabRequest/LabRequestList";
import BillSettings, { BillCounters } from "./Editor/BillSettings";
import * as billActions from "../../actions/bill";
import ClaimsList from "./Ssf/ClaimsList";
import ClientsWithProformaBills from "../IPD/Proforma/ClientsWithProformaBills";

export enum BillModule {
  NEW = "newBill",
  NEW_CREDIT_NOTE = "newCreditNote",
  EDIT_DRAFT = "editDraft"
}

export enum IpdBilling {
  IPD_BILL = "ipdBill",
  IPD_CN = "ipdCreditNote",
  EDIT_IPD_DRAFT = "editIpdDraft"
}

const getTitle = (draft) => {
  if (!draft) return BillModule.NEW;
  if (draft.type === DocumentTypes.CREDITNOTE && draft.visitType === VisitType.OPD) {
    return BillModule.NEW_CREDIT_NOTE;
  }
  if (draft.id && draft.visitType === VisitType.OPD) {
    return BillModule.EDIT_DRAFT;
  }
  return BillModule.NEW;
};

const getIpdTitle = (draft) => {
  if (!draft) return IpdBilling.IPD_BILL;
  if (draft.type === DocumentTypes.CREDITNOTE && draft.visitType === VisitType.IPD) {
    return IpdBilling.IPD_CN;
  }
  if (draft.id && draft.visitType === VisitType.IPD) {
    return IpdBilling.EDIT_IPD_DRAFT;
  }
  return IpdBilling.IPD_BILL;
};
const getKey = (draft) => (draft?.id ? "edit" : "new");

export enum IpdTabs {
  NewBill = "newBill",
  RequestForms = "requestForms",
  ProformaBills = "proformaBills"
}

export enum ListTabs {
  Bills = "bills",
  Claims = "claims"
}

interface QueryParams {
  clientId?: string;
  bedId?: string;
  bookingId?: string;
  ipdId?: string;
  labRequestId?: string;
  productMedicationRequestId?: string;
  tab?: IpdTabs;
  isProformaBill?: string;
  purchaseRequisitionFormId?: string;
}

const Billing = ({
  match,
  location
}: {
  match: Record<string, unknown>;
  location: Record<string, unknown>;
}): React.JSX.Element => {
  const draft = useSelector((state: RootState) => state.bills.draft);
  const dispatch = useDispatch();
  const [currentCounter, setCurrentCounter] = React.useState("");
  const [isSettingsOpen, setIsSettingsOpen] = React.useState<boolean>(false);
  const canUseIpdBill = useCan("ipd:ipdBill", { superAdminPassReverse: true });
  const isMobile = useMobileScreen();
  const isCounterBillingEnabled = useSelector(
    (s: RootState) =>
      s.resources.resourceCentres[0]?.settings?.billingSettings?.enableCounterBilling || false
  );

  const [isExtraReferrerEnabled, setIsExtraReferrerEnabled] = useState(
    JSON.parse(localStorage.getItem("billExtraReferrer")) || false
  );
  const multipleHeaders = (loc: { pathname: string }) => ({
    headers: [
      { key: getKey(draft), title: startCase(getTitle(draft)), goto: "/billing/new" },
      ...(canUseIpdBill
        ? [{ key: "ipd", title: startCase(getIpdTitle(draft)), goto: "/billing/ipd" }]
        : []),
      { key: "bills", title: tl("billing.previousBills"), goto: "/billing/bills" }
    ],
    url: loc.pathname
  });
  const filterData = {
    filters: [
      { key: IpdTabs.NewBill, title: tl(`billing.newBill`) },
      { key: IpdTabs.RequestForms, title: "Request Forms" },
      { key: IpdTabs.ProformaBills, title: "Proforma Bills" }
    ]
  };
  const listFilterData = {
    filters: [
      { key: ListTabs.Bills, title: ListTabs.Bills },
      { key: ListTabs.Claims, title: ListTabs.Claims }
    ]
  };

  const [filter, setFilter] = React.useState(IpdTabs.NewBill);
  const [listFilter, setListFilter] = React.useState(ListTabs.Bills);
  const {
    clientId,
    bedId,
    bookingId,
    ipdId,
    labRequestId,
    tab,
    purchaseRequisitionFormId,
    productMedicationRequestId,
    isProformaBill
  }: QueryParams = queryString.parse(location.search);

  React.useEffect(() => {
    if (tab) {
      setFilter(tab);
    }
  }, [tab]);

  return (
    <Box height="100%">
      <Box pl={2} pt={2} pb={isMobile ? 2 : 0} display="flex" justifyContent="space-between">
        <MultipleHeader
          multipleHeaders={multipleHeaders(location)}
          onChange={() => {
            localStorage.getItem(LOCALSTORAGE_BILL);
            dispatch(clearDraft());
          }}
        />
        {draft && draft.type !== DocumentTypes.CREDITNOTE && (
          <Box display="flex" alignItems="center" gap={1} pr={2}>
            {isCounterBillingEnabled && currentCounter && (
              <Chip size="medium" label={BillCounters[currentCounter]} color="primary" />
            )}
            <BillSettings
              isExtraReferrerEnabled={isExtraReferrerEnabled}
              isSettingsOpen={isSettingsOpen}
              setIsSettingsOpen={setIsSettingsOpen}
              draft={draft}
              updateSettings={(settings) => {
                dispatch(billActions.updateDraftSettings(settings));
              }}
              updateBillCounter={setCurrentCounter}
              onExtraReferrerSettingChange={(value) => {
                localStorage.setItem("billExtraReferrer", value);
                setIsExtraReferrerEnabled(value);
              }}
            />
          </Box>
        )}
      </Box>
      {window.location.pathname === "/billing/ipd" && (
        <Box pl={2}>
          <Filters
            filter={filter}
            onFilter={(key) => {
              dispatch(push(`/billing/ipd?tab=${key}`));
            }}
            filterData={filterData}
          />
        </Box>
      )}
      {(window.location.pathname.includes("bills") ||
        window.location.pathname.includes("claims")) && (
        <Box pl={2}>
          <Filters
            filter={listFilter}
            onFilter={(key) => {
              dispatch(push(`/billing/${key}`));
              setListFilter(key);
            }}
            filterData={listFilterData}
          />
        </Box>
      )}
      <Route
        exact
        path={`${match.path}/new`}
        render={(props) => (
          <BillEditor
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            isExtraReferrerEnabled={isExtraReferrerEnabled}
            mode="create"
            visitType={VisitType.OPD}
            clientId={Number(clientId)}
            bookingId={Number(bookingId)}
            purchaseRequisitionFormId={Number(purchaseRequisitionFormId)}
            currentCounter={currentCounter}
            setCurrentCounter={setCurrentCounter}
            setIsSettingsOpen={setIsSettingsOpen}
            isProformaBill={isProformaBill === "true"}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/ipd`}
        render={(props) => {
          if (filter === IpdTabs.NewBill) {
            return (
              <BillEditor
                isExtraReferrerEnabled={isExtraReferrerEnabled}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...props}
                mode="create"
                visitType={VisitType.IPD}
                clientId={Number(clientId)}
                bookingId={Number(bookingId)}
                bedId={bedId}
                ipdId={ipdId}
                labRequestId={labRequestId}
                productMedicationRequestId={productMedicationRequestId}
                currentCounter={currentCounter}
                setCurrentCounter={setCurrentCounter}
                setIsSettingsOpen={setIsSettingsOpen}
                isProformaBill={isProformaBill === "true"}
              />
            );
          }

          if (filter === IpdTabs.RequestForms) {
            return (
              <Box overflow="auto hidden" height="100%">
                <LabRequestList />
              </Box>
            );
          }

          return (
            <Box overflow="auto hidden" height="100%">
              <ClientsWithProformaBills />
            </Box>
          );
        }}
      />
      <Route
        exact
        path={`${match.path}/:billId/edit`}
        render={(props) => (
          <BillEditor
            isExtraReferrerEnabled={isExtraReferrerEnabled}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            mode="edit"
            billId={props.match.params.billId}
            currentCounter={currentCounter}
            setCurrentCounter={setCurrentCounter}
            setIsSettingsOpen={setIsSettingsOpen}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/ipd/:billId/edit`}
        render={(props) => (
          <BillEditor
            isExtraReferrerEnabled={isExtraReferrerEnabled}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            mode="edit"
            billId={props.match.params.billId}
            currentCounter={currentCounter}
            setCurrentCounter={setCurrentCounter}
            setIsSettingsOpen={setIsSettingsOpen}
          />
        )}
      />
      <Route
        exact
        path={`${match.path}/bills/:billId?`}
        render={(props) => {
          const { billId } = props.match.params;
          return (
            (typeof billId === "undefined" || !Number.isNaN(Number(billId))) && (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <BillList {...props} mode={Mode.Show} billId={Number(props.match.params.billId)} />
            )
          );
        }}
      />
      <Route exact path={`${match.path}/claims`} component={ClaimsList} />
    </Box>
  );
};

export default Billing;
