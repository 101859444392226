import React from "react";
import { useDispatch, useSelector } from "react-redux";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import { Box, Button, IconButton, styled, TextareaAutosize, Typography } from "@mui/material";

import { RootState } from "../../store";
import { notificationAdd } from "../../actions/notification";
import { postKnowledgeBaseFeedback } from "../../api/knowledgeBase";
import { getMessageTemplate, MessageTemplateEnum } from "../../helpers/messages";

const Textarea = styled(TextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.5;
    padding: 8px 12px;
    border-radius: 8px;
    color: ${theme.palette.mode === "dark" ? "#C7D0DD" : "#1C2025"};
    background: ${theme.palette.mode === "dark" ? "#1C2025" : "#fff"};
    border: 1px solid ${theme.palette.mode === "dark" ? "#434D5B" : "#DAE2ED"};

    &:focus-visible {
      outline: 0;
    }
  `
);

interface PropType {
  knowledgebaseTitle: string;
}

export default function KnowledgeBaseReview({ knowledgebaseTitle }: PropType): React.ReactElement {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const activeUser = useSelector((state: RootState) => state.userContext.user);
  const activeRc = useSelector((state: RootState) => state.userContext.resourceCentre);

  const { subject } = getMessageTemplate({
    messageTemplateType: MessageTemplateEnum.KNOWLEDGEBASE_FEEDBACK,
    activeRc,
    activeUser,
    knowledgebaseTitle
  });

  const { emailBody } = getMessageTemplate({
    messageTemplateType: MessageTemplateEnum.KNOWLEDGEBASE_FEEDBACK,
    activeRc,
    activeUser,
    knowledgebaseTitle
  });

  const [feedback, setFeedback] = React.useState("");

  const sendFeedback = async (text?: string) => {
    const feedbackToSend = text || feedback;

    if (!feedbackToSend) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "warning",
          message: "Please provide feedback before submitting.",
          autoTimeout: true
        })
      );
      return;
    }

    try {
      setIsLoading(true);
      await postKnowledgeBaseFeedback({ feedback: emailBody + feedbackToSend, subject });

      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Feedback sent successfully.",
          autoTimeout: true
        })
      );

      setFeedback("");
    } catch (error) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message:
            error?.message || error?.data?.message || "Failed to send feedback. Please try again.",
          autoTimeout: true
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ paddingY: "10px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Typography variant="body1">Was this helpful?</Typography>
        <Box>
          <IconButton disabled={isLoading} onClick={() => sendFeedback("Thumbs up!")}>
            <ThumbUpIcon />
          </IconButton>
          <IconButton disabled={isLoading} onClick={() => sendFeedback("Thumbs down!")}>
            <ThumbDownIcon />
          </IconButton>
        </Box>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "10px",
          gap: "10px"
        }}
      >
        <Textarea
          minRows={6}
          placeholder="Your feedback..."
          value={feedback}
          required
          onChange={(e) => setFeedback(e.target.value)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "10px"
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={() => sendFeedback()}
            disabled={isLoading}
          >
            Submit
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
