import React, { JSX } from "react";
import { Box } from "@mui/material";
import { Route } from "react-router";
import queryString from "query-string";
import Assessment from "./index";
import OpdRequests from "./OpdRequest";
import AssessmentList from "./AssessmentList";
import PrescribedLabs from "./PrescribedLabs";
import { t } from "../../components/translate";
import { useAppDispatch } from "../../store/hooks";
import { MultipleHeader } from "../../components/List";
import { getAssessmentById } from "../../api/assessment";
import { notificationAdd } from "../../actions/notification";
import { errorFetchMessage, MODULE } from "../../helpers/messages";

interface Props {
  match: Record<string, any>;
  location: Record<string, any>;
}

function AssessmentHome({ location, match }: Props): JSX.Element {
  const [assessmentCopy, setAssessmentCopy] = React.useState(null);

  const headersInfo = {
    headers: [
      { key: "new", title: `${t("Newprescription")}`, goto: "/assessment/new" },
      {
        key: "assessments",
        title: `${t("Prevprescription")}`,
        goto: "/assessment/assessments"
      },
      {
        key: "labTest",
        title: "Prescribed Lab Test",
        goto: "/assessment/labTest",
        badge: 0
      },
      {
        key: "labOrderForms",
        title: "Order Forms for Laboratory",
        goto: "/assessment/labOrderForms",
        badge: 0
      }
    ],
    url: location.pathname
  };
  const { id } = queryString.parse(location.search);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (!assessmentCopy && id) {
      (async () => {
        try {
          const assessment = await getAssessmentById(Number(id));
          setAssessmentCopy(assessment);
        } catch (e) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: errorFetchMessage(MODULE.ASSESSMENT),
              autoTimeout: true
            })
          );
        }
      })();
    }
  }, []);

  return (
    <Box sx={{ height: "calc(100% - 56px)" }}>
      <Box
        sx={{
          backgroundColor: "#fff",
          zIndex: 2,
          position: "relative",
          pl: 2,
          pt: 2
        }}
      >
        <MultipleHeader multipleHeaders={headersInfo} />
      </Box>
      <Route
        path={`${match.path}/new`}
        render={(props) => (
          <Assessment
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            assessmentCopy={assessmentCopy}
            setAssessmentCopy={setAssessmentCopy}
          />
        )}
      />
      <Route
        path={`${match.path}/assessments`}
        render={(props) => (
          <AssessmentList
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}
            setAssessmentCopy={setAssessmentCopy}
          />
        )}
      />

      <Route path={`${match.path}/labTest`} render={() => <PrescribedLabs />} />

      <Route
        path={`${match.path}/labOrderForms`}
        // eslint-disable-next-line react/jsx-props-no-spreading
        render={(props) => <OpdRequests {...props} />}
      />
    </Box>
  );
}

export default AssessmentHome;
