import { Box, Typography } from "@mui/material";
import React from "react";
import { isRadiologyOrCytology, LabHeadersFlexBasis, scaleFont } from "../../LabPrintFunctions";

export const DataHeaders = ({
  headers = ["Test"],
  showMethods,
  showRanges,
  showUnit,
  showReading,
  type,
  readingsPresent,
  showRangeFlag
}: {
  headers?: string[];
  showMethods: boolean;
  showRanges: boolean;
  showUnit: boolean;
  showReading: boolean;
  type: string;
  readingsPresent: boolean;
  showRangeFlag: boolean;
}): JSX.Element => {
  const handleHeaders = (h = headers) => {
    const headings = [...h];
    if (showReading) {
      headings.push("Result");
    }
    if (showRangeFlag) {
      headings.push("Flag");
    }
    if (showUnit) {
      headings.push("Unit");
    }
    if (showRanges) {
      headings.push("Range");
    }
    if (showMethods) {
      headings.push("Methods");
    }
    if (isRadiologyOrCytology(type)) {
      headings.splice(2, headings.length);
    }
    return headings;
  };

  return (
    <>
      {readingsPresent && (
        <Box display="flex" alignItems="center" borderBottom="1px solid lightgrey" bgcolor="#ddd">
          {handleHeaders().map((colHeader, i) => (
            <Typography
              key={colHeader}
              sx={{
                flexGrow: i === 0 ? 0.4 : 0.15,
                flexShrink: i === 0 ? 0.4 : 0.15,
                flexBasis: LabHeadersFlexBasis[colHeader] || "7cm",
                fontSize: scaleFont("0.27cm", 1.2),
                textTransform: "uppercase",
                fontWeight: 600,
                pl: i === 0 ? 1 : 0
              }}
            >
              {colHeader}
            </Typography>
          ))}
        </Box>
      )}
    </>
  );
};

export default DataHeaders;
