import moment from "moment-timezone";
import InfoIcon from "@mui/icons-material/Info";
import SettingsIcon from "@mui/icons-material/Settings";
import RefreshIcon from "@mui/icons-material/Refresh";
import React, { JSX } from "react";
import { Box, Grid2 as Grid, IconButton, LinearProgress, Tooltip, Typography } from "@mui/material";
import { push } from "connected-react-router";
import { Redirect } from "react-router";
import { PermissionGroups } from "../../interfaces/User";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  useDashboardKeyIndicators,
  useDashboardPatientFlow
} from "../DashboardV2/hooks/useDashboard";
import { getCurrentSubscription } from "../../slices/subscriptionSlice";
import { navigateAddModal } from "../../actions/navigation";
import PendingInvoices from "../ResourceCentreInvoices/PendingInvoices";
import DatePickers from "../../components/DatePickers";
import ButtonGroup from "../DashboardV2/ButtonGroup";
import Can from "../Policy/Can";
import WeeklyRevenueChart from "../DashboardV2/WeeklyRevenueChart";
import LivePatientFlow from "../DashboardV2/LivePatientFlow";
import CardStats from "./CardStats";
import DepartmentMultiSelect from "./DepartmentMultiSelect";
import { t } from "../../components/translate";
import { RootState } from "../../store";
import Appointments from "./Appointments";
import { isSuperAdmin } from "../NavigationBar/SideBar";
import SettingsPanel from "./Settings/SettingsPanel";
import SubscriptionExpiryModal from "../Subscriptions/SubscriptionExpiryModal";
import useCan from "../../hooks/useCan";

const getNumberOfMonths = (startDate: moment.Moment, endDate: moment.Moment) => {
  let totalNumberOfMonths = 0;

  const sDate = startDate.startOf("month");
  const eDate = endDate.startOf("month");
  if (sDate > eDate) return totalNumberOfMonths;

  const date = sDate;
  while (date < eDate) {
    totalNumberOfMonths += 1;
    date.add(1, "month");
  }
  return totalNumberOfMonths;
};

export enum DASHBOARD_CARD_ITEMS {
  KPI = "kpi",
  PATIENT_FLOW = "patientFlow",
  WEEKLY_REVENUE = "weeklyRevenue",
  APPOINTMENTS = "appointments"
}

const columnSpan = { big: 7, small: 5 };

const getDashboardItemCardWidth = ({
  item,
  cardItemsOrder,
  itemIndex
}: {
  itemIndex: number;
  item: DASHBOARD_CARD_ITEMS;
  cardItemsOrder: DASHBOARD_CARD_ITEMS[];
}) => {
  if (item === DASHBOARD_CARD_ITEMS.WEEKLY_REVENUE) {
    const indexOfKpi = cardItemsOrder.indexOf(DASHBOARD_CARD_ITEMS.KPI);
    if (itemIndex === 0 || itemIndex === 2) {
      if (indexOfKpi === itemIndex + 1) {
        return columnSpan.small;
      }
    } else if (itemIndex === 1 || itemIndex === 3) {
      if (indexOfKpi === itemIndex - 1) {
        return columnSpan.small;
      }
    }
    return columnSpan.big;
  }
  if (item === DASHBOARD_CARD_ITEMS.APPOINTMENTS) {
    const indexOfPatientFlow = cardItemsOrder.indexOf(DASHBOARD_CARD_ITEMS.PATIENT_FLOW);
    if (itemIndex === 0 || itemIndex === 2) {
      if (indexOfPatientFlow === itemIndex + 1) {
        return columnSpan.big;
      }
    } else if (itemIndex === 1 || itemIndex === 3) {
      if (indexOfPatientFlow === itemIndex - 1) {
        return columnSpan.big;
      }
    }
    return columnSpan.small;
  }
  return columnSpan.small;
};

const DashboardV3 = (): JSX.Element => {
  const { mode, userCreds } = useAppSelector(({ userContext }) => ({
    mode: userContext.mode,
    userCreds: userContext.userCreds
  }));

  const [selectedDate, setSelectedDate] = React.useState(moment());
  const [showSettingsPanel, setShowSettingsPanel] = React.useState(false);
  const permissionGroup = useAppSelector((state) => state.userContext?.userCreds?.userGroups[0]);
  const resourceCentre = useAppSelector((state) => state.resources.resourceCentres[0] || null);
  const branchForDashboad = useAppSelector((state) => state.userBranch.selectedBranchForDashboard);
  const cardItemsOrder = resourceCentre?.settings.dashboardSettings?.cardItemsOrder || [];

  const showDepartmentAndPFlow =
    !branchForDashboad || (branchForDashboad && branchForDashboad === resourceCentre?.id);
  const currentSubscription = useAppSelector((state) => state.subscriptions.currentSubscription);
  const remainingMonths = getNumberOfMonths(moment(), moment(currentSubscription?.subscriptionEnd));

  const { bookings, refetch, isBookingFetching } = useDashboardPatientFlow(selectedDate);

  const onDateChange = (date) => {
    setSelectedDate(date);
  };

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (resourceCentre) dispatch(getCurrentSubscription(resourceCentre.id));
  }, [dispatch, resourceCentre]);

  React.useEffect(() => {
    if (mode && userCreds && userCreds.tempPassword) {
      dispatch(
        navigateAddModal({
          component: "ChangePassword",
          props: {
            next: () => ({})
          }
        })
      );
    }
  }, [mode, userCreds, dispatch]);
  const userDepartmentId = useAppSelector((state) => state.userContext.user?.departmentId);
  const belongsToDepartment = Boolean(userDepartmentId);

  const [selectedDepartments, setSelectedDepartments] = React.useState(
    userDepartmentId ? [userDepartmentId] : []
  );
  const [isRefreshBtnDisabled, setIsRefreshBtnDisabled] = React.useState(false);
  const selectedBranch = useAppSelector(
    (state: RootState) => state.userBranch.selectedBranchForDashboard
  );
  const [refreshKpi, setRefreshKpi] = React.useState(false);
  const {
    data,
    refetch: refetchKpi,
    isFetching: isKpiRefreshing
  } = useDashboardKeyIndicators(
    moment(selectedDate).startOf("d").toISOString(),
    moment(selectedDate).endOf("d").toISOString(),
    selectedBranch,
    refreshKpi
  );

  const canViewDashboard = useCan(
    [
      "dashboard:accessStatistics",
      "dashboard:accessBookings",
      "dashboard:accessWeeklyRevenue",
      "dashboard:accessAppointments"
    ],
    { partialCheck: true }
  );

  if (isSuperAdmin(permissionGroup)) return <Redirect to="/resourcecentres" />;

  return (
    <Box sx={{ height: "100%", overflowY: "auto" }}>
      <Box height="4px">
        {isBookingFetching && <LinearProgress className="okhatiLoader" color="secondary" />}
      </Box>
      <Box sx={{ p: 3 }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            pb: 1
          }}
        >
          {currentSubscription && remainingMonths < 1 && (
            <SubscriptionExpiryModal
              expiresIn={moment(currentSubscription.subscriptionEnd).format("MMMM D, YYYY")}
            />
          )}
          {currentSubscription && remainingMonths < 2 && (
            <Box sx={{ p: 1, border: "2px solid orange", borderRadius: "8px" }}>
              <Box sx={{ px: 1, display: "flex", gap: 1 }}>
                <InfoIcon htmlColor="orange" />
                <Typography flexGrow={1}>
                  Your subscription is going to expire on{" "}
                  {moment(currentSubscription.subscriptionEnd).format("MMMM")}. It will be renewed
                  automatically, please pay as soon as possible.
                </Typography>
                {permissionGroup === PermissionGroups.ResourceCentreAdmin && (
                  <Typography
                    component="a"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    onClick={() => {
                      dispatch(push(`/resourcecentres/${resourceCentre.id}/subscriptions`));
                    }}
                  >
                    MORE INFO
                  </Typography>
                )}
              </Box>
            </Box>
          )}
          <PendingInvoices />
        </Box>
        <Box>
          {/* header */}
          <Box
            sx={{
              border: "1px solid #cecece",
              px: 2,
              py: 1
            }}
          >
            <Grid container spacing={0.5}>
              {!belongsToDepartment && showDepartmentAndPFlow && canViewDashboard && (
                <Grid
                  size={{
                    xs: 12,
                    sm: 12,
                    md: 12,
                    lg: 4
                  }}
                  alignItems="center"
                >
                  <Box
                    sx={(theme) => ({
                      display: "flex",
                      alignItems: "center",
                      height: "100%",
                      [theme.breakpoints.down("lg")]: {
                        justifyContent: "center"
                      }
                    })}
                  >
                    <DepartmentMultiSelect
                      value={selectedDepartments}
                      onChange={(value) => setSelectedDepartments(value)}
                    />
                  </Box>
                </Grid>
              )}
              <Grid
                size={{
                  xs: 12,
                  sm: 12,
                  md: canViewDashboard ? 6 : 12,
                  lg: canViewDashboard ? 4 : 12
                }}
              >
                <Box display="flex" justifyContent="center">
                  <DatePickers date={selectedDate} onChange={(date) => onDateChange(date)} />
                </Box>
              </Grid>
              {canViewDashboard && (
                <Grid
                  size={{
                    xs: 12,
                    sm: 12,
                    md: 6,
                    lg: 4
                  }}
                >
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    gap={1}
                    height="100%"
                    sx={(theme) => ({
                      [theme.breakpoints.down("md")]: {
                        justifyContent: "center",
                        flexDirection: "column"
                      }
                    })}
                  >
                    {data.lastCachedAt && (
                      <Typography fontWeight={700}>
                        {`${t("dashboard.lastupdatedat")}: ${moment
                          .tz(data.lastCachedAt, "Asia/kathmandu")
                          .format("hh:mm A")}`}
                      </Typography>
                    )}

                    <Box
                      sx={(theme) => ({
                        display: "flex",
                        alignItems: "center",
                        height: "20px",
                        mx: 1,
                        borderInline: "1px solid #e5e5e5",
                        [theme.breakpoints.down("sm")]: {
                          borderInline: "none",
                          my: 1
                        }
                      })}
                    >
                      <Tooltip arrow title="Refresh KPIs">
                        <IconButton
                          disabled={isRefreshBtnDisabled}
                          onClick={() => {
                            setIsRefreshBtnDisabled(true);
                            setTimeout(() => setIsRefreshBtnDisabled(false), 5000);
                            if (refreshKpi) {
                              refetchKpi();
                            } else {
                              setRefreshKpi(true);
                            }
                          }}
                        >
                          <RefreshIcon
                            color={isRefreshBtnDisabled ? "disabled" : "action"}
                            sx={{
                              animation: isKpiRefreshing ? "spin 1s linear" : "",
                              "@keyframes spin": {
                                "0%": {
                                  transform: "rotate(-360deg)"
                                },
                                "100%": {
                                  transform: "rotate(0deg)"
                                }
                              }
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip arrow title={`${t("KPIsareUpdated")}`}>
                        <InfoIcon color="action" />
                      </Tooltip>
                      <Tooltip arrow title={`${t("settings")}`}>
                        <IconButton onClick={() => setShowSettingsPanel(true)}>
                          <SettingsIcon color="action" />
                        </IconButton>
                      </Tooltip>
                    </Box>

                    <ButtonGroup />
                    {showSettingsPanel && resourceCentre && (
                      <SettingsPanel
                        onClose={() => setShowSettingsPanel(false)}
                        resourceCentre={resourceCentre}
                      />
                    )}
                  </Box>
                </Grid>
              )}{" "}
            </Grid>
          </Box>

          {/* body */}
          <Box sx={{ backgroundColor: "#e5e5e5", border: "1px solid #cecece", padding: 2 }}>
            <Grid container spacing={2}>
              {cardItemsOrder.map((cardItem: DASHBOARD_CARD_ITEMS, itemIndex: number) => {
                if (cardItem === DASHBOARD_CARD_ITEMS.KPI) {
                  return (
                    <Can policyAccessKey="dashboard:accessStatistics" key={cardItem}>
                      <Grid
                        size={{
                          xs: 12,
                          sm: 12,
                          lg: 7
                        }}
                      >
                        <CardStats data={data} hideKpi={!!selectedDepartments.length} />
                      </Grid>
                    </Can>
                  );
                }

                if (cardItem === DASHBOARD_CARD_ITEMS.PATIENT_FLOW && showDepartmentAndPFlow) {
                  return (
                    <Can policyAccessKey="dashboard:accessBookings" key={cardItem}>
                      <Grid
                        size={{
                          xs: 12,
                          sm: 12,
                          lg: 5
                        }}
                      >
                        <LivePatientFlow
                          selectedDepartmentId={selectedDepartments[0]?.id || null}
                          selectedDate={selectedDate}
                          bookings={isBookingFetching ? [] : bookings}
                          isBookingFetching={isBookingFetching}
                          refetch={refetch}
                        />
                      </Grid>
                    </Can>
                  );
                }

                if (cardItem === DASHBOARD_CARD_ITEMS.WEEKLY_REVENUE) {
                  return (
                    <Can policyAccessKey="dashboard:accessWeeklyRevenue" key={cardItem}>
                      <Grid
                        size={{
                          xs: 12,
                          sm: 12,
                          lg: getDashboardItemCardWidth({
                            item: cardItem,
                            itemIndex,
                            cardItemsOrder
                          })
                        }}
                      >
                        <WeeklyRevenueChart />
                      </Grid>
                    </Can>
                  );
                }

                if (cardItem === DASHBOARD_CARD_ITEMS.APPOINTMENTS) {
                  return (
                    <Can policyAccessKey="dashboard:accessAppointments" key={cardItem}>
                      <Grid
                        size={{
                          xs: 12,
                          sm: 12,
                          lg: getDashboardItemCardWidth({
                            item: cardItem,
                            itemIndex,
                            cardItemsOrder
                          })
                        }}
                      >
                        <Appointments
                          isBookingFetching={isBookingFetching}
                          selectedDate={selectedDate}
                          bookings={bookings}
                          selectedDepartmentId={
                            selectedDepartments.length
                              ? selectedDepartments.map((item) => item.id)[0] || null
                              : null
                          }
                        />
                      </Grid>
                    </Can>
                  );
                }
                return <></>;
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardV3;
