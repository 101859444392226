import * as React from "react";
import { startCase } from "lodash";
import { useDispatch } from "react-redux";
import { Box, Chip, Typography } from "@mui/material";

import "./Reports.scss";
import Can from "../Policy/Can";
import Filters from "./Filters";
import * as reportsApi from "../../api/reports";
import { tl, t } from "../../components/translate";
import { downloadExcel } from "../../helpers/files";
import { BillType } from "../../interfaces/BillInterfaces";
import { notificationAdd } from "../../actions/notification";
import useIsReactNativeWebView from "../../hooks/useIsReactNativeWebView";
import * as calFns from "../../components/Calendar/functions/calendarFunctions";
import { formatDataForExcel, formatHeaderForExcel } from "../accounts/Reports/helper";
import List, { EmptyView, ListActions, Menu, MenuItem } from "../../components/OList";

type IPDBillsReportType = BillType & {
  __meta__row_type: string;
  clientName: string;
  services: string;
  enteredBy: string;
};

const docColumns = () => [
  t("reports.proformaBills.issueDate"),
  t("reports.proformaBills.clientName"),
  t("reports.proformaBills.status"),
  t("reports.proformaBills.services"),
  t("reports.proformaBills.enteredBy"),
  t("reports.proformaBills.total")
];

const docRowProcessor = ({
  issueDate,
  clientName,
  status,
  services,
  enteredBy,
  total,
  // eslint-disable-next-line camelcase
  __meta__row_type
}: // eslint-disable-next-line camelcase
IPDBillsReportType) => {
  // eslint-disable-next-line camelcase
  if (__meta__row_type === "segment_summary") return;
  // eslint-disable-next-line consistent-return
  return [
    calFns.bsFullDate(issueDate) || "-",
    clientName || "-",
    status || "-",
    services || "-",
    enteredBy || "-",
    total || "-"
  ];
};

export default function IPDBillsReport(): JSX.Element {
  const dispatch = useDispatch();
  const { isRnWebView } = useIsReactNativeWebView();
  const [ipdBillsData, setIpdBillsData] = React.useState<BillType[]>([]);
  const [isRequestLoading, setIsRequestLoading] = React.useState(false);

  const [filters, setFilters] = React.useState({
    from: calFns.startOfDay(new Date()).toDate(),
    until: new Date(),
    clientIds: [],
    billStatus: ""
  });

  React.useEffect(() => {
    (async () => {
      try {
        setIsRequestLoading(true);
        const data = await reportsApi.getIpdBillsReport({ ...filters });
        setIpdBillsData(data);
      } catch (e) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Failed to fetch report!",
            autoTimeout: true
          })
        );
      } finally {
        setIsRequestLoading(false);
      }
    })();
  }, [filters]);

  return (
    <Can policyAccessKey="report:*">
      <Box overflow="auto hidden">
        <Box minWidth={isRnWebView ? "1100px" : "auto"}>
          <Box margin="0px 32px">
            <Filters
              filters={filters}
              onSetFilters={(f) => setFilters(f)}
              isRequestLoading={isRequestLoading}
            />
          </Box>

          <Box marginTop="32px" height="calc(100vh - 225px)" className="clientLedgerReport">
            <List
              automation="proformaReportList"
              data={ipdBillsData}
              rowHeight={50}
              defaultSortColumn="clientFullName"
              defaultSortOrder={1}
              activeRow={0}
              adjustHeightToContents
              columns={[
                {
                  key: "issueDate",
                  label: tl("reports.proformaBills.issueDate"),
                  formatter: (row) => (
                    <Typography>{calFns.bsFullDate(row?.transactionDate)}</Typography>
                  ),
                  sortable: true
                },

                {
                  key: "services",
                  label: tl("reports.proformaBills.services"),
                  formatter: ({ services }) => {
                    const title = (services || []).length ? `- ${services.join("\n- ")}` : "";
                    return (
                      <Box title={title} justifyContent="center" alignItems="center">
                        {services.map(
                          (service, index) =>
                            index < 2 &&
                            service.length > 0 && (
                              <Chip
                                size="small"
                                label={service}
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${service}_${index}`}
                                style={{
                                  maxWidth: services.length > 1 ? "140px" : "100%"
                                }}
                              />
                            )
                        )}
                        {services.length > 2 && "..."}
                      </Box>
                    );
                  }
                },
                {
                  key: "clientName",
                  label: tl("reports.proformaBills.clientName"),
                  formatter: (row) => <Typography>{row.clientName.toUpperCase()}</Typography>,
                  segmentable: true,
                  segmentBy: (row) => row.clientName,
                  sortable: true
                },
                {
                  key: "status",
                  label: tl("reports.proformaBills.status"),
                  formatter: (row) => <Typography>{startCase(row.status)}</Typography>,
                  sortable: true
                },
                {
                  key: "total",
                  label: tl("reports.proformaBills.total"),
                  formatter: (row) => <Typography>{row.total || "-"}</Typography>
                }
              ]}
              segementSummaryRenderer={(acc) => (
                <Box
                  bgcolor="#e6e6e6"
                  display="flex"
                  px="20px"
                  justifyContent="space-between"
                  width="100%"
                  alignItems="center"
                >
                  <Typography>{acc.segment}</Typography>
                </Box>
              )}
            >
              <EmptyView>
                <Box textAlign="center" padding="50px">
                  No items to show.
                </Box>
              </EmptyView>
              <ListActions>
                {({ getProcessedData }) => (
                  <Menu>
                    <MenuItem
                      onClick={async () => {
                        try {
                          await downloadExcel(
                            t("reports.receiptReport", "en"),
                            formatHeaderForExcel(docColumns()),
                            formatDataForExcel(getProcessedData(), docRowProcessor)
                          );
                        } catch (err) {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Failed to download Excel.",
                              autoTimeout: true
                            })
                          );
                        }
                      }}
                    >
                      {tl("reports.excel")}
                    </MenuItem>
                  </Menu>
                )}
              </ListActions>
            </List>
          </Box>
        </Box>
      </Box>
    </Can>
  );
}
