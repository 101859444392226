import * as React from "react";
import { Box, Card, Grid2 as Grid, Typography } from "@mui/material";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import InventoryIcon from "@mui/icons-material/Inventory";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import EuroIcon from "@mui/icons-material/Euro";
import HistoryIcon from "@mui/icons-material/History";
import GppMaybeIcon from "@mui/icons-material/GppMaybe";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import SummarizeIcon from "@mui/icons-material/Summarize";
import styles from "./Reports.module.css";
import { tl } from "../../components/translate";
import Can from "../Policy/Can";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../mixpanel-analytics/event";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";

const ReportsStock = (): JSX.Element => {
  const dispatch = useDispatch();
  const rc = useCurrentResourceCentre();

  return (
    <Box height="100%" padding="0 32px">
      <Typography fontSize="20px" fontWeight={600} lineHeight="40px" component="div">
        {tl("reports.stock")}
      </Typography>
      <Box marginTop="32px">
        <Can policyAccessKey="report:stockAuditReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_STOCK_AUDIT, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/stock/stockAuditReport"));
            }}
            data-testmation="stockAuditReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    Stock Audit Report
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <VerifiedUserIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="report:stockCashFlowReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_STOCK_CASHFLOW, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/stock/stockCashFlowReport"));
            }}
            data-testmation="stockCashFlowReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    {tl("reports.stockCashFlowReport")}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <EuroIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="report:stockCashFlowReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_SUPPLIER_LEDGER, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/stock/supplierLedgerReport"));
            }}
            data-testmation="supplierLedgerReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    Supplier Ledger Report
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <InsertChartIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="report:stockAuditReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_STOCK_TRANSACTION, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/stock/stockTransactions"));
            }}
            data-testmation="stockTransactionsReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    Stock Transactions
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <InventoryIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="report:stockAuditReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_STOCK_HISTORY, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/stock/stockHistory"));
            }}
            data-testmation="stockHistoryReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    Stock History Report
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <HistoryIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_STOCK_SUMMARY, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/stock/stockSummary"));
            }}
            data-testmation="stockSummaryReportCard"
          >
            <Grid container>
              <Grid size={8}>
                <Typography fontWeight="700" fontSize="24px">
                  Stock Summary Report
                </Typography>
              </Grid>
              <Grid size={4}>
                <SummarizeIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_STOCK_EXPIRY, {
              rcId: rc.id,
              rcName: rc.name
            });
            return dispatch(push("/reports/stock/stockExpiry"));
          }}
          data-testmation="stockExpiryReportCard"
        >
          <Grid container>
            <Grid size={8}>
              <Typography fontWeight="700" fontSize="24px">
                Stock Expiry Report
              </Typography>
            </Grid>
            <Grid size={4}>
              <GppMaybeIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_STOCK_LEDGER, {
              rcId: rc.id,
              rcName: rc.name
            });
            return dispatch(push("/reports/stock/stockLedger"));
          }}
          data-testmation="stockLedgerReportCard"
        >
          <Grid container>
            <Grid size={8}>
              <Typography fontWeight="700" fontSize="24px">
                Stock Ledger Report
              </Typography>
            </Grid>
            <Grid size={4}>
              <TextSnippetIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Box>
  );
};

export default ReportsStock;
