import * as React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { useLocation } from "react-router";
import { ResponsivePopperOrModal } from "../Schedule/SingleBookedSlotPill";
import BookingCreateEdit from "./Booking/BookingCreateEdit";
import SlotCreateEdit from "./Slot/SlotCreateEdit";
import BookingInfo from "./Booking/BookingInfo";
import * as scheudleActions from "../../actions/schedules";
import * as bookingActions from "../../actions/booking";
import { getSchedule } from "../../helpers/schedule";
import { schdulesFromOkhatiEvents } from "../../reducers/bookings";
import { getClientById } from "../../actions/client";
import SlotInfo from "./Slot/ScheduleInfo";
import Can from "../Policy/Can";

const EventPopup = ({
  anchorEl,
  eventType = "booking",
  event,
  events,
  infoMode,
  onClose,
  onSave,
  onChange,
  onEdit,
  onTabChange,
  onBookingSave,
  onScheduleSave,
  calStart,
  calEnd,
  selectedSP,
  selectedResource,
  disableEdit,
  onCreateBookigFromBookingInfo,
  createFollowUpFromBookingInfoPanel
}) => {
  const location = useLocation();
  const params = queryString.parse(location.search);

  const infoView = () =>
    eventType === "booking" ? (
      <BookingInfo
        disableEdit={disableEdit}
        booking={event}
        onClose={onClose}
        onEdit={onEdit}
        onCreateBookigFromBookingInfo={onCreateBookigFromBookingInfo}
      />
    ) : (
      <SlotInfo
        onClose={onClose}
        schedule={event}
        calStart={calStart}
        calEnd={calEnd}
        selectedSP={selectedSP}
        selectedResource={selectedResource}
      />
    );

  const bookingOrScheduleView = (eType: string) => {
    if (eType === "booking") {
      return (
        <Can policyAccessKey="booking:createBooking">
          <BookingCreateEdit
            booking={event}
            events={events}
            onChange={onChange}
            onClose={(e) => onClose(e)}
            onTabChange={onTabChange}
            createFollowUpFromBookingInfoPanel={createFollowUpFromBookingInfoPanel}
            onSave={async (data) => {
              await onBookingSave(data);
              onSave(data.serviceProviderId);
            }}
          />
        </Can>
      );
    }
    return (
      <Can policyAccessKey="booking:createSchedule">
        <SlotCreateEdit
          schedule={event}
          onClose={onClose}
          onChange={onChange}
          onTabChange={onTabChange}
          onSave={async (data) => {
            await onScheduleSave(data);
            onSave();
          }}
        />
      </Can>
    );
  };

  return (
    <ResponsivePopperOrModal
      open
      popperZIndex={params.clientId ? 1299 : 2}
      anchorEl={anchorEl}
      onClose={(e) => {
        if (infoMode) {
          onClose(e);
        }
      }}
    >
      {infoMode ? infoView() : bookingOrScheduleView(eventType)}
    </ResponsivePopperOrModal>
  );
};

export default connect(
  () => ({}),
  (dispatch) => ({
    onBookingSave: async (booking) => {
      await dispatch(async (d: dispatch, getState) => {
        if (booking.id) {
          const {
            start,
            end,
            services,
            remarks,
            isVideoAppointment,
            paymentInfo,
            reminders,
            followUp,
            clientKnownFrom,
            referredBy,
            serviceProviderId,
            clientInternalNotes,
            referrerId,
            departmentId
          } = booking;
          await d(
            bookingActions.updateBooking(
              booking.id,
              {
                start,
                end,
                services,
                remarks,
                isVideoAppointment,
                paymentInfo,
                reminders,
                followUp,
                clientKnownFrom,
                referredBy,
                serviceProviderId,
                clientInternalNotes,
                referrerId,
                departmentId
              },
              null
            )
          );
        } else {
          const event = getSchedule(
            booking.start,
            booking.end,
            schdulesFromOkhatiEvents(getState().schedules.collection)
          );
          await d(bookingActions.book({ ...booking, eventId: event?.id }));
          if (booking.clientId && booking.clientEmail) {
            d(getClientById(booking.clientId));
          }
        }
      });
    },
    onScheduleSave: async (schedule) => {
      await dispatch(scheudleActions.saveSchedule(schedule));
    }
  })
)(EventPopup);
