import React from "react";
import { Box, Typography } from "@mui/material";
import { ResourceCentre } from "../../LabprintComponents/LabPrintLetterhead";
import LabPrintFooter from "../../LabprintComponents/LabPrintFooter";
import LabPrintClientInfo from "../../LabprintComponents/LabPrintClientInfo";
import LabPrintLabInfo from "../../LabprintComponents/LabPrintLabInfo";
import AuthorizedSPInfo, { AuthorizedSp } from "../../LabprintComponents/AuthorizedSPInfo";
import LabPrintCoreData from "./CategoryHeaderTemplateCore";
import {
  applySpaciousMargins,
  getSectionStyle,
  groupLabTestByCategory,
  scaleFont,
  showDynamicSignature
} from "../../LabPrintFunctions";

import { LabObject, LabPrintSettings } from "../../../../../interfaces/LabInterfaces";
import { CustomEl } from "../../../LabEntry/LabTestSubRow";
import InfoField from "../../LabprintComponents/LabPrintInfofield";
import { getFooterHeight } from "../../labPrintHelpers";
import { getLabHeader } from "../A5/A5Template";
import CustomHeader from "../../CustomHeader";

interface ExtraProps {
  unfixFooter?: boolean;
}

interface SectionedTemplateProps {
  resourceCentre: ResourceCentre;
  labData: LabObject;
  settings: LabPrintSettings;
  otherProps?: ExtraProps;
  showAdditionalLabData: boolean;
  showDobOnLabPrint: boolean;
}

const CategoryHeaderTemplate = ({
  settings,
  resourceCentre,
  labData,
  otherProps,
  showAdditionalLabData,
  showDobOnLabPrint
}: SectionedTemplateProps): JSX.Element => {
  const authorizedLabSp1: AuthorizedSp = labData.results?.approvedBy?.authorizedSP1 || null;
  const authorizedLabSp2: AuthorizedSp = labData.results?.approvedBy?.authorizedSP2 || null;
  const authorizedLabSp3: AuthorizedSp = labData.results?.approvedBy?.authorizedSP3 || null;
  const authorizedLabSp4: AuthorizedSp = labData.results?.approvedBy?.authorizedSP4 || null;

  const {
    showLetterhead,
    showFooter,
    headerColor,
    dateSetting,
    showPrintedOnTime,
    showReportOnTime,
    showSampleTakenTime,
    showSampleTakenDate,
    showReportOnDate,
    showPrintedOnDate,
    spaciousMargins,
    centralizedHeader,
    clinicNameFontScale,
    showLabRemarks,
    labRemarks,
    showMethods,
    showPhoto,
    showCustomerIdentifier,
    customTitle,
    showRanges,
    showUnit,
    showReading,
    hideFooterLogoCompletely,
    showRangeFlag,
    useCustomLabTestNumber,
    showQrWhenFooterIsHidden,
    showSampleCollectedBy,
    customHeaderImage
  } = settings;

  const profileImage = labData.results?.client?.profileImage || null;

  return (
    <div>
      <Box component="table" width="100%">
        <thead>
          {!showLetterhead && (
            <tr>
              <td>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <div style={{ height: "3.5cm" }} />
              </td>
            </tr>
          )}
          {showLetterhead && (
            <tr>
              <td>
                <Box sx={{ ...getSectionStyle(headerColor).headerStyle }}>
                  {showLetterhead &&
                    (customHeaderImage ? (
                      <CustomHeader imageUrl={customHeaderImage} />
                    ) : (
                      getLabHeader(centralizedHeader, resourceCentre, clinicNameFontScale)
                    ))}
                </Box>
              </td>
            </tr>
          )}
        </thead>

        <tbody>
          <tr>
            <td>
              <Box sx={{ ...getSectionStyle(headerColor).bodyStyle }}>
                {spaciousMargins && applySpaciousMargins()}
                <Typography
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "0.5cm",
                    marginBottom: "0.8cm",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    minHeight: "0.5cm"
                  }}
                >
                  {customTitle === null || customTitle === undefined
                    ? "Lab Test Result"
                    : customTitle}
                </Typography>
                {spaciousMargins && applySpaciousMargins(2)}
                <Box
                  display="flex"
                  marginBottom="0.5cm"
                  justifyContent="space-between"
                  border="1px solid black"
                  p="0.03cm 0.3cm"
                  gap={2}
                >
                  <Box flexGrow={1}>
                    <LabPrintClientInfo
                      showNameAndAgeInDiffRow
                      ipd={
                        labData?.ipd
                          ? {
                              ...labData.ipd,
                              bedNumber: labData.results?.bed?.name || ""
                            }
                          : {}
                      }
                      client={labData.client}
                      customerNumber={labData.customer?.customerNumber}
                      customerIdentifier={
                        (showCustomerIdentifier && labData.customer?.externalIdentifier) || ""
                      }
                      showDobOnLabPrint={showDobOnLabPrint}
                    />
                    {labData.labIdentifier && (
                      <InfoField
                        label="Lab Id"
                        data={labData.labIdentifier}
                        labelStyle={{ width: "2.9cm", fontSize: scaleFont("0.28cm", 1.2) }}
                        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
                      />
                    )}
                    {labData.referrers && (
                      <InfoField
                        label="Referred By"
                        data={labData.referrers.toUpperCase()}
                        labelStyle={{ width: "2.9cm", fontSize: scaleFont("0.28cm", 1.2) }}
                        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
                      />
                    )}
                    {labData.extraReferrer?.name && (
                      <InfoField
                        label="Extra Referrer"
                        data={labData.extraReferrer.name.toUpperCase()}
                        labelStyle={{ width: "2.9cm", fontSize: scaleFont("0.28cm", 1.2) }}
                        valueStyle={{ fontSize: scaleFont("0.28cm", 1.2) }}
                      />
                    )}
                  </Box>
                  <LabPrintLabInfo
                    dateSetting={dateSetting}
                    labTestId={
                      useCustomLabTestNumber ? labData.labTestNumber || labData.id : labData.id
                    }
                    showPrintedOnTime={showPrintedOnTime}
                    showReportOnTime={showReportOnTime}
                    showSampleTakenTime={showSampleTakenTime}
                    showSampleTakenDate={showSampleTakenDate}
                    showPrintedOnDate={showPrintedOnDate}
                    showReportOnDate={showReportOnDate}
                    sampleRemarks={
                      Array.isArray(labData.remarks) && labData.remarks.length > 0
                        ? labData.remarks[0].remark
                        : ""
                    }
                    collectionDate={labData.collectionDate}
                    resultDate={labData.resultDate}
                    modifiedDate={labData.results.modifiedDate || null}
                  />
                  {showPhoto && (
                    <Box width="2.5cm" display="flex" justifyContent="flex-end">
                      {profileImage && (
                        <img
                          src={profileImage}
                          style={{
                            height: "auto",
                            width: "2.5cm",
                            objectFit: "cover",
                            ...(profileImage && { minHeight: "4cm" })
                          }}
                          alt="Profile"
                        />
                      )}
                    </Box>
                  )}
                </Box>
                <>
                  {spaciousMargins && applySpaciousMargins(4)}
                  <div>
                    <LabPrintCoreData
                      labData={groupLabTestByCategory(labData)}
                      showAdditionalLabData={showAdditionalLabData}
                      showMethods={showMethods}
                      showRanges={showRanges}
                      showUnit={showUnit}
                      showReading={showReading}
                      showRangeFlag={showRangeFlag}
                      showSampleCollectedBy={showSampleCollectedBy}
                    />
                  </div>
                  {spaciousMargins && applySpaciousMargins()}
                  {labData.results?.currentApprovedUser ? (
                    <Box
                      sx={{
                        pageBreakInside: "avoid",
                        display: "flex",
                        mt: "0.1cm",
                        justifyContent: "space-between"
                      }}
                    >
                      <AuthorizedSPInfo sp={labData.results?.currentApprovedUser} />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        pageBreakInside: "avoid",
                        display: "flex",
                        gap: "30px",
                        justifyContent: "space-between"
                      }}
                    >
                      {showDynamicSignature(authorizedLabSp1) && (
                        <div>
                          <AuthorizedSPInfo sp={authorizedLabSp1} />
                        </div>
                      )}
                      {showDynamicSignature(authorizedLabSp2, authorizedLabSp1) && (
                        <div>
                          <AuthorizedSPInfo sp={authorizedLabSp2} />
                        </div>
                      )}
                      {showDynamicSignature(
                        authorizedLabSp3,
                        authorizedLabSp1,
                        authorizedLabSp2
                      ) && (
                        <div>
                          <AuthorizedSPInfo sp={authorizedLabSp3} />
                        </div>
                      )}
                      {showDynamicSignature(
                        authorizedLabSp4,
                        authorizedLabSp1,
                        authorizedLabSp2,
                        authorizedLabSp3
                      ) && (
                        <div>
                          <AuthorizedSPInfo sp={authorizedLabSp4} />
                        </div>
                      )}
                    </Box>
                  )}

                  {showLabRemarks && (
                    <Typography sx={{ marginTop: "16px" }}>
                      <CustomEl data={labRemarks} />
                    </Typography>
                  )}
                </>
              </Box>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td>
              {/* footer offset */}
              <div
                style={{
                  height: getFooterHeight({
                    showFooter,
                    showQrWhenFooterIsHidden
                  })
                }}
              />
            </td>
          </tr>
        </tfoot>
      </Box>
      <LabPrintFooter
        uuid={labData.uuid}
        headerColor={headerColor}
        unfixFooter={otherProps?.unfixFooter}
        showFooter={showFooter}
        hideFooterLogoCompletely={hideFooterLogoCompletely}
        showQRCode={showQrWhenFooterIsHidden}
      />
    </div>
  );
};

export default CategoryHeaderTemplate;
