import React from "react";
import { Box, Typography } from "@mui/material";
import { ResourceCentre } from "../../LabprintComponents/LabPrintLetterhead";
import LabPrintFooter from "../../LabprintComponents/LabPrintFooter";
import AuthorizedSPInfo from "./AuthorizedSPInfo";
import LabPrintCoreData from "./GovernmentTemplateCore";
import {
  getSectionStyle,
  groupLabTestByCategory,
  showDynamicSignature
} from "../../LabPrintFunctions";
import { LabObject, LabPrintSettings } from "../../../../../interfaces/LabInterfaces";
import { AuthorizedSp } from "../../LabprintComponents/AuthorizedSPInfo";
import dummy from "../../../../../../assets/images/dummyProfile.png";
import GovLetterhead from "../../LabprintComponents/GovLetterhead";
import LabPrintClientInfoGov from "../../LabprintComponents/LabPrintClientInfoGov";
import { CustomEl } from "../../../LabEntry/LabTestSubRow";
import LabGovPrintInfoField from "../../LabprintComponents/LabGovPrintInfoField";
import LabGovPrintLabInfo from "../../LabprintComponents/LabGovPrintLabInfo";
import { t } from "../../../../../components/translate";
import CustomHeader from "../../CustomHeader";
import { spFullNameSelector } from "../../../../../reducers/serviceProvider";

interface ExtraProps {
  unfixFooter?: boolean;
}

interface GovernmentTemplateProps {
  resourceCentre: ResourceCentre;
  labData: LabObject;
  settings: LabPrintSettings;
  otherProps?: ExtraProps;
  showAdditionalLabData: boolean;
  showDobOnLabPrint?: boolean;
}

type AuthorizedSps = {
  authorizedLabSp1: AuthorizedSp | null;
  authorizedLabSp2: AuthorizedSp | null;
  authorizedLabSp3: AuthorizedSp | null;
  authorizedLabSp4: AuthorizedSp | null;
};

function getAuthorizedSPs(labData): AuthorizedSps {
  const ret = {} as AuthorizedSps;
  const approvedBy = labData?.results?.approvedBy;
  if (approvedBy) {
    const {
      authorizedSP1 = null,
      authorizedSP2 = null,
      authorizedSP3 = null,
      authorizedSP4 = null
    } = approvedBy;
    ret.authorizedLabSp1 = authorizedSP1;
    ret.authorizedLabSp2 = authorizedSP2;
    ret.authorizedLabSp3 = authorizedSP3;
    ret.authorizedLabSp4 = authorizedSP4;
  }
  return ret;
}

const GovernmentTemplate: React.FC<GovernmentTemplateProps> = ({
  settings,
  resourceCentre,
  labData,
  otherProps,
  showAdditionalLabData,
  showDobOnLabPrint
}) => {
  const { authorizedLabSp1, authorizedLabSp2, authorizedLabSp3, authorizedLabSp4 } =
    getAuthorizedSPs(labData);

  const {
    showLetterhead,
    showFooter,
    headerColor,
    dateSetting,
    showPrintedOnTime,
    showReportOnTime,
    showSampleTakenTime,
    showSampleTakenDate,
    showReportOnDate,
    showPrintedOnDate,
    clinicNameFontScale,
    showLabRemarks,
    labRemarks,
    showMethods,
    showPhoto,
    showCustomerIdentifier,
    customTitle,
    govTemplateHeaderText1,
    govTemplateHeaderText2,
    showRanges,
    showUnit,
    showReading,
    showRangeFlag,
    hideFooterLogoCompletely,
    useCustomLabTestNumber,
    showSampleCollectedBy,
    customHeaderImage
  } = settings;

  const profileImage = labData.results?.client?.profileImage || (showPhoto ? dummy : null);

  const clientInfoComponent = (
    <Box sx={{ display: "flex", gap: 1, width: "100%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          border: "1px solid #bbb",
          padding: "0.15cm",
          gap: 1,
          width: "100%"
        }}
      >
        <LabPrintClientInfoGov
          client={labData.client}
          customerNumber={labData.customer?.customerNumber}
          labId={labData.id}
          customerIdentifier={
            (showCustomerIdentifier && labData.customer?.externalIdentifier) || ""
          }
          ipd={labData.ipd ? { ...labData.ipd, bed: labData.results.bed } : {}}
          showDobOnLabPrint={showDobOnLabPrint}
        />
        <Box sx={{ width: "5.3cm" }}>
          <LabGovPrintInfoField
            label="Test ID"
            data={<>{useCustomLabTestNumber ? labData.labTestNumber || labData.id : labData.id}</>}
          />
          {labData.labIdentifier && (
            <LabGovPrintInfoField label="Lab Id" data={labData.labIdentifier} />
          )}
          {labData.client.address && (
            <LabGovPrintInfoField label={t("address")} data={<>{labData.client.address}</>} />
          )}
          {labData.client.passportInfo?.passportNumber && (
            <LabGovPrintInfoField
              label="Passport no"
              data={<>{labData.client.passportInfo.passportNumber}</>}
            />
          )}
          {labData.client.nationality && (
            <LabGovPrintInfoField label={t("nationality")} data={labData.client.nationality} />
          )}

          <LabGovPrintInfoField
            label="Ordered By"
            data={
              labData?.serviceProvider
                ? spFullNameSelector(labData.serviceProvider)
                : t("labTest.orderBy.self")
            }
          />

          {labData.referrers && (
            <LabGovPrintInfoField label="Referred By" data={labData.referrers.toUpperCase()} />
          )}
          {labData.extraReferrer?.name && (
            <LabGovPrintInfoField
              label="Extra Referrer"
              data={labData.extraReferrer.name.toUpperCase()}
            />
          )}
        </Box>
        <LabGovPrintLabInfo
          dateSetting={dateSetting}
          showPrintedOnTime={showPrintedOnTime}
          showReportOnTime={showReportOnTime}
          showSampleTakenTime={showSampleTakenTime}
          showSampleTakenDate={showSampleTakenDate}
          showPrintedOnDate={showPrintedOnDate}
          showReportOnDate={showReportOnDate}
          collectionDate={labData.collectionDate}
          resultDate={labData.resultDate}
          labIdentifier={labData.labIdentifier || ""}
          modifiedDate={labData.results.modifiedDate || null}
        />
      </Box>
      {profileImage && (
        <Box width="2.5cm" display="flex" justifyContent="flex-end">
          <img
            src={profileImage}
            style={{
              height: "3cm",
              width: "2.5cm",
              objectFit: "cover"
            }}
            alt="profile"
          />
        </Box>
      )}
    </Box>
  );
  return (
    <table style={{ width: "100%" }}>
      <thead>
        <tr>
          <td>
            {!showLetterhead && (
              <Box width="100%" style={{ ...getSectionStyle("").headerStyle }}>
                {clientInfoComponent}
              </Box>
            )}
            {showLetterhead && (
              <>
                <Box
                  // look later
                  width={otherProps?.unfixFooter ? "100%" : "100%"}
                  sx={{
                    backgroundColor: headerColor || "none",
                    color: headerColor ? "white" : "black",
                    paddingRight: "10mm",
                    paddingLeft: "10mm",
                    paddingTop: "2mm",
                    paddingBottom: "2mm",
                    marginBottom: "1mm"
                  }}
                >
                  {customHeaderImage ? (
                    <CustomHeader imageUrl={customHeaderImage} />
                  ) : (
                    <GovLetterhead
                      resourceCentre={resourceCentre}
                      titleFontScale={clinicNameFontScale}
                      govHeader1={govTemplateHeaderText1}
                      govHeader2={govTemplateHeaderText2}
                    />
                  )}
                </Box>
                <Box
                  style={{
                    marginLeft: "10mm",
                    marginRight: "10mm",
                    marginBottom: "1mm"
                  }}
                >
                  {clientInfoComponent}
                </Box>
              </>
            )}
          </td>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td>
            <div style={{ ...getSectionStyle(headerColor).bodyStyle }}>
              {customTitle !== "" && (
                <Typography
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "0.5cm",
                    marginBottom: "0.1cm",
                    fontWeight: 600,
                    textTransform: "uppercase"
                  }}
                >
                  {customTitle === null || customTitle === undefined
                    ? "Lab Test Result"
                    : customTitle}
                </Typography>
              )}
              <Box mt="0.3cm">
                <LabPrintCoreData
                  showAdditionalLabData={showAdditionalLabData}
                  labData={groupLabTestByCategory(labData)}
                  headerColor={headerColor}
                  showMethods={showMethods}
                  showRanges={showRanges}
                  showUnit={showUnit}
                  showReading={showReading}
                  showRangeFlag={showRangeFlag}
                  showSampleCollectedBy={showSampleCollectedBy}
                />
              </Box>
              {showLabRemarks && (
                <Typography style={{ marginTop: "0.15cm" }}>
                  <CustomEl data={labRemarks} />
                </Typography>
              )}
            </div>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td>
            {/* position fixed offset */}
            <Box height="7.5cm" marginTop="-0.5cm" />
            <Box
              position={otherProps?.unfixFooter ? "static" : "fixed"}
              bottom="0px"
              height="7.5cm"
            >
              {labData.results?.currentApprovedUser ? (
                <Box
                  style={{ pageBreakInside: "avoid" }}
                  display="flex"
                  width={otherProps?.unfixFooter ? "96%" : "100vw"}
                  mt="0.1cm"
                  justifyContent="space-between"
                  px="24px"
                >
                  <AuthorizedSPInfo sp={labData.results?.currentApprovedUser} />
                </Box>
              ) : (
                <Box
                  style={{ pageBreakInside: "avoid" }}
                  display="flex"
                  width={otherProps?.unfixFooter ? "96%" : "100vw"}
                  mt="0.1cm"
                  justifyContent="space-between"
                  px="24px"
                >
                  {showDynamicSignature(authorizedLabSp1) && (
                    <Box>
                      <AuthorizedSPInfo sp={authorizedLabSp1} />
                    </Box>
                  )}
                  {showDynamicSignature(authorizedLabSp2, authorizedLabSp1) && (
                    <Box>
                      <AuthorizedSPInfo sp={authorizedLabSp2} />
                    </Box>
                  )}
                  {showDynamicSignature(authorizedLabSp3, authorizedLabSp1, authorizedLabSp2) && (
                    <Box>
                      <AuthorizedSPInfo sp={authorizedLabSp3} />
                    </Box>
                  )}
                  {showDynamicSignature(
                    authorizedLabSp4,
                    authorizedLabSp1,
                    authorizedLabSp2,
                    authorizedLabSp3
                  ) && (
                    <Box>
                      <AuthorizedSPInfo sp={authorizedLabSp4} />
                    </Box>
                  )}
                </Box>
              )}

              <LabPrintFooter
                uuid={labData.uuid}
                headerColor={headerColor}
                id={labData.id}
                unfixFooter={otherProps?.unfixFooter}
                hideFooterLogoCompletely={hideFooterLogoCompletely}
                showFooter={showFooter}
              />
            </Box>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

GovernmentTemplate.defaultProps = {
  otherProps: {}
};

export default GovernmentTemplate;
