import React, { JSX, useEffect, useMemo } from "react";
import { Controller, useForm, FieldValues } from "react-hook-form";
import { Box, Button, Checkbox, FormControlLabel, Grid2 as Grid } from "@mui/material";
import { startCase } from "lodash";
import { Client } from "../../../interfaces/ClientInterface";
import FormTextField from "../../../components/FormTextField/FormTextField";
import MaterialUiPhoneNumber from "../../../components/PhoneNumber";
import { isValidPhone } from "../../../helpers/phoneNumber";
import AddressSelect from "../../Address/AddressSelect";
import { HMISServiceType } from "../../../interfaces/HmisInterface";
import { ethnicityOptions, fields, genderOptions } from "../../../models/Client";
import ReferrerCreateSelect from "../../Referrers/ReferrerCreateSelect";
import { isISODateString } from "../../../helpers/validators";
import ToggleInputDate from "../../../components/ToggleADBS";
import { useAppDispatch } from "../../../store/hooks";
import { postHmis } from "../../../api/hmis";
import { notificationAdd } from "../../../actions/notification";
import { t } from "../../../components/translate";
import { errorFetchMessage, MODULE } from "../../../helpers/messages";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import * as hmisActions from "../../../actions/hmis";

interface Props {
  client?: Client;
  onClose: () => void;
  nextMulDartaNumber: string;
}
const MulDartaForm = ({
  client = {} as Client,
  onClose,
  nextMulDartaNumber
}: Props): JSX.Element => {
  const dispatch = useAppDispatch();

  const formData = useMemo(
    () => ({
      firstName: client?.firstName || "",
      lastName: client?.lastName || "",
      dob: client?.dob || "",
      gender: client?.gender,
      ethnicity: client?.ethnicity,
      phone: client?.phone || "",
      palikaId: client?.palikaId || "",
      wardNo: client?.wardNo || "",
      isFirstTime: !client?.id,
      serviceType: "",
      serviceCharge: "",
      referrerId: "",
      mulDartaNo: nextMulDartaNumber || ""
    }),
    [client]
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting }
  } = useForm<FieldValues>({
    defaultValues: {
      ...formData
    }
  });

  useEffect(() => {
    reset(formData);
  }, [client, formData]);

  const onSubmit = async (data: FieldValues) => {
    const postData = {
      client: {
        firstName: data.firstName,
        lastName: data.lastName,
        dob: data.dob,
        gender: data.gender,
        ethnicity: data.ethnicity,
        phone: data.phone,
        palikaId: data.palikaId,
        wardNo: data.wardNo,
        ...(client.id ? { id: client.id } : {})
      },
      himsServiceType: data.serviceType,
      serviceCharge: data.serviceCharge,
      referrerId: data.referrerId,
      followup: !data.isFirstTime,
      mulDartaNo: data.mulDartaNo
    };

    try {
      await postHmis(postData);
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Successfully created",
          autoTimeout: true
        })
      );
      onClose();
      await dispatch(hmisActions.getMulDarta({ pageSize: 20, page: 0 }));
    } catch (e) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: e?.data?.message || errorFetchMessage(MODULE.MUL_DARTA),
          autoTimeout: true
        })
      );
    }
  };

  return (
    <div>
      <Grid
        container
        spacing={2}
        component="form"
        id="mulDartaForm"
        onSubmit={handleSubmit(onSubmit)}
      >
        <Grid size={12}>
          <Controller
            name="isFirstTime"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                // eslint-disable-next-line react/jsx-props-no-spreading
                control={<Checkbox {...field} checked={field.value} />}
                label={t("hmis.isFirstTime")}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormTextField
            name="firstName"
            control={control}
            rules={{ required: "First name is required" }}
            label={t("hmis.firstName")}
            dataTestmation=""
            errors={errors}
            fullWidth
            placeholder={t("hmis.form.name")}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormTextField
            name="lastName"
            control={control}
            rules={{ required: "Last name is required" }}
            label={t("hmis.lastName")}
            placeholder={t("hmis.form.surname")}
            dataTestmation=""
            errors={errors}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormTextField
            select
            options={Object.keys(HMISServiceType).map((item) => ({
              label: startCase(item),
              value: HMISServiceType[item as keyof typeof HMISServiceType]
            }))}
            rules={{
              required: "Service type is required"
            }}
            name="serviceType"
            control={control}
            label={t("hmis.serviceType")}
            placeholder={t("hmis.form.serviceType")}
            dataTestmation=""
            errors={errors}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormTextField
            name="mulDartaNo"
            rules={{
              required: "Mul darta number is required"
            }}
            control={control}
            label={t("hmis.mulDartaNo")}
            placeholder={t("hmis.mulDartaNo")}
            dataTestmation=""
            errors={errors}
            fullWidth
          />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 8
          }}
        >
          <Controller
            name="dob"
            control={control}
            rules={{
              required: "Date of birth is required",
              validate: (value) =>
                (value ? !!isISODateString(value) : true) || "Please enter valid date"
            }}
            render={({ field }) => (
              <ToggleInputDate
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                triggerInternalStateUpdateOnDateChange
                data-testmation="clientAgeInput"
                key="dob"
                field={fields[4]}
                data={{
                  dob: field.value
                }}
                changeDate={(value) => {
                  field.onChange(value);
                }}
                label="hmis.dob"
                monthLabel={t("hmis.month")}
                yearLabel={t("hmis.year")}
                error={!!errors?.dob?.message}
                helperText={errors?.dob?.message || ""}
                isFocused={() => ({})}
                isBlurred={() => ({})}
              />
            )}
          />
        </Grid>
        <Grid
          size={{
            xs: 12,
            sm: 4
          }}
        >
          <FormTextField
            name="gender"
            rules={{
              required: "Gender is required"
            }}
            control={control}
            select
            options={genderOptions}
            label={t("hmis.gender")}
            placeholder={t("hmis.form.gender")}
            dataTestmation=""
            errors={errors}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormTextField
            name="ethnicity"
            control={control}
            select
            rules={{
              required: "Ethnicity is required"
            }}
            options={ethnicityOptions}
            label={t("hmis.ethnicity")}
            placeholder={t("hmis.form.ethnicity")}
            dataTestmation=""
            errors={errors}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: "Phone number is required",
              validate: (value) =>
                (value ? isValidPhone(value) : true) || "Please enter a valid phone number"
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <MaterialUiPhoneNumber
                data-testmation="clientPhoneNumberInput"
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                fullWidth
                disableAreaCodes
                variant="outlined"
                defaultCountry="np"
                errorMessages={errors?.phone}
                error={!!errors?.phone}
                label={t("hmis.contactNumber")}
                helperText={errors?.phone?.message || ""}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Controller
            name="palikaId"
            control={control}
            rules={{
              required: "Address is required"
            }}
            render={({ field, fieldState }) => (
              <AddressSelect
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...field}
                label={t("hmis.palikaDistrictProvince")}
                placeholder={t("hmis.palikaDistrictProvince")}
                error={{
                  value: Boolean(fieldState.error),
                  message: fieldState.error?.message || ""
                }}
                selectedOption={field.value}
                cssStyle={{ marginTop: "0px" }}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormTextField
            name="wardNo"
            control={control}
            label={t("hmis.wardNo")}
            placeholder={t("hmis.form.wardNo")}
            dataTestmation=""
            errors={errors}
            fullWidth
            rules={{
              required: "Ward number is required"
            }}
          />
        </Grid>
        <Grid size={12}>
          <FormTextField
            select
            options={[
              { label: "Free", value: "free" },
              { label: "Non-Free", value: "nonFree" }
            ]}
            rules={{
              required: "Service charge is required"
            }}
            name="serviceCharge"
            control={control}
            label={t("hmis.form.serviceCharge")}
            placeholder={t("hmis.form.serviceCharge")}
            dataTestmation=""
            errors={errors}
            fullWidth
          />
        </Grid>
        <Grid size={12}>
          <Controller
            name="referrerId"
            control={control}
            rules={{
              required: "Referrer is required"
            }}
            render={({ field: { onChange, value }, fieldState }) => (
              <ReferrerCreateSelect
                onReferrerChange={(v) => onChange(v?.id || null)}
                label={t("hmis.referrerOrganization")}
                placeholder={t("hmis.form.referrerOrganization")}
                error={{
                  value: Boolean(fieldState.error),
                  message: fieldState.error?.message || ""
                }}
                referrerId={value}
                textFieldWidth="100%"
                width="100%"
                textFieldProps={{
                  InputLabelProps: { shrink: true }
                }}
              />
            )}
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: 4,
          px: 4,
          py: 2,
          position: "absolute",
          left: 0,
          right: 0,
          bottom: 0
        }}
      >
        <Button data-testmation="hmisCancel" onClick={onClose}>
          Cancel
        </Button>
        <StatefulButton
          data-testmation="mulDartaSave"
          type="submit"
          form="mulDartaForm"
          variant="contained"
          color="primary"
          circularProgressProps={{ size: 16 }}
          isLoading={isSubmitting}
          disabled={isSubmitting}
        >
          Save
        </StatefulButton>
      </Box>
    </div>
  );
};

export default MulDartaForm;
