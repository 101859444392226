import { Autocomplete, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { Client } from "../../../interfaces/ClientInterface";
import { searchMuldartaClients } from "../../../api/client";
import DebouncedTextField from "../../../components/DebouncedTextField";
import { notificationAdd } from "../../../actions/notification";
import { useAppDispatch } from "../../../store/hooks";
import { t } from "../../../components/translate";
import useAddressOptions from "../../../hooks/useAddressOptions";
import { errorFetchMessage, MODULE } from "../../../helpers/messages";

interface Props {
  value: Client | null;
  onChange: (value: Client | null) => void;
}

const ClientSelect = ({ value, onChange }: Props): JSX.Element => {
  const [clientList, setClientList] = useState<Client[]>([]);
  const [loading, setLoading] = useState(false);
  const addressOptions = useAddressOptions();
  const dispatch = useAppDispatch();

  return (
    <Autocomplete
      disablePortal
      value={value}
      onChange={(_, v) => onChange(v)}
      options={clientList}
      loading={loading}
      getOptionLabel={(option) => (option ? `${option.firstName} ${option.lastName}` : "")}
      renderOption={(params, option) => {
        const selectedPalika = addressOptions.find((item) => item.palikaId === option.palikaId);
        return (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...params} key={`${option?.id} ${params.key}`}>
            <Stack>
              <Typography>{`${option.firstName} ${option.lastName}`}</Typography>
              <Typography variant="caption">
                {option.phone && option.phone}
                {selectedPalika && option.phone && `, `}
                {selectedPalika
                  ? `${selectedPalika.palikaName} , ${selectedPalika.districtName} , ${selectedPalika.provinceName}`
                  : ""}
              </Typography>
            </Stack>
          </li>
        );
      }}
      renderInput={(params) => (
        <DebouncedTextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          setDebounceLoading={(debounceLoading) => setLoading(debounceLoading)}
          slotProps={{
            inputLabel: { shrink: true }
          }}
          onChange={async (e) => {
            if (e.target.value?.length > 2) {
              setLoading(true);
              try {
                const response = await searchMuldartaClients(e.target.value);
                setClientList(response);
                setLoading(false);
              } catch (err) {
                dispatch(
                  notificationAdd({
                    id: new Date().getUTCMilliseconds(),
                    variant: "error",
                    message: err?.data || errorFetchMessage(MODULE.CLIENT),
                    autoTimeout: true
                  })
                );
              }
            }
          }}
          variant="outlined"
          debounceAt={1000}
          label={t("client")}
          placeholder={t("selectClient")}
        />
      )}
    />
  );
};

export default ClientSelect;
