import * as React from "react";
import { connect } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { push } from "connected-react-router";
import queryString from "query-string";
import classNames from "../../helpers/classNames";
import List from "../../components/List";
import { tl } from "../../components/translate";
import * as calendarFns from "../../components/Calendar/functions/calendarFunctions";
import LabStatusChip from "../Lab/LabStatusChip";
import { SearchBox } from "./index";
import { showLabComponentsBasedOnPermission } from "../Lab/LabDetails";
import useMobileScreen from "../../hooks/useMobileScreen";
import styles from "./Lab.module.css";
import MedicalTestStatusTypes from "../../interfaces/Medical";
import "./MedicalLabList.scss";
import { getCustomerNumber } from "../Client/ClientList";
import SettingsButton from "../../components/SettingsButton/SettingsButton";
import MedicalSettingsPanel from "../ResourceCentre/ModuleSettings/Panels/MedicalSettingsPanel";
import Can from "../Policy/Can";

interface Props {
  medicalTests: Array<any>;
  navigateTo: (v: string) => void;
  permissionGroup: string;
  restrictTestCreation: boolean;
}

const useStyles = makeStyles((theme) => ({
  filtersRoot: {
    display: "flex",
    flexWrap: "wrap",
    [theme.breakpoints.down("md")]: {
      overflowX: "auto",
      whiteSpace: "pre"
    }
  }
}));

function MedicalLabList({
  medicalTests,
  navigateTo,
  restrictTestCreation,
  permissionGroup
}: Props) {
  const isMobileScreen = useMobileScreen();
  const classes = useStyles();
  const locationTab = queryString.parse(window.location.search)?.status as string;
  const [tab, setTab] = React.useState<string>(locationTab || MedicalTestStatusTypes.ORDERED);
  const [isSettingsOpen, setIsSettingsOpen] = React.useState<boolean>(false);

  const filteredRecords = React.useMemo(() => {
    if (tab === "All") {
      return medicalTests.filter((mt) => mt?.client?.active);
    }
    return medicalTests.filter((mt) => mt.status === tab && mt?.client?.active);
  }, [tab, medicalTests]);

  const medicalTabs = {
    All: "all",
    [MedicalTestStatusTypes.ORDERED]: "ordered",
    [MedicalTestStatusTypes.INCOMPLETE_RESULT]: "incompleteResult",
    [MedicalTestStatusTypes.RESULT_READY]: "resultReady",
    [MedicalTestStatusTypes.CANCELED]: "canceled"
  };

  const StatusTabs = () => (
    <Box display="flex" justifyContent="space-between" className={styles.filterRoot}>
      <Box className={classes.filtersRoot}>
        {["All", ...Object.values(MedicalTestStatusTypes)].map((MedicalTestStatus) => (
          <Typography
            data-testmation="medicalStatus"
            key={MedicalTestStatus}
            className={classNames(styles.filter, {
              [styles.active]: tab === MedicalTestStatus
            })}
            onClick={() => {
              setTab(MedicalTestStatus);
              navigateTo(`/medical?status=${MedicalTestStatus}`);
            }}
            component="span"
            style={{ marginRight: "16px" }}
          >
            {tl(`lab.${medicalTabs[MedicalTestStatus]}`)}
          </Typography>
        ))}
      </Box>
    </Box>
  );

  return (
    <Box sx={{ height: "calc(100vh - 42px)" }} className="medicalLabList">
      <Box display="flex" mt={2} mx={2} gap={2}>
        <Box flex={1}>
          <StatusTabs />
        </Box>
        <SearchBox />
        {!restrictTestCreation && showLabComponentsBasedOnPermission(permissionGroup) && (
          <Button
            onClick={() => navigateTo("/medical/create")}
            variant="contained"
            data-testmation="createMedicalReport"
          >
            {isMobileScreen ? "Create" : tl("medical.createMedicalReport")}
          </Button>
        )}
        <SettingsButton onClick={() => setIsSettingsOpen(true)} />
      </Box>
      <List
        testmationLabel="createMedicalReport"
        automation="LabList"
        isResponsive
        data={([...filteredRecords] || []).sort((b, a) => a.id - b.id)}
        rowHeight={50}
        columns={[
          {
            key: "testId",
            label: tl("lab.testId"),
            cellRenderer: (row) => <Typography component="div">{row.id}</Typography>
          },
          {
            key: "client",
            label: tl("lab.client"),
            cellRenderer: (row) => (
              <Box>
                <Typography component="div">
                  {`${row.client?.firstName.toUpperCase()} ${row.client?.lastName.toUpperCase()}`}
                </Typography>
                <Typography>{getCustomerNumber(row.customer?.customerNumber)}</Typography>
              </Box>
            )
          },
          {
            key: "labTest",
            hideInNarrowView: true,
            label: tl("lab.labTest"),
            cellRenderer: (row) => (
              <Typography component="div">{row.results.data[0].name}</Typography>
            )
          },
          {
            key: "orderOn",
            label: tl("lab.orderedOn"),
            cellRenderer: (row) => (
              <Typography component="div">{calendarFns.bsFullDate(row.created_at)}</Typography>
            )
          },
          {
            key: "collected",
            hideInNarrowView: true,
            label: tl("lab.collected"),
            cellRenderer: (row) => (
              <Typography component="div">
                {row.collectionDate && calendarFns.bsFullDate(row.collectionDate)}
              </Typography>
            )
          },
          {
            key: "status",
            hideInNarrowView: true,
            label: tl("lab.status"),
            cellRenderer: (row) => (
              <Box display="flex" alignItems="center">
                <LabStatusChip status={row.status?.toLowerCase()} />
              </Box>
            ),
            sortable: false,
            sortFunction: (a, b) => (a > b ? 1 : -1)
          }
        ]}
        activeRow={null}
        onRowClick={(item) => {
          navigateTo(`/medical/details/${item.id}`);
        }}
        withoutSearch
        hideCreateButton
      />
      <Can policyAccessKey="medical:changeSettings">
        {isSettingsOpen && <MedicalSettingsPanel onClose={() => setIsSettingsOpen(false)} />}
      </Can>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  medicalTests: state.medicalTest.collection,
  permissionGroup: state.userContext?.userCreds?.userGroups[0],
  restrictTestCreation:
    state.userContext?.resourceCentre.labSettings?.enableTestCreationFromBillOnly
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: (url) => {
    dispatch(push(url));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MedicalLabList);
