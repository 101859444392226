import React, { JSX } from "react";
import produce from "immer";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import QuestionnaireResponse from "../Questionnaire";
import Vitals from "../Vitals";
import Symptoms from "../Symptoms";
import PastHistoryOfAllergy from "../PastHistoryOfAllergy";
import Status from "../Status";
import Hopi from "../HOPI";
import Investigation from "../Investigation";
import IntraOral from "../IntraOral/IntraOral";
import { Assessment as AssessmentInterface } from "../../../interfaces/AssessmentInterfaces";
import ExtraOral, { ExtraOralData } from "../ExtraOral";
import IntraOralAdvanced from "../IntraOralAdvanced";
import EyeExamination from "../EyeExamination/EyeExamination";
import ProvisionalDiagnosis from "../ProvisionalDiagnosis";
import LabTest from "../LabTest";
import LabService from "../LabServices/LabService";
import PresentHealthStatus from "../PresentHealthStatus";
import Attachment from "../Attachment";
import EditableTable, { TableState } from "../../../components/EditableTable/EditableTable";
import TreatmentPlan from "../TreatmentPlan";
import TreatmentGiven from "../TreatmentGiven";
import Medication from "../Medication/Medication";
import ProductMedication from "../ProductMedication/ProductMedication";
import FollowUp from "../FollowUp";
import Advice from "../Advice/Advice";
import Others from "../Others";
import { ReverseMap } from "../../../helpers/types";
import styles from "../Assessment.module.css";
import useAssessmentLabel from "../../../hooks/useAssessmentLabel";
import { advancedIntraOralInitState } from "./Form";
import RecordAudio from "./RecordAudio";
import DiagnosisWithTreeView from "../Diagnosis/DiagnosisWithTreeView";
import Compare from "./Compare";

export enum SORT_ORDER {
  RECORD_PRESCRIPTION = "recordPrescription",
  VITALS = "vitals",
  QUESTIONNAIRE = "questionnaire",
  CHIEF_COMPLAINTS = "chiefComplaints",
  PAST_HISTORY_OF_ALLERGY = "pastHistoryOfAllergy",
  EXAMINATION_OR_STATUS = "examination",
  HOPI = "hopi",
  INVESTIGATION = "investigation",
  COMPARE = "compare",
  INTRA_ORAL = "intraoral",
  EXTRA_ORAL = "extraOral",
  ADVANCED_INTRA_ORAL = "advancedIntraOral",
  EYE = "eye",
  PROVISIONAL_DIAGNOSIS = "provisionalDiagnosis",
  LAB_TEST = "labTest",
  SERVICE = "service",
  DIAGNOSIS = "diagnosis",
  PRESENT_HEALTH_STATUS = "presentHealthStatus",
  ATTACHMENTS = "attachments",
  TABULAR = "tabular",
  TREATMENT_PLAN = "treatmentPlan",
  TREATMENT_GIVEN = "treatmentGiven",
  MEDICATION = "medication",
  PRODUCT_MEDICATION = "productMedication",
  FOLLOWUP = "followup",
  ADVICE = "advice",
  OTHERS = "others"
}

export type SortOrderType = ReverseMap<typeof SORT_ORDER>;

interface Props {
  component: SortOrderType;
  assessment: AssessmentInterface & { status: string };
  setAssessment: (value: Partial<AssessmentInterface> & { status: string }) => void;
  spID: number;
  assessmentId: number;
  themeColor: string;
  tabular: { initValue: TableState; title: string };
  clientId?: number;
  intraOral: {
    showFields: { permanent: boolean; primary: boolean };
    setShowFields: (value: { showFields: { permanent: boolean; primary: boolean } }) => void;
  };
}

const OPDComponents = ({
  component,
  assessment,
  setAssessment,
  spID,
  assessmentId,
  themeColor,
  tabular,
  intraOral,
  clientId
}: Props): JSX.Element => {
  const assessmentLabels = useAssessmentLabel();

  return (
    <>
      {component === SORT_ORDER.RECORD_PRESCRIPTION && (
        <RecordAudio
          value={assessment.prescriptionAudioUrl}
          onChange={(v) => {
            setAssessment({ ...assessment, prescriptionAudioUrl: v });
          }}
        />
      )}

      {component === SORT_ORDER.COMPARE && <Compare data={assessment} clientId={clientId} />}
      {component === SORT_ORDER.QUESTIONNAIRE && (
        <QuestionnaireResponse
          assessmentQuestionnaire={assessment.questionnaire}
          onChange={(v) => {
            setAssessment({ ...assessment, questionnaire: v });
          }}
        />
      )}
      {component === SORT_ORDER.VITALS && (
        <Vitals
          assessmentVitals={assessment.vitals}
          onChange={(v) => {
            setAssessment({ ...assessment, vitals: v });
          }}
        />
      )}
      {component === SORT_ORDER.CHIEF_COMPLAINTS && (
        <Symptoms
          assessmentSymptoms={assessment.symptoms}
          onChange={(v) => {
            setAssessment({ ...assessment, symptoms: v });
          }}
        />
      )}

      {component === SORT_ORDER.PAST_HISTORY_OF_ALLERGY && (
        <PastHistoryOfAllergy
          assessmentPastHistoryOfAllergy={assessment?.pastHistoryOfAllergy}
          onChange={(v) => setAssessment({ ...assessment, pastHistoryOfAllergy: v })}
        />
      )}

      {component === SORT_ORDER.EXAMINATION_OR_STATUS && (
        <Status
          status={assessment.status}
          assessmentImage={assessment.assessmentImage}
          onChange={(status) => setAssessment({ ...assessment, status })}
          onChangeImage={(image) => setAssessment({ ...assessment, assessmentImage: image })}
        />
      )}

      {component === SORT_ORDER.HOPI && (
        <Hopi
          assessmentHOPI={assessment.hopi}
          onChange={(v) => {
            setAssessment({ ...assessment, hopi: v });
          }}
        />
      )}

      {component === SORT_ORDER.INVESTIGATION && (
        <Investigation
          investigation={assessment.investigation}
          onChange={(investigation) => setAssessment({ ...assessment, investigation })}
        />
      )}

      {component === SORT_ORDER.INTRA_ORAL && (
        <Box className={styles.assessmentRow}>
          <Box className={styles.assessmentLabel}>
            <Typography component="span" fontWeight="600">
              {assessmentLabels.intraoral}
            </Typography>
          </Box>
          <Box className={styles.assessmentField}>
            <IntraOral
              themeColor={themeColor}
              type="permanent"
              numbering="normal"
              showField={intraOral.showFields.permanent}
              onAddClicked={() => {
                intraOral.setShowFields({ ...intraOral.showFields, permanent: true });
              }}
              onClearClicked={() => {
                intraOral.setShowFields({ ...intraOral.showFields, permanent: false });
              }}
              stateValue={assessment.intraOral}
              onNumberSave={(comboString: string, data: Record<string, unknown>) => {
                const newData = produce(assessment, (draft: AssessmentInterface) => {
                  draft.intraOral.permanent[comboString] = data;
                });
                setAssessment(newData);
              }}
              onNodeDelete={(value: string) => {
                const newData = produce(assessment, (draft: AssessmentInterface) => {
                  delete draft.intraOral.permanent[value];
                });
                setAssessment(newData);
              }}
            />
            <IntraOral
              themeColor={themeColor}
              type="primary"
              numbering="alphabets"
              showField={intraOral.showFields.primary}
              onAddClicked={() => {
                intraOral.setShowFields({ ...intraOral.showFields, primary: true });
              }}
              onClearClicked={() => {
                intraOral.setShowFields({ ...intraOral.showFields, primary: false });
              }}
              stateValue={assessment.intraOral}
              onNumberSave={(comboString: string, data: Record<string, unknown>) => {
                const newData = produce(assessment, (draft: AssessmentInterface) => {
                  draft.intraOral.primary[comboString] = data;
                });
                setAssessment(newData);
              }}
              onNodeDelete={(value: string) => {
                const newData = produce(assessment, (draft: AssessmentInterface) => {
                  delete draft.intraOral.primary[value];
                });
                setAssessment(newData);
              }}
            />
          </Box>
        </Box>
      )}

      {component === SORT_ORDER.EXTRA_ORAL && (
        <ExtraOral
          extraOralData={assessment.extraOral}
          onChange={(v: ExtraOralData) => {
            setAssessment({ ...assessment, extraOral: v });
          }}
        />
      )}

      {component === SORT_ORDER.ADVANCED_INTRA_ORAL && (
        <IntraOralAdvanced
          data={assessment.advancedIntraOral}
          onChange={(v) => setAssessment({ ...assessment, advancedIntraOral: v })}
          onCancel={() => {
            setAssessment({ ...assessment, advancedIntraOral: advancedIntraOralInitState });
          }}
        />
      )}

      {component === SORT_ORDER.EYE && (
        <Box className={styles.assessmentRow}>
          <Box className={styles.assessmentLabel}>
            <Typography component="span" fontWeight="600">
              {assessmentLabels.eye}
            </Typography>
          </Box>
          <EyeExamination
            assessmentEyeExamination={assessment?.eye}
            onChange={(v) => {
              setAssessment({ ...assessment, eye: v });
            }}
          />
        </Box>
      )}

      {component === SORT_ORDER.PROVISIONAL_DIAGNOSIS && (
        <ProvisionalDiagnosis
          assessmentProvisionalDiagnosis={assessment.provisionalDiagnosis}
          onChange={(v) => {
            setAssessment({ ...assessment, provisionalDiagnosis: v });
          }}
        />
      )}

      {component === SORT_ORDER.LAB_TEST && (
        <LabTest
          assessmentLabTests={assessment.tests}
          onChange={(v) => {
            setAssessment({ ...assessment, tests: v });
          }}
        />
      )}

      {component === SORT_ORDER.SERVICE && (
        <LabService
          assessmentId={assessmentId || null}
          assessmentServices={assessment.services}
          onChange={(v) => {
            setAssessment({ ...assessment, services: [...assessment.services, v] });
          }}
          onRemove={(v) => {
            setAssessment({ ...assessment, services: v });
          }}
        />
      )}

      {component === SORT_ORDER.DIAGNOSIS && (
        <div>
          <DiagnosisWithTreeView
            label={assessmentLabels.diagnosis}
            onChange={(v) => {
              const newV = {
                diagnosis: v.code ? `${v.code} ${v.title}` : v.title,
                icdCode: v.code || ""
              };
              setAssessment({ ...assessment, diagnosis: [...assessment.diagnosis, newV] });
            }}
          />
          <Box className={styles.assessmentRow}>
            <div className={styles.assessmentLabel} />
            <Box className={styles.assessmentField} display="flex" alignItems="center" width="100%">
              <Stack gap={1} width="100%">
                {assessment.diagnosis.map((item) => (
                  <Box
                    key={item.diagnosis}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      gap: 2
                    }}
                  >
                    <Typography>{item.diagnosis}</Typography>
                    <IconButton
                      size="small"
                      onClick={() => {
                        setAssessment({
                          ...assessment,
                          diagnosis: assessment.diagnosis.filter((i) => {
                            if (item.icdCode) {
                              return item.icdCode !== i.icdCode;
                            }
                            return item.diagnosis !== i.diagnosis;
                          })
                        });
                      }}
                    >
                      <HighlightOffIcon />
                    </IconButton>
                  </Box>
                ))}
              </Stack>
            </Box>
          </Box>
        </div>
      )}

      {component === SORT_ORDER.PRESENT_HEALTH_STATUS && (
        <PresentHealthStatus
          assessmentPresentHealthStatus={{
            value: assessment?.presentHealthStatus,
            label: assessment?.presentHealthStatus
          }}
          onChange={(v) => setAssessment({ ...assessment, presentHealthStatus: v })}
        />
      )}

      {component === SORT_ORDER.ATTACHMENTS && (
        <Attachment
          assessmentId={assessmentId}
          onfilesChange={(files) => {
            setAssessment({ ...assessment, attachments: files });
          }}
        />
      )}

      {component === SORT_ORDER.TABULAR && (
        <Box className={styles.assessmentRow}>
          <Box className={styles.assessmentLabel}>
            <Typography component="span" fontWeight="600">
              {tabular.title}
            </Typography>
          </Box>
          <Box className={styles.assessmentField}>
            <EditableTable
              maxColCount={10}
              onChange={(value) =>
                setAssessment({
                  ...assessment,
                  tabularComponent: { ...assessment.tabularComponent, ...value }
                })
              }
              hideControls
              hideLabel
              initValue={tabular.initValue}
            />
          </Box>
        </Box>
      )}

      {component === SORT_ORDER.TREATMENT_PLAN && (
        <TreatmentPlan
          assessmentTreatmentPlan={assessment?.treatmentPlan}
          onChange={(v) => setAssessment({ ...assessment, treatmentPlan: v })}
        />
      )}

      {component === SORT_ORDER.TREATMENT_GIVEN && (
        <TreatmentGiven
          assessmentTreatmentGiven={assessment?.treatmentGiven}
          onChange={(v) => setAssessment({ ...assessment, treatmentGiven: v })}
        />
      )}

      {component === SORT_ORDER.MEDICATION && (
        <Medication
          medication={assessment?.medication}
          spID={spID}
          onChange={(v) => {
            setAssessment({ ...assessment, medication: v });
          }}
          isOpd
        />
      )}

      {component === SORT_ORDER.PRODUCT_MEDICATION && (
        <ProductMedication
          productMedication={assessment?.productMedication}
          onChange={(v) => {
            setAssessment({ ...assessment, productMedication: v });
          }}
        />
      )}

      {component === SORT_ORDER.FOLLOWUP && (
        <FollowUp
          assessmentFollowUp={assessment.followup}
          onChange={(updatedValues) => {
            setAssessment({
              ...assessment,
              followup: { ...assessment.followup, ...updatedValues }
            });
          }}
        />
      )}

      {component === SORT_ORDER.ADVICE && (
        <Advice
          advice={assessment.advice}
          onChange={(advice) => setAssessment({ ...assessment, advice })}
        />
      )}

      {component === SORT_ORDER.OTHERS && (
        <Others
          assessmentOthers={assessment.others}
          onChange={(v) => {
            setAssessment({ ...assessment, others: v });
          }}
        />
      )}
    </>
  );
};

export default OPDComponents;
