import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import { Box, Paper, TextField, Typography } from "@mui/material";
import Grid from "@mui/material/Grid2";
import moment from "moment";
import * as React from "react";
import classNames from "../../../helpers/classNames";
import { BillItemType } from "../../../interfaces/BillInterfaces";
import { Stock } from "../../../interfaces/StockInterfaces";
import styles from "./BillEditor.module.css";

interface Props {
  showBatchPopup: boolean;
  setShowBatchPopup: () => void;
  batchOptions: Stock[];
  quantityRef: HTMLElement;
  handleSwitchBatch: () => void;
  item: BillItemType;
  updateBillItem: (billItem: BillItemType) => void;
  infoText: string;
  setInfoText: (value: string) => void;
}

const BatchInfoPopup = ({
  showBatchPopup,
  setShowBatchPopup,
  batchOptions,
  quantityRef,
  handleSwitchBatch,
  item,
  infoText,
  setInfoText,
  updateBillItem
}: Props): JSX.Element | null => {
  const popupRef = React.useRef(null);

  const handleClickOutside = (event) => {
    if (
      !popupRef?.current?.contains(event.target) &&
      !quantityRef?.current?.contains(event.target)
    ) {
      setShowBatchPopup(false);
    }
  };

  React.useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!showBatchPopup || !batchOptions?.length) return null;

  const batchesQuantityTotal = batchOptions.reduce(
    (total, curr) => total + Number(curr.quantity),
    0
  );
  const unselectedBatchIds = batchOptions
    .filter((batchOpt) => batchOpt.batchId !== item.batchInfo.batchId)
    .map((batch) => batch.batchId);
  return (
    <Paper className={styles.batchInfo} ref={popupRef}>
      <Grid container className={styles.batchContent}>
        <Grid container style={{ marginBottom: "4px" }} spacing={1}>
          <Grid size={4} alignItems="center">
            <Typography style={{ fontSize: 12, fontWeight: 600, textAlign: "center" }}>
              Batch Id
            </Typography>
          </Grid>
          <Grid size={4} alignItems="center">
            <Typography style={{ fontSize: 12, fontWeight: 600, textAlign: "center" }}>
              Expiry Date
            </Typography>
          </Grid>
          <Grid size={4} alignItems="center">
            <Typography style={{ fontSize: 12, fontWeight: 600, textAlign: "center" }}>
              Available Qty
            </Typography>
          </Grid>
        </Grid>
        {batchOptions.map((batchOption) => {
          const isExpired = moment().isAfter(batchOption.expiryDate);
          const canSwitchBatch = unselectedBatchIds.includes(batchOption.batchId);
          const isSelectedBatch = batchOption.batchId === item.batchInfo.batchId;
          return (
            <Grid
              container
              key={batchOption.batchId}
              spacing={1}
              className={classNames({ [styles.batchItemHover]: canSwitchBatch })}
              onClick={() => {
                if (!isExpired && canSwitchBatch) {
                  handleSwitchBatch(batchOption);
                }
              }}
            >
              <Grid size={4} alignItems="center">
                <Box display="flex" alignItems="center">
                  <CheckIcon
                    color="primary"
                    style={{
                      fontSize: 14,
                      width: 20,
                      color: !isSelectedBatch ? "transparent" : ""
                    }}
                  />
                  <Typography style={{ fontSize: "12px" }}>{batchOption.batchId}</Typography>
                </Box>
              </Grid>
              <Grid size={4} alignItems="center">
                <Typography
                  style={{
                    fontSize: "12px",
                    textAlign: "center",
                    color: `${isExpired ? "red" : ""}`
                  }}
                >
                  {batchOption.expiryDate.slice(0, 10)}
                </Typography>
              </Grid>
              <Grid size={4} alignItems="center">
                <Typography style={{ fontSize: "12px", textAlign: "center" }}>
                  {Math.trunc(batchOption.quantity)}{" "}
                  {batchOption.product.unitsPerPackage > 1 &&
                    `(${Math.floor(
                      batchOption.quantity / batchOption.product.unitsPerPackage
                    )} packages)`}
                </Typography>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Grid container style={{ padding: "4px", borderTop: "1px solid #e0e0e0" }} spacing={1}>
        <Grid size={8} alignItems="center">
          <Typography style={{ fontSize: "12px", textAlign: "right" }}>Total Quantity</Typography>
          {batchOptions[0]?.product?.structuredInfo?.isNarcotics && (
            <Typography style={{ fontSize: "12px", textAlign: "right" }}>Narcotic</Typography>
          )}
        </Grid>
        <Grid size={4} alignItems="center">
          <Typography style={{ fontSize: "12px", fontWeight: 600, textAlign: "center" }}>
            {batchesQuantityTotal}
          </Typography>

          {batchOptions[0]?.product?.structuredInfo?.isNarcotics && (
            <Box style={{ textAlign: "center" }}>
              <WarningIcon style={{ color: "red" }} />
            </Box>
          )}
        </Grid>
      </Grid>
      {batchOptions[0]?.product?.structuredInfo?.isNarcotics && (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            mb: 1,
            mx: 1
          }}
        >
          <Typography component="label">Info</Typography>
          <TextField
            size="small"
            variant="outlined"
            placeholder="Add information"
            fullWidth
            value={infoText}
            onChange={({ target }) => setInfoText(target.value)}
            onBlur={() =>
              updateBillItem({
                ...item,
                info: infoText
              })
            }
          />
        </Box>
      )}
    </Paper>
  );
};

export default BatchInfoPopup;
