import { Box, CircularProgress } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import React, { useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { notificationAdd } from "../../actions/notification";
import * as bookingApi from "../../api/bookings";
import ClientCreateEdit from "../../containers/Client/ClientCreateEdit";
import { getDisplayAgeFromDOB } from "../../helpers/formatters";
import { Client, Gender } from "../../interfaces/ClientInterface";
import { RootState } from "../../store";
import DebouncedTextField from "../DebouncedTextField";
import { getCustomerNumber } from "../../containers/Client/ClientList";
import { VisitType } from "../../interfaces/BillInterfaces";

interface clientSearchInterface {
  client: Client;
  setClient?: (client: Client | null) => void;
  secondaryText?: {
    type: string | number;
    label?: string | JSX.Element;
    formatter?: (v: unknown) => unknown;
    disableDelimiter?: boolean;
  }[];
  autoFocus?: boolean;
  onFocus?: () => void;
  onBlur?: (e) => void;
  style?: { [k: string]: number | string };
  lastTouchedClient: Client;
  isDisabled?: boolean;
  fromBill?: boolean;
  [k: string]: unknown;
  resourceCentreId?: number | null;
  runAfterClientCreation?: () => void;
  onCreateNewClient?: (name: string) => void;
  label?: string;
  visitType?: VisitType;
}

export const splitName = (fullName: string): { firstName: string; lastName: string } => {
  const nameArray = fullName.split(" ");
  const lastName = nameArray.pop() || "";
  return { lastName, firstName: nameArray.join(" ") };
};

export const getClientGender = (clientGender: string): string => {
  if (clientGender === Gender.Male) {
    return "M";
  }
  if (clientGender === Gender.Female) {
    return "F";
  }
  if (clientGender === Gender.Other) {
    return "O";
  }
  return "-";
};

const defaultSecondaryText = {
  type: "phone",
  formatter: (phone: string) => `,  ${phone.replace("+977", "")}`,
  disableDelimiter: true
};

export enum SecondaryTextType {
  CustomerNumber = "customerNumber",
  ExternalIdentifier = "externalIdentifier",
  Phone = "phone",
  Address = "palikaId"
}

const ClientSearch: React.FC<clientSearchInterface> = (props) => {
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const textFieldAutoFocusRef = useRef<HTMLInputElement>(null);
  const [takeLastTouchedClient, setTakeLastTouchedClient] = React.useState(false);
  const {
    client,
    isDisabled = false,
    setClient,
    onFocus,
    onBlur,
    autoFocus = false,
    lastTouchedClient,
    fromBill = false,
    resourceCentreId,
    secondaryText,
    runAfterClientCreation,
    visitType,
    onCreateNewClient
  } = props;

  const dispatch = useDispatch();

  const [createNewClient, setCreateNewClient] = React.useState<boolean>(false);
  const [clientName, setClientName] = React.useState<string>("");
  React.useEffect(() => {
    if (takeLastTouchedClient && lastTouchedClient) {
      setClient(lastTouchedClient);
      setTakeLastTouchedClient(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [takeLastTouchedClient, lastTouchedClient]);

  React.useEffect(() => {
    if (textFieldAutoFocusRef.current && autoFocus) {
      textFieldAutoFocusRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [autoFocus]);

  return (
    <>
      <Autocomplete
        clearOnBlur
        onFocus={() => {
          if (onFocus) {
            onFocus();
          }
        }}
        onBlur={(e) => {
          setList([]);
          if (onBlur) {
            onBlur(e);
          }
        }}
        disabled={isDisabled}
        data-testmation="clientSelectTab"
        value={client?.id ? client : null}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => {
          if (!option?.firstName && !option?.lastName) return "";
          return `${option?.firstName} ${option.lastName}`;
        }}
        onChange={(e, newValue) => {
          if (fromBill && !newValue) {
            setClient({ id: null } as Client);
          } else if (newValue && newValue.inputValue) {
            // pass onCreateNewClient to skip regular create panel from opening
            // usage in hmis forms
            if (onCreateNewClient) {
              onCreateNewClient(newValue.inputValue);
            } else {
              setCreateNewClient(true);
              setClient(splitName(clientName) as Client);
            }
          } else {
            setClient(newValue);
          }
        }}
        options={list}
        filterOptions={(options, params) => {
          if (params.inputValue !== "") {
            options.push({
              inputValue: params.inputValue,
              firstName: `Add "${params.inputValue}"`,
              lastName: ""
            });
          }
          return options;
        }}
        renderOption={(params, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...params} key={`${option?.id} ${params.key}`}>
            <Box mb="2px">
              <Box>{`${option.firstName} ${option.lastName}`}</Box>
              <Box style={{ fontSize: "0.7rem", color: "grey" }}>
                {secondaryText
                  ?.concat(defaultSecondaryText)
                  ?.map((secondaryInfo, i) => {
                    if (option[secondaryInfo.type]) {
                      let formattedValue = secondaryInfo.formatter
                        ? secondaryInfo.formatter(option[secondaryInfo.type])
                        : option[secondaryInfo.type];
                      if (i > 0 && !secondaryInfo.disableDelimiter) {
                        formattedValue = ` (${formattedValue})`;
                      }
                      if (secondaryInfo.label) {
                        return `${secondaryInfo.label}: ${formattedValue}`;
                      }
                      return `${
                        secondaryInfo.type === SecondaryTextType.CustomerNumber
                          ? getCustomerNumber(formattedValue)
                          : formattedValue
                      }`;
                    }
                    return null;
                  })
                  .filter((value) => value)
                  .join("")}
                {option.dob &&
                  option.gender &&
                  `, ${getDisplayAgeFromDOB(option.dob)} / ${getClientGender(option.gender)} `}
              </Box>
            </Box>
          </li>
        )}
        loading={loading}
        freeSolo
        renderInput={(params) => (
          <Box component="div">
            <DebouncedTextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...props}
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              onFocus={(e) => e.target.select()}
              size="small"
              queryLength={0}
              slotProps={{
                inputLabel: { shrink: true },
                input: {
                  ...params.InputProps,
                  inputProps: {
                    ...params.inputProps,
                    maxLength: 30
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress color="primary" size={16} /> : null}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }
              }}
              inputRef={textFieldAutoFocusRef}
              setDebounceLoading={(debounceLoading) => setLoading(debounceLoading)}
              onChange={async (e) => {
                setClientName(e.target.value);
                if (e.target.value?.length > 0) {
                  setLoading(true);
                  try {
                    const response = await bookingApi.clientSearch(
                      e.target.value,
                      resourceCentreId,
                      visitType
                    );
                    const filteredClients = fromBill
                      ? response
                      : response.filter((clientData) => !clientData.isWalkInCustomer);
                    setList(
                      filteredClients.map((item: { customerDetails: { profileImageS3Url } }) => ({
                        ...item,
                        profileImage: item.customerDetails?.profileImageS3Url || null
                      }))
                    );
                    setLoading(false);
                  } catch (err) {
                    dispatch(
                      notificationAdd({
                        id: new Date().getUTCMilliseconds(),
                        variant: "error",
                        message: err?.data || "Something Went Wrong!.",
                        autoTimeout: true
                      })
                    );
                  }
                }
              }}
              debounceAt={1000}
            />
            {fromBill && (
              <Box component="div">
                <Box component="span">
                  {client?.email || ""} {client?.email && client?.phone ? ", " : " "}
                  {client?.phone || ""}
                </Box>
              </Box>
            )}
          </Box>
        )}
      />
      {createNewClient && (
        <ClientCreateEdit
          fromBill={fromBill}
          resourceCentreId={resourceCentreId}
          clientDefaultName={clientName}
          mode="create"
          afterSave={() => {
            setTakeLastTouchedClient(true);
            setCreateNewClient(false);
            if (runAfterClientCreation) {
              runAfterClientCreation();
            }
          }}
          onCancel={() => setCreateNewClient(false)}
          stayOnCurrentPage
        />
      )}
    </>
  );
};

export default connect(
  (state: RootState) => ({
    lastTouchedClient: state.clients.lastTouched
  }),
  null
)(ClientSearch);
