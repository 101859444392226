import React, { RefObject } from "react";
import { MenuItem, TextField, Typography } from "@mui/material";
import {
  Control,
  Controller,
  FieldErrors,
  FieldPath,
  FieldValues,
  RegisterOptions
} from "react-hook-form";
import { get, startCase } from "lodash";

interface FormTextFieldProps {
  style?: { [key: string]: string | number };
  control: Control<FieldValues>;
  name?: string;
  label: string;
  rules?: Omit<
    RegisterOptions<FieldValues, FieldPath<FieldValues>>,
    "valueAsNumber" | "valueAsDate" | "setValueAs" | "disabled"
  >;
  errors: FieldErrors;
  defaultValue?: string | number | boolean;
  fullWidth?: boolean;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
  inputRef?: RefObject<HTMLInputElement> | null;
  options?: { value: string | number; label: string; description?: string }[] | null;
  select?: boolean;
  multiline?: boolean;
  dataTestmation: string;
  renderValueAsLabel?: boolean;
}

const getError = (errors, name) => get(errors, name, "");
export default function FormTextField({
  style,
  control,
  name,
  rules,
  label,
  errors,
  defaultValue,
  fullWidth,
  disabled,
  className,
  placeholder,
  inputRef,
  select,
  multiline,
  options,
  dataTestmation,
  renderValueAsLabel
}: FormTextFieldProps): JSX.Element {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field }) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...field}
          slotProps={{
            inputLabel: { shrink: true }
          }}
          data-testmation={dataTestmation}
          sx={style}
          inputRef={inputRef}
          disabled={disabled}
          fullWidth={fullWidth}
          label={`${label}${rules?.required ? "*" : ""}`}
          variant="outlined"
          error={!!getError(errors, name)}
          helperText={getError(errors, name)?.message || " "}
          className={className}
          placeholder={placeholder}
          onFocus={(e) => !select && e.target.select()}
          multiline={multiline}
          rows={multiline ? 4 : 1}
          select={select}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...(select && renderValueAsLabel
            ? { SelectProps: { renderValue: (item) => <Typography>{startCase(item)}</Typography> } }
            : {})}
        >
          {select &&
            options?.map((item) => (
              <MenuItem
                value={item.value}
                key={item.label}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start"
                }}
              >
                <Typography>{item.label}</Typography>
                <Typography fontSize="10px" color="grey">
                  {item.description || ""}
                </Typography>
              </MenuItem>
            ))}
        </TextField>
      )}
    />
  );
}

FormTextField.defaultProps = {
  defaultValue: "",
  fullWidth: false,
  disabled: false,
  style: {},
  name: "",
  className: "",
  rules: {},
  placeholder: "",
  inputRef: null,
  options: null,
  select: false,
  multiline: false,
  renderValueAsLabel: false
};
