import { Autocomplete, TextField } from "@mui/material";
import React, { useEffect } from "react";
import useGetDepartments from "../../hooks/department";

interface Props {
  initialValue: number | null;
  onChange: (id: number | null) => void;
  width?: string;
}

const DepartmentSelector = ({ initialValue, onChange, width }: Props): JSX.Element => {
  const departments = useGetDepartments();
  const value = departments.find((item) => item.id === initialValue) || null;

  useEffect(() => {
    onChange(value?.id || null);
  }, [value]);

  return (
    <Autocomplete
      value={value}
      onChange={(_, newValue) => {
        onChange(newValue ? newValue.id : null);
      }}
      options={departments || []}
      getOptionLabel={(option) => option.name}
      sx={{ width }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          slotProps={{
            inputLabel: { shrink: true }
          }}
          variant="outlined"
          label="Departments"
        />
      )}
    />
  );
};

export default DepartmentSelector;
