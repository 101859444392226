import React, { FunctionComponent } from "react";
import { Typography, Link, Box, Tooltip } from "@mui/material";
import moment from "moment";
import ReactToPrint from "react-to-print";
import { useDispatch, useSelector } from "react-redux";
import Barcode from "react-barcode";
import "../PrintBill.scss";
import { convertADtoBS } from "../../../../components/Calendar/functions/calendarFunctions";
import { t, tl } from "../../../../components/translate";
import { registerPrint } from "../../../../actions/bill";
import { DocumentTypes, BillStatus, BillType } from "../../../../interfaces/BillInterfaces";
import { dateWithZeroBeforeSingleDigits } from "../../../../helpers/formatters";
import { isOldFormatBill } from "../../../../actions/helpers/billHelper";
import DescribeBillV1 from "../BillDescriptionV1";
import usePrintStyles from "../../../../hooks/usePrintStyles";
import { checkWalkInCustomer } from "../BillPrintHelpers";
import { RootState } from "../../../../store";
import usePrintShortcut from "../../../../hooks/usePrintShortcut";
import logo from "../../../../../assets/images/poweredByOkhati.png";
import PrintHeader from "./PrintHeader";
import InfoField, { Size } from "./InfoField";
import ClientInfo from "./ClientInfo";
import { Client } from "../../../../interfaces/ClientInterface";
import BillDescription from "./BillDescription";
import useLabIdsFromBills from "../../../../hooks/useLabIdsFromBills";
import useOpenPrintWindow from "../../../../hooks/useOpenPrintWindow";
import QrFromUrl from "../../../../components/Qr/QrFromUrl";

interface PrintBillProps {
  buttonText?: string;
  billId: number;
  billData: BillType;
  customerNumber: string | null;
  labelStyle?: React.CSSProperties;
  addPrintCount?: boolean;
  onPrintClick?: () => void;
  showDobOnPrint: boolean;
  hidePaymentMethodOnPrint?: boolean;
  isForPreview?: boolean;
  labTestUrl?: string;
}

const getPrintTitle = (billStatus: string, billType: string): string => {
  if (billType === DocumentTypes.CREDITNOTE) {
    return `Credit Note`;
  }
  if (billStatus === BillStatus.draft) {
    return `${t("billing.draft")}`;
  }
  if (billStatus === BillStatus.cancelled) {
    return `${t("billing.invoice")} (${t("billing.cancelled")})`;
  }
  if (billStatus === BillStatus.proformaDraft) {
    return `${t("billing.proformaDraft")}`;
  }
  return `${t("billing.invoice")}`;
};

const EightyMmPrint: FunctionComponent<PrintBillProps> = ({
  billId,
  billData,
  buttonText,
  labelStyle,
  addPrintCount,
  onPrintClick,
  showDobOnPrint = false,
  hidePaymentMethodOnPrint = false,
  isForPreview = false,
  labTestUrl
}) => {
  const dispatch = useDispatch();
  const printRef = React.useRef();
  const reactToPrintRef = React.useRef(null);
  const { resourceCentres } = useSelector((state: RootState) => state.resources);
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);
  const resourceCentre = resourceCentres.find((rc) => rc.id === rcId);
  const SPs = useSelector((state: RootState) => state.resources.resourceCentreServiceProviders);
  const labIdsForBill = useLabIdsFromBills(billData);

  const getCustomPrintStyle = () => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    usePrintStyles({
      pageStyle: `size: 8cm 13cm; margin: 0mm;margin-bottom: 12pt;
          counter-increment: page;
            @bottom-center {
            content: "Page " counter(page) " of " counter(pages);
            font-size: 5pt;
          }`
    }).applyPrintStyles();
  };
  usePrintShortcut(reactToPrintRef, getCustomPrintStyle);

  useOpenPrintWindow(reactToPrintRef, getCustomPrintStyle);

  const [isPrinting, setIsPrinting] = React.useState(false);
  if (!billData || !billData.document) {
    return null;
  }

  const showLetterhead =
    resourceCentre?.settings?.printSettings.billPrintSettings?.includeLetterhead;

  return (
    <>
      <ReactToPrint
        onAfterPrint={() => {
          window.focus();
          if (addPrintCount) {
            dispatch(registerPrint(billId));
          }
          setIsPrinting(false);
        }}
        onBeforePrint={() => {
          if (onPrintClick) onPrintClick();
        }}
        trigger={() => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <div>
            <Tooltip arrow title="Alt + P / Ctrl + P">
              <Link
                className="actionBtn"
                component="button"
                onMouseEnter={() => {
                  getCustomPrintStyle();
                }}
                onClick={() => {
                  if (isPrinting) return;
                  setIsPrinting(true);
                }}
                style={{ textDecoration: "none", padding: "5px 15px" }}
                ref={reactToPrintRef}
              >
                <Typography component="span" style={labelStyle}>
                  <Box className="actionBtnLabel" component="span">
                    {buttonText || "Print"}
                  </Box>
                </Typography>
              </Link>
            </Tooltip>
          </div>
        )}
        content={() => printRef.current}
      />
      <iframe title="printContents" className="displayContents">
        <div ref={printRef}>
          <Box component="table" width="100%" color="black">
            {/* thead and tfoot are repeated on every page when printing by default */}
            <tbody>
              {showLetterhead && (
                <tr>
                  <td>
                    {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                    <PrintHeader />
                  </td>
                </tr>
              )}
              <tr>
                <td>
                  <div>
                    <InfoField
                      size={Size.MEDIUM}
                      valueStyle={{
                        textAlign: "center",
                        textTransform: "uppercase",
                        padding: "0 0.5cm",
                        margin: "0.3cm 0"
                      }}
                      value={`${getPrintTitle(billData.status, billData.type)} ${
                        !isForPreview && billData?.printCount
                          ? ` (COPY - ${billData?.printCount})`
                          : ""
                      }`}
                    />
                    <Box m="0cm 0.5cm" pt="0.2cm" borderTop="1px solid #bbb">
                      <div>
                        {checkWalkInCustomer(billData.client as Client, "isWalkInCustomer") && (
                          <ClientInfo
                            client={billData?.client as Client}
                            customerNumber={billData.customer?.customerNumber}
                            showDobOnPrint={showDobOnPrint}
                          />
                        )}
                        {labIdsForBill && <InfoField label="Lab Id:" value={labIdsForBill} />}
                      </div>
                      {billData.ipd && (
                        <InfoField label="IPD No." value={billData.ipd.ipdNumber || ""} />
                      )}
                      {billData.document.bed?.name && (
                        <InfoField label="Bed No." value={billData.document.bed.name} />
                      )}
                      <InfoField
                        label={billData.type === DocumentTypes.CREDITNOTE ? "CN Number" : "Bill No"}
                        value={billData.billNumber}
                      />
                      {billData.type === DocumentTypes.CREDITNOTE && (
                        <InfoField label="CN For Bill" value={billData.related?.billNumber} />
                      )}

                      <InfoField
                        label="Issue Date"
                        value={`${dateWithZeroBeforeSingleDigits(
                          convertADtoBS(moment(billData.issueDate)).formatted
                        )}
                        ${moment(billData.issueDate).format("h:mm a")}`}
                      />

                      <InfoField
                        label={t("billing.dateAD")}
                        value={dateWithZeroBeforeSingleDigits(
                          moment(billData.issueDate).format("YYYY/MM/DD")
                        )}
                      />
                      {billData.document.referredBy && (
                        <InfoField
                          label={t("booking.referredBy")}
                          value={billData.document.referredBy.toUpperCase()}
                        />
                      )}
                      {billData.document.extraReferrer && (
                        <InfoField
                          label="Extra Referrer"
                          value={billData.document.extraReferrer.name.toUpperCase()}
                        />
                      )}
                      {billData.ipd?.doctorInCharge && (
                        <InfoField
                          label="Consulted by"
                          value={`${billData.ipd.doctorInCharge?.firstName || ""} ${
                            billData.ipd.doctorInCharge?.lastName || ""
                          }`}
                        />
                      )}

                      {billData.insuranceNumber && (
                        <InfoField
                          label={t("billing.insuranceNumber")}
                          value={billData.insuranceNumber}
                        />
                      )}

                      {billData.claimNumber && (
                        <InfoField label={t("billing.claimNumber")} value={billData.claimNumber} />
                      )}
                      {billData?.document?.ssf?.id && (
                        <InfoField label={tl("billing.ssid")} value={billData.document.ssf.id} />
                      )}

                      {billData?.document?.ssf?.id && billData?.document?.ssf?.scheme?.value && (
                        <InfoField
                          label={tl("billing.scheme")}
                          value={`${billData.document.ssf.scheme.label} (${billData.document.ssf.scheme.value})`}
                        />
                      )}

                      {billData?.document?.ssf?.id &&
                        billData?.document?.ssf?.subProduct?.value && (
                          <InfoField
                            label={tl("billing.subScheme")}
                            value={`${billData.document.ssf.subProduct.label} (${billData.document.ssf.subProduct.value})`}
                          />
                        )}
                    </Box>

                    {/* check the version and load bill content accordingly,
                    may use switch case if third version comes  */}
                    {isOldFormatBill(billData.document.version) ? (
                      <DescribeBillV1 billData={billData} SPs={SPs} />
                    ) : (
                      <Box p="0cm 0.5cm" mt={0.5}>
                        <BillDescription
                          billData={billData}
                          hidePaymentMethodOnPrint={hidePaymentMethodOnPrint}
                        />
                      </Box>
                    )}
                    <Box p="0cm 0.23cm">
                      <Barcode
                        value={billId}
                        width={1}
                        displayValue={false}
                        fontSize={3}
                        height={10}
                        font="arial"
                      />
                    </Box>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <Box
                    sx={{
                      paddingTop: "2mm",
                      margin: "0 0.5cm",
                      borderTop: "0.02mm solid #bbb",
                      display: "flex",
                      justifyContent: "space-between"
                    }}
                  >
                    {labTestUrl && (
                      <QrFromUrl
                        url={labTestUrl}
                        size={40}
                        label={t("billPrint.labTestQrLabel")}
                        labelFontSize="8px"
                      />
                    )}
                    <img src={logo} alt="logo" height="20mm" style={{ marginLeft: "auto" }} />
                  </Box>
                </td>
              </tr>
            </tbody>
          </Box>
        </div>
      </iframe>
    </>
  );
};

EightyMmPrint.defaultProps = {
  buttonText: "Print",
  labelStyle: {},
  addPrintCount: true,
  onPrintClick: () => null
};

export default EightyMmPrint;
