import React, { JSX, useState } from "react";
import { Alert, Box, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { ResourceCentreAutoComplete } from "../Subscriptions";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import { Post } from "../../api/apiHelper";
import { useAppDispatch } from "../../store/hooks";
import { notificationAdd } from "../../actions/notification";

const ImportDemoData = (): JSX.Element => {
  const [selectedRc, setSelectedRc] = useState<ResourceCentre | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasErrors, setHasErrors] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    try {
      setHasErrors(false);
      setIsLoading(true);
      await Post("/api/addDemoData", { resourceCentreId: selectedRc?.id });
    } catch (e) {
      setHasErrors(true);
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "Error, data could not be imported.",
          autoTimeout: true
        })
      );
    } finally {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Data imported.",
          autoTimeout: true
        })
      );
      setIsLoading(false);
    }
  };

  return (
    <Box p={2}>
      <Typography gutterBottom>Quickly Add Demo Data To Selected Resourcecentres</Typography>
      <Typography variant="body2">
        This will add labtests, bills, products and assessment for demo purposes.
      </Typography>
      <Box mt={4} display="flex" flexDirection="column" gap={2} alignItems="flex-start">
        <ResourceCentreAutoComplete setSelectedRc={setSelectedRc} />
        <Alert severity="warning">
          Warning: Please make sure atleast one fiscal year is active before importing.
        </Alert>
        <LoadingButton
          variant="contained"
          disabled={!selectedRc}
          onClick={onSubmit}
          loading={isLoading}
        >
          Start Importing
        </LoadingButton>
      </Box>
      {hasErrors && (
        <Alert severity="error">
          Importing data failed but some data might have been imported.
        </Alert>
      )}
    </Box>
  );
};

export default ImportDemoData;
