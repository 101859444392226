import * as React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

export type DebouncedTextFieldProps = TextFieldProps & {
  debounceAt?: number;
  setDebounceLoading?: (val: boolean) => void;
  queryLength?: number;
};

/**
 * The `DebouncedTextField` is an extension to Material UI's TextField
 * debounceAt is the extra added property to that of TextField's
 *
 * ## Advanced Configuration
 * ```jsx
 * const [text, setText] = React.useState('');
 * return <DebouncedTextField value={text} onChange={(e) => setText(e.target.value) debounceAt={300} }/>;
 * ```
 *
 * For advanced TextField cases, please look at the source of TextField by clicking on the
 * "Edit this page" button above. Consider either:
 *
 * -   using the upper case props for passing values directly to the components
 * -   using the underlying components directly as shown in the demos
 * Demos:
 *
 * - [Autocomplete](https://material-ui.com/components/autocomplete/)
 * - [Pickers](https://material-ui.com/components/pickers/)
 * - [Text Fields](https://material-ui.com/components/text-fields/)
 *
 * API:
 *
 * - [TextField API](https://material-ui.com/api/text-field/)
 * - inherits [FormControl API](https://material-ui.com/api/form-control/)
 */

const DebouncedTextField = ({
  value,
  onChange,
  debounceAt,
  setDebounceLoading,
  queryLength = 2,
  ...textFieldProps
}: DebouncedTextFieldProps): JSX.Element => {
  const [inputValue, setInputValue] = React.useState(value);
  const timer = React.useRef<NodeJS.Timeout | null>(null);

  React.useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    if (setDebounceLoading) {
      setDebounceLoading(newValue.length > queryLength);
    }

    if (timer.current) {
      clearTimeout(timer.current);
    }

    const { target } = e;
    timer.current = setTimeout(() => {
      if (onChange) {
        onChange({ ...e, target });
        setDebounceLoading?.(false);
      }
    }, debounceAt);
  };

  return (
    <TextField
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...textFieldProps}
      value={inputValue}
      onChange={debounceAt ? handleChange : onChange}
    />
  );
};

export default DebouncedTextField;
