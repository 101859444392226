import * as React from "react";
import { connect, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import queryString from "query-string";
import moment from "moment";
import { push } from "connected-react-router";
import useMobileScreen from "../../hooks/useMobileScreen";
import BigCalendar, {
  getStartAndEnd,
  getDays,
  daysCount
} from "../../components/Calendar/BigCalendar/Calendar";
import SidePanel from "./SidePanel";
import { serviceProviderActions as actions } from "../../actions";
import * as scheudleActions from "../../actions/schedules";
import * as bookingActions from "../../actions/booking";
import EventPopup from "./PopUp";
import { getNewBooking } from "../../models/Booking";
import { getNewSchedule } from "../../models/Schedule";
import { eventsSelector } from "../../reducers/bookings";
import styles from "./styles.module.css";
import MiniCalendar from "../../components/Calendar/MiniCalendar/Calendar";
import { getServices } from "../../actions/services";
import { getBookableResources } from "../../actions/bookableResource";
import hasOwnProperty from "../../helpers/object";
import { IThunkDispatch, RootState } from "../../store";
import useDepartmentServiceProviders from "../../hooks/useDepartmentServiceProviders";
import { getReminders } from "../../actions/reminders";
import { getClientDetails } from "../../api/client";
import { clientFullNameSelector } from "../../reducers/client";
import { getDefaultReminders } from "./Booking/BookingCreateEdit";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CalendarExternalProps {}

interface CalendarInternalProps {
  user: any;
  bookings: Array<any>;
  events: Array<any>;
  location: { search: any };
  resourceCentreId: number;
  loadScheduleEvents: (serviceProviderId, start, end, resourceId?, publicEventChecked?) => void;
  loadBookableResources: () => void;
  loadBookings: (serviceProviderId, start, end, resourceId?) => void;
  loadServices: () => void;
  navigateTo: (url) => void;
  bookableResources;
}

const getBooking = (bookingId, bookings) => bookings.find(({ id }) => id === bookingId);

const getSelectedDate = (date) =>
  date ? moment(date).startOf("day") : moment().tz("Asia/Kathmandu").startOf("day");

const getWeekDayNumber = (date) => {
  if (date instanceof Date) {
    return date.getDay();
  }

  if (moment.isMoment(date)) {
    return date.day();
  }

  return null;
};

const Calendar: React.FC<CalendarExternalProps & CalendarInternalProps> = ({
  user,
  bookings,
  events,
  location,
  resourceCentreId,
  loadScheduleEvents,
  loadBookings,
  loadServices,
  navigateTo,
  loadBookableResources,
  bookableResources
}) => {
  const isMobileScreen = useMobileScreen();
  const [createFollowUpFromBookingInfoPanel, setCreateFollowUpFromBookingInfoPanel] =
    React.useState(false);
  const [columnsMode, setColumnsMode] = React.useState(isMobileScreen ? "day" : "week");
  const [selectedEvent, setSelectedEvent] = React.useState({
    event: null,
    boxProps: null,
    placeholderEvent: null,
    infoMode: true
  });
  const dispatch = useDispatch();
  const serviceProviders = useDepartmentServiceProviders();
  const sessionBookableResource = sessionStorage.getItem("bookableResourceId");

  const [selectedBookableResource, setSelectedBookableResource] = React.useState(
    sessionBookableResource
      ? bookableResources.find((br) => br.id === Number(sessionBookableResource)) || null
      : null
  );

  const [publicEventChecked, setPublicEventsChecked] = React.useState(false);

  const [applyServiceProviderOwner, setApplyServiceProviderOwner] = React.useState(
    !selectedBookableResource ? true : Boolean(sessionStorage.getItem("spApplied"))
  );

  // calendarOwner
  const resetSelectedEvent = () => {
    setSelectedEvent({
      event: null,
      boxProps: null,
      placeholderEvent: null,
      infoMode: null
    });
  };
  const params = queryString.parse(location.search);

  const selectedServiceProvider = () =>
    user.role === "serviceProvider"
      ? Number(params.serviceProviderId) || user.id
      : Number(params.serviceProviderId) ||
        Number(sessionStorage.getItem("spID")) ||
        (serviceProviders && serviceProviders.length ? serviceProviders[0].id : null);

  const [selectedCalendarOwner, setSelectedCalendarOwner] = React.useState<{
    id: number;
    entity: string;
  }>({
    id: selectedServiceProvider(),
    entity: "serviceProvider"
  });

  const [selectedDate, setSelectedDate] = React.useState<Date>(
    params.date && params.date !== "null" ? new Date(params.date) : new Date()
  );

  React.useEffect(() => {
    loadServices();
    loadBookableResources();
  }, []);

  React.useEffect(() => {
    (async () => {
      if (selectedCalendarOwner.id && params.clientId) {
        const clientData = await getClientDetails(Number(params.clientId));
        setSelectedEvent({
          event: {
            ...getNewBooking({
              start: moment(),
              end: moment().add(15, "minutes"),
              resourceCentreId,
              serviceProviderId: applyServiceProviderOwner ? selectedCalendarOwner.id : null,
              bookableResource: selectedBookableResource
            }),
            clientId: clientData.id,
            clientName: clientFullNameSelector(clientData),
            clientPhoneNo: clientData.phone,
            clientEmail: clientData.email,
            clientAddress: clientData.address,
            serviceProviderId: selectedCalendarOwner.id
          },
          placeholderEvent: {
            start: moment(),
            end: moment().add(15, "minutes")
          },
          infoMode: false,
          boxProps: {
            position: "absolute",
            top: "50%",
            left: "50%"
          }
        });
      }
    })();
  }, [selectedCalendarOwner.id, params.clientId]);

  const loadEventsForTheview = () => {
    const [start, end] = getStartAndEnd(selectedDate, columnsMode);
    loadScheduleEvents(
      applyServiceProviderOwner ? selectedCalendarOwner.id : null,
      start,
      end,
      selectedBookableResource?.id || null,
      publicEventChecked
    );
    loadBookings(
      applyServiceProviderOwner ? selectedCalendarOwner.id : null,
      start,
      end,
      selectedBookableResource?.id || null,
      publicEventChecked
    );
  };

  React.useEffect(() => {
    if (selectedCalendarOwner.id || selectedBookableResource?.id) {
      loadEventsForTheview();
    }
  }, [
    selectedCalendarOwner.id,
    applyServiceProviderOwner,
    selectedDate,
    columnsMode,
    selectedBookableResource?.id,
    publicEventChecked
  ]);

  React.useEffect(() => {
    if (isMobileScreen) {
      setColumnsMode("day");
    } else {
      setColumnsMode("week");
    }
  }, [isMobileScreen]);

  React.useEffect(() => {
    const { serviceProviderId, date } = params;
    if (serviceProviderId && selectedCalendarOwner.id !== Number(serviceProviderId)) {
      setSelectedCalendarOwner({
        id: selectedServiceProvider(),
        entity: "serviceProvider"
      });
    }
    if (date && date !== "null" && selectedDate?.toISOString() !== date) {
      setSelectedDate(new Date(date));
    }
  }, [params.serviceProviderId, params.date, params.bookingId]);

  React.useEffect(() => {
    if (params.bookingId) {
      const { bookingId } = params;
      const booking = getBooking(Number(bookingId), bookings);
      setSelectedEvent({
        event: booking,
        placeholderEvent: booking,
        infoMode: true,
        boxProps: {
          position: "absolute",
          height: "25px",
          width: "25px",
          top: "50%",
          left: "50%"
        }
      });
    }
  }, [params.bookingId, bookings]);

  React.useEffect(() => {
    if (!selectedCalendarOwner.id && serviceProviders?.length) {
      setSelectedCalendarOwner({
        id: serviceProviders[0]?.id,
        entity: "serviceProvider"
      });
    }
  }, [serviceProviders]);
  const eventRef = React.useRef(null);
  const [isCalendarView, setIsCalendarView] = React.useState<boolean>(true);
  const highlightedDays = isCalendarView
    ? getDays(selectedDate, daysCount(columnsMode))
    : [getSelectedDate(selectedDate)];

  React.useEffect(() => {
    /*
    // checking if selectedEvent.event isn't empty obj,then updating the selectedEvent
    // from newly fetched events after booking action buttons click
    */
    if (hasOwnProperty(selectedEvent.event, "status")) {
      const prevSelectedEvent = selectedEvent;
      const newSelectedEvent = events.find((item) => item.id === prevSelectedEvent.event.id);
      setSelectedEvent({ ...selectedEvent, event: newSelectedEvent });
    }
  }, [events]);

  const selectedScheduleRefs = React.useRef([]);

  return (
    <>
      <Box className={styles.calendarPage}>
        <Box
          sx={(theme) => ({
            [theme.breakpoints.up("sm")]: {
              height: "calc(100vh - 50px)",
              overflowY: "auto"
            }
          })}
        >
          {!isMobileScreen && (
            <Box p={2}>
              <Box mt="16px" width="100%" justifyContent="center">
                <MiniCalendar
                  onChange={(date) => setSelectedDate(date)}
                  highlightedDays={highlightedDays}
                  activeDate={selectedDate}
                />
              </Box>
            </Box>
          )}

          <SidePanel
            serviceProviderList={serviceProviders}
            serviceProviderId={selectedCalendarOwner.id}
            onServiceProviderSelect={(id) => {
              setSelectedCalendarOwner({ id, entity: "serviceProvider" });
              resetSelectedEvent();
              sessionStorage.setItem("spID", id);
              navigateTo("/calendar");
            }}
            onBookableResourceSelect={(resource) => {
              // deselect public booking whenever bookable resource is true
              if (!selectedBookableResource) {
                setPublicEventsChecked(false);
              }
              setSelectedBookableResource(resource);
              sessionStorage.setItem("bookableResourceId", resource?.id);
            }}
            onPublicEventToggle={() => {
              // deselect service provider and bookable resource whenever publicEventChecked is true
              if (!publicEventChecked) {
                setApplyServiceProviderOwner(false);
                setSelectedBookableResource(false);
              }
              setPublicEventsChecked((p) => !p);
            }}
            publicEventChecked={publicEventChecked}
            selectedBookableResource={selectedBookableResource}
            applyServiceProviderOwner={applyServiceProviderOwner}
            changeApplyServiceProviderOwner={(value: boolean) => {
              // deselect public booking whenever service provider is true
              if (!applyServiceProviderOwner) {
                setPublicEventsChecked(false);
              }
              setApplyServiceProviderOwner(value);
            }}
          />
        </Box>
        <Box className={styles.calendarpanel}>
          <div
            ref={eventRef}
            style={
              selectedEvent.event
                ? {
                    ...selectedEvent.boxProps
                  }
                : { display: "none" }
            }
          />

          {(selectedCalendarOwner.id || selectedBookableResource) && (
            <BigCalendar
              isCalendarView={isCalendarView}
              setIsCalendarView={() => setIsCalendarView(!isCalendarView)}
              selectedCalendarOwner={selectedCalendarOwner}
              selectedDate={selectedDate}
              placeholderEvent={selectedEvent.placeholderEvent}
              columnsMode={columnsMode}
              events={events}
              onChange={(date) => {
                setSelectedDate(date);
              }}
              onScheduleSelect={(divRef) => {
                selectedScheduleRefs.current.push(divRef);
              }}
              onColumnsModeChange={(mode) => {
                setColumnsMode(mode);
              }}
              onNewEvent={(event, el) => {
                const { x, y } = el.getBoundingClientRect();
                setSelectedEvent({
                  event: getNewBooking({
                    start: event.start,
                    end: event.end,
                    resourceCentreId,
                    serviceProviderId: applyServiceProviderOwner ? selectedCalendarOwner.id : null,
                    bookableResource: selectedBookableResource
                  }),
                  placeholderEvent: event,
                  infoMode: false,
                  boxProps: {
                    position: "absolute",
                    height: `${event.height}`,
                    width: `${el.offsetWidth}px`,
                    top: `${y || 0}px`,
                    left: `${x || 0}px`
                  }
                });
              }}
              onEventHover={() => ({})}
              onEventClick={(event, el) => {
                const { x, y } = el.getBoundingClientRect();
                if (event.type === "booking") {
                  setSelectedEvent({
                    event: getBooking(event.id, bookings),
                    placeholderEvent: event,
                    infoMode: true,
                    boxProps: {
                      position: "absolute",
                      height: `${event.height}`,
                      width: `${el.offsetWidth}px`,
                      top: `${y || 0}px`,
                      left: `${x || 0}px`
                    }
                  });
                } else {
                  setSelectedEvent({
                    event,
                    placeholderEvent: event,
                    infoMode: true,
                    boxProps: {
                      position: "absolute",
                      height: `${event.height}`,
                      width: `${el.offsetWidth}px`,
                      top: `${y || 0}px`,
                      left: `${x || 0}px`
                    }
                  });
                }
              }}
            />
          )}
        </Box>

        {Boolean(selectedEvent.event) && (
          <EventPopup
            anchorEl={() => eventRef}
            event={selectedEvent.event}
            infoMode={selectedEvent.infoMode}
            events={events}
            calStart={getStartAndEnd(selectedDate, columnsMode)[0]}
            calEnd={getStartAndEnd(selectedDate, columnsMode)[1]}
            selectedSP={applyServiceProviderOwner ? selectedCalendarOwner.id : null}
            selectedResource={selectedBookableResource?.id || null}
            eventType={hasOwnProperty(selectedEvent.event, "eStart") ? "schedule" : "booking"}
            createFollowUpFromBookingInfoPanel={createFollowUpFromBookingInfoPanel}
            onChange={(data) => {
              if (
                !(
                  moment(data.start).isSame(selectedEvent.placeholderEvent.start) &&
                  moment(data.end).isSame(selectedEvent.placeholderEvent.end)
                )
              ) {
                setSelectedEvent({
                  ...selectedEvent,
                  // we have to update the box props
                  // boxProps: null,
                  placeholderEvent: {
                    ...selectedEvent.placeholderEvent,
                    start: data.start,
                    end: data.end,
                    column: getWeekDayNumber(data.start)
                  }
                });
              }
            }}
            onEdit={() => {
              setSelectedEvent({ ...selectedEvent, infoMode: false });
            }}
            onSave={(newSpId) => {
              resetSelectedEvent();
              navigateTo("/calendar");
              // When id is different events are loaded through useEffect
              // But when id is same events are not loaded
              // Hence needed to call loadEventsForTheview in else block
              if (newSpId && newSpId !== selectedCalendarOwner.id) {
                setSelectedCalendarOwner({
                  id: newSpId,
                  entity: "serviceProvider"
                });
              } else {
                loadEventsForTheview();
                dispatch(getReminders());
              }
            }}
            onClose={() => {
              navigateTo("/calendar");
              resetSelectedEvent();
              selectedScheduleRefs.current.forEach((el) => {
                // eslint-disable-next-line no-param-reassign
                el.style.border = "none";
                // eslint-disable-next-line no-param-reassign
                el.style.outline = "none";
              });
            }}
            onTabChange={(tabType) => {
              if (tabType === "schedule") {
                setSelectedEvent({
                  ...selectedEvent,
                  event: getNewSchedule({
                    start: selectedEvent.event.start,
                    end: selectedEvent.event.end,
                    resourceCentreId,
                    serviceProviderId: applyServiceProviderOwner ? selectedCalendarOwner.id : null,
                    bookableResource: selectedBookableResource
                  })
                });
              }
            }}
            onCreateBookigFromBookingInfo={() => {
              const newEvent = {
                ...selectedEvent,
                infoMode: false,
                event: {
                  ...getNewBooking({
                    start: selectedEvent?.event?.start,
                    end: selectedEvent?.event?.end,
                    resourceCentreId,
                    serviceProviderId: applyServiceProviderOwner ? selectedCalendarOwner.id : null,
                    bookableResource: selectedBookableResource,
                    client: selectedEvent?.event?.client,
                    followUp: true
                  }),
                  reminders: getDefaultReminders(moment(selectedEvent?.event?.start)).map(
                    (reminder) => ({
                      ...reminder,
                      on: reminder.on.toISOString()
                    })
                  )
                }
              };
              setSelectedEvent(newEvent);
              setCreateFollowUpFromBookingInfoPanel(true);
            }}
          />
        )}
      </Box>
    </>
  );
};

export default connect(
  (state: RootState) => {
    const { userContext, bookings } = state;
    return {
      resourceCentreId: userContext.resourceCentreId,
      user: { ...userContext.user, role: userContext.mode },
      events: eventsSelector(state),
      bookings: bookings.collection,
      bookableResources: state.bookableResources.collection.filter((item) => item.active)
    };
  },
  (dispatch: IThunkDispatch) => ({
    loadBookableResources: () => dispatch(getBookableResources()),
    loadServiceProviders: () =>
      dispatch((dispatchInner, getState) => {
        dispatchInner(
          actions.getResourceCentreServiceProviders({
            resourceCentreId: getState().userContext.resourceCentreId
          })
        );
      }),
    loadScheduleEvents: (serviceProviderId, start, end, resourceId?, publicEventChecked?) =>
      dispatch(
        scheudleActions.getSchedules(serviceProviderId, start, end, resourceId, publicEventChecked)
      ),
    loadBookings: (serviceProviderId, start, end, resourceId?, publicEventChecked?) =>
      dispatch(
        bookingActions.getBookings(serviceProviderId, start, end, resourceId, publicEventChecked)
      ),
    loadServices: () => dispatch(getServices()),
    navigateTo: (url) => dispatch(push(url))
  })
)(Calendar);
