import { Box, Button, TextField, Typography, Autocomplete, Alert } from "@mui/material";
import React, { useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import cloneDeep from "lodash/cloneDeep";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import produce from "immer";
import Panel from "../../../components/Panel";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import { t, tl } from "../../../components/translate";
import { fields } from "../../../models/Audience";
import Form from "../../../components/Form";
import "./Audience.scss";
import {
  RangeField,
  GenderSelectionField,
  BirthdaySelectionField,
  ServiceSelectionField
} from "./CriteriaFields";

import * as audienceActions from "../../../actions/audience";
import * as clientActions from "../../../actions/client";
import { clientFullNameSelector, clientsSortedSelector } from "../../../reducers/client";
import { AudienceData } from "./AudienceHelpers";
import { IThunkDispatch, RootState } from "../../../store";
import { getCustomerNumber } from "../../Client/ClientList";

interface AudienceCreateEditProps {
  mode: string;
  audienceId: any;
  audience: AudienceData;
  onCancel: () => any;
  actions: any;
  navigateTo: () => any;
  clients: any;
}

const AudienceCreateEdit: React.FC<AudienceCreateEditProps> = ({
  mode,
  onCancel,
  actions,
  audience,
  clients
}) => {
  const [formData, setFormData] = useState<AudienceData>(
    audience || {
      type: "static",
      name: "",
      rules: {
        client: {}
      }
    }
  );
  const [audienceSize, setAudienceSize] = useState(0);
  const [saving, setSaving] = useState(false);
  const [triggerAudienceSize, setTriggerAudienceSize] = useState(false);
  const [errorsCount, setErrorsCount] = React.useState(null);
  const [saveAttempted, setSaveAttempted] = React.useState(false);

  React.useEffect(() => {
    actions.getDynamic(formData, (data) => {
      setAudienceSize(data?.audienceSize);
    });
  }, [triggerAudienceSize]);

  React.useEffect(() => {
    if (!clients || !clients.length) actions.loadClients();
  }, []);

  const optionsForSelect = (clientList = [], filter?) => {
    if (filter) {
      // eslint-disable-next-line no-param-reassign
      clientList = filter
        .map((id) => clientList.find((client) => client.id === id))
        .filter((s) => Boolean(s));
    }
    return clientList.map((client) => ({
      label: clientFullNameSelector(client),
      value: client.id,
      client
    }));
  };

  return (
    <Panel
      onClose={onCancel}
      title={mode === "edit" ? tl("campaign.updateAudience") : tl("campaign.createAudience")}
      footer={
        <Box display="flex" justifyContent="space-between" width="100%" marginRight="32px">
          <Box />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Button
              data-testmation="campaign.cancel"
              onClick={onCancel}
              style={{ marginRight: "32px" }}
            >
              <Typography>{tl("client.cancel")}</Typography>
            </Button>
            <StatefulButton
              data-testmation="campaignSave"
              variant="contained"
              color="primary"
              type="submit"
              form="audienceForm"
              disabled={(errorsCount && saveAttempted) || saving}
              onClick={async () => {
                if (!errorsCount) {
                  const hasServiceRule = !!formData.rules.service?.id;
                  setSaving(true);
                  await actions
                    .onSave({ ...formData, isReady: !hasServiceRule, isBatch: hasServiceRule })
                    .then(() => {
                      setSaving(false);
                      actions.navigateTo("/campaign/audience");
                    });
                } else {
                  setSaveAttempted(true);
                }
              }}
              isLoading={saving}
              circularProgressProps={{ size: 16 }}
            >
              <Typography>{tl("client.save")}</Typography>
            </StatefulButton>
          </Box>
        </Box>
      }
    >
      <Box pl={5} pr={5} className="audienceForm">
        <Form
          name="audienceForm"
          fields={fields}
          data={{ type: formData.type, name: formData.name }}
          onChange={(data, errors, errorCount) => {
            const newFormData: AudienceData = cloneDeep(formData);
            newFormData.type = data.type;
            if (newFormData.type === "static") {
              delete newFormData.rules.booking;
              delete newFormData.rules.clientLedger;
              delete newFormData.rules.client.age;
              delete newFormData.rules.client.gender;
              delete newFormData.rules.client.dob;
            } else {
              delete newFormData.rules.client.ids;
              setTriggerAudienceSize((val) => !val);
            }
            newFormData.name = data.name;
            setErrorsCount(errorCount);
            setFormData(newFormData);
          }}
          showErrors
        />

        {formData.type === "static" && (
          <Box>
            <Box fontWeight={700} mb={1} mt={2}>
              Clients{" "}
            </Box>
            <Autocomplete
              data-testmation="clientSearch"
              size="small"
              multiple
              style={{ minWidth: "200px" }}
              options={optionsForSelect(clients)}
              getOptionLabel={(option) =>
                `${option.label} (${getCustomerNumber(option.client.customerNumber)})`
              }
              renderOption={(props, option) => (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <li {...props} key={option.value}>
                  <div>
                    {option.client.firstName} {option.client.lastName}
                  </div>
                  <div style={{ color: "gray" }}>
                    {getCustomerNumber(option.client.customerNumber)}
                  </div>
                </li>
              )}
              value={
                formData.rules.client.ids
                  ? optionsForSelect(clients, formData.rules.client.ids)
                  : []
              }
              isOptionEqualToValue={(option, value) => option.client.id === value.client.id}
              onChange={(e, values) => {
                const data: AudienceData = cloneDeep(formData);
                data.rules.client.ids = values.map((v: any) => v.client.id);
                setFormData(data);
              }}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Search"
                  variant="outlined"
                  slotProps={{
                    inputLabel: { shrink: true }
                  }}
                  margin="dense"
                />
              )}
            />
          </Box>
        )}
        {formData.type === "dynamic" && (
          <Box>
            <Box mt={3}>
              <Typography>
                <Box fontWeight={700}>Client selection criteria</Box>
              </Typography>
              <Box mt={2} mb={1}>
                <Box fontWeight={700}>Client</Box>
                <Box className="andContainer">
                  {formData.rules.client.age && (
                    <Box className="criteriaBox" display="inline-block">
                      <CancelIcon
                        className="cancelBtn"
                        onClick={() => {
                          const data: AudienceData = cloneDeep(formData);
                          delete data.rules.client.age;
                          setFormData(data);
                          setTriggerAudienceSize((val) => !val);
                        }}
                      />
                      <RangeField
                        text="with age between"
                        gteVal={formData.rules.client?.age?.gte}
                        lteVal={formData.rules.client?.age?.lte}
                        onChangeGTE={(val) => {
                          const data: AudienceData = cloneDeep(formData);
                          data.rules.client.age.gte = val;
                          setFormData(data);
                          setTriggerAudienceSize((v) => !v);
                        }}
                        onChangeLTE={(val) => {
                          const data: AudienceData = cloneDeep(formData);
                          data.rules.client.age.lte = val;
                          setFormData(data);
                          setTriggerAudienceSize((v) => !v);
                        }}
                      />
                    </Box>
                  )}

                  {formData.rules.client.gender && (
                    <Box className="criteriaBox" display="inline-block">
                      <CancelIcon
                        className="cancelBtn"
                        onClick={() => {
                          const data: AudienceData = cloneDeep(formData);
                          delete data.rules.client.gender;
                          setFormData(data);
                          setTriggerAudienceSize((val) => !val);
                        }}
                      />
                      <GenderSelectionField
                        onSelect={(val) => {
                          const data: AudienceData = cloneDeep(formData);
                          data.rules.client.gender = val;
                          setFormData(data);
                          setTriggerAudienceSize((v) => !v);
                        }}
                        val={formData.rules.client?.gender}
                      />
                    </Box>
                  )}

                  {formData.rules.client.dob && (
                    <Box className="criteriaBox" display="inline-block">
                      <CancelIcon
                        className="cancelBtn"
                        onClick={() => {
                          const data: AudienceData = cloneDeep(formData);
                          delete data.rules.client.dob;
                          setFormData(data);
                          setTriggerAudienceSize((val) => !val);
                        }}
                      />
                      <BirthdaySelectionField
                        onSelect={(val) => {
                          const data: AudienceData = cloneDeep(formData);
                          data.rules.client.dob = val;
                          setFormData(data);
                          setTriggerAudienceSize((v) => !v);
                        }}
                        val={formData.rules.client?.dob}
                      />
                    </Box>
                  )}
                </Box>
                <Box mt={1} mb={1}>
                  <Box fontWeight={700}>Due</Box>

                  {formData.rules.clientLedger?.dueAmount && (
                    <Box className="criteriaBox" display="inline-block">
                      <CancelIcon
                        className="cancelBtn"
                        onClick={() => {
                          const data: AudienceData = cloneDeep(formData);
                          delete data.rules.clientLedger?.dueAmount;
                          if (Object.keys(data.rules.clientLedger).length === 0)
                            delete data.rules.clientLedger;
                          setFormData(data);
                          setTriggerAudienceSize((val) => !val);
                        }}
                      />
                      <RangeField
                        text="Due between"
                        gteVal={formData.rules.clientLedger?.dueAmount?.gte}
                        lteVal={formData.rules.clientLedger?.dueAmount?.lte}
                        onChangeGTE={(val) => {
                          const data: AudienceData = cloneDeep(formData);
                          data.rules.clientLedger.dueAmount.gte = val;
                          setFormData(data);
                          setTriggerAudienceSize((v) => !v);
                        }}
                        onChangeLTE={(val) => {
                          const data: AudienceData = cloneDeep(formData);
                          data.rules.clientLedger.dueAmount.lte = val;
                          setFormData(data);
                          setTriggerAudienceSize((v) => !v);
                        }}
                      />
                    </Box>
                  )}
                </Box>
                {formData.rules.service && (
                  <Box mt={1} mb={1}>
                    <Typography fontWeight={700}>Service</Typography>
                    <Box className="criteriaBox">
                      <CancelIcon
                        className="cancelBtn"
                        onClick={() => {
                          const data: AudienceData = produce(formData, (draft) => {
                            delete draft.rules.service;
                          });
                          setFormData(data);
                          setTriggerAudienceSize((val) => !val);
                        }}
                      />
                      <ServiceSelectionField
                        onSelect={(val) => {
                          const data: AudienceData = produce(formData, (draft) => {
                            draft.rules.service = { id: val.id, duration: val.duration };
                          });
                          setFormData(data);
                          setTriggerAudienceSize((v) => !v);
                        }}
                        val={formData.rules.service}
                      />
                    </Box>
                    <Alert severity="info">
                      Audience for this rule will be available next day only.
                    </Alert>
                  </Box>
                )}
              </Box>

              <Typography>Current client counts for this criteria: {audienceSize}</Typography>

              <Typography
                sx={{
                  fontWeight: 700,
                  mt: 2,
                  mb: 1
                }}
              >
                Available criterion
              </Typography>

              {!formData.rules.client.age && (
                <Box
                  data-testmation="ageRange"
                  className="addOption"
                  onClick={() => {
                    const data: AudienceData = cloneDeep(formData);
                    if (!data.rules.client) data.rules = { ...data.rules, client: {} };
                    if (!data.rules.client.age)
                      data.rules.client = { ...data.rules.client, age: {} };
                    setFormData(data);
                    setTriggerAudienceSize((val) => !val);
                  }}
                >
                  <Typography>{t("campaign.audience.age")}</Typography>
                </Box>
              )}
            </Box>
            {!formData.rules.client.gender && (
              <Box
                className="addOption"
                onClick={() => {
                  const data: AudienceData = cloneDeep(formData);
                  if (!data.rules.client) data.rules = { ...data.rules, client: {} };
                  if (!data.rules.client.gender)
                    data.rules.client = { ...data.rules.client, gender: 1 };
                  setFormData(data);
                  setTriggerAudienceSize((val) => !val);
                }}
              >
                <Typography>{t("campaign.audience.gender")}</Typography>
              </Box>
            )}
            {!formData.rules.clientLedger?.dueAmount && (
              <Box
                className="addOption"
                onClick={() => {
                  const data: AudienceData = cloneDeep(formData);
                  if (!data.rules.clientLedger) data.rules = { ...data.rules, clientLedger: {} };
                  if (!data.rules.clientLedger.dueAmount)
                    data.rules.clientLedger = {
                      ...data.rules.clientLedger,
                      dueAmount: {
                        gte: 0,
                        lte: 0
                      }
                    };
                  setFormData(data);
                  setTriggerAudienceSize((val) => !val);
                }}
              >
                <Typography>{t("campaign.audience.due")}</Typography>
              </Box>
            )}
            {!formData.rules.client.dob && (
              <Box
                className="addOption"
                onClick={() => {
                  const data: AudienceData = cloneDeep(formData);
                  if (!data.rules.client) data.rules = { ...data.rules, client: {} };
                  if (!data.rules.client.dob) {
                    data.rules.client = { ...data.rules.client, dob: " " };
                  }
                  setFormData(data);
                  setTriggerAudienceSize((val) => !val);
                }}
              >
                <Typography>DOB Today</Typography>
              </Box>
            )}
            {!formData.rules.service && (
              <Box
                className="addOption"
                onClick={() => {
                  const data = produce(formData, (draft) => {
                    draft.rules = { ...draft.rules, service: {} };
                  });
                  setFormData(data);
                  setTriggerAudienceSize((val) => !val);
                }}
              >
                <Typography>Service</Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Panel>
  );
};

export default connect(
  (state: RootState, { audience, audienceId }) => ({
    audience: audience || state.audience.collection.find(({ id }) => Number(audienceId) === id),
    clients: clientsSortedSelector(state)
  }),
  (dispatch: IThunkDispatch) => ({
    actions: {
      onSave: async (audienceData) => {
        await dispatch(audienceActions.saveAudience(audienceData));
      },
      loadClients: () => dispatch(clientActions.getClients()),
      getDynamic: async (audienceData, next) => {
        const value = await dispatch(audienceActions.getAudienceSizeByRules(audienceData, next));
        return value;
      },
      navigateTo: (url) => dispatch(push(url))
    }
  })
)(AudienceCreateEdit);
