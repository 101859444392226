import React from "react";
import { Box, Button } from "@mui/material";
import produce from "immer";
import Panel from "../../../../components/Panel";
import AssessmentSettings from "../AssessmentSettings";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { useParsedProseMirror } from "../../../Lab/LabEntry/LabTestSubRow";
import { deserializeNode, proseMirrorOptions } from "../../../../components/RichTextfield";
import OkhatiDialog from "../../../../components/Dialog/Dialog";
import { resourceCentreActions as actions } from "../../../../actions";

interface Props {
  onClose: () => void;
}
const OpdSettingsPanel = ({ onClose }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const resourceCentre = useAppSelector((state) => state.resources.resourceCentres[0]);
  const [rc, setRc] = React.useState(resourceCentre);
  const [showConfirmDiaglog, setShowConfirmDiaglog] = React.useState(false);
  const [assessmentRemarksState, setAssessmentRemarksState] = useParsedProseMirror(
    rc.settings.printSettings.assessmentPrintSettings.assessmentRemarks || "",
    proseMirrorOptions
  );

  return (
    <Panel
      onClose={onClose}
      title="Opd Settings"
      footer={
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 2,
            width: "100%",
            mx: 4
          }}
        >
          <Button onClick={onClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={() => {
              setShowConfirmDiaglog(true);
            }}
          >
            Save
          </Button>
        </Box>
      }
    >
      <Box px={4} height="calc(100vh - 100px)" overflow="auto">
        <AssessmentSettings
          resourceCentre={rc}
          onChange={(value) => setRc(value)}
          assessmentRemarksState={assessmentRemarksState}
          setAssessmentRemarksState={setAssessmentRemarksState}
        />

        {showConfirmDiaglog && (
          <OkhatiDialog
            title="Change opd settings ?"
            description="Your changes will be permanently saved."
            next={async () => {
              const newRc = produce(rc, (draft) => {
                draft.settings.printSettings.assessmentPrintSettings.assessmentRemarks =
                  deserializeNode(assessmentRemarksState.doc);
              });
              setRc(newRc);
              await dispatch(actions.putResourceCentre(newRc));
              onClose();
              setShowConfirmDiaglog(false);
            }}
            cancel={() => {
              setShowConfirmDiaglog(false);
            }}
            readMode={false}
          />
        )}
      </Box>
    </Panel>
  );
};

export default OpdSettingsPanel;
