import React from "react";
import { Box, Typography, Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import ProductCreate from "../../Services/ProductCreate";
import { RootState } from "../../../store";
import { ServiceInterface } from "../../../interfaces/ServiceInterface";

interface AddLabServiceProps {
  services: ServiceInterface[];
  handleAddServices: (v) => void;
  handleRemoveServices: (v) => void;
  labServices: ServiceInterface[];
}

export default function AddLabService({
  services,
  handleAddServices,
  handleRemoveServices,
  labServices
}: AddLabServiceProps): JSX.Element {
  const [showCreatePanel, setShowCreatePanel] = React.useState(false);
  const [serviceName, setServiceName] = React.useState("");

  const createdService = useSelector((state: RootState) => state.services.lastTouched);

  React.useEffect(() => {
    if (createdService !== null) {
      handleAddServices(createdService);
    }
  }, [createdService]);
  return (
    <>
      <Box display="flex" alignItems="center" width="100%">
        <Autocomplete
          fullWidth
          options={labServices}
          getOptionLabel={(option) => option.name || ""}
          renderOption={(params, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...params} key={option.id}>
              {option.name || ""}
            </li>
          )}
          value={services}
          onChange={(e, v) => {
            if (typeof v === "string") return;
            if (v?.inputValue) {
              setShowCreatePanel(true);
              setServiceName(v?.inputValue);
            } else if (v !== null) {
              handleAddServices(v);
            }
          }}
          filterOptions={(options, params) => {
            if (params.inputValue !== "") {
              options.push({
                inputValue: params.inputValue,
                name: `Add "${params.inputValue}"`
              });
            }
            return options.filter((o) =>
              o.name.toLowerCase().includes(params.inputValue.toLowerCase())
            );
          }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              label="Lab services"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              placeholder="Search and select test/test groups"
              variant="outlined"
              margin="dense"
            />
          )}
        />
      </Box>
      <Box mt="16px">
        {services?.map((item, i) => (
          <Box key={`${item.id}${item.name}`} display="flex" my="6px">
            <Typography style={{ width: "40px" }}>{i + 1}. </Typography>
            <Typography style={{ width: "320px" }}>{item.name}</Typography>
            <CloseIcon style={{ cursor: "pointer" }} onClick={() => handleRemoveServices(i)} />
          </Box>
        ))}
      </Box>
      {showCreatePanel && (
        <ProductCreate
          isPubicTab={false}
          handleClose={() => setShowCreatePanel(false)}
          isOpd
          serviceName={serviceName}
        />
      )}
    </>
  );
}
