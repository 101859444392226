import React from "react";
import { useDispatch, useSelector } from "react-redux";
import differenceBy from "lodash/differenceBy";
import { Autocomplete, Box, Chip, TextField, Typography } from "@mui/material";
import { getBatchSymptoms } from "../../actions/assessments";
import { t } from "../../components/translate";
import styles from "./Assessment.module.css";
import { RootState } from "../../store";
import useAssessmentLabel from "../../hooks/useAssessmentLabel";

interface SymptomsInterface {
  assessmentSymptoms: [];
  onChange: (value) => void;
}

const Symptoms: React.FC<SymptomsInterface> = (props) => {
  const { assessmentSymptoms, onChange } = props;

  const dispatch = useDispatch();
  const symptoms = useSelector((state: RootState) => state.assessments.symptoms);

  const symptomsMap = React.useMemo(
    () => differenceBy(symptoms, assessmentSymptoms, "code"),
    [symptoms, assessmentSymptoms]
  );
  const assessmentLabels = useAssessmentLabel();
  React.useEffect(() => {
    dispatch(getBatchSymptoms());
  }, []);
  return (
    <Box className={styles.assessmentRow}>
      <Box className={styles.assessmentLabel}>
        <Typography component="span" fontWeight="600">
          {assessmentLabels.chiefComplaints}
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        <Autocomplete
          data-testmation="symptomsDropdown"
          multiple
          freeSolo
          options={symptomsMap}
          getOptionLabel={(option) => option.symptom}
          renderOption={(p, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...p} key={option.code}>
              {option.symptom}
            </li>
          )}
          value={assessmentSymptoms}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder={t("assessment.searchOrTypeSymptoms")}
              slotProps={{
                inputLabel: { shrink: true }
              }}
              onBlur={(e) => {
                if (
                  e.currentTarget.value &&
                  !assessmentSymptoms.find((as) => as.symptom === e.currentTarget.value)
                ) {
                  onChange([...assessmentSymptoms, { code: "", symptom: e.currentTarget.value }]);
                }
              }}
            />
          )}
          renderTags={(value, getTagProps) =>
            value.map((option, index) => (
              <Chip
                label={option.symptom}
                size="small"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...getTagProps({ index })}
                key={option.symptom}
              />
            ))
          }
          onChange={(e, v) => {
            if (!v.length) {
              onChange([]);
              return;
            }
            if (typeof v[v.length - 1] === "object") {
              onChange(v);
            } else {
              const newV = v.slice(0, -1);
              if (!newV.find((as) => as.symptom === v[v.length - 1])) {
                const newCollection = [...newV, { code: "", symptom: v[v.length - 1] }];
                onChange(newCollection);
              } else {
                onChange(newV);
              }
            }
          }}
        />
      </Box>
    </Box>
  );
};

export default Symptoms;
