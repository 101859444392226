import * as React from "react";
import { Box, ButtonGroup, Button, Divider, Tooltip } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Popper from "@mui/material/Popper";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import { tl } from "../../components/translate";
import Can from "../Policy/Can";
import { Supplier } from "../../interfaces/StockInterfaces";
import useKitchenPharmacy from "../../hooks/useKitchenPharmacy";
import StockSettingsPanel from "../ResourceCentre/ModuleSettings/Panels/StockSettingsPanel";
import SettingsButton from "../../components/SettingsButton/SettingsButton";

interface EntryButtonProps {
  navigateTo: (url: string) => void;
  suppliers: Supplier;
  setShowCreatePanel: (val: boolean) => void;
  setShowRecordPayment: (val: boolean) => void;
}

const EntryButton = ({
  navigateTo,
  setShowRecordPayment,
  setShowCreatePanel
}: EntryButtonProps): JSX.Element => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [isSettingsOpen, setIsSettingsOpen] = React.useState<boolean>(false);

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  const kitchenPharmacy = useKitchenPharmacy();
  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenMenu(false);
  };
  return (
    <Box display="flex" gap={1}>
      <div>
        <ButtonGroup
          size="small"
          variant="contained"
          color="primary"
          ref={anchorRef}
          aria-label="split button"
        >
          <Tooltip title="Alt + I" arrow>
            <Button
              data-testmation="createPurhcaseEntry"
              onClick={() => navigateTo("/stock/purchase")}
              color="primary"
              style={{ width: "145px", height: "32px" }}
            >
              {tl("stock.enterPurchase")}
            </Button>
          </Tooltip>
          <Button
            size="small"
            color="primary"
            aria-owns={openMenu ? "menu-list-grow" : undefined}
            aria-haspopup="true"
            onClick={handleToggle}
            data-testmation="createStockTransactionDownArrowButton"
          >
            <ArrowDropDownIcon />
          </Button>
        </ButtonGroup>
        <Popper
          style={{ zIndex: 1 }}
          open={openMenu}
          anchorEl={anchorRef.current}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom" ? "right bottom" : "right bottom"
              }}
            >
              <Paper id="menu-list-grow">
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    <Can policyAccessKey="stock:expiryDiscard">
                      <MenuItem
                        data-testmation="expiryOrDiscardment"
                        onClick={() => {
                          navigateTo("/stock/expiryOrDiscardment");
                        }}
                        color="primary"
                        style={{ width: "200px" }}
                      >
                        {tl("stock.expiryOrDiscardment")}
                      </MenuItem>
                    </Can>
                    <MenuItem
                      data-testmation="excess"
                      onClick={() => {
                        navigateTo("/stock/excess");
                      }}
                      color="primary"
                      style={{ width: "200px" }}
                    >
                      {tl("stock.excess")}
                    </MenuItem>
                    <Can policyAccessKey="stock:internalUse">
                      <MenuItem
                        data-testmation="internalUse"
                        onClick={() => {
                          navigateTo("/stock/internalUse");
                        }}
                        color="primary"
                        style={{ width: "200px" }}
                      >
                        {tl("stock.internalUse")}
                      </MenuItem>
                    </Can>
                    <Can policyAccessKey="stock:internalRestock">
                      <MenuItem
                        data-testmation="internalRestock"
                        onClick={() => {
                          navigateTo("/stock/internalReturn");
                        }}
                        color="primary"
                        style={{ width: "200px" }}
                      >
                        {tl("stock.internalRestock")}
                      </MenuItem>
                    </Can>
                    <MenuItem
                      data-testmation="openingStock"
                      onClick={() => {
                        navigateTo("/stock/openingStock");
                      }}
                      color="primary"
                      style={{ width: "200px" }}
                    >
                      {tl("stock.openingStock")}
                    </MenuItem>
                    <MenuItem
                      data-testmation="recordPayment"
                      onClick={() => {
                        setShowRecordPayment(true);
                      }}
                      color="primary"
                      style={{ width: "200px" }}
                    >
                      {tl("stock.recordPayment")}
                    </MenuItem>
                    {kitchenPharmacy?.id && (
                      <MenuItem
                        data-testmation="kitchenPharmacyPurchase"
                        onClick={() => {
                          navigateTo("/stock/kitchenPharmacyPurchase");
                        }}
                        color="primary"
                        style={{ width: "200px" }}
                      >
                        {tl("stockTransferRequest")}
                      </MenuItem>
                    )}
                    <Divider />
                    <MenuItem
                      data-testmation="createProduct"
                      onClick={() => {
                        setShowCreatePanel(true);
                      }}
                      color="primary"
                      style={{ width: "200px" }}
                    >
                      {tl("stock.createProduct")}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
      <Can policyAccessKey="stock:changeSettings">
        <SettingsButton onClick={() => setIsSettingsOpen(true)} />
      </Can>
      {isSettingsOpen && <StockSettingsPanel onClose={() => setIsSettingsOpen(false)} />}
    </Box>
  );
};

export default EntryButton;
