import { BookingStatus } from "../containers/Bookings/ManageBooking";
import { KnownUsFrom } from "../models/Client";
import { TransactionType } from "./Accounts";
import { ProductInterface } from "./ProductInterface";

export interface Client {
  // eslint-disable-next-line camelcase
  created_at: string;
  name: string;
  active: boolean;
  ssfId: string;
  ssfUuid: string;
  address: string;
  appliedCountry: string;
  city: string;
  customerDetails: CustomerDetails;
  customerNumber: string;
  dob: string | Date;
  email: string;
  internalNotes: string;
  ethnicity: string;
  externalIdentifier: string;
  firstName: string;
  gender: "1" | "2" | "3";
  id: number;
  associatedCompanyId: number;
  ipdNo: string;
  lastName: string;
  maritalStatus: string;
  nationality: string;
  oid: string;
  palikaId: number;
  passportInfo: PasswordInfo;
  phone: string;
  secondaryPhone: string;
  registrationNo: string | number;
  resourceCentreId: number;
  uniqueId: string;
  wardNo: string;
  bloodGroup: string;
  fullName?: string;
  isWalkInCustomer: boolean;
  image?: Blob;
  ledgerId?: number;
  lastHandledSpId: number;
  openingBalance: number;
  openingBalanceDate: string;
  transactionType: TransactionType.CREDIT | TransactionType.DEBIT;
  knownUsFrom: KnownUsFrom;
  isCorporateClient: boolean;
  panVatNumber: string;
  // payment term in days
  paymentTerm: number;
  nationalIdentityNumber: string;
  appLinkSentDate: Date;
}

interface PasswordInfo {
  expiryDate: string;
  issueDate: string;
  issuingAuthority: string;
  passportNumber: string;
}

export interface Customer {
  id: number;
  clientId: number;
  customerDetails: CustomerDetails;
  externalidentifier: string;
  customerNumber: string;
}

interface CustomerDetails {
  profileImageS3Url: string;
}

export interface ClientVisit {
  bookableResourceId: number;
  bookingType: string;
  clientId: number;
  // eslint-disable-next-line camelcase
  created_at: string;
  end: string;
  eventId: number;
  extra: null;
  followUp: boolean;
  id: number;
  isVideoAppointment: boolean;
  knownFrom: string;
  paymentInfo: { amount: number; isPaid: boolean; required: boolean };
  predecessorId: number;
  reasonOfVisit: string;
  referredBy: string;
  referrerId: number;
  remarks: string;
  resourceCentreId: number;
  serviceId: number;
  serviceProviderId: number;
  services: Array<ProductInterface>;
  start: string;
  status: BookingStatus;
  throughPublic: boolean;
  // eslint-disable-next-line camelcase
  updated_at: string;
  uuid: string;
  from: Date;
}

export interface ClientLedger {
  amount: number;
  balance: number;
  clientId: number;
  documentNumber: number;
  id: number;
  paymentMethod: string;
  remarks: string;
  resourceCentreId: number;
  transactionDate: Date;
  transactionType: string;
  voucherId: number;
  voucherType: string;
}

export enum Gender {
  Male = "1",
  Female = "2",
  Other = "3"
}
