import * as React from "react";
import { connect, useSelector } from "react-redux";
import produce from "immer";
import { Autocomplete, Box, IconButton, Popper, Typography } from "@mui/material";
import { LaunchOutlined } from "@mui/icons-material";
import { getStockProducts } from "../../../../api/stockProducts";
import CreateStockProduct from "../../StockCreateEdit/CreateStockProduct";
import Panel from "../../../../components/Panel";
import { tl, t } from "../../../../components/translate";
import {
  Entry,
  PriceCalculatedOn,
  Stock,
  StockItemInterface,
  StockProducts,
  TransactionType
} from "../../../../interfaces/StockInterfaces";

import StockProductEdit from "../../StockCreateEdit/StockProductEdit";
import { IThunkDispatch, RootState } from "../../../../store";
import { getStockProductById } from "../../../../actions/stockProductActions";
import hasOwnProperty from "../../../../helpers/object";
import DebouncedTextField from "../../../../components/DebouncedTextField";

interface PurchaseTaxationProps {
  id: number;
  code: string;
  description: string;
  rate: string;
}
const WidePopper = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <Popper {...props} style={{ width: "600px" }} placement="bottom-start" />
);
const StockItem = ({
  item,
  updateStockItem,
  getBatchs,
  entry,
  stocks,
  stockProducts,
  getProductById,
  error = false,
  batchRef = null,
  focusBatch = null
}: {
  item: StockItemInterface;
  updateStockItem: (item: StockItemInterface) => void;
  entry: Entry;
  stocks: Stock[];
  stockProducts: StockProducts[];
  getProductById: (id: number) => void;
  getBatchs: (id: number) => void;
  error: boolean;
  batchRef?: React.RefObject<HTMLInputElement> | null;
  focusBatch?: () => void | null;
}) => {
  const [showCreatePanel, setShowCreatePanel] = React.useState(false);
  const [showEditPanel, setShowEditPanel] = React.useState(false);
  const [editProductId, setEditProductId] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState(null);
  const [productName, setProductName] = React.useState("");
  const [stockItemOptions, setStockItemOptions] = React.useState<StockProducts[]>(
    stockProducts || []
  );

  const isAccountSubscribed = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription?.features?.account?.subscribed
  );

  const originalSelectedItem = React.useRef(null);

  React.useEffect(() => {
    setStockItemOptions(stockProducts);
    const foundStock = stockProducts.find((product) => product.id === editProductId);
    if (editProductId && !foundStock) {
      getProductById(editProductId);
    }
  }, [editProductId, getProductById, stockProducts]);

  React.useEffect(() => {
    if (item && item.productId && item.batchId) {
      const relatedStock =
        stocks.find(
          (stock) => stock.productId === item.productId && item.batchId === stock.batchId
        ) || null;
      if (relatedStock) {
        if (["purchaseReturn", "expiryOrDiscardment"].includes(entry.transactionType)) {
          updateStockItem({
            ...item,
            expiryDate: relatedStock.expiryDate,
            price: Number(relatedStock.purchasePrice) || Number(relatedStock.avgPurchasePrice)
          });
        }
      }
      setSelectedItem({ ...item, name: item.productName, id: item.productId });
      originalSelectedItem.current = { ...item, name: item.productName, id: item.productId };
    }
    if (!item.productId) {
      setSelectedItem(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.productId, item.batchId]);

  const setVatPercent = (taxation: PurchaseTaxationProps[], isAccSubscribed: boolean): number => {
    if (!isAccSubscribed || !taxation?.length) return 0;
    return Number(taxation[0].rate) || 0;
  };

  return (
    <Box width="100%">
      <Box display="flex" width="100%">
        <Box mt="-8px" width="100%">
          <Autocomplete
            options={stockItemOptions}
            clearOnBlur
            fullWidth
            freeSolo
            value={selectedItem}
            onChange={(_, val) => {
              if (typeof val === "string") {
                return;
              }
              if (val?.inputValue) {
                setShowCreatePanel(true);
                setProductName(val.inputValue);
              } else {
                setSelectedItem(val);
                const stock = val?.stocks.find((stockItem) => stockItem.batchId === item.batchId);
                if (val) {
                  updateStockItem({
                    ...item,
                    productName: val.name,
                    productId: val.id,
                    unit: val.unit,
                    price:
                      val.purchasePriceCalculationOn === PriceCalculatedOn.unit
                        ? Number(stock?.purchasePrice || Number(val.purchasePricePerUnit) || 0)
                        : Number(val.purchasePricePerPackage) ||
                          Number(val.purchasePricePerUnit) * Number(val.unitsPerPackage),
                    netTotal:
                      (val.purchasePriceCalculationOn === PriceCalculatedOn.unit
                        ? Number(stock?.purchasePrice || Number(val.purchasePricePerUnit) || 0)
                        : Number(val.purchasePricePerPackage) ||
                          Number(val.purchasePricePerUnit) * Number(val.unitsPerPackage)) *
                      item.quantity,
                    purchasePricePerUnit: Number(
                      stock?.purchasePrice || Number(val.purchasePricePerUnit) || 0
                    ),
                    purchasePricePerPackage:
                      Number(val.purchasePricePerPackage) ||
                      Number(val.purchasePricePerUnit) * Number(val.unitsPerPackage),
                    calculationBasis: "perUnit",
                    package: val.package,
                    unitsPerPackage: val.unitsPerPackage,
                    unitPriceExcVAT: val.unitPriceExcVAT,
                    vatPct: setVatPercent(val.purchaseTaxation, isAccountSubscribed),
                    discountPct: Number(val.discountPct || 0),
                    paidAll: true,
                    paidAmount: Number(val.paidAmount || 0),
                    productType: val.productType,
                    unitPriceIncVAT: val.unitPriceIncVAT,
                    packagePriceExcVAT: val.packagePriceIncVAT,
                    packagePriceIncVAT: val.packagePriceExcVAT,
                    salesPriceCalculationOn:
                      val.priceCalculationOn === PriceCalculatedOn.unit ? val.unit : val.package,
                    enteredQuantityUnit: val.purchasePriceCalculationOn || PriceCalculatedOn.unit,
                    discountAmt: Number(val?.discountAmt) || 0,
                    salesVatPct: Number(val.vatPct) || 0,
                    purchaseTaxationId: val.purchaseTaxationId,
                    purchaseLedgerId: val.purchaseLedgerId,
                    internalExpenseLedgerId: val.internalExpenseLedgerId,
                    purchasePriceCalculationOn:
                      val.purchasePriceCalculationOn || PriceCalculatedOn.unit,
                    priceCalculationOn: val.priceCalculationOn || PriceCalculatedOn.unit,
                    kitchenPharmacyProductId: val.kitchenPharmacyProductId
                  });
                  getBatchs(val.id);
                }
              }
            }}
            disabled={[
              TransactionType.EDIT_PURCHASE,
              TransactionType.ADJUSTMENT,
              TransactionType.PURCHASE_RETURN
            ].includes(entry.transactionType as TransactionType)}
            filterOptions={(options, params) => {
              if (params.inputValue !== "") {
                options.push({
                  inputValue: params.inputValue,
                  name: `Add "${params.inputValue}"`
                });
              }
              return options;
            }}
            renderInput={(params) => (
              <DebouncedTextField
                debounceAt={1000}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                className="stock_item_autocomplete"
                data-testmation="selectItem"
                label={tl("StockEntry.SelectItem")}
                placeholder={t("StockEntry.SelectItem")}
                slotProps={{
                  inputLabel: { shrink: true }
                }}
                variant="outlined"
                margin="dense"
                error={error}
                onFocus={(e) => e.target.select()}
                onChange={async ({ target }) => {
                  const query = (target.value || "").trim();
                  if (query.length > 2) {
                    const products = await getStockProducts({
                      search: query,
                      transactionType: entry.transactionType === "internal" ? 2 : 1,
                      limit: 20,
                      intangible: false,
                      forKitchenPharmacyPurchase:
                        entry.transactionType === TransactionType.KITCHEN_PHARMACY_PURCHASE
                    });
                    if (!hasOwnProperty(products, "results") && products?.length) {
                      setStockItemOptions(products as unknown as StockProducts[]);
                    } else {
                      setStockItemOptions([]);
                    }
                  }
                }}
              />
            )}
            slots={{
              popper: WidePopper
            }}
            renderOption={(ownProps, option) => (
              // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
              <li
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...ownProps}
                key={option.id || "customFilterKey"}
                style={{
                  display: "flex",
                  justifyContent: "space-between"
                }}
                onClick={(e) => {
                  ownProps.onClick(e);
                  e.stopPropagation();
                }}
                onKeyDown={null}
              >
                <Typography style={{ width: "80%" }}>{option?.name}</Typography>
                <Typography style={{ width: "20%" }}>
                  {option.unitsPerPackage} {option.unit} / {option.package}
                </Typography>
                {!option.inputValue && (
                  <IconButton
                    onClick={() => {
                      setShowEditPanel(true);
                      setEditProductId(option.id);
                    }}
                  >
                    <LaunchOutlined />
                  </IconButton>
                )}
              </li>
            )}
            getOptionLabel={(option) => option.name}
          />
          {showCreatePanel && (
            <Panel onClose={() => setShowCreatePanel(false)} title="Create Product">
              <CreateStockProduct
                batchRef={batchRef}
                focusBatch={focusBatch}
                productName={productName}
                handleClose={() => setShowCreatePanel(false)}
                saveAction={(savedItem) => {
                  const val = savedItem;
                  const updatedStockItemOptions = produce(stockItemOptions, (draft) => {
                    draft.push(savedItem);
                  });
                  setStockItemOptions(updatedStockItemOptions);
                  setSelectedItem(savedItem);
                  updateStockItem({
                    ...item,
                    productName: val.name,
                    productId: val.id,
                    unit: val.unit,
                    price:
                      val.purchasePriceCalculationOn === PriceCalculatedOn.unit
                        ? Number(val.purchasePricePerUnit) || 0
                        : Number(val.purchasePricePerPackage),
                    calculationBasis: "perUnit",
                    package: val.package,
                    unitsPerPackage: val.unitsPerPackage,
                    unitPriceExcVAT: Number(val.unitPriceExcVAT),
                    productType: val.productType,
                    vatPct: setVatPercent(val.purchaseTaxation, isAccountSubscribed),
                    discountPct: Number(val.discountPct || 0),
                    paidAll: true,
                    paidAmount: Number(val.paidAmount || 0),
                    unitPriceIncVAT: Number(val.unitPriceIncVAT),
                    packagePriceExcVAT: val.packagePriceIncVAT,
                    packagePriceIncVAT: val.packagePriceExcVAT,
                    salesPriceCalculationOn:
                      val.priceCalculationOn === PriceCalculatedOn.unit ? val.unit : val.package,
                    enteredQuantityUnit: val.purchasePriceCalculationOn || PriceCalculatedOn.unit,
                    salesVatPct: Number(val.vatPct || 0),
                    purchasePricePerUnit: Number(val.purchasePricePerUnit),
                    purchasePricePerPackage: Number(val.purchasePricePerPackage),
                    purchaseTaxationId: val.purchaseTaxationId,
                    purchaseLedgerId: val.purchaseLedgerId,
                    internalExpenseLedgerId: val.internalExpenseLedgerId,
                    purchasePriceCalculationOn:
                      val.purchasePriceCalculationOn || PriceCalculatedOn.unit,
                    priceCalculationOn: val.priceCalculationOn || PriceCalculatedOn.unit,
                    netTotal:
                      (val.purchasePriceCalculationOn === PriceCalculatedOn.unit
                        ? Number(val.purchasePricePerUnit)
                        : Number(val.purchasePricePerPackage) ||
                          Number(val.purchasePricePerUnit) * Number(val.unitsPerPackage)) *
                      item.quantity,
                    kitchenPharmacyProductId: val.kitchenPharmacyProductId
                  });
                  getBatchs(val.id);
                }}
                mode="Create"
              />
            </Panel>
          )}
          {showEditPanel && (
            <StockProductEdit
              editMode
              data={stockProducts.find((stockProduct) => stockProduct.id === editProductId)}
              handleClose={() => {
                setEditProductId(null);
                setShowEditPanel(false);
              }}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

StockItem.defaultProps = {
  batchRef: null,
  focusBatch: null
};

export default connect(
  (state: RootState) => ({
    stockProducts: state.stockProducts.collection
  }),
  (dispatch: IThunkDispatch) => ({
    getProductById: (id) => dispatch(getStockProductById(id))
  })
)(StockItem);
