import React from "react";
import type { JSX } from "react";
import { alpha, useTheme } from "@mui/material";

interface PropType {
  backgroundColor?: string;
  foregroundColor?: string;
}

export default function EmptyListIcon({ foregroundColor, backgroundColor }: PropType): JSX.Element {
  const theme = useTheme();
  const fgColor = theme.palette.primary.main || foregroundColor;
  const bgColor = alpha(theme.palette.secondary.main, 0.2) || backgroundColor;

  return (
    <svg
      width="184"
      height="98"
      viewBox="0 0 184 98"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="10" y="11" width="164" height="17" rx="3" fill={bgColor} />
      <rect x="17" y="15" width="15" height="8" opacity={0.5} fill={fgColor} />
      <rect x="47" y="15" width="35" height="8" opacity={0.5} fill={fgColor} />
      <rect x="132" y="15" width="35" height="8" opacity={0.5} fill={fgColor} />
      <rect x="10" y="31" width="164" height="17" rx="3" fill={bgColor} />
      <rect x="10" y="51" width="164" height="17" rx="3" fill={bgColor} />
      <rect x="10" y="71" width="164" height="17" rx="3" fill={bgColor} />
      <rect x="17" y="35" width="15" height="8" opacity={0.5} fill={fgColor} />
      <rect x="17" y="55" width="15" height="8" opacity={0.5} fill={fgColor} />
      <rect x="17" y="75" width="15" height="8" opacity={0.5} fill={fgColor} />
      <rect x="47" y="35" width="35" height="8" opacity={0.5} fill={fgColor} />
      <rect x="132" y="35" width="35" height="8" opacity={0.5} fill={fgColor} />
      <rect x="47" y="55" width="35" height="8" opacity={0.5} fill={fgColor} />
      <rect x="132" y="55" width="35" height="8" opacity={0.5} fill={fgColor} />
      <rect x="47" y="75" width="35" height="8" opacity={0.5} fill={fgColor} />
      <rect x="132" y="75" width="35" height="8" opacity={0.5} fill={fgColor} />
    </svg>
  );
}
