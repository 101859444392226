import React from "react";
import { Box, Menu, MenuItem, Divider, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ClientSearch from "../../components/ClientSearch";
import { tl, t } from "../../components/translate";
import ClientInfo from "../Client/ClientInfo/ClientInfo";
import ClientCreateEdit from "../Client/ClientCreateEdit";
import { clientSearchSecondaryLabel } from "../../models/Client";
import BookingSearch from "../../components/BookingSearch";
import Can from "../Policy/Can";
import useMobileScreen from "../../hooks/useMobileScreen";
import classNames from "../../helpers/classNames";

const useStyles = makeStyles(() => ({
  searchBox: {
    display: "flex",
    width: "36px",
    height: "30px",
    background: "#fff",
    borderRadius: "5px",
    alignItems: "center",
    paddingTop: "3px",
    marginRight: "5px",
    transition: "width .6s, background 1s ease",
    overflow: "hidden"
  },
  noBackground: { background: "none" },
  onFocus: {
    background: "#ececec",
    width: "250px",
    paddingTop: "0px",
    marginRight: "5px"
  },
  searchIcon: {
    margin: "0px 5px",
    position: "absolute",
    "@media (max-width:960px)": {
      color: "#fff"
    }
  },
  inputOnFocus: {
    marginLeft: "30px"
  },
  divider: {
    height: 20,
    marginRight: 15
  }
}));

const SearchBox = (): React.ReactElement => {
  const classes = useStyles();
  const [focused, setFocused] = React.useState(false);
  const [selectedClient, setSelectedClient] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [clientEditMode, setClientEditMode] = React.useState(false);
  const menuRef = React.useRef(null);

  const isMobileScreen = useMobileScreen(960);

  const handleViewClose = () => {
    setSelectedClient(null);
  };

  const handleBlurEvent = (e) => {
    if (e.relatedTarget?.className.includes("disableBlur")) return;
    setFocused(false);
  };

  const searchComponentMapper = {
    CLIENT: {
      label: tl("billing.client"),
      component: (
        <Can policyAccessKey="clients:searchClient">
          <ClientSearch
            autoFocus={false}
            onFocus={() => setFocused(true)}
            onBlur={handleBlurEvent}
            client={selectedClient}
            setClient={setSelectedClient}
            secondaryText={[
              {
                type: clientSearchSecondaryLabel.CUSTOMER_NUMBER
              },
              {
                type: clientSearchSecondaryLabel.EXTERNAL_IDENTIFIER,
                label: t("client.externalIdentifier.short")
              }
            ]}
          />
        </Can>
      )
    },
    BOOKING: {
      label: tl("booking.search"),
      component: (
        <Can policyAccessKey="booking:listBooking">
          <BookingSearch onBlur={handleBlurEvent} />
        </Can>
      )
    }
  };

  const menuItems = Object.keys(searchComponentMapper);
  const [active, setActive] = React.useState(menuItems[0]);

  React.useEffect(() => {
    if (!focused) {
      setActive(menuItems[0]);
    }
  }, [focused]);

  const handleClick = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const toggleMenu = (
    <div>
      <Box
        onClick={handleClick}
        display="flex"
        style={{ cursor: "pointer" }}
        height="100%"
        tabIndex={0}
        className="disableBlur" // used for losing focus on blur
      >
        <Typography>{searchComponentMapper[active].label}</Typography>
        <ExpandMoreIcon />
      </Box>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        ref={menuRef}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item}
            onClick={() => {
              setAnchorEl(null);
              setActive(item);
            }}
          >
            {searchComponentMapper[item].label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  return (
    <Can policyAccessKey={["booking:listBooking", "clients:searchClient"]} partialCheck>
      <Box
        className={classNames(classes.searchBox, {
          [classes.onFocus]: focused,
          [classes.noBackground]: isMobileScreen
        })}
      >
        <SearchIcon className={classes.searchIcon} color="primary" />
        <Box className={classNames({ [classes.inputOnFocus]: focused })} style={{ width: "200px" }}>
          {searchComponentMapper[active].component}
        </Box>
        <Divider className={`${classes.divider}`} orientation="vertical" />
        <Box style={{ marginRight: "10px" }}>{toggleMenu}</Box>
        {selectedClient?.id &&
          (clientEditMode ? (
            <ClientCreateEdit
              setEditMode={setClientEditMode}
              clientId={selectedClient.id}
              mode="edit"
              onCancel={() => setClientEditMode(false)}
              stayOnCurrentPage
            />
          ) : (
            <ClientInfo
              id={selectedClient.id}
              handleViewClose={handleViewClose}
              setEditMode={setClientEditMode}
              stayOnCurrentPage
            />
          ))}
      </Box>
    </Can>
  );
};

export default SearchBox;
