import React, { useState, useEffect } from "react";
import { LinePath } from "@vx/shape";
import { Drag } from "@vx/drag";
import { curveBasis } from "@vx/curve";
import { Typography, Button, Grid2 as Grid, ButtonGroup, DialogTitle, Box } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MuiDialogTitle from "@mui/material/DialogTitle";
import NotInterestedIcon from "@mui/icons-material/NotInterested";
import { tl } from "../../components/translate";

interface DrawingBoardProps {
  closeModal: () => void;
  title: JSX.Element;
  handleImageSave: (img: string, drawingBoardData: [], imageIndex: number) => void;
  width: number;
  height: number;
  boardData: [];
  setBoardData: React.Dispatch<React.SetStateAction<any[]>>;
  imageIndex: number;
  setImageIndex: React.Dispatch<React.SetStateAction<number>>;
  isAssessmentForm: boolean;
}
let svgPath;
const DrawingBoard: React.FC<DrawingBoardProps> = ({
  closeModal,
  title,
  handleImageSave,
  width,
  height,
  boardData,
  setBoardData,
  imageIndex,
  setImageIndex,
  isAssessmentForm = false
}) => {
  const [redoData, setRedoData] = useState([]);
  const [imageURL, setImageURL] = useState("");

  const importAll = (requireContext) => requireContext.keys().map(requireContext);

  const imagesPath = React.useMemo(
    () =>
      isAssessmentForm
        ? ["", ...importAll(require.context("../../../assets/images/assessmentTemplates/", false))]
        : [],
    [isAssessmentForm]
  );

  const getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      };
    });
  };

  useEffect(() => {
    const svg = document.getElementById("svg");
    svgPath = svg;
  });

  useEffect(() => {
    if (imageIndex !== 0)
      getBase64FromUrl(imagesPath[imageIndex]).then((result) => setImageURL(result as string));
    else setImageURL("");
  }, [imageIndex]);

  const downloadImageFn = () => {
    const xml = new XMLSerializer().serializeToString(svgPath);
    const svg64 = btoa(xml);
    const b64Start = "data:image/svg+xml;base64,";
    const image64 = b64Start + svg64;
    handleImageSave(image64, boardData, imageIndex);
    closeModal();
  };

  const clearFn = () => {
    setBoardData([]);
    setRedoData([]);
  };

  const undoFn = () => {
    if (boardData && boardData.length) {
      const updatedRedoData = redoData;
      updatedRedoData.push(boardData[boardData.length - 1]);
      setRedoData(updatedRedoData);
      const updatedBoardData = boardData.slice(0, -1);
      setBoardData(updatedBoardData);
    }
  };

  const redoFn = () => {
    if (redoData && redoData.length) {
      const lastElement = [redoData[redoData.length - 1]];
      const updatedBoardData = boardData.concat(lastElement);
      const updatedRedoData = redoData.slice(0, -1);
      setBoardData(updatedBoardData);
      setRedoData(updatedRedoData);
    }
  };
  return (
    <>
      <DialogTitle className="dgTitle">
        <MuiDialogTitle className="dgTitle">
          <Grid container>
            <Grid size={{ xs: 4 }}>
              <Typography>
                <b>{title}</b>
              </Typography>
            </Grid>
            <Grid size={{ xs: 8 }} className="txtAlgnEnd">
              <ButtonGroup size="small" aria-label="Small outlined button group">
                <Button
                  onClick={() => {
                    undoFn();
                  }}
                  data-testmation="drawingBoardUndoButton"
                >
                  {tl("assessment.undo")}
                </Button>
                <Button
                  onClick={() => {
                    redoFn();
                  }}
                  data-testmation="drawingBoardRedoButton"
                >
                  {tl("assessment.redo")}
                </Button>
                <Button
                  onClick={() => {
                    clearFn();
                  }}
                  data-testmation="drawingBoardClearButton"
                >
                  {tl("assessment.clear")}
                </Button>
              </ButtonGroup>
            </Grid>
          </Grid>
        </MuiDialogTitle>
      </DialogTitle>
      <DialogContent style={{ padding: 0 }}>
        <Box
          display="flex"
          justifyContent="center"
          style={{
            border: "1px solid lightgrey",
            width: `calc(${width} + 1px)`,
            height: `calc(${height} + 1px)`
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            id="svg"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
          >
            <rect id="rect" fill="white" width={width} height={height} rx={5} />
            <image href={imageURL} width={width} height={height} style={{ opacity: 0.3 }} />

            {boardData.map((d, i) => (
              <LinePath
                // eslint-disable-next-line react/no-array-index-key
                key={`line-${i}`}
                fill="transparent"
                stroke="black"
                strokeWidth={3}
                data={d}
                curve={curveBasis}
                x={(data) => data.x}
                y={(data) => data.y}
              />
            ))}

            <Drag
              width={width}
              height={height}
              resetOnStart
              onDragStart={({ x, y }) => {
                const newLine = [{ x, y }];
                const updatedBoardData = boardData.concat([newLine]);
                setBoardData(updatedBoardData);
              }}
              onDragMove={({ x, y, dx, dy }) => {
                const nextData = [...boardData];
                const point = [{ x: x + dx, y: y + dy }];
                const i = nextData.length - 1;
                nextData[i] = nextData[i].concat(point);
                setBoardData(nextData);
              }}
            >
              {({ x, y, dx, dy, isDragging, dragStart, dragEnd, dragMove }) => (
                <g>
                  {isDragging && (
                    <g>
                      <rect
                        fill="white"
                        width={8}
                        height={8}
                        x={x + dx - 4}
                        y={y + dy - 4}
                        className="pntEventNe"
                      />
                      <circle
                        cx={x}
                        cy={y}
                        r={4}
                        fill="transparent"
                        stroke="white"
                        className="pntEventNe"
                      />
                    </g>
                  )}
                  <rect
                    fill="transparent"
                    width={width}
                    height={height}
                    onMouseDown={dragStart}
                    onMouseUp={dragEnd}
                    onMouseMove={dragMove}
                    onTouchStart={dragStart}
                    onTouchEnd={dragEnd}
                    onTouchMove={dragMove}
                  />
                </g>
              )}
            </Drag>
          </svg>
        </Box>
      </DialogContent>
      <DialogActions style={{ justifyContent: "space-between" }}>
        <Box display="flex" alignItems="flex-start">
          {imagesPath.map((imagePath, index) => (
            <Box
              key={imagePath}
              onClick={() => {
                setImageIndex(index);
                clearFn();
              }}
              mr={0.8}
              sx={{
                border: index === imageIndex ? "2px solid" : "2px solid transparent"
              }}
            >
              {index === 0 && isAssessmentForm ? (
                <NotInterestedIcon
                  fontSize="large"
                  sx={{
                    display: "flex",
                    "&:hover": {
                      opacity: 0.6,
                      cursor: "pointer"
                    },
                    width: "30px",
                    height: "50px"
                  }}
                />
              ) : (
                <Box
                  display="flex"
                  sx={{
                    "&:hover": {
                      opacity: 0.6,
                      cursor: "pointer"
                    }
                  }}
                >
                  <img
                    src={imagePath}
                    alt={`template${index}`}
                    key={imagePath}
                    style={{
                      width: "30px",
                      height: "50px"
                    }}
                  />
                </Box>
              )}
            </Box>
          ))}
        </Box>

        <Box alignItems="flex-end">
          <Button onClick={closeModal} data-testmation="drawinBoardCancelButton">
            {tl("assessment.cancel")}
          </Button>
          <Button
            color="primary"
            variant="contained"
            onClick={downloadImageFn}
            data-testmation="drawingBoardSaveButton"
          >
            {tl("save")}
          </Button>
        </Box>
      </DialogActions>
    </>
  );
};

export default DrawingBoard;
