import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Dialog,
  Checkbox,
  Box,
  FormControlLabel,
  Typography
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import { startCase } from "lodash";
import React from "react";

export interface Columns {
  [key: string]: boolean;
}

interface Props {
  open: boolean;
  onChange: (columns: Columns) => void;
  onClose: () => void;
  columns: Columns;
  requiredColumns?: string[];
}

export default function ListHeaderShowHideDialog({
  open,
  onChange,
  onClose,
  columns,
  requiredColumns = []
}: Props): JSX.Element {
  const [internalState, setInternalState] = React.useState<Columns>(columns);

  const allChecked = Object.values(internalState).every((val) => val);

  const handleToggleAll = () => {
    const updatedObj = Object.keys(internalState).reduce((acc, key) => {
      if (requiredColumns.includes(key) && allChecked) {
        // eslint-disable-next-line no-param-reassign
        acc[key] = true;
      } else {
        // eslint-disable-next-line no-param-reassign
        acc[key] = !allChecked;
      }
      return acc;
    }, {});
    setInternalState(updatedObj);
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Select Columns</DialogTitle>
      <DialogContent>
        {requiredColumns.length > 0 && (
          <Grid container spacing={2}>
            {Object.keys(internalState)
              .filter((col) => requiredColumns.includes(col))
              .map((column) => {
                const isRequired = requiredColumns.includes(column);
                return (
                  <Grid key={column} size={{ xs: 6 }} alignItems="center">
                    <Box display="flex" alignItems="center">
                      <Checkbox
                        disabled={isRequired}
                        name={column}
                        checked={internalState[column]}
                        onChange={({ target }) =>
                          setInternalState({
                            ...internalState,
                            [target.name]: !internalState[target.name]
                          })
                        }
                      />
                      <Typography>{`${startCase(column)}${isRequired ? "*" : ""}`}</Typography>
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
        )}
        <FormControlLabel
          sx={{
            display: "flex",
            marginLeft: 0,
            borderTop: "1px solid #D3D3D3",
            borderBottom: "1px solid #D3D3D3"
          }}
          label={`${allChecked ? "Unselect All" : "Select All"}`}
          control={<Checkbox checked={allChecked} onChange={handleToggleAll} />}
        />
        <Grid container spacing={2}>
          {Object.keys(internalState)
            .filter((col) => !requiredColumns.includes(col))
            .map((column) => {
              const isRequired = requiredColumns.includes(column);
              return (
                <Grid key={column} size={{ xs: 6 }} alignItems="center">
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      disabled={isRequired}
                      name={column}
                      checked={internalState[column]}
                      onChange={({ target }) =>
                        setInternalState({
                          ...internalState,
                          [target.name]: !internalState[target.name]
                        })
                      }
                    />
                    <Typography>{`${startCase(column)}${isRequired ? "*" : ""}`}</Typography>
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            onChange(internalState);
            onClose();
          }}
          autoFocus
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

ListHeaderShowHideDialog.defaultProps = {
  requiredColumns: []
};
