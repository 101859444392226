import { TextField, MenuItem } from "@mui/material";
import * as React from "react";
import { useSelector } from "react-redux";
import { StockItemInterface } from "../../../../interfaces/StockInterfaces";
import { RootState } from "../../../../store";

interface Props {
  item: StockItemInterface;
  onSalesVatUpdate: (v) => void;
}

export default function StockSalesVat(props: Props): JSX.Element {
  const { item, onSalesVatUpdate } = props;
  const settings = useSelector(
    (state: RootState) => state.userContext?.resourceCentre?.settings?.billingSettings
  );
  const vatPercentageOptions = [0, 13];
  return (
    <div>
      <TextField
        disabled={!settings.vatRegistered}
        select
        label="Sales VAT"
        variant="outlined"
        fullWidth
        slotProps={{
          inputLabel: { shrink: true },
          input: { endAdornment: "%" }
        }}
        value={item.salesVatPct}
        onChange={(e) => {
          onSalesVatUpdate(e.target.value);
        }}
      >
        {vatPercentageOptions.map((val) => (
          <MenuItem value={val} key={val}>
            {val}
          </MenuItem>
        ))}
      </TextField>
    </div>
  );
}
