import { Box, Card, CardActionArea, CardContent, Grid, Typography } from "@mui/material";
import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { resourceCentreActions } from "../../../actions";
import { AsyncAction } from "../../../actions/actionHelpers";
import { tl } from "../../../components/translate";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import SectionHeading from "../SectionHeading";
import CalendarSettingPanel from "./Panels/CalendarSettingPanel";
import OpdSettingsPanel from "./Panels/OpdSettingsPanel";
import LabSettingsPanel from "./Panels/LabSettingsPanel";
import StockSettingsPanel from "./Panels/StockSettingsPanel";
import IpdSettingsPanel from "./Panels/IpdSettingsPanel";
import HmisSettingsPanel from "./Panels/HmisSettingsPanel";
import BillingSettingsPanel from "./Panels/BillingSettingsPanel";
import MedicalSettingsPanel from "./Panels/MedicalSettingsPanel";

enum ITEMS {
  CALENDAR = "Calendar",
  OPD = "OPD",
  LAB = "Lab",
  BILLING = "Billing",
  STOCK = "Stock",
  IPD = "IPD",
  HMIS = "HMIS",
  MEDICAL = "Medical"
}

const itemDescription = {
  [ITEMS.CALENDAR]: "Set primary calendar and starting month",
  [ITEMS.OPD]: "Manage print settings for OPD and components/vitals",
  [ITEMS.BILLING]: "Manage bill editor settings",
  [ITEMS.LAB]: "Create/edit labgroups and tests, manage print settings",
  [ITEMS.STOCK]: "Manage Stock product settings",
  [ITEMS.IPD]: "Edit wards/beds numbers and print settings",
  [ITEMS.HMIS]: "Manage HMIS settings",
  [ITEMS.MEDICAL]: "Edit signature, stamp, and other print settings"
};

export interface ModuleSettingsProps {
  actions?: {
    [x: string]: (options?: Record<string, unknown>, next?: unknown) => AsyncAction<unknown>;
  };
  resourceCentre: ResourceCentre;
}

const ModuleSettings = ({ resourceCentre, actions }: ModuleSettingsProps) => {
  const [panel, setPanel] = React.useState<ITEMS | null>(null);

  return (
    <Box
      sx={{
        pb: 4,
        pr: 2,
        height: "calc(100vh - 70px)",
        overflowY: "auto"
      }}
    >
      <SectionHeading name={tl("module.moduleSetting")} />
      <Grid container spacing={2} mt={2}>
        {Object.values(ITEMS).map((item) => (
          <Grid key={item} xs={12} sm={6} md={4} item>
            <Card variant="outlined">
              <CardActionArea onClick={() => setPanel(item)}>
                <CardContent>
                  <Box display="flex" gap={2} alignItems="center" justifyContent="space-between">
                    <div>
                      <Typography gutterBottom variant="h6">
                        {item}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "text.secondary" }}>
                        {itemDescription[item]}
                      </Typography>
                    </div>
                    <KeyboardArrowRightIcon />
                  </Box>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
      {panel === ITEMS.CALENDAR && (
        <CalendarSettingPanel
          resourceCentre={resourceCentre}
          updateRc={(newRc) => actions.putResourceCentre(newRc)}
          onClose={() => setPanel(null)}
        />
      )}
      {panel === ITEMS.OPD && <OpdSettingsPanel onClose={() => setPanel(null)} />}
      {panel === ITEMS.BILLING && <BillingSettingsPanel onClose={() => setPanel(null)} />}
      {panel === ITEMS.LAB && <LabSettingsPanel onClose={() => setPanel(null)} />}
      {panel === ITEMS.STOCK && <StockSettingsPanel onClose={() => setPanel(null)} />}
      {panel === ITEMS.IPD && <IpdSettingsPanel onClose={() => setPanel(null)} />}
      {panel === ITEMS.HMIS && <HmisSettingsPanel onClose={() => setPanel(null)} />}
      {panel === ITEMS.MEDICAL && <MedicalSettingsPanel onClose={() => setPanel(null)} />}
    </Box>
  );
};

export default connect(null, (dispatch) => ({
  actions: bindActionCreators({ ...resourceCentreActions }, dispatch)
}))(ModuleSettings);
