import { TextField } from "@mui/material";
import * as React from "react";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import { StockItemInterface, TransactionType } from "../../../../interfaces/StockInterfaces";
import { tl } from "../../../../components/translate";

interface Props {
  transactionType: TransactionType;
  item: StockItemInterface;
  onPriceUpdate: (v) => void;
  error: boolean;
  onEnterPress: () => void;
}

export default function StockPrice(props: Props): JSX.Element {
  const { transactionType, item, onPriceUpdate, error, onEnterPress } = props;
  return (
    <div>
      <TextField
        error={error}
        disabled={[
          TransactionType.EDIT_PURCHASE,
          TransactionType.EDIT_OPENING_STOCK,
          TransactionType.ADJUSTMENT
        ].includes(transactionType as TransactionType)}
        value={item.netTotal}
        label={tl("StockEntry.Price")}
        margin="none"
        fullWidth
        onFocus={(e) => e.target.select()}
        variant="outlined"
        type="number"
        slotProps={{
          input: {
            endAdornment: <KeyboardReturnIcon />
          },
          inputLabel: { shrink: true }
        }}
        onChange={(e) => {
          onPriceUpdate(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            onEnterPress();
          }
        }}
      />
    </div>
  );
}
