import { Box, Button, TextField, Typography } from "@mui/material";
import React from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import OkIcon from "@mui/icons-material/CheckCircleOutline";
import CancelIcon from "@mui/icons-material/HighlightOff";
import { useDispatch } from "react-redux";
import {
  patchDepartment,
  postRcDepartment,
  softDeleteDepartment
} from "../../slices/departmentSlice";
import { Department as DepartmentInterface } from "../../interfaces/DepartmentInterface";
import { t, tl } from "../../components/translate";
import useGetDepartments from "../../hooks/department";
import OkhatiDialog from "../../components/Dialog/Dialog";
import { notificationAdd } from "../../actions/notification";

interface IDepartmetState {
  id: null | number;
  name: string;
  shortCode: string;
}

const initialDeptState = {
  id: null,
  name: "",
  shortCode: ""
};

export default function Department(): JSX.Element {
  const dispatch = useDispatch();

  const [department, setDepartment] = React.useState<DepartmentInterface>(
    {} as DepartmentInterface
  );
  const departments = useGetDepartments();

  const changeHandler = (value, changer) => {
    setDepartment({ ...department, [changer]: value });
  };
  const [selectedDeptId, setSelectedDeptId] = React.useState<number | null>(null);
  const [editingDepartment, setEditingDepartment] = React.useState<IDepartmetState>({
    ...initialDeptState
  });

  return (
    <div>
      <Typography fontWeight="bold">{tl("addNewDepartment")}</Typography>
      <Box sx={{ my: "20px", display: "flex", gap: 1 }}>
        <Box sx={{ minWidth: "200px" }}>
          <TextField
            value={department?.name}
            onChange={(e) => changeHandler(e.target.value, "name")}
            variant="outlined"
            label={tl("department.name")}
            placeholder={t("deptName")}
            slotProps={{
              inputLabel: { shrink: true }
            }}
          />
        </Box>
        <Box sx={{ minWidth: "200px" }}>
          <TextField
            value={department?.shortCode}
            onChange={(e) => changeHandler(e.target.value, "shortCode")}
            variant="outlined"
            label={tl("department.shortCode")}
            placeholder={t("department.shortCode")}
            slotProps={{
              inputLabel: { shrink: true }
            }}
          />
        </Box>
        <Button
          onClick={() => {
            dispatch(postRcDepartment(department));
            setDepartment({} as DepartmentInterface);
          }}
          disabled={!department?.name || !department?.shortCode}
          variant="contained"
        >
          {tl("saveDepartment")}
        </Button>
      </Box>
      <div>
        <Box display="flex">
          <Typography fontWeight="bold" width="150px" m="5px 10px">
            {tl("ID")}
          </Typography>
          <Typography fontWeight="bold" width="200px" m="5px 10px">
            {tl("Name")}
          </Typography>
          <Typography fontWeight="bold">Short Code</Typography>
        </Box>

        {[...departments]
          .sort((a, b) => a.id - b.id)
          .map((dept) => (
            <Box display="flex" alignItems="center" key={dept.id}>
              {editingDepartment.id && editingDepartment.id === dept.id ? (
                <>
                  <Box width="200px" m="5px 10px">
                    <TextField
                      value={editingDepartment.name}
                      onChange={(e) => {
                        setEditingDepartment((prevState) => ({
                          ...prevState,
                          name: e.target.value
                        }));
                      }}
                      variant="outlined"
                    />
                  </Box>
                  <Box width="200px" m="5px 10px">
                    <TextField
                      value={editingDepartment.shortCode}
                      onChange={(e) => {
                        setEditingDepartment((prevState) => ({
                          ...prevState,
                          shortCode: e.target.value
                        }));
                      }}
                      slotProps={{
                        htmlInput: {
                          maxLength: 3
                        }
                      }}
                      variant="outlined"
                    />
                  </Box>
                  <Box display="flex">
                    <OkIcon
                      sx={{
                        cursor: "pointer"
                      }}
                      onClick={() => {
                        if (!editingDepartment.name || !editingDepartment.shortCode) {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Name and short code are required for department.",
                              autoTimeout: true
                            })
                          );
                          return;
                        }

                        if (editingDepartment.id) {
                          dispatch(patchDepartment(editingDepartment as DepartmentInterface));
                        } else {
                          dispatch(
                            notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Error updating department.",
                              autoTimeout: true
                            })
                          );
                        }
                        setEditingDepartment({
                          ...initialDeptState
                        });
                      }}
                    />
                    <CancelIcon
                      onClick={() => setEditingDepartment({ ...initialDeptState })}
                      sx={{ marginLeft: "10px", cursor: "pointer" }}
                    />
                  </Box>
                </>
              ) : (
                <>
                  <Box width="150px" m="5px 10px">
                    <Typography>{dept?.id}</Typography>
                  </Box>
                  <Box width="200px" m="5px 10px">
                    <Typography>{dept?.name}</Typography>
                  </Box>
                  <Box m="5px 10px" width="100px" display="flex">
                    <Typography>{dept?.shortCode}</Typography>
                  </Box>
                  <Box m="5px 10px" display="flex">
                    <EditIcon
                      sx={{ cursor: "pointer", fontSize: "16px", color: "rgba(0, 0, 0, 0.54)" }}
                      onClick={() => {
                        setEditingDepartment(dept);
                      }}
                    />
                    <DeleteIcon
                      sx={{
                        cursor: "pointer",
                        marginLeft: "5px",
                        fontSize: "16px",
                        color: "rgba(0, 0, 0, 0.54)"
                      }}
                      onClick={() => {
                        setSelectedDeptId(dept.id);
                      }}
                    />
                  </Box>
                </>
              )}
            </Box>
          ))}
      </div>
      <OkhatiDialog
        title="Warning"
        next={() => {
          dispatch(softDeleteDepartment({ id: selectedDeptId }));
          setSelectedDeptId(null);
        }}
        cancel={() => {
          setSelectedDeptId(null);
        }}
        readMode={false}
        description="Are you sure you want to delete department?"
        open={Boolean(selectedDeptId)}
      />
    </div>
  );
}
