import { Box, Grid2 as Grid, Icon, Typography } from "@mui/material";
import { isArray } from "lodash";
import React from "react";
import { t, tl } from "../../../components/translate";
import { IMedicine } from "../../../interfaces/IpdInterface";
import { countableTypes, medicineType } from "../../Assessment/Medication/medicineType";

const IpdMedicationShow = ({ medicineData }: IMedicine[]): JSX.Element | null => {
  const convertToDays = (weeks) => weeks * 7;
  if (!isArray(medicineData)) return null;
  return (
    <Box>
      {medicineData?.map((item, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box component="div" key={index} m={2}>
          <Grid container>
            <Grid size={{ xs: 10, sm: 4 }}>
              <Box display="flex" alignItems="center" style={{ wordBreak: "break-word" }}>
                <Box pt={1}>
                  <Icon style={{ color: "black" }}>{medicineType[item.form]()}</Icon>
                </Box>
                <Box pl={2}>
                  <Box fontSize="1.02rem" fontWeight="600">
                    {`${item.brand} ${item.strength} `}
                  </Box>
                  <Box>{item.genericName}</Box>
                  {item.remark && (
                    <Typography>
                      <Box component="span">
                        <b>{tl("assessment.medication.remark")}</b> {item.remark}
                      </Box>
                    </Typography>
                  )}
                </Box>
              </Box>
            </Grid>

            <Grid size={{ xs: 6, sm: 3 }}>
              <Box pt={1}>
                <Box>
                  {item.frequency === 0 ? "" : `${item.frequency || ""} ${t("assessment.times")}`}
                  {item.times === "week" ? tl("medication.week") : tl("medication.day")}
                  <br />
                  {item?.meal === "before" && t("assessment.beforeMeal")}
                  {item?.meal === "after" && t("assessment.afterMeal")}
                </Box>
              </Box>
            </Grid>
            <Grid size={{ xs: 6, sm: 3 }}>
              <Box pt={1}>
                {item.frequency !== 0 &&
                  `${
                    countableTypes.includes(item.form)
                      ? item.frequency ||
                        0 *
                          (item.frequencyType === "day"
                            ? item.duration || 0
                            : convertToDays(item.duration || 0))
                      : ""
                  } ${item.form} (${item.duration || 0} ${
                    item.frequencyType === "day"
                      ? t("assessment.medicationDays")
                      : t("assessment.week")
                  })`}
              </Box>
            </Grid>
          </Grid>
        </Box>
      ))}
    </Box>
  );
};

export default IpdMedicationShow;
