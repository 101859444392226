import { Box, Typography, Card, CardContent, Grid2 as Grid, Link, Button } from "@mui/material";
import React from "react";
import CallIcon from "@mui/icons-material/Call";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import EmailIcon from "@mui/icons-material/Email";
import PinDropIcon from "@mui/icons-material/PinDrop";
import { address, salesPhoneNumber, supportEmail, supportPhoneNumber } from "../../constants";
import teamviewerLogo from "../../../assets/images/teamviewer.png";
import anydeskLogo from "../../../assets/images/anydesk.png";

interface InfoCardProps {
  label: string;
  value: string;
  icon: JSX.Element;
}
const InfoCard = ({ label, icon, value }: InfoCardProps) => (
  <Card variant="outlined">
    <CardContent>
      <Box sx={{ display: "flex", gap: 2 }}>
        {icon}
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography>{label}</Typography>
          <Typography fontSize={16}>{value}</Typography>
        </Box>
      </Box>
    </CardContent>
  </Card>
);

const ContactInfo = (): JSX.Element => (
  <Box>
    <Typography variant="h6" component="p">
      Need more help?
    </Typography>
    <Grid container spacing={1} mt={1}>
      <Grid size={{ xs: 12, sm: 6 }}>
        <InfoCard label="Support" value={supportPhoneNumber} icon={<SupportAgentIcon />} />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <InfoCard label="Sales" value={salesPhoneNumber} icon={<CallIcon />} />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <InfoCard label="Email" value={supportEmail} icon={<EmailIcon />} />
      </Grid>
      <Grid size={{ xs: 12, sm: 6 }}>
        <InfoCard label="Head Office" value={address} icon={<PinDropIcon />} />
      </Grid>
    </Grid>
    <Box mt={4}>
      <Typography variant="h6" component="p">
        Downloads
      </Typography>
      <Grid container spacing={1} mt={1}>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Button
            component={Link}
            title="Download TeamViewer"
            href="https://www.teamviewer.com/en/download/windows/"
            target="_blank"
            rel="noreferrer"
          >
            <img src={teamviewerLogo} alt="teamviewer" width={200} />
          </Button>
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Button
            component={Link}
            title="Download AnyDesk"
            href="https://anydesk.com/en/downloads/windows"
            target="_blank"
            rel="noreferrer"
          >
            <img src={anydeskLogo} alt="anydesk" width={200} />
          </Button>
        </Grid>
      </Grid>
    </Box>
  </Box>
);
export default ContactInfo;
