import React from "react";
import { Box, Typography } from "@mui/material";
import { ResourceCentre } from "./LabPrintLetterhead";
import { scaleFont } from "../LabPrintFunctions";
/* eslint-disable react/require-default-props */

interface GovLetterheadProps {
  resourceCentre: ResourceCentre;
  titleFontScale?: number;
  hideRcLogo?: boolean;
  govHeader1: string;
  govHeader2: string;
}

function lineBreak(tobreak: string): Array<string> {
  return tobreak.split("#");
}

const GovLetterhead: React.FC<GovLetterheadProps> = ({
  resourceCentre: {
    resourceCentreLogo,
    name,
    address,
    city,
    registrationNumber,
    phone,
    phone2,
    optionalPhone,
    email,
    panNo
  },
  govHeader1,
  govHeader2,
  titleFontScale = 1,
  hideRcLogo = false
}) => (
  <Box display="flex" justifyContent="space-between">
    <Box sx={{ width: "3.2cm", height: "auto" }}>
      {!hideRcLogo && resourceCentreLogo && (
        <Box>
          <img
            src={resourceCentreLogo.s3ResourceURL}
            alt="logo"
            style={{ width: "3cm", height: "auto" }}
          />
        </Box>
      )}
    </Box>
    <Box flex={1} alignItems="center">
      <Typography
        sx={{
          fontWeight: 500,
          fontSize: scaleFont("0.5cm", titleFontScale),
          textAlign: "center"
        }}
      >
        {lineBreak(govHeader1).map((item) => (
          <Typography key={item} sx={{ fontSize: scaleFont("0.25cm", titleFontScale) }}>
            {item}
          </Typography>
        ))}
        <Typography sx={{ fontSize: "0.35cm" }}>{govHeader2}</Typography>
        {name}
      </Typography>

      <Typography sx={{ textAlign: "center", textTransform: "capitalize", fontSize: "0.325cm" }}>
        {address} {city}
      </Typography>
    </Box>
    <Box sx={{ width: "3.2cm", height: "auto" }}>
      <Box sx={{ textAlign: "right", textTransform: "capitalize", fontSize: "0.25cm" }}>
        {registrationNumber && (
          <Typography sx={{ textAlign: "right", textTransform: "capitalize", fontSize: "0.25cm" }}>
            Reg No: {registrationNumber}
          </Typography>
        )}
        <Typography sx={{ textAlign: "right", fontSize: "0.25cm" }}>
          Phone no: {phone}
          {phone2 && `, ${phone2}`}
          {optionalPhone && `, ${optionalPhone}`}
        </Typography>
        {email && (
          <Typography
            sx={{
              wordWrap: "break-word",
              textAlign: "right",
              fontSize: "0.25cm",
              maxWidth: "3.2cm"
            }}
          >
            {email}
          </Typography>
        )}
      </Box>

      {Boolean(panNo) && (
        <Typography sx={{ textAlign: "right", fontSize: "0.25cm" }}>Pan No: {panNo}</Typography>
      )}
    </Box>
  </Box>
);

export default GovLetterhead;
