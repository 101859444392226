import { Box, Typography } from "@mui/material";
import startCase from "lodash/startCase";
import { chain, round } from "mathjs";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import logo from "../../../../../../assets/images/poweredByOkhati.png";
import { convertADtoBS } from "../../../../../components/Calendar/functions/calendarFunctions";
import { t, tl } from "../../../../../components/translate";
import classNames from "../../../../../helpers/classNames";
import { dateWithZeroBeforeSingleDigits } from "../../../../../helpers/formatters";
import capitalizedName from "../../../../../helpers/nameCapitalizer";
import hasOwnProperty from "../../../../../helpers/object";
import { rupeeDisplay } from "../../../../../helpers/rupee";
import { BillType, DiscountBasedOn, DocumentTypes } from "../../../../../interfaces/BillInterfaces";
import { Client } from "../../../../../interfaces/ClientInterface";
import { spFromIdSelector, spFullNameSelector } from "../../../../../reducers/serviceProvider";
import { RootState } from "../../../../../store";
import {
  BillClientInfo,
  InfoField,
  checkWalkInCustomer
} from "../../../../Billing/PrintBill/BillPrintHelpers";
import "../../../../Billing/PrintBill/PrintBill.scss";
import { getSectionStyle } from "../../../../Lab/LabPrint/LabPrintFunctions";
import { getCustomerNumber } from "../../../../Client/ClientList";
import { showUnitAfterQuantity } from "../../../../../actions/helpers/billHelper";
import BillPrintSummary from "../../../../Billing/BillPrintSummary";

interface Props {
  bill: BillType;
  headerColor: string;
}

const BillDescription = ({ bill, headerColor }: Props): JSX.Element | null => {
  const SPs = useSelector((state: RootState) => state.resources.resourceCentreServiceProviders);
  if (!bill.document) return null;

  const { client, customer } = bill;
  const hasVatPct = bill.document.billItems.some((item) => item.vatPct);

  const billColumns = [
    "sNo",
    "description",
    "quantity",
    "perUnit",
    "discountAmt",
    "vatPct",
    "grossTotal"
  ].filter((header) => {
    if (header === "discountAmt") {
      return !(
        ["discountAmt"].includes(header) &&
        bill.document?.settings.discountSettings.discountBasis === DiscountBasedOn.invoice
      );
    }
    if (header === "vatPct") {
      if (hasVatPct) {
        return true;
      }
      return false;
    }
    return true;
  });

  let netTotal;
  if (bill.document.type === DocumentTypes.CREDITNOTE) {
    netTotal = bill.document.billItems.reduce(
      (sum, item) =>
        chain(sum)
          .add(
            chain(item.perUnit || 0)
              .multiply(item.quantity || 1)
              .add(item.discountAmt || 0)
              .done()
          )
          .done(),
      0
    );
  } else {
    netTotal = bill.document.billItems.reduce(
      (sum, item) =>
        chain(sum)
          .add(
            chain(item.perUnit || 0)
              .multiply(item.quantity || 1)
              .subtract(item.discountAmt || 0)
              .done()
          )
          .done(),
      0
    );
  }

  return (
    <Box>
      <Box m="0.5cm" p="0.3cm" border="1px solid black" display="flex">
        <Box width="50%">
          {checkWalkInCustomer(client as Client, "isWalkInCustomer") && (
            <BillClientInfo
              client={client as Client}
              customerNumber={getCustomerNumber(customer.customerNumber)}
            />
          )}
        </Box>
        <Box width="50%">
          <InfoField
            label={
              bill.type === DocumentTypes.CREDITNOTE
                ? tl("billing.creditNoteNumber")
                : tl("billnumber")
            }
            data={<>: {bill.billNumber}</>}
          />
          {bill.type === DocumentTypes.CREDITNOTE && (
            <InfoField
              label={tl("billing.creditNoteForBill")}
              data={<>: {bill.related?.billNumber}</>}
            />
          )}
          <InfoField
            label={tl("billing.issueDate")}
            data={
              <>
                : {dateWithZeroBeforeSingleDigits(convertADtoBS(moment(bill.issueDate)).formatted)}{" "}
                {moment(bill.issueDate).format("h:mm a")}
              </>
            }
          />
          <InfoField
            label={tl("billing.dateAD")}
            data={
              <>: {dateWithZeroBeforeSingleDigits(moment(bill.issueDate).format("YYYY/MM/DD"))} </>
            }
          />
          {bill.document.referredBy && (
            <InfoField
              label={tl("booking.referredBy")}
              data={<>: {bill.document.referredBy.toUpperCase()}</>}
            />
          )}

          {bill.insuranceNumber && (
            <InfoField
              label={tl("billing.insuranceNumber")}
              data={<>: {bill.insuranceNumber} </>}
            />
          )}
          {bill.claimNumber && (
            <InfoField label={tl("billing.claimNumber")} data={<>: {bill.claimNumber} </>} />
          )}
        </Box>
      </Box>
      <Box p="0 0.5cm">
        <Box>
          <Box
            component="div"
            display="flex"
            borderTop="1px solid black"
            borderRight="1px solid black"
            borderBottom="1px solid black"
            borderLeft="1px solid black"
            paddingBottom="0.2cm"
            p="0.3cm"
            className="borderBotBlack1"
          >
            {billColumns.map((column, i) => (
              <Box
                component="div"
                key={column}
                className={classNames({ grow1: i === 0 || i === 2, flex3: i === 1, flex1: i > 2 })}
              >
                <Typography component="div">
                  <Box
                    textAlign={i > 2 ? "center" : "left"}
                    style={{ textTransform: "uppercase" }}
                    fontWeight={600}
                    fontSize="0.27cm"
                  >
                    {tl(`billing.billItem.${column}`)}
                  </Box>
                </Typography>
              </Box>
            ))}
          </Box>

          <Box component="div" paddingBottom="0.45cm">
            {bill.document.billItems.map((item, index) => (
              <Box
                component="div"
                key={item.sNo}
                display="flex"
                style={{ pageBreakInside: "avoid" }}
                borderTop="0.5px solid lightgrey"
                borderBottom={
                  index === bill.document.billItems.length - 1
                    ? "1px solid black"
                    : "0.5px solid lightgrey"
                }
                className={index === bill.document.billItems.length - 1 ? "borderBotBlack1" : ""}
              >
                {billColumns.map((column, i) => (
                  <Box
                    borderLeft={i === 0 ? "1px solid black" : ""}
                    borderRight={i === billColumns.length - 1 ? "1px solid black" : ""}
                    paddingLeft={i === 0 ? "0.3cm" : ""}
                    paddingRight={i === billColumns.length - 1 ? "0.3cm" : ""}
                    key={column}
                    className={classNames({
                      grow1: i === 0 || i === 2,
                      flex3: i === 1,
                      flex1: i > 2
                    })}
                    paddingTop="0.2cm"
                    paddingBottom="0.2cm"
                  >
                    <Typography component="div">
                      <Box
                        className="blockContent"
                        sx={{ display: column === "description" ? "inline" : "block" }}
                        fontWeight={["description", "grossTotal"].includes(column) ? 500 : 400}
                        fontSize="0.33cm"
                        textAlign={i > 2 ? "center" : "left"}
                      >
                        {["perUnit", "grossTotal"].includes(column) && item[column] !== null
                          ? Number(item[column]).toFixed(2)
                          : item[column]}{" "}
                        {showUnitAfterQuantity(column) && item.unit}
                        {column === "description" &&
                          item.serviceProviderId &&
                          `(${spFullNameSelector(spFromIdSelector(SPs, item.serviceProviderId))})`}
                      </Box>
                      {column === "description" && (
                        <Box sx={{ whiteSpace: "pre-wrap", display: "inline" }} fontSize="0.23cm">
                          {item.batchInfo?.batchId && `Batch ${item.batchInfo?.batchId}`}
                          {item.batchInfo?.expiryDate &&
                            `, Expiring ${moment(item.batchInfo.expiryDate).format("YYYY/MM/DD")}`}
                        </Box>
                      )}
                      {column === "description" &&
                        item?.subItems?.map((subitem) => (
                          <Box key={subitem.productId} paddingTop="0.05cm" className="blockContent">
                            {`◦ ${subitem.description}`}
                            {hasOwnProperty(subitem, "serviceProviderId") &&
                              subitem.serviceProviderId && (
                                <span style={{ marginLeft: "8px" }}>
                                  {`(${spFullNameSelector(
                                    spFromIdSelector(SPs, subitem.serviceProviderId)
                                  )})`}
                                </span>
                              )}
                          </Box>
                        ))}
                    </Typography>
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
        <Box component="div" display="flex" paddingTop="1cm" className="blockContent">
          <Box component="div" width="50%">
            {Boolean(bill.document.remarks) && (
              <Box marginBottom="0.3cm">
                <Typography variant="subtitle2" align="left" component="div" display="block">
                  <Box fontSize="0.3cm" style={{ textTransform: "uppercase" }}>
                    {tl("billing.remarks")}
                  </Box>
                </Typography>
                <Typography align="left" component="div">
                  <Box fontWeight={400} fontSize="0.33cm" marginRight="2rem" marginTop="0.1cm">
                    {bill.document.remarks}
                  </Box>
                </Typography>
              </Box>
            )}
            {Boolean(bill?.paymentInfo?.paymentMethod) && (
              <Box display="flex" gap={2} alignItems="center">
                <Box fontSize="0.3cm" style={{ textTransform: "uppercase" }}>
                  {tl("billing.paymentMethod")}
                </Box>
                <Box fontWeight={400} fontSize="0.33cm" marginRight="2rem">
                  {startCase(bill.paymentInfo?.paymentMethod)}
                </Box>
              </Box>
            )}
          </Box>
          <Box width="50%">
            <Box paddingBottom="0.6cm" width="100%" textAlign="right">
              <BillPrintSummary
                totalPerUnitTimesQty={bill.document.summary.totalPerUnitTimesQty}
                discount={bill.document.summary.discount}
                taxAmount={bill.document.summary.taxAmount}
                netTotal={netTotal}
              />
            </Box>
            <Box gap={2} alignItems="center" justifyContent="flex-end" display="flex">
              <Box fontSize="0.3cm" fontWeight={600} style={{ textTransform: "uppercase" }}>
                {tl("billing.grandTotal")}
              </Box>
              <Box fontWeight={500} fontSize="0.6cm">
                {rupeeDisplay(bill.document.summary.totalAmount.toFixed(2))}
              </Box>
            </Box>
            <Box component="div">
              <Typography align="right" component="div">
                <Box fontWeight={400} fontSize="0.3cm">
                  {bill.document.summary.inWords} only
                </Box>
              </Typography>
            </Box>

            {bill.paymentInfo?.paidAmount > 0 && (
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                justifyContent="flex-end"
                marginTop="0.2cm"
              >
                <Box fontSize="0.3cm" fontWeight={600} style={{ textTransform: "uppercase" }}>
                  {t("reports.paidAmount")}
                </Box>
                <Box fontWeight={500} fontSize="0.35cm">
                  {round(bill.paymentInfo?.paidAmount, 2).toFixed(2)}
                </Box>
              </Box>
            )}
            {!bill.paymentInfo?.paid && (
              <Box
                display="flex"
                alignItems="center"
                gap={2}
                justifyContent="flex-end"
                marginTop="0.2cm"
              >
                <Box fontSize="0.3cm" fontWeight={600} style={{ textTransform: "uppercase" }}>
                  {t("reports.dueAmount")}
                </Box>
                <Box fontWeight={500} fontSize="0.35cm">
                  {round(
                    chain(bill.summary?.totalAmount)
                      .subtract(bill.paymentInfo?.paidAmount || 0)
                      .done(),
                    2
                  ).toFixed(2)}
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        <Box
          display="flex"
          flexDirection="row"
          mt="32px"
          width="100%"
          justifyContent="space-between"
          alignItems="flex-end"
          className="blockContent"
        >
          <Box component="div" width="60%">
            <Box width="120px" component="div">
              <Typography align="left">
                <Box
                  component="span"
                  fontSize="0.3cm"
                  fontWeight={600}
                  style={{ textTransform: "uppercase" }}
                >
                  Authorized by
                </Box>
              </Typography>
            </Box>
            <Box component="div">
              {bill.document.enteredBy.signature && (
                <img
                  src={bill.document.enteredBy.signature}
                  alt="Service Provider Signature"
                  width="180px"
                  style={{ objectFit: "contain" }}
                />
              )}
              <Box component="div">
                <Typography>
                  <Box fontWeight="400" fontSize="0.33cm" component="span">
                    {capitalizedName(bill.document.enteredBy.name)}
                  </Box>
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box component="div" width="35%">
            <InfoField
              label={tl("billing.printedDateTime")}
              data={
                <>
                  {moment().format("YYYY/MM/DD")} {moment().format("h:mm a")}
                </>
              }
              labelStyle={{ fontSize: "0.3cm" }}
              valueStyle={{ fontSize: "0.3cm" }}
            />
          </Box>
        </Box>
      </Box>
      <Box
        component="div"
        paddingTop="0.5rem"
        display="flex"
        width="100%"
        className="billFooterPos borderTopBlack1 billFooter"
        style={{ ...getSectionStyle(headerColor).footerStyle }}
        flexDirection="row-reverse"
      >
        <Box>
          <img src={logo} alt="logo" height="35px" />
        </Box>
      </Box>
    </Box>
  );
};

export default BillDescription;
