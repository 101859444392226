import React from "react";
import { useSelector } from "react-redux";
import { Box, Button } from "@mui/material";
import { getLabTestsRecordByUUID } from "../../api/labTest";
import { RootState } from "../../store";
import useMobileScreen from "../../hooks/useMobileScreen";
import ForeignEmploymentPrint, { MedicalPrintView } from "./Print/Templates/ForeignEmploymentPrint";

interface MyMedicalReportProps {
  match: any;
}

export default function MyMedicalReport({
  match
}: MyMedicalReportProps): React.ReactElement | null {
  const printRef = React.useRef(null);
  const isMobileScreen = useMobileScreen();
  const [medicalTest, setMedicalTest] = React.useState<any>(null);

  const resourceCentre = useSelector((state: RootState) => state.userContext?.resourceCentre);

  React.useEffect(() => {
    (async () => {
      const report = await getLabTestsRecordByUUID(match.params.uuid);
      setMedicalTest(report);
    })();
  }, [match.params.uuid]);

  if (!medicalTest) return null;

  return (
    <Box sx={{ display: "flex", justifyContent: "center" }}>
      <Box
        sx={{
          width: isMobileScreen ? "100%" : "60%",
          display: "flex",
          flexDirection: "column"
        }}
      >
        <Button variant="contained" sx={{ marginY: "10px", alignSelf: "flex-end" }}>
          <ForeignEmploymentPrint
            buttonText="Print"
            labRecord={medicalTest}
            assessment={[medicalTest.results.assessment]}
          />
        </Button>

        <Box sx={{ border: "1px solid #b2beb5", padding: "10px", overflow: "auto" }}>
          <MedicalPrintView
            forwardRef={printRef}
            resourceCentre={resourceCentre}
            labRecord={medicalTest}
            assessment={[medicalTest.results.assessment]}
          />
        </Box>
      </Box>
    </Box>
  );
}
