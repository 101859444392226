import React from "react";
import { Tooltip, IconButton } from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import { t } from "../translate";

interface Props {
  onClick: () => void;
  dataTestmation?: string;
}

const SettingsButton = ({ onClick, dataTestmation = "settingsButton" }: Props): JSX.Element => (
  <Tooltip arrow title={`${t("settings")}`}>
    <IconButton onClick={onClick} data-testmation={dataTestmation}>
      <SettingsIcon color="action" />
    </IconButton>
  </Tooltip>
);

export default SettingsButton;
