import { Box, Grid2 as Grid, Typography } from "@mui/material";
import * as React from "react";
import classNames from "classnames";
import produce from "immer";
import chunk from "../../../helpers/array";
import styles from "../Assessment.module.css";
import Teeth, { dentalIconsMappings, InitialState, TeethTypes } from "./Tooth";

export interface Tooth {
  t: number;
  b: number;
  type: TeethTypes;
  row: "first" | "second";
  v: {
    base: { legendId: number; legendName: string };
    center: { legendId: number; legendName: string };
    l: { legendId: number; legendName: string };
    u: { legendId: number; legendName: string };
    d: { legendId: number; legendName: string };
    r: { legendId: number; legendName: string };
  };
}

const generateTooth = (t: number, b: number, type: TeethTypes): Omit<Tooth, "row"> => ({
  t,
  b,
  v: {
    base: { legendId: null, legendName: null },
    center: { legendId: null, legendName: null },
    l: { legendId: null, legendName: null },
    u: { legendId: null, legendName: null },
    d: { legendId: null, legendName: null },
    r: { legendId: null, legendName: null }
  },
  type
});

export const legendMapId = {
  1: { name: "Dental Caries", offset: { x: -5, y: -5 } },
  2: { name: "Secondary Caries", icon: "", offset: { x: -5, y: -5 } },
  3: { name: "Cervical Abasion", icon: "", offset: { x: -5, y: -5 } },
  4: { name: "Filled", icon: "", offset: { x: -5, y: -5 } },
  5: { name: "Pit and Fissure", icon: "", offset: { x: -5, y: -5 } },
  6: { name: "Crown", icon: "", offset: { x: -5, y: -5 } },
  7: { name: "Fracture", icon: "", offset: { x: -5, y: -5 } },
  8: { name: "Crack", icon: "", offset: { x: -5, y: -5 } },
  9: { name: "Mobile", icon: "", offset: { x: -5, y: -5 } },
  10: { name: "Root Stumps", icon: "", offset: { x: -2, y: -4 } },
  11: { name: "Tooth Missing", icon: "", offset: { x: -2, y: -5 } },
  12: { name: "Impacted Tooth", icon: "", offset: { x: -2, y: -5 } },
  13: { name: "Implant", icon: "", offset: { x: -2, y: -5 } },
  14: { name: "RCT", icon: "", offset: { x: -2, y: -5 } },
  15: { name: "Bridge", icon: "", offset: { x: -2, y: -5 } },
  16: { name: "Percussion", icon: "", offset: { x: -2, y: -5 } }
};

export const generateTeethFirstRow = (): Array<Tooth> =>
  [
    generateTooth(18, null, TeethTypes.Flat),
    generateTooth(17, null, TeethTypes.Flat),
    generateTooth(16, null, TeethTypes.Flat),
    generateTooth(15, 55, TeethTypes.Flat),
    generateTooth(14, 54, TeethTypes.Flat),
    generateTooth(13, 53, TeethTypes.Sharp),
    generateTooth(12, 52, TeethTypes.Sharp),
    generateTooth(11, 51, TeethTypes.Sharp),
    generateTooth(21, 61, TeethTypes.Sharp),
    generateTooth(22, 62, TeethTypes.Sharp),
    generateTooth(23, 63, TeethTypes.Sharp),
    generateTooth(24, 64, TeethTypes.Flat),
    generateTooth(25, 65, TeethTypes.Flat),
    generateTooth(26, null, TeethTypes.Flat),
    generateTooth(27, null, TeethTypes.Flat),
    generateTooth(28, null, TeethTypes.Flat)
  ].map((el) => ({ ...el, row: "first" }));

export const generateTeethSecondRow = (): Array<Tooth> =>
  [
    generateTooth(null, 48, TeethTypes.Flat),
    generateTooth(null, 47, TeethTypes.Flat),
    generateTooth(null, 46, TeethTypes.Flat),
    generateTooth(85, 45, TeethTypes.Flat),
    generateTooth(84, 44, TeethTypes.Flat),
    generateTooth(83, 43, TeethTypes.Sharp),
    generateTooth(82, 42, TeethTypes.Sharp),
    generateTooth(81, 41, TeethTypes.Sharp),
    generateTooth(71, 31, TeethTypes.Sharp),
    generateTooth(72, 32, TeethTypes.Sharp),
    generateTooth(73, 33, TeethTypes.Sharp),
    generateTooth(74, 34, TeethTypes.Flat),
    generateTooth(75, 35, TeethTypes.Flat),
    generateTooth(null, 36, TeethTypes.Flat),
    generateTooth(null, 37, TeethTypes.Flat),
    generateTooth(null, 38, TeethTypes.Flat)
  ].map((el) => ({ ...el, row: "second" }));

interface Props {
  teethData: Array<TeethData>;
  onChange: (v: Array<TeethData>) => void;
}

const getActiveItemFromStates = (
  firstRowState: Array<{ shapeState: InitialState; tooth: Tooth }>
): {
  part: keyof InitialState;
  item: { shapeState: InitialState; tooth: Tooth };
  index: number;
} => {
  const mergedState = [...firstRowState];
  const activeItemIndex = mergedState.findIndex((el) =>
    Object.keys(el.shapeState).some((k) => el.shapeState[k].isActive)
  );

  const activeItem = activeItemIndex === -1 ? null : mergedState[activeItemIndex];
  if (!activeItem) return { part: null, item: null, index: null };
  return {
    part: Object.keys(activeItem.shapeState).find(
      (key) => activeItem.shapeState[key].isActive
    ) as keyof InitialState,
    item: activeItem,
    index: activeItemIndex
  };
};

export interface TeethData {
  shapeState: InitialState;
  tooth: Tooth;
}

export default function TeethMarkerComponent(props: Props): JSX.Element {
  const { teethData, onChange } = props;

  const resetActiveRest = (shapeKey: keyof InitialState, toothIdx: number) => {
    const updatedState = produce(teethData, (teeth) => {
      teeth.forEach((tooth) => {
        Object.keys(tooth.shapeState).forEach((k) => {
          // eslint-disable-next-line no-param-reassign
          tooth.shapeState[k].isActive = false;
        });
      });
      // eslint-disable-next-line no-param-reassign
      teeth[toothIdx].shapeState[shapeKey].isActive = true;
    });
    onChange(updatedState);
  };

  const { part: activeItemPart, item, index: activeItemIndex } = getActiveItemFromStates(teethData);

  return (
    <Box>
      <Box>
        <Teeth
          teeth={teethData}
          setState={onChange}
          startIndex={0}
          endIndex={generateTeethFirstRow().length - 1}
          resetActiveRest={resetActiveRest}
          onLoad={() => ({})}
        />
        <Teeth
          teeth={teethData}
          setState={onChange}
          startIndex={generateTeethFirstRow().length}
          endIndex={teethData.length - 1}
          resetActiveRest={resetActiveRest}
          onLoad={() => ({})}
        />
      </Box>
      {/* eslint-disable-next-line @typescript-eslint/no-use-before-define */}
      <Legend
        item={item}
        updateActiveItem={(legendId) => {
          const updatedState = produce(teethData, (draft) => {
            draft[activeItemIndex].tooth.v[activeItemPart] = {
              legendId,
              legendName: legendMapId[legendId]
            };
            draft[activeItemIndex].shapeState[activeItemPart].isActive = false;
          });
          onChange(updatedState);
        }}
      />
    </Box>
  );
}

export function Legend({
  item,
  updateActiveItem,
  mini = false
}: {
  item: { shapeState: InitialState; tooth: Tooth };
  updateActiveItem: (v: number) => void;
  mini?: boolean;
}): JSX.Element {
  const chunks = chunk(Object.entries(legendMapId), 4);

  return (
    <Grid container mt="32px" spacing={4}>
      {chunks.map((chunkArr, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid size={{ xs: 12, sm: 3 }} key={i}>
          <Box width="100%" className={styles.legendContainer}>
            {chunkArr.map(([id, { name }]) => (
              <Box
                style={mini ? { height: "24px" } : {}}
                key={id}
                width="100%"
                className={classNames({
                  [styles.legendButton]: item,
                  [styles.legendButtonMuted]: !item
                })}
                onClick={() => {
                  if (item) {
                    updateActiveItem(Number(id));
                  }
                }}
                display="flex"
              >
                <img
                  src={dentalIconsMappings.find((el) => el.mappedId === Number(id))?.path}
                  alt="dentalIcon"
                />
                <Typography ml="16px">{name}</Typography>
              </Box>
            ))}
          </Box>
        </Grid>
      ))}
    </Grid>
  );
}

Legend.defaultProps = {
  mini: false
};
