import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import {
  Typography,
  Box,
  TextField,
  Autocomplete,
  Button,
  Grid2 as Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  Checkbox,
  FormControlLabel
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { push } from "connected-react-router";
import PhoneNumber from "awesome-phonenumber";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import produce from "immer";
import { resourceCentreActions, ResourceCentreActionsType } from "../../actions";
import { uploadProfileResourceCentre } from "../../actions/resourceCentre";
import { t, tl } from "../../components/translate";
import Dialog from "../../components/Dialog/Dialog";
import ImageCrop from "../../components/ImageCrop/ImageCrop";
import dummy from "../../../assets/images/dummyProfile.png";
import styles from "./style.module.css";
import { places } from "../../models/ResourceCentre";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import { User } from "../../interfaces/UserContext";
import { PermissionGroups, UserMode } from "../../interfaces/User";
import { IThunkDispatch, RootState } from "../../store";
import Panel from "../../components/Panel";
import StatefulButton from "../../components/StatefulButton/StatefulButton";
import FormTextField from "../../components/FormTextField/FormTextField";
import MaterialUiPhoneNumber from "../../components/PhoneNumber";
import { isNewPhoneNumber, isStartWith970 } from "../../helpers/phoneNumber";
import { isEmail, validate } from "../../helpers/validators";
import * as NotificationActions from "../../actions/notification";
import Theme from "./OtherSettings/Theme";
import useCan from "../../hooks/useCan";
import {
  assignRcBranch,
  deleteEverything,
  getResourceCentreBranches,
  unassignRcBranch
} from "../../api/resourceCentre";
import { notificationAdd } from "../../actions/notification";
import Can from "../Policy/Can";

interface DetailsProps {
  resourceCentre: ResourceCentre;
  actions: ResourceCentreActionsType;
  onUploadProfileResourceCentre: (e) => void;
  editMode: boolean;
  fetchResourceCenter: () => void;
  permissionGroup: string;
  user: User & { role: UserMode; permissionGroup: PermissionGroups };
}

export enum BranchTypes {
  KITCHEN_PHARMACY = "kitchenPharmacy"
}

// eslint-disable-next-line react/require-default-props
const RcDetailText = ({ name, value = "" }: { name: React.ReactNode; value?: string }) => (
  <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", pb: 2 }}>
    <Typography color="gray" width={150}>
      {name}
    </Typography>
    <Typography>{value}</Typography>
  </Box>
);

export const RcDetailSection = ({
  resourceCentre
}: {
  resourceCentre: Partial<ResourceCentre>;
}): React.JSX.Element => {
  const {
    name,
    address,
    city,
    email,
    ownerEmail,
    phone,
    web,
    about,
    registrationNumber,
    govRegNo,
    optionalPhone,
    mobileNumber,
    ownerMobileNumber,
    location,
    shortName
  } = resourceCentre;

  return (
    <>
      <RcDetailText name={tl("name")} value={name} />
      <RcDetailText name={tl("shortName")} value={shortName} />
      <RcDetailText name={tl("resourceCentre.city")} value={city} />
      <RcDetailText name={tl("geoLocation")} value={location?.join(", ") || ""} />
      <RcDetailText name={tl("resourceCentre.address")} value={address} />
      <RcDetailText name={tl("resourceCentre.email")} value={email} />
      <RcDetailText name={tl("resourceCentre.ownerEmail")} value={ownerEmail} />
      <RcDetailText name={tl("resourceCentre.phone")} value={phone} />
      <RcDetailText name={tl("mobileNumber")} value={mobileNumber} />
      <RcDetailText name={tl("ownerMobileNumber")} value={ownerMobileNumber} />
      <RcDetailText name={tl("resourceCentre.optionalPhone")} value={optionalPhone} />
      <RcDetailText name={tl("registrationNumber")} value={registrationNumber} />
      <RcDetailText name={tl("resourceCentre.panVat")} value={govRegNo} />
      <RcDetailText name={tl("resourceCentre.web")} value={web} />
      <RcDetailText name={tl("resourceCentre.about")} value={about} />
    </>
  );
};

const Details: React.FC<DetailsProps> = ({
  resourceCentre,
  editMode,
  actions,
  onUploadProfileResourceCentre,
  fetchResourceCenter,
  permissionGroup
}) => {
  const {
    name,
    id,
    address,
    city,
    email,
    ownerEmail,
    phone,
    phone2,
    web,
    about,
    slogan,
    registrationNumber,
    govRegNo,
    optionalPhone,
    mobileNumber,
    ownerMobileNumber,
    location,
    shortName,
    isKitchenPharmacy,
    settings: {
      lookAndFeel: { primaryColour }
    }
  } = resourceCentre;

  const dispatch = useDispatch();
  const navigateTo = (url: string) => dispatch(push(url));
  const [logo, setLogo] = React.useState(dummy);
  const [isDialogOpen, setIsDialogOpen] = React.useState(false);
  const [rcBranches, setRcBranches] = React.useState<Partial<ResourceCentre[]>>([]);
  const [branch, setBranch] = React.useState<ResourceCentre | null>(null);
  const [unassigningBranchInfo, setUnassigningBranchInfo] = React.useState({
    openModal: false,
    branchId: null
  });

  const [isKitchenPharmacyBranch, setIsKitchenPharmacyBranch] = React.useState<boolean>(false);

  const isSuperAdmin = useCan("deny", { superAdminAgentPass: true });
  const {
    handleSubmit,
    control,
    setValue,
    getValues,
    formState: { isSubmitting, errors }
  } = useForm<FieldValues>({
    defaultValues: {
      name,
      id,
      address,
      city,
      email,
      ownerEmail,
      phone,
      web,
      about,
      slogan,
      registrationNumber,
      govRegNo,
      optionalPhone,
      mobileNumber,
      ownerMobileNumber,
      location: (location || []).join(),
      shortName,
      primaryColour,
      isKitchenPharmacy,
      phone2
    },
    mode: "onChange"
  });
  const dialogToggle = () => {
    setIsDialogOpen(!isDialogOpen);
  };

  React.useEffect(() => {
    if (resourceCentre.resourceCentreLogo) {
      setLogo(resourceCentre.resourceCentreLogo.s3ResourceURL);
    } else {
      setLogo(dummy);
    }
  }, [resourceCentre, logo]);

  React.useEffect(() => {
    if (isSuperAdmin) {
      dispatch(resourceCentreActions.getResourceCentres());
    }
  }, [isSuperAdmin, dispatch]);

  React.useEffect(() => {
    const getSubBranches = async () => {
      try {
        const branchData = await getResourceCentreBranches(id);
        setRcBranches(branchData);
      } catch (err) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getTime(),
            message: err?.data?.message || "Failed fetching branches for resource centre",
            autoTimeout: true,
            variant: "error"
          })
        );
      }
    };
    if (id) {
      getSubBranches();
    }
  }, [id, dispatch]);

  const resourceCentres = useSelector((state: RootState) => state.resources.resourceCentres);

  const [rcOptions, setRcOptions] = React.useState<Array<ResourceCentre>>([]);
  React.useEffect(() => {
    setRcOptions(
      resourceCentres?.filter(
        (rc) => rc.id !== resourceCentre.id && !rc.branch?.headResourceCentreId
      ) || []
    );
  }, [resourceCentres, resourceCentre.id]);
  const isValidPhone = (value: string) => {
    const ph = new PhoneNumber(value);
    const phoneNumber = ph.getNumber("significant");
    if (ph.getCountryCode() === 977 && isStartWith970(value)) {
      return isNewPhoneNumber(phoneNumber);
    }
    return ph.isValid() && ph.isMobile();
  };
  const disabledEditing = !["superAdmin", "resourceCentreAdmin"].includes(permissionGroup);

  const [isCropperOpen, setIsCropperOpen] = React.useState(false);
  const [tempImg, setTempImg] = React.useState(null);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [isDestroyModalOpen, setIsDestroyModalOpen] = React.useState(false);

  const fileUploader = React.useRef<HTMLInputElement>();

  const handleLogoChange = () => {
    fileUploader.current.click();
  };

  async function handleImageChange(event) {
    event.preventDefault();
    if (event.target.files[0].size >= 2097152) {
      // eslint-disable-next-line no-param-reassign
      event.target.value = "";
      return dialogToggle();
    }
    const file = event.target.files[0];
    const someVar = file.name.lastIndexOf(".") + 1;
    const someVar2 = file.name.substr(someVar, file.name.length).toLowerCase();
    if (someVar2 === "jpg" || someVar2 === "jpeg" || someVar2 === "png" || someVar2 === "gif") {
      setTempImg(URL.createObjectURL(file));
      setIsCropperOpen(true);
    } else {
      // eslint-disable-next-line no-param-reassign
      event.target.value = "";
      dialogToggle();
    }
    return false;
  }

  const validateEmail = (value: string) => {
    const { isValid, messages } = validate(value, [isEmail({ msg: "Email address invalid" })]);
    return isValid || messages[0];
  };

  const onBranchAssign = async () => {
    try {
      if (!branch) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getTime(),
            message: "Please select a branch to assign.",
            autoTimeout: true,
            variant: "error"
          })
        );
        return;
      }
      await assignRcBranch({
        mainResourceCentreId: id,
        branchResourceCentreId: branch.id,
        isKitchenPharmacyBranch
      });
      setRcBranches([
        ...rcBranches,
        { ...branch, ...(isKitchenPharmacyBranch && { branchType: BranchTypes.KITCHEN_PHARMACY }) }
      ]);
      setRcOptions(() => rcOptions.filter((rc) => rc.id !== branch.id));
      setBranch(null);
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getTime(),
          message: "Branch Assigned Successfully",
          autoTimeout: true,
          variant: "success"
        })
      );
      if (isKitchenPharmacyBranch) {
        setIsKitchenPharmacyBranch(false);
      }
    } catch (err) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getTime(),
          message: err?.data?.message || "Failed to assign branch to resource centre",
          autoTimeout: true,
          variant: "error"
        })
      );
      setIsKitchenPharmacyBranch(false);
    }
  };

  const onBranchUnassign = async () => {
    try {
      if (unassigningBranchInfo.branchId && id) {
        await unassignRcBranch({
          mainResourceCentreId: id,
          resourceCentreId: unassigningBranchInfo.branchId
        });
      }
      const unassignedRc = rcBranches.find((rc) => rc.id === unassigningBranchInfo.branchId);
      if (unassignedRc) {
        setRcOptions(() => [...rcOptions, unassignedRc]);
      }
      const updatedBranches = produce(rcBranches, (draft) =>
        draft.filter((rc) => rc.id !== unassigningBranchInfo.branchId)
      );
      setRcBranches(updatedBranches);
      setUnassigningBranchInfo({
        openModal: false,
        branchId: null
      });
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getTime(),
          message: "Branch Unassigned Successfully",
          autoTimeout: true,
          variant: "success"
        })
      );
    } catch (err) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getTime(),
          message: err?.data?.message || "Failed to unassign branch from resource centre",
          autoTimeout: true,
          variant: "error"
        })
      );
    }
  };
  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    const updatedResourceCenter = produce(resourceCentre, (draft) => {
      draft.settings.lookAndFeel.primaryColour = data.primaryColour;
      draft.name = data.name;
      draft.id = data.id;
      draft.address = data.address;
      draft.city = data.city;
      draft.email = data.email;
      draft.ownerEmail = data.ownerEmail;
      draft.phone = data.phone;
      draft.web = data.web;
      draft.about = data.about;
      draft.slogan = data.slogan;
      draft.registrationNumber = data.registrationNumber;
      draft.govRegNo = data.govRegNo;
      draft.optionalPhone = data.optionalPhone;
      draft.mobileNumber = data.mobileNumber;
      draft.ownerMobileNumber = data.ownerMobileNumber;
      draft.location = data.location.split(",");
      draft.shortName = data.shortName;
      draft.isKitchenPharmacy = data.isKitchenPharmacy;
      draft.phone2 = data.phone2;
    });
    try {
      await actions.putResourceCentre(updatedResourceCenter);
      navigateTo(`/resourcecentres/${resourceCentre.id}/details`);
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Profile updated.",
          autoTimeout: true
        })
      );
    } catch (error) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: error.data?.message || "Sorry! Something went wrong.",
          autoTimeout: true,
          timeout: 10000
        })
      );
    }
  };

  const onDeleteRc = async () => {
    setIsDeleting(true);
    try {
      await deleteEverything({ id: resourceCentre.id });
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: "Resource centre deleted successfully.",
          autoTimeout: true,
          variant: "success"
        })
      );
      dispatch(resourceCentreActions.getResourceCentres());
    } catch (err) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: err?.data?.message || "Failed to delete resource centre",
          autoTimeout: true,
          variant: "error"
        })
      );
    }
    setIsDeleting(false);
    setIsDestroyModalOpen(false);
  };

  return (
    <Box width="100%">
      <Box px={1} data-testmation="editForm">
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          maxWidth="650px"
          mb={3}
        >
          <Box display="flex" gap={2} alignItems="center">
            <img className={styles.logo} src={logo} alt="profileimage" />
            <Box display="flex" flexDirection="column">
              <Box display="flex" gap={2} alignItems="center">
                <Typography variant="h6" fontWeight="600">
                  {resourceCentre.name}
                </Typography>
                {resourceCentre.isKitchenPharmacy && (
                  <Typography color="gray">(Central Store)</Typography>
                )}
              </Box>

              <Typography variant="body1" color="gray">
                {resourceCentre.slogan}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" gap={1}>
            {!disabledEditing && (
              <Button
                startIcon={<EditIcon />}
                onClick={() => navigateTo(`/resourcecentres/${resourceCentre.id}/details/edit`)}
                data-testmation="editButton"
              >
                {tl("account.edit")}
              </Button>
            )}
            <Can policyAccessKey="deny" superAdminPass>
              <Button onClick={() => setIsDestroyModalOpen(true)} color="error">
                {tl("delete")}
              </Button>
            </Can>
          </Box>
        </Box>
        <Dialog
          open={isDestroyModalOpen}
          title={`Are you sure you want to delete ${resourceCentre.name} ?`}
          description="This action will remove all the data of the selected resource centre permanently."
          next={onDeleteRc}
          cancel={() => {
            setIsDeleting(false);
            setIsDestroyModalOpen(false);
          }}
          readMode={false}
          nextButtonText="Yes"
          cancelButtonText="Cancel"
          isLoading={isDeleting}
          promptBeforeSave
          promptComparisonValue={resourceCentre.name}
        />
        <RcDetailText name={tl("shortName")} value={shortName} />
        <RcDetailText name={tl("resourceCentre.city")} value={city} />
        <RcDetailText name={tl("geoLocation")} value={location?.join(", ") || ""} />
        <RcDetailText name={tl("resourceCentre.address")} value={address} />
        <RcDetailText name={tl("resourceCentre.email")} value={email} />
        <RcDetailText name={tl("resourceCentre.ownerEmail")} value={ownerEmail} />
        <RcDetailText name={tl("resourceCentre.phone")} value={phone} />
        <RcDetailText name={tl("resourceCentre.phone2")} value={phone2} />
        <RcDetailText name={tl("mobileNumber")} value={mobileNumber} />
        <RcDetailText name={tl("ownerMobileNumber")} value={ownerMobileNumber} />
        <RcDetailText name={tl("resourceCentre.optionalPhone")} value={optionalPhone} />
        <RcDetailText name={tl("registrationNumber")} value={registrationNumber} />
        <RcDetailText name={tl("resourceCentre.panVat")} value={govRegNo} />
        <RcDetailText name={tl("resourceCentre.web")} value={web} />
        <RcDetailText name={tl("resourceCentre.about")} value={about} />
        <Box sx={{ display: "flex", gap: 2, flexWrap: "wrap", pb: 2 }}>
          <Typography color="gray" width={150}>
            {tl("themeColor")}
          </Typography>
          <Box
            sx={{
              backgroundColor: primaryColour,
              height: "24px",
              width: "24px",
              borderRadius: "50%"
            }}
          />
        </Box>
      </Box>

      <Box my={2}>
        {isSuperAdmin && !resourceCentre.branch?.headResourceCentreId && (
          <>
            <Typography variant="subtitle1" mb={2}>
              Organizations
            </Typography>
            <div>
              <Grid spacing={2} container>
                <Grid size={6}>
                  <Autocomplete
                    options={rcOptions}
                    freeSolo
                    getOptionLabel={(opt) => opt?.name || ""}
                    renderOption={(params, opt) => (
                      <Box
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        display="flex"
                        alignItems="center"
                        component="li"
                        key={opt.id}
                      >
                        <Typography>{opt?.name || ""}</Typography>
                        <Typography variant="caption" color="grey" ml={1}>
                          ({opt.id || ""})
                        </Typography>
                      </Box>
                    )}
                    onChange={(e, v) => {
                      if (v && typeof v !== "string") {
                        setBranch(v);
                      }
                    }}
                    value={branch || {}}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        variant="outlined"
                        label="Branches/Outlets"
                        placeholder="Branches/Outlets"
                        slotProps={{
                          inputLabel: { shrink: true }
                        }}
                      />
                    )}
                  />
                </Grid>
                {branch && (
                  <Grid size={4}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isKitchenPharmacyBranch}
                            onChange={({ target }) => setIsKitchenPharmacyBranch(target.checked)}
                          />
                        }
                        label="Central Store Outlet ?"
                      />
                    </FormGroup>
                  </Grid>
                )}
              </Grid>
              {isSuperAdmin && (
                <Button
                  sx={{
                    marginY: "10px"
                  }}
                  variant="contained"
                  onClick={onBranchAssign}
                  disabled={!branch || !id}
                >
                  Link Branch/Outlet
                </Button>
              )}
            </div>
          </>
        )}

        {rcBranches.length > 0 && (
          <Box mb={2}>
            <Typography variant="subtitle1" fontWeight="bold">
              Branches and Outlets
            </Typography>
            {rcBranches.map((rc) => (
              <Accordion key={rc.id}>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                  <Box display="flex" gap={2}>
                    <Typography>
                      {rc.name} [{rc.id}]
                    </Typography>
                    {rc.branchType === BranchTypes.KITCHEN_PHARMACY && (
                      <Typography fontWeight="bold">(Outlet)</Typography>
                    )}
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <RcDetailSection resourceCentre={rc} />
                  {isSuperAdmin && (
                    <Button
                      variant="contained"
                      onClick={() => {
                        setUnassigningBranchInfo({
                          openModal: true,
                          branchId: rc.id
                        });
                      }}
                    >
                      Unlink Branch/Outlet
                    </Button>
                  )}
                </AccordionDetails>
              </Accordion>
            ))}
          </Box>
        )}

        <Dialog
          open={unassigningBranchInfo.openModal}
          title="Warning"
          description="Are you sure you want to unassign user from the resource centre?"
          next={onBranchUnassign}
          cancel={() => {
            setUnassigningBranchInfo({
              openModal: false,
              branchId: null
            });
          }}
          readMode={false}
        />
      </Box>
      {editMode && !disabledEditing && (
        <>
          <Panel
            wrapperStyle={{ height: "100vh" }}
            onClose={() => navigateTo(`/resourcecentres/${resourceCentre.id}/details/`)}
            title={tl("account.edit")}
            footer={
              <Box display="flex" justifyContent="space-between" width="100%" marginRight="32px">
                <Box />
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Button
                    onClick={() => {
                      navigateTo(`/resourcecentres/${resourceCentre.id}/details`);
                    }}
                    style={{ marginRight: "32px" }}
                  >
                    <Typography>{tl("cancel")}</Typography>
                  </Button>
                  <StatefulButton
                    variant="contained"
                    color="primary"
                    onClick={() => {
                      handleSubmit(onSubmit);
                    }}
                    disabled={isSubmitting}
                    isLoading={isSubmitting}
                    circularProgressProps={{ size: 20 }}
                    type="submit"
                    form="updateProfile"
                  >
                    <Typography data-testmation="saveBtn">{tl("save")}</Typography>
                  </StatefulButton>
                </Box>
              </Box>
            }
          >
            <Box
              px={5}
              width="100%"
              height="calc(100vh - 100px)"
              overflow="auto"
              component="form"
              id="updateProfile"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  gap: 1,
                  mb: 3
                }}
              >
                <Box pt={2}>
                  <img
                    className={styles.logo}
                    height={64}
                    width={64}
                    src={logo}
                    alt="profileimage"
                  />
                </Box>
                <div>
                  <Typography
                    onClick={handleLogoChange}
                    color="primary"
                    sx={{ cursor: "pointer", ":hover": { textDecoration: "underline" } }}
                    variant="body2"
                  >
                    {tl("changePicture")}
                  </Typography>
                  <input
                    className="imageUpload"
                    type="file"
                    ref={fileUploader}
                    name="fileUploader"
                    style={{ display: "none" }}
                    onChange={handleImageChange}
                  />
                  <Box>
                    <ImageCrop
                      image={tempImg}
                      dynamicRatio
                      isOpen={isCropperOpen}
                      onClose={() => setIsCropperOpen(false)}
                      onSave={async (croppedImage) => {
                        const formData = new FormData();
                        formData.append("qqfile", croppedImage);
                        const rcObj = { id, formData };
                        await onUploadProfileResourceCentre(rcObj);
                        fetchResourceCenter();
                      }}
                    />
                    {isDialogOpen && (
                      <Dialog
                        title={tl("CantUploadImg")}
                        description={tl("UploadError")}
                        next={dialogToggle}
                        readMode
                      />
                    )}
                  </Box>
                </div>
              </Box>
              <Grid container columnSpacing={2} rowSpacing={1}>
                {permissionGroup === PermissionGroups.SuperAdmin && (
                  <Grid size={12}>
                    <FormTextField
                      dataTestmation="rcNameInput"
                      fullWidth
                      control={control}
                      name="name"
                      errors={errors}
                      label="Name"
                      placeholder="Name of clinic/hospital"
                      rules={{
                        required: "Name can't be empty"
                      }}
                    />
                  </Grid>
                )}

                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormTextField
                    fullWidth
                    control={control}
                    name="shortName"
                    errors={errors}
                    label={t("CompanyProfile.Form.ShortName")}
                    placeholder={t("CompanyProfile.Form.ShortNamePlaceHolder")}
                    rules={{
                      required: `${t("CompanyProfile.Form.ShortNameWarn")}`
                    }}
                  />
                </Grid>

                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormTextField
                    fullWidth
                    control={control}
                    name="address"
                    label={t("CompanyProfile.Form.Address")}
                    placeholder={t("CompanyProfile.Form.AddressPlaceholder")}
                    errors={errors}
                  />
                </Grid>

                {permissionGroup === PermissionGroups.SuperAdmin && (
                  <>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <Controller
                        name="city"
                        control={control}
                        rules={{ required: "City can't be empty" }}
                        render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                          <Autocomplete
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...rest}
                            options={places.map((item) =>
                              item.city === item.district
                                ? item.city
                                : `${item.city}, ${item.district}`
                            )}
                            getOptionLabel={(option) => option}
                            onChange={(_, value) => onChange(value)}
                            renderInput={(params) => (
                              <TextField
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                slotProps={{
                                  inputLabel: { shrink: true }
                                }}
                                placeholder="Select city name"
                                variant="outlined"
                                label="City"
                                margin="normal"
                                error={!!error}
                                helperText={error?.message || " "}
                                sx={{ my: 0 }}
                              />
                            )}
                          />
                        )}
                      />
                    </Grid>
                    <Grid size={{ xs: 12, sm: 6 }}>
                      <FormTextField
                        fullWidth
                        rules={{
                          required: false,
                          pattern: {
                            value:
                              // eslint-disable-next-line max-len
                              /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/,
                            message: "Coordinates not valid"
                          }
                        }}
                        control={control}
                        name="location"
                        label="Geo Location"
                        placeholder="ex: '27.43, 85.00'"
                        errors={errors}
                      />
                    </Grid>
                  </>
                )}

                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormTextField
                    fullWidth
                    control={control}
                    name="ownerEmail"
                    errors={errors}
                    label={t("CompanyProfile.Form.Owner'sEmail")}
                    placeholder={t("CompanyProfile.Form.Owner's Email")}
                    rules={{
                      required: `${t("CompanyProfile.Form.EmaiWarn")}`,
                      validate: (value) => validateEmail(value)
                    }}
                  />
                </Grid>

                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormTextField
                    fullWidth
                    dataTestmation="emailField"
                    control={control}
                    name="email"
                    errors={errors}
                    label={t("CompanyProfile.Form.EntitysEmail")}
                    placeholder={t("CompanyProfile.Form.EntityEmailPlaceholder")}
                    rules={{
                      required: `${t("CompanyProfile.Form.EmaiWarn")}`,
                      validate: (value) => validateEmail(value)
                    }}
                  />
                </Grid>

                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormTextField
                    fullWidth
                    dataTestmation="numberField"
                    control={control}
                    name="phone"
                    errors={errors}
                    label={t("CompanyProfile.Form.LandlineNumber")}
                    placeholder="Eg: 01-1324511, 057-523256"
                    rules={{
                      required: false,
                      pattern: {
                        value: /^(0[0-9]{1,2}-[0-9]{6,7})$/,
                        message: "Please enter a valid landline number"
                      }
                    }}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <FormTextField
                    fullWidth
                    dataTestmation="numberField"
                    control={control}
                    name="phone2"
                    errors={errors}
                    label={t("CompanyProfile.Form.LandlineNumber2")}
                    placeholder="Eg: 01-1324511, 057-523256"
                    rules={{
                      required: false,
                      pattern: {
                        value: /^(0[0-9]{1,2}-[0-9]{6,7})$/,
                        message: "Please enter a valid landline number"
                      }
                    }}
                  />
                </Grid>

                <Grid size={{ xs: 12, sm: 6 }}>
                  <Controller
                    name="mobileNumber"
                    control={control}
                    rules={{
                      required: false,
                      validate: (value) =>
                        (value ? isValidPhone(value) : true) || "Please enter a valid phone number"
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <MaterialUiPhoneNumber
                        onChange={onChange}
                        value={value}
                        onBlur={onBlur}
                        fullWidth
                        disableAreaCodes
                        variant="outlined"
                        defaultCountry="np"
                        errorMessages={errors?.mobileNumber}
                        error={!!errors?.mobileNumber}
                        label={t("CompanyProfile.Form.MobileNumber")}
                        helperText={errors?.mobileNumber?.message || " "}
                      />
                    )}
                  />
                </Grid>

                <Grid size={{ xs: 12, sm: 6 }}>
                  <Controller
                    name="ownerMobileNumber"
                    control={control}
                    rules={{
                      required: `${t("CompanyProfile.Form.OwnerMobNoWarn")}`,
                      validate: (value) =>
                        (value ? isValidPhone(value) : true) || "Please enter a valid phone number"
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <MaterialUiPhoneNumber
                        onChange={onChange}
                        value={value}
                        onBlur={onBlur}
                        fullWidth
                        disableAreaCodes
                        variant="outlined"
                        defaultCountry="np"
                        errorMessages={errors?.ownerMobileNumber}
                        error={!!errors?.ownerMobileNumber}
                        label={t("CompanyProfile.Form.OwnerMobNo")}
                        helperText={errors?.ownerMobileNumber?.message || " "}
                      />
                    )}
                  />
                </Grid>

                <Grid size={{ xs: 12, sm: 6 }}>
                  <Controller
                    name="optionalPhone"
                    control={control}
                    rules={{
                      validate: (value) =>
                        (value ? isValidPhone(value) : true) || "Please enter a valid phone number"
                    }}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <MaterialUiPhoneNumber
                        onChange={onChange}
                        value={value}
                        onBlur={onBlur}
                        fullWidth
                        disableAreaCodes
                        variant="outlined"
                        defaultCountry="np"
                        errorMessages={errors?.optionalPhone}
                        error={!!errors?.optionalPhone}
                        label={t("CompanyProfile.Form.OptPhNo")}
                        helperText={errors?.optionalPhone?.message || " "}
                      />
                    )}
                  />
                </Grid>

                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormTextField
                    fullWidth
                    control={control}
                    name="registrationNumber"
                    label={t("CompanyProfile.Form.RegNo")}
                    placeholder={t("CompanyProfile.Form.RegNoPlaceholder")}
                    errors={errors}
                  />
                </Grid>

                <Grid size={{ xs: 12, sm: 6 }}>
                  <FormTextField
                    fullWidth
                    control={control}
                    name="govRegNo"
                    errors={errors}
                    label={t("CompanyProfile.Form.PanVatNo")}
                    placeholder={t("CompanyProfile.Form.PanVatNoPlaceholder")}
                  />
                </Grid>

                <Grid size={12}>
                  <FormTextField
                    fullWidth
                    control={control}
                    name="slogan"
                    label={t("CompanyProfile.Form.Slogan")}
                    placeholder={t("CompanyProfile.Form.SloganPlaceholder")}
                    errors={errors}
                  />
                </Grid>

                <Grid size={12}>
                  <FormTextField
                    fullWidth
                    control={control}
                    name="web"
                    label={t("CompanyProfile.Form.Website")}
                    placeholder="Eg: okhati.com.np"
                    errors={errors}
                  />
                </Grid>

                <Grid size={12}>
                  <FormTextField
                    fullWidth
                    control={control}
                    name="about"
                    label={t("CompanyProfile.Form.About")}
                    placeholder={t("CompanyProfile.Form.AboutPlaceholder")}
                    errors={errors}
                  />
                </Grid>
                <Grid size={12}>
                  <Theme setValue={setValue} getValues={getValues} />
                </Grid>
              </Grid>
              {(isSuperAdmin || isKitchenPharmacy) && (
                <Controller
                  name="isKitchenPharmacy"
                  control={control}
                  defaultValue={isKitchenPharmacy}
                  render={({ field }) => (
                    <FormControlLabel
                      label="Is Central Store"
                      control={
                        <Checkbox
                          data-testmation="isKitchenPharmacy"
                          size="small"
                          checked={field.value}
                          disabled={!isSuperAdmin}
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                        />
                      }
                    />
                  )}
                />
              )}
            </Box>
          </Panel>
        </>
      )}
    </Box>
  );
};

export default connect(
  (state: RootState) => ({
    permissionGroup: state.userContext?.userCreds?.userGroups[0]
  }),
  (dispatch: IThunkDispatch) => ({
    actions: bindActionCreators({ ...resourceCentreActions }, dispatch),
    onUploadProfileResourceCentre: async (rcObj) => {
      await dispatch(uploadProfileResourceCentre(rcObj));
    }
  })
)(Details);
