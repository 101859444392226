import { TextField } from "@mui/material";
import * as React from "react";
import { StockItemInterface, TransactionType } from "../../../../interfaces/StockInterfaces";

interface Props {
  item: StockItemInterface;
  onVatUpdate: (v) => void;
  transactionType: TransactionType;
}

export default function StockVat(props: Props): JSX.Element {
  const { item, onVatUpdate, transactionType } = props;

  return (
    <div>
      <TextField
        value={item.vatPct}
        margin="none"
        label="Vat%"
        disabled={transactionType === TransactionType.ADJUSTMENT}
        onFocus={(e) => e.target.select()}
        fullWidth
        type="number"
        variant="outlined"
        slotProps={{
          input: {
            endAdornment: "%"
          },
          inputLabel: { shrink: true }
        }}
        onChange={(e) => {
          onVatUpdate(e.target.value);
        }}
      />
    </div>
  );
}
