import React from "react";
import { Box, Typography } from "@mui/material";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";

interface LabPrintLetterHeadProps {
  resourceCentre: Partial<ResourceCentre>;
  hideRcLogo?: boolean;
}

const BankPaperLetterHeadCentered: React.FC<LabPrintLetterHeadProps> = ({
  resourceCentre: {
    resourceCentreLogo,
    name,
    slogan,
    address,
    city,
    registrationNumber,
    phone,
    phone2,
    mobileNumber,
    optionalPhone,
    web,
    email,
    panNo
  },
  hideRcLogo = false
}) => (
  <Box display="flex" justifyContent="space-between">
    <Box style={{ width: "3.2cm", height: "auto" }}>
      {!hideRcLogo && resourceCentreLogo && (
        <Box>
          <img
            src={resourceCentreLogo.s3ResourceURL}
            alt="logo"
            style={{ width: "2.6cm", height: "auto" }}
          />
        </Box>
      )}
    </Box>
    <Box flex={1} alignItems="center">
      <Typography
        style={{
          fontWeight: 500,
          fontSize: "0.4cm",
          textAlign: "center"
        }}
      >
        {name}
      </Typography>
      {slogan && (
        <Typography style={{ fontSize: "0.3cm", fontWeight: 400, textAlign: "center" }}>
          {slogan}
        </Typography>
      )}
      <Typography style={{ textAlign: "center", textTransform: "capitalize", fontSize: "0.3cm" }}>
        {address} {city}
      </Typography>
      <Typography style={{ textAlign: "center", fontSize: "0.3cm" }}>
        {mobileNumber ? `${mobileNumber}, ` : ""}
        {phone}
        {optionalPhone && `, ${optionalPhone}`} {phone2 && `, ${phone2}`} {email && `, ${email}`}{" "}
        {web && `, ${web}`}
      </Typography>
    </Box>
    <Box style={{ width: "3.2cm", height: "auto" }}>
      {registrationNumber && (
        <Typography style={{ textAlign: "right", textTransform: "capitalize", fontSize: "0.3cm" }}>
          Reg No: {registrationNumber}
        </Typography>
      )}
      {Boolean(panNo) && (
        <Typography style={{ textAlign: "right", fontSize: "0.3cm" }}>Pan No: {panNo}</Typography>
      )}
    </Box>
  </Box>
);

BankPaperLetterHeadCentered.defaultProps = {
  hideRcLogo: false
};

export default BankPaperLetterHeadCentered;
