import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  FormControlLabel,
  Checkbox,
  TextField,
  Paper,
  Tab,
  Grid2 as Grid
} from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import produce from "immer";
import makeStyles from "@mui/styles/makeStyles";
import { resourceCentreActions, ResourceCentreActionsType } from "../../../actions";
import DebouncedTextField from "../../../components/DebouncedTextField";
import { t, tl } from "../../../components/translate";
import Can from "../../Policy/Can";
import Department from "../../Department";
import { vitals } from "../../Assessment/Vitals/VitalRow";
import SectionHeading from "../SectionHeading";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { ReverseMap } from "../../../helpers/types";
import { RootState } from "../../../store";
import SmsSettings from "./SmsSettings";
import EmailSettings from "./EmailSettings";
import ProductCategoryAddition from "../ProductCategoryAddition";
import HtmlTooltip from "../../../components/HtmlTooltip";
import SurveyFormSettings from "./SurveyFormSettings";
import Ssf from "./Ssf";
import { PermissionGroups } from "../../../interfaces/User";

const useStyles = makeStyles(() => ({
  paperRoot: {
    width: "200px",
    height: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingLeft: "8px",
    paddingRight: "8px"
  },
  colorOption: {
    height: "32px",
    width: "32px",
    borderRadius: "50%"
  }
}));

enum TAB_ITEMS {
  GENERAL_PRINT = "General Print",
  DEPARTMENT = "Departments",
  SURVEY_FORM = "Survey Form",
  VITALS = "Vitals",
  SMS_EMAIL = "SMS / Email",
  FORM = "Form",
  SERVICE_TYPE = "Service Type",
  SSF = "SSF"
}

type TabValues = ReverseMap<typeof TAB_ITEMS>;

const DEFAULT_SETTINGS = {
  settings: {
    lookAndFeel: {
      primaryColour: "",
      textColour: ""
    },
    smsSettings: {
      remindClient: false,
      nextDaySummarySP: false,
      assessment: false,
      creation: false,
      confirmation: false,
      cancellation: false,
      serviceProviders: false,
      labTests: false,
      labTestsDispatched: false,
      campaign: false,
      referrer: false,
      salesSummary: false,
      bookingConfirmationSP: false,
      publicBooking: false,
      billReminder: false
    },
    emailSettings: {
      remindClient: false,
      nextDaySummarySP: false,
      assessment: false,
      creation: false,
      confirmation: false,
      cancellation: false,
      serviceProviders: false,
      labTests: false,
      labTestsDispatched: false,
      campaign: false,
      referrer: false,
      salesSummary: false,
      bookingConfirmationSP: false,
      publicBooking: false,
      billReminder: false
    }
  }
};

export type SettingsType = typeof DEFAULT_SETTINGS;

export interface OtherSettingsProps {
  resourceCentre: ResourceCentre;
  actions: ResourceCentreActionsType;
  serviceProviders: ServiceProvider[];
}

const OtherSettings = ({ resourceCentre, actions }: OtherSettingsProps) => {
  const [tab, setTab] = React.useState(TAB_ITEMS.GENERAL_PRINT);

  const [rcSettings, setRcSettings] = React.useState<SettingsType>(DEFAULT_SETTINGS);

  const { primaryColour, textColour } = resourceCentre.settings.lookAndFeel;

  const {
    remindClient: remindClientSMS,
    nextDaySummarySP: nextDaySummarySPSMS,
    assessment: assessmentSms,
    creation: creationSms,
    confirmation: confirmationSms,
    cancellation: cancellationSms,
    serviceProviders: serviceProvidersSms,
    labTests: labTestsSms,
    labTestsDispatched: labTestsDispatchedSms,
    campaign,
    referrer: referrerSms,
    salesSummary: salesSummarySms,
    bookingConfirmationSP: bookingConfirmationSPSMS,
    publicBooking: publicBookingSms,
    billReminder: billReminderSms
  } = resourceCentre.settings.smsSettings;

  const {
    remindClient: remindClientEmail,
    nextDaySummarySP: nextDaySummarySPEmail,
    assessment: assessmentEmail,
    creation: creationEmail,
    confirmation: confirmationEmail,
    cancellation: cancellationEmail,
    serviceProviders: serviceProvidersEmail,
    labTests: labTestsEmail,
    labTestsDispatched: labTestsDispatchedEmail,
    campaign: campaignEmail,
    referrer: referrerEmail,
    salesSummary: salesSummaryEmail,
    bookingConfirmationSP: bookingConfirmationSPEmail,
    publicBooking: publicBookingEmail,
    billReminder: billReminderEmail
  } = resourceCentre.settings.emailSettings;

  const handleChange = (newValue: TabValues) => {
    setTab(newValue);
  };

  const departmentSubscription =
    useSelector(
      (state: RootState) =>
        state.userContext.resourceCentre?.subscriptions.features.department?.subscribed
    ) || false;

  const [colorCode, setColorCode] = useState("#009654");
  useEffect(() => {
    setColorCode(resourceCentre.settings.printSettings.labPrintSettings.color);
  }, [resourceCentre.settings.printSettings.labPrintSettings.color]);
  const classes = useStyles();
  React.useEffect(() => {
    setRcSettings({
      ...resourceCentre,
      settings: {
        ...resourceCentre.settings,
        lookAndFeel: {
          primaryColour,
          textColour
        },
        smsSettings: {
          remindClient: remindClientSMS,
          nextDaySummarySP: nextDaySummarySPSMS,
          assessment: assessmentSms,
          creation: creationSms,
          confirmation: confirmationSms,
          cancellation: cancellationSms,
          serviceProviders: serviceProvidersSms,
          labTests: labTestsSms,
          labTestsDispatched: labTestsDispatchedSms,
          campaign,
          referrer: referrerSms,
          salesSummary: salesSummarySms,
          bookingConfirmationSP: bookingConfirmationSPSMS,
          publicBooking: publicBookingSms || false,
          billReminder: billReminderSms || false
        },
        emailSettings: {
          remindClient: remindClientEmail,
          nextDaySummarySP: nextDaySummarySPEmail,
          assessment: assessmentEmail,
          creation: creationEmail,
          confirmation: confirmationEmail,
          cancellation: cancellationEmail,
          serviceProviders: serviceProvidersEmail,
          labTests: labTestsEmail,
          labTestsDispatched: labTestsDispatchedEmail,
          campaign: campaignEmail,
          referrer: referrerEmail,
          salesSummary: salesSummaryEmail,
          bookingConfirmationSP: bookingConfirmationSPEmail,
          publicBooking: publicBookingEmail || false,
          billReminder: billReminderEmail || false
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceCentre]);
  const userGroup = useSelector((state) => state.userContext?.userCreds?.userGroups[0]);

  const isRcAdmin = [PermissionGroups.ResourceCentreAdmin, PermissionGroups.CmsAdmin].includes(
    userGroup
  );

  return (
    <div>
      <SectionHeading name={t("OtherSettings")} />
      <Box
        sx={(theme) => ({
          width: "100%",
          mb: 2,
          borderBottom: `1px solid ${theme.palette.divider}`
        })}
      >
        <Tabs
          value={tab}
          onChange={(_, newValue) => handleChange(newValue)}
          aria-label="other settings tab"
          variant="scrollable"
          scrollButtons="auto"
          allowScrollButtonsMobile
          sx={{
            width: { xs: "100%" },
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 }
            }
          }}
        >
          <Tab value={TAB_ITEMS.GENERAL_PRINT} label={tl("TAB_ITEMS.GENERAL_PRINT")} />
          {departmentSubscription && (
            <Tab value={TAB_ITEMS.DEPARTMENT} label={tl("TAB_ITEMS.DEPARTMENT")} />
          )}
          <Tab value={TAB_ITEMS.SERVICE_TYPE} label={tl("TAB_ITEMS.SERVICE_TYPE")} />
          <Tab value={TAB_ITEMS.VITALS} label={tl("TAB_ITEMS.VITALS")} />
          <Tab value={TAB_ITEMS.SMS_EMAIL} label={tl("TAB_ITEMS.SMS_EMAIL")} />
          <Tab value={TAB_ITEMS.FORM} label={tl("TAB_ITEMS.FORM")} />
          <Tab value={TAB_ITEMS.SURVEY_FORM} label="Survey form" />
          {isRcAdmin && <Tab value={TAB_ITEMS.SSF} label={TAB_ITEMS.SSF} />}
        </Tabs>
      </Box>
      {tab === TAB_ITEMS.GENERAL_PRINT && (
        <Box sx={{ marginTop: "15px" }}>
          <Typography sx={{ fontWeight: 600 }}>{tl("settings.general")}</Typography>
          <Box sx={{ pl: "16px" }}>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={resourceCentre.settings.printSettings.centralizedHeader}
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.centralizedHeader =
                          !resourceCentre.settings.printSettings.centralizedHeader;
                      });
                      actions.putResourceCentre(newRc);
                    }}
                  />
                }
                label={tl("settings.centralizedHeader")}
              />
            </Box>
            <Box sx={{ alignItems: "center" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    size="small"
                    checked={resourceCentre.settings.printSettings.labPrintSettings.enableColor}
                    onChange={() => {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.labPrintSettings.enableColor =
                          !resourceCentre.settings.printSettings.labPrintSettings.enableColor;
                      });
                      actions.putResourceCentre(newRc);
                    }}
                  />
                }
                label={tl("clinicSettings.enableLetterHeadColor")}
              />
              {resourceCentre.settings.printSettings.labPrintSettings.enableColor && (
                <Box sx={{ ml: "24px", mb: "16px" }}>
                  <Paper elevation={6} className={classes.paperRoot}>
                    <TextField
                      margin="dense"
                      label="Hex value"
                      onChange={(e) => {
                        setColorCode(e.target.value);
                        if (/^#[0-9A-F]{6}$/i.test(e.target.value)) {
                          const newRc = produce(resourceCentre, (draft) => {
                            draft.settings.printSettings.labPrintSettings.color = e.target.value;
                          });
                          actions.putResourceCentre(newRc);
                        } else if (!e.target.value) {
                          const newRc = produce(resourceCentre, (draft) => {
                            draft.settings.printSettings.labPrintSettings.color = "";
                          });
                          actions.putResourceCentre(newRc);
                        }
                      }}
                      value={colorCode}
                      variant="outlined"
                      sx={{ width: "120px" }}
                    />

                    <Box
                      bgcolor={resourceCentre.settings.printSettings.labPrintSettings.color}
                      className={classes.colorOption}
                    />
                  </Paper>
                </Box>
              )}

              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography>{tl("ClinicNameFontScale")} (0.5 - 2)</Typography>
                <DebouncedTextField
                  sx={{ marginLeft: "16px", width: "28px" }}
                  value={resourceCentre.settings.printSettings.clinicNameFontScale}
                  debounceAt={1000}
                  onChange={(e) => {
                    if (Number(e.target.value) >= 0.5 && Number(e.target.value) <= 2) {
                      const newRc = produce(resourceCentre, (draft) => {
                        draft.settings.printSettings.clinicNameFontScale = Number(e.target.value);
                      });
                      actions.putResourceCentre(newRc);
                    }
                  }}
                  type="number"
                />
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      {tab === TAB_ITEMS.DEPARTMENT && (
        <Can policyAccessKey="allow" superAdminPassReverse>
          <Department />
        </Can>
      )}
      {tab === TAB_ITEMS.VITALS && (
        <Grid container spacing={2}>
          {vitals.map((vital) => {
            const vitalKey = vital.dbKey;
            return (
              <Grid key={vitalKey} size={{ xs: 12, sm: 6, md: 4 }}>
                <FormControlLabel
                  control={<Checkbox size="small" />}
                  label={vital.label}
                  checked={resourceCentre.settings.vitalSettings[vitalKey]}
                  onChange={() => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.settings.vitalSettings[vitalKey] =
                        !resourceCentre.settings.vitalSettings[vitalKey];
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
      {tab === TAB_ITEMS.SMS_EMAIL && (
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, md: 6 }}>
            <SmsSettings rcSettings={rcSettings} setRcSettings={setRcSettings} actions={actions} />
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <EmailSettings
              rcSettings={rcSettings}
              setRcSettings={setRcSettings}
              actions={actions}
            />
          </Grid>
        </Grid>
      )}
      {tab === TAB_ITEMS.FORM && (
        <>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={resourceCentre.settings.formSettings.requireEmail}
                  onChange={() => {
                    const newRc = produce(resourceCentre, (draft) => {
                      draft.settings.formSettings.requireEmail =
                        !resourceCentre.settings.formSettings.requireEmail;
                    });
                    actions.putResourceCentre(newRc);
                  }}
                />
              }
              label={tl("requireEmail")}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={resourceCentre.settings.formSettings.requireKnownUsFrom}
                  onChange={() => {
                    actions.putResourceCentre(
                      produce(resourceCentre, (draft) => {
                        draft.settings.formSettings.requireKnownUsFrom =
                          !resourceCentre.settings.formSettings.requireKnownUsFrom;
                      })
                    );
                  }}
                />
              }
              label={tl("requireKnownUsFrom")}
            />
          </Box>
          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={resourceCentre.settings.formSettings.requireReferrer}
                  onChange={() => {
                    actions.putResourceCentre(
                      produce(resourceCentre, (draft) => {
                        draft.settings.formSettings.requireReferrer =
                          !resourceCentre.settings.formSettings.requireReferrer;
                      })
                    );
                  }}
                />
              }
              label={tl("requirereferrer")}
            />
            <HtmlTooltip description="Referrer will be required when creating lab, booking and bills." />
          </Box>

          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={resourceCentre.settings.formSettings.requirePhoneNumber}
                  onChange={() => {
                    actions.putResourceCentre(
                      produce(resourceCentre, (draft) => {
                        draft.settings.formSettings.requirePhoneNumber =
                          !resourceCentre.settings.formSettings.requirePhoneNumber;
                      })
                    );
                  }}
                />
              }
              label={tl("requirePhNo.")}
            />
          </Box>

          <Box display="flex" alignItems="center">
            <FormControlLabel
              control={
                <Checkbox
                  size="small"
                  checked={resourceCentre.settings.formSettings.allowClientDuplication}
                  onChange={() => {
                    actions.putResourceCentre(
                      produce(resourceCentre, (draft) => {
                        draft.settings.formSettings.allowClientDuplication =
                          !resourceCentre.settings.formSettings.allowClientDuplication;
                      })
                    );
                  }}
                />
              }
              label={tl("AllowClientDuplication")}
            />
          </Box>
        </>
      )}
      {tab === TAB_ITEMS.SERVICE_TYPE && (
        <ProductCategoryAddition isServiceCreation resourceCentre={resourceCentre} />
      )}
      {tab === TAB_ITEMS.SURVEY_FORM && <SurveyFormSettings resourceCentre={resourceCentre} />}
      {tab === TAB_ITEMS.SSF && <Ssf resourceCentre={resourceCentre} />}
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  serviceProviders: state.resources.resourceCentreServiceProviders.filter(
    (sp) => sp.active === true
  )
});

export default connect(mapStateToProps, (dispatch) => ({
  actions: bindActionCreators({ ...resourceCentreActions }, dispatch)
}))(OtherSettings);
