import React, { JSX, useState } from "react";
import produce from "immer";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Typography } from "@mui/material";
import { Vaccination } from "../../../interfaces/AssessmentInterfaces";
import { adShortDate } from "../../../components/Calendar/functions/calendarFunctions";
import { tl } from "../../../components/translate";
import AddUpdateMedicalVaccination, { VaccinationMode } from "./AddUpdateMedicalVaccination";
import { RootState } from "../../../store";
import { LabStatuses } from "../../../interfaces/Lab";
import { patchMedicalTest } from "../../../actions/medicalTest";

interface PropTypes {
  medicalTestId: number;
  vaccinations: Vaccination[] | [];
  nextAction: () => void;
}

export default function ShowVaccinations({
  medicalTestId,
  vaccinations,
  nextAction
}: PropTypes): JSX.Element {
  const dispatch = useDispatch();
  const medicalTest = useSelector((state: RootState) =>
    state.medicalTest.collection.find((item) => item.id === Number(medicalTestId))
  );
  const rc = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres.find((r) => r.id === state.userContext.resourceCentreId) ||
      state.userContext.resourceCentre
  );

  const authorizedSP = rc?.settings?.medicalSettings;

  const [newVaccinations, setNewVaccinations] = useState<Vaccination[]>(
    vaccinations ?? [
      {
        name: "",
        yesno: true,
        date: new Date()
      }
    ]
  );
  const [showUpdateVitals, setShowUpdateVitals] = React.useState(!vaccinations);

  return (
    <Box sx={{ width: "100%", display: "flex", flexDirection: "column", gap: "10px" }}>
      {!showUpdateVitals && (
        <Button
          sx={{ marginLeft: "auto" }}
          onClick={() => {
            setShowUpdateVitals(true);
          }}
        >
          {tl("medical.updateVaccinations")}
        </Button>
      )}

      {showUpdateVitals && (
        <Box sx={{ width: "100%" }}>
          <AddUpdateMedicalVaccination
            vaccinations={newVaccinations}
            onChange={setNewVaccinations}
            mode={VaccinationMode.UPDATE}
            onVaccinationUpdate={async (updatedVaccination) => {
              const results = produce(medicalTest.results, (draft) => {
                draft.vaccinations = updatedVaccination;
              });
              const toPost = {
                attachments: [],
                authorizedSP1Id: authorizedSP.authorizedSP1,
                authorizedSP2Id: authorizedSP.authorizedSP2,
                authorizedSP3Id: authorizedSP.authorizedSP3,
                authorizedSP4Id: authorizedSP.authorizedSP4,
                final: medicalTest.status !== LabStatuses.INCOMPLETE_RESULT,
                sendToClient: false,
                updatedTestResults: results
              };
              await dispatch(patchMedicalTest(toPost, medicalTestId));

              // state change
              setNewVaccinations(updatedVaccination);
              setShowUpdateVitals(false);
              nextAction();
            }}
          />
        </Box>
      )}

      <Box sx={{ marginTop: "16px" }}>
        {vaccinations && (
          <>
            {vaccinations.map((item, i) => (
              <Typography key={item.name}>
                {i + 1}. {item.name}: {adShortDate(item.date)}
              </Typography>
            ))}
          </>
        )}
      </Box>
    </Box>
  );
}
