import React, { JSX } from "react";
import startCase from "lodash/startCase";
import CloseIcon from "@mui/icons-material/Close";
import { Box, TextField, Autocomplete, ButtonGroup, Button, IconButton } from "@mui/material";
import { t } from "../../../components/translate";

interface IVaccination {
  name: string;
  label: string;
  yesno: boolean;
  date: string;
}

export const vaccinations = [
  {
    name: "Polio",
    label: "Polio"
  },
  {
    name: "COVID-19",
    label: "COVID-19"
  }
];

interface PropTypes {
  vaccination: IVaccination;
  onChange: (state) => void;
  onRemove: () => void;
}

export default function VaccinationRow({
  vaccination,
  onChange,
  onRemove
}: PropTypes): JSX.Element {
  const [state, setState] = React.useState(vaccination);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        gap: "10px"
      }}
    >
      <Box sx={{ width: "40%" }}>
        <Autocomplete
          value={state}
          options={vaccinations}
          getOptionLabel={(option) => startCase(option.name)}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder={t("assessment.selectVaccination")}
            />
          )}
          onChange={(_, v) => {
            if (!v || typeof v === "string") return;
            setState({
              ...state,
              name: v.name
            });
            onChange({ ...state, name: v.name });
          }}
        />
      </Box>

      <Box
        sx={{
          width: "20%",
          display: "flex",
          alignItems: "center"
        }}
      >
        <ButtonGroup variant="outlined" sx={{ width: "100%" }}>
          <Button
            sx={{
              width: "50%",
              backgroundColor: state.yesno ? "primary.main" : "white",
              color: state.yesno ? "white" : "text.primary",
              "&:hover": {
                backgroundColor: state.yesno ? "primary.dark" : "#ededed"
              }
            }}
            onClick={() => {
              setState({ ...state, yesno: true });
              onChange(state);
            }}
          >
            Yes
          </Button>

          <Button
            sx={{
              width: "50%",
              backgroundColor: !state.yesno ? "primary.main" : "white",
              color: !state.yesno ? "white" : "text.primary",
              "&:hover": {
                backgroundColor: !state.yesno ? "primary.dark" : "#ededed"
              }
            }}
            onClick={() => {
              setState({ ...state, yesno: false });
              onChange({ ...state, yesno: false });
            }}
          >
            No
          </Button>
        </ButtonGroup>
      </Box>

      <Box
        sx={{
          width: "30%",
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center"
        }}
      >
        <TextField
          sx={{ width: "100%" }}
          type="date"
          onChange={(e) => {
            setState({ ...state, date: e.target.value });
            onChange({ ...state, date: e.target.value });
          }}
          value={state.date}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
        />
      </Box>
      <IconButton
        sx={{
          width: "10%",
          borderRadius: "0px"
        }}
      >
        <CloseIcon sx={{ cursor: "pointer" }} onClick={() => onRemove()} />
      </IconButton>
    </Box>
  );
}
