/* eslint-disable react/no-unescaped-entities */
import { Grid2 as Grid, Typography } from "@mui/material";
import SummarizeIcon from "@mui/icons-material/Summarize";
import React from "react";
import moment from "moment-timezone";
import { LabSummary } from "../../../../interfaces/Lab";
import { StatCard } from "../../../DashboardV2/CardStats";

const findTestCountInLast24Hour = (status: string, labSummary: LabSummary[]): number =>
  labSummary?.reduce(
    (prev, curr): number =>
      prev +
      Number(
        curr.labStatus === status &&
          new Date(curr.date) >=
            moment.tz("Asia/Kathmandu").startOf("day").subtract(1, "day").toDate()
      ) *
        curr.testCount,
    0
  );

const findTestCountForCurrentFiscalYear = (status: string, labSummary: LabSummary[]): number =>
  labSummary?.reduce(
    (prev, curr): number =>
      prev +
      Number(curr.labStatus === status && new Date("2022-01-01") < new Date(curr.date)) *
        curr.testCount,
    0
  );

const LabStatistics = ({ labSummary }: { labSummary: LabSummary[] }): JSX.Element => {
  const iconSize = { width: "24px", height: "24px" };

  const completedTestInLast24Hour = findTestCountInLast24Hour("Ready", labSummary);

  const wipTestInLast24Hour = findTestCountInLast24Hour("WIP", labSummary);

  const completedLabTestsForCurrentFiscalYear = findTestCountForCurrentFiscalYear(
    "Ready",
    labSummary
  );

  const labInProgressForCurrentFiscalYear = findTestCountForCurrentFiscalYear("WIP", labSummary);

  return (
    <div>
      <Grid item container spacing={2}>
        <Grid size={{ xs: 12, sm: 3 }}>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold", marginBottom: "5px" }}>
            Yesterday's Update
          </Typography>
          <StatCard
            title="Lab Test Completed"
            totalValue={completedTestInLast24Hour || 0}
            icon={<SummarizeIcon style={iconSize} />}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 3 }}>
          <StatCard
            title="Lab in Progress"
            totalValue={wipTestInLast24Hour || 0}
            icon={<SummarizeIcon style={iconSize} />}
            cssStyle={{ marginTop: "30px" }}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 3 }}>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold", marginBottom: "5px" }}>
            Total tests this fiscal year
          </Typography>
          <StatCard
            title="Lab Test Completed"
            totalValue={completedLabTestsForCurrentFiscalYear || 0}
            icon={<SummarizeIcon style={iconSize} />}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 3 }}>
          <StatCard
            title="Lab in Progress"
            totalValue={labInProgressForCurrentFiscalYear || 0}
            icon={<SummarizeIcon style={iconSize} />}
            cssStyle={{ marginTop: "30px" }}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default LabStatistics;
