import React from "react";
import {
  Box,
  TextField,
  InputAdornment,
  MenuItem,
  Checkbox,
  Typography,
  Grid2 as Grid,
  FormControlLabel,
  Alert,
  Button
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Autocomplete from "@mui/material/Autocomplete";
import upperFirst from "lodash/upperFirst";
import { useDispatch, useSelector } from "react-redux";
import { tl, t } from "../../components/translate";
import { getMedicineSearch } from "../../api/prescrption";
import { medicineType } from "../Assessment/Medication/medicineType";
import { unitOfProducts } from "../../models/Service";
import ProductExtraInfo from "./ProductExtraInfo";
import hasOwnProperty from "../../helpers/object";
import { StateProductinfo } from "./ProductCreate";
import { LabTest } from "../../interfaces/Lab";
import AccountLedgerAutocomplete from "../../components/AccountLedgerAutocomplete/AccountLedgerAutocomplete";
import { KnownLedgers, PrimaryLedgerNames } from "../../interfaces/Accounts";
import { RootState } from "../../store";
import VatOptionSelection from "../Stock/StockCreateEdit/VatOptionSelection";
import { getTaxationOptions } from "../Stock/StockCreateEdit/CreateStockProduct";
import useTaxations from "../../hooks/useTaxations";
import { defaultServiceTypes, specialLabCategories } from "./ServiceList";
import Modal from "../../components/Modal/Modal";
import LedgerSelect from "../ResourceCentre/Settings/AccountSettings/LedgerSelect";
import { findByCode } from "../accounts/hooks";
import { taxationPatch } from "../../slices/journalEntry";
import { TaxationInterface } from "../../interfaces/ProductInterface";

interface ResourceCentreInfo {
  rcIds: Array<number>;
  name: string;
}

interface CommonServiceFieldsState {
  fieldData: StateProductinfo & {
    packageName: "string";
    packagePrice: number;
    departmentId: number;
  };
  onUpdate: (updates: { [key: string]: unknown } | string, value?: unknown) => void;
  labTests: LabTest[];
  forPublicBooking?: boolean;
  isPublicTab?: boolean;
  isOpd?: boolean;
  resourceCentres: ResourceCentreInfo[];
  isForEditingServiceUnderPackage?: boolean;
  packageClinicPriceError?: string;
  setPackageClinicPriceError?: React.Dispatch<React.SetStateAction<string>>;
  isSsfService?: boolean;
}

export const MedicationListView = (props: {
  brand: string;
  genericName: string;
  strength: string;
  form: string;
}): JSX.Element => {
  const { form, brand, strength, genericName } = props;
  return (
    <Box alignItems="center" display="flex" width="100%" pt="1rem" pb="1rem" pl="1rem">
      <Box>{medicineType[form] && medicineType[form]()}</Box>
      <Typography component="span" variant="body2">
        <Box pl={1} display="inline">
          {brand}
        </Box>
        <Box pl={0.5} display="inline">
          {strength}
        </Box>
        <Box pl={1}>{genericName}</Box>
      </Typography>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  priceUnitsContainer: {
    marginTop: "8px",
    marginBottom: "8px",
    [theme.breakpoints.down("md")]: {
      width: "100%"
    }
  },
  clinicPriceContainer: {
    marginTop: "16px",
    marginBottom: "48px"
  },
  clinicPrice: {
    fontWeight: 700,
    fontSize: "16px",
    lineHeight: "24px",
    marginBottom: "20px"
  }
}));

export interface UpdateProps {
  info: { [key: string]: string };
  productisableId: number;
  productisableName: string;
}

const CommonServiceFields: React.FC<CommonServiceFieldsState> = (props) => {
  const {
    fieldData,
    onUpdate,
    labTests = [],
    forPublicBooking = false,
    isPublicTab = false,
    isOpd = false,
    resourceCentres = [],
    isForEditingServiceUnderPackage = false,
    packageClinicPriceError,
    setPackageClinicPriceError,
    isSsfService = false
  } = props;

  const [medicines, setMedicines] = React.useState([]);
  const [selectedResourceCentre, setSelectedResourceCentre] = React.useState({
    id: null,
    label: ""
  });

  React.useEffect(() => {
    const resourceCentre = resourceCentres.find((rc) => rc.rcIds.includes(fieldData.assignedRc));
    if (resourceCentre) {
      setSelectedResourceCentre({
        id: resourceCentre.rcIds,
        label: resourceCentre.name
      });
    }
  }, [fieldData.assignedRc, resourceCentres]);

  const isAccountSubscribed = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription?.features?.account?.subscribed
  );
  const keyValues = Object.keys(fieldData);
  const priceKey: string = keyValues.includes("productPrice") ? "productPrice" : "packagePrice";

  const classes = useStyles();
  const rcServiceTypes = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres[0]?.settings?.serviceSettings?.serviceTypes || []
  );
  const serviceTypes = forPublicBooking ? defaultServiceTypes : rcServiceTypes;

  const handleNonNumericInput = (e) => {
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };
  const taxations = useTaxations() || [];

  const { salesTaxationOptions } = getTaxationOptions(taxations);

  const dispatch = useDispatch();
  const [showTaxationMapAlert, setTaxationMapAlert] = React.useState<boolean>(false);
  const [openTaxationMappingModal, setOpenTaxationMappingModal] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (isAccountSubscribed && fieldData.salesTaxationId) {
      const selectedTaxationRule = salesTaxationOptions.find(
        (rule) => rule.id === fieldData.salesTaxationId
      );
      if (!selectedTaxationRule?.ledgerId) {
        setTaxationMapAlert(true);
      } else {
        setTaxationMapAlert(false);
      }
    }
  }, [fieldData.salesTaxationId, isAccountSubscribed, salesTaxationOptions]);
  const vatOnSalesLedger = findByCode(KnownLedgers.VAT_ON_SALES);
  const [selectedLedger, setSelectedLedger] = React.useState<TaxationInterface | null>(null);
  return (
    <Box style={{ width: "100%" }}>
      {hasOwnProperty(fieldData, "productType") && (
        <Box gap={2} display="flex">
          <TextField
            label={tl("services")}
            select
            fullWidth
            disabled={isPublicTab || isOpd || isForEditingServiceUnderPackage || isSsfService}
            margin="dense"
            variant="outlined"
            data-testmation="productType"
            value={fieldData.productType}
            onChange={(e) =>
              onUpdate({
                productType: e.target.value,
                productisableId: null,
                productisableName: null
              })
            }
          >
            {serviceTypes.map((option) => (
              <MenuItem key={option} value={option} data-testmation="productMenuItem">
                {upperFirst(option)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      )}
      {!["labTest", "medication"].includes(fieldData.productType) ? (
        <>
          {hasOwnProperty(fieldData, "packageName") && (
            <TextField
              label={tl("services.name")}
              placeholder={t("services.enterPackageName")}
              fullWidth
              disabled={isPublicTab}
              data-testmation={keyValues[0]}
              margin="dense"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              variant="outlined"
              value={fieldData.packageName}
              onChange={(e) => onUpdate({ packageName: e.target.value })}
            />
          )}
          {hasOwnProperty(fieldData, "productName") && (
            <TextField
              label={tl("services.name")}
              placeholder={t("services.enterServiceName")}
              fullWidth
              data-testmation={keyValues[0]}
              disabled={isPublicTab || isForEditingServiceUnderPackage}
              margin="dense"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              variant="outlined"
              value={fieldData.productName}
              onChange={(e) => onUpdate({ productName: e.target.value })}
            />
          )}
        </>
      ) : (
        <Autocomplete
          freeSolo
          disableClearable
          disabled={isPublicTab || isForEditingServiceUnderPackage}
          inputValue={
            keyValues[0] === "packageName" ? fieldData.packageName : fieldData.productName
          }
          options={(fieldData.productType === "medication" ? medicines : labTests).map((item) => ({
            ...item,
            label: item.name || item.brand
          }))}
          getOptionLabel={(option) => {
            // Value selected with enter, right from the input
            if (typeof option === "string") {
              return option;
            }
            return fieldData.productType === "medication" ? option.brand : option.name;
          }}
          renderOption={(renderProps, option) => {
            if (option && fieldData.productType === "medication") {
              return (
                // eslint-disable-next-line react/jsx-props-no-spreading
                <li {...renderProps} key={option.id}>
                  <MedicationListView
                    form={option.form}
                    brand={option.brand}
                    strength={option.strength}
                    genericName={option.genericName}
                  />
                </li>
              );
            }
            return (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...renderProps} key={option.id}>
                {option.name}
              </li>
            );
          }}
          data-testmation={keyValues[0] === "packageName" ? "packageName" : "productName"}
          onChange={(e, v) => {
            let fieldName = v || "";
            const updates: UpdateProps = {} as UpdateProps;
            if (v && typeof v === "object") {
              if (fieldData.productType === "medication") {
                const { brand, genericName, strength, code, genericCode, form } = v;
                fieldName = v.strength
                  ? `${brand} (${String(form).substr(0, 3)}) (${strength})`
                  : `${brand} (${String(form).substr(0, 3)})`;
                updates.info = { brand, genericName, strength, code, genericCode, form };
              } else {
                updates.productisableId = v.id;
                updates.productisableName = "labTestGroup";
                fieldName = v.name;
              }
            }
            onUpdate({
              [keyValues[0] === "packageName" ? "packageName" : "productName"]: fieldName,
              ...updates
            });
          }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              disabled={isPublicTab || isForEditingServiceUnderPackage}
              label={tl("services.name")}
              placeholder={t("services.name")}
              slotProps={{
                inputLabel: { shrink: true }
              }}
              margin="dense"
              variant="outlined"
              onChange={(e) => {
                if (e.target.value.length > 2) {
                  if (fieldData.productType === "medication") {
                    getMedicineSearch(e.target.value).then((res) => setMedicines(res));
                  }
                }
                onUpdate({
                  [keyValues[0] === "packageName" ? "packageName" : "productName"]: e.target.value
                });
              }}
            />
          )}
        />
      )}
      {fieldData.productType === "labTest" && fieldData.productisableId && (
        <Typography style={{ fontSize: "0.75rem" }}>
          Linked to {labTests.find((test) => test.id === fieldData.productisableId)?.name}
        </Typography>
      )}
      {fieldData.info && (
        <ProductExtraInfo
          info={fieldData.info}
          clearInfo={isPublicTab ? null : () => onUpdate({ info: null })}
        />
      )}

      {(forPublicBooking || isPublicTab) &&
        ["labTest", ...specialLabCategories].includes(fieldData.productType) && (
          <TextField
            label="Special Lab Category"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            select
            sx={{ width: "333px" }}
            disabled={isPublicTab}
            margin="dense"
            variant="outlined"
            data-testmation="specialLabTest"
            value={fieldData.specialLabTest}
            onChange={(e) =>
              onUpdate({
                specialLabTest: e.target.value
              })
            }
          >
            {specialLabCategories.map((option) => (
              <MenuItem key={option} value={option} data-testmation="specialLabTestCategory">
                {upperFirst(option)}
              </MenuItem>
            ))}
          </TextField>
        )}

      {(isPublicTab || forPublicBooking) && (
        <Box>
          <TextField
            label="Public Description"
            placeholder="Enter Public Description"
            multiline
            rows={4}
            disabled={isPublicTab}
            fullWidth
            data-testmation="publicDescription"
            margin="dense"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            value={fieldData.publicDescription}
            onChange={(e) => onUpdate({ publicDescription: e.target.value })}
          />
          <TextField
            label="Instructions"
            placeholder="Enter Instructions For Service"
            multiline
            rows={4}
            disabled={isPublicTab}
            fullWidth
            data-testmation="instructions"
            margin="dense"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            value={fieldData.instructions}
            onChange={(e) => onUpdate({ instructions: e.target.value })}
          />
        </Box>
      )}
      {forPublicBooking && (
        <>
          <FormControlLabel
            control={<Checkbox checked={fieldData.handledByOkhati} />}
            label="Handled By Okhati (Home Sample Collection)"
            onChange={() =>
              onUpdate({
                handledByOkhati: !fieldData.handledByOkhati
              })
            }
          />

          {fieldData.handledByOkhati && (
            <Autocomplete
              fullWidth
              freeSolo
              options={resourceCentres.map((rc) => ({ id: rc.rcIds, label: rc.name }))}
              value={selectedResourceCentre}
              data-testmation="assignedRc"
              getOptionLabel={(option) => option.label}
              renderInput={(params) => (
                <TextField
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...params}
                  label="Assign To"
                  placeholder="Assign To"
                  slotProps={{
                    inputLabel: { shrink: true }
                  }}
                  margin="dense"
                  variant="outlined"
                />
              )}
              onChange={(e, v) => {
                onUpdate({
                  // since rcIds always have single value inside array for resource centres
                  assignedRc: v?.id[0]
                });
              }}
            />
          )}
        </>
      )}

      <Box className={classes.priceUnitsContainer}>
        <Grid container columnSpacing={{ md: 1.5 }}>
          <Grid size={9}>
            <TextField
              label={tl("services.clinicPrice")}
              margin="dense"
              type="number"
              placeholder="0"
              data-testmation={priceKey}
              slotProps={{
                input: {
                  startAdornment: <InputAdornment position="start">Rs.</InputAdornment>
                },
                inputLabel: { shrink: true }
              }}
              disabled={isSsfService}
              onFocus={(e) => e.target.select()}
              variant="outlined"
              value={fieldData[priceKey]}
              onKeyDown={(e) => handleNonNumericInput(e)}
              onChange={(e) => {
                const { value } = e.target;

                onUpdate({ [priceKey]: value });

                if (setPackageClinicPriceError) {
                  if (value > fieldData?.refTotalIncVAT) {
                    setPackageClinicPriceError(
                      "Price can't be greater than sum of clinic prices of services within package"
                    );
                  } else {
                    setPackageClinicPriceError("");
                  }
                }
              }}
              fullWidth
            />
            {packageClinicPriceError && (
              <Typography variant="body2" color="#E2A336">
                {packageClinicPriceError}
              </Typography>
            )}
          </Grid>
          <Grid size={3}>
            {hasOwnProperty(fieldData, "productUnits") && (
              <Autocomplete
                fullWidth
                freeSolo
                options={unitOfProducts.map((option) => option)}
                value={fieldData.productUnits}
                data-testmation="productUnits"
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label={tl("services.units")}
                    placeholder={t("services.units")}
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                    margin="dense"
                    variant="outlined"
                  />
                )}
                onInputChange={(e, v) => {
                  onUpdate({ productUnits: v });
                }}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box
        style={{
          marginTop: "8px",
          marginBottom: "8px"
        }}
      >
        <Grid container columnSpacing={{ md: 1.5 }}>
          <Grid size={4}>
            {isAccountSubscribed ? (
              <>
                <VatOptionSelection
                  sx={{ width: "210px", margin: "0" }}
                  value={salesTaxationOptions.find(
                    (option) => option.id === fieldData.salesTaxationId
                  )}
                  onChange={(v) => {
                    onUpdate({ salesTaxationId: v?.id, vatPct: Number(v.rate) });
                  }}
                  vatPercentageOptions={salesTaxationOptions}
                  dataTestmation="vatOptionSelect"
                />
                {showTaxationMapAlert && (
                  <Alert
                    sx={{ position: "absolute", zIndex: 999 }}
                    action={
                      <Button
                        onClick={() => setOpenTaxationMappingModal(true)}
                        variant="outlined"
                        color="inherit"
                        size="small"
                      >
                        {tl("mapLedger")}
                      </Button>
                    }
                    severity="warning"
                  >
                    {tl("TaxRuleMsg")}
                  </Alert>
                )}
                <Modal
                  open={openTaxationMappingModal}
                  title={tl("SelectLedger")}
                  footer={
                    <>
                      <Button onClick={() => setOpenTaxationMappingModal(false)}>Cancel</Button>
                      <Button
                        onClick={() => {
                          if (fieldData.salesTaxationId) {
                            dispatch(
                              taxationPatch({
                                id: fieldData.salesTaxationId,
                                ledgerId: selectedLedger?.id || null
                              })
                            );
                            setOpenTaxationMappingModal(false);
                          }
                        }}
                      >
                        Save
                      </Button>
                    </>
                  }
                >
                  <LedgerSelect
                    options={vatOnSalesLedger ? [vatOnSalesLedger] : []}
                    selected={selectedLedger}
                    onChange={(value) => setSelectedLedger(value)}
                  />
                </Modal>
              </>
            ) : (
              <TextField
                type="number"
                label={tl("services.vatPct")}
                // disabled={keyValues[1] === 'packagePrice'}
                placeholder="0"
                margin="dense"
                variant="outlined"
                data-testmation="vatPct"
                value={fieldData.vatPct}
                onChange={(e) => onUpdate({ vatPct: e.target.value })}
                slotProps={{
                  inputLabel: { shrink: true },
                  input: {
                    endAdornment: (
                      <InputAdornment position="start">
                        <Typography>%</Typography>
                      </InputAdornment>
                    )
                  }
                }}
                onFocus={(e) => e.target.select()}
              />
            )}
          </Grid>
          <Grid size={4}>
            {hasOwnProperty(fieldData, "materialCharge") && (
              <TextField
                type="number"
                label={tl("services.materialCharge")}
                placeholder="0"
                margin="dense"
                variant="outlined"
                data-testmation="materialCharge"
                value={fieldData.materialCharge}
                onChange={(e) => onUpdate({ materialCharge: e.target.value || 0 })}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="start">
                        <Typography>%</Typography>
                      </InputAdornment>
                    )
                  },
                  inputLabel: { shrink: true }
                }}
                onFocus={(e) => e.target.select()}
              />
            )}
          </Grid>
          <Grid size={4}>
            {hasOwnProperty(fieldData, "labCharge") && (
              <TextField
                type="number"
                label={tl("services.labCharge")}
                placeholder="0"
                margin="dense"
                variant="outlined"
                data-testmation="labCharge"
                value={fieldData.labCharge}
                onChange={(e) => onUpdate({ labCharge: e.target.value || 0 })}
                slotProps={{
                  inputLabel: { shrink: true },
                  input: {
                    startAdornment: (
                      <InputAdornment position="start">
                        <Typography>Rs</Typography>
                      </InputAdornment>
                    )
                  }
                }}
                onFocus={(e) => e.target.select()}
              />
            )}
          </Grid>
        </Grid>
      </Box>
      {isAccountSubscribed && (
        <Box width="400px">
          <AccountLedgerAutocomplete
            ledgerId={fieldData.salesLedgerId}
            onChange={(v) => onUpdate({ salesLedgerId: v?.id })}
            ledgerType={PrimaryLedgerNames.INCOME}
          />
        </Box>
      )}

      <Box className={classes.clinicPriceContainer}>
        <Typography className={classes.clinicPrice}>{tl("services.clinicPriceTitle")}:</Typography>
        <Grid container>
          <Grid size={{ xs: 12, md: 8 }}>
            <Grid container>
              <Grid size={4}>
                <Typography style={{ fontSize: "14px", fontWeight: 500 }}>Without VAT</Typography>
              </Grid>

              <Grid size={4}>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#5A607F"
                  }}
                >
                  Rs
                </Typography>
              </Grid>

              <Grid size={4}>
                <Typography
                  style={{
                    fontSize: "14px",
                    fontWeight: 500,
                    color: "#5A607F"
                  }}
                >
                  {fieldData.servicePriceExVAT}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      {false && (
        <Box
          pb={3}
          display="flex"
          alignItems="center"
          style={{ borderBottom: "0.5px solid lightgray" }}
        >
          <Typography>Is Taxable</Typography>
          <Checkbox
            checked={fieldData.isTaxable}
            onChange={() => {
              const toggleVal = !fieldData.isTaxable;
              onUpdate({ isTaxable: toggleVal });
            }}
          />
        </Box>
      )}
      {isPublicTab && fieldData.handledByOkhati && (
        <Typography marginBottom={2}>
          Note: The sample will be collected from home by Okhati
        </Typography>
      )}
    </Box>
  );
};

CommonServiceFields.defaultProps = {
  forPublicBooking: false,
  isPublicTab: false,
  isOpd: false
};

export default CommonServiceFields;
