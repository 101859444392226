import React from "react";
import { connect } from "react-redux";
import { Box, Stack, Typography } from "@mui/material";
import ClientSearch from "../../components/ClientSearch";
import { t, tl } from "../../components/translate";
import { Client } from "../../interfaces/ClientInterface";
import { showDOBAndGender } from "../Billing/PrintBill/EightyMmPrint/ClientInfo";

export const getClientGender = (genderValue: string): string => {
  if (genderValue === "1") return "Male";

  if (genderValue === "2") return "Female";

  return "Other";
};

const AssessmentClientSelect = ({
  client,
  setClientId,
  isDisabled,
  width
}: {
  client: Client;
  setClientId: React.Dispatch<React.SetStateAction<number>>;
  isDisabled: boolean;
  width?: string;
}) => (
  <Box
    sx={{
      flexGrow: 1,
      display: "flex",
      flexDirection: "column",
      gap: 1,
      justifyContent: "space-between",
      width: "300px"
    }}
  >
    <Stack sx={{ width: width || "20rem" }}>
      <ClientSearch
        label={tl("billing.client")}
        placeholder={t("selectClient")}
        error={!client}
        variant="outlined"
        margin="dense"
        client={client}
        autoFocus
        isDisabled={isDisabled}
        secondaryText={[
          {
            type: "customerNumber"
          },
          {
            type: "externalIdentifier",
            label: t("client.externalIdentifier.short")
          }
        ]}
        setClient={(selectedClient) => {
          if (selectedClient) setClientId(selectedClient.id);
        }}
      />
      {client && (
        <Box>
          <Typography fontSize="14px" component="span">
            {showDOBAndGender(client.gender, client.dob, true)}
            {client.phone && (client.dob || client.gender) ? `, ${client.phone}` : client.phone}
          </Typography>
        </Box>
      )}
    </Stack>
  </Box>
);

export default connect(({ clients }, { clientId }) => ({
  client: clients.collection?.find(({ id }) => Number(clientId) === id)
}))(AssessmentClientSelect);
