import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import produce from "immer";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { getSurveyFormTemplate, postSurveyFormTemplate } from "../../../api/surveyForm";
import { SurveyData } from "../../Client/Survey/SurveyCreateEditForm";
import { notificationAdd } from "../../../actions/notification";
import { errorFetchMessage, MODULE, updateSuccessMessage } from "../../../helpers/messages";
import { useAppDispatch } from "../../../store/hooks";

export const defaultQuestions = [
  {
    question: "How did you hear about us ?",
    options: ["friend/family member", "FM", "hoarding board", "newspaper", "social media", "other"],
    key: "knowUsFrom",
    isVisible: true
  },
  {
    question: "Any idea behind the reason of you hair issue ?",
    options: ["yes", "no"],
    key: "reasonForIssue",
    isVisible: true
  },
  {
    question: "Have you tried anything in the past ?",
    options: ["yes", "no"],
    key: "pastTreatment",
    isVisible: true
  },
  {
    question: "Do you know some one who have done hair transplant ?",
    options: ["yes", "no"],
    key: "knownPatient",
    isVisible: true
  },
  {
    question:
      "Are you interested in receiving updates from us on Hair Treatment in future via Email/SMS/Call ?",
    options: ["yes", "no"],
    key: "receiveUpdates",
    isVisible: true
  }
];

export const defaultTemplateDocument = { defaultQuestions, customQuestions: [] };

enum QUESTION_TYPE {
  YES_NO = "Yes/No",
  TEXT = "Text"
}

const QuestionType = [{ label: QUESTION_TYPE.YES_NO }, { label: QUESTION_TYPE.TEXT }];

const SurveyFormSettings = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [newQuestion, setNewQuestion] = useState({
    create: false,
    question: { type: QUESTION_TYPE.YES_NO, text: "" }
  });
  const [isDirty, setIsDirty] = useState(false);

  const [template, setTemplate] = useState<Partial<SurveyData>>({
    document: defaultTemplateDocument
  });

  useEffect(() => {
    (async () => {
      try {
        const response = await getSurveyFormTemplate();
        if (response) {
          setTemplate(response);
        }
      } catch (e) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            message: errorFetchMessage(MODULE.SURVERY_FORM_TEMPLATE),
            variant: "error",
            autoTimeout: true
          })
        );
      }
    })();
  }, []);

  const onSubmit = async () => {
    try {
      const res = await postSurveyFormTemplate(template);
      setTemplate(res);
      setIsDirty(false);
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: updateSuccessMessage(MODULE.SURVERY_FORM_TEMPLATE),
          variant: "success",
          autoTimeout: true
        })
      );
    } catch (e) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: errorFetchMessage(MODULE.SURVERY_FORM_TEMPLATE),
          variant: "error",
          autoTimeout: true
        })
      );
    }
  };

  return (
    <div>
      <Typography fontSize={16} fontWeight={500} gutterBottom>
        Default Questions
      </Typography>
      {template.document?.defaultQuestions.map((item, index) => (
        <Box key={item.key} display="flex" gap={1} alignItems="center">
          <FormControlLabel
            label={item.question}
            control={
              <Tooltip title={item.isVisible ? "Hide question" : "Show question"}>
                <Checkbox
                  checked={item.isVisible}
                  onChange={() => {
                    setIsDirty(true);
                    const updatedState = produce(template, (draft) => {
                      draft.document.defaultQuestions[index].isVisible = !item.isVisible;
                    });
                    setTemplate(updatedState);
                  }}
                />
              </Tooltip>
            }
          />
        </Box>
      ))}
      <Box mt={3}>
        <Typography fontSize={16} fontWeight={500} gutterBottom>
          Custom Questions
        </Typography>
        {template.document?.customQuestions.map((item, index) => (
          <Box key={item.question} display="flex" gap={2} alignItems="center">
            <Typography>{`${item.question} (${item.options ? "Yes/no" : "Text"})`}</Typography>
            <IconButton
              size="small"
              onClick={() => {
                setIsDirty(true);
                const updatedState = produce(template, (draft) => {
                  draft.document?.customQuestions.splice(index, 1);
                });
                setTemplate(updatedState);
              }}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        ))}
      </Box>
      <Box my={4}>
        <Box display="flex" gap={1} alignItems="center" mt={2} component="form">
          <Box maxWidth={500} width="100%">
            <TextField
              fullWidth
              variant="outlined"
              label="Question"
              placeholder="Enter you question"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              value={newQuestion.question.text}
              onChange={(event) => {
                setNewQuestion({
                  ...newQuestion,
                  question: { ...newQuestion.question, text: event.currentTarget.value }
                });
              }}
            />
          </Box>
          <Autocomplete
            disablePortal
            defaultValue={QuestionType[0]}
            disableClearable
            onChange={(_, newValue) => {
              setNewQuestion({
                ...newQuestion,
                question: { ...newQuestion.question, type: newValue.label }
              });
            }}
            options={QuestionType}
            sx={{ width: 150 }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                label="Answer type"
                variant="outlined"
                slotProps={{
                  inputLabel: { shrink: true }
                }}
              />
            )}
          />
          <Button
            disabled={!newQuestion.question.text}
            variant="contained"
            type="submit"
            onClick={async (event) => {
              event.preventDefault();
              const updatedState = produce(template, (draft) => {
                draft.document.customQuestions = [
                  ...draft.document.customQuestions,
                  {
                    question: newQuestion.question.text,
                    isVisible: true,
                    ...(newQuestion.question.type === QUESTION_TYPE.YES_NO
                      ? { options: ["yes", "no"] }
                      : {})
                  }
                ];
              });
              setTemplate(updatedState);
              setIsDirty(true);
              setNewQuestion({
                create: false,
                question: { type: QUESTION_TYPE.YES_NO, text: "" }
              });
            }}
          >
            Add
          </Button>
        </Box>
      </Box>
      <Box mt={1}>
        <Button variant="contained" onClick={onSubmit} disabled={!isDirty}>
          Save
        </Button>
      </Box>
    </div>
  );
};

export default SurveyFormSettings;
