import { Box, Typography } from "@mui/material";
import React from "react";
import { Bed, Ward } from "../../../api/wards";
import { IPD } from "../../../interfaces/IpdInterface";
import { tl } from "../../../components/translate";

interface Props {
  wards: Array<Ward>;
  beds: Array<Bed>;
  activeIpds: Array<Partial<IPD>>;
  setFilter: React.Dispatch<
    React.SetStateAction<{
      selectedWard: number | null;
      bedNumber: string;
      kpiKey: string;
      filterAvailableBeds: boolean;
    }>
  >;
}
const RoomList = ({ wards, beds, activeIpds, setFilter }: Props): JSX.Element => (
  <Box
    sx={{
      minHeight: "540px",
      border: "1px solid rgba(90, 96, 127, 0.5)",
      borderRadius: "4px"
    }}
    p={3}
  >
    <Box display="flex" alignItems="center" mb={3}>
      <Box
        sx={{
          width: "14px",
          height: "14px",
          borderRadius: "7px",
          backgroundColor: "#009654"
        }}
      />
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: "500"
        }}
        ml={2}
      >
        {tl("ipd.availableRooms")}
      </Typography>
    </Box>
    <>
      {wards?.map((ward) => {
        const totalBeds = beds.filter((bed) => bed.wardId === ward.id).length;
        const totalOccupiedbeds = activeIpds.filter((ipd) => ipd.wardId === ward.id).length;

        return (
          <Box
            key={ward.id}
            sx={{
              "&:hover": {
                backgroundColor: "#F1FFF3"
              }
            }}
            onClick={() => {
              setFilter({
                bedNumber: "",
                kpiKey: "",
                selectedWard: ward.id,
                filterAvailableBeds: true
              });
            }}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" pt={2}>
              <Typography flexBasis="20%">{ward.shortCode}</Typography>
              <Typography flexBasis="40%" textAlign="left">
                {ward.name}
              </Typography>
              <Typography flexBasis="30%" textAlign="right">
                {totalOccupiedbeds}/{totalBeds}
              </Typography>
            </Box>

            <Box
              sx={{
                width: "100%",
                backgroundColor: "rgba(90, 96, 127, 0.25)",
                height: "1px"
              }}
              mt={1}
            />
          </Box>
        );
      })}
    </>
  </Box>
);

export default RoomList;
