import React from "react";

const CustomHeader = ({ imageUrl }: { imageUrl: string }): JSX.Element => (
  <img
    src={imageUrl}
    alt="custom-header"
    style={{
      objectFit: "cover",
      width: "100%"
    }}
  />
);

export default CustomHeader;
