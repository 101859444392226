import React from "react";
import { Box, Typography } from "@mui/material";
import { tl } from "../../../components/translate";
import styles from "./Assessment.module.css";
import TextBox from "../../../components/TextBox/TextBox";

interface Props {
  assessmentPastHistoryOfAllergy: string;
  onChange: (val: string) => void;
}
const PastHistoryOfAllergy = ({ assessmentPastHistoryOfAllergy, onChange }: Props): JSX.Element => (
  <Box className={styles.assessmentRow}>
    <Box className={styles.assessmentLabel}>
      <Typography>
        <Box component="span" fontWeight="600">
          {tl("assessment.pastHistoryOfAllergy")}
        </Box>
      </Typography>
    </Box>
    <Box className={styles.assessmentField}>
      <TextBox
        onFocus={(e) => e.target.select()}
        data-testmation="PastHistoryOfAllergy"
        margin="dense"
        variant="outlined"
        fullWidth
        multiline
        rowsMax={3}
        value={assessmentPastHistoryOfAllergy}
        placeholder="Type past history of allergy"
        showTextCounter
        slotProps={{
          htmlInput: { maxLength: 255 },
          formHelperText: { style: { position: "absolute", right: 0, bottom: -5, margin: 5 } }
        }}
        onSave={(value) => onChange(value)}
      />
    </Box>
  </Box>
);

export default PastHistoryOfAllergy;
