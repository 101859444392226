import {
  Box,
  Grid2 as Grid,
  TextField,
  Typography,
  FormGroup,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import produce from "immer";
import * as React from "react";
import { IntraOralAdvancedData } from "./IntraOralAdvancedForm";

interface Props {
  data: Omit<IntraOralAdvancedData, "teethData">;
  onChange: (v: Omit<IntraOralAdvancedData, "teethData">) => void;
}

function RestForm(props: Props): JSX.Element {
  const { data, onChange } = props;
  return (
    <div>
      <Box mt="32px">
        <Grid container spacing={2}>
          <Grid size={12}>
            <TextField
              fullWidth
              label="Soft Tissue Examination"
              variant="outlined"
              defaultValue={data.softTissueExamination}
              onBlur={(e) => {
                onChange({ ...data, softTissueExamination: e.target.value });
              }}
            />
          </Grid>
          <Grid size={12}>
            <TextField
              fullWidth
              label="Occlusion"
              variant="outlined"
              defaultValue={data.occlusion}
              onBlur={(e) => {
                onChange({ ...data, occlusion: e.target.value });
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              fullWidth
              label="Calculus"
              variant="outlined"
              defaultValue={data.calculus}
              onBlur={(e) => {
                onChange({ ...data, calculus: e.target.value });
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              fullWidth
              label="Stain"
              variant="outlined"
              defaultValue={data.stain}
              onBlur={(e) => {
                onChange({ ...data, stain: e.target.value });
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }} display="flex" alignItems="center">
            <Typography>Carries Risk</Typography>
            <FormGroup row sx={{ ml: "32px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.cariesRisk === "high"}
                    onChange={() => onChange({ ...data, cariesRisk: "high" })}
                  />
                }
                label="High"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.cariesRisk === "medium"}
                    onChange={() => onChange({ ...data, cariesRisk: "medium" })}
                  />
                }
                label="Medium"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={data.cariesRisk === "low"}
                    onChange={() => onChange({ ...data, cariesRisk: "low" })}
                  />
                }
                label="Low"
              />
            </FormGroup>
          </Grid>
          <Grid size={{ xs: 12, sm: 6 }}>
            <TextField
              label="Recall Gap"
              fullWidth
              variant="outlined"
              defaultValue={data.recallGap}
              onBlur={(e) => {
                onChange({ ...data, recallGap: e.target.value });
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }}>
            <TextField
              label="Attrition"
              fullWidth
              variant="outlined"
              defaultValue={data.attrition}
              onBlur={(e) => {
                onChange({ ...data, attrition: e.target.value });
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }}>
            <TextField
              label="Abrasion"
              fullWidth
              variant="outlined"
              defaultValue={data.abrasion}
              onBlur={(e) => {
                onChange({ ...data, abrasion: e.target.value });
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }}>
            <TextField
              label="Erosion"
              fullWidth
              variant="outlined"
              defaultValue={data.erosion}
              onBlur={(e) => {
                onChange({ ...data, erosion: e.target.value });
              }}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 3 }}>
            <TextField
              label="Abfraction"
              fullWidth
              variant="outlined"
              defaultValue={data.abfraction}
              onBlur={(e) => {
                onChange({ ...data, abfraction: e.target.value });
              }}
            />
          </Grid>
          <Grid size={12}>
            <Typography>BPE</Typography>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Grid container>
              {data.bpe.slice(0, 6).map((el, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid size={{ xs: 12, sm: 6 }} key={i}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    defaultValue={data.bpe[i]}
                    onBlur={(e) => {
                      const updatedData = produce(data, (draft) => {
                        draft.bpe[i] = e.target.value;
                      });
                      onChange(updatedData);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Grid container>
              {data.bpe.slice(6, 12).map((el, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid size={{ xs: 12, sm: 4 }} key={i}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    defaultValue={data.bpe[i + 6]}
                    onBlur={(e) => {
                      const updatedData = produce(data, (draft) => {
                        draft.bpe[i + 6] = e.target.value;
                      });
                      onChange(updatedData);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid size={{ xs: 12, sm: 4 }}>
            <Grid container>
              {data.bpe.slice(12).map((el, i) => (
                // eslint-disable-next-line react/no-array-index-key
                <Grid size={{ xs: 12, sm: 4 }} key={i}>
                  <TextField
                    variant="outlined"
                    fullWidth
                    defaultValue={data.bpe[i + 12]}
                    onBlur={(e) => {
                      const updatedData = produce(data, (draft) => {
                        draft.bpe[i + 12] = e.target.value;
                      });
                      onChange(updatedData);
                    }}
                  />
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default RestForm;
