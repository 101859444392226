import { Box, Button, Link, TextField, Tooltip, Typography } from "@mui/material";
import { push } from "connected-react-router";
import React from "react";
import { connect, useDispatch } from "react-redux";
import * as billActions from "../../../actions/bill";
import {
  isBillCreatedBefore24HoursAgo,
  isOldFormatBill
} from "../../../actions/helpers/billHelper";
import { navigateRemoveModal, showDialog } from "../../../actions/navigation";
import { getClientBalance, getCreditableQuantities } from "../../../api/bill";
import MultipleButtonGroup, { PRINT_FROM } from "../../../components/MultipleButtonGroup";
import { tl } from "../../../components/translate";
import hasOwnProperty from "../../../helpers/object";
import { BillStatus, BillType, DocumentTypes, VisitType } from "../../../interfaces/BillInterfaces";
import { CanActions } from "../../../interfaces/policy";
import EVENT from "../../../mixpanel-analytics/event";
import mixpanelAnalytics from "../../../mixpanel-analytics/mixpanelAnalytics";
import { IThunkDispatch, RootState } from "../../../store";
import Can from "../../Policy/Can";
import BankPaperPrint from "../PrintBill/BankPaperPrint";
import { ForceTemplate } from "../PrintBill/BillPrintHelpers";
import EightyMmPrint from "../PrintBill/EightyMmPrint/EightyMmPrint";
import PrintBill from "../PrintBill/PrintBill";
import PrintBillHalf from "../PrintBill/PrintBillHalf";
import BillStatusChip from "../common/BillStatusChip";
import styles from "./BillActions.module.css";
import CorporateBillPrint from "../PrintBill/CorporatePrint/CorporateBillPrint";
import useCurrentResourceCentre from "../../../hooks/useCurrentResourceCentre";
import OkhatiDialog from "../../../components/Dialog/Dialog";
import { notificationAdd } from "../../../actions/notification";
import { updateLabTestsRecordSample } from "../../../actions/labTest";

const ActionButton = ({
  bill,
  action,
  actions,
  propActions,
  disabled = false,
  helperText = "",
  setLoading,
  onCancel
}) => {
  const actionsMap = {
    edit: actions.onEdit,
    copy: actions.onCopy,
    delete: actions.onDelete,
    cancel: onCancel,
    credit: actions.onCredit,
    commission: propActions.onCommission
  };

  const actionsCanMap: { [k: string]: CanActions | "allow" } = {
    edit: "bill:createDraft",
    copy: "bill:copyBill",
    delete: "bill:createDraft",
    cancel: "bill:cancelBill",
    credit: "bill:createCreditNote",
    commission: "bill:referralAndFees"
  };

  return (
    <Can policyAccessKey={actionsCanMap[action]}>
      <Tooltip title={helperText} placement="top">
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <Link
          className={styles.actionBtn}
          onClick={async () => {
            if (!disabled) {
              // eslint-disable-next-line no-unused-expressions
              setLoading && setLoading(true);
              await actionsMap[action](bill);
              // eslint-disable-next-line no-unused-expressions
              setLoading && setLoading(false);
            }
          }}
          data-testmation={`billAction${action}`}
          style={disabled ? { color: "lightgrey" } : {}}
        >
          <Typography component="span">
            <Box className={styles.actionBtnLabel} component="span" fontSize="0.83em">
              {tl(`billing.action.${action}`)}
            </Box>
          </Typography>
        </Link>
      </Tooltip>
    </Can>
  );
};

const options = {
  draft: ["edit", "copy", "delete"],
  billed: ["cancel", "copy", "credit", "commission"],
  cancelled: ["copy"],
  proformaDraft: ["credit"]
};

export interface ClientDobOnPrint {
  [key: number]: boolean;
}

interface BillActionProps {
  bill: BillType;
  actions: {
    onEdit: (bill: BillType) => void;
    onCopy: (bill: BillType) => Promise<void>;
    onDelete: (bill: BillType) => Promise<void>;
    onPrint: () => void;
    onCancel: (bill: BillType) => Promise<void>;
    onCredit: (bill: BillType) => Promise<void>;
  };
  propActions?: Partial<{
    onCommission: () => void;
  }>;
  permissionGroup: string;
  hideBillingActions?: boolean;
  isKitchenPharmacyBill?: boolean;
  hasCreditNote?: boolean;
  labTestUrl?: string;
}

const BillActions: React.FC<BillActionProps> = ({
  bill,
  actions,
  propActions = {},
  permissionGroup,
  hideBillingActions = false,
  isKitchenPharmacyBill = false,
  hasCreditNote,
  labTestUrl
}) => {
  const dispatch = useDispatch();
  if (!hasOwnProperty(propActions, "onCommission")) {
    options.billed = options.billed.filter((option) => option !== "commission");
  }

  const [remarks, setRemarks] = React.useState("");
  const [showCancelDialog, setShowCancelDialog] = React.useState(false);

  const [clientBalance, setClientBalance] = React.useState<number>(0);
  const rc = useCurrentResourceCentre();

  const [filterBillSubItems, setFilterBillSubItems] = React.useState(bill);
  const [dobInfoOnPrint, setDobInfoOnPrint] = React.useState({} as ClientDobOnPrint);
  const showDobOnPrint = !!dobInfoOnPrint[bill.id];
  const [paymentMethodOnPrint, setPaymentMethodOnPrint] = React.useState({});
  const hidePaymentMethodOnPrint = !!paymentMethodOnPrint[bill.id];
  const [loadingState, setLoadingState] = React.useState(false);

  React.useEffect(() => {
    (async () => {
      try {
        const { balance } = await getClientBalance(Number(bill?.client?.id));
        setClientBalance(balance);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log(error);
      }
    })();
  }, [bill?.client?.id]);

  React.useEffect(() => {
    setFilterBillSubItems(bill);
  }, [bill]);

  React.useEffect(() => {
    const dobLSItems = localStorage.getItem("showDobOnBillPrint");
    const paymentMethodLSItems = localStorage.getItem("showPaymentMethodOnBillPrint");
    if (dobLSItems) {
      setDobInfoOnPrint(JSON.parse(dobLSItems) as ClientDobOnPrint);
    }
    if (paymentMethodLSItems) {
      setPaymentMethodOnPrint(JSON.parse(paymentMethodLSItems));
    }
  }, []);

  const getPrintComponent = ({
    billId,
    buttonText,
    useBordered,
    customerNumber,
    addPrintCount = true,
    forceTemplate,
    onPrintClick,
    isForPreview = false
  }: {
    billId: number;
    buttonText: string;
    useBordered: boolean;
    customerNumber: string | null;
    addPrintCount?: boolean;
    forceTemplate?: string;
    onPrintClick?: () => void;
    isForPreview?: boolean;
  }) => (
    <PrintBill
      hidePaymentMethodOnPrint={hidePaymentMethodOnPrint}
      showDobOnPrint={showDobOnPrint}
      billId={billId}
      billData={filterBillSubItems}
      buttonText={buttonText}
      labelStyle={{
        fontSize: "0.95em",
        textTransform: "none"
      }}
      clientBalance={clientBalance}
      useBordered={useBordered}
      customerNumber={customerNumber}
      addPrintCount={addPrintCount}
      forceTemplate={forceTemplate}
      onPrintClick={onPrintClick}
      isForPreview={isForPreview}
      labTestUrl={labTestUrl}
    />
  );

  const [disableCreditNoteButton, setDisableCreditNoteButton] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (bill && bill.type === DocumentTypes.INVOICE) {
      (async () => {
        try {
          // data comes in this form: {8037: 6, 8038: 1}
          // where key is product id and value is creditable quantity
          // if creditable quantity is 0 for all keys, then disable credit button
          const data = await getCreditableQuantities(bill.id);
          if (data) {
            const creditableQuantityValues = Object.values(data);
            setDisableCreditNoteButton(creditableQuantityValues.every((value) => value <= 0));
          }
        } catch (err) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: err.message || "Failed to get creditable quantity for bill items.",
              autoTimeout: true
            })
          );
        }
      })();
    }
  }, [bill, dispatch]);

  return (
    <Box className={styles.root} display="flex" padding="16px 24px" alignItems="center">
      <BillStatusChip bill={bill} />
      {(!hideBillingActions || !isKitchenPharmacyBill) && (
        <Box justifySelf="flex-end" marginLeft="auto">
          {options[bill.status || "draft"].map((action) => {
            if (bill.type !== DocumentTypes.CREDITNOTE) {
              if (bill?.isProformaBill && bill.status === BillStatus.billed) {
                return null;
              }

              if (["credit", "copy"].includes(action) && isOldFormatBill(bill.document?.version)) {
                return null;
              }

              if (action === "credit" && disableCreditNoteButton) {
                return null;
              }

              if (hasCreditNote && ["cancel"].includes(action)) {
                return null;
              }
              if (
                action === "cancel" &&
                (!bill.created_at ||
                  (permissionGroup !== "resourceCentreAdmin" &&
                    isBillCreatedBefore24HoursAgo(bill)))
              ) {
                return (
                  <ActionButton
                    key={action}
                    bill={bill}
                    action={action}
                    actions={actions}
                    propActions={propActions}
                    helperText="Bill can only be cancelled before 24 hours of creation!"
                    disabled
                  />
                );
              }
              return (
                <ActionButton
                  onCancel={() => setShowCancelDialog(true)}
                  key={action}
                  bill={bill}
                  action={action}
                  actions={actions}
                  propActions={propActions}
                  disabled={loadingState}
                  setLoading={setLoadingState}
                />
              );
            }
            return null;
          })}
        </Box>
      )}
      <Box
        display="flex"
        justifyContent="flex-end"
        marginLeft={hideBillingActions ? "auto" : "16px"}
      >
        {isKitchenPharmacyBill && !bill.relatedPurchaseEntry && (
          <Box>
            <Button onClick={() => dispatch(push(`/stock/kitchen/purchase?billId=${bill.id}`))}>
              Create Purchase Entry
            </Button>
          </Box>
        )}
        <MultipleButtonGroup
          hidePaymentMethodOnPrint={paymentMethodOnPrint}
          setHidePaymentMethodOnPrint={setPaymentMethodOnPrint}
          showClientDobOnPrint={dobInfoOnPrint}
          setShowClientDobOnPrint={setDobInfoOnPrint}
          printFrom={PRINT_FROM.BILL}
          billData={bill}
          setFilterBillSubItems={setFilterBillSubItems}
          options={[
            {
              label: "Print Default",
              onClickAction: () => ({}),
              component: getPrintComponent({
                billId: bill.id,
                buttonText: "Print Default",
                useBordered: false,
                customerNumber: null,
                onPrintClick: () =>
                  mixpanelAnalytics.track(EVENT.PRINT_BILL_DEFAULT, {
                    rcId: rc.id,
                    rcName: rc.name
                  })
              }),
              previewComponent: getPrintComponent({
                billId: bill.id,
                buttonText: "Preview",
                useBordered: false,
                customerNumber: null,
                addPrintCount: false,
                isForPreview: true,
                onPrintClick: () =>
                  mixpanelAnalytics.track(EVENT.PRINT_PREVIEW_BILL_DEFAULT, {
                    rcId: rc.id,
                    rcName: rc.name
                  })
              })
            },
            {
              label: "Print Bordered",
              onClickAction: () => ({}),
              component: getPrintComponent({
                billId: bill.id,
                buttonText: "Print Bordered",
                useBordered: true,
                customerNumber: null,
                onPrintClick: () =>
                  mixpanelAnalytics.track(EVENT.PRINT_BILL_BORDERED, {
                    rcId: rc.id,
                    rcName: rc.name
                  })
              }),
              previewComponent: getPrintComponent({
                billId: bill.id,
                buttonText: "Preview",
                useBordered: true,
                customerNumber: null,
                addPrintCount: false,
                isForPreview: true,
                onPrintClick: () =>
                  mixpanelAnalytics.track(EVENT.PRINT_PREVIEW_BILL_BORDERED, {
                    rcId: rc.id,
                    rcName: rc.name
                  })
              })
            },
            {
              label: "Print A5",
              onClickAction: () => ({}),
              component: (
                <PrintBillHalf
                  showDobOnPrint={showDobOnPrint}
                  hidePaymentMethodOnPrint={hidePaymentMethodOnPrint}
                  billId={bill.id}
                  billData={filterBillSubItems}
                  buttonText="Print A5"
                  labelStyle={{
                    fontSize: "0.95em",
                    textTransform: "none"
                  }}
                  clientBalance={clientBalance}
                  addPrintCount
                  onPrintClick={() =>
                    mixpanelAnalytics.track(EVENT.PRINT_BILL_A5, { rcId: rc.id, rcName: rc.name })
                  }
                  labTestUrl={labTestUrl}
                />
              ),
              previewComponent: (
                <PrintBillHalf
                  showDobOnPrint={showDobOnPrint}
                  hidePaymentMethodOnPrint={hidePaymentMethodOnPrint}
                  billId={bill.id}
                  billData={filterBillSubItems}
                  buttonText="Preview"
                  labelStyle={{
                    fontSize: "0.95em",
                    textTransform: "none"
                  }}
                  isForPreview
                  clientBalance={clientBalance}
                  addPrintCount={false}
                  onPrintClick={() =>
                    mixpanelAnalytics.track(EVENT.PRINT_PREVIEW_BILL_A5, {
                      rcId: rc.id,
                      rcName: rc.name
                    })
                  }
                  labTestUrl={labTestUrl}
                />
              )
            },
            {
              label: "Print Bank Paper",
              onClickAction: () => ({}),
              component: (
                <BankPaperPrint
                  showDobOnPrint={showDobOnPrint}
                  hidePaymentMethodOnPrint={hidePaymentMethodOnPrint}
                  billId={bill.id}
                  billData={filterBillSubItems}
                  buttonText="Print Bank Paper"
                  labelStyle={{
                    fontSize: "0.95em",
                    textTransform: "none"
                  }}
                  clientBalance={clientBalance}
                  useBordered={false}
                  customerNumber={null}
                  forceTemplate={ForceTemplate.BANK_PAPER}
                  onPrintClick={() =>
                    mixpanelAnalytics.track(EVENT.PRINT_BILL_BANK_PAPER, {
                      rcId: rc.id,
                      rcName: rc.name
                    })
                  }
                  labTestUrl={labTestUrl}
                />
              ),
              previewComponent: (
                <BankPaperPrint
                  showDobOnPrint={showDobOnPrint}
                  hidePaymentMethodOnPrint={hidePaymentMethodOnPrint}
                  billId={bill.id}
                  billData={filterBillSubItems}
                  buttonText="Preview"
                  labelStyle={{
                    fontSize: "0.95em",
                    textTransform: "none"
                  }}
                  isForPreview
                  clientBalance={clientBalance}
                  useBordered={false}
                  customerNumber={null}
                  addPrintCount={false}
                  forceTemplate={ForceTemplate.BANK_PAPER}
                  onPrintClick={() =>
                    mixpanelAnalytics.track(EVENT.PRINT_PREVIEW_BILL_BANK_PAPER, {
                      rcId: rc.id,
                      rcName: rc.name
                    })
                  }
                  labTestUrl={labTestUrl}
                />
              )
            },
            {
              label: "80 mm Print",
              onClickAction: () => ({}),
              component: (
                <EightyMmPrint
                  showDobOnPrint={showDobOnPrint}
                  hidePaymentMethodOnPrint={hidePaymentMethodOnPrint}
                  billId={bill.id}
                  billData={filterBillSubItems}
                  buttonText="80 mm Print"
                  labelStyle={{
                    fontSize: "0.95em",
                    textTransform: "none"
                  }}
                  clientBalance={clientBalance}
                  useBordered={false}
                  customerNumber={null}
                  onPrintClick={() =>
                    mixpanelAnalytics.track(EVENT.PRINT_BILL_80MM, { rcId: rc.id, rcName: rc.name })
                  }
                  labTestUrl={labTestUrl}
                />
              ),
              previewComponent: (
                <EightyMmPrint
                  showDobOnPrint={showDobOnPrint}
                  hidePaymentMethodOnPrint={hidePaymentMethodOnPrint}
                  billId={bill.id}
                  billData={filterBillSubItems}
                  buttonText="Preview"
                  labelStyle={{
                    fontSize: "0.95em",
                    textTransform: "none"
                  }}
                  isForPreview
                  clientBalance={clientBalance}
                  useBordered={false}
                  customerNumber={null}
                  addPrintCount={false}
                  onPrintClick={() =>
                    mixpanelAnalytics.track(EVENT.PRINT_PREVIEW_BILL_80MM, {
                      rcId: rc.id,
                      rcName: rc.name
                    })
                  }
                  labTestUrl={labTestUrl}
                />
              )
            },
            ...(bill.client?.isCorporateClient
              ? [
                  {
                    label: "Corporate Print",
                    onClickAction: () => ({}),
                    component: (
                      <CorporateBillPrint
                        billId={bill.id}
                        billData={filterBillSubItems}
                        buttonText="Corporate Print"
                        labelStyle={{
                          fontSize: "0.95em",
                          textTransform: "none"
                        }}
                        onPrintClick={() =>
                          mixpanelAnalytics.track(EVENT.PRINT_BILL_CORPORATE, {
                            rcId: rc.id,
                            rcName: rc.name
                          })
                        }
                        addPrintCount
                        labTestUrl={labTestUrl}
                      />
                    ),
                    previewComponent: (
                      <CorporateBillPrint
                        billId={bill.id}
                        billData={filterBillSubItems}
                        buttonText="Preview"
                        labelStyle={{
                          fontSize: "0.95em",
                          textTransform: "none"
                        }}
                        addPrintCount={false}
                        onPrintClick={() =>
                          mixpanelAnalytics.track(EVENT.PRINT_PREVIEW_BILL_CORPORATE, {
                            rcId: rc.id
                          })
                        }
                        labTestUrl={labTestUrl}
                      />
                    )
                  }
                ]
              : [])
          ]}
        />
        {/* Only Allow Bill Cancellation if the paid Amount is 0 */}
        {/* Or Should clear all the payment history */}
        <OkhatiDialog
          open={showCancelDialog}
          title={tl("billing.action.cancelBillTitle")}
          description={
            <div>
              <Typography fontWeight="bold">Are you sure? Note that:</Typography>
              <Typography>- This action can not be reversed.</Typography>
              <Typography>- All receipts related to this bill will be cancelled too.</Typography>
              <Typography>
                - The bill/invoice number used in this bill can&apos;t be reused.
              </Typography>
              {bill?.document?.createdLabTestIds && bill.document.createdLabTestIds.length > 0 && (
                <Typography>
                  - Lab tests created for this bill will be cancelled as well.
                </Typography>
              )}

              <Box mt={2}>
                <TextField
                  label="Remarks"
                  placeholder="Cancellation remarks"
                  variant="outlined"
                  fullWidth
                  value={remarks}
                  onChange={(e) => {
                    setRemarks(e.target.value);
                  }}
                  slotProps={{
                    inputLabel: { shrink: true }
                  }}
                />
              </Box>
            </div>
          }
          disableConfirmBtn={!remarks.trim()}
          next={() => {
            dispatch(billActions.cancelBill(bill.id, remarks));
            // cancel the lab tests created for this bill
            bill?.document?.createdLabTestIds?.forEach((labTestId) => {
              // spelling wrong but it is how its used in the code
              dispatch(updateLabTestsRecordSample(labTestId, "Canceled"));
            });
            setShowCancelDialog(false);
            dispatch(navigateRemoveModal("Dialog"));
          }}
          cancel={() => {
            setShowCancelDialog(false);
          }}
          readMode={false}
        />
      </Box>
    </Box>
  );
};

export default connect(
  (state: RootState) => {
    const resourceCentre =
      (state.resources?.resourceCentres?.length && state.resources.resourceCentres[0]) ||
      state.userContext.resourceCentre;

    return {
      permissionGroup:
        state.userContext?.userCreds?.userGroups && state.userContext?.userCreds?.userGroups[0],
      isA5Template:
        resourceCentre?.settings.printSettings.billPrintSettings.template === "A5Template"
    };
  },
  (dispatch: IThunkDispatch) => ({
    actions: {
      onEdit: (bill) => {
        dispatch(push(`/billing/${bill.id}/edit`));
      },
      onCopy: async (bill) => {
        await dispatch(billActions.copyAsDraft(bill));
        if (bill.visitType === VisitType.IPD) {
          dispatch(push("/billing/ipd"));
        } else {
          dispatch(push("/billing/new"));
        }
      },
      onDelete: async (bill) => {
        await dispatch(
          showDialog({
            title: tl("billing.action.deleteDraftTitle"),
            description: tl("billing.action.deleteDraftDescription"),
            next: () => {
              dispatch(billActions.deleteDraft(bill.id));
              dispatch(navigateRemoveModal("Dialog"));
              dispatch(push("/billing/bills"));
            },
            onCancel: () => ({})
          })
        );
      },
      onPrint: () => {
        dispatch(push(""));
      },
      onCredit: async (bill) => {
        await dispatch(billActions.copyAsCredit(bill));
        if (bill.visitType === VisitType.IPD) {
          dispatch(push("/billing/ipd?tab=newBill"));
        } else {
          dispatch(push("/billing/new"));
        }
      }
    }
  })
)(BillActions);
