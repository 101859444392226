import * as React from "react";
import { Box, Checkbox, Typography } from "@mui/material";
import { tl } from "../../../components/translate";

interface FollowUp {
  value: boolean;
  onChange: (value) => void;
}

const FollowUp: React.FC<FollowUp> = ({ value, onChange }) => (
  <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
    <Typography>{tl("booking.followUp")}</Typography>
    <Checkbox
      style={{ height: "18px" }}
      checked={value}
      onChange={() => onChange(!value)}
      data-testmation="followUpCheck"
    />
  </Box>
);

export default FollowUp;
