import * as React from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { omit, startCase } from "lodash";
import { push, goBack } from "connected-react-router";
import Typography from "@mui/material/Typography";
import { bindActionCreators } from "redux";
import { Box, Button, Link } from "@mui/material";
import produce from "immer";
import { t, tl } from "../../../components/translate";
import List from "../../../components/List";
import fields, {
  extraFieldForAccountUser,
  getDepartmentOptions,
  getUserGroupOption
} from "../../../models/ResourceCentreEmployee";
import Form from "../../../components/Form";
import EmployeeInfo from "../../Employee/EmployeeShow";
import Authorize from "../../../auth/authorization";
import style from "../style.module.css";
import { resourceCentreEmployeeActions } from "../../../actions";
import * as NotificationActions from "../../../actions/notification";
import { removeGeneralErrorByKey } from "../../../actions/error";
import Panel from "../../../components/Panel";
import { employeeFullNameSelector } from "../../../reducers/employee";
import Can from "../../Policy/Can";
import { User } from "../../../interfaces/UserContext";
import { Employee, PermissionGroups, UserGroupTypes, UserMode } from "../../../interfaces/User";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { IThunkDispatch, RootState } from "../../../store";
import useCan from "../../../hooks/useCan";
import { ledgerFindBySubLedgerType } from "../../accounts/hooks";
import { SubLedgerTypes } from "../../../interfaces/Accounts";
import useIsAccountSubscribed from "../../../hooks/accounts";
import useGetDepartments from "../../../hooks/department";
import { SETTING_ROUTES } from "../ResourceCentreShow";
import { ALL_DEPARTMENT } from "./SPCreateEdit";
import { ListActions, Menu, MenuItem } from "../../../components/OList";
import CsvUploader from "../../../components/CsvUploader/CsvUploader";
import { employeeUploadColumns } from "../../../components/CsvUploader/csvHelpers";
import { batchUploadEmployee, batchUploadNonUserEmployee } from "../../../api/user";
import UserChip from "../../Modals/User/Chip";

interface AdminsProps {
  resourceCentre: ResourceCentre;
  editMode: boolean;
  createMode: boolean;
  employeeId: number;
  resourceCentreState: { selectedEmployee: number | null };
  user: User & { role: UserMode; permissionGroup: PermissionGroups };
  onRowClick: (id: number) => void;
  closeInfo: () => void;
  onClose: () => void;
  onSave: (data: Partial<Employee>, id: number, afterSave: (id: number) => void) => void;
  goBack: () => void;
  navigateTo: (path: string) => void;
  resourceCentreEmployees: Employee[];
  isEmployeeOnlyTab: boolean;
}

const RequiredEmployeeFormFields = (isEmployeeTab: boolean) => ({
  FirstName: "firstName",
  LastName: "lastName",
  ...(!isEmployeeTab && { UserGroup: "userGroup", Email: "email", Phone: "phone" })
});

function downloadEmployeeUpload() {
  window.open(
    "https://drive.google.com/file/d/1ZgqkpAodcEc9nZ4dRwZobAPZ4hefSnK4/view?usp=sharing",
    "_blank"
  );
}

const Admins: React.FC<AdminsProps> = ({
  resourceCentre,
  editMode,
  createMode,
  employeeId,
  resourceCentreState,
  user,
  onRowClick,
  closeInfo,
  onClose,
  onSave,
  goBack: goBackAction,
  navigateTo,
  resourceCentreEmployees,
  isEmployeeOnlyTab = false
}) => {
  const [state, setState] = React.useState({
    formData: {
      departmentId: ALL_DEPARTMENT
    },
    saveAttempted: false,
    errorsCount: {}
  });
  const dispatch = useDispatch();
  const isAccountSubscribed = useIsAccountSubscribed();
  const employeeLedger = ledgerFindBySubLedgerType(SubLedgerTypes.EMPLOYEE);

  let resourceCentreEmployee = {
    resourceCentreId: resourceCentre.id,
    departmentId: ALL_DEPARTMENT,
    ...(isAccountSubscribed && employeeLedger && createMode ? { ledgerId: employeeLedger.id } : {})
  } as Employee;

  const canChangePermission = useCan("clinic:changePermissionGroups", { superAdminPass: true });
  const resourceCentrePermissionGroups =
    useSelector((s: RootState) => s.resourceCentreUserGroups.collection) || [];
  const options = resourceCentrePermissionGroups
    .filter((group) => group.userGroupType === UserGroupTypes.resourceCentreEmployee)
    .sort((a, b) => a.id - b.id)
    .map((group) => ({
      value: group.name,
      label: startCase(group.name),
      description: group.description || "",
      isDepartmentGroup: group.isDepartment || false
    }));

  if (editMode) {
    let foundRcEmployee = resourceCentreEmployees.find((rce) => rce.id === Number(employeeId));

    if (foundRcEmployee) {
      foundRcEmployee = {
        ...foundRcEmployee,
        userGroup: foundRcEmployee.user?.userGroups ? foundRcEmployee.user.userGroups[0]?.name : "",
        departmentId: foundRcEmployee?.departmentId || ALL_DEPARTMENT
      };
    }
    resourceCentreEmployee = !isAccountSubscribed
      ? (omit(foundRcEmployee, [
          "openingBalance",
          "openingBalanceDate",
          "openingBalance",
          "ledgerId"
        ]) as Employee)
      : foundRcEmployee;
  }
  const { selectedEmployee } = resourceCentreState;

  const departments = useGetDepartments({
    showDeletedDepartments: false,
    resourceCentreId: resourceCentre.id
  });
  const departmentOptions = departments.map((dept) => ({
    value: dept.id,
    label: dept.name
  }));
  const departmentSubscription = resourceCentre?.subscriptions.features.department?.subscribed;
  const employeeFields = isAccountSubscribed
    ? [
        ...(departmentSubscription ? getDepartmentOptions(departmentOptions) : []),
        ...(canChangePermission
          ? getUserGroupOption(
              options,
              editMode && !state.formData
                ? resourceCentreEmployee?.departmentId
                : state.formData?.departmentId || null,
              isEmployeeOnlyTab
            )
          : []),
        ...fields({ isPhoneEmailRequired: !isEmployeeOnlyTab }),
        ...extraFieldForAccountUser,
        ...(employeeLedger
          ? [
              {
                key: "ledgerId",
                label: "ledger",
                value: "",
                editable: true,
                creatable: true,
                required: true,
                disabled: Boolean(resourceCentreEmployee.ledgerId),
                inputType: "select",
                options: [{ value: employeeLedger.id, label: employeeLedger.ledgerName }]
              }
            ]
          : [])
      ]
    : [
        ...(departmentSubscription ? getDepartmentOptions(departmentOptions) : []),
        ...(canChangePermission
          ? getUserGroupOption(
              options,
              editMode && !state.formData
                ? resourceCentreEmployee?.departmentId
                : state.formData?.departmentId || null,
              isEmployeeOnlyTab
            )
          : []),
        ...fields({ isPhoneEmailRequired: !isEmployeeOnlyTab })
      ];
  const showCreateButton = useCan("clinic:createAdmin", { superAdminPass: true });

  if (!resourceCentre) return null;

  const onChangeForm = (formData, errorsCount) => {
    setState({ ...state, formData, errorsCount });
  };

  const onAdminSave = () => {
    const size = Object.keys(state.errorsCount || {}).length;
    const isValid = Object.values(RequiredEmployeeFormFields(isEmployeeOnlyTab)).every((item) =>
      Object.keys(state.formData || {}).includes(item)
    );

    if (!state.formData || !isValid || size > 0) {
      setState({ ...state, saveAttempted: true });
    } else {
      const newFormData = produce(state.formData, (draft: Partial<Employee>) => {
        if (editMode) draft.id = resourceCentreEmployee.id;
      });
      onSave(
        {
          ...newFormData,
          ...(isEmployeeOnlyTab && { nonUserEmployee: true }),
          departmentId:
            newFormData.departmentId === ALL_DEPARTMENT ? null : newFormData.departmentId
        },
        resourceCentre.id,
        (id) => onRowClick(id)
      );
    }
  };

  const { twoFactorAuthentication } = resourceCentre?.subscriptions?.features || {};
  return (
    <div className={style.listContainer}>
      <List
        automation="employeeList"
        title={`${t("employee.employees")} (${resourceCentreEmployees?.length || 0})`}
        rowHeight={50}
        createLabel={
          isEmployeeOnlyTab ? tl("employee.createEmployeeOnly") : tl("employee.administrativeUser")
        }
        defaultSortColumn="name"
        hideCreateButton={!showCreateButton}
        columns={[
          {
            key: "name",
            label: tl("employee.name"),
            sortable: true,
            sortFunction: (a, b) =>
              `${a.firstName}${a.lastName}`.toLowerCase() >
              `${b.firstName}${b.lastName}`.toLowerCase()
                ? 1
                : -1,
            formatter: (emp) => (
              <Typography variant="body2">
                {employeeFullNameSelector({ firstName: emp.firstName, lastName: emp.lastName })}
              </Typography>
            )
          },
          departmentSubscription
            ? {
                key: "department",
                label: tl("department"),
                formatter: (emp) => (
                  <Typography variant="body2">
                    {departments.find((dept) => dept.id === emp?.departmentId)?.name || ""}
                  </Typography>
                )
              }
            : {
                key: "",
                label: ""
              },
          { key: "phone", label: tl("employee.phone") },
          { key: "email", label: tl("employee.email") },
          ...(twoFactorAuthentication?.subscribed
            ? [
                {
                  key: "twoFactorAuthentication",
                  label: "2FA",
                  formatter: (row) => <UserChip allowed={row?.user?.enforcedTwoFactorAuth} />
                }
              ]
            : [])
        ]}
        activeRow={selectedEmployee}
        data={resourceCentreEmployees}
        onRowClick={({ id }) => {
          setState({
            formData: { departmentId: ALL_DEPARTMENT },
            saveAttempted: false,
            errorsCount: {}
          });
          onRowClick(id);
        }}
        testmationLabel="employeeCreateAdmin"
        onCreateNew={
          Authorize(user).can("admin", "Employee")
            ? () =>
                navigateTo(
                  `/resourcecentres/${resourceCentre.id}/${
                    isEmployeeOnlyTab ? SETTING_ROUTES.NON_USER_EMPLOYEE : SETTING_ROUTES.EMPLOYEES
                  }/create`
                )
            : null
        }
      >
        <ListActions>
          {() => (
            <Menu>
              <CsvUploader
                buttonText="Upload employees (.csv)"
                columns={employeeUploadColumns}
                requiredFieldInfoText="First name, Last name, Phone and email are mandatory for employee upload."
                createDataApi={isEmployeeOnlyTab ? batchUploadNonUserEmployee : batchUploadEmployee}
                useBatchUpload
                runAfterSave={() =>
                  dispatch(
                    resourceCentreEmployeeActions.getResourceCentreEmployees({
                      resourceCentreId: resourceCentre.id
                    })
                  )
                }
                renderAdditionalInfo={() => (
                  <Typography
                    style={{
                      cursor: "pointer",
                      position: "fixed",
                      right: 20
                    }}
                  >
                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                    <Link onClick={downloadEmployeeUpload}>{tl("stock.viewSample")}</Link>
                  </Typography>
                )}
              />
              <MenuItem onClick={downloadEmployeeUpload}>{tl("stock.viewSample")}</MenuItem>
            </Menu>
          )}
        </ListActions>
      </List>
      {selectedEmployee && (
        <EmployeeInfo
          isEmployeeOnlyTab={isEmployeeOnlyTab}
          employeeId={selectedEmployee}
          handleClose={() => {
            navigateTo(
              `/resourcecentres/${resourceCentre.id}/${
                isEmployeeOnlyTab ? SETTING_ROUTES.NON_USER_EMPLOYEE : SETTING_ROUTES.EMPLOYEES
              }`
            );
            closeInfo();
          }}
        />
      )}

      {(createMode || editMode) && resourceCentreEmployee && (
        <Can policyAccessKey="clinic:createAdmin" superAdminPass>
          <Panel
            onClose={() => {
              onClose();
              goBackAction();
            }}
            title={createMode ? tl("employee.createEmployee") : tl("employee.updateEmployee")}
            footer={
              <Box display="flex" width="100%" justifyContent="space-between">
                <Box />
                <Box display="flex" justifyContent="space-between" alignItems="center" mr="32px">
                  <Button
                    onClick={() => {
                      onClose();
                      goBackAction();
                    }}
                    style={{ marginRight: "32px" }}
                    data-testmation="employeeCancel"
                  >
                    <Typography>{tl("cancel")}</Typography>
                  </Button>
                  <Button
                    form="adminForm"
                    type="submit"
                    color="primary"
                    variant="contained"
                    onClick={() => onAdminSave()}
                    data-testmation="employeeCreateSave"
                  >
                    {createMode ? `${t("Create")}` : "Save"}
                  </Button>
                </Box>
              </Box>
            }
          >
            <Box pl={5} pr={5} width="100%" className={style.adminForm}>
              <Form
                name="adminForm"
                disableFlex
                fields={employeeFields}
                data={resourceCentreEmployee}
                onChange={(formData, errorsCount) => {
                  onChangeForm(formData, errorsCount);
                }}
                showErrors={state.saveAttempted}
              />
            </Box>
          </Panel>
        </Can>
      )}
    </div>
  );
};

export default connect(
  (state: RootState) => ({
    permissionGroup: state.userContext?.userCreds?.userGroups[0],
    policies: state.userContext.policies || {}
  }),
  (dispatch: IThunkDispatch) => ({
    actions: bindActionCreators({ ...resourceCentreEmployeeActions }, dispatch),
    onSave: async (data, rcId, afterSave) => {
      dispatch(async (dispatchI, getState) => {
        if (data.id) {
          dispatchI(resourceCentreEmployeeActions.putResourceCentreEmployee(data));
          dispatchI(
            push(
              `/resourcecentres/${rcId}/${
                data?.nonUserEmployee ? SETTING_ROUTES.NON_USER_EMPLOYEE : SETTING_ROUTES.EMPLOYEES
              }`
            )
          );
        } else {
          await dispatchI(
            resourceCentreEmployeeActions.postResourceCentreEmployee(data, (employee: Employee) =>
              afterSave(employee.id)
            )
          );
          const currentState = getState();
          const hasError = currentState.error.find(
            (d) =>
              d &&
              typeof d !== "string" &&
              d.key === "POST_RESOURCECENTREEMPLOYEE" &&
              d.data.type === "UniqueViolation"
          );

          const userLimitError = currentState.error.find(
            ({ key, status }) => key === "POST_RESOURCECENTREEMPLOYEE" && status === 400
          );

          if (userLimitError) {
            dispatchI(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: tl("userLimitReached"),
                autoTimeout: true
              })
            );
            dispatchI(removeGeneralErrorByKey("POST_RESOURCECENTRESERVICEPROVIDER"));
          } else if (hasError) {
            dispatchI(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: tl("phoneNumberAlreadyExists"),
                autoTimeout: true
              })
            );
            dispatchI(removeGeneralErrorByKey("POST_RESOURCECENTREEMPLOYEE"));
          } else {
            dispatchI(
              NotificationActions.notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "success",
                message: tl("successAdminCreation"),
                autoTimeout: true
              })
            );
            dispatchI(
              push(
                `/resourcecentres/${rcId}/${
                  data?.nonUserEmployee
                    ? SETTING_ROUTES.NON_USER_EMPLOYEE
                    : SETTING_ROUTES.EMPLOYEES
                }`
              )
            );
            dispatch(
              resourceCentreEmployeeActions.getResourceCentreEmployees({ resourceCentreId: rcId })
            );
          }
        }
      });
    },
    navigateTo: (url) => {
      dispatch(push(url));
    },
    goBack: () => dispatch(goBack())
  })
)(Admins);
