import * as React from "react";
import {
  Box,
  FormGroup,
  Typography,
  TextField,
  Button,
  Checkbox,
  Link,
  Autocomplete,
  Grid2 as Grid,
  Alert
} from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import produce from "immer";
import { pick } from "lodash";
import { push } from "connected-react-router";
import { round } from "mathjs";
import { t, tl } from "../../../components/translate";
import NrsFormat from "../../../components/FormattingInput/NrsInput";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";
import { PaymentMethodSelect } from "../../Billing/Editor/BillSummary";
import Panel from "../../../components/Panel";
import * as stockActions from "../../../actions/stock";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import classNames from "../../../helpers/classNames";
import {
  PaymentInfoStateProps,
  paymentOptionsEnum,
  StockTransactions,
  Supplier,
  TransactionType,
  TransactionItemsInterface
} from "../../../interfaces/StockInterfaces";
import { IThunkDispatch, RootState } from "../../../store";
import { getTrnxHavingDue } from "../../../api/stock";
import { supplierActions } from "../../../actions";
import useIsAccountSubscribed from "../../../hooks/accounts";

const useStyles = makeStyles({
  footer: {
    display: "flex",
    flexDirection: "row",
    position: "fixed",
    right: "32px",
    paddingTop: "8px"
  },
  boldText: {
    fontWeight: 600,
    textAlign: "right",
    display: "flex",
    justifyContent: "flex-end"
  },
  tableHeader: {
    fontWeight: 700,
    fontSize: "14px"
  },
  tableRow: {
    fontWeight: 500,
    fontSize: "14px"
  }
});

enum Changer {
  AMOUNT = "amount",
  CHECK_BOX = "checkBox"
}

const checkForAccountSubscribed = (
  isAccountSubscribed,
  supplier,
  paymentMethods,
  selectedPaymentMethod
) => {
  if (!isAccountSubscribed) {
    return false;
  }
  return !supplier?.ledgerId || !paymentMethods[selectedPaymentMethod]?.ledgerId;
};

export interface StockRecordPaymentProps {
  entry?: StockTransactions;
  onClose: () => void;
  recordPayment: (paymentInfoState: PaymentInfoStateProps) => void;
  navigateTo: (url: string) => void;
  suppliers: Supplier[];
}
const checkChanger = (changer, selected) => (changer === Changer.AMOUNT ? true : selected);

const StockRecordPayment = ({
  entry,
  onClose,
  recordPayment,
  navigateTo,
  suppliers
}: StockRecordPaymentProps) => {
  const dispatch = useDispatch();
  const resourceCentreId = useSelector((state: RootState) => state.userContext.resourceCentreId);
  const styles = useStyles({});
  const [saving, setSaving] = React.useState(false);
  const [selectedSupplier, setSelectedSupplier] = React.useState<Supplier | null>(null);
  const [showAlert, setShowAlert] = React.useState<boolean>(false);
  const isAccountSubscribed = useIsAccountSubscribed();
  const paymentMethods = useSelector(
    (state: RootState) =>
      state.resources.resourceCentres[0]?.settings?.accountSettings?.modeOfPayment
  );
  React.useEffect(() => {
    if (!suppliers?.length) {
      dispatch(supplierActions.getSuppliers(resourceCentreId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (entry?.supplierId) {
      const supplier = suppliers.find((item) => item.id === Number(entry?.supplierId));
      setSelectedSupplier(supplier as Supplier);
    }
  }, [entry?.supplierId, suppliers]);

  const [paymentInfoState, setPaymentInfo] = React.useState<PaymentInfoStateProps>({
    supplierId: entry?.supplierId ? entry.supplierId : null,
    transactionId: entry ? entry.id : null,
    supplierInvoiceId: entry?.supplierInvoiceId || "",
    paidAmount: 0,
    advanceAmt: 0,
    remarks: "",
    paymentMethod: paymentOptionsEnum.cash,
    transactionDate: new Date(),
    transactionItems: []
  });
  React.useEffect(() => {
    if (selectedSupplier && [TransactionType.PURCHASE, TransactionType.PURCHASE_RETURN]) {
      (async () => {
        const response = await getTrnxHavingDue(selectedSupplier.id, {
          transactionType: entry?.transactionType || TransactionType.PURCHASE
        });
        if (response.length > 0) {
          /*
          --prioritizing the current bill.
          --put the current selected bill(having due amount) at the first
            so that the payment amount applied to it.
          --while making payment, the amount is applied from top-to-bottom
          */
          const sortedStockTransaction = [...response].sort((a, b) => b.id - a.id);
          const relatedTrx = sortedStockTransaction.find((trx) => trx.id === entry?.id);
          const filteredStockTransactions = relatedTrx?.id
            ? [relatedTrx, ...sortedStockTransaction.filter((trx) => trx.id !== entry?.id)]
            : sortedStockTransaction;
          setPaymentInfo({
            ...paymentInfoState,
            transactionItems: filteredStockTransactions.map(
              (item, index) =>
                ({
                  ...pick(item, [
                    "transactionType",
                    "resourceCentreId",
                    "id",
                    "supplierId",
                    "paidAll",
                    "totalAmount",
                    "paidAmount",
                    "supplierInvoiceId",
                    "dueAmount"
                  ]),
                  sNo: index,
                  isSelectedTrx: false,
                  currPaidAmt: 0
                } as TransactionItemsInterface)
            )
          });
        } else {
          setPaymentInfo({
            ...paymentInfoState,
            transactionItems: []
          });
        }
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSupplier, entry?.supplierId]);

  const paidAmtChangeHandler = (state: PaymentInfoStateProps, changer: Changer) => {
    let remainingAmt = state.paidAmount;
    setPaymentInfo(
      produce(state, (draft) => {
        draft.transactionItems.forEach((draftItem) => {
          if (checkChanger(changer, draftItem.isSelectedTrx)) {
            const diff = remainingAmt - draftItem.dueAmount;
            if (diff >= 0) {
              draftItem.currPaidAmt = draftItem.dueAmount;
              remainingAmt = diff;
            }
            if (diff < 0) {
              draftItem.currPaidAmt = remainingAmt;
              remainingAmt = 0;
            }
          }
          if (draftItem.currPaidAmt) {
            draftItem.isSelectedTrx = true;
          } else {
            draftItem.isSelectedTrx = false;
          }
          return draftItem;
        });
        const currentlyPaidAmt = draft.transactionItems.reduce(
          (acc, val) => Number(acc) + Number(val.currPaidAmt),
          0
        );
        draft.advanceAmt = round(draft.paidAmount - currentlyPaidAmt, 2);
      })
    );
  };

  const totalDueAmount = paymentInfoState.transactionItems.reduce(
    (acc, val) => acc + (Number(val.totalAmount) - Number(val.paidAmount) || 0),
    0
  );

  return (
    <Panel
      wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
      title="Record Payment"
      onClose={onClose}
      footer={
        <Box className={classNames(styles.footer)}>
          <Box mr={5}>
            <Button data-testmation="billing.recordPayment.cancel" onClick={onClose}>
              <Typography>{tl("cancel")}</Typography>
            </Button>
          </Box>
          <StatefulButton
            variant="contained"
            color="primary"
            data-testmation="billing.recordPayment.save"
            disabled={
              saving ||
              !paymentInfoState.paidAmount ||
              checkForAccountSubscribed(
                isAccountSubscribed,
                selectedSupplier,
                paymentMethods,
                paymentInfoState.paymentMethod
              ) ||
              !selectedSupplier
            }
            isLoading={saving}
            circularProgressProps={{ size: 16 }}
            onClick={async () => {
              setSaving(true);
              await recordPayment({
                ...paymentInfoState,
                transactionItems: paymentInfoState.transactionItems.filter(
                  (item) => item.isSelectedTrx
                )
              });
              setSaving(false);
              onClose();
            }}
          >
            <Typography>{tl("save")}</Typography>
          </StatefulButton>
        </Box>
      }
    >
      <Box
        p={4}
        // consistent with other info or form panels
        style={{
          height: `calc(100vh - 100px)`,
          overflowY: "auto"
        }}
      >
        <FormGroup>
          <Grid container spacing={1}>
            <Grid size={12}>
              {!entry?.supplierInvoiceId ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  options={suppliers.filter((supplier) => supplier.active)}
                  sx={{ width: "100%" }}
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <li {...props} key={option.id}>
                      {option.name}
                    </li>
                  )}
                  value={selectedSupplier}
                  onChange={(e, v) => {
                    if (typeof v === "string") return;
                    if (v) {
                      setSelectedSupplier(v);
                      setPaymentInfo({ ...paymentInfoState, supplierId: v.id });
                      if (isAccountSubscribed && !v?.ledgerId) {
                        setShowAlert(true);
                      } else {
                        setShowAlert(false);
                      }
                    } else {
                      setShowAlert(false);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label={tl("stock.recordPayment.supplier")}
                      margin="dense"
                      variant="outlined"
                      placeholder={t("stock.recordPayment.selectSupplier")}
                      slotProps={{
                        inputLabel: { shrink: true }
                      }}
                    />
                  )}
                />
              ) : (
                <TextField
                  disabled
                  label="Supplier invoice number"
                  value={paymentInfoState.supplierInvoiceId}
                  margin="dense"
                  variant="outlined"
                  onFocus={(e) => e.target.select()}
                  onChange={(e) => {
                    setPaymentInfo({ ...paymentInfoState, supplierInvoiceId: e.target.value });
                  }}
                  sx={{ width: "100%" }}
                />
              )}
              {showAlert && (
                <Box position="absolute" display="flex" justifyContent="center">
                  <Alert sx={{ zIndex: 999, marginTop: "-20px" }} severity="warning">
                    This supplier is not mapped with ledger.
                  </Alert>
                </Box>
              )}
            </Grid>
            <Grid size={12} sx={{ marginTop: "4px" }}>
              <Typography sx={{ fontWeight: 500, fontSize: "16px", lineHeight: "22px" }}>
                Payment Information
              </Typography>
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <TextField
                label={tl("billing.recordPayment.payment")}
                data-testmation="billing.recordPayment.payment"
                value={paymentInfoState?.paidAmount.toFixed(2)}
                margin="dense"
                variant="outlined"
                onFocus={(e) => e.target.select()}
                onChange={({ target }) => {
                  paidAmtChangeHandler(
                    { ...paymentInfoState, paidAmount: Number(target.value) },
                    Changer.AMOUNT
                  );
                }}
                slotProps={{
                  input: {
                    inputComponent: NrsFormat,
                    startAdornment: "रू",
                    style: {
                      textAlign: "right"
                    }
                  }
                }}
                sx={{ width: "100%" }}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 6 }}>
              <PaymentMethodSelect
                selectedPaymentMethod={paymentInfoState.paymentMethod}
                onPaymentMethodChange={(e, v) => {
                  if (!v) return;
                  setPaymentInfo({ ...paymentInfoState, paymentMethod: v });
                }}
                TxtFieldProps={{ label: tl("billing.paymentMethod"), fullWidth: true }}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 9 }}>
              <TextField
                label={tl("remarks")}
                placeholder={t("remarks")}
                data-testmation="billing.recordPayment.remarks"
                value={paymentInfoState.remarks}
                margin="dense"
                variant="outlined"
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  setPaymentInfo({ ...paymentInfoState, remarks: e.target.value });
                }}
                sx={{ width: "100%" }}
                slotProps={{
                  inputLabel: { shrink: true }
                }}
              />
            </Grid>
            <Grid size={{ xs: 12, md: 3 }}>
              <CalendarDropdown
                TextFieldProps={{
                  fullWidth: true,
                  variant: "outlined",
                  margin: "dense",
                  label: tl("billing.paymentDate")
                }}
                className="billActionCalendarPopper"
                date={paymentInfoState.transactionDate}
                onChange={(date) => setPaymentInfo({ ...paymentInfoState, transactionDate: date })}
                data-testmation="billing.recordPayment.calendarDropdown"
              />
            </Grid>
          </Grid>
        </FormGroup>
        {paymentInfoState.transactionItems.length > 0 && (
          <div>
            <Typography fontWeight={600} fontSize="14px" mt={4}>
              Payment will be recorded as below (Number of Open Transaction:
              {paymentInfoState.transactionItems.length})
            </Typography>
            <Typography mt={1} display="flex" justifyContent="space-between">
              Advance Payment
              <span>Rs {paymentInfoState.advanceAmt !== 0 ? paymentInfoState.advanceAmt : 0}</span>
            </Typography>
            <Typography mt={1} display="flex" justifyContent="space-between">
              Due Amount
              <span>Rs {round(totalDueAmount, 2)}</span>
            </Typography>
            <Grid container spacing={1} mt={4} mb={2}>
              <Grid size={{ sm: 1 }} />
              <Grid size={{ md: 4 }}>
                <Typography className={styles.tableHeader}>Due clearance of invoice</Typography>
              </Grid>
              <Grid size={{ md: 3 }}>
                <Typography className={styles.tableHeader} textAlign="right">
                  Due for invoice
                </Typography>
              </Grid>
              <Grid size={{ md: 3 }}>
                <Typography className={styles.tableHeader} textAlign="right">
                  Amount Payable
                </Typography>
              </Grid>
            </Grid>
            {paymentInfoState.transactionItems.map((item, index) => (
              <Box key={item.sNo}>
                <Grid container spacing={1}>
                  <Grid size={{ sm: 1 }}>
                    <Checkbox
                      size="small"
                      checked={item.isSelectedTrx}
                      onChange={() => {
                        const updatedPaymentState = produce(paymentInfoState, (draft) => {
                          const id = draft.transactionItems.findIndex((trx) => trx.sNo === index);
                          if (id !== -1) {
                            draft.transactionItems[id].isSelectedTrx =
                              !draft.transactionItems[id].isSelectedTrx;
                            if (!draft.transactionItems[id].isSelectedTrx) {
                              draft.transactionItems[id].currPaidAmt = 0;
                            }
                          }
                        });
                        paidAmtChangeHandler({ ...updatedPaymentState }, Changer.CHECK_BOX);
                      }}
                    />
                  </Grid>
                  <Grid size={{ md: 4 }}>
                    <Link
                      component="button"
                      onClick={() => navigateTo(`/stock/stockTransactions/${item.id}`)}
                      className={styles.tableRow}
                    >
                      {item.supplierInvoiceId}
                    </Link>
                  </Grid>
                  <Grid siz={{ md: 3 }}>
                    <Typography className={styles.tableRow} textAlign="right">
                      Rs: {item.dueAmount}
                    </Typography>
                  </Grid>
                  <Grid siz={{ md: 3 }}>
                    <Typography className={styles.tableRow} textAlign="right">
                      Rs: {item.currPaidAmt}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            ))}
          </div>
        )}
        <Box mt={5} display="flex" textAlign="end" justifyContent="flex-end">
          <div>
            <Typography fontWeight="bold">Total Amount:</Typography>
            <Typography>Rs {paymentInfoState.paidAmount}</Typography>
          </div>
        </Box>
      </Box>
    </Panel>
  );
};

StockRecordPayment.defaultProps = {
  entry: {}
} as { entry: StockTransactions };

export default connect(
  (state: RootState) => ({
    clients: state.clients.collection?.filter((client) => client.active),
    lastTouchedClient: state.clients.lastTouched,
    suppliers: state.resources?.suppliers
  }),
  (dispatch: IThunkDispatch) => ({
    recordPayment: (trxData) => {
      dispatch(stockActions.recordPayment(trxData));
    },
    navigateTo: (url) => dispatch(push(url))
  })
)(StockRecordPayment);
