import { Autocomplete, TextField } from "@mui/material";
import * as React from "react";
import { TestTypes } from "../../../interfaces/Lab";
import { toStartCase } from "../../../helpers/formatters";

interface Props {
  value: TestTypes;
  onChange: (value: TestTypes) => void;
}

const LabTestTypeSelector = ({ value, onChange }: Props): JSX.Element => (
  <Autocomplete
    disablePortal
    disableClearable
    fullWidth
    options={[TestTypes.PATHOLOGY, TestTypes.RADIOLOGY]}
    getOptionLabel={(option) => toStartCase(option)}
    value={value || TestTypes.PATHOLOGY}
    onChange={(_, newValue) => {
      onChange(newValue);
    }}
    renderInput={(params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        variant="outlined"
        label="Select Test Group Type"
        slotProps={{
          inputLabel: { shrink: true }
        }}
      />
    )}
  />
);

export default LabTestTypeSelector;
