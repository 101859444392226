import * as React from "react";
import { connect } from "react-redux";
import capitalize from "lodash/capitalize";
import { Box, Divider, Tooltip, Typography } from "@mui/material";
import * as moment from "moment";
import { chain, round } from "mathjs";
import CheckIcon from "@mui/icons-material/Check";
import startCase from "lodash/startCase";
import { push } from "connected-react-router";
import PrintIcon from "@mui/icons-material/Print";
import produce from "immer";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import MessageIcon from "@mui/icons-material/Message";
import { t, tl } from "../../../components/translate";
import { clientFullNameSelector } from "../../../reducers/client";
import BillItems from "../BillItemsShow";
import { rupeeDisplay } from "../../../helpers/rupee";
import { separateByComma } from "../../../helpers/formatters";
import * as calFns from "../../../components/Calendar/functions/calendarFunctions";
import BillActions from "../BillActions/BillActions";
import styles from "./BillShow.module.css";
import PaymentInfoAction from "./PaymentInfoAction";
import { loadBill as loadBillAction } from "../../../actions/bill";
import Panel from "../../../components/Panel";
import { BillStatus, BillType, DocumentTypes } from "../../../interfaces/BillInterfaces";
import HistoryItems from "../../History/HistoryItems";
import { StyledTab, StyledTabs } from "../../../components/StyledTabs";
import ClientInfo from "../../Client/ClientInfo/ClientInfo";
import ClientCreateEdit from "../../Client/ClientCreateEdit";

import PaymentHistoryPrint from "../PrintBill/PrintPaymentHistory/PrintPaymentHistory";
import CommissionPanel from "../Comission/ComissionPanel";
import { IThunkDispatch, RootState } from "../../../store";
import BillHistoryCancellation from "./BillHistoryCancellation";
import useLabIdsFromBills from "../../../hooks/useLabIdsFromBills";
import { getCustomerNumber } from "../../Client/ClientList";
import CopyToClipboard from "../../../components/ClipboardCopy/ClipboardCopy";
import { getAppBaseUrl } from "../../../helpers/urls";
import BillCreditNoteList from "../BillCreditNoteList";
import ParentBill from "../ParentBill";
import { INSURANCE_TYPE } from "../../../actions/helpers/billHelper";
import { getLabTestRecord } from "../../../api/labTest";
import { TestTypes } from "../../../interfaces/Lab";
import { DataComment } from "../../Lab/LabPrint/Templates/DefaultTemplate/DefaultTemplateCore";
import { hasValidRichTextRemark } from "../Editor/BilllEditorActions";

const Row = ({ label = "", value }: { label: React.ReactNode; value: React.ReactNode }) => (
  <Box display="flex">
    <Typography fontSize="12px" fontWeight={500} width={140}>
      {label}
    </Typography>
    <Typography fontSize="12px" fontWeight={600} flex={1}>
      {value}
    </Typography>
  </Box>
);

const switchTabRenderer = (activeTab, bill, setShowMessagePanel, isForKitchenPharmacy) => {
  switch (activeTab) {
    case "billItems":
      return bill.document ? (
        <Box className="billItemsShow">
          <BillItems bill={bill} />
        </Box>
      ) : null;
    case "billHistory":
      return (
        <Box>
          <HistoryItems entity="bill" entityId={bill.id} />
        </Box>
      );
    case "billCreditNotes":
      return (
        <Box className="creditNotesShow">
          <BillCreditNoteList
            setShowMessagePanel={setShowMessagePanel}
            billId={bill.id}
            isForKitchenPharmacy={isForKitchenPharmacy}
          />
        </Box>
      );
    default:
      throw new Error(`Unknown activeTab: ${activeTab}`);
  }
};

function filterCustomProducts(bill) {
  return produce(bill, (draft) => {
    if (draft.document.billItems) {
      draft.document.billItems = draft.document.billItems
        .filter((item) =>
          item.subItems
            ? item.subItems.some((subItem) => Boolean(subItem.productData))
            : Boolean(item.productData)
        )
        .map((item) =>
          item.subItems
            ? { ...item, subItems: item.subItems.filter((subItem) => Boolean(subItem.productData)) }
            : item
        );
    }
  });
}

interface Props {
  bill: BillType;
  billId: number;
  loadBill: (id: number, billData: BillType, isForKitchenPharmacy?: boolean) => void;
  handleViewClose: () => void;
  hideActions: boolean;
  isCommissionSubscribed: boolean;
  hideBillingActions: boolean;
  setShowMessagePanel: (value: boolean) => void;
  wrapperStyle?: { minWidth: string; maxWidth: string };
  billData: BillType;
  isForKitchenPharmacy?: boolean;
  hasCreditNote?: boolean;
}

const BillShow = ({
  bill,
  billId,
  loadBill,
  handleViewClose,
  hideActions,
  isCommissionSubscribed,
  hideBillingActions = false,
  setShowMessagePanel,
  wrapperStyle = { minWidth: "820px", maxWidth: "820px" },
  billData,
  isForKitchenPharmacy = false,
  hasCreditNote = false
}: Props) => {
  const [activeTab, setActiveTab] = React.useState("billItems");
  const [labTestUrl, setLabTestUrl] = React.useState("");
  const [showClientInfoPanel, setShowClientInfoPanel] = React.useState(false);
  const [clientEditMode, setClientEditMode] = React.useState(false);
  const [commissionOpen, setCommissionOpen] = React.useState(false);
  const [showParentBill, setShowParentBill] = React.useState(false);

  React.useEffect(() => {
    if (!bill?.document) {
      loadBill(billId, billData || null, isForKitchenPharmacy);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [billId, bill]);

  React.useEffect(() => {
    setShowParentBill(false);
  }, [billId]);

  // fetch the lab test if it has been created from bills
  React.useEffect(() => {
    (async () => {
      if (bill?.document?.createdLabTestIds?.length && bill?.resourceCentreId) {
        // fetch all the lab test
        const labTestRecords = await Promise.all(
          bill.document.createdLabTestIds.map((labTestId) =>
            getLabTestRecord(labTestId, bill.resourceCentreId)
          )
        );

        const records = labTestRecords.flat();

        let url = "";

        // eslint-disable-next-line no-restricted-syntax
        for (const r of records) {
          if (r.type === TestTypes.PATHOLOGY) {
            url = `https://okhati.com.np/my-lab/${r.uuid}`;
            break;
          } else if (!url) {
            url = `https://okhati.com.np/my-lab/${r.uuid}`;
          }
        }

        // Set the lab test URL if found
        if (url) {
          setLabTestUrl(url);
        }
      } else {
        setLabTestUrl("");
      }
    })();
  }, [billId, bill]);

  const labIdsForBill = useLabIdsFromBills(bill);
  if (!bill) {
    return null;
  }

  const summary = bill.document?.summary || bill.summary;
  const client = bill?.client || bill.document?.client;

  // do not show record payment link for bills
  // 1. if no receivable due is left, including credit note
  // 2. if it is a draft
  // 3. if it is in read only mode
  const maxReceivableDue = chain(bill.summary?.totalAmount)
    .add(bill.related?.summary?.totalAmount || 0)
    .subtract(bill.paymentInfo?.paidAmount || 0)
    .done();

  const hideRecordPayment = bill.document
    ? bill.status === BillStatus.cancelled ||
      Boolean(
        bill.isDraft ||
          hideActions ||
          bill.paymentInfo?.paid ||
          maxReceivableDue <= 0 ||
          bill?.isProformaBill
      )
    : true;

  return (
    <Panel
      onClose={handleViewClose}
      wrapperStyle={wrapperStyle}
      messageButton={
        bill.uuid && (
          <Tooltip title="Send Email" aria-label="message">
            <MessageIcon
              sx={{ cursor: "pointer" }}
              onClick={() => {
                setShowMessagePanel(true);
              }}
            />
          </Tooltip>
        )
      }
      genericButton={
        bill.uuid && (
          <CopyToClipboard
            TooltipProps={{
              title: "Bill link is copied to clipboard!"
            }}
          >
            {({ copy }) => (
              <Tooltip title="Copy link" aria-label="copylink">
                <FileCopyIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                    copy(`${getAppBaseUrl()}/my-bill/${bill.uuid}`);
                  }}
                />
              </Tooltip>
            )}
          </CopyToClipboard>
        )
      }
    >
      <Box className={styles.boxHeight}>
        {!hideActions && (
          <BillActions
            labTestUrl={labTestUrl}
            bill={bill}
            propActions={
              isCommissionSubscribed ? { onCommission: () => setCommissionOpen(true) } : {}
            }
            hideBillingActions={isForKitchenPharmacy ? true : hideBillingActions}
            isKitchenPharmacyBill={isForKitchenPharmacy}
            hasCreditNote={hasCreditNote}
          />
        )}
        <Box padding="24px 24px 32px 24px">
          {!bill.isDraft && !bill?.isProformaBill && (
            <Box display="flex" mt="16px">
              <Typography style={{ fontWeight: 600, width: "140px" }}>
                {bill.type === DocumentTypes.CREDITNOTE
                  ? tl("billing.creditNoteNo")
                  : tl("billing.billNumber")}
              </Typography>
              <Typography>{bill.billNumber}</Typography>
            </Box>
          )}

          <Box display="flex" mt="16px">
            <Typography style={{ fontWeight: 600, width: "140px" }}>Customer Name</Typography>
            <Typography
              style={{ cursor: "pointer", textDecoration: "underline" }}
              onClick={() => {
                setShowClientInfoPanel(true);
              }}
            >
              {clientFullNameSelector(client).toUpperCase()}
            </Typography>
          </Box>
          <Box display="flex">
            <Typography style={{ fontWeight: 600, width: "140px" }}>Customer No.</Typography>
            <Typography>{getCustomerNumber(bill.customerNumber)}</Typography>
          </Box>
          <Box display="flex">
            <Typography style={{ fontWeight: 600, width: "140px" }}>Phone</Typography>
            <Typography>{client.phone}</Typography>
          </Box>
          {client.email && (
            <Box display="flex">
              <Typography style={{ fontWeight: 600, width: "140px" }}>Email</Typography>
              <Typography>{client.email}</Typography>
            </Box>
          )}
          {(client.address || client.city) && (
            <Box display="flex">
              <Typography style={{ fontWeight: 600, width: "140px" }}>Address</Typography>
              <Typography>
                {separateByComma(capitalize(client.address), capitalize(client.city))}
              </Typography>
            </Box>
          )}
          {bill.insuranceNumber && (
            <Box display="flex">
              <Typography style={{ fontWeight: 600, width: "140px" }}>
                {tl("billing.insuranceNumber")}
              </Typography>
              <Typography>{bill.insuranceNumber}</Typography>
            </Box>
          )}
          {bill.claimNumber && (
            <Box display="flex">
              <Typography style={{ fontWeight: 600, width: "140px" }}>
                {tl("billing.claimNumber")}
              </Typography>
              <Typography>{bill.claimNumber}</Typography>
            </Box>
          )}

          {bill?.document?.ssf?.id && (
            <Box display="flex">
              <Typography style={{ fontWeight: 600, width: "140px" }}>
                {tl("billing.ssid")}
              </Typography>
              <Typography>{bill.document.ssf.id}</Typography>
            </Box>
          )}

          {bill?.document?.ssf?.id && bill?.document?.ssf?.scheme?.value && (
            <Box display="flex">
              <Typography style={{ fontWeight: 600, width: "140px" }}>
                {tl("billing.scheme")}
              </Typography>
              <Typography>{`${bill.document.ssf.scheme.label} (${bill.document.ssf.scheme.value})`}</Typography>
            </Box>
          )}

          {bill?.document?.ssf?.id && bill?.document?.ssf?.subProduct?.value && (
            <Box display="flex">
              <Typography style={{ fontWeight: 600, width: "140px" }}>
                {tl("billing.subScheme")}
              </Typography>
              <Typography>{`${bill.document.ssf.subProduct.label} (${bill.document.ssf.subProduct.value})`}</Typography>
            </Box>
          )}

          {bill?.document?.paymentInfo?.paymentDistribution?.paymentFromSsf && (
            <Box display="flex">
              <Typography style={{ fontWeight: 600, width: "140px" }}>
                {tl("billing.paymentFromSSF")}
              </Typography>
              <Typography>
                {rupeeDisplay(bill.document.paymentInfo.paymentDistribution.paymentFromSsf)}
              </Typography>
            </Box>
          )}

          <Box marginBottom="16px" marginTop="16px">
            <Row
              label={tl("billing.issueDate")}
              value={calFns.convertADtoBS(moment(bill.issueDate)).formatted4}
            />
            {bill.document?.referredBy && (
              <Row label="Referrer" value={bill.document.referredBy.toUpperCase()} />
            )}
            {bill.document?.extraReferrer && (
              <Row label="Extra Referrer" value={bill.document.extraReferrer.name.toUpperCase()} />
            )}

            {labIdsForBill && <Row label={tl("billing.labId")} value={labIdsForBill} />}

            {bill.referenceTo && (
              <Row
                label={
                  bill.type === DocumentTypes.CREDITNOTE
                    ? tl("billing.creditNoteForBill")
                    : tl("billing.creditNoteBill")
                }
                value={
                  <Typography
                    style={{ cursor: "pointer", textDecoration: "underline", fontSize: "12px" }}
                    onClick={() => {
                      if (bill.type === DocumentTypes.CREDITNOTE) {
                        setShowParentBill(true);
                      }
                    }}
                  >
                    {bill.related?.billNumber}
                  </Typography>
                }
              />
            )}
            <>
              {Boolean(summary.taxAmount) && (
                <Row label={tl("billing.taxAmount")} value={rupeeDisplay(summary.taxAmount)} />
              )}
              {Boolean(summary.discount) && (
                <Row
                  label={tl("billing.discount")}
                  value={
                    summary.discountType === "percent"
                      ? `${summary.discountPct} %`
                      : rupeeDisplay(summary.discount)
                  }
                />
              )}
              <Row label={tl("billing.total")} value={rupeeDisplay(bill.total)} />
              <Row label={tl("billing.inWords")} value={summary.inWords} />
              {bill?.paymentInfo?.paymentMethod && (
                <Row
                  label={tl("billing.paymentMethod")}
                  value={startCase(bill.paymentInfo?.paymentMethod)}
                />
              )}

              {!bill.isDraft && (!bill.paymentInfo || bill.paymentInfo?.paid === true) && (
                <Row label={tl("billing.label.paidAll")} value={<CheckIcon color="primary" />} />
              )}
              {bill.type !== DocumentTypes.CREDITNOTE &&
                bill.insuranceType !== INSURANCE_TYPE.SSF && (
                  <>
                    {!Number.isNaN(bill.paymentInfo?.paidAmount) &&
                      Boolean(
                        chain(summary.totalAmount)
                          .subtract(bill.paymentInfo?.paidAmount || 0)
                          .done()
                      ) && (
                        <Row
                          label={tl("billing.dueAmount")}
                          value={rupeeDisplay(
                            round(
                              chain(summary.totalAmount)
                                .subtract(bill.paymentInfo?.paidAmount || 0)
                                .done(),
                              2
                            )
                          )}
                        />
                      )}
                    {!Number.isNaN(maxReceivableDue) && bill.related && (
                      <Row
                        label={tl("billing.dueAmountAfterCreditNote")}
                        value={rupeeDisplay(maxReceivableDue < 0 ? 0 : maxReceivableDue)}
                      />
                    )}
                    {!Number.isNaN(bill.paymentInfo?.paidAmount) && (
                      <>
                        <Row
                          label={tl("billing.paidAmount")}
                          value={rupeeDisplay(
                            bill.paymentInfo?.paidAmount ||
                              (!bill.paymentInfo?.paidAmount && bill.paymentInfo?.paid
                                ? summary.totalAmount
                                : 0)
                          )}
                        />

                        {!hideRecordPayment && (
                          <Row label="" value={<PaymentInfoAction showDisc bill={bill} />} />
                        )}
                      </>
                    )}
                  </>
                )}
              {bill.type !== DocumentTypes.CREDITNOTE &&
                bill.insuranceType === INSURANCE_TYPE.SSF && (
                  <>
                    {Number(bill.paymentInfo?.paymentDistribution?.additionalPayment) +
                      Number(bill.paymentInfo?.paymentDistribution?.paymentFromSsf) !==
                      Number(summary.totalAmount) && (
                      <Row
                        label={tl("billing.dueAmount")}
                        value={rupeeDisplay(
                          round(
                            chain(summary.totalAmount)
                              .subtract(bill.paymentInfo?.paymentDistribution?.paymentFromSsf || 0)
                              .done(),
                            2
                          )
                        )}
                      />
                    )}
                    {!Number.isNaN(maxReceivableDue) && bill.related && (
                      <Row
                        label={tl("billing.dueAmountAfterCreditNote")}
                        value={rupeeDisplay(maxReceivableDue < 0 ? 0 : maxReceivableDue)}
                      />
                    )}
                    {!Number.isNaN(bill.paymentInfo?.paidAmount) && (
                      <>
                        <Row
                          label={tl("billing.paidAmount")}
                          value={rupeeDisplay(
                            bill.paymentInfo?.paidAmount ||
                              (!bill.paymentInfo?.paidAmount && bill.paymentInfo?.paid
                                ? summary.totalAmount
                                : 0)
                          )}
                        />
                        {!hideRecordPayment && (
                          <Row label="" value={<PaymentInfoAction showDisc bill={bill} />} />
                        )}
                      </>
                    )}
                  </>
                )}
              {Boolean(bill?.paymentInfo?.tenderAmount) && (
                <>
                  <Row
                    label={tl("billing.tenderAmount")}
                    value={rupeeDisplay(bill.paymentInfo.tenderAmount.toFixed(2))}
                  />
                  <Row
                    label={tl("billing.changeAmount")}
                    value={rupeeDisplay(bill.paymentInfo.changeAmount.toFixed(2))}
                  />
                </>
              )}
              {Boolean(bill.receipts) && Boolean(bill.receipts.length) && (
                <>
                  <Row
                    label={tl("billing.receiptHistory")}
                    value={[...bill.receipts]
                      .sort(
                        (a, b) =>
                          new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
                      )
                      .map(
                        (
                          { id, receivedOn, paidAmount, paymentMethod, remarks, cancelled },
                          idx
                        ) => (
                          <Box mb={1} key={id}>
                            {idx ? <Divider /> : ""}
                            <Box
                              marginTop="10px"
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Box flexBasis={200} fontWeight={500}>
                                {rupeeDisplay(round(paidAmount, 2))}
                              </Box>
                              <Box flexBasis={150} fontWeight={500}>
                                {calFns.convertADtoBS(moment(receivedOn)).formatted4}
                              </Box>
                              <Box flexBasis={150} fontWeight={500}>
                                {(paymentMethod || "").toUpperCase()}
                              </Box>
                              <Box flexBasis={100} fontWeight={500}>
                                <PaymentHistoryPrint
                                  billData={bill}
                                  receivedOn={receivedOn}
                                  paidAmount={paidAmount}
                                  paymentMethod={paymentMethod}
                                  remarks={remarks}
                                  buttonText={
                                    <PrintIcon htmlColor="black" style={{ cursor: "pointer" }} />
                                  }
                                  mode="recordPayment"
                                />
                              </Box>
                              <Box width="100px">
                                <BillHistoryCancellation
                                  paymentMethod={paymentMethod}
                                  billStatus={bill.status}
                                  billType={bill.type}
                                  cancelled={cancelled}
                                  receiptInfo={{
                                    receiptId: id,
                                    paidAmount,
                                    billId: bill.id,
                                    paymentMethod,
                                    receivedOn: new Date().toISOString()
                                  }}
                                />
                              </Box>
                            </Box>
                            <Box flexBasis={200} fontWeight={500}>
                              {remarks}
                            </Box>
                          </Box>
                        )
                      )}
                  />
                </>
              )}
            </>
          </Box>
          {hasValidRichTextRemark(bill.document?.remarks) && (
            <>
              <Divider sx={{ marginBottom: "10px" }} />

              <Box display="flex">
                <Typography fontSize="12px" fontWeight={500} width={140}>
                  {t("billing.remarks")}
                </Typography>
                <Typography fontSize="12px" fontWeight={600} flex={1}>
                  <DataComment comment={bill.document.remarks} />
                </Typography>
              </Box>
            </>
          )}
          <StyledTabs value={activeTab} onChange={(e, tab) => setActiveTab(tab)}>
            <StyledTab label={t("bill.billItems")} value="billItems" />
            <StyledTab label={t("bill.history")} value="billHistory" />
            <StyledTab label={t("bill.creditNotes")} value="billCreditNotes" />
          </StyledTabs>
          {switchTabRenderer(activeTab, bill, setShowMessagePanel, isForKitchenPharmacy)}
        </Box>
      </Box>
      {showClientInfoPanel &&
        (clientEditMode ? (
          <ClientCreateEdit
            setEditMode={setClientEditMode}
            clientId={client.id}
            mode="edit"
            onCancel={() => {
              setClientEditMode(false);
            }}
            stayOnCurrentPage
          />
        ) : (
          <ClientInfo
            wrapperStyle={{ minWidth: "645px", maxWidth: "645px" }}
            id={client.id}
            handleViewClose={() => setShowClientInfoPanel(false)}
            setEditMode={setClientEditMode}
            stayOnCurrentPage
          />
        ))}
      {commissionOpen && (
        <CommissionPanel
          selectedBill={filterCustomProducts(bill)}
          handleClose={() => setCommissionOpen(false)}
        />
      )}

      {showParentBill && (
        <ParentBill
          bill={bill}
          setShowParentBill={setShowParentBill}
          isForKitchenPharmacy={isForKitchenPharmacy}
          setShowMessagePanel={setShowMessagePanel}
        />
      )}
    </Panel>
  );
};

export default connect(
  (state: RootState, ownProps: { billId: number }) => ({
    clients: state.clients.collection,
    bill: state.bills.collection.find(({ id }) => id === ownProps.billId),
    isCommissionSubscribed:
      state.userContext?.resourceCentre.subscriptions.features.commission.subscribed
  }),
  (dispatch: IThunkDispatch) => ({
    loadBill: (billId, billData, isForKitchenPharmacy) =>
      dispatch(loadBillAction(billId, billData, isForKitchenPharmacy)),
    navigateTo: (url) => dispatch(push(url))
  })
)(BillShow);
