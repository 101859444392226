import * as React from "react";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import useCurrentResourceCentre from "./hooks/useCurrentResourceCentre";
import { useAppSelector } from "./store/hooks";

const StyleWrapper = ({ children }: { children: React.ReactNode }): React.JSX.Element => {
  const userContext = useAppSelector((state) => state?.userContext);
  const rc = useCurrentResourceCentre();
  const lookAndFeel =
    userContext?.userCreds?.userPreferences?.lookAndFeel ||
    rc?.settings.lookAndFeel ||
    userContext?.resourceCentre?.settings.lookAndFeel;
  const theme = createTheme({
    typography: {
      fontFamily: "'Noto Sans Display', 'Poppins', sans-serif",
      fontSize: 12,
      fontWeightLight: 400,
      fontWeightRegular: 500,
      fontWeightMedium: 600
    },
    palette: {
      primary: {
        main: `${lookAndFeel ? lookAndFeel.primaryColour : "#009654"}`,
        light: `${lookAndFeel ? lookAndFeel.primaryColour : "#C8E6C9"}`,
        dark: `${lookAndFeel ? lookAndFeel.primaryColour : "#007d46"}`,
        contrastText: "#FFFFFF"
      },
      secondary: {
        main: `${lookAndFeel ? lookAndFeel.primaryColour : "#4CAF50"}`,
        light: `${lookAndFeel ? lookAndFeel.primaryColour : "#0066ff"}`,
        dark: `${lookAndFeel ? lookAndFeel.primaryColour : "#4CAF50"}`,
        contrastText: `${lookAndFeel ? lookAndFeel.primaryColour : "#ffcc00"}`
      },
      text: {
        primary: "#454545",
        secondary: "#454545"
      }
    },
    components: {
      MuiDrawer: {
        styleOverrides: {
          paper: {
            backgroundColor: `${lookAndFeel ? lookAndFeel.primaryColour : "#009654"}`
          }
        }
      },
      MuiDialog: {
        styleOverrides: {
          paper: {
            borderRadius: "15px"
          },
          root: {
            borderRadius: "15px"
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: "standard",
          size: "small"
        }
      },
      MuiFormControl: {
        defaultProps: {
          variant: "standard"
        }
      },
      MuiSelect: {
        defaultProps: {
          variant: "standard"
        }
      }
    },
    breakpoints: {
      values: {
        xs: 0,
        sm: 768,
        md: 960,
        lg: 1280,
        xl: 1920
      }
    }
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StyledEngineProvider>
  );
};

export default StyleWrapper;
