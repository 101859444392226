/* eslint-disable no-nested-ternary */
import React, { useState } from "react";
import {
  Box,
  Typography,
  TextField,
  Select,
  FormControlLabel,
  Checkbox,
  Autocomplete
} from "@mui/material";
import produce from "immer";
import { EditorState } from "prosemirror-state";
import { connect } from "react-redux";
import { DOMParser as PDOMParser } from "prosemirror-model";
import { makeStyles } from "@mui/styles";
import styles from "../Lab.module.css";
import classnames from "../../../helpers/classNames";
import {
  getGender,
  createMarkup,
  AdvancedLabRangeHandler,
  advancedLabRangeProcessor
} from "../LabPrint/LabPrintFunctions";
import Modal from "../../../components/Modal/Modal";
import useProseMirror from "../../../components/RichTextfield/useProsemirror";
import {
  customSchema,
  deserializeNode,
  proseMirrorOptions,
  RichTextfield
} from "../../../components/RichTextfield";
import EditableTable from "../../../components/EditableTable/EditableTable";
import hasOwnProperty from "../../../helpers/object";

interface LabTestSubRowInterface {
  status: string;
  test: Record<string, unknown>;
  subTest?: boolean;
  onSubRowChange: (newSubRow) => void;
  children?: any;
  gender: "1" | "2";
  index: any;
  printMode: boolean;
  disabled: boolean;
  additionallabdata: boolean;
  setAdditionalData: any;
  pl?: Record<string, unknown>;
  dob: string;
  showMethods: boolean;
}

const printRowStyles = {
  display: "flex",
  height: "40px",
  alignItems: "center"
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const CustomEl = ({ data }): JSX.Element => (
  // eslint-disable-next-line react/no-danger
  <div dangerouslySetInnerHTML={createMarkup(data)} style={{ fontSize: "12px" }} />
);

export function useParsedProseMirror(
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  document,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  options,
  schema = customSchema
): [EditorState<any>, React.Dispatch<React.SetStateAction<EditorState<any>>>] {
  const parser = new DOMParser();
  const doc = PDOMParser.fromSchema(schema).parse(
    parser.parseFromString(`${document}`, "text/html").documentElement,
    { preserveWhitespace: true }
  );
  const [state, setState] = useProseMirror({
    ...options,
    doc
  });
  return [state, setState];
}

function AdditionalData({ lt, onSubRowChange }): JSX.Element {
  const [openModal, setOpenModal] = useState(false);
  const [state, setState] = useParsedProseMirror(lt.additionalTestData || "", proseMirrorOptions);
  const additionalDataView = (
    <Box>
      <Typography>Additional Test Data</Typography>
      <RichTextfield state={state} setState={setState} />
    </Box>
  );

  return (
    <>
      <span
        role="button"
        tabIndex={0}
        onKeyDown={() => setOpenModal(true)}
        onClick={() => setOpenModal(true)}
        style={{ textDecoration: "underline", cursor: "pointer" }}
      >
        {" "}
        More
      </span>
      <Modal
        title="Additional Test Data"
        open={openModal}
        onClose={() => setOpenModal(false)}
        onSave={() => {
          const mutatedLt = produce(lt, (draft) => {
            // eslint-disable-next-line no-param-reassign
            draft.additionalTestData = deserializeNode(state.doc);
          });
          onSubRowChange(mutatedLt);
          setOpenModal(false);
        }}
      >
        {additionalDataView}
      </Modal>
    </>
  );
}

const useStyles = makeStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#ffb02a"
      },
      "&:hover fieldset": {
        borderColor: "#ffb02a"
      },
      "&.Mui-focused fieldset": {
        borderColor: "#ffb02a"
      }
    }
  }
});

const LabTestSubRow: React.FC<LabTestSubRowInterface> = ({
  test,
  onSubRowChange,
  children,
  gender,
  index,
  printMode,
  disabled,
  additionallabdata,
  pl = {},
  showMethods,
  dob
}) => {
  const [lt, setLt] = React.useState<any>(test);
  const [showViewButton, setViewButton] = React.useState(false);
  const additionalTestData = lt?.additionalTestData;
  const clientGender = getGender(lt, gender);
  const classes = useStyles();

  React.useEffect(() => {
    setLt(test);
  }, [test]);

  React.useEffect(() => {
    if (!additionallabdata && lt?.additionalTestData) {
      const mutatedLt = produce(lt, (draft) => {
        // eslint-disable-next-line no-param-reassign
        draft.additionalTestData = {};
      });
      onSubRowChange(mutatedLt);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lt?.additionalTestData]);

  const getRanges = () => {
    const range = advancedLabRangeProcessor(lt.ranges, Number(clientGender), dob);
    if (typeof range === "string" && range !== "") {
      const [min, max] = range.split("-");
      return [parseFloat(min), parseFloat(max)];
    }
    return [];
  };

  const [minRange, maxRange] = getRanges();

  const withinRange =
    lt.formData.reading && minRange && maxRange
      ? parseFloat(lt.formData.reading) >= minRange && parseFloat(lt.formData.reading) <= maxRange
      : true;

  const showInterpretationTemplate = (template: {
    nodes: Array<Array<{ x: number; y: number; data: string }>>;
    title: string;
  }) => {
    if (
      template.nodes &&
      Array.isArray(template.nodes) &&
      template.nodes.length > 0 &&
      template.nodes[0].length > 0
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      {lt && Object.keys(lt).length > 0 && (
        <>
          <Box display="flex" alignItems="center">
            <Box
              className={styles.fifthWidthCent}
              onMouseEnter={() => setViewButton(true)}
              onMouseLeave={() => setViewButton(false)}
            >
              <Typography>
                <Box component="span" style={pl}>
                  {lt.name}
                  {showViewButton && additionallabdata && !disabled && additionalTestData && (
                    <AdditionalData lt={lt} onSubRowChange={onSubRowChange} />
                  )}
                </Box>
              </Typography>
            </Box>
            {(!(lt?.subTests?.length > 0) || lt.info) && hasOwnProperty(lt, "formData") && (
              <Box className={styles.twenWidthCent} style={printMode ? printRowStyles : {}}>
                {printMode ? (
                  <Box display="flex" justifyContent="center" width="100%">
                    {lt.formData.reading}
                  </Box>
                ) : lt.info?.inputType === "select" ? (
                  <Select
                    native
                    fullWidth
                    disabled={disabled}
                    margin="dense"
                    variant="outlined"
                    value={lt.formData.reading}
                    InputProps={{ classes: { input: styles.rowInputRoot } }}
                    onChange={(e) => {
                      const mutatedLt = produce(lt, (draft) => {
                        // eslint-disable-next-line no-param-reassign
                        draft.formData.reading = e.target.value;
                      });
                      onSubRowChange(mutatedLt);
                    }}
                    data-testmation={`selectFlag${index}`}
                    // eslint-disable-next-line react/jsx-no-duplicate-props
                    inputProps={{ className: "labTestReading" }}
                  >
                    {lt.info.options.map((option, idx) => (
                      <option value={option} data-testmation={`${option}${idx}`} key={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                ) : lt.info?.inputType === "autocomplete" ? (
                  <div>
                    <Autocomplete
                      freeSolo
                      value={lt.formData.reading}
                      onChange={(_, v) => {
                        const mutatedLt = produce(lt, (draft) => {
                          // eslint-disable-next-line no-param-reassign
                          draft.formData.reading = v;
                        });
                        onSubRowChange(mutatedLt);
                      }}
                      onBlur={(e) => {
                        const mutatedLt = produce(lt, (draft) => {
                          // eslint-disable-next-line no-param-reassign
                          draft.formData.reading = e.target.value;
                        });
                        onSubRowChange(mutatedLt);
                      }}
                      options={lt.info?.options || []}
                      renderInput={(params) => (
                        <TextField
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...params}
                          label="Select or Type"
                          margin="dense"
                          variant="outlined"
                        />
                      )}
                    />
                  </div>
                ) : (
                  <TextField
                    className={classnames({ [classes.root]: !withinRange })}
                    disabled={disabled}
                    margin="dense"
                    variant="outlined"
                    value={lt.formData.reading}
                    slotProps={{
                      input: {
                        classes: {
                          input: styles.rowInputRoot
                        }
                      },
                      htmlInput: {
                        className: "labTestReading"
                      }
                    }}
                    onChange={(e) => {
                      const mutatedLt = produce(lt, (draft) => {
                        // eslint-disable-next-line no-param-reassign
                        draft.formData.reading = e.target.value;
                      });
                      onSubRowChange(mutatedLt);
                    }}
                    onFocus={(e) => e.target.select()}
                    data-testmation={`readingField${index}`}
                  />
                )}
              </Box>
            )}
            {/* {!(lt?.subTests?.length > 0) && lt.hasOwnProperty('formData') && (
            <Box className={styles.twenWidthCent} style={printMode ? printRowStyles : {}}>
              {printMode ? (
                <Box display={'flex'} justifyContent={'center'} width={'100%'}>
                  {lt.formData.flag}
                </Box>
              ) : (
                <Select
                  disabled={disabled}
                  margin="dense"
                  variant="outlined"
                  value={lt.formData.flag}
                  onChange={async (e) => {
                    await setLt({ ...lt, formData: { ...lt.formData, flag: e.target.value } });
                  }}
                  onBlur={() => onSubRowChange(lt)}
                  data-testmation={`selectFlag${index}`}
                >
                  <MenuItem value={'normal'} data-testmation={`normalFlag${index}`}>
                    Normal
                  </MenuItem>
                  <MenuItem value={'abnormal'} data-testmation={`abnormalFlag${index}`}>
                    Abnormal
                  </MenuItem>
                  <MenuItem value={'extreme'} data-testmation={`extremeFlag${index}`}>
                    Extreme
                  </MenuItem>
                </Select>
              )}
            </Box>
          )} */}
            {!(lt?.subTests?.length > 0) && hasOwnProperty(lt, "unit") && (
              <Box className={styles.twenWidthCent} p="0px 12px">
                <Typography>{lt.unit}</Typography>
              </Box>
            )}
            {!(lt?.subTests?.length > 0) && hasOwnProperty(lt, "ranges") && lt.ranges && (
              <Box className={styles.twenWidthCent}>
                <Typography style={{ marginLeft: "20px", paddingTop: "5px" }}>
                  <AdvancedLabRangeHandler
                    range={lt.ranges}
                    gender={Number(clientGender)}
                    dob={dob}
                  />
                </Typography>
              </Box>
            )}
            {showMethods && !(lt?.subTests?.length > 0) && hasOwnProperty(lt, "methods") && (
              <Box className={styles.twenWidthCent} p="0px 12px">
                <Typography>{lt.methods}</Typography>
              </Box>
            )}
          </Box>
          {lt.info?.interpretationTemplate &&
            showInterpretationTemplate(lt.info.interpretationTemplate) && (
              <Box mb="16px">
                <FormControlLabel
                  control={
                    <Checkbox
                      defaultChecked
                      onChange={(e) => {
                        const mutatedLt = produce(lt, (draft) => {
                          // eslint-disable-next-line no-param-reassign
                          if (e.target.checked) {
                            draft.info.interpretationTemplate.show = true;
                          } else {
                            draft.info.interpretationTemplate.show = false;
                          }
                        });
                        onSubRowChange(mutatedLt);
                      }}
                      name="checkedA"
                    />
                  }
                  label="Show Template"
                />
                <EditableTable
                  maxColCount={10}
                  onChange={(v) => {
                    const mutatedLt = produce(lt, (draft) => {
                      // eslint-disable-next-line no-param-reassign
                      draft.info.interpretationTemplate = v;
                    });
                    onSubRowChange(mutatedLt);
                  }}
                  initValue={lt.info?.interpretationTemplate}
                />
              </Box>
            )}
        </>
      )}
      {children}
    </div>
  );
};

export default connect((state: any) => {
  const resourceCentre =
    state.resources.resourceCentres.find((rc) => rc.id === state.userContext.resourceCentreId) ||
    state.userContext.resourceCentre;
  const additionallabdata: boolean = resourceCentre?.labSettings?.additionallabdata;
  const showMethods: boolean = resourceCentre?.labSettings?.showMethods;
  return {
    additionallabdata,
    showMethods
  };
}, null)(LabTestSubRow);
