import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { Fragment } from "react";
import EditIcon from "@mui/icons-material/Edit";
import moment from "moment";
import { convertADtoBS } from "../../../components/Calendar/functions/calendarFunctions";
import Panel from "../../../components/Panel";
import { tl } from "../../../components/translate";
import { formatVoucherDisplay } from "./AccountingVoucherSeriesList";
import EditAccountingVoucher from "./EditAccountingVoucher";
import { AccountingVoucherSeriesInterface } from "../../../interfaces/Accounts";

const useStyles = makeStyles({
  title: { fontWeight: 500, fontSize: "16px", lineHeight: "20px", textTransform: "capitalize" },
  value: { fontWeight: 300, fontSize: "16px", lineHeight: "20px", textTransform: "capitalize" }
});

const formatVoucher = (voucher) => {
  const { voucherName, voucherCode, customPrefix, startingVoucher, digitsCount } = voucher;
  return {
    digitsCount: digitsCount || 0,
    voucherType: voucherName,
    voucherCode,
    fiscalYear: convertADtoBS(new Date()).bsYear,
    customPrefix,
    startVoucher: startingVoucher,
    endVoucher: "Continuing",
    sampleDisplay: formatVoucherDisplay(voucher, "sampleDisplay"),
    nextFiscal: formatVoucherDisplay(voucher, "nextFiscal"),
    ...(voucher.updated_at && {
      lastEditedDate: convertADtoBS(moment(voucher.updated_at)).formatted4
    })
  };
};

const AccountingVoucherPanel = ({
  onClose,
  selectedId,
  voucherSeries
}: {
  onClose: () => void;
  selectedId: number;
  voucherSeries: AccountingVoucherSeriesInterface[];
}): React.ReactElement => {
  const styles = useStyles();
  const voucher = voucherSeries.find((pre) => pre.id === selectedId);
  const formattedVoucher = formatVoucher(voucher);
  const [openEditPanel, setOpenEditPanel] = React.useState(false);

  const voucherKeys = Object.keys(formattedVoucher);
  const editButton = (
    <EditIcon
      style={{ cursor: "pointer" }}
      data-testmation="packageInfoEditButton"
      onClick={() => setOpenEditPanel(true)}
    />
  );
  return (
    <>
      <Panel onClose={onClose} editButton={editButton}>
        <Box style={{ margin: "12px 64px" }}>
          <Grid container spacing={2}>
            {voucherKeys.map((key, i) => (
              <Fragment key={key}>
                <Grid size={{ sm: 3 }} key={key}>
                  <Typography className={styles.title}>
                    {tl(`account.${voucherKeys[i]}`)}
                  </Typography>
                </Grid>
                <Grid size={{ sm: 9 }}>
                  <Typography className={styles.value}>{formattedVoucher[key]}</Typography>
                </Grid>
              </Fragment>
            ))}
          </Grid>
        </Box>
      </Panel>
      {openEditPanel && (
        <EditAccountingVoucher setOpenEditPanel={setOpenEditPanel} voucher={voucher} />
      )}
    </>
  );
};

export default AccountingVoucherPanel;
