import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import PeopleIcon from "@mui/icons-material/People";
import TableChartIcon from "@mui/icons-material/TableChart";
import TodayIcon from "@mui/icons-material/Today";
import { Box, Card, Grid2 as Grid, Typography } from "@mui/material";
import { push } from "connected-react-router";
import * as React from "react";
import { useDispatch } from "react-redux";
import { tl } from "../../components/translate";
import Can from "../Policy/Can";
import styles from "./Reports.module.css";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../mixpanel-analytics/event";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";

const ReportsPatient = (): JSX.Element => {
  const dispatch = useDispatch();
  const rc = useCurrentResourceCentre();

  return (
    <Box height="100%" padding="0 32px">
      <Typography fontSize="20px" fontWeight={600} lineHeight="40px" component="div">
        {tl("reports.patient")}
      </Typography>
      <Box marginTop="32px">
        <Can policyAccessKey="report:patientFlowReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_PATIENT_APPOINTMENT, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/patient/appointmentReport"));
            }}
            data-testmation="patientFlowReportCard"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    {tl("reports.appointmentReport")}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <TableChartIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="report:patientAssessmentReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_PATIENT_PRESCRIPTION, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/patient/patientAssessmentReport"));
            }}
            data-testmation="patientAssessmentReportCard"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    {tl("reports.patientAssessmentReport")}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <BubbleChartIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="report:patientFlowReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_PATIENT_CLIENT, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/patient/clientReport"));
            }}
            data-testmation="clientReportCard"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    {tl("reports.clientReport")}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <PeopleIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="report:patientFlowReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_PATIENT_REMINDERS, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/patient/reminders"));
            }}
            data-testmation="clientReportCard"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    {tl("reports.reminders")}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <TodayIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
      </Box>
    </Box>
  );
};

export default ReportsPatient;
