import { TextField } from "@mui/material";
import * as React from "react";
import { tl } from "../../../../components/translate";

interface Props {
  value: number;
  onCarryingChargeUpdate: (v) => void;
  disabled: boolean;
}

export default function StockCarryingCharge(props: Props): JSX.Element {
  const { value, onCarryingChargeUpdate, disabled } = props;

  return (
    <div>
      <TextField
        value={value}
        disabled={disabled}
        fullWidth
        label={tl("StockEntry.CC")}
        onChange={(e) => onCarryingChargeUpdate(Number(e.target.value))}
        margin="none"
        type="number"
        onFocus={(e) => e.target.select()}
        variant="outlined"
        slotProps={{
          inputLabel: { shrink: true },
          input: { endAdornment: "%" }
        }}
      />
    </div>
  );
}
