import React from "react";
import { Box } from "@mui/material";
import produce from "immer";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import Panel from "../../../../components/Panel";
import { resourceCentreActions as actions } from "../../../../actions";
import DiscountSetting from "../../ClinicSettings/DiscountSettings";
import ProductCategoryAddition from "../../ProductCategoryAddition";

interface Props {
  onClose: () => void;
}

const StockSettingsPanel = ({ onClose }: Props): JSX.Element => {
  const resourceCentre = useAppSelector((state) => state.resources.resourceCentres[0]);
  const dispatch = useAppDispatch();

  return (
    <Panel onClose={onClose} title="Stock Settings">
      <Box px={4}>
        <Box display="flex" alignItems="center">
          <DiscountSetting
            discountSettings={resourceCentre.settings.stockSettings.discountSettings}
            onChange={(discountSettings) => {
              const newRc = produce(resourceCentre, (draft) => {
                draft.settings.stockSettings.discountSettings = discountSettings;
              });
              dispatch(actions.putResourceCentre(newRc));
            }}
          />
        </Box>
        <ProductCategoryAddition resourceCentre={resourceCentre} />
      </Box>
    </Panel>
  );
};

export default StockSettingsPanel;
