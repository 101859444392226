import * as React from "react";
import { connect, Matching } from "react-redux";
import Typography from "@mui/material/Typography";
import {
  Box,
  Button,
  ButtonGroup,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper
} from "@mui/material";
import Grid from "@mui/material/Grid2";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { push } from "connected-react-router";
import { ComponentType } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { supplierNameSelector } from "../../reducers/suppliers";
import { supplierActions as actions } from "../../actions";
import { t, tl } from "../../components/translate";

import Panel from "../../components/Panel";
import styles from "./Supplier.module.css";
import Can from "../Policy/Can";
import { StockTransactions, Supplier, TransactionType } from "../../interfaces/StockInterfaces";
import { User } from "../../interfaces/User";
import { IThunkDispatch, RootState } from "../../store";
import { StyledTab, StyledTabs } from "../../components/StyledTabs";
import SupplierProducts from "./SupplierProducts";
import SupplierLedger from "./SupplierLedger";
import SupplierTransactions from "./SupplierTransactions";
import StockRecordPayment from "../Stock/StockTransactions/StockRecordPayment";
import { notificationAdd } from "../../actions/notification";
import { deleteErrorMessage, deleteSuccessMessage, MODULE } from "../../helpers/messages";
import { useAppDispatch } from "../../store/hooks";
import OkhatiDialog from "../../components/Dialog/Dialog";
import { removeGeneralErrorByKey } from "../../actions/error";

interface OwnProps {
  supplierId: number;
}

interface SupplierShowInterface {
  supplierId: number;
  supplier: Supplier;
  loadSupplier: (id: number) => void;
  navigateTo: (url: string) => void;
  permissionGroup: string;
  user: User;
  handleViewClose: () => void;
}

const switchTabRenderer = (activeTab: number, supplierId: number) => {
  switch (activeTab) {
    case 0:
      return <SupplierProducts supplierId={supplierId} />;
    case 1:
      return <SupplierTransactions supplierId={supplierId} />;
    case 2:
      return <SupplierLedger supplierId={supplierId} />;
    default:
      throw new Error(`Unknown activeTab: ${activeTab}`);
  }
};

const SupplierShow = ({
  supplierId,
  supplier,
  loadSupplier,
  navigateTo,
  permissionGroup,
  user,
  handleViewClose
}: SupplierShowInterface): JSX.Element | null => {
  const anchorRef = React.useRef<HTMLDivElement>(null);
  const [openMenu, setOpenMenu] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);

  const [recordPaymentOpen, setRecordPaymentOpen] = React.useState(false);
  const [recordPaymentReturnOpen, setRecordPaymentReturnOpen] = React.useState(false);
  const [showDeleteDialog, setShowDeleteDialog] = React.useState(false);
  const [isDeleteLoading, setIsDeleteLoading] = React.useState(false);

  const dispatch = useAppDispatch();

  const handleClose = (event: MouseEvent | TouchEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenMenu(false);
  };

  const handleToggle = () => {
    setOpenMenu((prevOpen) => !prevOpen);
  };

  React.useEffect(() => {
    loadSupplier(supplierId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierId]);

  if (!supplier) return null;

  const canAdminServiceProvider =
    ["superAdmin", "resourceCentreAdmin"].includes(permissionGroup) || user.id === supplier.id;
  let editButton = null;
  let deleteButton = null;
  if (canAdminServiceProvider) {
    editButton = (
      <Can policyAccessKey="clinic:editSuppliers" superAdminPass>
        <EditIcon
          data-testmation="editIcon"
          style={{ cursor: "pointer" }}
          onClick={() =>
            navigateTo(`/resourcecentres/${supplier.resourceCentreId}/supplier/${supplier.id}/edit`)
          }
        />
      </Can>
    );
  }

  if (["superAdmin", "resourceCentreAdmin"].includes(permissionGroup)) {
    deleteButton = (
      <Can policyAccessKey="clinic:deleteSuppliers" superAdminPass>
        <DeleteIcon
          data-testmation="deleteButton"
          style={{ cursor: "pointer" }}
          onClick={() => setShowDeleteDialog(true)}
        />
      </Can>
    );
  }

  return (
    <Panel onClose={handleViewClose} editButton={editButton} deleteButton={deleteButton}>
      <Box width="100%" className={styles.boxHeight}>
        <Typography pl={5} variant="h6">
          {supplierNameSelector(supplier)}
        </Typography>
        <Grid container style={{ marginTop: "15px" }}>
          <Grid p="0 40px" size={12}>
            {supplier && (
              <Grid container>
                <Grid size={6}>
                  <Typography variant="body1">{tl("address")}</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">{supplier.address}</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body1">{tl("email")}</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">{supplier.email}</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body1">{tl("phone")}</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">{supplier.phone}</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body1">{tl("panNo")}</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">{supplier.panNo}</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body1">{tl("detail")}</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">{supplier.detail}</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body1">{tl("agingDate")}</Typography>
                </Grid>
                <Grid size={6}>
                  <Typography variant="body2">
                    {supplier?.ageingDays} {supplier?.ageingDays && "days"}
                  </Typography>
                </Grid>

                <ButtonGroup
                  style={{ marginTop: "32px" }}
                  size="small"
                  variant="outlined"
                  color="primary"
                  ref={anchorRef}
                  aria-label="split button"
                >
                  <Button
                    data-testmation="createAssessment"
                    variant="outlined"
                    onClick={() => {
                      navigateTo(`/stock/purchase?sId=${supplierId}`);
                    }}
                    color="primary"
                  >
                    Entry Purchase
                  </Button>
                  <Button
                    data-testmation="clientinfoDropdown"
                    size="small"
                    color="primary"
                    aria-owns={openMenu ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={handleToggle}
                  >
                    <ArrowDropDownIcon />
                  </Button>
                </ButtonGroup>
                <Popper
                  style={{ zIndex: 1 }}
                  open={openMenu}
                  anchorEl={anchorRef.current}
                  transition
                  disablePortal
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...TransitionProps}
                      style={{
                        transformOrigin: placement === "bottom" ? "right bottom" : "right bottom"
                      }}
                    >
                      <Paper id="menu-list-grow">
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList>
                            <MenuItem
                              data-testmation="vitals"
                              onClick={() => {
                                setRecordPaymentOpen(true);
                              }}
                              color="primary"
                              style={{ minWidth: "200px" }}
                            >
                              Record Payment Purchase
                            </MenuItem>
                            <MenuItem
                              data-testmation="vitals"
                              onClick={() => {
                                setRecordPaymentReturnOpen(true);
                              }}
                              color="primary"
                              style={{ minWidth: "200px" }}
                            >
                              Record Payment Purchase Return
                            </MenuItem>
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </Grid>
            )}
          </Grid>
          <Box marginTop="4px" width="100%">
            <StyledTabs
              value={activeTab}
              onChange={(e, tab) => setActiveTab(tab)}
              aria-label="client info tabs"
            >
              <StyledTab label={t("supplier.products")} />
              <StyledTab label={t("supplier.transactions")} />
              <StyledTab label={t("supplier.ledger")} />
            </StyledTabs>
            {switchTabRenderer(activeTab, supplierId)}
          </Box>
          <Grid size={{ xs: 12, md: 6, lg: 6 }} />
        </Grid>
      </Box>
      {recordPaymentOpen && (
        <StockRecordPayment
          entry={{ supplierId, transactionType: TransactionType.PURCHASE } as StockTransactions}
          onClose={() => setRecordPaymentOpen(false)}
        />
      )}
      {recordPaymentReturnOpen && (
        <StockRecordPayment
          entry={
            { supplierId, transactionType: TransactionType.PURCHASE_RETURN } as StockTransactions
          }
          onClose={() => setRecordPaymentReturnOpen(false)}
        />
      )}
      <OkhatiDialog
        title="Are you sure about deleting?"
        open={showDeleteDialog}
        isLoading={isDeleteLoading}
        cancel={() => setShowDeleteDialog(false)}
        next={async () => {
          dispatch(async (innerDispatch, getState) => {
            setIsDeleteLoading(true);
            await innerDispatch(actions.deleteSupplier(supplier.id));
            const error = getState().error?.find((item) => item.key === "DELETE_SUPPLIER");
            if (error) {
              dispatch(
                notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "error",
                  message: error.message || deleteErrorMessage(MODULE.SUPPLIER),
                  autoTimeout: true
                })
              );
              innerDispatch(removeGeneralErrorByKey("DELETE_SUPPLIER"));
            } else
              dispatch(
                notificationAdd({
                  id: new Date().getUTCMilliseconds(),
                  variant: "success",
                  message: deleteSuccessMessage(MODULE.SUPPLIER),
                  autoTimeout: true
                })
              );
            setShowDeleteDialog(false);
            setIsDeleteLoading(false);
          });
        }}
        description="This action cannot be undone."
        readMode={false}
      />
    </Panel>
  );
};

export default connect(
  (state: RootState, ownprops: OwnProps) => ({
    user: { ...state.userContext.user, role: state.userContext.mode },
    supplier: state.resources.suppliers.find((sp) => sp.id === ownprops.supplierId),
    permissionGroup: state.userContext?.userCreds?.userGroups[0]
  }),
  (dispatch: IThunkDispatch) => ({
    loadSupplier: (id) => dispatch(actions.getSupplier(id)),
    navigateTo: (url) => dispatch(push(url))
  })
)(SupplierShow as unknown as ComponentType<Matching<Record<string, any>, SupplierShowInterface>>);
