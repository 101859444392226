import * as React from "react";
import {
  Box,
  Divider,
  TextField,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Alert,
  Autocomplete,
  Button
} from "@mui/material";
import { round, startCase } from "lodash";
import produce from "immer";
import { connect, useDispatch, useSelector } from "react-redux";
import { tl, t } from "../../../components/translate";
import NrsFormat from "../../../components/FormattingInput/NrsInput";
import styles from "./PurchaseEntry.module.css";
import {
  DiscountBasis,
  DiscountSettings,
  DiscountTypes,
  Entry,
  paymentOptionsEnum as PurchasePaymentOption,
  Supplier,
  TransactionType
} from "../../../interfaces/StockInterfaces";
import { RootState } from "../../../store";
import { paymentOptionsEnum } from "../../Billing/Editor/BillSummary";
import Modal from "../../../components/Modal/Modal";
import LedgerSelect from "../../ResourceCentre/Settings/AccountSettings/LedgerSelect";
import { ChildGeneralLedger, LedgerType } from "../../../interfaces/Accounts";
import { getAllChildGlWithLedgerTypeFilter } from "../../accounts/hooks";
import { resourceCentreActions } from "../../../actions";
import { roundTwoDecimalPoint } from "../../../helpers/number";

const Label = ({ children }) => (
  <Typography component="span" style={{ display: "flex" }}>
    <Box
      component="span"
      display="flex"
      fontSize="0.83em"
      fontWeight={600}
      alignItems="center"
      width="100px"
      marginRight="16px"
    >
      {children}
    </Box>
  </Typography>
);

const paymentOptions = [
  paymentOptionsEnum.cash,
  paymentOptionsEnum.card,
  paymentOptionsEnum.cheque,
  paymentOptionsEnum.onlinePayment,
  paymentOptionsEnum.eSewa,
  paymentOptionsEnum.fonePay,
  paymentOptionsEnum.khalti,
  PurchasePaymentOption.credit
];

interface SummaryInterface {
  updateEntry: (entry: Entry) => void;
  entry: Entry;
  discountSettings: DiscountSettings;
  suppliers: Supplier[];
}

const Summary = ({ updateEntry, entry, discountSettings, suppliers }: SummaryInterface) => {
  const dispatch = useDispatch();
  React.useEffect(() => {
    updateEntry({
      ...entry,
      paidAll: true,
      paidAmount: entry.summary?.totalIncVat,
      totalAmount: entry.summary?.totalIncVat
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry.summary?.totalIncVat, entry?.paymentInfo?.fromBalance]);
  const [showLedgerSelect, setShowLedgerSelect] = React.useState<boolean>(false);
  const [selectedLedger, setSelectedLedger] = React.useState<ChildGeneralLedger>(null);
  const cashAndBankLedgers = getAllChildGlWithLedgerTypeFilter([
    LedgerType.BANK_LEDGER,
    LedgerType.CASH_LEDGER
  ]);
  const { isAccountSubscribed, paymentMethods } = useSelector((state: RootState) => ({
    isAccountSubscribed: state.subscriptions.currentSubscription?.features?.account?.subscribed,
    paymentMethods: state.resources.resourceCentres[0]?.settings?.accountSettings?.modeOfPayment
  }));
  const resourceCentre = useSelector((state: RootState) => state.resources.resourceCentres[0]);

  const [selectedSupplier, setSupplier] = React.useState<Supplier>(null);

  React.useEffect(() => {
    setSupplier(suppliers.find((supplier) => supplier.id === entry.supplierId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entry.supplierId]);

  // currrently enableOnTags doesnt work for "alt+n" on mac devices

  const [showAlert, setShowAlert] = React.useState<boolean>(false);

  const currDiscountPercent = React.useMemo(() => {
    const total = entry.stockItems?.reduce((acc, stockItem) => acc + stockItem.netTotal, 0);
    const amountToDiscount = roundTwoDecimalPoint(entry.summary.discountAmt / total);
    return Number(amountToDiscount) * 100;
  }, [entry.summary.discountAmt, entry.stockItems]);

  return (
    <Box display="flex" padding="32px 32px 64px 32px" borderTop="1px solid lightgrey">
      <Box flexGrow={1}>
        {[
          TransactionType.PURCHASE,
          TransactionType.PURCHASE_RETURN,
          TransactionType.OPENING_STOCK_ADJUSTMENT
        ].includes(entry.transactionType as TransactionType) && (
          <Box mt="16px">
            <Box marginTop={2}>
              <Label>{tl("stock.paymentMethod")}</Label>
            </Box>
            <Box width="200px">
              <Autocomplete
                data-testmation="paymentMethodSelect"
                value={entry.paymentType}
                options={paymentOptions}
                getOptionLabel={(option) => startCase(option)}
                renderInput={(params) => (
                  <TextField
                    /* eslint-disable react/jsx-props-no-spreading */
                    {...params}
                    fullWidth
                    variant="outlined"
                    margin="dense"
                    placeholder={t("billing.selectPaymentMethod")}
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                  />
                )}
                onChange={(e, v) => {
                  if (!v) return;
                  if (!paymentMethods[v]?.ledgerId && v !== PurchasePaymentOption.credit) {
                    setShowAlert(true);
                  } else {
                    setShowAlert(false);
                  }
                  if (v === "credit") {
                    updateEntry(
                      produce(entry, (draft) => {
                        draft.paymentType = v;
                        draft.paidAll = false;
                        draft.paymentInfo.cashPayment = 0;
                        draft.paymentInfo.paidFromBalance = 0;
                        draft.paymentInfo.fromBalance = false;
                      })
                    );
                    setShowAlert(false);
                  } else if (v === "cash") {
                    updateEntry({
                      ...entry,
                      paymentType: v,
                      paidAmount: Number(entry.totalAmount)
                    });
                  } else {
                    updateEntry({ ...entry, paymentType: v });
                  }
                }}
              />
            </Box>
            {isAccountSubscribed && showAlert && (
              <Alert
                action={
                  <Button
                    onClick={() => setShowLedgerSelect(true)}
                    variant="outlined"
                    color="inherit"
                    size="small"
                    data-testmation="mapLedger"
                  >
                    Map Ledger
                  </Button>
                }
                severity="warning"
                sx={{ width: "290px" }}
              >
                Not mapped to ledger
              </Alert>
            )}
            <Modal
              open={showLedgerSelect}
              title="Select Ledger for Supplier"
              footer={
                <>
                  <Button
                    onClick={() => {
                      setShowLedgerSelect(false);
                      setSelectedLedger(null);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    disabled={!selectedLedger}
                    onClick={() => {
                      if (selectedLedger) {
                        dispatch(
                          resourceCentreActions.putResourceCentre(
                            produce(resourceCentre, (draft) => {
                              const { modeOfPayment } = draft.settings.accountSettings;
                              if (modeOfPayment[entry.paymentType]) {
                                draft.settings.accountSettings.modeOfPayment[
                                  entry.paymentType
                                ].ledgerId = selectedLedger.id;
                              } else {
                                draft.settings.accountSettings.modeOfPayment[entry.paymentType] = {
                                  ledgerId: selectedLedger.id
                                };
                              }
                            })
                          )
                        );
                        setSelectedLedger(null);
                        setShowLedgerSelect(false);
                        setShowAlert(false);
                      }
                    }}
                  >
                    Save
                  </Button>
                </>
              }
            >
              <LedgerSelect
                options={cashAndBankLedgers || []}
                selected={selectedLedger}
                onChange={(value) => setSelectedLedger(value)}
              />
            </Modal>
          </Box>
        )}

        <Box maxWidth="500px" paddingRight="64px" paddingTop="16px">
          <Typography>
            <Box component="span" fontWeight={500} marginBottom="8px">
              {tl("stock.note")}
            </Box>
          </Typography>
          <TextField
            style={{ background: "white" }}
            fullWidth
            variant="outlined"
            multiline
            placeholder={t("stock.note")}
            value={entry.note}
            onChange={(e) => {
              updateEntry({ ...entry, note: e.target.value });
            }}
          />
        </Box>
        {selectedSupplier &&
          entry?.paymentInfo?.balance >= 0 &&
          entry.transactionType === TransactionType.PURCHASE && (
            <Box border="1px solid #00000030" mt={3} borderRadius="4px" maxWidth="500px" p={1}>
              <Box>{`${selectedSupplier?.name}'s`} Ledger</Box>
              <Box display="flex" flexDirection="row" justifyContent="space-between" mt={1}>
                <Box component="span">{tl("clientLedger.balance")}</Box>
                <Box>
                  {tl("rs")} {entry?.paymentInfo?.balance} ({tl("clientLedger.payable")})
                </Box>
              </Box>
            </Box>
          )}
      </Box>
      {entry.transactionType !== TransactionType.OPENING_STOCK && (
        <Box display="flex" flexDirection="column" width="300px">
          <Box display="flex" textAlign="right">
            <Typography style={{ fontWeight: 600 }}>{tl("stock.totalCcAmt")}</Typography>
            <Box marginLeft="auto" display="flex" style={{ color: "gray" }}>
              <Typography>Rs. </Typography>
              <Typography>{Number(entry.summary?.totalCcAmt).toFixed(2)} </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" textAlign="right" justifyContent="flex-end">
            <Typography style={{ fontWeight: 600 }}>
              {tl("stock.discount")}
              {discountSettings.discountType === DiscountTypes.AMOUNT &&
              discountSettings.discountBasis === DiscountBasis.TOTAL &&
              currDiscountPercent
                ? ` (${currDiscountPercent}%)`
                : ""}
            </Typography>
            <Box marginLeft="auto" display="flex">
              <Box component="span" marginLeft="16px" textAlign="left">
                <TextField
                  value={
                    discountSettings.discountType === DiscountTypes.PERCENT &&
                    discountSettings.discountBasis === DiscountBasis.TOTAL
                      ? entry?.summary?.totalDiscountPct
                      : entry.summary?.discountAmt
                  }
                  margin="dense"
                  onFocus={(e) => e.target.select()}
                  variant="outlined"
                  disabled={discountSettings.discountBasis === DiscountBasis.INLINE}
                  slotProps={{
                    input: {
                      inputComponent: NrsFormat,
                      startAdornment:
                        discountSettings.discountType === DiscountTypes.AMOUNT && tl("rs"),
                      endAdornment:
                        discountSettings.discountType === DiscountTypes.PERCENT &&
                        discountSettings.discountBasis === DiscountBasis.TOTAL &&
                        "%",
                      classes: { input: styles.discountInputInput }
                    }
                  }}
                  onChange={(e) => {
                    if (!(discountSettings.discountType === DiscountTypes.PERCENT)) {
                      const discountAmt = Number(e.target.value);
                      const totalWithoutDiscount = entry.stockItems?.reduce(
                        (acc, stockItem) => acc + stockItem.netTotal,
                        0
                      );
                      if (discountAmt < totalWithoutDiscount) {
                        const updatedDisAmt = produce(entry, (draft) => {
                          draft.summary.discountAmt = discountAmt;
                        });
                        updateEntry(updatedDisAmt);
                      } else {
                        const updatedDisAmt = produce(entry, (draft) => {
                          draft.summary.discountAmt = totalWithoutDiscount;
                        });
                        updateEntry(updatedDisAmt);
                      }
                    } else {
                      const discountPct = Number(e.target.value);
                      const updatedTotalDis = produce(entry, (draft) => {
                        draft.summary.totalDiscountPct = discountPct > 100 ? 100 : discountPct;
                      });
                      updateEntry(updatedTotalDis);
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" textAlign="right">
            {/* <Typography style={{ fontWeight: 600 }}>{tl('stock.totalAmount')}</Typography> */}
            <Typography style={{ fontWeight: 600 }}>{tl("stock.taxableAmount")}</Typography>
            <Box marginLeft="auto" display="flex">
              <Typography>Rs. </Typography>
              <Typography>{entry.summary?.taxAmt?.toFixed(2)} </Typography>
            </Box>
          </Box>

          {Object.keys(entry.summary?.rates || {}).map((key, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <Box key={i} display="flex" textAlign="right">
              <Typography style={{ fontWeight: 600 }}>{`VAT ${key.toString()} %`}</Typography>
              <Box marginLeft="auto" display="flex">
                <Typography>Rs. </Typography>
                <Typography>{entry.summary?.rates[key]?.toFixed(2)} </Typography>
              </Box>
            </Box>
          ))}
          <Box display="flex" textAlign="right">
            <Typography style={{ fontWeight: 600 }}>{tl("stock.freeItemVat")}</Typography>
            <Box marginLeft="auto" display="flex">
              <Typography>Rs. </Typography>
              <Typography> {Number(entry.summary?.totalVatAmtOfFreeItems).toFixed(2)} </Typography>
            </Box>
          </Box>
          <Box display="flex" alignItems="center" textAlign="right" justifyContent="flex-end">
            <Typography style={{ fontWeight: 600 }}>{tl("stock.roundingOff")}</Typography>
            <Box marginLeft="auto" display="flex">
              <Box component="span" marginLeft="16px" textAlign="left">
                <TextField
                  disabled={entry.transactionType === TransactionType.PURCHASE_RETURN}
                  value={entry.summary?.roundOffAmt}
                  margin="dense"
                  onFocus={(e) => e.target.select()}
                  variant="outlined"
                  slotProps={{
                    input: {
                      // inputComponent: NrsFormat as any,
                      startAdornment: tl("rs"),
                      classes: { input: styles.discountInputInput }
                    }
                  }}
                  type="number"
                  onChange={(e) => {
                    const roundOffAmt = e.target.value;
                    if (Number(roundOffAmt) >= -5 && Number(roundOffAmt) <= 5) {
                      const newState = produce(entry, (draft) => {
                        draft.summary.roundOffAmt = roundOffAmt;
                      });
                      updateEntry(newState);
                    }
                  }}
                />
              </Box>
            </Box>
          </Box>
          <Box display="flex" textAlign="right">
            <Typography style={{ fontWeight: 600 }}>{tl("stock.totalAmount")}</Typography>
            <Box marginLeft="auto" display="flex">
              <Typography>Rs. </Typography>
              <Typography> {entry.summary?.totalIncVat?.toFixed(2)} </Typography>
            </Box>
          </Box>
          {entry?.paymentInfo?.balance > 0 &&
            entry?.transactionType === TransactionType.PURCHASE &&
            entry.paymentType !== PurchasePaymentOption.credit && (
              <>
                <Box
                  component="div"
                  marginBottom="2px"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Box
                    component="span"
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    width="125px"
                    fontWeight={500}
                  >
                    <Checkbox
                      checked={entry.paymentInfo.fromBalance}
                      onChange={() => {
                        const newState = produce(entry, (draft) => {
                          draft.paymentInfo.fromBalance = !draft.paymentInfo.fromBalance;
                          if (draft?.paymentInfo?.fromBalance) {
                            const remaining = entry.paymentInfo.balance - Number(entry.totalAmount);
                            if (remaining <= 0) {
                              draft.paymentInfo.paidFromBalance = Number(draft.paymentInfo.balance);
                              draft.paymentInfo.cashPayment = round(Math.abs(remaining), 2);
                            }
                          }
                        });
                        updateEntry(newState);
                      }}
                    />
                    <Typography>{tl("stock.fromBalance")}</Typography>
                  </Box>
                  <Box component="span" marginLeft="16px" textAlign="left">
                    <TextField
                      disabled
                      value={entry.paymentInfo?.paidFromBalance}
                      margin="dense"
                      onFocus={(e) => e.target.select()}
                      variant="outlined"
                      slotProps={{
                        input: {
                          inputComponent: NrsFormat,
                          startAdornment: tl("rs"),
                          classes: { input: styles.discountInputInput }
                        }
                      }}
                    />
                  </Box>
                </Box>
                {entry.paymentInfo?.fromBalance && (
                  <Box
                    component="div"
                    marginBottom="2px"
                    display="flex"
                    justifyContent="space-between"
                  >
                    <Box
                      component="span"
                      display="flex"
                      alignItems="center"
                      justifyContent="flex-start"
                      width="125px"
                      fontWeight={500}
                    >
                      <Typography>{tl("stock.cashPaid")}</Typography>
                    </Box>
                    <Box component="span" marginLeft="16px" textAlign="left">
                      <TextField
                        disabled={entry.paymentInfo.paidFromBalance === entry.totalAmount}
                        value={entry.paymentInfo.cashPayment}
                        margin="dense"
                        onFocus={(e) => e.target.select()}
                        variant="outlined"
                        slotProps={{
                          input: {
                            inputComponent: NrsFormat,
                            startAdornment: tl("rs"),
                            classes: { input: styles.discountInputInput }
                          }
                        }}
                        onChange={({ target }) => {
                          const newState = produce(entry, (draft) => {
                            const dueAmt =
                              Number(draft.totalAmount) - Number(draft.paymentInfo.paidFromBalance);
                            if (Number(target.value) > dueAmt) {
                              draft.paymentInfo.cashPayment = round(Number(dueAmt), 2);
                            } else {
                              draft.paymentInfo.cashPayment = Number(target.value);
                            }
                          });
                          updateEntry(newState);
                        }}
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}

          <Box height="8px" />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  checked={entry?.paymentType === "credit" ? false : entry.paidAll}
                  data-testmation="billing.paidAll"
                  onChange={(e, checked) => {
                    let paidAmt = 0;
                    if (checked) {
                      paidAmt = entry.summary?.totalIncVat;
                    } else {
                      paidAmt = 0;
                    }
                    updateEntry({
                      ...entry,
                      paidAll: checked,
                      paidAmount: paidAmt
                    });
                  }}
                  disabled={entry?.paymentType === "credit"}
                />
              }
              label={t("billing.paidAll")}
            />

            <Box component="div" marginBottom="2px" display="flex" justifyContent="space-between">
              <Box
                component="span"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="125px"
                fontWeight={500}
              >
                <Typography>{tl("stock.paidAmount")}</Typography>
              </Box>
              <Box
                component="span"
                marginLeft="16px"
                textAlign="left"
                data-testmation="paidAmountInput"
              >
                <TextField
                  disabled={entry.transactionType === TransactionType.PURCHASE_RETURN}
                  value={entry.paidAmount?.toFixed(2)}
                  margin="dense"
                  onFocus={(e) => e.target.select()}
                  variant="outlined"
                  s
                  slotProps={{
                    input: {
                      inputComponent: NrsFormat,
                      startAdornment: tl("rs"),
                      classes: { input: styles.discountInputInput }
                    }
                  }}
                  onChange={(e) => {
                    let paidAmt = Number(e.target.value);
                    let paidAll = false;
                    if (paidAmt >= entry.summary?.totalIncVat) {
                      paidAmt = entry.summary?.totalIncVat;
                      paidAll = true;
                    }
                    updateEntry({
                      ...entry,
                      paidAmount: paidAmt,
                      paidAll
                    });
                  }}
                />
              </Box>
            </Box>

            <Box component="div" marginBottom="4px" display="flex" justifyContent="space-between">
              <Box
                component="span"
                display="flex"
                alignItems="center"
                justifyContent="flex-start"
                width="125px"
                fontWeight={500}
              >
                <Typography>{tl("stock.dueAmount")}</Typography>
              </Box>
              <Box component="span" marginLeft="16px" textAlign="left">
                <TextField
                  slotProps={{
                    input: {
                      inputComponent: NrsFormat,
                      startAdornment: t("rs"),
                      classes: { input: styles.discountInputInput }
                    }
                  }}
                  disabled
                  value={
                    entry.paidAmount - Number(entry.totalAmount) >= 0
                      ? 0
                      : (entry.summary?.totalIncVat - entry.paidAmount || 0).toFixed(2)
                  }
                  margin="dense"
                  variant="outlined"
                />
              </Box>
            </Box>
          </FormGroup>
        </Box>
      )}
      <Divider />
    </Box>
  );
};

export default connect(
  (state: RootState) => ({
    discountSettings: state.stock.entry?.settings?.discountSettings,
    suppliers: state.resources.suppliers
  }),
  null
)(Summary);
