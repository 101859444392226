import React, { JSX } from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../store";
import LabStatusChip from "../../Lab/LabStatusChip";
import MultipleButtonGroup, { PRINT_FROM } from "../../../components/MultipleButtonGroup";
import ForeignEmploymentBorderedPrint from "./Templates/ForeignEmploymentBorderedPrint";
import ForeignEmploymentPrint from "./Templates/ForeignEmploymentPrint";
import useShowMedicalDisclaimerOnFooter from "../../../hooks/useShowMedicalDisclaimerOnFooter";

interface PropTypes {
  medicalTestStatus: string;
  medicalTestId: string;
}

export default function MedicalPrintsButtonGroup({
  medicalTestStatus,
  medicalTestId
}: PropTypes): JSX.Element {
  const medicalTest = useSelector((state: RootState) =>
    state.medicalTest.collection.find((item) => item.id === Number(medicalTestId))
  );

  const { showMedicalDisclaimerOnFooter, toggleShowMedicalDisclaimerOnFooter } =
    useShowMedicalDisclaimerOnFooter();

  return (
    <Box sx={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
      <LabStatusChip status={medicalTestStatus} />

      <MultipleButtonGroup
        printFrom={PRINT_FROM.MEDICAL}
        showMedicalDisclaimerOnFooter={showMedicalDisclaimerOnFooter}
        toggleShowMedicalDisclaimerOnFooter={toggleShowMedicalDisclaimerOnFooter}
        options={[
          {
            label: "Print Default",
            onClickAction: () => ({}),
            component: (
              <ForeignEmploymentPrint
                buttonText="Print Default"
                labRecord={medicalTest}
                assessment={[medicalTest.results.assessment]}
                showMedicalDisclaimerOnFooter={showMedicalDisclaimerOnFooter}
              />
            )
          },
          {
            label: "Print Bordered",
            onClickAction: () => ({}),
            component: (
              <ForeignEmploymentBorderedPrint
                buttonText="Print Bordered"
                labRecord={medicalTest}
                assessment={[medicalTest.results.assessment]}
                showMedicalDisclaimerOnFooter={showMedicalDisclaimerOnFooter}
              />
            )
          }
        ]}
      />
    </Box>
  );
}
