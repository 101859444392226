import { cloneDeep } from "lodash";
import { LabRecordType } from "../containers/Lab";
import {
  BillingSourcesType,
  FilterProps,
  FILTERS,
  ReceiptModeType,
  ShowClientByType,
  StockStatusType
} from "../containers/Reports/Filters";
import { StockTransactionData } from "../containers/Reports/StockTransactionReport";
import { ProductType } from "../containers/Stock/StockCreateEdit/CreateStockProduct";
import { CustomerVendorReportProps } from "../interfaces/Accounts";
import { BankReconciliationReportInterface } from "../interfaces/BankReconciliationInterface";
import { Client } from "../interfaces/ClientInterface";
import { BillStatus, BillType, VisitTypes } from "../interfaces/BillInterfaces";
import { ClientLedgerReport } from "../interfaces/ClientLedgerReport";
import { LabReport as LabReportInterface, LabStatuses } from "../interfaces/Lab";
import {
  CustomerDueReportInterface,
  HMISReportRow,
  PatientFlowProps
} from "../interfaces/ReportInterface";
import { QueryParams } from "../interfaces/StockInterfaces";
import { Get } from "./apiHelper";
import { AssessmentReportData } from "../interfaces/AssessmentInterfaces";
import { History } from "../interfaces/HistoryInterface";
import { ReminderInterface } from "../interfaces/ReminderInterface";
import { reportsRoot as accountReportsRoot } from "./accountReports";
import { StockHistory } from "../interfaces/StockHistory";
import { StockExpiryData } from "../containers/Reports/StockExpiryReport";
import { MESSAGE_TYPE } from "../containers/Reports/PublicSmsReport";

const reportsRoot = "/api/reports";

type ClientLedgerReportParams = {
  from: Date;
  until: Date;
  clientIds: Array<number>;
};

export const getStockLedgerReport = async (filters: FilterProps): Promise<any[]> => {
  const url = `${reportsRoot}/stockLedger?from=${filters.from.toISOString()}&until=${filters.until.toISOString()}&productItems=${
    filters.productItems
  }`;
  const response = await Get(url, true);
  return response?.data as StockTransactionData[];
};

export const getStockTransactionReports = async (
  filters: FilterProps
): Promise<StockTransactionData[]> => {
  const url = `${reportsRoot}/stockTransactions?from=${filters.from.toISOString()}&until=${filters.until.toISOString()}&transactionType=${
    filters.transactionType
  }&supplierIds=${filters.supplierIds}`;
  const response = await Get(url, true);
  return response?.data as StockTransactionData[];
};

export const getStockSummaryReports = async (filters: FilterProps): Promise<Stocks[]> => {
  const url = `${reportsRoot}/stockSummary?from=${filters.from.toISOString()}&until=${filters.until.toISOString()}&productType=${
    filters.productType
  }${filters[FILTERS.PRODUCT_ITEMS] ? `&productItems=${filters.productItems}` : ""}`;
  const response = await Get(url, true);
  return response?.data as StockTransactionData[];
};

export const clientLedgerReport = async ({
  from,
  until,
  clientIds
}: ClientLedgerReportParams): Promise<Array<ClientLedgerReport>> => {
  const url = `${reportsRoot}/clientLedger?from=${from?.toISOString()}&until=${until.toISOString()}&clientIds=${clientIds}`;
  const response = await Get(url, true);
  return response.data as Array<ClientLedgerReport>;
};

export const patientFlowReport = async ({
  from,
  until,
  serviceIds,
  serviceProviderIds,
  clientIds,
  onlyPublic,
  departmentId,
  bookingStatus
}: {
  from: Date;
  until: Date;
  serviceIds: Array<number>;
  serviceProviderIds: Array<number>;
  clientIds: Array<number>;
  onlyPublic: boolean;
  departmentId: number;
  bookingStatus: string;
}): Promise<PatientFlowProps[]> => {
  const url = `${reportsRoot}/patientFlow?from=${from.toISOString()}&until=${until.toISOString()}&serviceIds=${serviceIds.toString()}&serviceProviderIds=${serviceProviderIds}&clientIds=${clientIds.toString()}&onlyPublic=${onlyPublic}${
    departmentId ? `&departmentId=${departmentId}` : ""
  }&status=${bookingStatus}`;
  const response = await Get(`${url}`, true);
  return response?.data as PatientFlowProps[];
};

export const assessmentReport = async ({
  from,
  until,
  clientIds,
  serviceProviderIds
}: {
  from: Date;
  until: Date;
  clientIds: Array<number>;
  serviceProviderIds: Array<number>;
}): Promise<AssessmentReportData[]> => {
  const url = `${reportsRoot}/patientAssessments?from=${from.toISOString()}&until=${until.toISOString()}&clientIds=${clientIds.toString()}&serviceProviderIds=${serviceProviderIds}`;
  const response = await Get(`${url}`, true);
  return response.data as AssessmentReportData[];
};

export const getSMSReport = async ({
  from,
  until,
  messageStatus,
  resourceCentreId,
  page,
  pageSize
}: {
  from: Date;
  until: Date;
  messageStatus: string;
  resourceCentreId: number;
  page: number;
  pageSize: number;
}): Promise<unknown> => {
  const url = `${reportsRoot}/${resourceCentreId}/smsReport?from=${from.toISOString()}&until=${until.toISOString()}&messageStatus=${messageStatus}&page=${page}&pagesize=${pageSize}`;
  const response = await Get(url, true);
  return response.data;
};

export const getPublicSmsReport = async ({
  from,
  until,
  type,
  page,
  pageSize
}: {
  from: Date;
  until: Date;
  type: MESSAGE_TYPE | null;
  page: number;
  pageSize: number;
}): Promise<unknown> => {
  const url = `${reportsRoot}/publicSms?from=${from.toISOString()}&until=${until.toISOString()}&page=${page}&pageSize=${pageSize}${
    type ? `&type=${type}` : ""
  }`;
  const response = await Get(url, true);
  return response.data;
};

export const serviceProviderChargesReport = async ({
  from,
  until,
  serviceIds,
  serviceProviderIds,
  includeProformaDrafts,
  productCategory
}: {
  from: Date;
  until: Date;
  serviceIds: Array<number>;
  serviceProviderIds: Array<number>;
  includeProformaDrafts: boolean;
  productCategory: string;
}): Promise<unknown> => {
  const serviceIdsParams = serviceIds.length ? `&serviceIds=${serviceIds.toString()}` : "";
  const serviceProviderIdsParams = serviceProviderIds.length
    ? `&serviceProviderIds=${serviceProviderIds}`
    : "";
  const url = `${reportsRoot}/serviceProviderCharges?from=${from.toISOString()}
    &until=${until.toISOString()}${serviceIdsParams}${serviceProviderIdsParams}
    &includeProformaDrafts=${includeProformaDrafts}
    &productCategory=${productCategory || ""}`;
  const response = await Get(`${url}`, true);
  return response.data;
};

export const commissionReport = async ({
  from,
  until,
  vendorType,
  referrersName,
  serviceProviderIds
}: {
  from: Date;
  until: Date;
  vendorType: string;
  referrersName: number[];
  serviceProviderIds: number[];
}): Promise<unknown> => {
  const vendorIds = vendorType === "serviceProvider" ? serviceProviderIds : referrersName;
  const url = `${reportsRoot}/commission?from=${from.toISOString()}&until=${until.toISOString()}&vendorIds=${
    vendorIds || []
  }&vendorType=${vendorType}`;
  const response = await Get(`${url}`, true);
  return response.data;
};

export const getServiceProviderChargesTally = async ({
  from,
  until,
  serviceIds,
  serviceProviderIds
}: {
  from: Date;
  until: Date;
  serviceIds: Array<number>;
  serviceProviderIds: Array<number>;
}): Promise<unknown> => {
  const url = `/api/tally/serviceProviderCharges?from=${from.toISOString()}&until=${until.toISOString()}&serviceIds=${serviceIds.toString()}&serviceProviderIds=${serviceProviderIds}`;
  const response = await Get(`${url}`, true);
  return response;
};

export const salesReport = async ({
  from,
  until,
  clientIds,
  billType,
  referrersName,
  employeeIds,
  visitType,
  counter,
  paymentMethods,
  billStatuses
}: {
  from: Date;
  until: Date;
  clientIds: Array<number>;
  billType: unknown;
  referrersName: Array<{ id: number } & Record<string, unknown>>;
  employeeIds: number[];
  visitType: VisitTypes;
  counter: string;
  billStatuses: BillStatus;
  paymentMethods: string[];
}): Promise<unknown> => {
  const url = `${reportsRoot}/sales?from=${from.toISOString()}&until=${until.toISOString()}&paymentMethods=${paymentMethods.toString()}&clientIds=${clientIds.toString()}&billType=${
    billType || ""
  }&referrers=${referrersName
    .map((referrer) => referrer.id)
    .toString()}&employeeIds=${employeeIds.toString()}&visitType=${visitType || ""}&counter=${
    counter || ""
  }${billStatuses ? `&billStatuses=${billStatuses}` : ""}`;
  const response = await Get(`${url}`, true);
  return response.data;
};

export const salesByServiceReport = async ({
  from,
  until,
  productCategory,
  serviceType,
  serviceIds,
  includeDrafts = false,
  departmentId,
  employeeIds,
  visitType,
  billingSource,
  resourceCentreIds,
  counter,
  includeProformaDrafts
}: {
  from: Date;
  until: Date;
  productCategory: unknown;
  serviceType: unknown;
  serviceIds: Array<number>;
  includeDrafts?: boolean;
  departmentId: number;
  employeeIds: number[];
  visitType: VisitTypes;
  billingSource: BillingSourcesType;
  resourceCentreIds: number[];
  counter: string;
  includeProformaDrafts: boolean;
}): Promise<unknown> => {
  const params = {
    from: from.toISOString(),
    until: until.toISOString(),
    serviceType,
    productCategory,
    billingSource,
    ...(serviceIds && { serviceIds: serviceIds.toString() }),
    ...(employeeIds && { employeeIds: employeeIds.toString() }),
    includeDrafts: `${includeDrafts}`,
    ...(departmentId && { departmentId: `${departmentId}` }),
    ...(visitType && { visitType }),
    ...(counter && { counter }),
    ...(resourceCentreIds && { resourceCentreIds: resourceCentreIds.toString() }),
    includeProformaDrafts
  };
  const url = `${reportsRoot}/salesByService`;
  const response = await Get(`${url}`, true, params as unknown as QueryParams);
  return response.data;
};

export const billPaymentRecords = async ({
  from,
  until,
  clientIds,
  referrersName,
  billType
}: {
  from: Date;
  until: Date;
  clientIds: Array<number>;
  referrersName: Array<{ id: number }>;
  billType: unknown;
}): Promise<unknown> => {
  const url = `${reportsRoot}/billPaymentRecords?from=${from.toISOString()}&until=${until.toISOString()}&clientIds=${clientIds.toString()}&referrerIds=${referrersName
    .map((referrer) => referrer.id)
    .toString()}&billType=${billType || ""}`;
  const response = await Get(`${url}`, true);
  return response.data;
};

export const reciept = async ({
  from,
  until,
  clientIds,
  referrersName,
  employeeIds,
  visitType,
  resourceCentreIds,
  receiptMode
}: {
  from: Date;
  until: Date;
  clientIds: Array<number>;
  referrersName: Array<{ id: number }>;
  employeeIds: number[];
  visitType: VisitTypes;
  resourceCentreIds: number[];
  receiptMode: ReceiptModeType[];
}): Promise<unknown> => {
  const url = `${reportsRoot}/receipt?from=${from.toISOString()}&until=${until.toISOString()}&clientIds=${
    clientIds?.toString() || ""
  }&referrers=${referrersName || ""}&employeeIds=${employeeIds?.toString() || ""}&visitType=${
    visitType || ""
  }&resourceCentreIds=${resourceCentreIds || ""}&receiptMode=${receiptMode.toString()}`;
  const response = await Get(`${url}`, true);
  return response.data;
};

export const getRevenueTally = async ({
  from,
  until
}: {
  from: Date;
  until: Date;
}): Promise<unknown> => {
  const url = `/api/tally/revenue?from=${from.toISOString()}&until=${until.toISOString()}`;
  const response = await Get(`${url}`, true);
  return response;
};

export const dueReport = async ({
  from,
  until,
  clientIds,
  referrersName,
  billType,
  employeeIds,
  visitType,
  resourceCentreIds,
  includeProformaDrafts
}: {
  from: Date;
  until: Date;
  clientIds: Array<number>;
  referrersName: Array<{ id: number }>;
  billType: unknown;
  employeeIds: number[];
  visitType;
  resourceCentreIds: number[];
  includeProformaDrafts: boolean;
}): Promise<unknown> => {
  const url = `${reportsRoot}/dueReport?from=${from?.toISOString()}&until=${until.toISOString()}&clientIds=${
    clientIds?.toString() || ""
  }&referrersName=${referrersName?.toString() || ""}&billType=${billType || ""}&employeeIds=${
    employeeIds?.toString() || ""
  }&visitType=${visitType || ""}&resourceCentreIds=${
    resourceCentreIds || ""
  }&includeProformaDrafts=${includeProformaDrafts}`;
  const response = await Get(`${url}`, true);
  return response.data;
};

export const labReport = async ({
  from,
  until,
  includeInternal,
  labStatus = [],
  referrersName = [],
  labTestsIds = [],
  labTestGroupIds = [],
  clientIds = [],
  labRecordType,
  page = 0,
  pageSize = 25,
  showLabTestInSingleRow = false
}: {
  from: Date;
  until: Date;
  labStatus: Array<LabStatuses>;
  includeInternal?: boolean;
  referrersName?: Array<{ id: number }>;
  labTestsIds?: Array<{ id: number }>;
  labTestGroupIds?: Array<{ id: number }>;
  clientIds?: number[];
  labRecordType?: LabRecordType;
  page: number;
  pageSize: number;
  showLabTestInSingleGroup: boolean;
}): Promise<LabReportInterface[]> => {
  const url = `${reportsRoot}/labReport?page=${page}&pageSize=${
    pageSize || ""
  }&from=${from.toISOString()}&until=${until.toISOString()}&labStatus=${labStatus}${
    includeInternal ? `&includeInternal=${includeInternal}` : ""
  }&referrers=${referrersName
    .map((referrer) => referrer.id)
    .toString()}&labTests=${labTestsIds}&labTestGroupIds=${labTestGroupIds}&clientIds=${clientIds}${
    labRecordType ? `&labRecordType=${labRecordType}` : ""
  }${showLabTestInSingleRow ? `&showLabTestInSingleRow=${showLabTestInSingleRow}` : ""}`;
  const response = await Get(`${url}`, true);
  return response?.data as LabReportInterface[];
};

export const getReceiptTally = async ({
  from,
  until
}: {
  from: Date;
  until: Date;
}): Promise<unknown> => {
  const url = `/api/tally/receipts?from=${from.toISOString()}&until=${until.toISOString()}`;
  const response = await Get(`${url}`, true);
  return response;
};

export const getStockExpiryReport = async ({
  stockStatus,
  productType,
  productItems
}: {
  stockStatus: StockStatusType[];
  productType: ProductType;
  productItems: number[];
}): Promise<StockExpiryData[]> => {
  const url = `${reportsRoot}/stockExpiryReport?productType=${productType}&stockStatus=${stockStatus}${
    productItems?.length ? `&productItems=${productItems}` : ""
  }`;
  const response = await Get(url, true);
  return response?.data as StockExpiryData[];
};

export const getStockAuditReports = async ({
  from,
  until,
  productCategory,
  productItems,
  onlyExpired,
  onlyBelowMinStock,
  includeBelowZero,
  stockExpiringIn,
  productType,
  resourceCentreIds
}: {
  from: Date;
  until: Date;
  productCategory: unknown;
  productItems: unknown;
  onlyExpired: boolean;
  onlyBelowMinStock: boolean;
  includeBelowZero: boolean;
  stockExpiringIn: Date | null;
  productType: ProductType.Sellable | ProductType.InternalUse;
  resourceCentreIds: number[];
}): Promise<unknown> => {
  const url = `${reportsRoot}`;
  const response = await Get(
    `${url}/stockAuditReport?from=${from.toISOString()}&until=${until.toISOString()}&productCategory=${productCategory}&productItems=${
      productItems || ""
    }&onlyExpired=${onlyExpired}&onlyBelowMinStock=${onlyBelowMinStock}&includeBelowZero=${includeBelowZero}&${
      stockExpiringIn && `stockExpiringIn=${stockExpiringIn}`
    }&productType=${productType}&resourceCentreIds=${resourceCentreIds || ""}`,
    true
  );
  return response.data;
};

export const getStockCashFlowReports = async (
  filters: Record<string, string>
): Promise<unknown> => {
  const url = `${reportsRoot}`;
  const response = await Get(`${url}/stockCashFlowReport`, true, filters);
  return response.data;
};

export const getSupplierLedgerReport = async (
  filters: Record<string, unknown>
): Promise<unknown> => {
  const url = `${reportsRoot}`;
  const f = cloneDeep(filters);
  f.supplierIds = f.supplierIds.join(", ");
  if (!filters.ignoreZeroDue) {
    delete f.ignoreZeroDue;
  }
  const response = await Get(`${url}/supplierLedgerReport`, true, f);
  return response.data;
};

export const getCustomerDueReport = async ({
  clientIds
}: FilterProps): Promise<CustomerDueReportInterface[]> => {
  const response = await Get(`/api/reports/customerDueReport?clientIds=${clientIds}`, true);
  return response.data as CustomerDueReportInterface[];
};

export const getCustomerVendorReport = async ({
  from,
  until,
  subLedgerType,
  vendorType,
  clientIds,
  supplierIds,
  employeeIds,
  referrersName,
  serviceProviderIds
}: FilterProps): Promise<CustomerVendorReportProps[]> => {
  const subLedgerIds =
    clientIds ||
    supplierIds ||
    employeeIds ||
    referrersName?.map(({ id }) => id) ||
    serviceProviderIds ||
    [];

  const response = await Get(
    `${accountReportsRoot}/customerVendorLedger?from=${from.toISOString()}&until=${until.toISOString()}
  &subLedgerType=${subLedgerType}${
      subLedgerType === "vendor" ? `&vendorType=${vendorType}` : ""
    }&subLedgerIds=${subLedgerIds}`,
    true
  );
  return response.data as CustomerVendorReportProps[];
};

export const getBankReconciliationReport = async (
  filters: FilterProps
): Promise<BankReconciliationReportInterface[]> => {
  const response = await Get(
    `${accountReportsRoot}/bankReconciliation?until=${filters.periodDate.toISOString()}&ledgerIds=${
      filters.ledgerIds
    }`,
    true
  );
  return response.data as BankReconciliationReportInterface[];
};

export const hmisLabReport = async (filters: {
  from: Date;
  until: Date;
  clinicSelect?: number;
}): Promise<Array<HMISReportRow> | undefined> => {
  const response = await Get(
    `${reportsRoot}/hmislab?from=${filters.from.toISOString()}&until=${filters.until.toISOString()}&resourceCentreId=${
      filters.clinicSelect || ""
    }`,
    true
  );
  return response?.data as Array<HMISReportRow>;
};

export const getClients = async (filters: {
  from: Date;
  until: Date;
  clientIds?: number[];
  showClientByDate: ShowClientByType;
  knownUsFrom: string;
}): Promise<Array<Client>> => {
  const response = await Get(
    `/api/clients?from=${filters.from.toISOString()}&until=${filters.until.toISOString()}&showClientBy=${
      filters.showClientByDate
    }&clientIds=${filters.clientIds}&knownUsFrom=${filters.knownUsFrom}`,
    true
  );
  return response.data as Array<Client>;
};

export const getHistoryReportData = async (filters: FilterProps): Promise<History[]> => {
  const response = await Get(
    `${reportsRoot}/history?from=${filters.from.toISOString()}&until=${filters.until.toISOString()}&entity=${
      filters.entity
    }`,
    true
  );
  return response.data as History[];
};

export const getReminderReport = async (filters: FilterProps): Promise<ReminderInterface[]> => {
  const response = await Get(
    `${reportsRoot}/reminder?from=${filters.from.toISOString()}&until=${filters.until.toISOString()}&entity=${
      filters.entity
    }&serviceProviderIds=${filters.serviceProviderIds}`,
    true
  );
  return response.data as ReminderInterface[];
};

export const getStockHistoryData = async (filters: FilterProps): Promise<StockHistory[]> => {
  const response = await Get(
    `${reportsRoot}/stockHistory?from=${filters.from.toISOString()}&until=${filters.until.toISOString()}&productType=${
      filters.productType
    }`
  );
  return response.data as StockHistory[];
};

// get proforma report
export const getIpdBillsReport = async ({
  billStatus: status,
  clientIds = [],
  from,
  until
}: {
  clientIds?: Array<number>;
  billStatus?: string;
  from?: Date;
  until?: Date;
}): Promise<BillType[]> => {
  const params = new URLSearchParams();

  if (status) params.append("status", status);
  if (from) params.append("from", from.toISOString());
  if (until) params.append("until", until.toISOString());
  if (clientIds.length > 0) params.append("clientIds", clientIds.join(","));

  const queryString = params.toString();
  const url = `/api/reports/ipdBills${queryString ? `?${queryString}` : ""}`;

  const response = await Get(url, true);
  return response.data as Promise<BillType[]>;
};
