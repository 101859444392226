import React from "react";
import { useDispatch } from "react-redux";

import "../index.css";

import {
  Grid2 as Grid,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  ListItemText,
  Box,
  CircularProgress,
  ListItemButton
} from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { blue, pink } from "@mui/material/colors";
import CloseIcon from "@mui/icons-material/Close";
import { TreeItem, SimpleTreeView } from "@mui/x-tree-view";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/CheckCircleOutline";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import {
  getBatchDiagnosis,
  getChildren,
  getSibblings,
  searchDisease
} from "../../../api/assessment";

import Search from "../SearchInputs";
import styles from "../Assessment.module.css";
import { tl, t } from "../../../components/translate";
import { Diagnosis as IDiagnosis } from "../../../interfaces/IpdInterface";
import { ICD } from "../../../interfaces/AssessmentInterfaces";
import { notificationAdd } from "../../../actions/notification";

const theme = createTheme({
  palette: {
    primary: blue,
    secondary: {
      light: "#ff79b0",
      main: pink.A200,
      dark: "#c60055",
      contrastText: "#fff"
    }
  }
});

const buildItemsHash = (items: ICD[] = [], parentMapHash = {}) => {
  const parentMap = { ...parentMapHash };
  items.forEach((item) => {
    parentMap[item.parentId] = parentMap[item.parentId] || {};
    parentMap[item.parentId][item.id] = item;
  });
  return parentMap;
};

const Leaf = ({ item, checkedLeaf, onCheck, onSelect, selectedTree }) => (
  <Box
    component="div"
    onClick={() => {
      onCheck(item.id);
      onSelect();
    }}
    display="flex"
  >
    {checkedLeaf === item.id && <CheckIcon />}
    <Typography component="span">
      <Box component="span">
        {selectedTree === item.id ? (
          <>
            <b>{item.code}</b>:{item.title}
          </>
        ) : (
          <>
            <b>{item.code} </b>:{item.title}
          </>
        )}
      </Box>
    </Typography>
  </Box>
);

const ChildLeaf = ({ item, selectedTree }) => (
  <Box component="div" display="flex">
    <Typography component="span">
      <Box component="span">
        {selectedTree === item.id ? (
          <>
            <b>{item.code}</b>:{item.title}
          </>
        ) : (
          <>
            <b>{item.code}</b>:{item.title}
          </>
        )}
      </Box>
    </Typography>
  </Box>
);

interface ItemHashType {
  [key: number]: ICD;
}

interface DiagnosisProps {
  label: string;
  diagnosis: IDiagnosis | undefined;
  hidetitle?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange: (v: any) => void;
}

const Diagnosis = ({ label, diagnosis, hidetitle, onChange }: DiagnosisProps): React.ReactNode => {
  const [open, setOpen] = React.useState(false);
  const [itemsHash, setItemsHash] = React.useState<ItemHashType>({});
  const [showTree, setShowTree] = React.useState(true);
  const [checkedLeaf, setCheckedLeaf] = React.useState(null);
  const [defaultExpand, setDefaultExpand] = React.useState<Array<string>>([]);
  const [clientPhoneNo, setClientPhoneNo] = React.useState("");
  const [selectedTree, setSelectedTree] = React.useState(undefined);
  const [diagnosisName, setDiagnosisName] = React.useState<string>("");

  const dispatch = useDispatch();
  const timeoutRef = React.useRef<number | undefined>(undefined);

  // Cleanup function to clear timeout on component unmount
  React.useEffect(
    () => () => {
      clearTimeout(timeoutRef.current);
    },
    []
  );

  React.useEffect(() => {
    (async () => {
      try {
        const res = await getBatchDiagnosis();
        setItemsHash(buildItemsHash(res, itemsHash));
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: "Error fetching diagnosis",
            autoTimeout: true
          })
        );
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (diagnosis && !isEmpty(diagnosis) && diagnosis.diagnosis) {
      const DiagnosisName = (diagnosis.icdCode ? diagnosis.icdCode : "") + diagnosis.diagnosis;
      setDiagnosisName(DiagnosisName);
    }
  }, [diagnosis]);

  const renderTreeItems = React.useCallback(
    (parentId = null): React.ReactNode => {
      if (!itemsHash[parentId]) {
        return null;
      }

      return (
        <>
          {Object.values(itemsHash[parentId]).map((item) => {
            const { isLeaf } = item;

            const itemLabel = isLeaf ? (
              <Leaf
                selectedTree={selectedTree}
                item={item}
                checkedLeaf={checkedLeaf}
                onSelect={() => {
                  setTimeout(() => {
                    setOpen(false);
                  }, 1000);
                }}
                onCheck={(id) => {
                  setCheckedLeaf(id);
                  setDiagnosisName(`${item.code} ${item.title}`);
                  onChange({
                    diagnosis: `${item.code} ${item.title}`,
                    icdCode: item.code
                  });
                }}
              />
            ) : (
              <ChildLeaf selectedTree={selectedTree} item={item} />
            );

            return (
              <TreeItem key={item.id} itemId={item.id} label={itemLabel}>
                {!isLeaf && (
                  <>
                    {itemsHash[item.id] ? renderTreeItems(item.id) : <CircularProgress size={16} />}
                  </>
                )}
              </TreeItem>
            );
          })}
        </>
      );
    },
    [itemsHash, selectedTree, checkedLeaf, onChange]
  );

  const search = React.useCallback(async (q) => {
    try {
      const result = await searchDisease(q);
      return result.filter(
        ({ title, code }) =>
          title.toLowerCase().includes(q.toLowerCase()) ||
          code.toLowerCase().includes(q.toLowerCase())
      );
    } catch (error) {
      return [];
    }
  }, []);

  const getAncestorsIds = React.useCallback((item, items) => {
    const map = items.reduce((acc, n) => {
      const newAcc = { ...acc };
      newAcc[n.id] = n;
      return newAcc;
    }, {});
    const parents: string[] = [];
    const addParent = (parentId: string) => {
      parents.push(parentId);
      const parent = map[parentId];
      if (parent) {
        addParent(parent.parentId);
      }
    };

    addParent(item.parentId);
    return parents;
  }, []);

  const openTreeFn = React.useCallback(
    async (item) => {
      setSelectedTree(item.id);

      if (!itemsHash[item.id]) {
        try {
          const response = await getSibblings(item.id);
          const allParentIds = getAncestorsIds(item, response);

          setDefaultExpand(allParentIds);
          setItemsHash((prevItemsHash) => buildItemsHash(response, prevItemsHash));

          setShowTree(true);
        } catch (error) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: "Error fetching siblings",
              autoTimeout: true
            })
          );
        }
      }
    },
    [dispatch, getAncestorsIds, itemsHash]
  );

  const handleClose = () => setOpen(false);
  const handleClickOpen = () => setOpen(true);

  const treeView = () =>
    showTree ? (
      <SimpleTreeView
        slots={{
          // eslint-disable-next-line react/jsx-props-no-spreading
          expandIcon: (props) => <ExpandMoreIcon {...props} />,
          // eslint-disable-next-line react/jsx-props-no-spreading
          collapseIcon: (props) => <ChevronRightIcon {...props} />
        }}
        defaultExpandedItems={defaultExpand}
        onExpandedItemsChange={(_, itemId) => {
          if (!itemsHash[itemId[0]]) {
            getChildren(Number(itemId[0])).then((res) => {
              setItemsHash(buildItemsHash(res, itemsHash));
            });
          }
        }}
      >
        {renderTreeItems()}
      </SimpleTreeView>
    ) : (
      ""
    );
  return (
    <>
      <Box className={styles.assessmentRow}>
        {!hidetitle && (
          <Box className={styles.assessmentLabel}>
            <Typography>
              <Box component="span" fontWeight="600">
                {label}
              </Box>
            </Typography>
          </Box>
        )}
        <Box className={styles.assessmentField} display="flex">
          <TextField
            fullWidth
            label={hidetitle ? label : ""}
            data-testmation="diagnosisTextField"
            onChange={(e) => {
              setDiagnosisName(e.target.value);
              onChange({ diagnosis: e.target.value });
            }}
            value={diagnosisName}
            margin="dense"
            variant="outlined"
            placeholder={t("assessment.typeDiagnosis")}
            slotProps={{
              input: {
                endAdornment: (
                  <Button
                    data-testmation="diagnosisButton"
                    variant="contained"
                    style={{ padding: 0, minWidth: "30px", cursor: "pointer" }}
                    onClick={handleClickOpen}
                  >
                    <Typography>
                      <Box component="span" fontWeight="600">
                        {tl("assessment.ICD")}
                      </Box>
                    </Typography>
                  </Button>
                )
              },
              inputLabel: { shrink: true }
            }}
          />
        </Box>
      </Box>
      <Dialog
        fullWidth
        maxWidth="md"
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle>
          <Box display="flex" justifyContent="space-between">
            <Typography>
              <Box component="span" fontWeight="600">
                {tl("assessment.diseaseSearch")}
              </Box>
            </Typography>
            <CloseIcon style={{ cursor: "pointer" }} onClick={handleClose} />
          </Box>
        </DialogTitle>

        <DialogContent>
          <Grid container justifyContent="space-between">
            <Grid size={12}>
              <Search
                data-testmation="diagnosisSearchTextField"
                searchFn={search}
                onChange={(e) => {
                  setClientPhoneNo(e);
                }}
                Namevalue={clientPhoneNo}
                placeholder={t("assessment.searchICD")}
                onItemSelect={(e) => {
                  setClientPhoneNo(`${e.code} ${e.title}`);
                  setOpen(false);
                  onChange({ icd: e.code, diagnosis: e.title });
                  openTreeFn(e);
                }}
                itemRenderer={({ title, code }) => (
                  <ListItemButton>
                    <ListItemText>
                      <div>
                        {code}: {title}
                      </div>
                    </ListItemText>
                  </ListItemButton>
                )}
              />
            </Grid>
          </Grid>

          <Box>
            <Typography>
              <Box component="span" fontWeight="600" pt={5} pb={3}>
                {tl("assessment.diseaseClassificationTree")}
              </Box>
            </Typography>

            <StyledEngineProvider injectFirst>
              <ThemeProvider theme={theme}>
                <Box>{treeView()}</Box>
              </ThemeProvider>
            </StyledEngineProvider>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default Diagnosis;
