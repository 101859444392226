import React, { JSX } from "react";

const EmptyBed = ({ color = "#009654" }: { color?: string }): JSX.Element => (
  <svg width="80" height="36" viewBox="0 0 80 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0.276204 35.8678C0.245845 35.7878 0.234297 27.6848 0.250497 17.8611L0.280026 0H4.30196H8.32388L8.35324 10.2846L8.3826 20.5691H43.7183H79.054L79.0244 28.2609L78.9949 35.9527H74.9155H70.8361L70.8058 32.2941L70.7754 28.6354H39.58H8.38459L8.35423 32.2941L8.32387 35.9527L4.32764 35.983C1.19223 36.0068 0.319522 35.9822 0.276204 35.8678Z"
      fill={color}
    />
    <path
      d="M11.0577 18.1533C11.2432 16.5 10.7999 15.6571 14.2432 15H27.7432C27.7432 15 43.295 14.8886 44.2432 14.9913C44.2432 14.9913 69.7432 15.5 75.0486 16.1753C75.0486 16.1753 76.2432 18.3512 75.0486 18.3797C74.4597 18.3937 71.0463 18.3512 71.0463 18.3512C68.845 18.3355 58.1805 18.3095 47.3475 18.2933L27.7264 18.3797H25.0527L18.2396 18.3792L11.4265 18.3788L11.0577 18.1533Z"
      fill={color}
    />
  </svg>
);

export default EmptyBed;
