import React from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { resourceCentreActions } from "../../actions";
import { SEARCH_BY, SearchType } from "./ChatStats";

interface Props {
  onSearch: (value: SearchType) => void;
}

const PublicResourceCentreSelector = ({ onSearch }: Props): JSX.Element => {
  const dispatch = useAppDispatch();
  const publicResourceCentres: ResourceCentre[] = useAppSelector((state) =>
    state.resources.resourceCentres.filter((item: ResourceCentre) => !!item.publicBooking)
  );

  React.useEffect(() => {
    if (!publicResourceCentres.length) {
      dispatch(resourceCentreActions.getResourceCentres());
    }
  }, []);

  return (
    <Box>
      <Autocomplete
        options={publicResourceCentres}
        getOptionLabel={(item) => item.name}
        sx={{ paddingLeft: "35px" }}
        fullWidth
        popupIcon={null}
        onChange={(_, newValue) => {
          onSearch({ by: SEARCH_BY.RESOURCECENTRE_ID, queryId: newValue?.id || null });
        }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            placeholder="Search..."
            margin="dense"
            size="small"
            slotProps={{
              input: {
                ...params.InputProps,
                disableUnderline: true
              }
            }}
          />
        )}
      />
    </Box>
  );
};

export default PublicResourceCentreSelector;
