import React from "react";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Box, Menu, MenuItem, Button, Divider, CircularProgress, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Clear } from "@mui/icons-material";
import DebouncedTextField from "../../components/DebouncedTextField";
import { ReverseMap } from "../../helpers/types";
import useLocalStorage from "../../hooks/useLocalStorage";

export enum CLIENT_SEARCH_BY {
  NAME = "name",
  // using name, contact infos and customer number for generic info
  GENERIC_INFO = "genericInfo",
  REGISTRATION_NO = "registrationNo",
  INTERNAL_NOTES = "internalNotes",
  OCCUPATION = "occupation"
}

export type ClientSearchByType = ReverseMap<typeof CLIENT_SEARCH_BY>;

const searchComponentMapper = {
  [CLIENT_SEARCH_BY.NAME]: {
    label: "Name",
    placeholder: "Type client name..."
  },
  [CLIENT_SEARCH_BY.GENERIC_INFO]: {
    label: "Generic",
    placeholder: "Name, email, phone, NIN..."
  },
  [CLIENT_SEARCH_BY.INTERNAL_NOTES]: {
    label: "Int. Notes",
    placeholder: "Internal notes..."
  },
  [CLIENT_SEARCH_BY.REGISTRATION_NO]: {
    label: "Reg. No",
    placeholder: "Registration number..."
  },
  [CLIENT_SEARCH_BY.OCCUPATION]: {
    label: "Occupation",
    placeholder: "Occupation"
  }
};

const menuItems = Object.keys(searchComponentMapper);

interface Props {
  value: string;
  onInputClear: () => void;
  loading: boolean;
  debounceLoading: boolean;
  onDebounceLoadingChange: (value: boolean) => void;
  onInputChange: (value: string) => void;
  onSearchByChange: (value: string) => void;
}

const ClientSearchComponents = ({
  value,
  onInputClear,
  loading,
  debounceLoading,
  onInputChange,
  onDebounceLoadingChange,
  onSearchByChange
}: Props): JSX.Element => {
  const [filterBy, setSearchFilters] = useLocalStorage<{
    client: string;
  }>({
    key: "searchFilters"
  });
  const toggleRef = React.useRef(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [activeMenuItem, setActiveMenuItem] = React.useState(menuItems[0]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  React.useEffect(() => {
    if (filterBy?.client) {
      onSearchByChange(filterBy.client);
      setActiveMenuItem(filterBy.client);
    }
  }, [filterBy]);

  const toggleMenu = (
    <div>
      <Button
        aria-controls="search-menu"
        aria-haspopup="true"
        onClick={handleClick}
        sx={{ whiteSpace: "pre" }}
      >
        {searchComponentMapper[activeMenuItem].label} <ExpandMoreIcon />
      </Button>
      <Menu
        ref={toggleRef}
        id="search-by-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item}
            onClick={() => {
              setAnchorEl(null);
              setActiveMenuItem(item);
              onSearchByChange(item);
              setSearchFilters({ ...filterBy, client: item });
            }}
          >
            {searchComponentMapper[item].label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );

  return (
    <Box
      sx={(theme) => ({
        position: "relative",
        borderRadius: 1,
        width: "300px",
        overflow: "hidden",
        display: "flex",
        alignItems: "center",
        transition: "width .5s, background 1s ease",
        backgroundColor: "#ececec",
        [theme.breakpoints.down("sm")]: {
          width: "200px"
        }
      })}
      data-testmation="searchInput"
    >
      <SearchIcon sx={{ position: "absolute", width: "35px" }} />
      <Box sx={{ mt: 1, width: "220px" }}>
        <DebouncedTextField
          sx={{ ml: 4 }}
          value={value}
          queryLength={0}
          placeholder={searchComponentMapper[activeMenuItem].placeholder}
          size="small"
          slotProps={{
            htmlInput: { maxLength: 30 },
            input: {
              disableUnderline: true
            }
          }}
          onChange={(e) => {
            onInputChange(e.target.value);
          }}
          debounceAt={1000}
          setDebounceLoading={onDebounceLoadingChange}
        />
      </Box>
      {loading || debounceLoading ? (
        <Box sx={{ display: "grid", placeItems: "center", mr: 1 }}>
          <CircularProgress size={20} />
        </Box>
      ) : (
        value && (
          <IconButton onClick={onInputClear} size="small">
            <Clear />
          </IconButton>
        )
      )}
      <Divider sx={{ height: "20px" }} orientation="vertical" />
      {toggleMenu}
    </Box>
  );
};

export default ClientSearchComponents;
