import React, { useMemo, useState } from "react";
import { Card, Box, Typography, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import { useDispatch } from "react-redux";
import classNames from "../../../helpers/classNames";
import Header from "./Header";
import ActiveBookings from "./ActiveBookings";
import PastBookings from "./PastBookings";
import { useDashboardBookingEvents } from "../hooks/useDashboard";
import ClientCreateEdit from "../../Client/ClientCreateEdit";
import ClientInfo from "../../Client/ClientInfo/ClientInfo";
import EventPopup from "../../Calendar/PopUp";
import { eventsSelector } from "../../../reducers/bookings";
import { getServices } from "../../../actions";
import useFullscreen from "../hooks/useFullScreen";
import FullScreenHeader from "./FullScreenHeader";
import "./styles.css";
import poweredByOkhatiImage from "../../../../assets/images/poweredByOkhati.svg";
import { Booking } from "../../../interfaces/BookingInterfaces";
import { BookingStatus } from "../../Bookings/ManageBooking";
import { ReverseMap } from "../../../helpers/types";
import { getSpFullName } from "../../Client/ClientInfo/PrintClientInfoSticker";
import { useAppSelector } from "../../../store/hooks";
import { UserMode } from "../../../interfaces/User";
import EmptyListIcon from "../../../../assets/icons/EmptyListIcon";

const useStyles = makeStyles((theme) => ({
  root: {
    background: "#FFFFFF",
    boxShadow: "0px 4px 20px rgba(0, 150, 84, 0.12)",
    borderRadius: "8px",
    padding: "0",
    [theme.breakpoints.down(960)]: {
      marginLeft: 0
    }
  },
  fullScreenContainer: {
    background: "#E5E5E5;",
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: "40px"
  },
  cardContainer: {
    minWidth: "762px",
    maxWidth: "1440px",
    margin: "auto",
    height: "643px",
    padding: "40px 56px 20px"
  },
  poweredBy: {
    display: "flex",
    alignItems: "center",
    minWidth: "762px",
    maxWidth: "1440px",
    justifyContent: "flex-end"
  }
}));

const sortOrder = [BookingStatus.engaged, BookingStatus.waiting, BookingStatus.reserved];
const activeBookingStatus = [BookingStatus.reserved, BookingStatus.waiting, BookingStatus.engaged];
const pastBookingStatus = Object.values(BookingStatus).filter(
  (status) => !activeBookingStatus.includes(status)
);

const getPastBookings = (bookings, showByStatus, selectedDepartmentId) => {
  let filteredBookings = [] as Booking[];
  if (showByStatus) {
    filteredBookings = bookings.filter((book) => pastBookingStatus.includes(book.status));
  } else {
    filteredBookings = bookings.filter((book) => moment(book?.end) < moment());
  }
  if (selectedDepartmentId) {
    filteredBookings = filteredBookings.filter(
      (book) => book.departmentId === Number(selectedDepartmentId)
    );
  }
  return filteredBookings;
};

const getActiveBookings = (bookings, showByStatus, selectedDepartmentId, showFollowUpOnly) => {
  let filteredBookings = [] as Array<Booking>;
  if (showByStatus) {
    filteredBookings = bookings.filter((book) => activeBookingStatus.includes(book.status));
  } else {
    filteredBookings = bookings.filter((book) => moment(book?.end) >= moment());
  }
  if (selectedDepartmentId) {
    filteredBookings = filteredBookings.filter(
      (book) => book.departmentId === selectedDepartmentId
    );
  }
  filteredBookings = showFollowUpOnly
    ? filteredBookings.filter((book) => book.followUp)
    : filteredBookings;
  return [...filteredBookings].sort(
    (a, b) => sortOrder.indexOf(a.status) - sortOrder.indexOf(b.status)
  );
};

export enum GROUP_BOOKINGS_BY {
  NONE = "none",
  BOOKABLE_RESOURCE = "bookableResource",
  SERVICE_PROVIDER = "serviceProvider",
  BOOKING_TYPE = "bookingType"
}

export enum BOOKING_TYPE {
  REGULAR = "regular",
  FOLLOW_UP = "followUp"
}

export type GroupBookingsByType = ReverseMap<typeof GROUP_BOOKINGS_BY>;

export interface GroupedBooking {
  groupName: string;
  bookings: Booking[];
}

const getGroupedBookings = ({
  bookings,
  groupBookingsBy,
  departmentId
}: {
  bookings: Booking[];
  groupBookingsBy: GroupBookingsByType;
  departmentId: number | null;
}): GroupedBooking[] => {
  if (groupBookingsBy === GROUP_BOOKINGS_BY.NONE) return [];

  const groupedBookings: { groupName: string; bookings: Booking[] }[] = [];
  const groupNames: string[] = [];
  const nameMap: { [key: number | string]: boolean } = {};
  let filteredBooking: Booking[] = [];

  if (departmentId) {
    filteredBooking = bookings.filter((booking) => booking.departmentId === departmentId);
  }

  filteredBooking = bookings.filter((book) => moment(book?.end) >= moment());

  // get unique group names
  filteredBooking.forEach((booking) => {
    if (groupBookingsBy === GROUP_BOOKINGS_BY.BOOKABLE_RESOURCE) {
      const id = booking.bookableResourceId;
      if (!nameMap[id]) {
        groupNames.push(booking.bookableResource?.name || "General Booking");
        nameMap[id] = true;
      }
    }
    if (groupBookingsBy === GROUP_BOOKINGS_BY.SERVICE_PROVIDER) {
      const id = booking.serviceProviderId;
      if (!nameMap[id]) {
        groupNames.push(
          booking.serviceProvider ? getSpFullName(booking.serviceProvider) : "Unassigned"
        );
        nameMap[id] = true;
      }
    }
    if (groupBookingsBy === GROUP_BOOKINGS_BY.BOOKING_TYPE) {
      const type = booking.bookingType;
      if (!nameMap[type]) {
        groupNames.push(booking.serviceProvider ? booking.bookingType : BOOKING_TYPE.REGULAR);
        nameMap[type] = true;
      }
    }
  });

  // group booking according to group names
  groupNames.forEach((groupName) => {
    if (groupBookingsBy === GROUP_BOOKINGS_BY.BOOKABLE_RESOURCE) {
      groupedBookings.push({
        groupName,
        bookings: filteredBooking.filter((booking) => {
          if (booking.bookableResource?.name) {
            return groupName === booking.bookableResource.name;
          }
          return groupName === "General Booking";
        })
      });
    }
    if (groupBookingsBy === GROUP_BOOKINGS_BY.SERVICE_PROVIDER) {
      groupedBookings.push({
        groupName,
        bookings: filteredBooking.filter(
          (booking) =>
            (booking.serviceProvider ? getSpFullName(booking.serviceProvider) : "Unassigned") ===
            groupName
        )
      });
    }
    if (groupBookingsBy === GROUP_BOOKINGS_BY.BOOKING_TYPE) {
      groupedBookings.push({
        groupName,
        bookings: filteredBooking
          .filter((booking) => {
            if (booking.bookingType) {
              return groupName === booking.bookingType;
            }
            return groupName === BOOKING_TYPE.REGULAR;
          })
          .sort((a, b) => {
            if (getSpFullName(a.serviceProvider) > getSpFullName(b.serviceProvider)) return 1;
            if (getSpFullName(a.serviceProvider) < getSpFullName(b.serviceProvider)) return -1;
            return 0;
          })
      });
    }
  });

  return groupedBookings;
};

const LivePatientFlow = ({
  selectedDate,
  bookings,
  refetch,
  selectedDepartmentId,
  isBookingFetching
}: {
  selectedDate: moment.Moment;
  bookings: Array<Booking>;
  selectedDepartmentId: number | null;
  refetch: () => void;
  isBookingFetching: boolean;
}): React.ReactElement => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isServiceProvider, userId } = useAppSelector((state) => ({
    isServiceProvider: state?.userContext.userCreds.authenticable === UserMode.ServiceProvider,
    userId: state?.userContext.user.id
  }));
  const [showFollowUpOnly, setShowFollowUpOnly] = useState(false);

  React.useEffect(() => {
    dispatch(getServices());
  }, [dispatch]);

  const events = useDashboardBookingEvents();
  const anchorEl = React.useRef(null);

  const [booking, setBooking] = React.useState({});
  const [showBooking, setShowBooking] = React.useState(false);
  const handleClick = (e) => {
    e.stopPropagation();
    setShowBooking(true);
  };
  const [editOpen, toggleEditOpen] = React.useState(false);

  const [showClientInfoPanel, setShowClientInfoPanel] = React.useState(false);
  const [clientId, setClientId] = React.useState(null);
  const [clientEditMode, setClientEditMode] = React.useState(false);
  const [groupBookingsBy, setGroupBookingsBy] = useState<GroupBookingsByType>(
    GROUP_BOOKINGS_BY.NONE
  );

  const sortedBookings = (
    isServiceProvider
      ? bookings.filter((bookingItem) => bookingItem.serviceProviderId === userId)
      : bookings
  ).sort((a, b) => new Date(a.start).getTime() - new Date(b.start).getTime());

  const [showByStatus, setShowByStatus] = React.useState<boolean>(false);

  React.useEffect(() => {
    const val = localStorage.getItem("SHOW_PATIENT_FLOW_BY_STATUS");
    if (!val) return;
    setShowByStatus(JSON.parse(val));
  }, []);
  const pastBookings = useMemo(
    () => getPastBookings(sortedBookings, showByStatus, selectedDepartmentId),
    [sortedBookings, showByStatus, selectedDepartmentId]
  );
  const activeBookings = useMemo(
    () => getActiveBookings(sortedBookings, showByStatus, selectedDepartmentId, showFollowUpOnly),
    [sortedBookings, showByStatus, selectedDepartmentId, showFollowUpOnly]
  );

  const { fullscreenRef, enterFullscreen, exitFullscreen, fullscreenActive } = useFullscreen();

  return (
    <>
      <Box data-testmation="patientFlow" ref={anchorEl} sx={{ height: "100%" }}>
        <Box
          className={fullscreenActive ? classes.fullScreenContainer : ""}
          ref={fullscreenRef}
          sx={{
            height: "100%",
            minHeight: "25rem"
          }}
        >
          {fullscreenActive && <FullScreenHeader />}
          <Card
            className={classNames(classes.root, "noScroll")}
            sx={{
              height: fullscreenActive ? "auto" : "100%",
              overflow: "auto"
            }}
          >
            <Box sx={{ height: "100%" }} className={fullscreenActive ? classes.cardContainer : ""}>
              <Header
                onGroupBookingByChange={(value) => setGroupBookingsBy(value)}
                groupBookingsBy={groupBookingsBy}
                showByStatus={showByStatus}
                setShowByStatus={() => {
                  localStorage.setItem(
                    "SHOW_PATIENT_FLOW_BY_STATUS",
                    JSON.stringify(!showByStatus)
                  );
                  setShowByStatus((prev) => !prev);
                }}
                fullscreenActive={fullscreenActive}
                exitFullscreen={exitFullscreen}
                enterFullscreen={enterFullscreen}
                showFollowUpOnly={showFollowUpOnly}
                setShowFollowUpOnly={setShowFollowUpOnly}
              />
              <Box sx={{ padding: "0 20px", height: "100%" }}>
                {groupBookingsBy === GROUP_BOOKINGS_BY.NONE && !!pastBookings.length && (
                  <PastBookings
                    bookings={pastBookings}
                    setClientId={setClientId}
                    setShowClientInfoPanel={setShowClientInfoPanel}
                    setBooking={setBooking}
                    handleClick={handleClick}
                    isToday={selectedDate.isSame(moment(), "day")}
                  />
                )}
                {!!activeBookings.length && (
                  <ActiveBookings
                    groupBookingsBy={groupBookingsBy}
                    groupedBookings={getGroupedBookings({
                      bookings: sortedBookings,
                      groupBookingsBy,
                      departmentId: selectedDepartmentId
                    })}
                    bookings={activeBookings}
                    setClientId={setClientId}
                    setShowClientInfoPanel={setShowClientInfoPanel}
                    setBooking={setBooking}
                    handleClick={handleClick}
                  />
                )}
                {!activeBookings.length && !pastBookings.length && (
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    height="80%"
                    flexDirection="column"
                    gap={1}
                    sx={{
                      height: "80%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 1
                    }}
                  >
                    {isBookingFetching ? (
                      <CircularProgress />
                    ) : (
                      <>
                        <EmptyListIcon />
                        <Typography>No bookings</Typography>
                      </>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          </Card>
          {fullscreenActive && (
            <Box className={classes.poweredBy}>
              <img src={poweredByOkhatiImage} alt="Power by okhati" style={{ width: "200px" }} />
            </Box>
          )}
        </Box>

        {showClientInfoPanel &&
          clientId &&
          (clientEditMode ? (
            <ClientCreateEdit
              setEditMode={setClientEditMode}
              clientId={clientId}
              mode="edit"
              stayOnCurrentPage
              onCancel={() => {
                setClientEditMode(false);
              }}
            />
          ) : (
            <ClientInfo
              id={clientId}
              handleViewClose={() => {
                setShowClientInfoPanel(false);
              }}
              setEditMode={setClientEditMode}
              stayOnCurrentPage
            />
          ))}
      </Box>
      {showBooking && (
        <EventPopup
          anchorEl={() => anchorEl}
          open={showBooking}
          infoMode={!editOpen}
          onClose={() => {
            setShowBooking(false);
            toggleEditOpen(false);
            refetch();
          }}
          events={eventsSelector(events)}
          event={booking}
          onSave={async () => {
            await refetch();
            setShowBooking(false);
            toggleEditOpen(false);
          }}
          onEdit={() => toggleEditOpen(true)}
        />
      )}
    </>
  );
};

export default LivePatientFlow;
