import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import produce from "immer";
import CustomHeader from "../../Lab/LabPrint/CustomHeader";
import ImageCrop from "../../../components/ImageCrop/ImageCrop";
import { customHeaderImagePresignedS3 } from "../../../api/resourceCentre";
import { notificationAdd } from "../../../actions/notification";
import { LabSettings, ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { AsyncAction } from "../../../actions/actionHelpers";

const HeaderImageSettings = ({
  labSettings,
  fileUploaderRef,
  onImageChange,
  resourceCentre,
  actions,
  isCropperOpen,
  setIsCropperOpen,
  tempImg
}: {
  labSettings: LabSettings;
  fileUploaderRef: HTMLInputElement;
  onImageChange: (e) => Promise<void>;
  resourceCentre: ResourceCentre;
  isCropperOpen: boolean;
  setIsCropperOpen: (data) => void;
  tempImg: Blob | null;
  actions: {
    [x: string]: (options?: Record<string, unknown>, next?: unknown) => AsyncAction<unknown>;
  };
}): JSX.Element => {
  const dispatch = useDispatch();

  return (
    <Box mt={2}>
      <Typography sx={{ fontWeight: 600 }} gutterBottom>
        Custom Print Header (772px * 130px)
      </Typography>
      <Box>
        {labSettings.customHeaderImage && <CustomHeader imageUrl={labSettings.customHeaderImage} />}
      </Box>
      <label htmlFor="fileUploader">
        <input
          className="imageUpload"
          data-testmation="uploadBannerInput"
          type="file"
          accept=".jpg,.jpeg,.gif,.png"
          ref={fileUploaderRef}
          name="fileUploader"
          onChange={onImageChange}
          id="fileUploader"
          style={{
            display: "none"
          }}
        />
        <Button variant="contained" component="span">
          Upload Custom Header
        </Button>
      </label>
      {labSettings.customHeaderImage && (
        <Button
          variant="contained"
          sx={{ backgroundColor: "red", marginLeft: 1 }}
          onClick={() => {
            const newRc = produce(resourceCentre, (draft) => {
              draft.labSettings.customHeaderImage = "";
            });
            dispatch(actions.putResourceCentre(newRc));
          }}
        >
          Remove Header Image
        </Button>
      )}
      <ImageCrop
        image={tempImg}
        dynamicRatio={false}
        defaultRatio={{ x: 6, y: 1 }}
        isOpen={isCropperOpen}
        onClose={() => setIsCropperOpen(false)}
        onSave={async (img) => {
          try {
            const data = await customHeaderImagePresignedS3(img);
            if (!data) {
              return;
            }
            const newRc = produce(resourceCentre, (draft) => {
              draft.labSettings.customHeaderImage = data;
            });
            dispatch(actions.putResourceCentre(newRc));
          } catch (err) {
            dispatch(
              notificationAdd({
                id: new Date().getUTCMilliseconds(),
                variant: "error",
                message: "Failed to upload custom header",
                autoTimeout: true
              })
            );
          }
        }}
      />
    </Box>
  );
};

export default HeaderImageSettings;
