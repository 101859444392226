import { TextField } from "@mui/material";
import * as React from "react";
import {
  DiscountBasis,
  DiscountTypes,
  StockItemInterface,
  TransactionType
} from "../../../../interfaces/StockInterfaces";
import { t } from "../../../../components/translate";

interface Props {
  item: StockItemInterface;
  transactionType: TransactionType;
  onDiscountUpdate: (v) => void;
  discountSettings: { discountType: DiscountTypes; discountBasis: DiscountBasis };
}

export default function StockDiscount(props: Props): JSX.Element {
  const { item, transactionType, discountSettings, onDiscountUpdate } = props;
  return (
    <div>
      <TextField
        value={
          discountSettings.discountType === DiscountTypes.PERCENT
            ? item.discountPct
            : item.discountAmt
        }
        disabled={
          [
            "internalUse",
            "internalReturn",
            "excess",
            "expiryOrDiscardment",
            "editPurchase",
            "adjustment"
          ].includes(transactionType) ||
          discountSettings.discountBasis === DiscountBasis.TOTAL ||
          item.isFree
        }
        label={`${t("StockEntry.Disc")}${
          discountSettings.discountType === DiscountTypes.PERCENT ? "%" : "Rs"
        }`}
        fullWidth
        margin="none"
        type="number"
        onFocus={(e) => e.target.select()}
        variant="outlined"
        slotProps={{
          input: {
            endAdornment: discountSettings.discountType === DiscountTypes.PERCENT && "%",
            startAdornment: discountSettings.discountType === DiscountTypes.AMOUNT && "रू"
          },
          inputLabel: { shrink: true }
        }}
        onChange={(e) => {
          const discount = Number(e.target.value);
          onDiscountUpdate(discount);
        }}
      />
    </div>
  );
}
