import React, { ReactElement } from "react";
import { Box, Card, CardContent, Grid2 as Grid, Typography } from "@mui/material";
import moment, { Moment } from "moment-timezone";
import {
  ReceiptLong as ReceiptLongIcon,
  RequestQuote as RequestQuoteIcon,
  Receipt as ReceiptIcon,
  Person as PersonIcon,
  Medication as MedicationIcon,
  Paid as PaidIcon,
  AllInbox as AllInboxIcon,
  Info as InfoIcon,
  Science as ScienceIcon
} from "@mui/icons-material";
import TextsmsIcon from "@mui/icons-material/Textsms";
import { useDispatch, useSelector } from "react-redux";
import { push } from "connected-react-router";
import Tooltip from "@mui/material/Tooltip";
import { useDashboardKeyIndicators } from "./hooks/useDashboard";
import { roundTwoDecimalPoint } from "../../helpers/number";
import Can from "../Policy/Can";
import { RootState } from "../../store";
import { getRCFromState } from "../../actions/resourceCentre";
import { t, tl } from "../../components/translate";
import { LabStatuses } from "../../interfaces/Lab";

export const StatCard = ({
  title,
  totalValue,
  isMoney,
  icon,
  cssStyle,
  onClick,
  toolTipInfo
}: {
  title: string;
  totalValue: unknown;
  isMoney?: boolean;
  icon: unknown;
  cssStyle?: React.CSSProperties;
  onClick?: () => void;
  toolTipInfo?: string;
}): JSX.Element => (
  <Card
    sx={{
      borderRadius: "8px",
      boxShadow: "0px 0px 4px rgba(0, 150, 84, 0.3)",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "50%",
      flexGrow: 1,
      height: "99px",
      p: 0,
      marginTop: cssStyle?.marginTop,
      textDecoration: "none",
      "&:hover": onClick && {
        boxShadow: "0px 4px 10px rgb(0 150 84 / 25%)",
        cursor: "pointer"
      }
    }}
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...(!toolTipInfo && { title: "Click to view details" })}
    onClick={() => typeof onClick === "function" && onClick()}
  >
    <CardContent
      sx={{
        p: "10px",
        display: "flex",
        justifyContent: "space-between",
        width: "100%"
      }}
    >
      <div>
        <Box sx={{ display: "flex", gap: 1 }}>
          <Typography
            sx={{
              fontSize: 16,
              fontWeight: 500,
              color: "#5A607F"
            }}
            variant="h3"
          >
            {title}
          </Typography>
          {toolTipInfo && (
            <Tooltip
              title={
                <Box component="span" sx={{ fontSize: "0.75rem" }}>
                  {toolTipInfo}
                </Box>
              }
              placement="right"
              arrow
            >
              <InfoIcon color="action" />
            </Tooltip>
          )}
        </Box>
        <Typography
          sx={{
            fontSize: 18,
            fontWeight: 700,
            lineHeight: "36px"
          }}
          variant="h2"
        >
          <>
            {isMoney && "Rs"} {totalValue}
          </>
        </Typography>
      </div>
    </CardContent>
    <CardContent sx={{ p: 1 }}>
      <Box
        sx={{
          width: "40px",
          height: "40px",
          backgroundColor: "rgba(0, 150, 29, 0.08)",
          borderRadius: "50%",
          display: "flex",
          justifyContent: "center",
          marginTop: "10px",
          alignItems: "center",
          color: "#009654"
        }}
      >
        {icon}
      </Box>
    </CardContent>
  </Card>
);

StatCard.defaultProps = {
  isMoney: false,
  cssStyle: {},
  onClick: undefined
};

const CardStats = ({ selectedDate }: { selectedDate: Moment }): ReactElement => {
  const dispatch = useDispatch();
  const selectedBranch = useSelector(
    (state: RootState) => state.userBranch.selectedBranchForDashboard
  );

  const data = useDashboardKeyIndicators(
    moment(selectedDate).startOf("d").toISOString(),
    moment(selectedDate).endOf("d").toISOString(),
    selectedBranch
  );
  const iconSize = { width: "24px", height: "24px" };
  const resourceCentre = useSelector(({ resources, userContext }: RootState) =>
    getRCFromState({ userContext, resources })
  );

  const navigateTo = (url: string) => dispatch(push(url));

  return (
    <>
      <Can policyAccessKey="dashboard:accessStatistics">
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            mb: 2,
            justifyContent: "space-between"
          }}
        >
          <Typography sx={{ fontSize: "1rem" }}>{tl("dashboard.dailykpis")}</Typography>
          {data.lastCachedAt && (
            <Typography variant="body1" fontWeight={600}>
              {`${t("dashboard.lastupdatedat")}: ${moment
                .tz(data.lastCachedAt, "Asia/kathmandu")
                .format("hh:mm A")}`}
            </Typography>
          )}
        </Box>
        <Grid container spacing={0}>
          <Can policyAccessKey="deny">
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
              <StatCard
                title="Total Dues"
                totalValue={roundTwoDecimalPoint(data?.duesUntilNow) || 0}
                isMoney
                icon={<RequestQuoteIcon style={iconSize} />}
              />
            </Grid>
          </Can>
          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
            <StatCard
              title={tl("card.sales")}
              totalValue={roundTwoDecimalPoint(data?.totalSalesToday || 0)}
              isMoney
              icon={<ReceiptLongIcon style={iconSize} />}
              onClick={() => navigateTo("/reports/financial/salesReport")}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
            <StatCard
              title={tl("card.receipt")}
              totalValue={roundTwoDecimalPoint(data?.totalReceiptToday || 0)}
              isMoney
              icon={<ReceiptIcon style={iconSize} />}
              onClick={() => navigateTo("/reports/financial/receiptReport")}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
            <StatCard
              title={tl("card.newClients")}
              totalValue={data?.customerCountToday || 0}
              icon={<PersonIcon style={iconSize} />}
              onClick={() => navigateTo("/clients?filter=visitedToday")}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
            <StatCard
              title={tl("card.oldClients")}
              toolTipInfo="WalkIn Customers are counted as 1 client."
              totalValue={data?.followupCustomerCountToday || 0}
              icon={<PersonIcon style={iconSize} />}
              onClick={() => navigateTo("/clients?filter=visitedToday")}
            />
          </Grid>
          <Can policyAccessKey="allow" superAdminPassReverse>
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
              <StatCard
                title={t("card.remainingSms")}
                totalValue={data?.totalMessagesLeft || 0}
                isMoney={false}
                icon={<TextsmsIcon style={iconSize} />}
                onClick={() =>
                  navigateTo(`/resourcecentres/${resourceCentre.id}/subscriptions?tab=sms`)
                }
              />
            </Grid>
          </Can>
          <Can policyAccessKey="allow" superAdminPassReverse>
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
              <StatCard
                title={t("dashboard.smsDelivered")}
                totalValue={data?.smsCountToday || 0}
                isMoney={false}
                icon={<TextsmsIcon style={iconSize} />}
                onClick={() =>
                  navigateTo(`/resourcecentres/${resourceCentre.id}/subscriptions?tab=sms`)
                }
              />
            </Grid>
          </Can>
          <Can policyAccessKey="allow" superAdminPassReverse>
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
              <StatCard
                title={t("dashboard.createdTests")}
                totalValue={data?.createdLabRecordsToday || 0}
                isMoney={false}
                icon={<ScienceIcon style={iconSize} />}
                onClick={() => navigateTo(`/lab/labRecords/all?status=${LabStatuses.ORDERED}`)}
              />
            </Grid>
          </Can>
          <Can policyAccessKey="allow" superAdminPassReverse>
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
              <StatCard
                title={t("dashboard.finalizedTests")}
                totalValue={data?.finalizedLabRecordsToday || 0}
                isMoney={false}
                icon={<ScienceIcon style={iconSize} />}
                onClick={() => navigateTo(`/lab/labRecords/all?status=${LabStatuses.RESULT_READY}`)}
              />
            </Grid>
          </Can>
          <Can policyAccessKey="allow" superAdminPassReverse>
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
              <StatCard
                title={t("dashboard.prescriptionCount")}
                totalValue={data?.assessmentsToday || 0}
                isMoney={false}
                icon={<MedicationIcon style={iconSize} />}
                onClick={() => navigateTo(`/assessment/assessments`)}
              />
            </Grid>
          </Can>
          <Can policyAccessKey="allow" superAdminPassReverse>
            <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
              <StatCard
                title={t("dashboard.followUp")}
                totalValue={data?.followupAssessmentsToday || 0}
                isMoney={false}
                icon={<MedicationIcon style={iconSize} />}
                onClick={() => navigateTo(`/assessment/assessments`)}
              />
            </Grid>
          </Can>
        </Grid>
      </Can>

      <Can policyAccessKey="stock:listStock">
        <Box sx={{ mb: "16px" }} pt={2}>
          <Typography sx={{ fontSize: "16px", fontWeight: 500 }}>
            {tl("dashboard.stockKPI")}
          </Typography>
        </Box>
        <Grid container spacing={2}>
          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
            <StatCard
              title={tl("card.stockValuation")}
              totalValue={(data?.totalStockValuation || 0).toFixed(2)}
              isMoney
              icon={<PaidIcon style={iconSize} />}
              onClick={() =>
                navigateTo("/reports/stock/stockAuditReport?filter=totalStockValuation")
              }
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
            <StatCard
              title={tl("card.expiringStocks")}
              totalValue={(data?.expiringStockCount || 0).toFixed(2)}
              icon={<MedicationIcon style={iconSize} />}
              onClick={() => navigateTo("/reports/stock/stockAuditReport?filter=showExpiring")}
            />
          </Grid>
          <Grid size={{ xs: 12, sm: 6, md: 6, lg: 3 }}>
            <StatCard
              title={tl("card.minimalStocks")}
              totalValue={(data?.minimalStockCount || 0).toFixed(2)}
              icon={<AllInboxIcon style={iconSize} />}
              onClick={() => navigateTo("/reports/stock/stockAuditReport?filter=showOnlyMinStocks")}
            />
          </Grid>
        </Grid>
      </Can>
    </>
  );
};

export default CardStats;
