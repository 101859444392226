import * as React from "react";
import { Box, TextField, Autocomplete, createFilterOptions, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { connect } from "react-redux";
import { serviceProviderActions as actions } from "../../actions";
import { spFullNameSelector, serviceProvidersSortedSelector } from "../../reducers/serviceProvider";
import { t } from "../../components/translate";
import { getSpeciality } from "../../reducers/serviceTags";
import { ServiceProvider } from "../../interfaces/ServiceProvidersInterface";
import { IServiceTag } from "../../interfaces/ServiceTagsInterface";
import { canUseSpecialities } from "./ServiceproviderShow";

const useStyles = makeStyles({
  textLight: {
    opacity: 0.6
  }
});

const processSpecialties = (sps: Array<ServiceProvider>, tags: IServiceTag) =>
  sps.map((sp) => {
    let { speciality } = sp;
    if (canUseSpecialities(sp.user?.userGroups[0]?.name, sp.specialities)) {
      speciality = getSpeciality(tags, sp.specialities[0]) || "";
    } else {
      speciality = sp.speciality || "";
    }
    return {
      ...sp,
      displaySpeciality: speciality
    };
  });

const optionsForSelect = (serviceProviders, limitBy = [], tags) => {
  let filterdServiceProviders = processSpecialties(serviceProviders, tags);
  if (limitBy.length) {
    filterdServiceProviders = serviceProviders.filter(({ id }) => limitBy.includes(id));
  }
  return filterdServiceProviders.map((sp) => ({
    label: spFullNameSelector(sp),
    value: sp.id,
    serviceProvider: sp
  }));
};

interface AutocompleteOptionsType {
  label: string;
  value: number;
  serviceProvider: ServiceProvider;
}

const filter = createFilterOptions<AutocompleteOptionsType>();

interface Props {
  resourceCentreId: number;
  serviceProviderId: number;
  serviceProviders: Array<ServiceProvider>;
  placeholder: string;
  label?: string;
  limitBy: Array<{ id: number & Record<string, any> }>;
  onChange: (id: number | null, sp: ServiceProvider) => void;
  loadServiceProviders: (v: number) => void;
  disabled: boolean;
  isClearable: boolean;
  serviceTags: Array<IServiceTag>;
  hideLabel: boolean;
  disableDefaultError?: boolean;
  withSpAssign?: boolean;
}

export const ServiceProviderAutoSelect = ({
  resourceCentreId,
  serviceProviderId,
  serviceProviders,
  placeholder,
  label,
  limitBy,
  onChange,
  loadServiceProviders,
  disabled,
  isClearable = false,
  hideLabel = false,
  serviceTags: tags,
  disableDefaultError,
  withSpAssign = false
}: Props): JSX.Element | null => {
  const classes = useStyles();

  React.useEffect(() => {
    if ((!serviceProviders || !serviceProviders.length) && resourceCentreId) {
      loadServiceProviders(resourceCentreId);
    }
  }, []);

  const optionsForSelectMemoized = React.useMemo(
    () => optionsForSelect(serviceProviders || [], limitBy, tags),
    [serviceProviders, limitBy]
  );

  const [selectedSP, setSelectedSP] = React.useState(null);

  const formatValue = (spId) => {
    const sP = serviceProviders?.find((sp) => sp.id === spId);
    if (!sP) return null;
    return { label: spFullNameSelector(sP), value: sP.id, serviceProvider: sP };
  };

  React.useEffect(() => {
    if (serviceProviderId) {
      setSelectedSP(formatValue(serviceProviderId));
    } else {
      setSelectedSP(null);
    }
  }, [serviceProviderId]);

  if (!serviceProviders) return null;

  return (
    <>
      <Autocomplete
        data-testmation="serviceProviderSelector"
        id="combo-box-demo"
        options={optionsForSelectMemoized}
        value={selectedSP}
        disableClearable={!isClearable}
        disabled={disabled}
        onChange={(_, value) => {
          onChange(value?.value || null, value?.serviceProvider || undefined);
        }}
        filterOptions={(options, params) => {
          const filtered = filter(options, params);
          if (withSpAssign) {
            filtered.push({
              label: "Add service provider with commission",
              serviceProvider: {
                firstName: "",
                lastName: "",
                title: ""
              }
            });
          }
          return filtered;
        }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            error={!disableDefaultError && !selectedSP}
            variant="outlined"
            fullWidth
            label={hideLabel ? "" : label || t("serviceProvider")}
            placeholder={placeholder || t("serviceProvider")}
            slotProps={{
              inputLabel: { shrink: true }
            }}
          />
        )}
        renderOption={(props, option) => {
          const speciality = option.serviceProvider?.displaySpeciality;
          return option.serviceProvider.id ? (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={option.value}>
              {spFullNameSelector(option.serviceProvider)}
              <Box component="span" fontSize="small" className={classes.textLight}>
                {speciality ? `, ${speciality}` : ""}
              </Box>
            </li>
          ) : (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={option.value}>
              <Typography fontWeight={600} color="primary" fontSize={12}>
                {option.label}
              </Typography>
            </li>
          );
        }}
      />
    </>
  );
};

export default connect(
  (state: any) => ({
    serviceProviders: serviceProvidersSortedSelector(state),
    resourceCentreId: state.userContext.resourceCentreId,
    userPermissionGroup: state.userContext.userCreds.userGroups[0],
    serviceTags: state.serviceTags.collection
  }),
  (dispatch) => ({
    loadServiceProviders: (id) =>
      dispatch(actions.getResourceCentreServiceProviders({ resourceCentreId: id }))
  })
)(ServiceProviderAutoSelect);
