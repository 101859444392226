import React from "react";
import { Box, Divider, Grid2 as Grid, Stack, Typography } from "@mui/material";
import { Control, Controller, FieldValues, FieldErrors, UseFormSetValue } from "react-hook-form";
import { CONFIRMATION, OUTCOME, PANELS, RESULT_OPTIONS } from "../../constants";
import FormTextField from "../../../../components/FormTextField/FormTextField";
import Diagnosis from "../../../Assessment/Diagnosis/Diagnosis";
import ReferrerCreateSelect from "../../../Referrers/ReferrerCreateSelect";
import { isISODateString } from "../../../../helpers/validators";
import ToggleInputDate from "../../../../components/ToggleADBS";
import { fields } from "../../../../models/Client";
import { Collapse } from "../AboveTwoMonths/CollapsableInputs";
import InputComponents from "../AboveTwoMonths/InputComponents";
import { PanelsType } from "./ImnciBelowTwoMonthsForm";

interface Props {
  control: Control<FieldValues>;
  errors: FieldErrors;
  setValue: UseFormSetValue<FieldValues>;
  icd: { icdCode: string; diagnosis: string } | null;
  showBreastFeedingProblem: boolean;
  showPbsi: boolean;
  showDiarrhoea: boolean;
}

const CollapsableInputs = ({
  control,
  errors,
  setValue,
  icd,
  showBreastFeedingProblem,
  showPbsi,
  showDiarrhoea
}: Props): JSX.Element => {
  const [expanded, setExpanded] = React.useState(PANELS.VITALS_AND_REFERRAL);

  const handleExpandChange = (panel: PanelsType) => {
    setExpanded(panel === expanded ? PANELS.NONE : panel);
  };

  return (
    <div>
      <Collapse
        expanded={expanded === PANELS.VITALS_AND_REFERRAL}
        onChange={() => handleExpandChange(PANELS.VITALS_AND_REFERRAL)}
        title="Vital And Referral"
        contents={
          <Grid container spacing={2} pl={2}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormTextField
                fullWidth
                control={control}
                label="Weight (Kg)"
                placeholder="Provide weight"
                dataTestmation="weight"
                errors={errors}
                name="weight"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormTextField
                fullWidth
                control={control}
                label="Temperature (°C)"
                placeholder="Provide temperature"
                dataTestmation="temperature"
                errors={errors}
                name="temperature"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 8 }}>
              <InputComponents
                control={control}
                name="referredBy"
                label="Referred By"
                type="radio"
                radioOptions={[
                  { label: "FCHV", value: "FCHV" },
                  { label: "PHC/ORC", value: "PHC/ORC" },
                  { label: "HF", value: "HF" }
                ]}
              />
            </Grid>
          </Grid>
        }
      />
      <Collapse
        expanded={expanded === PANELS.PATIENT_SIGN_AND_SYMPTOMS}
        onChange={() => handleExpandChange(PANELS.PATIENT_SIGN_AND_SYMPTOMS)}
        title="Patient Signs And Symptoms"
        contents={
          <Stack ml={2} gap={1}>
            {/* pbsi */}
            <div>
              <InputComponents
                control={control}
                name="pbsi"
                label="PSBI/LBI/NBI/Pneumonia"
                type="radio"
                radioOptions={[
                  {
                    value: CONFIRMATION.YES,
                    label: "Yes"
                  },
                  {
                    value: CONFIRMATION.NO,
                    label: "No"
                  }
                ]}
              />
              {showPbsi && (
                <Grid
                  container
                  spacing={0}
                  sx={{
                    pl: 2,
                    pt: 2,
                    borderLeft: "1px solid #ddd"
                  }}
                >
                  <Grid size={12}>
                    <FormTextField
                      fullWidth
                      control={control}
                      label="Respiratory rate (RR):"
                      dataTestmation="respiratoryRate"
                      errors={errors}
                      name="respiratoryRate"
                    />
                  </Grid>
                  {[
                    {
                      name: "convulsion",
                      label: "Convulsion"
                    },
                    {
                      name: "severeChestIndrawing",
                      label: "Severe Chest Indrawing"
                    },
                    {
                      name: "nasalFlaring",
                      label: "Nasal Flaring"
                    },
                    {
                      name: "grunting",
                      label: "Grunting"
                    },
                    {
                      name: "unableToFeed",
                      label: "Unable To Feed"
                    },
                    {
                      name: "buldgingFontanelle",
                      label: "Buldging Fontanelle"
                    },
                    {
                      name: "umbilicusInfectionToSkin",
                      label: "Umbilicus Infection To Skin"
                    },
                    {
                      name: "umbilicusRedOrWithPus",
                      label: "Umbilicus Red Or With Pus"
                    }
                  ].map((item) => (
                    <Grid size={{ xs: 12, sm: 6 }} key={item.name}>
                      <InputComponents
                        type="checkbox"
                        name={item.name}
                        control={control}
                        label={item.label}
                      />
                    </Grid>
                  ))}
                  {[
                    {
                      name: "tempAbove37",
                      label: "Temp > 37.5 C"
                    },
                    {
                      name: "tempBelow37",
                      label: "Temp < 37.5 C"
                    },
                    {
                      name: "skinPustulesAbove10",
                      label: "Skin pustules: Severe or > 10"
                    },
                    {
                      name: "skinPustulesBelow10",
                      label: "Skin pustules: < 10"
                    },
                    {
                      name: "pbsiLethargicUnconscious",
                      label: "Lethargic/Unconscious"
                    },
                    {
                      name: "lessThanNormalMovement",
                      label: "Less than normal movement"
                    },
                    {
                      name: "jaundiceUpToHandsFeet",
                      label: "Jaundice upto hands feet"
                    },
                    {
                      name: "jaundice",
                      label: "Jaundice"
                    },
                    {
                      name: "pusFromEye",
                      label: "Pus from eye"
                    }
                  ].map((item) => (
                    <Grid size={{ xs: 12, sm: 6 }} key={item.name}>
                      <InputComponents
                        type="checkbox"
                        name={item.name}
                        control={control}
                        label={item.label}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </div>

            {/* diarrhoea */}
            <div>
              <InputComponents
                control={control}
                name="diarrhoea"
                label="Diarrhoea"
                type="radio"
                radioOptions={[
                  {
                    value: CONFIRMATION.YES,
                    label: "Yes"
                  },
                  {
                    value: CONFIRMATION.NO,
                    label: "No"
                  }
                ]}
              />
              {showDiarrhoea && (
                <Grid
                  container
                  spacing={0}
                  sx={{
                    pl: 2,
                    borderLeft: "1px solid #ddd"
                  }}
                >
                  <Grid size={{ xs: 12, sm: 6 }} alignItems="center">
                    <FormTextField
                      style={{ marginTop: "1rem" }}
                      control={control}
                      label="Days"
                      dataTestmation="diarrhoeaDaysInput"
                      errors={errors}
                      name="diarrhoeaDays"
                    />
                    {[
                      {
                        name: "blood",
                        label: "Blood"
                      },
                      {
                        name: "diarrhoeaLethargicUnconscious",
                        label: "Lethergic / Unconcious"
                      },
                      {
                        name: "restlessIrritable",
                        label: "Restless Irritable"
                      },
                      {
                        name: "sunkenEyes",
                        label: "Sunken Eyes"
                      },
                      {
                        name: "skinPinchVerySlowly",
                        label: "Skin Pinch Very Slowly"
                      },
                      {
                        name: "skinPinchSlowly",
                        label: "Skin Pinch Slowly"
                      }
                    ].map((item) => (
                      <Stack key={item.name}>
                        <InputComponents
                          type="checkbox"
                          name={item.name}
                          control={control}
                          label={item.label}
                        />
                      </Stack>
                    ))}
                  </Grid>
                </Grid>
              )}
            </div>
            {/* breastFeedingProblem */}
            <div>
              <InputComponents
                control={control}
                name="breastFeedingProblem"
                label="Breast Feeding Problem"
                type="radio"
                radioOptions={[
                  {
                    value: CONFIRMATION.YES,
                    label: "Yes"
                  },
                  {
                    value: CONFIRMATION.NO,
                    label: "No"
                  }
                ]}
              />
              {showBreastFeedingProblem && (
                <Grid
                  container
                  spacing={0}
                  sx={{
                    pl: 2,
                    borderLeft: "1px solid #ddd"
                  }}
                >
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <FormTextField
                      style={{ marginTop: "1rem" }}
                      control={control}
                      label="How many times in 24 hours"
                      dataTestmation="breastFeedTimesIn24Hours"
                      errors={errors}
                      name="breastFeedTimesIn24Hours"
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <FormTextField
                      style={{ marginTop: "1rem" }}
                      control={control}
                      label="How often? Times: (Other foods/drinks)"
                      dataTestmation="otherFoodDrinksFeedTimes"
                      errors={errors}
                      name="otherFoodDrinksFeedTimes"
                    />
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }} alignItems="center">
                    {[
                      {
                        name: "breastfed",
                        label: "Breast fed"
                      },
                      {
                        name: "difficultFeeding",
                        label: "Difficult Feeding"
                      },
                      {
                        name: "receiveOtherFoodDrinks",
                        label: "Receive Other Food Drinks"
                      },
                      {
                        name: "feedByBottle",
                        label: "Feed By Bottle"
                      },
                      {
                        name: "feedBySpoon",
                        label: "Feed By Spoon"
                      }
                    ].map((item) => (
                      <Stack key={item.name}>
                        <InputComponents
                          type="checkbox"
                          name={item.name}
                          control={control}
                          label={item.label}
                        />
                      </Stack>
                    ))}
                  </Grid>
                  <Grid size={{ xs: 12, sm: 6 }}>
                    <>
                      {[
                        {
                          name: "veryLowWeight",
                          label: "Very Low Weight"
                        },
                        {
                          name: "lowWeight",
                          label: "low Weight"
                        },
                        {
                          name: "normalWeight",
                          label: "Normal Weight"
                        },
                        {
                          name: "oralUlcerThrush",
                          label: "Oral Ulcer Thrush"
                        }
                      ].map((item) => (
                        <Stack key={item.name}>
                          <InputComponents
                            type="checkbox"
                            name={item.name}
                            control={control}
                            label={item.label}
                          />
                        </Stack>
                      ))}
                    </>
                  </Grid>
                </Grid>
              )}
            </div>
            {/* assess breast feeding */}
            <div>
              <Typography fontWeight={500} gutterBottom fontSize="1rem">
                Assess breast feeding
              </Typography>
              <Grid
                container
                spacing={0}
                sx={{
                  pl: 2,
                  borderLeft: "1px solid #ddd"
                }}
              >
                {[
                  {
                    name: "includesAll4PointsOfAttachment",
                    label: "Includes All 4 Points Of Attachment"
                  },
                  {
                    name: "includesAll4PointsOfPosition",
                    label: "Includes All 4 Points Of Position"
                  },
                  {
                    name: "sucklingEffectively",
                    label: "Suckling Effectively"
                  }
                ].map((item) => (
                  <Grid size={{ xs: 12, sm: 6 }} key={item.name}>
                    <InputComponents
                      type="checkbox"
                      name={item.name}
                      control={control}
                      label={item.label}
                    />
                  </Grid>
                ))}
              </Grid>
            </div>
          </Stack>
        }
      />
      <Collapse
        expanded={expanded === PANELS.CLASSIFICATION_AND_CODE}
        onChange={() => handleExpandChange(PANELS.CLASSIFICATION_AND_CODE)}
        title="Classification And Code"
        contents={
          <Grid container spacing={2} pl={2}>
            <Grid size={12}>
              <FormTextField
                control={control}
                label="Major Classification"
                placeholder="Provide major classification"
                dataTestmation="majorClassification"
                errors={errors}
                name="majorClassification"
                multiline
                fullWidth
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormTextField
                fullWidth
                control={control}
                label="Number of classification"
                placeholder="Provide number classification"
                dataTestmation="majorClassification"
                errors={errors}
                name="numberOfClassification"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }} mt={-1}>
              <Diagnosis
                hidetitle
                label="ICD Code"
                diagnosis={icd}
                onChange={(v) => {
                  setValue("icd", v);
                }}
              />
            </Grid>
          </Grid>
        }
      />
      <Collapse
        expanded={expanded === PANELS.TREATMENT_AND_COUNSELLING}
        onChange={() => handleExpandChange(PANELS.TREATMENT_AND_COUNSELLING)}
        title="Treatment And Counselling"
        contents={
          <Grid container spacing={2} pl={2}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <InputComponents
                control={control}
                name="ampicillin"
                label="Ampicillin"
                type="radio"
              />
              <InputComponents
                control={control}
                name="amoxycillin"
                label="Amoxycillin"
                type="radio"
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormTextField
                fullWidth
                control={control}
                label="Other Medicines"
                placeholder="Provide other medicines"
                dataTestmation="medicinesInput"
                errors={errors}
                name="otherMedicines"
                multiline
              />
            </Grid>
            {/* gentamycin dose options */}
            <Grid size={12}>
              <Typography fontWeight={600} fontSize="1rem" gutterBottom>
                IF Gentamycin
              </Typography>
            </Grid>
            <Box pl={2}>
              <Grid
                container
                spacing={0}
                sx={{
                  pl: 2,
                  borderLeft: "1px solid #ddd"
                }}
              >
                {[
                  {
                    label: "1",
                    name: "gentamycinDoseOne"
                  },
                  {
                    label: "2",
                    name: "gentamycinDoseTwo"
                  },
                  {
                    label: "3",
                    name: "gentamycinDoseThree"
                  },
                  {
                    label: "4",
                    name: "gentamycinDoseFour"
                  },
                  {
                    label: "5",
                    name: "gentamycinDoseFice"
                  },
                  {
                    label: "6",
                    name: "gentamycinDoseSix"
                  },
                  {
                    label: "7",
                    name: "gentamycinDoseSeven"
                  }
                ].map((item) => (
                  <Grid size={{ xs: 6, sm: 3 }} key={item.name}>
                    <InputComponents
                      control={control}
                      name={item.name}
                      label={item.label}
                      type="checkbox"
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>

            {/* counselling to mother */}
            <Grid size={12}>
              <Typography fontWeight={600} fontSize="1rem" gutterBottom>
                Counselling to mother
              </Typography>
            </Grid>
            <Box pl={2}>
              <Grid
                container
                spacing={0}
                sx={{
                  pl: 2,
                  borderLeft: "1px solid #ddd"
                }}
              >
                {[
                  {
                    label: "Regular Followup",
                    name: "regularFollowUp"
                  },
                  {
                    label: "Intermediate Visit",
                    name: "intermediateVisit"
                  },
                  {
                    label: "keep Warm",
                    name: "keepWarm"
                  },
                  {
                    label: "Breast Feeding",
                    name: "breastFeeding"
                  }
                ].map((item) => (
                  <Grid size={{ xs: 12, sm: 6 }} key={item.name}>
                    <InputComponents
                      control={control}
                      name={item.name}
                      label={item.label}
                      type="checkbox"
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
            {/* treatment outcome */}
            <Box pl={2}>
              <InputComponents
                control={control}
                name="treatmentOutcome"
                label="Treatment outcomet"
                type="radio"
                radioOptions={[
                  {
                    label: "Improved",
                    value: OUTCOME.IMPROVED
                  },
                  {
                    label: "Referred",
                    value: OUTCOME.REFERRED
                  },
                  {
                    label: "LAMA/Absconded",
                    value: OUTCOME.LAMA_ABSCONDED
                  },
                  {
                    label: "Death",
                    value: OUTCOME.DEATH
                  }
                ]}
              />
            </Box>
          </Grid>
        }
      />
      <Collapse
        expanded={expanded === PANELS.REFERRED_TO_AND_FOLLOW_UP}
        onChange={() => handleExpandChange(PANELS.REFERRED_TO_AND_FOLLOW_UP)}
        title="Referred To And Follow Up"
        contents={
          <Grid container spacing={2} pl={2}>
            <Grid size={12}>
              <Controller
                name="referredToId"
                control={control}
                render={({ field: { onChange, value }, fieldState }) => (
                  <ReferrerCreateSelect
                    onReferrerChange={(v) => onChange(v?.id || null)}
                    label="Referred To"
                    error={{
                      value: Boolean(fieldState.error),
                      message: fieldState.error?.message || ""
                    }}
                    referrerId={value}
                    textFieldWidth="100%"
                    width="100%"
                    textFieldProps={{
                      InputLabelProps: { shrink: true }
                    }}
                  />
                )}
              />
            </Grid>
            <Divider />
            <Grid size={{ xs: 12, sm: 5 }}>
              <Controller
                name="followUpDate"
                control={control}
                rules={{
                  validate: (value) =>
                    (value ? !!isISODateString(value) : true) || "Please enter valid date"
                }}
                render={({ field }) => (
                  <ToggleInputDate
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    key="followUpDate"
                    showAgeField={false}
                    field={fields[4]}
                    data={{
                      followUpDate: ""
                    }}
                    changeDate={(value) => {
                      field.onChange(value);
                    }}
                    label="Followup"
                    error={!!errors?.dob?.message}
                    helperText={errors?.dob?.message || ""}
                    isFocused={() => ({})}
                    isBlurred={() => ({})}
                  />
                )}
              />
            </Grid>
            <Grid size={7} alignSelf="center">
              <InputComponents
                control={control}
                name="result"
                label="Results"
                type="radio"
                radioOptions={[
                  {
                    label: "Improved",
                    value: RESULT_OPTIONS.IMPROVED
                  },
                  {
                    label: "Same",
                    value: RESULT_OPTIONS.SAME
                  },
                  {
                    label: "Worse",
                    value: RESULT_OPTIONS.WORSE
                  }
                ]}
              />
            </Grid>
          </Grid>
        }
      />
      <Collapse
        expanded={expanded === PANELS.REMARKS}
        onChange={() => handleExpandChange(PANELS.REMARKS)}
        title="Remarks"
        contents={
          <Grid container spacing={2} pl={2}>
            <Grid size={12}>
              <FormTextField
                fullWidth
                control={control}
                label="Remarks"
                placeholder="Provide remarks"
                dataTestmation="remarks"
                errors={errors}
                name="remarks"
                multiline
              />
            </Grid>
          </Grid>
        }
      />
    </div>
  );
};

export default CollapsableInputs;
