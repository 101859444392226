import React from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { Control, Controller, FieldValues } from "react-hook-form";
import { RootState } from "app/store";
import { IServiceTag } from "../../../interfaces/ServiceTagsInterface";

interface Props {
  control: Control<FieldValues>;
  name: string;
}

interface OptionHash {
  [P: string]: {
    name: string;
    mappedName: string;
    tag: string;
    adjective: string;
  };
}

function ServiceTagsAutocomplete({ control, name }: Props): JSX.Element {
  const options: IServiceTag[] =
    useSelector((state: RootState) => state.serviceTags.collection) || [];

  const optionsHash: OptionHash = options.reduce(
    (accumulator, current) => ({
      ...accumulator,
      ...{
        [current.id]: {
          name: current?.tag || "",
          mappedName: current?.mappedName || "",
          tag: current?.tag || "",
          adjective: current?.adjective || ""
        }
      }
    }),
    {}
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
        <Autocomplete
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...rest}
          options={options.map((o) => o.id)}
          multiple
          getOptionLabel={(option) => `${optionsHash[option].tag} ${optionsHash[option].adjective}`}
          renderOption={(props, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={option}>
              {`${optionsHash[option].tag} ${optionsHash[option].adjective}`}
            </li>
          )}
          onChange={(_, value) => onChange(value)}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              variant="outlined"
              slotProps={{
                inputLabel: { shrink: true }
              }}
              placeholder="Select services for the provider"
              label="Select Service"
              fullWidth
              margin="dense"
              error={!!error}
              helperText={error?.message || " "}
              sx={{ my: 0 }}
            />
          )}
        />
      )}
    />
  );
}

export default ServiceTagsAutocomplete;
