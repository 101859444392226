import * as React from "react";
import { Box, TextField, CircularProgress, Autocomplete } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import startCase from "lodash/startCase";
import PeriodPicker from "../../components/PeriodPicker";

import { t } from "../../components/translate";
import * as bookingApi from "../../api/bookings";
import * as supportAdminApi from "../../api/supportAdmin";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";

const Search = ({
  filters,
  onSetFilters
}: {
  filters: {
    client?: string;
    end?: Date;
    resourceCentre?: ResourceCentre;
    serviceProvider?: string;
    start?: number;
  };
  onSetFilters: (filter) => void;
}): JSX.Element => {
  const [clientSearch, setClientSearch] = React.useState("");
  const [clientOptions, setClientOptions] = React.useState([]);

  const [clinicSearch, setClinicSearch] = React.useState("");
  const [clinicOptions, setClinicOptions] = React.useState([]);

  const [loading, setLoading] = React.useState({
    client: false,
    serviceProvider: false,
    clinic: false
  });

  const getRCs = async () => {
    const response = await supportAdminApi.getResourceCentres();
    setClinicOptions(response);
  };

  React.useEffect(() => {
    getRCs();
  }, []);

  const findAndSetResource = async (key, value?: string) => {
    if (key === "client") {
      setLoading({ ...loading, client: true });
      const response = await bookingApi.clientSearch(value);
      setLoading({ ...loading, client: false });
      setClientOptions(response);
    } else if (key === "clinic") {
      setLoading({ ...loading, clinic: true });
      setLoading({ ...loading, clinic: false });
    }
  };

  return (
    <Box display="flex" gap={2} flexDirection="row" alignItems="center" ml={2} mr={2}>
      <Autocomplete
        style={{ flexGrow: 0.2, flexBasis: 0.2 }}
        data-testmation="clientSelect"
        freeSolo
        disableClearable={loading.client}
        forcePopupIcon={false}
        value={filters.client || null}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) =>
          option.firstName ? `${option.firstName} ${option.lastName}` : ""
        }
        renderOption={(props, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={option.id}>
            <Box mb="2px">
              <Box>{startCase(`${option.firstName} ${option.lastName}`)}</Box>
              <Box fontSize="small">{option.phone}</Box>
            </Box>
          </li>
        )}
        onChange={(e, v) => {
          onSetFilters({ ...filters, client: v });
          setClientSearch(v ? `${v.firstName} ${v.lastName}` : "");
        }}
        options={clientOptions}
        filterOptions={(options) => options}
        loading={loading.client}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            fullWidth
            label={t("booking.client")}
            margin="dense"
            size="small"
            value={clientSearch}
            slotProps={{
              input: {
                ...params.InputProps,
                inputProps: {
                  ...params.inputProps,
                  maxLength: 30
                },
                endAdornment: (
                  <>
                    {loading.client ? (
                      <CircularProgress color="inherit" size="8px" />
                    ) : (
                      !clientSearch.length && <SearchIcon style={{ color: "#292929" }} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
                style: {
                  padding: 0,
                  fontSize: "14px"
                }
              }
            }}
            onChange={(e) => {
              setClientSearch(e.target.value || "");
              if (e.target.value.length > 2) findAndSetResource("client", e.target.value || "");
            }}
          />
        )}
      />

      <Autocomplete
        style={{ flexGrow: 0.2, flexBasis: 0.2 }}
        data-testmation="resourceCentreSelect"
        freeSolo
        disableClearable={loading.clinic}
        forcePopupIcon={false}
        value={filters.resourceCentre}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => (option.name ? option.name : "")}
        renderOption={(props, option) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <li {...props} key={option.id}>
            <Box mb="2px">
              <Box>{startCase(option.name)}</Box>
              <Box fontSize="small">{startCase(option.address)}</Box>
            </Box>
          </li>
        )}
        onChange={(e, v) => {
          onSetFilters({ ...filters, resourceCentre: v });
          setClinicSearch(v?.name || "");
        }}
        options={clinicOptions}
        loading={loading.clinic}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            fullWidth
            label={t("booking.clinic")}
            value={clinicSearch}
            margin="dense"
            size="small"
            slotProps={{
              input: {
                ...params.InputProps,
                endAdornment: (
                  <>
                    {loading.clinic ? (
                      <CircularProgress color="inherit" size="8px" />
                    ) : (
                      !clinicSearch.length && <SearchIcon style={{ color: "#292929" }} />
                    )}
                    {params.InputProps.endAdornment}
                  </>
                ),
                style: {
                  padding: 0,
                  fontSize: "14px"
                }
              }
            }}
            onChange={(e) => {
              setClinicSearch(e.target.value || "");
              findAndSetResource("clinic", e.target.value || "");
            }}
          />
        )}
      />
      <Box style={{ flexGrow: 0.2, flexBasis: 0.2 }}>
        <PeriodPicker
          start={filters.start}
          end={filters.end}
          onChange={({ start, end }) => {
            onSetFilters({
              ...filters,
              start: start.toDate(),
              end: end.toDate()
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default Search;
