import React, { JSX, useRef } from "react";
import ReactToPrint from "react-to-print";
import { Box, Typography } from "@mui/material";
import { LabRecord } from "../../../../interfaces/Lab";
import usePrintStyles from "../../../../hooks/usePrintStyles";
import CustomHeader from "../../../Lab/LabPrint/CustomHeader";
import Logo from "../../../../../assets/images/poweredByOkhati.png";
import { ResourceCentre } from "../../../../interfaces/ResourceCentreInterface";
import {
  ClientProfileImage,
  PresentHealthStatus
} from "../MedicalPrintComponents/DefaultPrintComponents";
import { CenteredLetterHead, DefaultLetterHead } from "../../../../components/Print/Print";
import {
  BorderedClientIllnessesDiv,
  BorderedClientInfoDiv,
  BorderedClientVitalsDiv,
  BorderedMainLabData
} from "../MedicalPrintComponents/BorderedPrintComponents";
import useCurrentResourceCentre from "../../../../hooks/useCurrentResourceCentre";

interface BorderedMedicalPrintViewPropTypes {
  resourceCentre?: ResourceCentre;
  labRecord: LabRecord;
  assessment: any;
  forwardRef: React.RefObject<HTMLDivElement | null>;
  centralizedHeader?: boolean;
  showQrCode?: boolean;
  showMedicalDisclaimerOnFooter?: boolean;
}

export const BorderedMedicalPrintView = ({
  resourceCentre,
  labRecord,
  showQrCode,
  centralizedHeader,
  assessment,
  forwardRef,
  showMedicalDisclaimerOnFooter
}: BorderedMedicalPrintViewPropTypes): JSX.Element | null => {
  const stampImage = resourceCentre?.settings?.medicalSettings?.stampImage;
  const profileImage = labRecord.customer.customerDetails?.profileImageS3Url || null;
  const includeLetterhead = resourceCentre?.settings?.medicalSettings?.includeLetterhead;
  const customHeaderImage = resourceCentre?.settings?.medicalSettings?.customHeaderImage;
  const recentAssessment = assessment?.sort(
    (a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
  )?.[0];

  if (!recentAssessment || !labRecord) return null;

  return (
    <div ref={forwardRef}>
      <Box component="table" sx={{ width: "100%", position: "relative" }}>
        {!includeLetterhead && <Box sx={{ height: "2.5cm" }} />}
        <tbody>
          <tr>
            <td>
              <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                {/* letter head */}
                {includeLetterhead && (
                  <div>
                    {customHeaderImage ? (
                      <CustomHeader imageUrl={customHeaderImage} />
                    ) : (
                      <>
                        {centralizedHeader ? (
                          <CenteredLetterHead resourceCentre={resourceCentre} />
                        ) : (
                          <DefaultLetterHead resourceCentre={resourceCentre} />
                        )}
                      </>
                    )}
                  </div>
                )}

                {/* title */}
                <Typography
                  sx={{
                    alignSelf: "center",
                    padding: "2px 8px",
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    fontSize: "0.35cm",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    whiteSpace: "nowrap",
                    background: "black",
                    color: "white"
                  }}
                >
                  {resourceCentre?.settings?.medicalSettings?.customPrintTitle ||
                    "MEDICAL EXAMINATION REPORT"}
                </Typography>

                {/* health status */}
                <Box sx={{ alignSelf: "center" }}>
                  <PresentHealthStatus recentAssessment={recentAssessment} />
                </Box>

                {/* client info div */}
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    gap: "10px",
                    position: "relative"
                  }}
                >
                  <Box sx={{ width: "calc(100% - 2.5cm - 10px)" }}>
                    <BorderedClientInfoDiv labRecord={labRecord} />
                  </Box>

                  <Box sx={{ position: "absolute", right: 0, bottom: 0 }}>
                    <ClientProfileImage profileImage={profileImage} />
                  </Box>
                </Box>

                {/* client illness div */}
                <BorderedClientIllnessesDiv recentAssessment={recentAssessment} />

                <Box sx={{ marginTop: "-4px" }}>
                  <BorderedClientVitalsDiv
                    labRecord={labRecord}
                    recentAssessment={recentAssessment}
                  />
                </Box>

                {/* main lab data */}
                <BorderedMainLabData
                  labRecord={labRecord}
                  recentAssessment={recentAssessment}
                  showQrCode={Boolean(showQrCode)}
                  showMedicalDisclaimerOnFooter={showMedicalDisclaimerOnFooter}
                />

                {/* stamp image */}
                {stampImage && (
                  <Box position="absolute" left={0} bottom={22}>
                    <img
                      src={stampImage}
                      alt="stamp"
                      style={{
                        display: "block",
                        width: 100,
                        height: 100
                      }}
                    />
                  </Box>
                )}
              </Box>
            </td>
          </tr>
        </tbody>
      </Box>

      {/* footer */}
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "space-between",
          marginTop: "8px"
        }}
      >
        {showMedicalDisclaimerOnFooter && (
          <Typography
            sx={{
              fontSize: "0.25cm"
            }}
          >
            Recommended by Ministry of Health and Population, Government of Nepal. This report is
            valid for two months from the date of Medical Examination.
          </Typography>
        )}
        <Box component="span" marginLeft={2} flexGrow={1} />
        <Box className="FooterPrintOkhatiLogo">
          <img style={{ display: "block" }} src={Logo} alt="logo" height="100%" width="100%" />
        </Box>
      </Box>
    </div>
  );
};

const ForeignEmploymentBorderedPrint = ({
  labRecord,
  buttonText,
  assessment = null,
  showMedicalDisclaimerOnFooter
}: {
  labRecord: LabRecord;
  buttonText?: string | React.ReactNode;
  assessment?: any[] | null;
  showMedicalDisclaimerOnFooter?: boolean;
}): JSX.Element => {
  usePrintStyles({ pageStyle: "size: A4; margin: 1mm 10mm" }).runPrintStyles();
  const printRef = useRef(null);

  const resourceCentre = useCurrentResourceCentre();
  const centralizedHeader = resourceCentre?.settings?.printSettings.centralizedHeader;
  const showQrCode = resourceCentre?.settings?.medicalSettings.showQrCode;

  return (
    <div style={{ width: "100%" }}>
      <ReactToPrint
        trigger={() => (
          <Typography
            component="span"
            sx={{
              width: "100%",
              height: "100%",
              padding: "5px 15px",
              fontSize: "0.95em",
              display: "flex",
              alignItems: "center",
              textTransform: "none"
            }}
          >
            {buttonText || "Print"}
          </Typography>
        )}
        content={() => printRef.current}
      />
      <iframe className="displayContents" title="printContainer">
        <BorderedMedicalPrintView
          forwardRef={printRef}
          resourceCentre={resourceCentre}
          labRecord={labRecord}
          showQrCode={showQrCode}
          centralizedHeader={centralizedHeader}
          assessment={assessment}
          showMedicalDisclaimerOnFooter={showMedicalDisclaimerOnFooter}
        />
      </iframe>
    </div>
  );
};

export default ForeignEmploymentBorderedPrint;
