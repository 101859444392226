import { Autocomplete, Box, MenuItem, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { pick, omit, pickBy, startCase } from "lodash";
import moment from "moment-timezone";
import Panel from "../../../components/Panel";
import "../styles.scss";
import {
  LedgerType,
  ChildGeneralLedger,
  TransactionType,
  LedgerNature,
  SubLedgerMapping,
  PrimaryLedgerNames,
  SubLedgerMappingOptions,
  LedgerTypeOptions,
  SecondaryLedger,
  AccountingGroup,
  NatureOfLedger,
  VendorType
} from "../../../interfaces/Accounts";
import { tl } from "../../../components/translate";
import ToggleInputDate from "../../../components/ToggleADBS";
import { bsDateField, DEBTORCREDITOR_FIELD } from "../../../models/Accounts";
import { useCoaAll, useGetAllChildLedgers, usePatchChildGl, usePostChildGl } from "../hooks";
import { ParentInterface } from "../AccountList";
import FormTextField from "../../../components/FormTextField/FormTextField";
import { isISODateString } from "../../../helpers/validators";
import Modal from "../../../components/Modal/Modal";
import { RootState } from "../../../store";
import { notificationAdd } from "../../../actions/notification";

interface CreateAccountGLProps {
  onClose: () => void;
  data?: ChildGeneralLedger;
  parentInfo?: ParentInterface;
  creatable?: boolean;
  ledgerName?: string;
}

export enum LedgerMappingLabel {
  CUSTOMER = "Customer",
  VENDOR = "Vendor"
}

export enum LedgerTypeForLabel {
  NORMAL_LEDGER = "Normal",
  BANK_LEDGER = "Bank",
  CASH_LEDGER = "Cash",
  DEBTOR_CREDITOR_LEDGER = "Debtor/Creditor"
}

interface LedgerCreationInfoProps {
  selectedSecondaryLedger: null | SecondaryLedger;
  coaSecondaryList: SecondaryLedger[];
  selectedAccountingGroup: null | AccountingGroup;
  coaAccountingGroupList: AccountingGroup[];
}

const isAssetsOrEquityParent = (parent: string): boolean =>
  parent === PrimaryLedgerNames.EQUITY_AND_LIABILITIES || parent === PrimaryLedgerNames.ASSETS;

const isIncomeOrAssetsParent = (parent: string): boolean =>
  parent === PrimaryLedgerNames.INCOME || parent === PrimaryLedgerNames.EXPENSES;

const isAssetsOrExpensesParent = (parent: string): boolean =>
  parent === PrimaryLedgerNames.ASSETS || parent === PrimaryLedgerNames.EXPENSES;

const findMappedLedger = (subLedger, subLedgerType, allChildLedgers): ChildGeneralLedger =>
  allChildLedgers.find(
    (ledger) => ledger.subLedgerMapping === subLedger && ledger.subLedgerType === subLedgerType
  );

export default function CreateAccountGL({
  parentInfo,
  onClose,
  data,
  creatable,
  ledgerName = ""
}: CreateAccountGLProps): JSX.Element {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { accountOpeningDate } = useSelector((state: RootState) => ({
    accountOpeningDate: state.userContext.resourceCentre?.accountsOpeningDate
  }));
  const dispatch = useDispatch();
  const defaultLedgerNature = isAssetsOrEquityParent(parentInfo?.header)
    ? LedgerNature[0].value
    : LedgerNature[1].value;

  const { data: coaAll } = useCoaAll();

  const {
    handleSubmit,
    control,
    setValue,
    watch,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<ChildGeneralLedger>({
    defaultValues: data
      ? { ...data, parentLedgerName: parentInfo?.parentLedgerName }
      : {
          ledgerName,
          parentId: null,
          ledgerType: null,
          subLedgerMapping: "",
          parentLedgerName: parentInfo?.parentLedgerName,
          ledgerNature: defaultLedgerNature,
          validFrom: moment("2000-01-01").toISOString(),
          validTo: moment("9000-12-31").toISOString(),
          openingBalance: 0,
          transactionType: isAssetsOrExpensesParent(parentInfo?.header)
            ? TransactionType.DEBIT
            : TransactionType.CREDIT,
          openingBalanceDate: moment(accountOpeningDate).toISOString() || "",
          bankRecoInfo: {
            accountNumber: "",
            bankAccountOpeningDate: "",
            bankName: "",
            bankBranch: ""
          }
        }
  });

  const formState = watch();
  const { mutate: postMutate, isLoading: postLoading, isSuccess: postSuccess } = usePostChildGl();
  const {
    mutate: patchMutate,
    isLoading: patchLoading,
    isSuccess: patchSuccess
  } = usePatchChildGl();

  const submitHandler = (submittedData) => {
    const filteredData = pickBy({
      ...submittedData,
      ...(!creatable && { parentId: parentInfo?.parentId })
    });
    if (data?.id) {
      patchMutate({
        ...filteredData,
        id: data.id
      } as ChildGeneralLedger);
    } else if (filteredData.ledgerType === LedgerType.DEBTOR_CREDITOR_LEDGER) {
      postMutate(pick(filteredData, DEBTORCREDITOR_FIELD) as ChildGeneralLedger);
    } else if (filteredData.ledgerType === LedgerType.BANK_LEDGER) {
      postMutate(omit(filteredData, "subLedgerMapping") as ChildGeneralLedger);
    } else {
      postMutate(omit(filteredData, ["subLedgerMapping", "bankRecoInfo"]) as ChildGeneralLedger);
    }
  };

  if (postSuccess || patchSuccess) {
    onClose();
  }

  const subLedgerMappingOptions =
    parentInfo?.header === PrimaryLedgerNames.ASSETS
      ? SubLedgerMappingOptions.filter((item) => item === SubLedgerMapping.CUSTOMER)
      : SubLedgerMappingOptions;

  const ledgerTypeOptions = isIncomeOrAssetsParent(parentInfo?.header)
    ? LedgerTypeOptions.filter((item) => item === LedgerType.NORMAL_LEDGER)
    : LedgerTypeOptions;

  const initialLedgerCreationInfo = {
    selectedSecondaryLedger: null,
    coaSecondaryList: [],
    selectedAccountingGroup: null,
    coaAccountingGroupList: []
  };
  const [ledgerCreationInfo, setLedgerCreationInfo] =
    React.useState<LedgerCreationInfoProps>(initialLedgerCreationInfo);
  const footer = (
    <Box sx={{ position: "absolute", bottom: "20px", right: "20px" }}>
      <LoadingButton
        data-testmation="footerSave"
        loading={postLoading || patchLoading}
        variant="contained"
        onClick={() => {
          if (data?.id) {
            handleSubmit(submitHandler)();
          } else {
            setOpenModal(true);
          }
        }}
      >
        {data ? tl("update") : tl("save")}
      </LoadingButton>
      <Modal
        saveAndClose
        open={openModal}
        title=""
        onSave={handleSubmit(submitHandler)}
        onClose={() => setOpenModal(false)}
      >
        <Typography>Once created you cannot delete the Child GL</Typography>
      </Modal>
    </Box>
  );

  const showForm = !(creatable && !ledgerCreationInfo.coaSecondaryList.length);
  const subLedgerTypes =
    formState.subLedgerMapping === "vendor" ? Object.values(VendorType) : ["client"];

  const allChildLedgers = useGetAllChildLedgers();

  const handleSubLedgerTypeSelect = (e) => {
    const subLedgerType = e.target.value;
    const subLedgerMappedLedger = findMappedLedger(
      formState.subLedgerMapping,
      subLedgerType,
      allChildLedgers
    );
    if (subLedgerMappedLedger) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: `Sub Ledger Type:${subLedgerType} is already mapped for ${subLedgerMappedLedger.ledgerName}`,
          autoTimeout: true,
          variant: "error"
        })
      );
    } else {
      setValue("subLedgerType", subLedgerType);
    }
  };

  return (
    <Panel footer={footer} title={tl("accounts.createAccountGL")} onClose={onClose}>
      <Box p={3}>
        {creatable && (
          <>
            <Box display="flex" m="20px 0" gap="10px">
              <Autocomplete
                id="primary-ledger"
                options={Object.values(PrimaryLedgerNames)}
                getOptionLabel={(option) => startCase(option)}
                sx={{ flexGrow: 1, width: "500px" }}
                onChange={(e, value) => {
                  if (value) {
                    setLedgerCreationInfo({
                      ...ledgerCreationInfo,
                      coaSecondaryList: coaAll.find((coa) => coa.primaryLedgerName === value)
                        ?.secondaryLedgers
                    });
                  } else {
                    setLedgerCreationInfo(initialLedgerCreationInfo);
                  }
                  if (
                    value === PrimaryLedgerNames.EQUITY_AND_LIABILITIES ||
                    value === PrimaryLedgerNames.ASSETS
                  ) {
                    setValue("ledgerNature", NatureOfLedger.BALANCE_SHEET);
                  }
                  if (
                    value === PrimaryLedgerNames.EXPENSES ||
                    value === PrimaryLedgerNames.INCOME
                  ) {
                    setValue("ledgerNature", NatureOfLedger.PROFIT_LOSS);
                  }
                  if (
                    value === PrimaryLedgerNames.EQUITY_AND_LIABILITIES ||
                    value === PrimaryLedgerNames.INCOME
                  ) {
                    setValue("transactionType", TransactionType.CREDIT);
                  }
                  if (
                    value === PrimaryLedgerNames.ASSETS ||
                    value === PrimaryLedgerNames.EXPENSES
                  ) {
                    setValue("transactionType", TransactionType.DEBIT);
                  }
                }}
                renderInput={(params) => (
                  <TextField
                    variant="outlined"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Select Primary Ledgers*"
                  />
                )}
              />
              <Autocomplete
                id="primary-ledger"
                options={ledgerCreationInfo.coaSecondaryList}
                getOptionLabel={(option) => option.secondaryLedgerName}
                sx={{ flexGrow: 1, width: "500px" }}
                onChange={(e, value: SecondaryLedger) => {
                  if (value) {
                    setLedgerCreationInfo({
                      ...ledgerCreationInfo,
                      selectedSecondaryLedger: value,
                      coaAccountingGroupList: value?.accountingGroups
                    });
                  } else {
                    setLedgerCreationInfo({
                      ...ledgerCreationInfo,
                      coaAccountingGroupList: [],
                      selectedAccountingGroup: null
                    });
                  }
                }}
                value={ledgerCreationInfo.selectedSecondaryLedger}
                renderInput={(params) => (
                  <TextField
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                    variant="outlined"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    label="Select Secondary Ledgers*"
                  />
                )}
              />
            </Box>
            <Controller
              control={control}
              name="parentId"
              rules={{ required: "Select the Accounting Group" }}
              render={() => (
                <Autocomplete
                  value={ledgerCreationInfo.selectedAccountingGroup}
                  id="primary-ledger"
                  options={ledgerCreationInfo.coaAccountingGroupList}
                  getOptionLabel={(option) => option.accountingGroupName}
                  sx={{ flexGrow: 1 }}
                  onChange={(e, value: AccountingGroup) => {
                    if (value) {
                      setLedgerCreationInfo({
                        ...ledgerCreationInfo,
                        selectedAccountingGroup: value
                      });
                      setValue("parentId", value.id);
                    } else {
                      setLedgerCreationInfo({
                        ...ledgerCreationInfo,
                        selectedAccountingGroup: null
                      });
                      setValue("parentId", null);
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      slotProps={{
                        inputLabel: { shrink: true }
                      }}
                      error={!!errors.parentId}
                      helperText={errors.parentId && errors.parentId.message}
                      variant="outlined"
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...params}
                      label="Select Accounting Groups *"
                    />
                  )}
                />
              )}
            />
          </>
        )}
        {showForm && (
          <>
            <FormTextField
              dataTestmation="accountLedgerName"
              style={{ marginTop: "20px" }}
              fullWidth
              control={control}
              name="ledgerName"
              errors={errors}
              rules={{ required: "Account ledger can't be empty." }}
              label="Account Ledger Name"
            />
            <Box display="flex">
              <Controller
                name="ledgerType"
                control={control}
                rules={{ required: "Choose any one." }}
                render={({ field }) => (
                  <TextField
                    data-testmation="selectLedgerType"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    style={{ marginTop: "20px" }}
                    label="Select Ledger type *"
                    select
                    fullWidth
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                    variant="outlined"
                    error={!!errors.ledgerType}
                    helperText={errors.ledgerType?.message || ""}
                    onChange={({ target }) => {
                      field.onChange(target.value);
                      if (target.value === LedgerType.DEBTOR_CREDITOR_LEDGER) {
                        setValue("subLedgerMapping", "vendor");
                        return;
                      }
                      setValue("subLedgerMapping", null);
                      setValue("subLedgerType", null);
                    }}
                  >
                    {ledgerTypeOptions.map((ledger) => (
                      <MenuItem value={ledger} key={ledger}>
                        {startCase(ledger)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              {formState.ledgerType === LedgerType.DEBTOR_CREDITOR_LEDGER && (
                <Controller
                  name="subLedgerMapping"
                  control={control}
                  rules={{ required: "Choose any one." }}
                  render={({ field }) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      style={{ width: "250px", marginLeft: "10px", marginTop: "20px" }}
                      label="Sub Ledger Mapping *"
                      select
                      slotProps={{
                        inputLabel: { shrink: true }
                      }}
                      disabled={Boolean(data?.subLedgerMapping)}
                      variant="outlined"
                      error={!!errors?.subLedgerMapping}
                      helperText={errors?.subLedgerMapping?.message || ""}
                      onChange={({ target }) => field.onChange(target.value)}
                    >
                      {subLedgerMappingOptions.map((ledger) => (
                        <MenuItem value={ledger} key={ledger}>
                          {startCase(ledger)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              )}
            </Box>

            <Box display="flex" gap="10px" sx={{ marginTop: "20px" }}>
              {!creatable && (
                <FormTextField
                  name="parentLedgerName"
                  style={{ flexGrow: 1 }}
                  label="Under Parent 3"
                  control={control}
                  errors={errors}
                  rules={{ required: "Can't be empty." }}
                  disabled
                />
              )}
              {formState.ledgerType === LedgerType.DEBTOR_CREDITOR_LEDGER && (
                <Controller
                  name="subLedgerType"
                  control={control}
                  rules={{
                    required:
                      formState.ledgerType === LedgerType.DEBTOR_CREDITOR_LEDGER
                        ? "Can't be empty!"
                        : false
                  }}
                  render={({ field }) => (
                    <TextField
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      style={{ width: "200px" }}
                      slotProps={{
                        inputLabel: { shrink: true }
                      }}
                      label="Sub Ledger Type *"
                      select
                      fullWidth
                      onChange={handleSubLedgerTypeSelect}
                      variant="outlined"
                      error={!!errors.subLedgerType}
                      helperText={errors.subLedgerType?.message || ""}
                    >
                      {subLedgerTypes.map((type) => (
                        <MenuItem value={type} key={type}>
                          {startCase(type)}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
              )}
              <Controller
                name="ledgerNature"
                control={control}
                rules={{ required: "Choose any one." }}
                render={({ field }) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    style={{ width: "200px" }}
                    label="Nature Of Ledger *"
                    select
                    fullWidth
                    disabled={
                      Boolean(data?.ledgerNature) || isIncomeOrAssetsParent(parentInfo?.header)
                    }
                    variant="outlined"
                    error={!!errors.ledgerNature}
                    helperText={errors.ledgerNature?.message || ""}
                  >
                    {LedgerNature.map((nature) => (
                      <MenuItem value={nature.value} key={nature.value}>
                        {nature.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Box>
            <Box display="flex" m="20px 0 15px 0" justifyContent="space-between">
              <Box width={340}>
                <Controller
                  name="validFrom"
                  control={control}
                  rules={{
                    validate: (value) => (value ? moment(value).isValid() : true)
                  }}
                  render={({ field }) => (
                    <ToggleInputDate
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      key="validFrom"
                      field={{ ...bsDateField, key: "validFrom" }}
                      data={{
                        validFrom: (formState?.validFrom || data?.validFrom) as string
                      }}
                      changeDate={(value) => {
                        if (isISODateString(value) && formState?.validTo) {
                          if (moment(value).isBefore(formState?.validTo)) {
                            setValue("validFrom", value);
                            clearErrors("validFrom");
                          } else {
                            setError("validFrom", {
                              type: "valid",
                              message: "Should be Lesser then Valid To date."
                            });
                          }
                        } else if (isISODateString(value)) {
                          setValue("validFrom", value);
                          clearErrors("validFrom");
                        } else if (!isISODateString(value) && value) {
                          setError("validFrom", {
                            type: "valid",
                            message: "Please, enter valid date."
                          });
                        } else if (!value) {
                          setValue("validFrom", null);
                          clearErrors("validFrom");
                        }
                      }}
                      showAgeField={false}
                      label="validFrom"
                      error={!!errors.validFrom}
                      helperText={errors?.validFrom?.message || ""}
                    />
                  )}
                />
              </Box>
              <Box width={340}>
                <Controller
                  name="validTo"
                  control={control}
                  rules={{
                    validate: (value) => (value ? moment(value).isValid() : true)
                  }}
                  render={({ field }) => (
                    <ToggleInputDate
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      key="validTo"
                      field={{ ...bsDateField, key: "validTo" }}
                      data={{
                        validTo: (formState?.validTo || data?.validTo) as string
                      }}
                      changeDate={(value) => {
                        if (formState?.validFrom && isISODateString(value)) {
                          if (moment(value).isAfter(formState?.validFrom)) {
                            setValue("validTo", value);
                            clearErrors("validTo");
                          } else {
                            setError("validTo", {
                              type: "valid",
                              message: "Should be Greater then Valid from date."
                            });
                          }
                        } else if (isISODateString(value)) {
                          setValue("validTo", value);
                          clearErrors("validTo");
                        } else if (!isISODateString(value) && value) {
                          setError("validTo", {
                            type: "valid",
                            message: "Please, enter valid date."
                          });
                        } else if (!value) {
                          setValue("validTo", null);
                          clearErrors("validTo");
                        }
                      }}
                      showAgeField={false}
                      label="validUpto"
                      error={!!errors.validTo}
                      helperText={errors?.validTo?.message || ""}
                    />
                  )}
                />
              </Box>
            </Box>
            {!(
              formState.ledgerType === LedgerType.DEBTOR_CREDITOR_LEDGER ||
              data?.ledgerType === LedgerType.DEBTOR_CREDITOR_LEDGER
            ) && (
              <>
                <Box display="flex">
                  <FormTextField
                    dataTestmation="openingBalanceInput"
                    style={{ margin: "10px 10px 10px 0" }}
                    control={control}
                    name="openingBalance"
                    errors={errors}
                    label="Opening Balance"
                    fullWidth
                  />
                  <Controller
                    name="transactionType"
                    control={control}
                    rules={{
                      required:
                        formState.ledgerNature === LedgerNature[0].value ? "Can't be empty!" : false
                    }}
                    render={({ field }) => (
                      <TextField
                        data-testmation="typeRequire"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        style={{ marginTop: "10px" }}
                        label="Type*"
                        select
                        fullWidth
                        variant="outlined"
                        slotProps={{
                          inputLabel: { shrink: true }
                        }}
                        error={!!errors.transactionType}
                        helperText={errors.transactionType?.message || ""}
                      >
                        {Object.keys(TransactionType).map((type) => (
                          <MenuItem value={TransactionType[type]} key={type}>
                            {startCase(type)}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  />
                </Box>
                <Box width={340} mt="10px">
                  <Controller
                    name="openingBalanceDate"
                    control={control}
                    rules={{
                      validate: (value) => (value ? moment(value).isValid() : true)
                    }}
                    render={({ field }) => (
                      <ToggleInputDate
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        key="openingBalanceDate"
                        field={{ ...bsDateField, key: "openingBalanceDate" }}
                        data={{
                          openingBalanceDate: formState?.openingBalanceDate as string
                        }}
                        changeDate={(value) => {
                          setValue("openingBalanceDate", value);
                        }}
                        showAgeField={false}
                        label="openingBalanceDate"
                        error={!!errors.openingBalanceDate}
                        helperText={errors?.openingBalanceDate?.message || ""}
                      />
                    )}
                  />
                </Box>
              </>
            )}
            {(formState.ledgerType === LedgerType.BANK_LEDGER ||
              data?.ledgerType === LedgerType.BANK_LEDGER) && (
              <Box>
                <Box display="flex">
                  <Box sx={{ marginRight: "10px" }}>
                    <FormTextField
                      style={{ width: "250px", marginTop: "20px" }}
                      control={control}
                      name="bankRecoInfo.accountNumber"
                      errors={formState.ledgerType === LedgerType.BANK_LEDGER ? errors : {}}
                      rules={{ required: "Can't be empty!" }}
                      label="Bank A/C Number *"
                      disabled={Boolean(data?.bankRecoInfo?.accountNumber)}
                    />
                  </Box>
                  <Controller
                    name="bankRecoInfo.bankAccountOpeningDate"
                    control={control}
                    rules={{
                      required: "Must be in specified format.",
                      validate: (value) => (value ? moment(value).isValid() : true)
                    }}
                    render={({ field }) => (
                      <ToggleInputDate
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        key="bankAccountOpeningDate"
                        field={{ ...bsDateField, key: "bankAccountOpeningDate" }}
                        data={{
                          bankAccountOpeningDate: (formState?.bankRecoInfo
                            ?.bankAccountOpeningDate ||
                            data?.bankRecoInfo?.bankAccountOpeningDate) as string
                        }}
                        changeDate={(value) => {
                          setValue("bankRecoInfo.bankAccountOpeningDate", value);
                        }}
                        showAgeField={false}
                        label="bankReconcilationFrom"
                        error={!!errors?.bankRecoInfo?.bankAccountOpeningDate}
                        helperText={errors?.bankRecoInfo?.bankAccountOpeningDate?.message || ""}
                      />
                    )}
                  />
                </Box>
                <Box display="flex" sx={{ marginTop: "10px" }}>
                  <FormTextField
                    style={{ flexGrow: 1, marginRight: "10px" }}
                    control={control}
                    name="bankRecoInfo.bankName"
                    errors={formState.ledgerType === LedgerType.BANK_LEDGER ? errors : {}}
                    rules={{ required: "Can't be empty!" }}
                    label="Bank Name *"
                    disabled={Boolean(data?.bankRecoInfo?.bankName)}
                  />
                  <FormTextField
                    style={{ flexGrow: 1 }}
                    control={control}
                    name="bankRecoInfo.bankBranch"
                    errors={formState.ledgerType === LedgerType.BANK_LEDGER ? errors : {}}
                    rules={{ required: "Can't be empty!" }}
                    label="Bank Branch *"
                    disabled={Boolean(data?.bankRecoInfo?.bankBranch)}
                  />
                </Box>
              </Box>
            )}
          </>
        )}
      </Box>
    </Panel>
  );
}

CreateAccountGL.defaultProps = {
  data: null,
  parentInfo: null,
  creatable: false,
  ledgerName: ""
};
