import * as React from "react";
import { TextField, Popover, Button, Typography, List, Box, ListItemButton } from "@mui/material";
import Grid from "@mui/material/Grid2";
import * as moment from "moment-timezone";
import { useDispatch, useSelector } from "react-redux";
import styles from "./PeriodPicker.module.css";
import CalendarDropdown from "../CalendarDropdown/CalendarDropdown";
import {
  convertADtoBS,
  endOfDay,
  endOfMonth,
  getFiscalPeriodInterval,
  getLast12Months,
  startOfDay,
  startOfMonth
} from "../Calendar/functions/calendarFunctions";
import * as NotificationActions from "../../actions/notification";
import { tl, t } from "../translate";
import { useFiscalPeriod } from "../../hooks/query/useFiscalPeriods";
import { RootState } from "../../store";
import { FiscalPeriodProps } from "../../interfaces/Accounts";
import { getCurrentSubscription } from "../../slices/subscriptionSlice";
import HtmlTooltip from "../HtmlTooltip";
import { ReverseMap } from "../../helpers/types";
import { AdminPermissionGroup } from "../../interfaces/User";

enum PeriodFilter {
  FISCAL_PERIOD = "Fiscal Period",
  TILL_DATE = "Till Date"
}

enum DateSelectTypes {
  PERIOD = "period",
  MONTHS = "months",
  YEARS = "years"
}

enum PERIODS {
  TODAY = "today",
  YESTERDAY = "yesterday",
  CURRENT_MONTH = "currentMonth",
  TILL_DATE = "tillDate",
  TOMORROW = "tomorrow",
  NEXT_SEVEN_DAYS = "next7Days"
}

type PeriodTypes = ReverseMap<typeof PERIODS>;

function isTimeBetween10AMAnd5PM() {
  return moment().isBetween(
    moment().hour(10).minute(0).second(0),
    moment().hour(17).minute(0).second(0)
  );
}

const isDateBeforeShrawan2080 = (date: moment.Moment) => {
  // disabling selection of months before 2080/4 as the data is not available before that
  // disabled for stock ledger report
  const { bsYear, bsMonth } = convertADtoBS(date);
  return bsYear < 2080 || (bsYear === 2080 && bsMonth < 4);
};

type DateSelectType = ReverseMap<typeof DateSelectTypes>;

const isOptionDisabled = ({
  type,
  date,
  disableMonthsBefore
}: {
  type: DateSelectType;
  date: moment.Moment;
  disableMonthsBefore: boolean;
}) => {
  if (type === DateSelectTypes.YEARS) {
    return isTimeBetween10AMAnd5PM();
  }
  if (type === DateSelectTypes.MONTHS && disableMonthsBefore) {
    return isDateBeforeShrawan2080(date);
  }
  return false;
};

const getDates = (date, opts) => {
  const yesterday = moment(date).subtract(1, "days");
  const tomorrow = moment(date).add(1, "days");
  const next7Days = moment(date).add(7, "days");

  return opts.map((opt: PeriodTypes) => {
    switch (opt) {
      case PERIODS.TODAY:
        return { start: startOfDay(date), end: endOfDay(date), label: t(`periodPicker.${opt}`) };
      case PERIODS.YESTERDAY:
        return {
          start: startOfDay(yesterday),
          end: endOfDay(yesterday),
          label: t(`periodPicker.${opt}`)
        };
      case PERIODS.CURRENT_MONTH:
        return {
          start: startOfMonth(date),
          end: endOfMonth(date),
          label: t(`periodPicker.${opt}`)
        };
      case PERIODS.TILL_DATE:
        return {
          start: startOfDay(date),
          end: endOfDay(date),
          label: t(`periodPicker.${opt}`)
        };
      case PERIODS.TOMORROW:
        return {
          start: startOfDay(tomorrow),
          end: endOfDay(tomorrow),
          label: t(`periodPicker.${opt}`)
        };
      case PERIODS.NEXT_SEVEN_DAYS:
        return {
          start: startOfDay(tomorrow),
          end: endOfDay(next7Days),
          label: t(`periodPicker.${opt}`)
        };
      default:
        throw new Error(`Unhandled type ${opt}`);
    }
  });
};

const getRcsPreviousFiscalPeriod = (fiscalPeriods, currentFiscalPeriod) => {
  const { bsMonth, bsYear } = convertADtoBS(new Date(currentFiscalPeriod.from));
  const { previousFiscalYear } = getFiscalPeriodInterval(bsYear, bsMonth);

  const previousFiscalPeriod = fiscalPeriods.find((f) => {
    const { bsYear: yearInBs } = convertADtoBS(new Date(f.from));
    if (yearInBs === previousFiscalYear.startYear) {
      return true;
    }
    return false;
  });
  if (!previousFiscalPeriod) return null;
  return {
    previousFiscalYear: {
      startOfYear: previousFiscalPeriod.from,
      endOfYear: previousFiscalPeriod.to
    }
  };
};

const getFiscalYears = (
  fiscalPeriods,
  accountsOpeningDate,
  currentFiscalPeriod,
  isAccountSubscribed
) => {
  let startDateOfCurrentFiscalPeriod = currentFiscalPeriod.from;
  // Set the accountOpeningDate as start of fiscal year if accountOpeningDate is
  // after the fiscalYear and show alert.
  let thisYearAlertMsg = "";
  let previousYearAlertMsg = "";

  if (
    isAccountSubscribed &&
    accountsOpeningDate &&
    moment(accountsOpeningDate).isAfter(startDateOfCurrentFiscalPeriod)
  ) {
    startDateOfCurrentFiscalPeriod = accountsOpeningDate;
    thisYearAlertMsg = `Your account subscription date is after this fiscal year, the report data are after the ${
      convertADtoBS(new Date(startDateOfCurrentFiscalPeriod)).formatted4
    }`;
  }

  const { bsYear, bsMonth } = convertADtoBS(new Date(currentFiscalPeriod.from));

  // First find previous fiscal period from rc fiscalPeriods
  // if not found then setPreviousFiscalPeriod to currentFiscalPeriod - 1
  const { previousFiscalYear } =
    getRcsPreviousFiscalPeriod(fiscalPeriods, currentFiscalPeriod) ||
    getFiscalPeriodInterval(bsYear, bsMonth);

  let startOfPreviousFiscalYear = previousFiscalYear.startOfYear;
  if (
    isAccountSubscribed &&
    accountsOpeningDate &&
    moment(accountsOpeningDate).isAfter(previousFiscalYear.startOfYear)
  ) {
    startOfPreviousFiscalYear = accountsOpeningDate;
    previousYearAlertMsg = `Your account subscription date is after previous fiscal year, the report data are after the ${
      convertADtoBS(new Date(startOfPreviousFiscalYear)).formatted4
    }`;
  }

  return [
    {
      start: moment(startDateOfCurrentFiscalPeriod),
      end: moment(currentFiscalPeriod.to),
      label: t(`periodPicker.thisFiscalYear`),
      alertMsg: thisYearAlertMsg
    },
    {
      start: moment(startOfPreviousFiscalYear),
      end: moment(previousFiscalYear.endOfYear),
      label: t(`periodPicker.previousFiscalYear`),
      alertMsg: previousYearAlertMsg
    }
  ];
};

const getPeriod = ({
  currentFiscalPeriod,
  isAccountSubscribed,
  showTillDate,
  accountsOpeningDate,
  fiscalPeriods,
  showFuturePeriods,
  showMonthOnly = false,
  hideFiscalYear = false
}: {
  forAccountUser: boolean;
  currentFiscalPeriod: FiscalPeriodProps;
  isAccountSubscribed: boolean;
  showTillDate: boolean;
  accountsOpeningDate?: string;
  fiscalPeriods: FiscalPeriodProps[];
  showFuturePeriods: boolean;
  showMonthOnly: boolean;
  hideFiscalYear: boolean;
}) => {
  const date = moment();
  const periodTypes = [
    ...(showMonthOnly
      ? []
      : [
          {
            type: DateSelectTypes.PERIOD,
            opts: [
              PERIODS.TODAY,
              PERIODS.YESTERDAY,
              ...(showFuturePeriods ? [PERIODS.TOMORROW, PERIODS.NEXT_SEVEN_DAYS] : []),
              PERIODS.CURRENT_MONTH,
              ...(showTillDate ? [PERIODS.TILL_DATE] : [])
            ]
          }
        ]),
    {
      type: DateSelectTypes.MONTHS
    },
    ...(hideFiscalYear ? [] : [{ type: DateSelectTypes.YEARS }])
  ];
  let result = [] as {
    type: string;
    dates: { [key: string]: string | moment.Moment | number }[];
  }[];
  periodTypes.map((period) => {
    switch (period.type) {
      case DateSelectTypes.PERIOD:
        result = [...result, { type: period.type, dates: getDates(date, period.opts) }];
        break;
      case DateSelectTypes.MONTHS:
        result = [
          ...result,
          {
            type: period.type,
            dates: getLast12Months()
          }
        ];
        break;
      case DateSelectTypes.YEARS:
        result = [
          ...result,
          ...(currentFiscalPeriod
            ? [
                {
                  type: period.type,
                  dates: getFiscalYears(
                    fiscalPeriods,
                    accountsOpeningDate,
                    currentFiscalPeriod,
                    isAccountSubscribed
                  )
                }
              ]
            : [])
        ];
        break;
      default:
        throw new Error(`Unhandled type ${period.type}`);
    }
    return [];
  });
  return result;
};

function validate(startDate: moment.Moment, endDate: moment.Moment): boolean {
  return !startDate ? false : Math.abs(startDate.diff(endDate, "days")) > 95;
}

interface PeriodPicker {
  end: Date | moment.Moment;
  start: Date | moment.Moment;
  onChange: ({ start, end }: { start: Date | moment.Moment; end: Date | moment.Moment }) => void;
  limitToMonth: boolean;
  forAccountUser?: boolean;
  showTillDate?: boolean;
  variant?: string;
  showFuturePeriods?: boolean;
  showMonthOnly?: boolean;
  hideFiscalYear?: boolean;
  disableMonthsBefore?: boolean;
}

const PeriodPicker = ({
  end,
  start,
  onChange,
  limitToMonth = false,
  forAccountUser = false,
  showTillDate = false,
  showFuturePeriods = false,
  variant,
  showMonthOnly = false,
  hideFiscalYear = false,
  disableMonthsBefore = false
}: PeriodPicker): JSX.Element => {
  const dispatch = useDispatch();
  const rcId = useSelector((state: RootState) => state.userContext.resourceCentreId);
  const permissionGroup = useSelector(
    (state: RootState) => state.userContext.userCreds.userGroups[0]
  );

  const isAdmin = AdminPermissionGroup.includes(permissionGroup);

  const hideFiscalFilters = hideFiscalYear || !isAdmin;

  const isAccountSubscribed = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription?.features?.account?.subscribed
  );
  const { accountsOpeningDate } = useSelector(
    (state: RootState) => state.userContext.resourceCentre || {}
  );
  const hasCurrentSubscription = useSelector(
    (state: RootState) => state.subscriptions.currentSubscription
  );
  React.useEffect(() => {
    if (!hasCurrentSubscription && !!rcId) {
      dispatch(getCurrentSubscription(rcId));
    }
  }, [dispatch, hasCurrentSubscription, rcId]);
  const { data: fiscalPeriods } = useFiscalPeriod(rcId);
  const [open, setOpen] = React.useState(false);
  const [internalDate, setInternalDate] = React.useState({
    start: moment(start),
    end: moment(end)
  });
  const [selectedPeriod, setSelectedPeriod] = React.useState("");
  const anchorRef = React.useRef(null);
  const fromDateDisplayText = internalDate.start
    ? `${convertADtoBS(internalDate.start).formatted} ${internalDate.start.format("HH:mm")} -`
    : "";
  const displayText =
    selectedPeriod ||
    `${fromDateDisplayText}
     ${convertADtoBS(internalDate.end).formatted} ${internalDate.end.format("HH:mm")}`;

  const currentFiscalPeriod: FiscalPeriodProps = fiscalPeriods
    ?.sort((a, b) => new Date(b.from as Date).getTime() - new Date(a.from as Date).getTime())
    .find((fP) => fP.allowPeriod);

  return (
    <div>
      <TextField
        variant={variant}
        data-testmation="periodPicker"
        label={t("periodPicker.period")}
        value={displayText}
        margin="dense"
        fullWidth
        slotProps={{
          input: { readOnly: true }
        }}
        ref={anchorRef}
        className={styles.displayText}
        onClick={() => setOpen(true)}
      />
      <Popover
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center"
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
      >
        <div className={styles.popoverContent}>
          <div className={styles.periodGroup}>
            {getPeriod({
              forAccountUser,
              currentFiscalPeriod,
              isAccountSubscribed,
              showTillDate,
              accountsOpeningDate,
              showFuturePeriods,
              fiscalPeriods,
              showMonthOnly,
              hideFiscalYear: hideFiscalFilters
            }).map((period) => (
              <React.Fragment key={period.type}>
                <Typography
                  color="inherit"
                  component="div"
                  fontWeight={600}
                  mb={1}
                  fontSize="0.83rem"
                >
                  {t(`periodPicker.${period.type}`)}
                  {period.type === DateSelectTypes.YEARS && " (Available 5PM - 10AM)"}
                </Typography>
                <List disablePadding className={styles["MuiList-padding"]}>
                  {period.dates.map(({ start: startDate, end: endDate, label, alertMsg }) => (
                    <ListItemButton
                      disabled={isOptionDisabled({
                        type: period.type,
                        date: startDate,
                        disableMonthsBefore
                      })}
                      key={label}
                      dense
                      className={label === selectedPeriod ? styles.selected : ""}
                      onClick={() => {
                        if (forAccountUser && moment(startDate).isBefore(accountsOpeningDate)) {
                          dispatch(
                            NotificationActions.notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message:
                                "Sorry ! You can't select the date before you subscribed account module.",
                              autoTimeout: true
                            })
                          );
                          setOpen(false);
                          return;
                        }
                        if (label === PeriodFilter.FISCAL_PERIOD && !currentFiscalPeriod) {
                          dispatch(
                            NotificationActions.notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message: "Sorry ! Can't find Active Fiscal Period.",
                              autoTimeout: true
                            })
                          );
                          setOpen(false);
                          return;
                        }
                        if (label === PeriodFilter.TILL_DATE) {
                          setInternalDate({ start: null, end: endDate });
                          onChange({
                            start: null,
                            end: endDate
                          });
                          setOpen(false);
                          setSelectedPeriod(label);
                          return;
                        }
                        setSelectedPeriod(label);
                        setInternalDate({ start: startDate, end: endDate });
                        onChange({
                          start: startDate,
                          end: endDate
                        });
                        setOpen(false);
                      }}
                      data-testmation={label}
                    >
                      <Box display="flex" gap={1} alignItems="center">
                        <Typography fontWeight={400} fontSize="0.75rem">
                          {label}
                        </Typography>
                        {alertMsg && <HtmlTooltip description={alertMsg} />}
                      </Box>
                    </ListItemButton>
                  ))}
                </List>
              </React.Fragment>
            ))}
          </div>
          {!showMonthOnly && (
            <div className={styles.calendarGroup}>
              <Typography
                variant="h6"
                color="inherit"
                component="div"
                className={styles.calendarGroupLabel}
              >
                <Box fontWeight={600} fontSize="0.83rem">
                  {tl("periodPicker.selectSpecificPeriod")}{" "}
                  {limitToMonth && internalDate.start && " (Max 100 days)"}
                </Box>
              </Typography>
              <div className={styles.calendarInputGroup}>
                <Grid container>
                  {internalDate.start && (
                    <Grid className={styles.specificPickerGridItem} size={{ xs: 12, md: 5, lg: 5 }}>
                      <CalendarDropdown
                        label={t("periodPicker.from")}
                        TextFieldProps={{
                          margin: "dense",
                          variant: "outlined"
                        }}
                        maxValidDate={internalDate.end}
                        date={internalDate.start.toDate()}
                        withTimeSelector
                        onChange={(date) =>
                          setInternalDate((state) => ({ ...state, start: moment(date) }))
                        }
                      />
                    </Grid>
                  )}
                  <Grid className={styles.specificPickerGridItem} size={{ xs: 12, md: 5, lg: 5 }}>
                    <CalendarDropdown
                      label={t("periodPicker.until")}
                      TextFieldProps={{
                        margin: "dense",
                        variant: "outlined"
                      }}
                      minValidDate={internalDate.start}
                      date={internalDate.end.toDate()}
                      withTimeSelector
                      onChange={(date) =>
                        setInternalDate((state) => ({ ...state, end: moment(date) }))
                      }
                    />
                  </Grid>
                  <Grid className={styles.specificPickerGridItem} size={{ xs: 12, md: 12, lg: 2 }}>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        if (
                          forAccountUser &&
                          moment(internalDate.start).isBefore(accountsOpeningDate)
                        ) {
                          dispatch(
                            NotificationActions.notificationAdd({
                              id: new Date().getUTCMilliseconds(),
                              variant: "error",
                              message:
                                "Sorry ! You can't select the date before you subscribed account module.",
                              autoTimeout: true
                            })
                          );
                          setOpen(false);
                          return;
                        }
                        onChange(internalDate);
                        setSelectedPeriod("");
                        setOpen(false);
                      }}
                      disabled={limitToMonth && validate(internalDate.start, internalDate.end)}
                    >
                      {tl("periodPicker.set")}
                    </Button>
                  </Grid>
                </Grid>
              </div>
            </div>
          )}
        </div>
      </Popover>
    </div>
  );
};

PeriodPicker.defaultProps = {
  forAccountUser: false,
  showTillDate: false,
  variant: "standard"
};

export default PeriodPicker;
