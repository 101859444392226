import * as React from "react";
import { connect } from "react-redux";
import { push } from "connected-react-router";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid2 as Grid,
  GridProps,
  Paper,
  Tooltip,
  Typography
} from "@mui/material";
import queryString from "query-string";
import moment from "moment";
import { useRef } from "react";
import produce from "immer";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import * as assessmentActions from "../../../actions/assessments";
import * as NotificationActions from "../../../actions/notification";
import { t, tl } from "../../../components/translate";
import ServiceProviderSelector from "../ServiceProviderSelector";
import CreatedDate from "../CreatedDate";
import Footer from "./Footer";
import styles from "../Assessment.module.css";
import * as VitalActions from "../../../actions/vitals";
import { getReminders } from "../../../actions/reminders";
import {
  Assessment as IAssessment,
  Assessment as AssessmentInterface,
  AssessmentSettingsInterface,
  Diagnosis
} from "../../../interfaces/AssessmentInterfaces";
import { IThunkDispatch, RootState } from "../../../store";
import { Client } from "../../../interfaces/ClientInterface";
import OkhatiDialog from "../../../components/Dialog/Dialog";
import { ExtraOralData } from "../ExtraOral";
import {
  generateTeethFirstRow,
  generateTeethSecondRow
} from "../IntraOralAdvanced/TeethMarkerComponent";
import { getInitialState } from "../IntraOralAdvanced/Tooth";
import { hasContentAdvancedIntraOral } from "../../Modals/Assessment/AdvancedIntraOralContent";
import { getBatchSymptoms } from "../../../actions/assessments";
import { TableState } from "../../../components/EditableTable/EditableTable";
import OPDDuration from "../OPDDuration";
import AssessmentClientSelect from "../AssessmentClientSelect";
import Allergies from "../Allergies";
import { ComponentLabel, ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import ClientAssessments from "../../Client/ClientInfo/ClientAssessments";
import { ReverseMap } from "../../../helpers/types";
import OPDComponents, { SortOrderType } from "./OPDComponents";
import useMobileScreen from "../../../hooks/useMobileScreen";
import DepartmentSelector from "../DepartmentSelector";
import useDepartmentSubscription from "../../../hooks/useDepartmentSubscription";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import ServiceProviderAutoSelect from "../../ServiceProvider/ServiceProviderAutoSelect";
import { getActiveClaimCode } from "../../../api/ssf";
import { notificationAdd } from "../../../actions/notification";
import { commonErrorMessage } from "../../../helpers/messages";
import useIsSSFEnabled from "../../../hooks/useIsSSFEnabled";
import OpdSettingsPanel from "../../ResourceCentre/ModuleSettings/Panels/OpdSettingsPanel";
import SettingsButton from "../../../components/SettingsButton/SettingsButton";
import Can from "../../Policy/Can";
import { book } from "../../../api/bookings";

export enum MODE {
  CREATE = "create",
  EDIT = "edit",
  COPY = "copy"
}

export type ModeType = ReverseMap<typeof MODE>;

interface LocationInterface {
  hash: string;
  key: string;
  pathname: string;
  search: string;
}

interface AssessmentForm {
  mode: ModeType;
  clientId: number;
  setClientId: (id) => void;
  client: Client;
  userId: number;
  match: { params: { id: string; clientId: string } };
  isServiceProvider: boolean;
  resourceCentreId: number;
  spId: number;
  onSave: (clientId, assessmentId, serviceProviderId, data, isSaveAsTemplate) => void;
  getUpdatedSymptoms: () => void;
  goBack: (url) => void;
  assessmentSettings: AssessmentSettingsInterface;
  themeColor: string;
  location: LocationInterface;
  getVitalsById: (id) => void;
  vitalsCollection: [];
  getTemplate: () => void;
  templateData: AssessmentInterface;
  assessmentCopy: AssessmentInterface;
  setAssessmentCopy: React.Dispatch<React.SetStateAction<IAssessment>>;
  tabularComponent: TableState;
  sortOrder: SortOrderType[];
}

export const LOCALSTORAGE_ASSESSMENT = "assessment";

export const getLabelForComponent = (componentLabel: ComponentLabel): string =>
  componentLabel.customLabel || componentLabel.defaultLabel;

export const advancedIntraOralInitState = {
  teethData: [
    ...generateTeethFirstRow().map((tooth, i) => ({
      shapeState: getInitialState(i, tooth.type, false),
      tooth
    })),
    ...generateTeethSecondRow().map((tooth, i) => ({
      shapeState: getInitialState(i, tooth.type, true),
      tooth
    }))
  ],
  softTissueExamination: "",
  occlusion: "",
  calculus: "",
  stain: "",
  cariesRisk: "",
  recallGap: "",
  attrition: "",
  abrasion: "",
  erosion: "",
  abfraction: "",
  bpe: new Array(18).fill("")
};

const extraOralInitState = {
  tmz: "",
  lymphNodes: "",
  otherFindings: ""
};

const GridItem = ({
  children,
  xs = 12,
  sm = 6,
  md = 6,
  /* eslint-disable react/jsx-props-no-spreading */
  ...otherProps
}: {
  children: React.ReactNode;
  xs?: number;
  sm?: number;
  md?: number;
} & GridProps) => (
  <Grid size={{ xs, sm, md }} {...otherProps}>
    {children}
  </Grid>
);

const AssessmentForm: React.FC<AssessmentForm> = (props) => {
  const {
    location,
    mode,
    userId,
    client,
    clientId,
    setClientId,
    isServiceProvider,
    resourceCentreId,
    spId,
    onSave,
    goBack,
    assessmentSettings,
    themeColor,
    getVitalsById,
    vitalsCollection,
    templateData,
    getTemplate,
    assessmentCopy,
    setAssessmentCopy,
    getUpdatedSymptoms,
    tabularComponent,
    match,
    sortOrder
  } = props;

  const [assessment, setAssessment] = React.useState<
    Partial<AssessmentInterface> & { status: string }
  >({
    createdDate: new Date(),
    symptoms: [],
    tests: [],
    services: [],
    diagnosis: [],
    attachments: [],
    medication: [],
    followup: {
      days: 0,
      format: "days",
      note: "",
      startDate: new Date(),
      endDate: new Date(new Date().getTime() + 15 * 60000) // adjust the gap as needed
    },
    status: "",
    assessmentImage: "",
    others: "",
    intraOral: { permanent: {}, primary: {} },
    advancedIntraOral: advancedIntraOralInitState,
    extraOral: extraOralInitState as ExtraOralData,
    provisionalDiagnosis: "",
    hopi: "",
    vitals: [],
    questionnaire: [],
    treatmentPlan: "",
    treatmentGiven: "",
    pastHistoryOfAllergy: "",
    presentHealthStatus: "",
    advice: "",
    eye: {},
    productMedication: [],
    investigation: "",
    tabularComponent,
    opdDuration: null,
    sendToClient: true,
    prescriptionAudioUrl: null,
    departmentId: null,
    referToSpId: null,
    referToSpDepartmentId: null,
    claimCode: ""
  });

  const [isSsfOpd, setIsSsfOpd] = React.useState(false);

  const [clientAssessments, setClientAssessments] = React.useState<AssessmentInterface[]>([]);
  const assessmentId = Number(match?.params?.id);
  const assessmentData = assessmentId
    ? clientAssessments.find(({ id }) => assessmentId === id)
    : null;

  React.useEffect(() => {
    getTemplate();
  }, [getTemplate]);

  React.useEffect(() => {
    if (Number(assessment.followup.days) < 0) {
      setAssessment({
        ...assessment,
        followup: { ...assessment.followup, days: 0 }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessment.followup.days]);

  const [spID, setSpID] = React.useState(isServiceProvider ? spId : "");

  const currentServiceProvider = useAppSelector((state) =>
    state.resources.resourceCentreServiceProviders.find((item) => item.id === spID)
  );

  const currentReferToSp = useAppSelector((state) => {
    if (assessment.referToSpId) {
      return state.resources.resourceCentreServiceProviders.find(
        (item) => item.id === Number(assessment.referToSpId)
      );
    }
    return null;
  });

  const [showFields, setShowFields] = React.useState({
    permanent: true,
    primary: false
  });

  const isMobile = useMobileScreen();

  const [showDialog, setShowDialog] = React.useState(false);
  const [showSettingsPanel, setShowSettingsPanel] = React.useState(false);
  const unsavedAssessment = React.useRef(null);

  const opdTimeRef = useRef<HTMLButtonElement>(null);
  const { isDepartmentSubscribed } = useDepartmentSubscription();
  const dispatch = useAppDispatch();
  const { isSsfEnabled } = useIsSSFEnabled();

  React.useEffect(() => {
    if (clientId && isSsfOpd) {
      (async () => {
        try {
          const code = await getActiveClaimCode(clientId);
          setAssessment({ ...assessment, claimCode: code.claimCode });
        } catch (e) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: e.msg || commonErrorMessage,
              autoTimeout: false
            })
          );
        }
      })();
    }
  }, [clientId]);

  React.useEffect(() => {
    const { spid } = queryString.parse(location?.search);
    if (spid) {
      setSpID(Number(spid));
    }
  }, [location?.search]);

  React.useEffect(() => {
    if (clientId) getVitalsById(clientId);
  }, [clientId, getVitalsById]);

  React.useEffect(() => {
    // set vitals that were entered from client info panel in last 24 hours for new opd
    if (mode === MODE.CREATE && clientId) {
      setAssessment({ ...assessment, vitals: vitalsCollection });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vitalsCollection, assessmentId]);

  React.useEffect(() => {
    setShowFields({
      permanent: assessmentData?.intraOral?.permanent
        ? !(Object.keys(assessmentData.intraOral.permanent).length === 0)
        : true,
      primary:
        assessmentData?.intraOral?.primary &&
        !(Object.keys(assessmentData?.intraOral?.primary).length === 0)
    });
  }, [assessmentData]);

  const setData = (data: Partial<AssessmentInterface>) => {
    let newDiagnosis: Diagnosis[] = [];
    if (data.diagnosis && !Array.isArray(data.diagnosis)) {
      newDiagnosis.push(data.diagnosis);
    } else if (Array.isArray(data.diagnosis)) {
      newDiagnosis = data.diagnosis;
    }

    setAssessment({
      createdDate: data.createdDate,
      symptoms: data.symptoms || [],
      tests: data.tests || [],
      services: data.services || [],
      diagnosis: newDiagnosis,
      attachments: data.attachments,
      followup: data.followup
        ? data.followup
        : {
            days: 0,
            format: "days",
            note: "",
            startDate: new Date(),
            endDate: new Date(new Date().getTime() + 15 * 60000) // adjust the gap as needed
          },
      medication: data.medication,
      status: data.assessment,
      assessmentImage: data.assessmentImage,
      others: data.others,
      extraOral: data.extraOral || extraOralInitState,
      intraOral: data.intraOral || { permanent: {}, primary: {} },
      advancedIntraOral: data.advancedIntraOral || advancedIntraOralInitState,
      provisionalDiagnosis: data.provisionalDiagnosis,
      hopi: data.hopi,
      vitals: data.vitals,
      questionnaire: data.questionnaire,
      treatmentPlan: data.treatmentPlan ? data.treatmentPlan : "",
      treatmentGiven: data.treatmentGiven ? data.treatmentGiven : "",
      pastHistoryOfAllergy: data.pastHistoryOfAllergy ? data.pastHistoryOfAllergy : "",
      presentHealthStatus: data.presentHealthStatus ? data.presentHealthStatus : "",
      eye: data.eye,
      advice: data.advice,
      productMedication: data.productMedication,
      investigation: data.investigation || "",
      tabularComponent: data.tabularComponent || tabularComponent,
      opdDuration: data.opdDuration,
      sendToClient: data.sendToClient,
      prescriptionAudioUrl: data.prescriptionAudioUrl,
      departmentId: data.departmentId,
      referToSpId: data.referToSpId,
      referToSpDepartmentId: data.referToSpDepartmentId
    });
  };

  React.useEffect(() => {
    if (assessmentData) {
      setData({ ...assessmentData, status: assessmentData.assessment });
      setSpID(assessmentData.serviceProviderId);
    }
  }, [assessmentData]);

  React.useEffect(() => {
    if (assessmentCopy) {
      setShowFields({
        permanent: assessmentData?.intraOral?.permanent
          ? !(Object.keys(assessmentData.intraOral.permanent).length === 0)
          : true,
        primary:
          assessmentData?.intraOral?.primary &&
          !(Object.keys(assessmentData?.intraOral?.primary).length === 0)
      });

      setData(assessmentCopy);
      setSpID(assessmentCopy.serviceProviderId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assessmentCopy]);

  React.useEffect(() => {
    if (mode === MODE.CREATE) {
      const incompleteAssessment = JSON.parse(localStorage.getItem(LOCALSTORAGE_ASSESSMENT));
      if (
        incompleteAssessment &&
        incompleteAssessment.userId === userId &&
        (!clientId || Number(clientId) === Number(incompleteAssessment?.clientId))
      ) {
        unsavedAssessment.current = incompleteAssessment;
        setShowDialog(true);
      }
    }
  }, [clientId, mode, userId]);

  React.useEffect(() => {
    if (mode === MODE.CREATE && Boolean(clientId)) {
      localStorage.setItem(
        LOCALSTORAGE_ASSESSMENT,
        JSON.stringify({
          clientId,
          clientName: `${client?.firstName} ${client?.lastName}`,
          spID,
          userId,
          data: assessment
        })
      );
    }
  }, [clientId, client, spID, userId, mode, assessment]);

  if (!assessmentSettings) return null;

  const filteredComponentLabelOrder = sortOrder.filter((item) => assessmentSettings[item]);

  const onApplyTemplateClick = () => {
    if (templateData) {
      setShowFields({
        permanent: templateData?.intraOral?.permanent
          ? !(Object.keys(templateData.intraOral.permanent).length === 0)
          : true,
        primary:
          templateData?.intraOral?.primary &&
          !(Object.keys(templateData?.intraOral?.primary).length === 0)
      });
      setData(templateData);
    }
  };

  return (
    <Box className={styles.assessmentFormRoot}>
      {showDialog && (
        <OkhatiDialog
          title={tl("assessment.recoverUnsavedPrescriptionTitle")}
          description={`Unsaved prescription found for ${unsavedAssessment.current.clientName}. Do you want to recover it?`}
          next={async () => {
            setClientId(unsavedAssessment.current.clientId);
            setSpID(unsavedAssessment.current.spID);
            setData(unsavedAssessment.current.data);
            setShowDialog(false);
          }}
          cancel={() => {
            localStorage.removeItem(LOCALSTORAGE_ASSESSMENT);
            setShowDialog(false);
          }}
          readMode={false}
        />
      )}
      <Box
        sx={(theme) => ({
          position: "absolute",
          top: theme.spacing(6),
          right: isMobile ? theme.spacing(2) : theme.spacing(5),
          zIndex: 3,
          display: "flex",
          gap: 1
        })}
      >
        <Tooltip title="Apply Template" arrow>
          <Button
            startIcon={<ContentPasteIcon />}
            data-testmation="applyTemplate"
            color="primary"
            onClick={() => onApplyTemplateClick()}
            disabled={!templateData}
            size="small"
          >
            {isMobile ? "" : "Apply Template"}
          </Button>
        </Tooltip>
        <SettingsButton onClick={() => setShowSettingsPanel(true)} />
      </Box>
      {isSsfEnabled && (
        <Box px={2} display="flex" gap={2} alignItems="center">
          <FormControlLabel
            control={
              <Checkbox
                size="small"
                checked={isSsfOpd}
                onChange={() => {
                  setIsSsfOpd(!isSsfOpd);
                  setClientId(null);
                  setAssessment({ ...assessment, claimCode: "" });
                }}
              />
            }
            label="Is SSF"
          />
          {isSsfOpd && clientId && (
            <Typography>
              {`Claim code: ${
                assessment.claimCode || "Not found, please create bill for opd first."
              }`}
            </Typography>
          )}
        </Box>
      )}
      <Can policyAccessKey="medical:changeSettings">
        {showSettingsPanel && <OpdSettingsPanel onClose={() => setShowSettingsPanel(false)} />}
      </Can>
      <Grid
        container
        spacing={1}
        sx={{
          px: 2,
          py: 1
        }}
      >
        <GridItem sm={12}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1.5
            }}
          >
            <AssessmentClientSelect
              clientId={clientId}
              setClientId={setClientId}
              isDisabled={mode === MODE.EDIT}
              width={isDepartmentSubscribed ? "280px" : "320px"}
            />

            {!isServiceProvider && !assessmentData?.serviceProviderId && (
              <Box sx={{ display: "flex", gap: 2 }}>
                <ServiceProviderSelector
                  width={isDepartmentSubscribed ? "280px" : "320px"}
                  resourceCentreId={resourceCentreId}
                  spID={spID}
                  onChange={(v) => setSpID(v)}
                />
                {isDepartmentSubscribed && (
                  <DepartmentSelector
                    width="150px"
                    initialValue={currentServiceProvider?.departmentId || assessment.departmentId}
                    onChange={(id) => setAssessment({ ...assessment, departmentId: id })}
                  />
                )}
              </Box>
            )}

            {!isServiceProvider && !assessmentData?.referToSpId && (
              <Box sx={{ display: "flex", gap: 2 }}>
                <Box width={isDepartmentSubscribed ? "280px" : "320px"}>
                  <ServiceProviderAutoSelect
                    isClearable
                    disableDefaultError
                    onChange={(v) => {
                      setData({ ...assessment, referToSpId: v });
                    }}
                    serviceProviderId={Number(assessment?.referToSpId)}
                    label={t("assessment.referToSp")}
                  />
                </Box>
                {isDepartmentSubscribed && (
                  <DepartmentSelector
                    width="150px"
                    // eslint-disable-next-line max-len
                    initialValue={
                      currentReferToSp?.departmentId || assessment?.referToSpDepartmentId || null
                    }
                    onChange={(id) => setAssessment({ ...assessment, referToSpDepartmentId: id })}
                  />
                )}
              </Box>
            )}
            <Box sx={{ display: "flex", gap: 2 }}>
              <CreatedDate
                width={isDepartmentSubscribed ? "280px" : "150px"}
                createdDate={assessment && assessment.createdDate}
                onChange={(v) => {
                  setAssessment({ ...assessment, createdDate: v });
                }}
              />
              {assessmentSettings.opdDuration && (
                <OPDDuration
                  opdTimeRef={opdTimeRef}
                  assessmentId={assessmentId}
                  opdDuration={assessment.opdDuration}
                />
              )}
            </Box>
          </Box>
        </GridItem>
        <GridItem sm={12}>
          <Box
            sx={(theme) => ({
              display: "flex",
              alignItems: "flex-end",
              flexDirection: "column",
              [theme.breakpoints.down("md")]: {
                alignItems: "flex-start"
              }
            })}
          >
            <Allergies clientId={client?.id} />
          </Box>
        </GridItem>
        {/* empty element to reserve a whole row */}
        <Grid size={{ xs: 12 }}>
          <Divider />
        </Grid>
      </Grid>
      <Box
        sx={(theme) => ({
          display: "flex",
          gap: 1,
          [theme.breakpoints.down("md")]: {
            flexDirection: "column-reverse"
          }
        })}
      >
        {/* form inputs */}
        <Box
          sx={{
            padding: 2,
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            gap: 1
          }}
        >
          {filteredComponentLabelOrder.map((item) => (
            <OPDComponents
              key={item}
              component={item}
              assessment={assessment}
              setAssessment={setAssessment}
              spID={spID}
              assessmentId={assessmentId}
              clientId={clientId}
              themeColor={themeColor}
              tabular={{
                initValue: assessmentData?.tabularComponent || tabularComponent,
                title: tabularComponent.title
              }}
              intraOral={{ showFields, setShowFields }}
            />
          ))}
        </Box>
        {/* history sidebar */}
        <Box
          component={Paper}
          sx={(theme) => ({
            width: "30%",
            px: 2,
            [theme.breakpoints.down("md")]: {
              width: "100%",
              maxHeight: "70vh",
              overflowY: "auto"
            }
          })}
        >
          <ClientAssessments
            clientId={clientId}
            setAssessmentCopy={setAssessmentCopy}
            numberOfHistoryToLoad={10}
            afterFetch={(list) => setClientAssessments(list)}
          />
        </Box>
      </Box>

      <Footer
        mode={mode}
        sendToClient={assessment.sendToClient}
        onSendToClientChange={() =>
          setAssessment((prevState) => ({ ...assessment, sendToClient: !prevState.sendToClient }))
        }
        onSave={async () => {
          const isSaveAsTemplate = false;
          localStorage.removeItem(LOCALSTORAGE_ASSESSMENT);
          const updatedAssessment = produce(assessment, (draft) => {
            draft.opdDuration = opdTimeRef.current ? Number(opdTimeRef.current.value) : null;
          });
          // eslint-disable-next-line max-len
          await onSave(clientId, assessmentId, spID, updatedAssessment, isSaveAsTemplate);
          await getVitalsById(clientId);
          if (assessment.symptoms.some((item) => !item.code.length)) {
            await getUpdatedSymptoms();
          }

          // if follow up day in greater than 0 then create/update followup booking
          if (mode === MODE.CREATE && assessment.followup && assessment.followup.days > 0) {
            const { startDate, endDate, days, format, note } = assessment.followup;

            if (!startDate || !endDate) {
              return;
            }

            const start = moment(startDate)
              .add(days, format as moment.unitOfTime.DurationConstructor)
              .toDate();
            const end = moment(endDate)
              .add(days, format as moment.unitOfTime.DurationConstructor)
              .toDate();

            const bookingData = {
              resourceCentreId,
              serviceProviderId: spID,
              departmentId: assessment.departmentId,
              clientId: clientId || client?.id,
              start,
              end,
              clientName: `${client?.firstName} ${client?.lastName}`,
              clientPhoneNo: client?.phone,
              clientEmail: client?.email,
              clientGender: client?.gender,
              clientAddress: client?.address,
              followUp: true,
              remarks: note,
              // using default valuse for paymentInfo, sendToClient, sendToSp and services
              paymentInfo: { amount: 0, isPaid: false, required: true },
              sendToClient: true,
              sendToSp: true,
              services: []
            };
            await book(bookingData);
          }
        }}
        onTemplateSave={async () => {
          const isSaveAsTemplate = true;
          await onSave(clientId, assessmentId, spID, assessment, isSaveAsTemplate);
        }}
        saveDisabled={!spID || !clientId}
        goBack={() => goBack(`/clients/${clientId}`)}
        printJSX={undefined}
      />
    </Box>
  );
};

const mapStateToProps = (
  { assessments, userContext, vitals, clients, resources }: RootState,
  ownProps
) => {
  const currentResourceCentre: ResourceCentre = resources.resourceCentres.find(
    (item: ResourceCentre) => item.id === userContext.resourceCentreId
  );
  const isServiceProvider = userContext.userCreds?.authenticable === "serviceProvider" || false;
  const userId = Number(userContext.userCreds.id);
  const spId = isServiceProvider && Number(userContext.user.id);
  const client = clients.collection?.find(({ id }) => Number(ownProps.clientId) === id);
  const assessmentSettings = currentResourceCentre?.settings.assessmentSettings.components;
  const tabularComponent = currentResourceCentre?.settings.assessmentSettings.tabularComponent;
  const themeColor = currentResourceCentre?.settings.lookAndFeel.primaryColour;
  const vitalsCollection = vitals.collection;
  const templateData = assessments.template;
  const sortOrder = currentResourceCentre?.settings.assessmentSettings.sortOrder;
  return {
    spId,
    userId,
    client,
    isServiceProvider,
    assessmentSettings,
    themeColor,
    vitalsCollection,
    templateData,
    tabularComponent,
    sortOrder
  };
};

export default connect(mapStateToProps, (dispatch: IThunkDispatch) => ({
  getVitalsById: (id: number) => dispatch(VitalActions.getVitalsById(id)),
  goBack: (url: string) => {
    dispatch(push(url));
  },
  getUpdatedSymptoms: async () => {
    await dispatch(getBatchSymptoms(true));
  },
  onSave: async (clientId, assessmentId, spId, assessment, isSaveAsTemplate) => {
    const {
      assessmentImage,
      createdDate,
      diagnosis,
      symptoms,
      tests,
      services,
      attachments,
      followup,
      medication,
      others,
      intraOral,
      extraOral,
      advancedIntraOral,
      provisionalDiagnosis,
      hopi,
      vitals,
      questionnaire,
      treatmentPlan,
      treatmentGiven,
      pastHistoryOfAllergy,
      presentHealthStatus,
      eye,
      advice,
      productMedication,
      investigation,
      tabularComponent,
      opdDuration,
      sendToClient,
      prescriptionAudioUrl,
      departmentId,
      referToSpId,
      referToSpDepartmentId,
      claimCode
    } = assessment;

    if (!spId && !isSaveAsTemplate) {
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: tl("assessment.noSPError"),
          autoTimeout: true
        })
      );
      return;
    }
    const assessmentData = {
      clientId,
      assessment: assessment.status,
      createdDate,
      assessmentImage: assessmentImage || "",
      diagnosis,
      symptoms,
      tests,
      services,
      attachments: attachments.map((item) => item.uuid),
      followup: followup.days <= 0 ? null : followup,
      medication,
      others,
      intraOral,
      advancedIntraOral: hasContentAdvancedIntraOral(advancedIntraOral) ? advancedIntraOral : null,
      extraOral,
      provisionalDiagnosis,
      hopi,
      vitals,
      questionnaire,
      serviceProviderId: spId,
      treatmentPlan,
      treatmentGiven,
      pastHistoryOfAllergy,
      presentHealthStatus,
      eye,
      advice,
      productMedication,
      investigation,
      tabularComponent,
      opdDuration,
      sendToClient,
      prescriptionAudioUrl,
      departmentId,
      referToSpId,
      referToSpDepartmentId,
      claimCode
    };

    assessmentData.vitals.forEach((vital) => {
      // eslint-disable-next-line no-param-reassign
      delete vital.type;
    });

    if (assessmentId && !isSaveAsTemplate) {
      await dispatch(assessmentActions.updateAssessment(assessmentId, assessmentData));
    } else if (isSaveAsTemplate) {
      await dispatch(
        assessmentActions.createTemplateAssessment({ ...assessmentData, createdDate: null })
      );
      dispatch(
        NotificationActions.notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Template Saved Successfully",
          autoTimeout: true
        })
      );
      await dispatch(assessmentActions.getTemplateAssessment());
    } else {
      await dispatch(assessmentActions.createAssessment(assessmentData));
    }
    dispatch(getReminders());
  },
  getTemplate: async () => {
    await dispatch(assessmentActions.getTemplateAssessment());
  }
}))(AssessmentForm);
