import React from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Autocomplete,
  createFilterOptions,
  debounce
} from "@mui/material";
import * as NotificationActions from "../../../actions/notification";
import { getBatchDiagnosis, searchDisease } from "../../../api/assessment";
import { tl } from "../../../components/translate";
import styles from "../Assessment.module.css";
import { ICD } from "../../../interfaces/AssessmentInterfaces";
import { useAppDispatch } from "../../../store/hooks";
import { commonErrorMessage } from "../../../helpers/messages";
import DiagnosisTreeViewDialog from "./DiagnosisTreeViewDialog";

export interface IcdItemHashMap {
  [key: string]: Record<string, ICD>;
}

const buildItemsHash = (items: ICD[] = [], parentMapHash: IcdItemHashMap) => {
  const parentMap = { ...parentMapHash };
  items.forEach((item) => {
    parentMap[item.parentId] = parentMap[item.parentId] || {};
    parentMap[item.parentId][item.id] = item;
  });
  return parentMap;
};

interface Props {
  onChange: (value: Partial<ICD>) => void;
  disableCustomIcd?: boolean;
  label?: string;
  isTitleHidden?: boolean;
}

const filter = createFilterOptions<ICD>();

const DiagnosisWithTreeView = ({
  onChange,
  label,
  isTitleHidden,
  disableCustomIcd = false
}: Props): JSX.Element => {
  const [icdItemsHash, setIcdItemsHash] = React.useState<IcdItemHashMap>({});
  const [icdItems, setIcdItems] = React.useState<ICD[]>([]);
  const [isTreeViewOpen, setIsTreeViewOpen] = React.useState(false);
  const dispatch = useAppDispatch();
  const [selectedICD, setSelectedICD] = React.useState<ICD>();
  const [icdInputValue, setIcdInputValue] = React.useState("");

  React.useEffect(() => {
    (async () => {
      try {
        const res = await getBatchDiagnosis();
        setIcdItemsHash((prevState) => ({
          ...prevState,
          ...buildItemsHash(res, prevState)
        }));
      } catch (error) {
        dispatch(
          NotificationActions.notificationAdd({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: error?.data?.message || commonErrorMessage,
            autoTimeout: true
          })
        );
      }
    })();
  }, []);

  const searchIcd = React.useMemo(
    () =>
      debounce(async (request: { input: string }, callback: (results?: ICD[]) => void) => {
        try {
          const response = await searchDisease(request.input);
          callback(response);
        } catch (error) {
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: error?.data?.message || commonErrorMessage,
              autoTimeout: true
            })
          );
        }
      }, 400),
    []
  );

  React.useEffect(() => {
    if (icdInputValue === "") {
      setIcdItems([]);
      return;
    }
    searchIcd({ input: icdInputValue }, (results?: ICD[]) => {
      let newOptions: ICD[] = [];
      if (results) {
        newOptions = [...newOptions, ...results];
      }
      setIcdItems(newOptions);
    });
  }, [icdInputValue, fetch]);

  return (
    <>
      <Box className={styles.assessmentRow}>
        {!isTitleHidden && (
          <Box className={styles.assessmentLabel}>
            <Typography>
              <Box component="span" fontWeight="600">
                {label}
              </Box>
            </Typography>
          </Box>
        )}
        <Box className={styles.assessmentField} display="flex">
          <Autocomplete
            forcePopupIcon={false}
            options={icdItems}
            inputValue={icdInputValue}
            disableClearable
            fullWidth
            value={selectedICD}
            onChange={(_, newValue) => {
              if (newValue && newValue.inputValue) {
                onChange({ title: newValue.inputValue, code: "" });
              } else {
                onChange(newValue);
              }
              setSelectedICD(undefined);
              setIcdInputValue("");
            }}
            filterOptions={(options, params) => {
              const filtered = filter(options, params);

              const { inputValue } = params;
              const isExisting = options.some((option) => inputValue === option.title);
              if (inputValue !== "" && !isExisting && !disableCustomIcd) {
                filtered.push({
                  inputValue,
                  title: `Add "${inputValue}"`
                });
              }

              return filtered;
            }}
            getOptionLabel={(item) => {
              if (item.code) {
                return `${item.code}: ${item.title}`;
              }
              return item.title;
            }}
            onInputChange={async (_, value, reason) => {
              if (reason === "input") {
                setIcdInputValue(value);
              } else {
                setIcdInputValue("");
              }
            }}
            renderInput={({ InputProps, ...otherProps }) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...otherProps}
                fullWidth
                margin="dense"
                variant="outlined"
                placeholder="Write/search diagnosis"
                slotProps={{
                  input: {
                    ...InputProps,
                    endAdornment: (
                      <Button
                        data-testmation="diagnosisButton"
                        variant="contained"
                        sx={{ padding: 0, minWidth: "30px" }}
                        onClick={() => setIsTreeViewOpen(true)}
                      >
                        {tl("assessment.ICD")}
                      </Button>
                    )
                  }
                }}
              />
            )}
          />
        </Box>
      </Box>
      <DiagnosisTreeViewDialog
        onClose={() => setIsTreeViewOpen(false)}
        isOpen={isTreeViewOpen}
        onChange={onChange}
        icdItemsHash={icdItemsHash}
        onIcdItemChange={(newItems) => {
          setIcdItemsHash((prevState) => ({
            ...prevState,
            ...buildItemsHash(newItems, prevState)
          }));
        }}
      />
    </>
  );
};

export default DiagnosisWithTreeView;
