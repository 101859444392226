import React from "react";
import produce from "immer";
import { Prompt } from "react-router";
import Clear from "@mui/icons-material/Clear";
import { push } from "connected-react-router";
import SearchIcon from "@mui/icons-material/Search";
import { useDispatch, useSelector } from "react-redux";
import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  IconButton,
  ButtonGroup,
  Menu,
  MenuItem
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import "./LinkServicesAndLabTests.scss";
import List from "../../components/List";
import { tl } from "../../components/translate";
import { getLabTest } from "../../api/assessment";
import PageControl from "../../components/PageControl";
import { LabTest } from "../../interfaces/LabInterfaces";
import { notificationAdd } from "../../actions/notification";
import { ServiceInterface } from "../../interfaces/ServiceInterface";
import { QueryProps } from "../../interfaces/ProductInterface";
import DebouncedTextField from "../../components/DebouncedTextField";
import { RootState } from "../../store";
import useMobileScreen from "../../hooks/useMobileScreen";
import OkhatiDialog from "../../components/Dialog/Dialog";
import { getServicesForMassLinking, linkServicesAndLabTests } from "../../api/services";

export default function LinkServicesAndLabTests(): JSX.Element {
  const dispatch = useDispatch();
  const isMobileScreen = useMobileScreen();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isUpdading, setIsUpdating] = React.useState(false);
  const [isDebouncing, setIsDebouncing] = React.useState(false);
  const [labTests, setLabTests] = React.useState<LabTest[]>([]);
  const [showConfirmDialog, setShowConfirmDialog] = React.useState(false);
  const policies = useSelector((state: RootState) => state.userContext.policies);
  const [pendingNavigation, setPendingNavigation] = React.useState<string | null>(null);
  const [originalServices, setOriginalServices] = React.useState<ServiceInterface[]>([]);

  const [services, setServices] = React.useState<{
    results: ServiceInterface[];
    total: number;
  }>({
    results: [],
    total: 0
  });
  const [query, setQuery] = React.useState<Partial<QueryProps>>({
    page: 0,
    pageSize: 50,
    search: ""
  });
  const [servicesToLink, setServicesToLink] = React.useState<
    { serviceId: number; labTestId: number | null }[]
  >([]);

  // linkServicesAndLabTests
  const handleLinkLabTest = async () => {
    setIsUpdating(true);

    try {
      await linkServicesAndLabTests(servicesToLink);

      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          variant: "success",
          autoTimeout: true,
          message: "Lab tests linked successfully"
        })
      );

      setServicesToLink([]);
      if (pendingNavigation) {
        // dispatch(push()) and history.push
        // is not working here, so using window.location.href
        window.location.href = pendingNavigation;
        setPendingNavigation(null);
      }
    } catch (error) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          variant: "error",
          autoTimeout: true,
          message: error?.data?.message || error?.message || "Error linking lab tests"
        })
      );
    } finally {
      setIsUpdating(false);
      setShowConfirmDialog(false);
    }
  };

  // get lab tests and services
  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      try {
        const [labTestRes, serviceRes] = await Promise.all([
          getLabTest(true),
          getServicesForMassLinking({ ...query })
        ]);
        setLabTests(labTestRes);
        setServices(serviceRes);
        setOriginalServices(serviceRes.results);
      } catch (error) {
        dispatch(
          notificationAdd({
            id: new Date().getTime(),
            variant: "error",
            autoTimeout: true,
            message: error?.data?.message || error?.message || "Error fetching data"
          })
        );
      } finally {
        setIsLoading(false);
      }
    })();
  }, [query]);

  return (
    <Box className="massLinkserviceList">
      <Box sx={{ height: "calc(100% - 40px)" }}>
        <Prompt
          when={servicesToLink.length > 0}
          message={(location) => {
            setPendingNavigation(location.pathname);
            setShowConfirmDialog(true);
            return false;
          }}
        />

        {showConfirmDialog && (
          <OkhatiDialog
            title="Unsaved Changes"
            description="You have unsaved changes. Do you want to save them before leaving?"
            next={handleLinkLabTest}
            cancel={() => {
              setPendingNavigation(null);
              setShowConfirmDialog(false);
            }}
            readMode={false}
          />
        )}

        <List
          data={services.results || []}
          rowHeight={50}
          createDropdownLabel={isMobileScreen ? "Create" : tl("services.createService")}
          hideCreateButton
          isResponsive
          activeRow={null}
          withoutSearch
          dropdownMenuItems={
            <ButtonGroup
              color="primary"
              variant="contained"
              ref={anchorEl}
              aria-label="split button"
              sx={{ marginLeft: "20px" }}
            >
              <Button
                onClick={() => {
                  setAnchorEl(null);

                  dispatch(push("/services/newProduct"));
                }}
                data-testmation="serviceListCreateProduct"
                disabled={!policies["services:createProduct"]}
              >
                {isMobileScreen ? "Create" : tl("services.createService")}
              </Button>
              <Button onClick={(event) => setAnchorEl(event.currentTarget)} size="small">
                <ArrowDropDownIcon />
              </Button>
              <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
                data-testmation="serviceListCreateDropdown"
              >
                <MenuItem
                  onClick={() => {
                    setAnchorEl(null);
                    dispatch(push("/services/newPackage"));
                  }}
                  data-testmation="serviceListCreatePackage"
                  disabled={!policies["services:createPackage"]}
                >
                  {tl("services.createPackage")}
                </MenuItem>
                <MenuItem onClick={() => dispatch(push("/linkServicesAndLabTests"))}>
                  {tl("services.linkServicesAndLabTests")}
                </MenuItem>
              </Menu>
            </ButtonGroup>
          }
          onDropdownClicked={(event) => setAnchorEl(event.currentTarget)}
          customButtonGroup={
            <Box
              sx={{
                height: "35px",
                padding: "0 10px",
                display: "flex",
                borderRadius: "5px",
                alignItems: "center",
                position: "relative",
                width: "250px",
                overflow: "hidden",
                backgroundColor: "#ededed"
              }}
            >
              <SearchIcon />

              <DebouncedTextField
                sx={{ ml: "10px" }}
                value={query.search}
                placeholder="Search..."
                size="small"
                InputLabelProps={{ shrink: true }}
                InputProps={{ disableUnderline: true }}
                onChange={(e) => {
                  setQuery({ ...query, search: e.target.value, page: 0 });
                }}
                debounceAt={1000}
                setDebounceLoading={setIsDebouncing}
              />

              {isLoading || isDebouncing ? (
                <Box sx={{ display: "grid", placeItems: "center" }}>
                  <CircularProgress size={20} />
                </Box>
              ) : (
                query.search && (
                  <IconButton onClick={() => setQuery({ ...query, search: "" })} size="small">
                    <Clear />
                  </IconButton>
                )
              )}
            </Box>
          }
          columns={[
            {
              key: "name",
              label: tl("name"),
              sortable: true,
              sortFunction: (a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1),
              cellRenderer: (item) => <Typography>{item.name}</Typography>
            },
            {
              key: "linkLabTest",
              label: tl("services.linkLabTest"),
              sortable: true,
              sortFunction: (a, b) => {
                if (!a.productisableId) return -1;
                if (!b.productisableId) return 1;

                return a.productisableId > b.name.productisableId ? 1 : -1;
              },
              cellRenderer: (row) => (
                <Autocomplete
                  value={
                    row?.productisableId
                      ? labTests.find((test) => test.id === row.productisableId)
                      : (() => {
                          const serviceToLinkItem = servicesToLink.find(
                            (item) => item.serviceId === Number(row.id)
                          );
                          return serviceToLinkItem
                            ? labTests.find((test) => test.id === serviceToLinkItem.labTestId)
                            : null;
                        })()
                  }
                  options={labTests}
                  getOptionLabel={(option) => option.name}
                  onChange={(_, newValue) => {
                    const productisableId = newValue?.id || null;

                    setServices((prevServices) =>
                      produce(prevServices, (draft) => {
                        const service = draft.results.find((s) => s.id === row.id);
                        if (service) {
                          service.productisableId = productisableId;
                        }
                      })
                    );

                    setServicesToLink((prev) =>
                      produce(prev, (draft) => {
                        const index = draft.findIndex((item) => item.serviceId === Number(row.id));
                        if (index !== -1) {
                          draft[index].labTestId = productisableId;
                        } else {
                          draft.push({
                            serviceId: Number(row.id),
                            labTestId: productisableId
                          });
                        }
                      })
                    );
                  }}
                  sx={{ width: "250px" }}
                  renderInput={(params) => (
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    <TextField {...params} margin="dense" variant="outlined" />
                  )}
                />
              )
            },
            {
              key: "grossTotalPrice",
              label: tl("price"),
              sortable: true,
              cellRenderer: (item) => (
                <Typography component="div" variant="body2">
                  {tl("rs")}
                  {item.grossTotalPrice}
                </Typography>
              )
            },
            {
              key: "category",
              label: tl("category"),
              cellRenderer: (item) => <Typography>{item.category}</Typography>
            }
          ]}
        />
      </Box>

      <Box
        sx={{
          height: "40px",
          display: "flex",
          gap: "10px",
          alignItems: "center",
          justifyContent: "flex-end"
        }}
      >
        <PageControl
          page={query.page || 0}
          pageSize={query.pageSize}
          maximumDataCount={services.total || 0}
          onSetPage={(value) => setQuery({ ...query, page: value })}
        />

        <Button
          variant="outlined"
          onClick={() => {
            if (servicesToLink.length > 0) {
              setServicesToLink([]);
              setServices((prev) => ({
                ...prev,
                results: originalServices
              }));
            } else {
              dispatch(push("/services"));
            }
          }}
          disabled={isUpdading}
          sx={{ marginLeft: "10px" }}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          onClick={handleLinkLabTest}
          disabled={isUpdading}
          sx={{ marginLeft: "10px" }}
        >
          Save Services {servicesToLink.length > 0 && `(${servicesToLink.length})`}
        </Button>
      </Box>
    </Box>
  );
}
