import React from "react";

const OccupiedBed = ({
  width = 80,
  height = 36,
  color = "#FBBF24" // tailwid-200
}: {
  [key: string]: string | number;
}): JSX.Element => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 80 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.844075 35.8765C0.813717 35.7965 0.802168 27.6935 0.818368 17.8699L0.847898 0.00872647H4.86983H8.89175L8.92111 10.2933L8.95047 20.5778H44.2862H79.6218L79.5923 28.2696L79.5628 35.9615H75.4834H71.404L71.3736 32.3028L71.3433 28.6442H40.1479H8.95246L8.9221 32.3028L8.89174 35.9615L4.89551 35.9918C1.7601 36.0156 0.887394 35.9909 0.844075 35.8765ZM11.6255 18.162C9.11627 16.6278 11.1642 11.8229 14.6074 11.1658C20.845 9.97551 30.5184 15.8828 26.0636 18.1618L25.6206 18.3884L18.8075 18.388L11.9944 18.3875L11.6255 18.162ZM47.9153 18.302L28.2188 18.2725L28.2943 16.6308C28.5368 11.3576 29.9769 7.17126 32.4413 4.57535L33.1382 3.84126L34.8044 3.9172C36.4923 3.99414 40.751 4.28436 41.6992 4.38708C63.5639 6.75562 74.1478 11.0172 75.5419 18.0139L75.6165 18.3884L71.6142 18.3599C69.4129 18.3442 58.7484 18.3182 47.9153 18.302ZM24.0027 12.0458C21.7933 10.9144 18.5823 10.1888 15.9016 10.2152L14.2931 10.231L14.0791 9.73898C12.1082 5.20653 15.9596 -0.326969 20.8472 0.0151112C26.6903 0.424071 29.4374 7.48141 25.4983 11.9642C24.9348 12.6055 25.0803 12.5976 24.0027 12.0458Z"
      fill={color}
    />
  </svg>
);
export default OccupiedBed;
