import React from "react";
import { Box, Typography, TextField, InputAdornment, Autocomplete, MenuItem } from "@mui/material";
import startCase from "lodash/startCase";
import CloseIcon from "@mui/icons-material/Close";
import { t } from "../../../components/translate";

export const vitals = [
  {
    name: "bloodPressure",
    unit: "mm/Hg",
    label: "Blood Pressure",
    dbKey: "bloodpressure",
    defaultValue: "120/80",
    type: "text"
  },
  {
    name: "glucoseLevel",
    unit: "mg/dl",
    label: "Glucose Level",
    dbKey: "glucoselevel",
    defaultValue: "",
    type: "text"
  },
  {
    name: "headCircumference",
    unit: "cm",
    label: "Head Circumference",
    dbKey: "headcircumference",
    defaultValue: "",
    type: "text"
  },
  { name: "height", unit: "cm", label: "Height", dbKey: "height", defaultValue: "", type: "text" },
  {
    name: "respiration",
    unit: "pm",
    label: "Respiration",
    dbKey: "respiration",
    defaultValue: "",
    type: "text"
  },
  { name: "pulse", unit: "bpm", label: "Pulse", dbKey: "pulse", defaultValue: "72", type: "text" },
  {
    name: "saturation",
    unit: "%",
    label: "Saturation",
    dbKey: "saturation",
    defaultValue: "",
    type: "text"
  },
  {
    name: "temperature",
    unit: "f",
    label: "Temperature",
    dbKey: "temperature",
    defaultValue: "98",
    type: "text"
  },
  { name: "weight", unit: "kg", label: "Weight", dbKey: "weight", defaultValue: "", type: "text" },
  {
    name: "SPO2",
    unit: "%",
    label: "SPO2",
    dbKey: "spo2",
    defaultValue: "50%",
    dropdownValues: [],
    reading: "",
    type: "text"
  },
  {
    name: "BMI",
    unit: "kg/m2",
    label: "BMI",
    dbKey: "bmi",
    defaultValue: "",
    dropdownValues: [],
    reading: "",
    type: "text"
  },
  {
    name: "Bodyfat",
    unit: "%",
    label: "Body Fat",
    dbKey: "bodyfat",
    defaultValue: "20%",
    dropdownValues: [],
    reading: "",
    type: "text"
  },
  {
    name: "Clubbing",
    unit: "",
    label: "Clubbing",
    dbKey: "clubbing",
    defaultValue: "Absent",
    type: "select"
  },
  {
    name: "Jaundice",
    unit: "",
    label: "Jaundice",
    dbKey: "jaundice",
    defaultValue: "Absent",
    type: "select"
  },
  {
    name: "Oedema",
    unit: "",
    label: "Oedema",
    dbKey: "oedema",
    defaultValue: "Absent",
    type: "select"
  },
  {
    name: "Pallor",
    unit: "",
    label: "Pallor",
    dbKey: "pallor",
    defaultValue: "Absent",
    type: "select"
  },
  {
    name: "Ascites",
    unit: "",
    label: "Ascites",
    dbKey: "ascites",
    defaultValue: "Absent",
    type: "select"
  },
  {
    name: "Cyanosis",
    unit: "",
    label: "Cyanosis",
    dbKey: "cyanosis",
    defaultValue: "Absent",
    type: "select"
  },
  {
    name: "Lymph node",
    unit: "",
    label: "Lymph Node",
    dbKey: "lymphnode",
    defaultValue: "Absent",
    type: "select"
  },
  { name: "OFC", unit: "", label: "OFC", dbKey: "ofc", defaultValue: "", type: "text" },
  { name: "BSA(m2)", unit: "", label: "BSA(m2)", dbKey: "bsa", defaultValue: "", type: "text" },
  { name: "WFH", unit: "", label: "WFH", dbKey: "wfh", defaultValue: "", type: "text" },
  { name: "RR", unit: "", label: "RR", dbKey: "rr", defaultValue: "", type: "text" },
  { name: "Icterus", unit: "", label: "Icterus", dbKey: "icterus", defaultValue: "", type: "text" },
  {
    name: "Dysmpophism",
    unit: "",
    label: "Dysmpophism",
    dbKey: "dysmpophism",
    defaultValue: "",
    type: "text"
  },
  { name: "Bruit", unit: "", label: "Bruit", dbKey: "bruit", defaultValue: "", type: "text" },
  { name: "Scalp", unit: "", label: "Scalp", dbKey: "scalp", defaultValue: "", type: "text" },
  { name: "Spine", unit: "", label: "Spine", dbKey: "spine", defaultValue: "", type: "text" },
  { name: "others", unit: "", label: "Others", dbKey: "others", defaultValue: "", type: "text" }
];

interface VitalInterface {
  name: string;
  unit: string;
  label: string;
  dbKey: string;
  defaultValue: string;
  type: string;
  reading?: string;
  extraInfo?: string;
}

interface VitalRowInterface {
  vital: VitalInterface;
  onChange: (state) => void;
  onRemove: () => void;
}

const VitalRow: React.FC<VitalRowInterface> = ({ vital, onChange, onRemove }): JSX.Element => {
  const [state, setState] = React.useState(vital);

  React.useEffect(() => {
    setState(vital);
  }, [vital]);

  return (
    <Box style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
      <Box width="30%">
        <Autocomplete
          value={state}
          options={vitals}
          getOptionLabel={(option) => startCase(option.name)}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              fullWidth
              variant="outlined"
              margin="dense"
              placeholder={t("assessment.selectVital")}
            />
          )}
          onChange={(e, v) => {
            if (!v || typeof v === "string") return;
            setState({
              ...state,
              name: v.name,
              unit: v.unit,
              reading: v.defaultValue,
              type: v.type
            });
          }}
          onBlur={() => onChange(state)}
        />
      </Box>
      <Box width="30%">
        {state.type === "text" ? (
          <TextField
            data-testmation="vitalsReading"
            variant="outlined"
            margin="dense"
            placeholder={t("assessment.typeReading")}
            fullWidth
            value={state.reading}
            onChange={(e) => {
              const val = e.target.value;
              // Condition to add string in the reading
              const vitalsReading = vitals.map((a) => a.name).slice(0, 8);
              if (vitalsReading.includes(state.name))
                setState({ ...state, reading: val.replace(/[^\d|\\/|\\.]/g, "") });
              else {
                setState({ ...state, reading: val });
              }
            }}
            onBlur={() => onChange(state)}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <Typography>{state.unit}</Typography>
                  </InputAdornment>
                )
              }
            }}
          />
        ) : (
          <TextField
            id="standard-select"
            select
            variant="outlined"
            value={state.reading}
            fullWidth
            margin="dense"
            onChange={(e) => {
              setState({ ...state, reading: e.target.value });
            }}
            onBlur={() => onChange(state)}
          >
            {["Absent", "Present"].map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        )}
      </Box>
      <Box width="30%">
        <TextField
          data-testmation="vitals.extraInfo"
          variant="outlined"
          margin="dense"
          placeholder={t("assessment.typeExtraInfo")}
          fullWidth
          value={state.extraInfo}
          onChange={(e) => {
            setState({ ...state, extraInfo: e.target.value });
          }}
          onBlur={() => onChange(state)}
        />
      </Box>
      <Box
        style={{
          display: "flex",
          alignItems: "center"
        }}
      >
        <CloseIcon
          data-testmation="vitals.removeButton"
          style={{ cursor: "pointer" }}
          onClick={() => onRemove()}
        />
      </Box>
    </Box>
  );
};

export default VitalRow;
