import * as React from "react";
import {
  Fade,
  Popper,
  Paper,
  Tooltip,
  IconButton,
  Typography,
  TextField,
  Button
} from "@mui/material";
import { useDispatch } from "react-redux";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import { notificationAdd } from "../../actions/notification";
import { postFedback } from "../../api/feedback";

export default function FeedbackPopper(): React.ReactNode {
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [feedback, setFeedback] = React.useState<string>("");
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleSendFeedback = async () => {
    if (!feedback) {
      return;
    }

    try {
      setLoading(true);
      await postFedback(feedback);
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "success",
          message: "Feedback submitted successfully",
          autoTimeout: true
        })
      );
    } catch (error) {
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: error?.message || error?.data?.message || "An error occurred",
          autoTimeout: true
        })
      );
    } finally {
      setLoading(false);
      setOpen(false);
      setAnchorEl(null);
      setFeedback("");
    }
  };

  return (
    <>
      <Popper open={open} anchorEl={anchorEl} placement="bottom-end" transition>
        {({ TransitionProps }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <Fade {...TransitionProps} timeout={350}>
            <Paper sx={{ p: 2, width: 300, boxShadow: 3, marginTop: 1 }}>
              <Typography variant="body2" sx={{ mb: 1 }}>
                Your Feedback
              </Typography>
              <TextField
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                placeholder="Please enter your feedback here..."
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
              />

              <Button
                fullWidth
                variant="contained"
                sx={{ mt: 2, bgcolor: "primary.main" }}
                onClick={handleSendFeedback}
                loading={loading}
              >
                Submit Feedback
              </Button>
            </Paper>
          </Fade>
        )}
      </Popper>

      <Tooltip title="Feedback">
        <IconButton
          onClick={(e) => {
            setAnchorEl(e.currentTarget);
            setOpen((prev) => !prev);
          }}
        >
          <ThumbUpIcon color="primary" />
        </IconButton>
      </Tooltip>
    </>
  );
}
