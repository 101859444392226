import React from "react";
import moment, { Moment } from "moment";
import { TimePicker } from "@mui/x-date-pickers";
import { Box, TextField, ButtonGroup, Button, Typography } from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import { tl, t } from "../../components/translate";
import styles from "./Assessment.module.css";
import DebouncedTextField from "../../components/DebouncedTextField";
import useAssessmentLabel from "../../hooks/useAssessmentLabel";
import { roundMinutes } from "../Calendar/Booking/BookingCreateEdit";

interface FollowUpInterface {
  assessmentFollowUp: Record<string, unknown>;
  onChange: (updatedValues) => void;
}

const FollowUp: React.FC<FollowUpInterface> = (props) => {
  const { assessmentFollowUp, onChange } = props;
  const assessmentLabels = useAssessmentLabel();
  return (
    <Box sx={{ display: "flex", alignItems: "flex-start", height: "auto" }}>
      <Box className={styles.assessmentLabel} sx={{ pt: "4px" }}>
        <Typography component="span" sx={{ fontWeight: "600" }}>
          {assessmentLabels.followup}
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        <Box sx={{ display: "flex", gap: 1, flexDirection: "column" }}>
          <Box className={styles.buttonGroups}>
            <Box sx={{ marginRight: "16px", display: "flex", gap: 0.5 }}>
              <Button
                data-testmation="followUpMinusButton"
                sx={{ minWidth: "10px" }}
                size="small"
                variant="outlined"
                disabled={assessmentFollowUp.days <= 0}
                onClick={() => {
                  onChange({ days: Number(assessmentFollowUp.days) - 1 });
                }}
              >
                <Remove />
              </Button>
              <TextField
                data-testmation="followUpNumberTextField"
                type="number"
                size="small"
                variant="outlined"
                color="primary"
                value={assessmentFollowUp.days}
                onChange={(e) => {
                  onChange({ days: e.target.value });
                }}
                slotProps={{
                  htmlInput: { style: { textAlign: "center", width: "20px" } }
                }}
              />
              <Button
                data-testmation="followUpPlusButton"
                sx={{ minWidth: "10px" }}
                size="small"
                variant="outlined"
                onClick={() => {
                  onChange({ days: Number(assessmentFollowUp.days) + 1 });
                }}
              >
                <Add />
              </Button>
            </Box>

            <ButtonGroup size="small" aria-label="small outlined button group">
              <Button
                data-testmation="followUpFormatDaysButton"
                size="small"
                variant="outlined"
                color={assessmentFollowUp.format === "days" ? "primary" : "inherit"}
                onClick={() => {
                  onChange({ format: "days" });
                }}
              >
                <Typography>
                  <Box component="span" fontWeight="600" fontSize="12px">
                    {tl("assessment.days")}
                  </Box>
                </Typography>
              </Button>
              <Button
                data-testmation="followUpFormatWeeksButton"
                size="small"
                variant="outlined"
                color={assessmentFollowUp.format === "weeks" ? "primary" : "inherit"}
                onClick={() => {
                  onChange({ format: "weeks" });
                }}
              >
                <Typography>
                  <Box component="span" fontWeight="600" fontSize="12px">
                    {tl("assessment.week")}
                  </Box>
                </Typography>
              </Button>
              <Button
                data-testmation="followUpFormatWeeksButton"
                size="small"
                variant="outlined"
                color={assessmentFollowUp.format === "months" ? "primary" : "inherit"}
                onClick={() => {
                  onChange({ format: "months" });
                }}
              >
                <Typography>
                  <Box component="span" fontWeight="600" fontSize="12px">
                    {tl("assessment.month")}
                  </Box>
                </Typography>
              </Button>

              <Button
                data-testmation="followUpFormatYearsButton"
                size="small"
                variant="outlined"
                color={assessmentFollowUp.format === "years" ? "primary" : "inherit"}
                onClick={() => {
                  onChange({ format: "years" });
                }}
              >
                <Typography>
                  <Box component="span" fontWeight="600" fontSize="12px">
                    {tl("assessment.years")}
                  </Box>
                </Typography>
              </Button>
            </ButtonGroup>

            <Box sx={{ marginLeft: "16px", display: "flex", gap: 1, alignItems: "center" }}>
              <TimePicker
                skipDisabled
                timeSteps={{ minutes: 10 }}
                label="Start Time"
                disabled={assessmentFollowUp.days <= 0}
                value={moment(assessmentFollowUp.startDate || new Date())}
                onChange={(newTime: Moment | null) => {
                  if (!newTime) return;
                  const hour = newTime.hour();
                  const minute = newTime.minute();
                  const roundedMinute = roundMinutes(minute, 5);

                  const startDate = moment(assessmentFollowUp.startDate)
                    .set("hour", hour)
                    .set("minute", roundedMinute || 0)
                    .toDate();

                  const endDate = moment(startDate).add(15, "minutes").toDate();

                  onChange({
                    startDate,
                    endDate
                  });
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    size: "small",
                    sx: {
                      width: "120px",
                      "& .MuiInputBase-root": {
                        fontSize: "12px"
                      }
                    }
                  }
                }}
              />

              <TimePicker
                skipDisabled
                timeSteps={{ minutes: 10 }}
                label="End Time"
                disabled={assessmentFollowUp.days <= 0}
                value={moment(assessmentFollowUp.endDate || new Date())}
                onChange={(newTime: Moment | null) => {
                  if (!newTime) return;

                  if (newTime.isBefore(assessmentFollowUp.startDate)) {
                    const endDate = moment(assessmentFollowUp.startDate)
                      .add(15, "minutes")
                      .toDate();
                    onChange({ endDate });
                    return;
                  }

                  const hour = newTime.hour();
                  const minute = newTime.minute();
                  const roundedMinute = roundMinutes(minute, 5);

                  const endDate = moment(assessmentFollowUp.endDate)
                    .set("hour", hour)
                    .set("minute", roundedMinute || 0)
                    .toDate();

                  onChange({ endDate });
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    size: "small",
                    sx: {
                      width: "120px",
                      "& .MuiInputBase-root": {
                        fontSize: "12px"
                      }
                    }
                  }
                }}
              />
            </Box>
          </Box>
          <DebouncedTextField
            data-testmation="followUpRemarksTextField"
            variant="outlined"
            margin="dense"
            fullWidth
            placeholder={t("assessment.followUpRemarks")}
            slotProps={{
              inputLabel: { shrink: true }
            }}
            value={assessmentFollowUp.note}
            onChange={(e) => onChange("note", e.target.value)}
            debounceAt={400}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default FollowUp;
