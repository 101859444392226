import * as React from "react";
import { FilesInput } from "../../../components/FilesInput/FilesInput";
import { labTestsAttachmentsUrl } from "../../../api/labTest";
import { getToken } from "../../../auth/authentication";
import { tl } from "../../../components/translate";

interface Props {
  labEntryId: number;
  onFilesChange: (v) => void;
  readOnly: boolean;
  requireAuthentication: boolean;
}

const LabEntryAttachment = ({
  labEntryId,
  onFilesChange,
  readOnly = false,
  requireAuthentication
}: Props): JSX.Element => (
  <FilesInput
    endpoint={labTestsAttachmentsUrl(labEntryId)}
    deleteEndpoint={labTestsAttachmentsUrl(labEntryId)}
    authToken={requireAuthentication ? getToken() : undefined}
    attachmentDisabled={readOnly}
    label={tl("lab.attachments")}
    chooseFileLabel={tl("lab.addAttachments")}
    onfilesChange={(value) => {
      const uuids = value.map((item) => item.uuid);
      onFilesChange(uuids);
    }}
  />
);

export default LabEntryAttachment;
