import React from "react";
import { Box, Typography, Checkbox, TextField, Button, Autocomplete } from "@mui/material";
import moment from "moment";
import { useSelector } from "react-redux";
import { convertADtoBS } from "../../../../components/Calendar/functions/calendarFunctions";
import OkhatiDialog from "../../../../components/Dialog/Dialog";
import {
  useFiscalPeriod,
  useMasterFiscalPeriods,
  usePatchFiscalPeriod,
  usePostFiscalPeriod
} from "../../../../hooks/query/useFiscalPeriods";
import { tl } from "../../../../components/translate";
import { ResourceCentre } from "../../../../interfaces/ResourceCentreInterface";
import { RootState } from "../../../../store";
import { FiscalPeriodProps } from "../../../../interfaces/Accounts";

const FiscalPeriods = ({ resourceCentre }: { resourceCentre: ResourceCentre }): JSX.Element => {
  const permissionGroup = useSelector(
    (state: RootState) => state.userContext?.userCreds?.userGroups[0]
  );
  const disableAddFiscalPeriod = !["superAdmin", "resourceCentreAdmin"].includes(permissionGroup);

  const [fiscalPeriod, setFiscalPeriod] = React.useState<Partial<FiscalPeriodProps>>({
    localCalendar: resourceCentre.localCalendar !== null ? resourceCentre.localCalendar : true,
    from: "",
    to: "",
    resourceCentreId: resourceCentre.id,
    allowPeriod: false,
    fiscalStartMonth: resourceCentre.fiscalStartMonth || 0
  });
  const { data = [] } = useFiscalPeriod(resourceCentre.id);

  const { mutate: postMutate } = usePostFiscalPeriod();
  const { mutate: patchMutate } = usePatchFiscalPeriod();
  const [newFiscalYear, setNewFiscalYear] = React.useState<FiscalPeriodProps>(
    {} as FiscalPeriodProps
  );

  const [openDialog, setOpenDialog] = React.useState<boolean>(false);
  const getStartEndDate = (date: string | Date | moment.Moment, calendar: boolean): string => {
    if (!date) return "";
    if (calendar) {
      const { bsDate, bsMonth, bsYear } = convertADtoBS(moment(date));
      return `${bsYear}-${bsMonth}-${bsDate}`;
    }
    return moment(date).format("YYYY-MM-DD");
  };

  const { data: masterFiscalPeriods = [] } = useMasterFiscalPeriods();

  return (
    <Box display="flex" flexDirection="column">
      <Box mt="20px">
        <Typography mb="10px" fontSize="bold">
          {tl("account.accFiscalPeriod")}
        </Typography>
        {data
          ?.sort((a, b) => a.id - b.id)
          .map((period) => (
            <Box display="flex" key={period.id} mb={2}>
              <TextField
                sx={{ width: "200px", marginRight: "10px" }}
                variant="outlined"
                label={tl("accounts.fiscalYear")}
                value={
                  resourceCentre.localCalendar
                    ? convertADtoBS(moment(period.from)).bsYear
                    : moment(period.from).year()
                }
                disabled
              />
              <TextField
                sx={{ width: "200px", marginRight: "10px" }}
                variant="outlined"
                label={tl(`accounts.from`)}
                value={
                  resourceCentre.localCalendar
                    ? convertADtoBS(moment(period.from)).formatted4
                    : moment(period.from).format("YYYY-MM-DD")
                }
                disabled
              />
              <TextField
                sx={{ width: "200px", marginRight: "10px" }}
                variant="outlined"
                label={tl(`accounts.to`)}
                value={
                  resourceCentre.localCalendar
                    ? convertADtoBS(moment(period.to)).formatted4
                    : moment(period.to).format("YYYY-MM-DD")
                }
                disabled
              />
              <Checkbox
                checked={period.allowPeriod}
                onChange={({ target }) => {
                  patchMutate({
                    allowPeriod: target.checked,
                    id: period.id,
                    resourceCentreId: resourceCentre.id
                  });
                }}
              />
            </Box>
          ))}

        <Box mb={2} display="flex" alignItems="center">
          <Autocomplete
            disabled={disableAddFiscalPeriod}
            value={newFiscalYear?.from ? newFiscalYear : null}
            options={
              masterFiscalPeriods.filter(
                (masterPeriod) =>
                  !data.some((period) => period.from === masterPeriod.from) &&
                  masterPeriod.to >= moment().tz("Asia/Kathmandu").toISOString()
              ) as FiscalPeriodProps[]
            }
            getOptionLabel={(option) =>
              resourceCentre.localCalendar
                ? `${convertADtoBS(moment(option.from)).bsYear}`
                : `${moment(option.from).year()}`
            }
            renderOption={(renderProps, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...renderProps} key={option.id}>
                {resourceCentre.localCalendar
                  ? `${convertADtoBS(moment(option.from)).bsYear}`
                  : `${moment(option.from).year()}`}
              </li>
            )}
            data-testmation="yearSelection"
            onChange={(e, v: FiscalPeriodProps) => {
              if (v) {
                setNewFiscalYear(v as FiscalPeriodProps);
                setFiscalPeriod({
                  ...fiscalPeriod,
                  from: v.from,
                  to: v.to
                });
              } else {
                setNewFiscalYear({} as FiscalPeriodProps);
              }
            }}
            renderInput={(params) => (
              <TextField
                data-testmation="yearSelection"
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                slotProps={{
                  inputLabel: { shrink: true }
                }}
                sx={{ width: "200px", marginRight: "10px" }}
                margin="dense"
                fullWidth
                variant="outlined"
                label="Year"
              />
            )}
          />
          <TextField
            sx={{ width: "200px", marginRight: "10px" }}
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            label={tl("accounts.from")}
            value={getStartEndDate(fiscalPeriod.from, resourceCentre.localCalendar)}
            disabled
          />
          <TextField
            sx={{ width: "200px", marginRight: "10px" }}
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            label={tl("accounts.to")}
            value={getStartEndDate(fiscalPeriod.to, resourceCentre.localCalendar)}
            disabled
          />
          <Checkbox
            checked={fiscalPeriod.allowPeriod}
            onChange={({ target }) =>
              setFiscalPeriod({ ...fiscalPeriod, allowPeriod: target.checked })
            }
          />
          <Button
            variant="contained"
            disabled={!Object.keys(newFiscalYear).length || disableAddFiscalPeriod}
            onClick={() => {
              if (newFiscalYear) {
                setOpenDialog(true);
              }
            }}
          >
            {tl("save")}
          </Button>
        </Box>
        <OkhatiDialog
          readMode={false}
          title="Confirmation"
          description="You have set your Fiscal period. Once confirmed, cannot be edited, do you want to save?"
          open={openDialog}
          next={() => {
            postMutate(fiscalPeriod);
            setOpenDialog(false);
            setFiscalPeriod({ ...fiscalPeriod, from: "", to: "", allowPeriod: false });
            setNewFiscalYear({} as FiscalPeriodProps);
          }}
          cancel={() => {
            setOpenDialog(false);
          }}
        />
      </Box>
    </Box>
  );
};

export default FiscalPeriods;
