import * as React from "react";
import { connect, useSelector } from "react-redux";
import {
  Box,
  Typography,
  Checkbox,
  TextField,
  Button,
  Card,
  Badge,
  Avatar,
  FormControlLabel,
  FormGroup,
  Grid2 as Grid
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import CancelIcon from "@mui/icons-material/DeleteOutline";
import AssignmentIcon from "@mui/icons-material/Assignment";
import UndoIcon from "@mui/icons-material/Undo";
import ScheduleIcon from "@mui/icons-material/Schedule";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import RadioIcon from "@mui/icons-material/Radio";
import DescriptionIcon from "@mui/icons-material/Description";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import EventNoteIcon from "@mui/icons-material/EventNote";
import ClearIcon from "@mui/icons-material/Clear";
import moment, { Moment } from "moment";
import NotesIcon from "@mui/icons-material/Notes";
import EmailIcon from "@mui/icons-material/Email";
import AddBoxIcon from "@mui/icons-material/AddBox";
import produce from "immer";
import { Booking } from "../../../interfaces/BookingInterfaces";
import { tl, t } from "../../../components/translate";
import ActionBar from "../../../components/ActionBar/ActionBar";
import BookingStatus from "./BookingStatus";
import FollowUp from "./FollowUp";
import IconWithField from "../../../components/IconWithField";
import CreateEditHeader from "./CreateEdtiHeader";
import styles from "./styles.module.css";
import * as bookingActions from "../../../actions/booking";
import { showDialog, navigateRemoveModal } from "../../../actions/navigation";
import { isEmail, validate } from "../../../helpers/validators";
import StatefulButton from "../../../components/StatefulButton/StatefulButton";
import ClientSearchAndCreate from "./ClientSearchAndCreate";
import { getSPSpecificServices } from "../../../reducers/services";
import { serviceProvidersSortedSelector } from "../../../reducers/serviceProvider";
import CalendarDropdown from "../../../components/CalendarDropdown/CalendarDropdown";
import { clientFullNameSelector } from "../../../reducers/client";
import ClientInfo from "../../Client/ClientInfo/ClientInfo";
import ClientCreateEdit from "../../Client/ClientCreateEdit";
import ReferrerCreateSelect from "../../Referrers/ReferrerCreateSelect";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { ProductInterface } from "../../../interfaces/ProductInterface";
import { IThunkDispatch, RootState } from "../../../store";
import mixpanelAnalytics from "../../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../../mixpanel-analytics/event";
import DepartmentSelect from "../../../components/DepartmentSelect";
import useDepartmentServiceProviders from "../../../hooks/useDepartmentServiceProviders";
import KnownUsFromSelect from "../../../components/KnownUsFromSelect/KnownUsFromSelect";
import ServicesSelectWithSpAssign from "./ServicesSelectWithSpAssign";

export const roundMinutes = (minutes: number, duration: number): number =>
  Math.round(minutes / duration) * duration;

export const getDefaultReminders = (
  targetDate: moment.Moment
): Array<{
  on: Date;
}> => {
  const remindOn = targetDate.subtract(3, "day").toDate();
  return [{ on: remindOn }];
};

interface BookingCreateEditProps {
  booking: Booking;
  onClose: () => void;
  onDelete: (bookingId, onClose) => void;
  onChange: (eventData) => void;
  onShowBookingHistory: () => void;
  onSave: (booking: Partial<Booking>) => void;
  resourceCentreId: number;
  serviceProvider: {
    firstName: string;
    lastName: string;
    speciality: string;
    title: string;
  };
  resourceCentre: ResourceCentre;
  services: Array<ProductInterface>;
  onTabChange: (tab) => void;
  events: [];
  serviceProviders: Array<ServiceProvider>;
}

const BookingCreateEdit: React.FC<BookingCreateEditProps> = (props) => {
  const {
    booking,
    onClose,
    onDelete,
    onShowBookingHistory,
    onSave,
    onChange,
    onTabChange,
    serviceProvider,
    resourceCentre,
    services,
    events,
    serviceProviders
  } = props;
  const requireKnownUsFrom = resourceCentre?.settings?.formSettings?.requireKnownUsFrom;
  const [bookingState, setBookingState] = React.useState<Partial<Booking>>(booking);
  const [editMode, setEditMode] = React.useState(false);
  const [clientEmail, setClientEmail] = React.useState("");
  const [knownUsFromValue, setKnownUsFromValue] = React.useState(
    bookingState?.client?.knownUsFrom || ""
  );
  const [bookingServiceOptions, setBookingServiceOptions] = React.useState([]);
  const [error, setError] = React.useState<{
    emailError: { isValid: boolean; message: string } | null;
    knownUsFromError: { isValid: boolean; message: string } | null;
  }>({
    emailError: null,
    knownUsFromError: {
      isValid: !requireKnownUsFrom || (requireKnownUsFrom && !!knownUsFromValue?.length),
      message: requireKnownUsFrom && !!knownUsFromValue?.length ? "Known us from is required" : ""
    }
  });
  const [currentClient, setCurrentClient] = React.useState({
    id: null,
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    sendEmail: false,
    knownUsFrom: "",
    internalNotes: ""
  });
  const spSpecificServices = React.useMemo(
    () => getSPSpecificServices(bookingState.serviceProviderId, services),
    [bookingState.serviceProviderId, services]
  );
  const [clientEditMode, setClientEditMode] = React.useState(false);
  const [selectedSpDepartmentId, setSelectedSpDepartmentId] = React.useState(null);

  React.useEffect(() => {
    if (booking) {
      setBookingState({
        id: booking.id ? booking.id : undefined,
        start: booking.start,
        end: booking.end,
        clientId: booking.clientId ? booking.clientId : undefined,
        departmentId: booking.departmentId || undefined,
        clientName: booking.clientName,
        clientPhoneNo: booking.clientPhoneNo,
        clientEmail: booking.clientEmail,
        clientGender: booking.clientGender,
        clientAddress: booking.clientAddress,
        clientInternalNotes: booking.client?.internalNotes || "",
        clientKnownFrom: booking.client?.knownUsFrom || "",
        services: booking.services,
        followUp: booking.followUp,
        remarks: booking.remarks,
        reasonOfVisit: booking.reasonOfVisit,
        reminders: booking.reminders || [],
        resourceCentreId: booking.resourceCentreId,
        serviceProviderId: booking.serviceProviderId,
        resourceId: booking.resourceId,
        status: booking.status,
        bookingHistory: booking.bookingHistory,
        paymentInfo: booking.paymentInfo,
        throughPublic: booking.throughPublic,
        referredBy: booking.referredBy,
        knownFrom: booking.knownFrom,
        bookableResource: booking.bookableResource,
        sendToSp: booking.sendToSp,
        sendToClient: booking.sendToClient
      });

      setCurrentClient(
        bookingState.clientId
          ? {
              id: bookingState.clientId,
              firstName: bookingState.clientName,
              lastName: "",
              phone: bookingState.clientPhoneNo,
              email: bookingState.clientEmail,
              sendEmail: true,
              knownUsFrom: bookingState.client?.knownUsFrom || "",
              internalNotes: bookingState.client?.internalNotes || ""
            }
          : {
              id: undefined,
              firstName: "",
              lastName: "",
              phone: "",
              email: "",
              sendEmail: false,
              knownUsFrom: "",
              internalNotes: ""
            }
      );
      if (booking.id) {
        setEditMode(true);
      } else {
        setEditMode(false);
      }
      if (bookingState.client?.knownUsFrom) setKnownUsFromValue(bookingState.client.knownUsFrom);
    }
  }, [booking]);

  React.useEffect(() => {
    if (!editMode) {
      setBookingState({
        ...bookingState,
        clientId: currentClient.id,
        clientName: `${currentClient.firstName} ${currentClient.lastName}`,
        clientPhoneNo: currentClient.phone,
        clientKnownFrom: currentClient.knownUsFrom,
        clientInternalNotes: currentClient.internalNotes
      });
      // When client is in create mode there is no current client
      // Only require known us from during client creation
      if (requireKnownUsFrom && !currentClient.knownUsFrom && !currentClient) {
        setError((prevState) => ({
          ...prevState,
          knownUsFromError: { isValid: false, message: "Known us from is required" }
        }));
      } else {
        setKnownUsFromValue(currentClient.knownUsFrom || "");
        setError((prevState) => ({ ...prevState, knownUsFromError: null }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentClient]);

  React.useEffect(() => {
    if (booking.client) {
      setBookingState({
        ...bookingState,
        clientName: `${booking?.client?.firstName} ${booking?.client?.lastName}`,
        clientPhoneNo: booking?.client?.phone,
        clientInternalNotes: booking?.client?.internalNotes,
        clientKnownFrom: booking?.client?.knownUsFrom
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [booking.client, currentClient]);

  React.useEffect(() => {
    if (bookingState.services && bookingState.services.length > 0) {
      const fullServices = [];
      bookingState.services.forEach((bS) => {
        spSpecificServices.forEach((service) => {
          if (service.id === bS) {
            fullServices.push(service);
          }
        });
      });
      setBookingServiceOptions(fullServices);
    } else {
      setBookingServiceOptions([]);
    }
  }, [bookingState.services, spSpecificServices]);

  let actions = [];
  if (booking.id) {
    actions = actions.concat([
      {
        label: tl("booking.activitiesHistory"),
        action: () => onShowBookingHistory(),
        Icon: AssignmentIcon,
        dataTestmation: "activitiesHistory"
      },
      {
        label: tl("booking.cancelBooking"),
        action: () => {
          mixpanelAnalytics.track(EVENT.BOOKING_STATUS_CANCELED, { rcId: resourceCentre.id });
          onDelete(bookingState.id, onClose);
        },
        Icon: CancelIcon,
        dataTestmation: "cancelBooking"
      }
    ]);
  }

  const [isLoading, setIsLoading] = React.useState(false);

  const isValidData = () => {
    let isValid = true;
    if (error) {
      Object.keys(error).forEach((key) => {
        if (error[key]) {
          if (!error[key].isValid) {
            isValid = false;
          }
        }
      });
    }
    if (
      !(bookingState.clientName && (bookingState.clientId ? true : bookingState.clientGender)) ||
      (resourceCentre?.settings?.formSettings?.requirePhoneNumber &&
        !(currentClient.phone || bookingState.clientPhoneNo || bookingState.client?.phone)) ||
      (resourceCentre?.settings?.formSettings?.requireEmail &&
        !(currentClient.email || bookingState.clientEmail || bookingState.client?.email)) ||
      (resourceCentre?.settings?.formSettings?.requireReferrer && !bookingState.referrerId)
    ) {
      isValid = false;
    }
    return isValid;
  };

  const formSettingsEmail = resourceCentre?.settings?.formSettings?.requireEmail;

  const isSmsSubscribed = resourceCentre.subscriptions.features.sms.subscribed;

  const askForEmail = () => {
    let shouldAsk = false;
    if (!editMode && currentClient.id) {
      if (!currentClient.email) {
        shouldAsk = true;
      } else if (currentClient.email && !currentClient.sendEmail) {
        shouldAsk = true;
      }
    }
    return shouldAsk;
  };

  const getEmailErrorMsg = () => {
    if (formSettingsEmail && !clientEmail.length) return "Email is required";
    if (error?.emailError && clientEmail.length) {
      return error?.emailError?.messages[0];
    }
    if (!currentClient.sendEmail && formSettingsEmail) {
      return tl("booking.bouncedEmail");
    }
    return "";
  };

  const getScheduleDuration = (evs) => {
    const scheduleDuration = evs.find((ev) => {
      if (ev.type === "schedule") {
        return (
          moment(ev.start).isSameOrBefore(bookingState.start) &&
          moment(ev.end).isAfter(bookingState.start)
        );
      }
      return false;
    });
    if (scheduleDuration) {
      return scheduleDuration.duration;
    }
    return null;
  };

  const duration = getScheduleDuration(events);

  const endTimeReconciliation = () => {
    const difference = moment(bookingState.end).diff(bookingState.start, "minutes");
    if (duration) {
      if (difference < duration) {
        setBookingState({
          ...bookingState,
          end: new Date(moment(bookingState.start).add(duration, "minutes").toISOString())
        });
      }
    } else if (difference < 10) {
      setBookingState({
        ...bookingState,
        end: new Date(moment(bookingState.start).add(10, "minutes").toISOString())
      });
    }
  };

  React.useEffect(() => {
    const selectedSp = serviceProviders.find((sp) => sp.id === bookingState.serviceProviderId);
    if (selectedSp) {
      setSelectedSpDepartmentId(selectedSp.departmentId);
    }
  }, [bookingState.serviceProviderId, serviceProviders]);

  const departmentId =
    useSelector((state: RootState) => state.userContext.user.departmentId) || null;

  React.useEffect(() => {
    if (!editMode && !booking.id && bookingState.serviceProviderId) {
      if (departmentId) {
        setBookingState({
          ...bookingState,
          departmentId
        });
      } else {
        const selectedServiceProvider = serviceProviders.find(
          (sp) => sp.id === bookingState.serviceProviderId
        );
        setBookingState({
          ...bookingState,
          departmentId: selectedServiceProvider ? selectedServiceProvider.departmentId : null
        });
      }
    }
  }, [bookingState.serviceProviderId, editMode, serviceProviders, booking.id]);

  const [showClientInfoPanel, setShowClientInfoPanel] = React.useState(false);
  const [currentSelectedClientId, setCurrentSelectedClientId] = React.useState(null);

  const serviceProviderList = useDepartmentServiceProviders();

  return (
    <Box sx={{ p: 3 }} className={styles.bookingCreateEdit}>
      <Box className={styles.bookingCreateEditBodyContainer}>
        <Box sx={{ pb: 3, display: "flex" }}>
          {booking.id && <BookingStatus booking={booking} hideLabel />}

          {booking.id && (
            <Typography component="span" style={{ marginLeft: "16px", fontSize: "0.875rem" }}>
              <Box component="span" fontWeight="700">
                ID
              </Box>{" "}
              {booking.id}
            </Typography>
          )}

          {!booking.id && (
            <CreateEditHeader
              activeTab="booking"
              onChange={(e, tab) => {
                if (tab === "schedule") {
                  onTabChange("schedule");
                }
              }}
            />
          )}

          <Box flexGrow={1} textAlign="right">
            <ActionBar actions={actions} onClose={onClose} />
          </Box>
        </Box>

        {bookingState.bookableResource && (
          <Box sx={{ mb: "16px" }}>
            <IconWithField LabelIcon={<AddBoxIcon />}>
              <Typography style={{ fontSize: "14px" }}>
                {bookingState?.bookableResource?.name}
              </Typography>
            </IconWithField>
          </Box>
        )}

        <Box
          sx={{
            height: "385px",
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            gap: "10px"
          }}
        >
          {/* follow up */}
          <Box sx={{ display: "flex", alignItems: "flex-start" }}>
            <Box
              sx={{
                height: "20px",
                width: "20px",
                marginRight: "20px",
                alignSelf: "center"
              }}
            >
              <EventNoteIcon />
            </Box>

            <Box sx={{ flexGrow: 1 }}>
              <FollowUp
                value={Boolean(bookingState.followUp)}
                onChange={(followUp) => {
                  setBookingState({ ...bookingState, followUp });
                }}
              />
            </Box>
          </Box>

          {/* service provider and department select  */}
          <Box sx={{ height: "30px", display: "flex", alignItems: "center" }}>
            <Box sx={{ marginRight: "10px" }}>
              <Avatar
                style={{ height: "30px", width: "30px" }}
                alt={serviceProvider && serviceProvider.firstName}
                src="https://picsum.photos/100/100/?random"
              />
            </Box>

            <Box sx={{ width: "100%" }}>
              <Autocomplete
                fullWidth
                disableClearable
                options={serviceProviderList}
                getOptionLabel={(option) =>
                  option.title
                    ? `${
                        option.title.charAt(0).toUpperCase() + option.title.slice(1)
                      } ${option.firstName.toUpperCase()} ${option.lastName.toUpperCase()}`
                    : ` ${option.firstName.toUpperCase()} ${option.lastName.toUpperCase()}`
                }
                value={
                  serviceProviderList.find((sp) => sp.id === bookingState.serviceProviderId) ||
                  undefined
                }
                onChange={(e, v) => {
                  if (typeof v === "string") return;
                  setBookingState({ ...bookingState, serviceProvider: v, serviceProviderId: v.id });
                }}
                popupIcon={<ExpandMore style={{ color: "black" }} />}
                renderOption={(params, option) => (
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  <li {...params} key={option.id} style={{ fontSize: "12px" }}>
                    {option.title
                      ? `${
                          option.title.charAt(0).toUpperCase() + option.title.slice(1)
                        } ${option.firstName.toUpperCase()} ${option.lastName.toUpperCase()}`
                      : ` ${option.firstName.toUpperCase()} ${option.lastName.toUpperCase()}`}
                  </li>
                )}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    placeholder="Assign service provider"
                    data-testmation="serviceProviderSelectTextfield"
                    variant="outlined"
                    slotProps={{
                      input: {
                        ...params.InputProps,
                        disableUnderline: true,
                        style: {
                          fontSize: "12px"
                        }
                      }
                    }}
                  />
                )}
              />
            </Box>

            <Box sx={{ width: "100%", marginLeft: "8px" }}>
              <DepartmentSelect
                fullWidth
                onChange={(department) => {
                  setBookingState({ ...bookingState, departmentId: department.id });
                }}
                id={departmentId && !editMode ? departmentId : bookingState.departmentId}
                disabled={Boolean(selectedSpDepartmentId || departmentId)}
                placeholderText="Select department"
                variant="outlined"
                isBookingWindow
              />
            </Box>
          </Box>

          {/* time select */}
          <Box>
            <IconWithField
              style={{
                display: "flex",
                alignItems: "center",
                minHeight: "24px"
              }}
              LabelIcon={<ScheduleIcon />}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  gap: "8px"
                }}
              >
                <CalendarDropdown
                  width="70px"
                  disable={false}
                  fullwidth
                  TextFieldProps={{ variant: "outlined" }}
                  isBookingWindow
                  TextFieldInputProps={{
                    disableUnderline: true,
                    style: {
                      fontSize: "12px"
                    }
                  }}
                  TextFieldHtmlInputProps={{ sx: { fontSize: "12px" } }}
                  minValidDate={moment()}
                  date={new Date(bookingState.start)}
                  onChange={(newDate) => {
                    const [startHour, startMinute] = moment(new Date(bookingState.start))
                      .format("HH mm")
                      .split(" ")
                      .map(Number);
                    let startDate: Moment | Date = moment(newDate).set("hour", startHour);
                    startDate = startDate.set("minute", startMinute);
                    const [endHour, endMinute] = moment(new Date(bookingState.end))
                      .format("HH mm")
                      .split(" ")
                      .map(Number);
                    let endDate: Moment | Date = moment(newDate).set("hour", endHour);
                    endDate = endDate.set("minute", endMinute);
                    setBookingState({
                      ...bookingState,
                      start: new Date(startDate.toISOString()),
                      end: new Date(endDate.toISOString()),
                      reminders: (bookingState.reminders ?? []).map(() => ({
                        on: moment(startDate.toISOString()).subtract(3, "days").toISOString()
                      }))
                    });
                    onChange({
                      ...bookingState,
                      start: new Date(startDate.toISOString()),
                      end: new Date(endDate.toISOString())
                    });
                  }}
                />
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    data-testmation="scheduleStartDate"
                    id="time"
                    type="time"
                    size="small"
                    variant="outlined"
                    value={moment(bookingState.start).format("HH:mm")}
                    slotProps={{
                      inputLabel: { shrink: true },
                      input: {
                        disableUnderline: true,
                        style: {
                          fontSize: "12px"
                        }
                      },
                      htmlInput: { step: 600, sx: { fontSize: "12px" } }
                    }}
                    onBlur={() => {
                      if (duration) {
                        endTimeReconciliation();
                      }
                    }}
                    onChange={({ target }) => {
                      const [hour, minute] = target.value.split(":").map(Number);
                      const roundedMinute = roundMinutes(minute, duration || 5);
                      let date: Moment | Date = moment(bookingState.start).set("hour", hour);
                      date = date.set("minute", roundedMinute || 0);
                      const difference = moment(bookingState.end).diff(
                        bookingState.start,
                        "minutes"
                      );
                      const endDate = new Date(
                        moment(date).add(difference, "minutes").toISOString()
                      );
                      date = new Date(moment(date).toISOString());
                      setBookingState({ ...bookingState, start: date, end: endDate });
                      onChange({ ...bookingState, start: date });
                    }}
                  />
                  <Typography style={{ marginLeft: "8px", marginRight: "8px" }}>-</Typography>
                  <TextField
                    data-testmation="scheduleEndDate"
                    id="time"
                    type="time"
                    size="small"
                    variant="outlined"
                    value={moment(bookingState.end).format("HH:mm")}
                    slotProps={{
                      input: {
                        disableUnderline: true,
                        style: {
                          fontSize: "12px"
                        }
                      },
                      htmlInput: {
                        step: 600,
                        min: `${moment(bookingState.start).format("HH:mm")}`,
                        sx: { fontSize: "12px" }
                      }
                    }}
                    onBlur={() => {
                      endTimeReconciliation();
                    }}
                    onChange={({ target }) => {
                      const [hour, minute] = target.value.split(":").map(Number);
                      const roundedMinute = roundMinutes(minute, duration || 5);
                      let date: Moment | Date = moment(bookingState.end).set("hour", hour);
                      date = date.set("minute", roundedMinute || 0);
                      date = new Date(moment(date).toISOString());
                      setBookingState({ ...bookingState, end: date });
                      onChange({ ...bookingState, start: date });
                    }}
                  />
                </Box>
              </Box>
            </IconWithField>
          </Box>

          {/* remainder select */}
          <Box sx={{ mt: "4px" }}>
            {bookingState.reminders && bookingState.reminders.length > 0 && (
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Box
                  sx={{
                    height: "20px",
                    width: "20px",
                    marginRight: "20px",
                    alignSelf: "center"
                  }}
                >
                  <NotificationsNoneIcon />
                </Box>
                <Box
                  sx={{
                    flexGrow: 1
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    {bookingState.reminders.map((rem, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Box key={i} sx={{ width: "100%" }}>
                        <CalendarDropdown
                          fullwidth
                          label="Remind on"
                          isBookingWindow
                          placeholder="Remind on"
                          maxValidDate={moment(bookingState.start)}
                          date={new Date(rem.on as string)}
                          onChange={(on) => {
                            const rems = [...(bookingState.reminders || [])];
                            rems[i] = { ...rem, on };
                            setBookingState({ ...bookingState, reminders: rems });
                          }}
                          format="formatted3"
                          TextFieldProps={{ variant: "outlined" }}
                          TextFieldInputProps={{
                            disableUnderline: true,
                            style: {
                              fontSize: "12px"
                            }
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>

          {/* client select */}
          <Box>
            {currentClient.id && !editMode ? (
              <Card style={{ padding: "8px" }} variant="outlined">
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between"
                  }}
                >
                  <Box>
                    <Box sx={{ height: "18px", mb: "8px", display: "flex", alignItems: "center" }}>
                      <Box sx={{ pr: 1, display: "flex", alignItems: "center" }}>
                        <PersonIcon />
                      </Box>
                      <Box
                        fontWeight={600}
                        style={{
                          textDecoration: "underline",
                          cursor: "pointer",
                          fontSize: "12px"
                        }}
                        onClick={() => {
                          setCurrentSelectedClientId(currentClient.id);
                          setShowClientInfoPanel(true);
                        }}
                      >
                        {`${currentClient.firstName} ${currentClient.lastName}`.toUpperCase()}
                      </Box>
                    </Box>
                    <Box sx={{ height: "18px", display: "flex", alignItems: "center" }}>
                      <Box sx={{ pr: 1, display: "flex", alignItems: "center" }}>
                        <PhoneIcon />
                      </Box>
                      <Box fontSize={12}>{currentClient.phone}</Box>
                    </Box>
                  </Box>

                  <Box
                    data-testmation="clearIcon"
                    sx={{ cursor: "pointer" }}
                    onClick={async () => {
                      await setCurrentClient({
                        id: null,
                        firstName: "",
                        lastName: "",
                        phone: "",
                        email: "",
                        sendEmail: false,
                        knownUsFrom: "",
                        internalNotes: ""
                      });
                      setClientEmail("");
                      setError({ ...error, emailError: null });
                    }}
                  >
                    <ClearIcon />
                  </Box>
                </Box>
              </Card>
            ) : (
              <>
                {editMode ? (
                  <>
                    <IconWithField LabelIcon={<PersonIcon />}>
                      <Typography
                        sx={{
                          textDecoration: `${booking.client?.active ? "underline" : ""}`,
                          cursor: "pointer"
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (booking.client.active) {
                            setCurrentSelectedClientId(booking.client.id);
                            setShowClientInfoPanel(true);
                          }
                        }}
                      >
                        {booking?.client &&
                          `${clientFullNameSelector(booking.client).toUpperCase()}
                          ${!booking.client.active ? "(deleted)" : ""}`}
                      </Typography>
                    </IconWithField>
                    <IconWithField LabelIcon={<PhoneIcon />}>
                      <Typography style={{ fontSize: "12px" }}>{booking?.client?.phone}</Typography>
                    </IconWithField>
                  </>
                ) : (
                  <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                    <Box
                      sx={{
                        height: "20px",
                        width: "20px",
                        marginRight: "20px"
                      }}
                    >
                      <PersonIcon />
                    </Box>

                    <Box sx={{ flexGrow: 1 }}>
                      <ClientSearchAndCreate
                        currentClient={currentClient}
                        setCurrentClient={setCurrentClient}
                        bookingState={bookingState}
                        setBookingState={setBookingState}
                        error={error}
                        setError={setError}
                        formSettingsEmail={formSettingsEmail}
                      />
                    </Box>
                  </Box>
                )}
              </>
            )}
          </Box>

          {/* ask for email */}
          {askForEmail() && (
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Box
                sx={{
                  height: "20px",
                  width: "20px",
                  marginRight: "20px",
                  alignSelf: "center"
                }}
              >
                <Badge
                  variant="dot"
                  color="error"
                  invisible={!resourceCentre?.settings?.formSettings?.requireEmail}
                >
                  <EmailIcon />
                </Badge>
              </Box>

              <Box sx={{ flexGrow: 1 }}>
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  data-testmation="clientEmailField"
                  placeholder={formSettingsEmail ? "Customer email* (required)" : "Customer email"}
                  slotProps={{
                    input: {
                      disableUnderline: true,
                      style: {
                        fontSize: "12px"
                      }
                    }
                  }}
                  value={clientEmail}
                  error={
                    (formSettingsEmail && !clientEmail.length) ||
                    (error?.emailError ? !error.emailError.isValid : false)
                  }
                  helperText={getEmailErrorMsg()}
                  onChange={(e) => {
                    setClientEmail(e.target.value);
                    if (e.target.value?.length) {
                      const emailError = validate(e.target.value, [
                        isEmail({ msg: "Email address invalid" })
                      ]);
                      setError({ ...error, emailError });
                    } else {
                      setError({ ...error, emailError: null });
                    }
                  }}
                  onBlur={async () => {
                    await setBookingState({
                      ...bookingState,
                      clientEmail
                    });
                  }}
                />
              </Box>
            </Box>
          )}

          {/* select services and pay later */}
          {!!serviceProvider && (
            <Box sx={{ display: "flex", alignItems: "flex-start" }}>
              <Box
                sx={{
                  height: "20px",
                  width: "20px",
                  marginRight: "20px",
                  alignSelf: "center"
                }}
              >
                <CardGiftcardIcon />
              </Box>

              <Box sx={{ flexGrow: 1 }}>
                <ServicesSelectWithSpAssign
                  isBookingWindow
                  value={bookingServiceOptions}
                  spSpecificServices={spSpecificServices}
                  assignableServices={services.filter((item) => {
                    const isAlreadyAssigned = (item.document.rates || []).some(
                      (rate) => rate.serviceProviderId === bookingState.serviceProviderId
                    );
                    return (
                      item.productType === "single" && !isAlreadyAssigned && !item.serviceProviderId
                    );
                  })}
                  selectedSP={
                    bookingState.serviceProvider ||
                    serviceProviders.filter((item) => item.id === bookingState.serviceProviderId)[0]
                  }
                  selectedServicesId={bookingState.services || []}
                  onChange={(value) => {
                    let paymentAmount = 0;
                    const serviceIds = value.map((service) => {
                      if (Number(service.grossTotalPrice))
                        paymentAmount += Number(service.grossTotalPrice);
                      return service.id;
                    });
                    setBookingState({
                      ...bookingState,
                      services: serviceIds,
                      paymentInfo: { ...bookingState.paymentInfo, amount: paymentAmount }
                    });
                  }}
                  afterSpAssignSave={({ serviceIds, paymentAmount }) => {
                    setBookingState({
                      ...bookingState,
                      services: serviceIds,
                      paymentInfo: {
                        ...bookingState.paymentInfo,
                        amount: paymentAmount
                      }
                    });
                  }}
                  error={{
                    value: false,
                    message: ""
                  }}
                />
              </Box>
            </Box>
          )}

          {/* referrer select and know us from select */}
          <Grid container spacing={3}>
            <Grid size={6}>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Box
                  sx={{
                    height: "20px",
                    width: "20px",
                    marginRight: "20px",
                    alignSelf: "center"
                  }}
                >
                  <UndoIcon />
                </Box>

                <Box sx={{ flexGrow: 1 }}>
                  <ReferrerCreateSelect
                    isBookingWindow
                    referrerId={bookingState.referrerId}
                    useSettings
                    onReferrerChange={(referrer) => {
                      if (referrer) {
                        setBookingState({
                          ...bookingState,
                          referredBy: referrer.referrer,
                          referrerId: referrer.id
                        });
                      }
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid size={6}>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Box
                  sx={{
                    height: "20px",
                    width: "20px",
                    marginRight: "10px",
                    alignSelf: "center"
                  }}
                >
                  <RadioIcon />
                </Box>
                <Box sx={{ flexGrow: 1 }}>
                  <KnownUsFromSelect
                    isBookingWindow
                    variant="standard"
                    value={knownUsFromValue}
                    required={requireKnownUsFrom && !bookingState.clientId}
                    error={{
                      value:
                        !bookingState.clientId &&
                        ((requireKnownUsFrom && !knownUsFromValue.length) ||
                          (error?.knownUsFromError ? !error.knownUsFromError.isValid : false)),
                      message: error?.knownUsFromError?.message || ""
                    }}
                    onChange={(value) => {
                      setKnownUsFromValue(value);
                      if (requireKnownUsFrom && !bookingState.clientId && !value.length) {
                        setError((prevState) => ({
                          ...prevState,
                          knownUsFromError: { isValid: false, message: "Known us from is required" }
                        }));
                      } else {
                        setBookingState({ ...bookingState, clientKnownFrom: value });
                        setError((prevState) => ({ ...prevState, knownUsFromError: null }));
                      }
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>

          {/* add notes and client specific notes */}
          <Grid container spacing={3}>
            <Grid size={6}>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Box
                  sx={{
                    height: "20px",
                    width: "20px",
                    marginRight: "20px",
                    alignSelf: "center"
                  }}
                >
                  <NotesIcon />
                </Box>

                <Box sx={{ flexGrow: 1 }}>
                  <TextField
                    data-testmation="bookingRemarks"
                    multiline
                    fullWidth
                    maxRows={4}
                    value={bookingState.remarks}
                    placeholder={t("booking.note")}
                    variant="outlined"
                    size="small"
                    slotProps={{
                      input: {
                        disableUnderline: true,
                        style: {
                          padding: "8px",
                          fontSize: "12px"
                        }
                      }
                    }}
                    onChange={(e) => {
                      if (e.target.value) {
                        return setBookingState({ ...bookingState, remarks: e.target.value });
                      }
                      return setBookingState({ ...bookingState, remarks: "" });
                    }}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid size={6}>
              <Box sx={{ display: "flex", alignItems: "flex-start" }}>
                <Box
                  sx={{
                    height: "20px",
                    width: "20px",
                    marginRight: "10px",
                    alignSelf: "center"
                  }}
                >
                  <DescriptionIcon />
                </Box>

                <Box sx={{ flexGrow: 1 }}>
                  <TextField
                    data-testmation="clientNotes"
                    fullWidth
                    multiline
                    value={bookingState.clientInternalNotes}
                    placeholder="Client Specific Notes"
                    size="small"
                    variant="outlined"
                    slotProps={{
                      input: {
                        disableUnderline: true,
                        style: {
                          padding: "8px",
                          fontSize: "12px"
                        }
                      }
                    }}
                    onChange={(e) => {
                      setBookingState({ ...bookingState, clientInternalNotes: e.target.value });
                    }}
                  />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* footer */}
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            mt: "8px"
          }}
        >
          {isSmsSubscribed && (
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Typography fontSize="12px">Send SMS To: </Typography>
              <FormGroup row sx={{ ml: "16px" }}>
                <FormControlLabel
                  control={<Checkbox checked={bookingState.sendToClient} />}
                  label="Client"
                  onChange={() => {
                    setBookingState({
                      ...bookingState,
                      sendToClient: !bookingState.sendToClient
                    });
                  }}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "12px"
                    }
                  }}
                />
                <FormControlLabel
                  control={<Checkbox checked={bookingState.sendToSp} />}
                  label="Doctor"
                  onChange={() => {
                    setBookingState({ ...bookingState, sendToSp: !bookingState.sendToSp });
                  }}
                  sx={{
                    "& .MuiFormControlLabel-label": {
                      fontSize: "12px"
                    }
                  }}
                />
              </FormGroup>
            </Box>
          )}

          <Box>
            <Button
              color="primary"
              onClick={() => onClose()}
              style={{ paddingRight: "16px" }}
              data-testmation="bookingCancel"
            >
              {tl("booking.cancel")}
            </Button>
            <StatefulButton
              data-testmation={editMode ? "bookingUpdate" : "bookingBook"}
              variant="contained"
              color="primary"
              disabled={isLoading || !isValidData()}
              onClick={async () => {
                setIsLoading(true);
                const formattedData = produce(bookingState, (draft) => {
                  draft.bookableResourceId = draft.bookableResource?.id || null;
                  delete draft.bookableResource;
                });
                await onSave(formattedData);
                setIsLoading(false);
              }}
              isLoading={isLoading}
              circularProgressProps={{ size: 16 }}
            >
              <Typography>{editMode ? tl("booking.update") : tl("booking.book")}</Typography>
            </StatefulButton>
          </Box>
        </Box>
      </Box>

      {showClientInfoPanel &&
        (clientEditMode ? (
          <ClientCreateEdit
            setEditMode={setClientEditMode}
            clientId={currentSelectedClientId}
            mode="edit"
            onCancel={() => {
              setClientEditMode(false);
            }}
            stayOnCurrentPage
            wrapperStyle={{ zIndex: "1300" }}
          />
        ) : (
          <ClientInfo
            setEditMode={setClientEditMode}
            id={currentSelectedClientId}
            handleViewClose={() => setShowClientInfoPanel(false)}
            stayOnCurrentPage
            wrapperStyle={{ zIndex: "1300" }}
          />
        ))}
    </Box>
  );
};

export default connect(
  (state: RootState, ownProps: { booking: Partial<Booking> }) => {
    const rcId = state.userContext.resourceCentreId;
    const spId = ownProps.booking.serviceProviderId;
    const resourceCentre =
      state.resources.resourceCentres?.find((rc) => rc.id === rcId) ||
      state.userContext.resourceCentre;
    const serviceProviderInArr = state.resources.resourceCentreServiceProviders.filter(
      (sp) => Number(sp.id) === Number(spId)
    );
    const serviceProvider = serviceProviderInArr[0];
    const services = state.services.collection.filter(({ active }) => active);
    return {
      rcId,
      serviceProvider,
      resourceCentre,
      services,
      serviceProviders: serviceProvidersSortedSelector(state as never)
    };
  },
  (dispatch: IThunkDispatch) => ({
    onDelete: (bookingId, onClose) => {
      dispatch(
        showDialog({
          title: tl("booking.cancel"),
          description: "Are you sure you want to cancel this booking?",
          next: async () => {
            await dispatch(bookingActions.cancelBooking(bookingId));
            dispatch(navigateRemoveModal("Dialog"));
            onClose();
          },
          onCancel: () => ({})
        })
      );
    }
  })
)(BookingCreateEdit);
