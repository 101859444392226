import * as React from "react";
import "./style.scss";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputLabel from "@mui/material/InputLabel";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid2";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import without from "lodash/without";
import * as moment from "moment";
import { spFullNameSelector } from "../../reducers/serviceProvider";
import { tl, t } from "../../components/translate";
import CalendarDropdown from "../../components/CalendarDropdown/CalendarDropdown";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";
import { ServiceProvider } from "../../interfaces/ServiceProvidersInterface";

const WEEK_DAYS = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export interface SlotsProps {
  serviceProvider: ServiceProvider;
  onChange: (data: SlotsState) => void;
  resourceCentre?: Partial<ResourceCentre>;
  resourceCentreServiceProvider?: Partial<ServiceProvider>;
}

export interface SlotsState {
  slotDuration: number;
  resourceCentre: any;
  resourceCentreServiceProvider: any;
  fromDate: Date;
  toDate: Date;
  fromTime: string;
  toTime: string;
  capacity: number;
  every: Array<number>;
}

const className = "slots";
const Slots: React.FC<SlotsProps> = ({
  serviceProvider,
  onChange,
  resourceCentre,
  resourceCentreServiceProvider
}) => {
  const weekLater = moment().add("week", 1).toDate();

  const [state, setState] = React.useState<SlotsState>({
    slotDuration: 10,
    resourceCentre,
    resourceCentreServiceProvider,
    fromDate: new Date(),
    toDate: weekLater,
    fromTime: "07:00",
    toTime: "11:30",
    capacity: 1,
    every: []
  });

  const propagateData = () => {
    onChange({ ...state });
  };

  React.useEffect(() => {
    propagateData();
  }, [state]);

  const isChecked = (i: number) => state.every.includes(i);

  const toggleCheckDay = (i: number) => {
    setState((prevState) => ({
      ...prevState,
      every: isChecked(i) ? without(prevState.every, i) : prevState.every.concat(i)
    }));
  };

  const handleChange = (propName: keyof SlotsState, e: any) => {
    setState((prevState) => ({
      ...prevState,
      [propName]: e.target ? e.target.value : e
    }));
  };

  return (
    <div className={`${className}`}>
      <Grid container>
        <Grid size={{ xs: 12, lg: 12 }}>
          <Typography variant="h5">{spFullNameSelector(serviceProvider)}</Typography>
        </Grid>
        <Grid container>
          <Grid size={{ xs: 12, sm: 12, lg: 6 }}>
            <FormControl className={`${className}__formcontrol`}>
              <CalendarDropdown
                date={state.fromDate}
                label={t("booking.fromDate")}
                placeholder=""
                onChange={(d) => handleChange("fromDate", { target: { value: d } })}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: 12, lg: 6 }}>
            <FormControl className={`${className}__formcontrol`}>
              <CalendarDropdown
                date={state.toDate}
                label={t("booking.toDate")}
                placeholder=""
                onChange={(d) => handleChange("toDate", { target: { value: d } })}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid size={{ xs: 12, sm: 12, lg: 6 }}>
            <FormControl className={`${className}__formcontrol`}>
              <TextField
                id="time"
                label={tl("booking.fromTime")}
                type="time"
                value={state.fromTime}
                onChange={(e) => handleChange("fromTime", e)}
                slotProps={{
                  htmlInput: { step: 600 },
                  inputLabel: { shrink: true }
                }}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: 12, lg: 6 }}>
            <FormControl className={`${className}__formcontrol`}>
              <TextField
                id="time"
                label={tl("booking.toTime")}
                type="time"
                value={state.toTime}
                onChange={(e) => handleChange("toTime", e)}
                slotProps={{
                  htmlInput: { step: 600 },
                  inputLabel: { shrink: true }
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container>
          <Grid size={{ xs: 12, sm: 12, lg: 6 }}>
            <FormControl className={`${className}__formcontrol`}>
              <InputLabel htmlFor="to-date-time">{tl("booking.slotDuration")}</InputLabel>
              <Select
                value={state.slotDuration}
                onChange={(val) => handleChange("slotDuration", val)}
                inputProps={{
                  name: "slot-duration",
                  id: "slot-duration"
                }}
              >
                <MenuItem value={10}>10 minutes </MenuItem>
                <MenuItem value={5}>5 minutes</MenuItem>
                <MenuItem value={15}>15 minutes</MenuItem>
                <MenuItem value={30}>30 minutes</MenuItem>
                <MenuItem value={60}>60 minutes</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, sm: 12, lg: 6 }}>
            <TextField
              className={`${className}__formcontrol`}
              id="slot-Capacity"
              label={tl("booking.slotCapacity")}
              value="1 Person"
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid size={{ xs: 12, lg: 12 }}>
            <Typography>
              <Box component="div" fontWeight={400} fontSize="0.75rem" paddingTop="8px">
                {tl("booking.repeat")}
              </Box>
            </Typography>
            {WEEK_DAYS.map((d, i) => (
              <FormControlLabel
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                control={
                  <Checkbox value={d} onChange={() => toggleCheckDay(i)} checked={isChecked(i)} />
                }
                label={tl(d)}
              />
            ))}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default Slots;
