import * as React from "react";
import { Box, Typography, Card, Grid2 as Grid } from "@mui/material";
import { MenuBook } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { push } from "connected-react-router";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AssessmentIcon from "@mui/icons-material/Assessment";
import InventoryIcon from "@mui/icons-material/Inventory";
import StackedLineChartOutlinedIcon from "@mui/icons-material/StackedLineChartOutlined";
import BoyIcon from "@mui/icons-material/Boy";
import InsertChartIcon from "@mui/icons-material/InsertChart";
import SmsFailedIcon from "@mui/icons-material/SmsFailed";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import QueuePlayNextIcon from "@mui/icons-material/QueuePlayNext";
import FlaskIcon from "../../../assets/icons/Flask.icon";
import styles from "./Reports.module.css";
import { tl } from "../../components/translate";
import Can from "../Policy/Can";
import { PolicyAccessKeys } from "../../interfaces/policy";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../mixpanel-analytics/event";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";
import ReportSearch from "./search/ReportSearch";
import useIsDaybookSubscribed from "../../hooks/useIsDaybookSubscribed";

const ReportTypesList = (): JSX.Element => {
  const dispatch = useDispatch();
  const rc = useCurrentResourceCentre();
  const { isDaybookSubscribed } = useIsDaybookSubscribed();

  return (
    <Box height="100%" padding="0 32px">
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        fontSize="20px"
        fontWeight={600}
      >
        <p>{tl("reports.reports")}</p>
        <ReportSearch />
      </Box>
      <Box marginTop="32px">
        <Can policyAccessKey="account:listAccount">
          <Card
            className={styles.reportCardRoot}
            onClick={() => dispatch(push("/reports/account"))}
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    {tl("reports.account")}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <AttachMoneyIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="stock:listStock">
          <Card
            className={styles.reportCardRoot}
            onClick={() => dispatch(push("/reports/stock"))}
            data-testmation="stockReportCard"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography fontWeight="700" fontSize="24px" component="span">
                    {tl("reports.stock")}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <InventoryIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Card className={styles.reportCardRoot} onClick={() => dispatch(push("/reports/patient"))}>
          <Grid container>
            <Grid size={8}>
              <Box height="100%" width="100%">
                <Typography fontWeight="700" fontSize="24px" component="span">
                  {tl("reports.patient")}
                </Typography>
              </Box>
            </Grid>
            <Grid size={4}>
              <BoyIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
        <Card
          className={styles.reportCardRoot}
          onClick={() => dispatch(push("/reports/financial"))}
        >
          <Grid container>
            <Grid size={8}>
              <Box height="100%" width="100%">
                <Typography fontWeight="700" fontSize="24px" component="span">
                  {tl("reports.financial")}
                </Typography>
              </Box>
            </Grid>
            <Grid size={4}>
              <StackedLineChartOutlinedIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>

        <Can policyAccessKey="deny" superAdminPass>
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_SUMMARY_REPORT, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/superAdminSummaryReport"));
            }}
            data-testmation="summaryReportCard"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography>
                    <Box component="span" fontWeight="700" fontSize="24px">
                      {tl("reports.superAdminSummaryReport")}
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <InsertChartIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="report:smsReport">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_MESSAGE_REPORT, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/messageReport"));
            }}
            data-testmation="messageReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography>
                    <Box component="span" fontWeight="700" fontSize="24px">
                      {tl("MessageReport")}
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <SmsFailedIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="lab:listLab">
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_LAB_REPORT, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/labReport"));
            }}
            data-testmation="labReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography>
                    <Box component="span" fontWeight="700" fontSize="24px">
                      {tl("reports.labReport")}
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <FlaskIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey="deny" superAdminPass restrictSupportAdmin>
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              if (rc) {
                mixpanelAnalytics.track(EVENT.REPORT_GO_TO_IRD_REPORT, {
                  rcId: rc.id,
                  rcName: rc.name
                });
              }
              return dispatch(push("/reports/irdReport"));
            }}
            data-testmation="irdReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography>
                    <Box component="span" fontWeight="700" fontSize="24px">
                      IRD Report
                    </Box>
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <MonetizationOnIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>

        <Can policyAccessKey={PolicyAccessKeys.CmsReport}>
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_CENTRAL_MONITORING_REPORT, {
                rcId: rc.id
              });
              return dispatch(push("/reports/centralMonitoringLabReport"));
            }}
            data-testmation="centralMonitoringLabReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography component="span" fontWeight="700" fontSize="24px">
                    Central Monitoring Report
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <QueuePlayNextIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>
        <Can policyAccessKey={PolicyAccessKeys.CmsReport}>
          <Card
            className={styles.reportCardRoot}
            onClick={() => {
              mixpanelAnalytics.track(EVENT.REPORT_GO_TO_BRANCH_REPORT, {
                rcId: rc.id,
                rcName: rc.name
              });
              return dispatch(push("/reports/branchReports"));
            }}
            data-testmation="branchReports"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography component="span" fontWeight="700" fontSize="24px">
                    Central Reporting For Branches
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <QueuePlayNextIcon className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        </Can>

        {isDaybookSubscribed && (
          <Card
            className={styles.reportCardRoot}
            onClick={() => dispatch(push("/reports/daybookReport"))}
            data-testmation="daybookReport"
          >
            <Grid container>
              <Grid size={8}>
                <Box height="100%" width="100%">
                  <Typography component="span" fontWeight="700" fontSize="24px">
                    {tl("reports.daybookReport")}
                  </Typography>
                </Box>
              </Grid>
              <Grid size={4}>
                <MenuBook className={styles.reportCardChartIcon} />
              </Grid>
            </Grid>
          </Card>
        )}

        <Card
          className={styles.reportCardRoot}
          onClick={() => dispatch(push("/reports/otherReport"))}
          data-testmation="otherReport"
        >
          <Grid container>
            <Grid size={8}>
              <Box height="100%" width="100%">
                <Typography component="span" fontWeight="700" fontSize="24px">
                  {tl("reports.otherReport")}
                </Typography>
              </Box>
            </Grid>
            <Grid size={4}>
              <AssessmentIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Box>
  );
};

export default ReportTypesList;
