import * as React from "react";
import { Box, Typography, Card, Grid2 as Grid } from "@mui/material";
import { push } from "connected-react-router";
import { useDispatch } from "react-redux";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import TableChartIcon from "@mui/icons-material/TableChart";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import styles from "./Reports.module.css";
import { tl } from "../../components/translate";
import mixpanelAnalytics from "../../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../../mixpanel-analytics/event";
import useCurrentResourceCentre from "../../hooks/useCurrentResourceCentre";

const ReportsAccount = (): JSX.Element => {
  const dispatch = useDispatch();
  const rc = useCurrentResourceCentre();

  return (
    <Box height="100%" padding="0 32px">
      <Typography fontSize="20px" fontWeight={600} lineHeight="40px" component="div">
        {tl("reports.account")}
      </Typography>
      <Box marginTop="32px">
        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_ACCOUNT_TRIAL_BALANCE, {
              rcId: rc.id,
              rcName: rc.name
            });
            return dispatch(push("/reports/account/trialBalance"));
          }}
          data-testmation="trialBalanceReportCard"
        >
          <Grid container>
            <Grid size={8}>
              <Typography fontWeight="700" fontSize="24px">
                {tl("reports.trialBalance")}
              </Typography>
            </Grid>
            <Grid size={4}>
              <DonutSmallIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_ACCOUNT_GENERAL_LEDGER, {
              rcId: rc.id,
              rcName: rc.name
            });
            return dispatch(push("/reports/account/generalLedger"));
          }}
          data-testmation="generalLedgerReport"
        >
          <Grid container>
            <Grid size={8}>
              <Typography fontWeight="700" fontSize="24px">
                {tl("generalLEdgerReport")}
              </Typography>
            </Grid>
            <Grid size={4}>
              <CreditScoreIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_ACCOUNT_CUSTOMER, {
              rcId: rc.id,
              rcName: rc.name
            });
            return dispatch(push("/reports/account/customerReport"));
          }}
          data-testmation="customerReport"
        >
          <Grid container>
            <Grid size={8}>
              <Typography fontWeight="700" fontSize="24px">
                {tl("customerReport")}
              </Typography>
            </Grid>
            <Grid size={4}>
              <AccessibilityIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_ACCOUNT_VENDOR, {
              rcId: rc.id,
              rcName: rc.name
            });
            return dispatch(push("/reports/account/vendorReport"));
          }}
          data-testmation="vendorReport"
        >
          <Grid container>
            <Grid size={8}>
              <Typography fontWeight="700" fontSize="24px">
                {tl("vendorReport")}
              </Typography>
            </Grid>
            <Grid size={4}>
              <AddBusinessIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_ACCOUNT_BANK_RECONCILIATION, {
              rcId: rc.id
            });
            return dispatch(push("/reports/account/bankReconciliation"));
          }}
          data-testmation="bankReconciliation"
        >
          <Grid container>
            <Grid size={8}>
              <Typography fontWeight="700" fontSize="24px">
                {tl("bankReconciliationReport")}
              </Typography>
            </Grid>
            <Grid size={4}>
              <AccountBalanceIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>

        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_ACCOUNT_BALANCE_SHEET, {
              rcId: rc.id,
              rcName: rc.name
            });
            return dispatch(push("/reports/account/balanceSheet"));
          }}
          data-testmation="balanceCard"
        >
          <Grid container>
            <Grid size={8}>
              <Typography fontWeight="700" fontSize="24px">
                {tl("balanceSheet")}
              </Typography>
            </Grid>
            <Grid size={4}>
              <TableChartIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_ACCOUNT_PROFIT_LOSS, {
              rcId: rc.id,
              rcName: rc.name
            });
            return dispatch(push("/reports/account/profitLoss"));
          }}
          data-testmation="profitLossReportCard"
        >
          <Grid container>
            <Grid size={8}>
              <Typography fontWeight="700" fontSize="24px">
                {tl("profitLoss")}
              </Typography>
            </Grid>
            <Grid size={4}>
              <BubbleChartIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
        <Card
          className={styles.reportCardRoot}
          onClick={() => {
            mixpanelAnalytics.track(EVENT.REPORT_GO_TO_ACCOUNT_DAY_BOOK, {
              rcId: rc.id,
              rcName: rc.name
            });
            return dispatch(push("/reports/account/dayBookReport"));
          }}
          data-testmation="dayBookReportCard"
        >
          <Grid container>
            <Grid size={8}>
              <Typography fontWeight="700" fontSize="24px">
                {tl("dayBookReport")}
              </Typography>
            </Grid>
            <Grid size={4}>
              <LibraryBooksIcon className={styles.reportCardChartIcon} />
            </Grid>
          </Grid>
        </Card>
      </Box>
    </Box>
  );
};

export default ReportsAccount;
