import React from "react";
import { keys } from "lodash";
import { Dialog, DialogContent, Box, IconButton, Grid, Typography } from "@mui/material";
import Portal from "@mui/material/Portal";
import CloseIcon from "@mui/icons-material/Close";
import moment from "moment/moment";
import { RichText } from "prismic-reactjs";
import { Announcement, getAnnouncements } from "../../../api/prismicQuery";
import localStorageObject from "../../../helpers/localStorage";

const announcementsKey = "announcements";

const AnnouncementModal = (): JSX.Element => {
  const [newAnnouncements, setNewAnnouncements] = React.useState<null | Announcement[]>(null);

  const getSeenAnnouncementsId = () =>
    JSON.parse(localStorageObject.getItem(announcementsKey)) || {};

  const handleCloseReleaseNote = () => {
    let lastSeenAnnouncements = getSeenAnnouncementsId();

    if (keys(lastSeenAnnouncements).length > 14) {
      lastSeenAnnouncements = {};
    }

    const allSeenReleaseNotes = newAnnouncements?.reduce(
      (seenFlags, releaseNote) => ({ ...seenFlags, [releaseNote.id]: true }),
      lastSeenAnnouncements
    );

    localStorageObject.setItem(
      announcementsKey,
      JSON.stringify(allSeenReleaseNotes as Record<string, boolean>)
    );
    setNewAnnouncements(null);
  };

  React.useEffect(() => {
    (async () => {
      try {
        const latestAnnouncements = await getAnnouncements();

        if (latestAnnouncements.length === 0) {
          return;
        }

        const seenAnnouncements = getSeenAnnouncementsId();
        const unseenAnnouncements = latestAnnouncements.filter(
          (latestReleaseNote) => !keys(seenAnnouncements).includes(latestReleaseNote.id)
        );

        if (unseenAnnouncements.length === 0) {
          return;
        }
        setNewAnnouncements(unseenAnnouncements);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log("Error fetching announcements:", error);
      }
    })();
  }, []);

  const showModal = Boolean(newAnnouncements?.length);

  if (!showModal) {
    return null;
  }

  return (
    <Portal>
      <Dialog
        fullWidth
        maxWidth="md"
        open={showModal}
        aria-labelledby="release-note-modal"
        aria-describedby="release note"
      >
        <DialogContent sx={{ p: 0 }}>
          <div>
            {/* eslint-disable-next-line camelcase */}
            {newAnnouncements?.map(({ id, data: { body, announcement_date, title } }, index) => {
              const items = body[0]?.items || [];
              return (
                <div key={id}>
                  <div>
                    <Box
                      sx={(theme) => ({
                        backgroundColor: theme.palette.primary.main,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        p: 2
                      })}
                    >
                      <div>
                        <Typography fontSize={20} color="#fff">
                          {title[0].text}
                        </Typography>
                        <Typography fontSize={14} color="#ccc">
                          {moment(announcement_date).format("DD.MM.YYYY")}
                        </Typography>
                      </div>
                      {index === 0 && (
                        <IconButton
                          aria-label="close release note"
                          component="button"
                          onClick={handleCloseReleaseNote}
                        >
                          <CloseIcon sx={{ color: "#fff" }} />
                        </IconButton>
                      )}
                    </Box>
                  </div>
                  <Box p={2}>
                    {/* eslint-disable-next-line camelcase */}
                    {items.map(({ announcement_content }, i) => (
                      // eslint-disable-next-line react/no-array-index-key
                      <Grid key={i} container spacing={2}>
                        <Grid item display="flex" alignItems="center" minWidth={100} />
                        <Grid item>
                          {/* eslint-disable-next-line camelcase */}
                          <RichText render={announcement_content} />
                        </Grid>
                      </Grid>
                    ))}
                  </Box>
                </div>
              );
            })}
          </div>
        </DialogContent>
      </Dialog>
    </Portal>
  );
};

export default AnnouncementModal;
