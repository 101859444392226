import React from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "../../../../store/hooks";
import Panel from "../../../../components/Panel";
import { resourceCentreActions as actions } from "../../../../actions";
import HmisSettings from "../../Settings/HmisSettings";

interface Props {
  onClose: () => void;
}

const HmisSettingsPanel = ({ onClose }: Props): JSX.Element => {
  const resourceCentre = useAppSelector((state) => state.resources.resourceCentres[0]);
  const { hmisSettings } = resourceCentre.settings;
  const [settings, setSettings] = React.useState({
    settings: {
      hmisSettings: {
        username: "",
        password: "",
        orgUnit: "",
        hmisFormDataIds: [],
        government: false
      }
    }
  });

  React.useEffect(() => {
    setSettings({
      ...resourceCentre,
      settings: {
        ...resourceCentre.settings,
        hmisSettings: {
          username: hmisSettings?.username,
          password: hmisSettings?.password,
          orgUnit: hmisSettings?.orgUnit,
          hmisFormDataIds: hmisSettings?.hmisFormDataIds,
          government: hmisSettings?.government
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resourceCentre]);
  return (
    <Panel onClose={onClose} title="Lab Settings">
      <Box px={4}>
        <HmisSettings state={settings} actions={actions} />
      </Box>
    </Panel>
  );
};

export default HmisSettingsPanel;
