import EmailIcon from "@mui/icons-material/Email";
import PersonIcon from "@mui/icons-material/Person";
import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  CircularProgress,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import makeStyles from "@mui/styles/makeStyles";
import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { notificationAdd } from "../../../actions/notification";
import { clientSearch } from "../../../api/bookings";
import MuiPhoneNumber from "../../../components/PhoneNumber";
import { t, tl } from "../../../components/translate";
import {
  isEmail,
  isISODateString,
  isValidMobileNumber,
  validate
} from "../../../helpers/validators";
import { RootState } from "../../../store";
import ToggleInputDate from "../../../components/ToggleADBS";
import { fields } from "../../../models/Client";

const useStyles = makeStyles({
  helperText: {
    fontSize: "0.625rem",
    marginTop: "4px",
    color: "#009856"
  },
  formControlLabel: {
    height: "20px",
    marginTop: "5px"
  }
});

const ClientSearchAndCreate: React.FC<{
  currentClient;
  setCurrentClient;
  error;
  setError;
  bookingState;
  setBookingState;
  formSettingsEmail: boolean;
}> = ({
  currentClient,
  setCurrentClient,
  error,
  setError,
  bookingState,
  setBookingState,
  formSettingsEmail
}) => {
  const classes = useStyles();
  const [name, setName] = React.useState("");
  const [phone, setPhone] = React.useState("");
  const [gender, setGender] = React.useState("");
  const [dob, setDob] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [address, setAddress] = React.useState("");
  const [searchString, setSearchString] = React.useState("");
  const [createClient, setCreateClient] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [clientOptions, setClientOptions] = React.useState([]);
  const [err, setErr] = React.useState(null);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (searchString.length > 2) {
      (async () => {
        setLoading(true);
        try {
          const response = await clientSearch(searchString);
          setClientOptions(response.filter((client) => !client.isWalkInCustomer));
        } catch (e) {
          dispatch(
            notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "error",
              message: e?.data || "Something Went Wrong!.",
              autoTimeout: true
            })
          );
        }
        setLoading(false);
      })();
    }
  }, [searchString]);

  React.useEffect(() => {
    setName(searchString);
    setPhone("");
    setEmail("");
    setBookingState({
      ...bookingState,
      clientName: searchString,
      clientPhoneNo: "",
      clientEmail: ""
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createClient]);

  const requirePhoneNumber = useSelector(
    (state: RootState) =>
      state.resources?.resourceCentres[0]?.settings?.formSettings?.requirePhoneNumber
  );

  const validateNewClient = () => {
    let errorCount = 0;
    if (err) {
      Object.keys(err).forEach((key) => {
        if (key && err[key] && !err[key].isValid) {
          errorCount += 1;
          setError({
            ...error,
            clientError: { messages: ["Invalid client data"], isValid: false }
          });
        }
      });
    }
    if (!errorCount) {
      setError({
        ...error,
        clientError: null
      });
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  React.useEffect(() => validateNewClient(), [err]);

  if (!createClient) {
    return (
      <Box
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "4px"
        }}
      >
        <Autocomplete
          data-testmation="clientSelect"
          fullWidth
          disableClearable
          forcePopupIcon={false}
          value={currentClient}
          isOptionEqualToValue={(option, value) => option.id === value.id}
          loading={loading}
          options={clientOptions}
          filterOptions={(options) => options}
          getOptionLabel={(option) =>
            option.firstName ? `${option.firstName} ${option.lastName}` : ""
          }
          renderOption={(props, option) => (
            // eslint-disable-next-line react/jsx-props-no-spreading
            <li {...props} key={option.id}>
              <Box sx={{ mb: 2 }}>
                <Box>
                  {option.firstName} {option.lastName}
                </Box>
                <Box fontSize="small">{option.phone}</Box>
              </Box>
            </li>
          )}
          onChange={(e, v) => {
            if (v) {
              setCurrentClient({
                id: v.id,
                firstName: v.firstName,
                lastName: v.lastName,
                phone: v.phone,
                email: v.email,
                sendEmail: v.sendEmail,
                knownUsFrom: v.knownUsFrom,
                internalNotes: v.internalNotes
              });
            }
          }}
          renderInput={(params) => (
            <TextField
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...params}
              placeholder={t("booking.searchCustomer")}
              variant="outlined"
              onChange={(e) => {
                if (e.target.value) setSearchString(e.target.value);
              }}
              onBlur={() => setClientOptions([])}
              slotProps={{
                input: {
                  ...params.InputProps,
                  disableUnderline: true,
                  style: {
                    fontSize: "12px"
                  },
                  endAdornment: (
                    <>
                      {loading ? (
                        <CircularProgress color="inherit" size="8px" />
                      ) : (
                        <SearchIcon style={{ color: "#292929" }} />
                      )}
                      {params.InputProps.endAdornment}
                    </>
                  )
                }
              }}
            />
          )}
        />

        <Link
          data-testmation="createClient"
          onClick={() => {
            setCreateClient(true);
          }}
          style={{
            color: "#292929",
            textDecoration: "underline",
            fontWeight: "bold",
            fontSize: "12px",
            cursor: "pointer"
          }}
        >
          {tl("booking.createCustomer")}
        </Link>
      </Box>
    );
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row", alignItems: "flex-start" }}>
      <Box
        sx={{
          paddingRight: 1,
          display: "flex",
          flexDirection: "column",
          flexGrow: 1
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: 1,
            alignItems: "center"
          }}
        >
          <TextField
            fullWidth
            required
            value={name}
            label={t("booking.customerName")}
            placeholder="e.g Ram Mahato"
            data-testmation="customerName"
            variant="outlined"
            margin="dense"
            slotProps={{
              input: {
                style: {
                  paddingTop: 0,
                  fontSize: 12
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                )
              }
            }}
            style={{ marginTop: "10px" }}
            onChange={(e) => {
              setName(e.target.value);
              if (e.target.value.length) setErr({ ...err, nameError: null });
              else
                setErr({
                  ...err,
                  nameError: { isValid: false, messages: ["Name can't be empty"] }
                });
            }}
            helperText={err?.nameError?.messages[0]}
            error={!name.length || (err?.nameError ? !err.nameError.isValid : false)}
            onBlur={() => {
              setBookingState({
                ...bookingState,
                clientName: name
              });
            }}
          />
          <IconButton
            title="Search existing customer"
            data-testmation="searchClient"
            onClick={() => {
              setCreateClient(false);
              setError({ ...error, clientError: null });
            }}
            size="small"
          >
            <SearchIcon style={{ color: "#292929" }} />
          </IconButton>
        </Box>
        <Box
          sx={{
            marginTop: "12px",
            marginBottom: "5px",
            display: "grid",
            gap: 1,
            gridTemplateColumns: "1fr 120px"
          }}
        >
          <ToggleInputDate
            data-testmation="clientAgeInput"
            key="dob"
            field={fields[4]}
            data={{
              dob
            }}
            changeDate={(value) => {
              if (isISODateString(value) || value === null) {
                setDob(value);
                setBookingState({
                  ...bookingState,
                  clientDOB: value
                });
                setErr({ ...err, dobError: null });
              } else {
                setErr({
                  ...err,
                  dobError: { isValid: false, messages: ["Please enter valid date"] }
                });
              }
            }}
            showAgeField
            label="dob"
            FormHelperTextProps={{ classes: { root: classes.helperText } }}
            error={err?.dobError ? !err.dobError.isValid : false}
            helperText=""
            isFocused={() => ({})}
            isBlurred={() => ({})}
          />
          <FormControl size="small" variant="outlined" error={!gender} required>
            <InputLabel id="gender-select-label" shrink>
              {tl("clients.gender")}
            </InputLabel>
            <Select
              notched
              placeholder="select gender"
              labelId="gender-select-label"
              id="gender-select"
              value={gender}
              label="Gender"
              onChange={(event) => {
                setGender(event.target.value);
                setBookingState({
                  ...bookingState,
                  clientGender: Number(event.target.value)
                });
              }}
              variant="outlined"
            >
              <MenuItem value="1">{t("clients.gender.1")} </MenuItem>
              <MenuItem value="2">{t("clients.gender.2")}</MenuItem>
              <MenuItem value="3">{t("clients.gender.3")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <MuiPhoneNumber
          required={requirePhoneNumber}
          data-testmation="customerPhone"
          label="Customer's Phone"
          disableAreaCodes
          variant="outlined"
          margin="dense"
          defaultCountry="np"
          value={phone}
          InputProps={{
            style: {
              paddingTop: 0,
              fontSize: 12
            }
          }}
          style={{ marginTop: "10px" }}
          autoFormat={false}
          onChange={(phoneNumber) => {
            setPhone(phoneNumber);
            if (phoneNumber) {
              const phoneError = validate(phoneNumber, [
                isValidMobileNumber({ msg: "Mobile number invalid" })
              ]);
              setErr({ ...err, phoneError });
            } else {
              setErr({ ...err, phoneError: null });
            }
          }}
          FormHelperTextProps={{ classes: { root: classes.helperText } }}
          error={
            (requirePhoneNumber && !phone.length) ||
            (err?.phoneError ? !err.phoneError.isValid : false)
          }
          helperText={err?.phoneError?.messages[0]}
          onBlur={() => {
            setBookingState({
              ...bookingState,
              clientPhoneNo: phone
            });
          }}
        />
        <TextField
          fullWidth
          required={formSettingsEmail}
          data-testmation="customers Email"
          value={email}
          label={t("booking.customerEmail")}
          placeholder="e.g ram@gmail.com"
          variant="outlined"
          margin="dense"
          slotProps={{
            input: {
              disableUnderline: true,
              style: {
                paddingTop: 0,
                fontSize: 12
              },
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              )
            },
            formHelperText: {
              classes: {
                root: classes.helperText
              }
            }
          }}
          style={{ marginTop: "10px" }}
          helperText={err?.emailError?.messages[0]}
          error={
            (formSettingsEmail && !email.length) ||
            (err?.emailError ? !err.emailError.isValid : false)
          }
          onChange={(e) => {
            setEmail(e.target.value);
            if (e.target.value.length) {
              const emailError = validate(e.target.value, [
                isEmail({ msg: "Email address invalid" })
              ]);
              setErr({ ...err, emailError });
            } else {
              setErr({ ...err, emailError: null });
            }
          }}
          onBlur={() => {
            setBookingState({ ...bookingState, clientEmail: email });
          }}
        />
        <TextField
          sx={{ mt: 1 }}
          label={tl("address")}
          variant="outlined"
          placeholder="Tole/Village/City-Ward no"
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">
                  <LocationOnIcon />
                </InputAdornment>
              )
            }
          }}
          value={address}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          onBlur={() => {
            setBookingState({ ...bookingState, clientAddress: address });
          }}
        />
      </Box>
    </Box>
  );
};

export default ClientSearchAndCreate;
