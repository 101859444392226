import React from "react";
import { Typography, Box, TextField, MenuItem } from "@mui/material";
import { produce } from "immer";
import { AccountTypes, BankInfoProps } from "../../../interfaces/Accounts";
import { tl } from "../../../components/translate";

const initialState = {
  accountType: "",
  bankAccountNumber: "",
  bankBranch: "",
  bankName: "",
  ifscCode: "",
  nameOfBeneficiary: "",
  swiftCode: "",
  sNo: 0
};

export default function BankInfo({
  data,
  updateBankInfo
}: {
  updateBankInfo: (data) => void;
  data: BankInfoProps[];
}): JSX.Element {
  const [bankInfo, setBankInfo] = React.useState([{ ...initialState }]);

  React.useEffect(() => {
    if (data?.length) {
      setBankInfo(data);
    }
  }, [data]);

  return (
    <>
      <Typography fontWeight="medium" mb={3}>
        Bank Information
      </Typography>
      {bankInfo.map((item, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Box key={i} margin="10px 0">
          <TextField
            data-testmation="beneficiaryName"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            sx={{ marginRight: "10px", marginBottom: "15px", width: "320px" }}
            onChange={({ target }) => {
              const newState = produce(bankInfo, (draft) => {
                draft[i].nameOfBeneficiary = target.value;
              });
              setBankInfo(newState);
              updateBankInfo(newState);
            }}
            label={tl("accounts.nameOfBeneficiary")}
            value={item?.nameOfBeneficiary}
          />
          <TextField
            data-testmation="bankName"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            sx={{ width: "320px", marginBottom: "15px" }}
            onChange={({ target }) => {
              const newState = produce(bankInfo, (draft) => {
                draft[i].bankName = target.value;
              });
              setBankInfo(newState);
              updateBankInfo(newState);
            }}
            label={tl("accounts.bankName")}
            value={item?.bankName}
          />
          <TextField
            data-testmation="bankBranch"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            sx={{ marginRight: "10px", width: "320px", marginBottom: "15px" }}
            onChange={({ target }) => {
              const newState = produce(bankInfo, (draft) => {
                draft[i].bankBranch = target.value;
              });
              setBankInfo(newState);
              updateBankInfo(newState);
            }}
            label={tl("accounts.bankBranch")}
            value={item?.bankBranch}
          />
          <TextField
            data-testmation="bankAccNumber"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            sx={{ width: "320px", marginBottom: "15px" }}
            onChange={({ target }) => {
              const newState = produce(bankInfo, (draft) => {
                draft[i].bankAccountNumber = target.value;
              });
              setBankInfo(newState);
              updateBankInfo(newState);
            }}
            label={tl("accounts.bankAccountNumber")}
            value={item?.bankAccountNumber}
          />
          <TextField
            data-testmation="accountType"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            sx={{ marginRight: "10px", width: "320px", marginBottom: "15px" }}
            onChange={({ target }) => {
              const newState = produce(bankInfo, (draft) => {
                draft[i].accountType = target.value;
              });
              setBankInfo(newState);
              updateBankInfo(newState);
            }}
            label={tl("accounts.accountType")}
            value={item?.accountType}
            select
          >
            {Object.keys(AccountTypes).map((acType) => (
              <MenuItem key={acType} value={AccountTypes[acType]}>
                {AccountTypes[acType]}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            data-testmation="isfcCode"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            sx={{ width: "320px", marginBottom: "15px" }}
            onChange={({ target }) => {
              const newState = produce(bankInfo, (draft) => {
                draft[i].ifscCode = target.value;
              });
              setBankInfo(newState);
              updateBankInfo(newState);
            }}
            label={tl("accounts.ifscCode")}
            value={item?.ifscCode}
          />
          <TextField
            data-testmation="swiftCode"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            variant="outlined"
            sx={{ marginRight: "10px", width: "320px", marginBottom: "15px" }}
            onChange={({ target }) => {
              const newState = produce(bankInfo, (draft) => {
                draft[i].swiftCode = target.value;
              });
              setBankInfo(newState);
              updateBankInfo(newState);
            }}
            label={tl("accounts.swiftCode")}
            value={item?.swiftCode}
          />
        </Box>
      ))}
    </>
  );
}
