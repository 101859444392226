import {
  Autocomplete,
  Box,
  Checkbox,
  FormControlLabel,
  TextField,
  Typography
} from "@mui/material";
import React, { JSX, useEffect, useState } from "react";
import produce from "immer";
import { tl } from "../../../components/translate";
import { spFromIdSelector, spFullNameSelector } from "../../../reducers/serviceProvider";
import { OtherSettingsProps } from "../OtherSettings";
import MedicalLabHeader from "./MedicalLabHeader";
import { notificationAdd } from "../../../actions/notification";
import { useAppDispatch } from "../../../store/hooks";
import UploadStamp from "../OtherSettings/UploadStamp";

export default function MedicalReportSettings({
  resourceCentre,
  actions,
  serviceProviders
}: OtherSettingsProps): JSX.Element {
  const { medicalSettings } = resourceCentre.settings;
  const dispatch = useAppDispatch();
  const [errorMessage, setErrorMessage] = React.useState("");

  const [medicalSp, setMedicalSp] = useState({
    authorizedSP1: null,
    authorizedSP2: null,
    authorizedSP3: null
  });

  const fileUploader = React.useRef<HTMLInputElement>(null);
  const [tempImg, setTempImg] = React.useState<string | null>(null);
  const [isCropperOpen, setIsCropperOpen] = React.useState(false);
  const [customPrintTitle, setCustomPrintTitle] = useState(
    resourceCentre.settings.medicalSettings.customPrintTitle || ""
  );

  const handleImageChange = (e) => {
    if (!e?.target?.files?.length) {
      return;
    }
    const file = e.target.files[0];
    const lastDotIndex = file.name.lastIndexOf(".");
    const extension = file.name.substring(lastDotIndex + 1);

    if (file.size >= 200000 || !["jpg", "jpeg", "png", "gif"].includes(extension.toLowerCase())) {
      // show error dialog if file size exceeds 200KB
      // eslint-disable-next-line no-param-reassign
      e.target.value = "";
      dispatch(
        notificationAdd({
          id: new Date().getUTCMilliseconds(),
          variant: "error",
          message: "File type not valid or size is greater than 200KB.",
          autoTimeout: true
        })
      );
      return;
    }

    setTempImg(URL.createObjectURL(file));
    setIsCropperOpen(true);
  };

  useEffect(() => {
    setMedicalSp({
      authorizedSP1: spFromIdSelector(serviceProviders, medicalSettings.authorizedSP1) || null,
      authorizedSP2: spFromIdSelector(serviceProviders, medicalSettings.authorizedSP2) || null,
      authorizedSP3: spFromIdSelector(serviceProviders, medicalSettings.authorizedSP3) || null
    });
  }, [medicalSettings, serviceProviders]);

  return (
    <Box sx={{ pt: "10px", pb: "20px", display: "flex", flexDirection: "column", gap: "24px" }}>
      {/* upload medical lab header */}
      <MedicalLabHeader
        fileUploaderRef={fileUploader}
        onImageChange={handleImageChange}
        isCropperOpen={isCropperOpen}
        setIsCropperOpen={setIsCropperOpen}
        tempImg={tempImg}
      />

      {/* custom print title */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Typography
          sx={{
            pb: "5px",
            fontWeight: 700,
            borderBottom: "1px solid #ededed"
          }}
        >
          {tl("medical.setting.customPrintTitle")}
        </Typography>

        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Typography sx={{ width: "500px" }}>Print Title</Typography>

          <TextField
            variant="outlined"
            slotProps={{ inputLabel: { shrink: true } }}
            fullWidth
            placeholder="Provide custom print title"
            value={customPrintTitle}
            onChange={(e) => {
              if (e.target.value.length > 50) {
                setErrorMessage("**Title can not exceed more than 50 characters");
                return;
              }
              setErrorMessage("");
              setCustomPrintTitle(e.target.value);
            }}
            onFocus={() => setErrorMessage("")}
            onBlur={() => {
              if (customPrintTitle.length <= 50) {
                const newRc = produce(resourceCentre, (draft) => {
                  draft.settings.medicalSettings.customPrintTitle = customPrintTitle;
                });
                dispatch(actions.putResourceCentre(newRc));
              }
            }}
          />
        </Box>

        {errorMessage && <Typography sx={{ color: "red", my: 2 }}>{errorMessage}</Typography>}
      </Box>

      {/* authorized sp signatures */}
      <Box sx={{ display: "flex", flexDirection: "column", gap: "8px" }}>
        <Typography sx={{ pb: "5px", fontWeight: 700, borderBottom: "1px solid #ededed" }}>
          {tl("authorisedSignatures")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", maxWidth: "52vw" }}>
          <Typography sx={{ width: "500px" }}>{tl("authorisedSignature1")}</Typography>
          <Autocomplete
            data-testmation="authorizedSP1"
            options={serviceProviders}
            getOptionLabel={(option) => spFullNameSelector(option)}
            renderOption={(props, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...props} key={option.id}>
                {spFullNameSelector(option)}
              </li>
            )}
            onChange={async (e, v) => {
              const newRc = produce(resourceCentre, (draft) => {
                draft.settings.medicalSettings.authorizedSP1 = v ? v.id : null;
              });
              dispatch(actions.putResourceCentre(newRc));
            }}
            value={medicalSp.authorizedSP1}
            style={{ width: "100%" }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                slotProps={{ inputLabel: { shrink: true } }}
              />
            )}
          />
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", maxWidth: "50vw" }}>
          <Typography sx={{ width: "500px" }}>{tl("authorisedSignature2")}</Typography>
          <Autocomplete
            options={serviceProviders}
            getOptionLabel={(option) => spFullNameSelector(option)}
            renderOption={(props, option) => (
              // eslint-disable-next-line react/jsx-props-no-spreading
              <li {...props} key={option.id}>
                {spFullNameSelector(option)}
              </li>
            )}
            style={{ width: "100%" }}
            onChange={async (e, v) => {
              const newRc = produce(resourceCentre, (draft) => {
                draft.settings.medicalSettings.authorizedSP2 = v ? v.id : null;
              });
              dispatch(actions.putResourceCentre(newRc));
            }}
            value={medicalSp.authorizedSP2}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                slotProps={{ inputLabel: { shrink: true } }}
              />
            )}
          />
        </Box>
      </Box>

      {/* report options */}
      <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
        <Typography sx={{ pb: "5px", fontWeight: 700, borderBottom: "1px solid #ededed" }}>
          {tl("medical.setting.reportOptions")}
        </Typography>
        <FormControlLabel
          sx={{ marginTop: "5px" }}
          control={
            <Checkbox
              size="small"
              checked={resourceCentre.settings.medicalSettings.includeLetterhead}
              onChange={() => {
                const newRc = produce(resourceCentre, (draft) => {
                  draft.settings.medicalSettings.includeLetterhead =
                    !resourceCentre.settings.medicalSettings.includeLetterhead;
                });
                dispatch(actions.putResourceCentre(newRc));
              }}
            />
          }
          label={tl("clinicSettings.includeLetterheadInMedicalReport")}
        />

        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={resourceCentre.settings.medicalSettings.showBarCode}
              onChange={() => {
                const newRc = produce(resourceCentre, (draft) => {
                  draft.settings.medicalSettings.showBarCode =
                    !resourceCentre.settings.medicalSettings.showBarCode;
                });
                dispatch(actions.putResourceCentre(newRc));
              }}
            />
          }
          label={tl("clinicSettings.showBarCodeInMedicalReport")}
        />

        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={resourceCentre.settings.medicalSettings.showQrCode}
              onChange={() => {
                const newRc = produce(resourceCentre, (draft) => {
                  draft.settings.medicalSettings.showQrCode =
                    !resourceCentre.settings.medicalSettings.showQrCode;
                });
                dispatch(actions.putResourceCentre(newRc));
              }}
            />
          }
          label={tl("clinicSettings.showQRCodeInMedicalReport")}
        />

        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={resourceCentre.settings.medicalSettings.showReferrer}
              onChange={() => {
                const newRc = produce(resourceCentre, (draft) => {
                  draft.settings.medicalSettings.showReferrer =
                    !resourceCentre.settings.medicalSettings.showReferrer;
                });
                dispatch(actions.putResourceCentre(newRc));
              }}
            />
          }
          label={tl("clinicSettings.showReferrerInMedicalReport")}
        />
      </Box>

      {/* upload stamp */}
      <UploadStamp />
    </Box>
  );
}
