import React from "react";
import { Box } from "@mui/material";
import Panel from "../../../../components/Panel";
import { useAppSelector } from "../../../../store/hooks";
import IpdSettings from "../IpdSettings";

interface Props {
  onClose: () => void;
}

const IpdSettingsPanel = ({ onClose }: Props): JSX.Element => {
  const resourceCentre = useAppSelector((state) => state.resources.resourceCentres[0]);
  return (
    <Panel onClose={onClose} title="IPD Settings">
      <Box px={4}>
        <IpdSettings resourceCentre={resourceCentre} />
      </Box>
    </Panel>
  );
};

export default IpdSettingsPanel;
