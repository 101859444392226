import {
  Autocomplete,
  Box,
  Button,
  Divider,
  Grid2 as Grid,
  MenuItem,
  TextField
} from "@mui/material";
import Typography from "@mui/material/Typography";
import PhoneNumber from "awesome-phonenumber";
import { pickBy, startCase } from "lodash";
import * as moment from "moment-timezone";
import React, { useState } from "react";
import { Controller, FieldValues, SubmitHandler, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  convertADtoBS,
  convertBStoAD
} from "../../../components/Calendar/functions/calendarFunctions";
import FormTextField from "../../../components/FormTextField/FormTextField";
import MaterialUiPhoneNumber from "../../../components/PhoneNumber";
import ToggleInputDate from "../../../components/ToggleADBS";
import { t, tl } from "../../../components/translate";
import { isNewPhoneNumber, isStartWith970 } from "../../../helpers/phoneNumber";
import {
  isADDate,
  isBSDate,
  isEmail,
  isISODateString,
  validate
} from "../../../helpers/validators";
import { ChildGeneralLedger, TransactionType } from "../../../interfaces/Accounts";
import { Gender } from "../../../interfaces/ClientInterface";
import { ServiceProvider } from "../../../interfaces/ServiceProvidersInterface";
import { places } from "../../../models/ResourceCentre";
import { RootState } from "../../../store";
import ServiceTagsAutocomplete from "./ServiceTagsAutocomplete";
import Signature from "./Signature";
import SpecialitySearch from "./SpecialitySearch";
import UserGroupSelect from "./UserGroupSelect";
import { UserGroupTypes } from "../../../interfaces/User";
import useGetDepartments from "../../../hooks/department";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import ClientImageUploader from "../../../components/ImageUploader/ClientImageUploader";

interface SPCreateEditProps {
  spLedger: ChildGeneralLedger;
  isAccountSubscribed: boolean;
  serviceProvider: Partial<ServiceProvider> | undefined;
  onSave: (data: Partial<ServiceProvider>) => void;
  onCancel: () => void;
  createSPMode: boolean;
  resourceCentre: ResourceCentre;
}

export enum UserGroups {
  ServiceProviderFull = "serviceProviderFull",
  ServiceProviderLabTechnician = "serviceProviderLabTechnician",
  Pharmacist = "Pharmacist"
}

export enum DefaultServiceProviders {
  ServiceProviderFull = "serviceProviderFull",
  ServiceProviderLimited = "serviceProviderLimited",
  ServiceProviderLabTechnician = "serviceProviderLabTechnician"
}

export const ALL_DEPARTMENT = "all";

function SPCreateEdit({
  spLedger,
  isAccountSubscribed,
  serviceProvider,
  onSave,
  onCancel,
  createSPMode,
  resourceCentre
}: SPCreateEditProps): JSX.Element {
  const departments = useGetDepartments({
    showDeletedDepartments: false,
    resourceCentreId: resourceCentre.id
  });
  const resourceCentrePermissionGroups =
    useSelector((state: RootState) => state.resourceCentreUserGroups.collection) || [];

  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors }
  } = useForm<FieldValues>({
    defaultValues: serviceProvider || {
      title: "",
      firstName: "",
      lastName: "",
      gender: "1",
      phone: "",
      address: "",
      city: "",
      registrationNumber: "",
      specialities: [],
      speciality: "",
      serviceTagIds: [],
      email: "",
      qualification: "",
      details: "",
      tds: "",
      departmentId: ALL_DEPARTMENT,
      designation: "",
      userGroup: "",
      ...(isAccountSubscribed && {
        ledgerId: spLedger ? spLedger.id : "",
        openingBalance: "",
        transactionType: "",
        openingBalanceDate: ""
      }),
      profileImage: ""
    },
    mode: "onChange"
  });
  const [signatureData, setSignatureData] = useState({ hasUpdatedSignature: false, signature: "" });

  const { openingBalanceDate, userGroup, departmentId, profileImage } = watch();

  const options = resourceCentrePermissionGroups
    .filter((group) => group.userGroupType === UserGroupTypes.serviceProvider)
    .filter((group) => (departmentId === ALL_DEPARTMENT ? !group.isDepartment : group.isDepartment))
    .sort((a, b) => a.id - b.id)
    .map((group) => ({
      value: group.name,
      label: startCase(group.name),
      description: group.description || "",
      isDepartmentGroup: group.isDepartment || false
    }));

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    const nullableValues = {
      designation: data.designation,
      address: data.address,
      email: data.email,
      speciality: data.speciality,
      departmentId: data.departmentId
    };
    onSave({ ...pickBy({ ...data, ...signatureData }), ...nullableValues });
  };

  const validateEmail = (value: string) => {
    const { isValid, messages } = validate(value, [isEmail({ msg: "Email address invalid" })]);
    return isValid || messages[0];
  };

  const isValidPhone = (value: string) => {
    const ph = new PhoneNumber(value);
    const phoneNumber = ph.getNumber("significant");
    if (ph.getCountryCode() === 977 && isStartWith970(value)) {
      return isNewPhoneNumber(phoneNumber);
    }
    return ph.isValid() && ph.isMobile();
  };

  const departmentSubscription =
    resourceCentre?.subscriptions.features.department?.subscribed || false;

  const [selectedUserGroupOption, setSelectedUSerGroupOption] = React.useState(null);
  React.useEffect(() => {
    const selectedUserGroup = options.find((option) => option.value === userGroup);
    if (selectedUserGroup) {
      setSelectedUSerGroupOption(selectedUserGroup);
    }
  }, [userGroup]);

  React.useEffect(() => {
    if (serviceProvider) {
      setValue("profileImage", serviceProvider.serviceProviderDetails?.profileImage);
    }
  }, [serviceProvider]);

  return (
    <>
      <Grid container columnSpacing={2} rowSpacing={1} mt={1}>
        <Grid size={12}>
          <Box display="flex" justifyContent="center">
            <Controller
              name="profileImage"
              control={control}
              render={({ field }) => (
                <ClientImageUploader
                  action={(image) => {
                    field.onChange(image);
                  }}
                  initialImage={
                    typeof profileImage === "string"
                      ? profileImage
                      : (profileImage && URL.createObjectURL(profileImage)) || ""
                  }
                />
              )}
            />
          </Box>
        </Grid>
        {departmentSubscription && (
          <Grid size={12}>
            <Box display="flex" alignItems="center">
              <Controller
                name="departmentId"
                control={control}
                render={({ field }) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    fullWidth
                    label={t("department")}
                    select
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                    variant="outlined"
                    error={!!errors?.departmentId}
                    onChange={({ target }) => {
                      // case where user selects all then selects specific department and vice versa
                      if (typeof departmentId !== typeof target.value) {
                        setValue("userGroup", "");
                        setSelectedUSerGroupOption(null);
                      }
                      field.onChange(target.value);
                    }}
                  >
                    {[
                      ...[{ id: ALL_DEPARTMENT, name: startCase(ALL_DEPARTMENT) }],
                      ...departments
                    ].map((dept) => (
                      <MenuItem value={dept.id} key={dept.id}>
                        {startCase(dept.name)}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Box>
          </Grid>
        )}
        <Grid size={12}>
          <UserGroupSelect errors={errors} control={control} options={options} value={userGroup} />
          {selectedUserGroupOption && (
            <Box display="flex" alignItems="center">
              <Typography
                sx={{
                  paddingBottom: "5px",
                  fontSize: "14px"
                }}
              >
                {selectedUserGroupOption.description}
              </Typography>
            </Box>
          )}
        </Grid>
        <Grid size={{ xs: 12, sm: 3 }}>
          <FormTextField
            dataTestmation="title"
            placeholder={t("TitleEnter")}
            name="title"
            control={control}
            label={t("Title")}
            errors={errors}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 4.5 }}>
          <FormTextField
            dataTestmation="firstName"
            placeholder={t("EnterFName")}
            name="firstName"
            control={control}
            label={t("FName")}
            errors={errors}
            fullWidth
            rules={{ required: { value: true, message: "First name is required" } }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 4.5 }}>
          <FormTextField
            dataTestmation="lastName"
            placeholder={t("EnterLName")}
            name="lastName"
            control={control}
            label={t("LName")}
            errors={errors}
            fullWidth
            rules={{ required: { value: true, message: "Last name is required" } }}
          />
        </Grid>

        <Grid size={{ xs: 12, sm: 4 }}>
          <FormTextField
            dataTestmation="registrationNumber"
            placeholder="eg. NMC No: 123 or NHPC No: A01EMDE"
            name="registrationNumber"
            control={control}
            label={t("RegNo.")}
            errors={errors}
            fullWidth
            rules={{ required: { value: true, message: "Registration number is required" } }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 4 }}>
          <FormTextField
            dataTestmation="gender"
            placeholder={t("SelectGender")}
            name="gender"
            control={control}
            label={t("Gender")}
            errors={errors}
            fullWidth
            select
            options={[
              { value: Gender.Male, label: "Male" },
              { value: Gender.Female, label: "Female" },
              { value: Gender.Other, label: "Other" }
            ]}
            rules={{ required: { value: true, message: "Gender is required" } }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Controller
            name="phone"
            control={control}
            rules={{
              required: `${t("MobNo.Req")}`,
              validate: (value) => (value ? isValidPhone(value) : true) || `${t("MobNo.Val")}`
            }}
            render={({ field: { onChange, onBlur, value } }) => (
              <MaterialUiPhoneNumber
                data-testmation="phone"
                placeholder="Eg: +9779807100001"
                onChange={onChange}
                value={value}
                onBlur={onBlur}
                fullWidth
                disableAreaCodes
                variant="outlined"
                defaultCountry="np"
                errorMessages={errors?.phone}
                error={!!errors?.phone}
                label={t("MobNo.")}
                helperText={errors?.phone?.message || " "}
              />
            )}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormTextField
            dataTestmation="email"
            name="email"
            control={control}
            label={t("email")}
            errors={errors}
            fullWidth
            placeholder="Eg: email@domain.com"
            rules={{
              validate: (value) => !value || validateEmail(value)
            }}
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormTextField
            dataTestmation="address"
            placeholder={t("AddressPlc")}
            name="address"
            control={control}
            label={t("Address")}
            errors={errors}
            fullWidth
          />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <Controller
            name="city"
            control={control}
            render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
              <Autocomplete
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...rest}
                options={places.map((item) =>
                  item.city === item.district ? item.city : `${item.city}, ${item.district}`
                )}
                getOptionLabel={(option) => option}
                onChange={(_, value) => onChange(value)}
                renderInput={(params) => (
                  <TextField
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...params}
                    slotProps={{
                      inputLabel: { shrink: true }
                    }}
                    placeholder={t("CityPlc")}
                    variant="outlined"
                    label={t("City")}
                    margin="normal"
                    error={!!error}
                    helperText={error?.message || " "}
                    sx={{ my: 0 }}
                  />
                )}
              />
            )}
          />
        </Grid>
        <Grid size={12}>
          <ServiceTagsAutocomplete control={control} name="serviceTagIds" />
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          {userGroup === UserGroups.ServiceProviderFull ? (
            <SpecialitySearch control={control} name="specialities" />
          ) : (
            <FormTextField
              dataTestmation="speciality"
              placeholder={t("Speciality")}
              name="speciality"
              control={control}
              label={t("Speciality")}
              errors={errors}
              fullWidth
              rules={{
                validate: {
                  required: (value) => {
                    if (userGroup === DefaultServiceProviders.ServiceProviderLimited) {
                      return true;
                    }
                    return !value ? "Speciality is required" : true;
                  }
                }
              }}
            />
          )}
        </Grid>
        <Grid size={{ xs: 12, sm: 6 }}>
          <FormTextField
            dataTestmation="qualification"
            placeholder="Eg: MD, MBBS..."
            name="qualification"
            control={control}
            label={t("Qualification")}
            errors={errors}
            fullWidth
            rules={{ required: { value: true, message: "Qualification is required" } }}
          />
        </Grid>
        <Grid size={12}>
          <FormTextField
            dataTestmation="tds"
            placeholder={t("TDS%Plc")}
            name="tds"
            control={control}
            label={t("TDS%")}
            errors={errors}
            fullWidth
          />
        </Grid>
        <Grid size={12}>
          <FormTextField
            multiline
            dataTestmation="bio"
            placeholder={t("DetailsPlc")}
            name="details"
            control={control}
            label={t("Details")}
            errors={errors}
            fullWidth
          />
        </Grid>
      </Grid>
      {isAccountSubscribed && (
        <>
          <Divider />
          <Grid container columnSpacing={2} rowSpacing={1} mt={1}>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormTextField
                dataTestmation="transactionType"
                placeholder={t("transactionTypePlc")}
                name="transactionType"
                control={control}
                label={t("transactionType")}
                errors={errors}
                fullWidth
                select
                options={[
                  { value: TransactionType.DEBIT, label: startCase(TransactionType.DEBIT) },
                  { value: TransactionType.CREDIT, label: startCase(TransactionType.CREDIT) }
                ]}
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }}>
              <FormTextField
                dataTestmation="openingBalance"
                placeholder={t("OpeningBalancePlc")}
                name="openingBalance"
                control={control}
                label={t("OpeningBalance")}
                errors={errors}
                fullWidth
              />
            </Grid>
            <Grid size={{ xs: 12, sm: 6 }} mt={-1}>
              <Controller
                name="openingBalanceDate"
                control={control}
                rules={{
                  validate: (value) =>
                    (value ? !!isISODateString(value) : true) || "Please enter valid date"
                }}
                render={({ field: { onChange, ...rest }, fieldState: { error } }) => (
                  <ToggleInputDate
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...rest}
                    key="openingBalanceDate"
                    field={{
                      value: openingBalanceDate,
                      key: "openingBalanceDate",
                      label: `${t("OpeningBalanceDate")}`,
                      inputType: "bsDate",
                      editable: true,
                      creatable: true,
                      required: false,
                      showAgeField: false,
                      formatter: (
                        dateString: Date | moment.Moment | string
                      ): Date | moment.Moment | string => {
                        if (!dateString) return dateString;
                        const isValid = moment(dateString).toISOString() === dateString;
                        if (!isValid) return dateString;
                        return convertADtoBS(moment.utc(dateString)).formatted4;
                      },
                      reverseFormatter: (
                        dateString: Date | moment.Moment | string
                      ): Date | moment.Moment | string | null => {
                        if (!dateString) return null;
                        const isValid = isBSDate({ msg: "" })(dateString);
                        if (!dateString || !isValid) return dateString;
                        const [year, month, date] = dateString.split("-");
                        return moment
                          .utc(
                            // when we have a date string without timezone, it is better to force them at +0 UTC
                            moment(convertBStoAD(Number(year), Number(month), Number(date))).format(
                              "YYYYMMDD"
                            )
                          )
                          .toISOString();
                      },
                      ADFormatter: (
                        dateString: Date | moment.Moment | string
                      ): Date | moment.Moment | string | null => {
                        if (!dateString) return null;
                        const isValid = isADDate({ msg: "" })(dateString);
                        if (!dateString || !isValid) return dateString;
                        return moment.utc(dateString).toISOString();
                      }
                    }}
                    data={{ openingBalanceDate }}
                    changeDate={(value) => onChange(value)}
                    showAgeField={false}
                    error={!!error}
                    helperText={error?.message || " "}
                  />
                )}
              />
            </Grid>
            {spLedger && (
              <Grid size={{ xs: 12, sm: 6 }}>
                <FormTextField
                  dataTestmation="ledger"
                  placeholder={t("ledger")}
                  name="ledgerId"
                  control={control}
                  label={t("ledger")}
                  errors={errors}
                  rules={{ required: { value: true, message: "Ledger is required" } }}
                  fullWidth
                  select
                  options={[{ value: spLedger.id, label: spLedger.ledgerName }]}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Divider />
      <Grid container columnSpacing={2} rowSpacing={1} mt={1}>
        <Grid size={12}>
          <Signature signature={serviceProvider?.signature} setSignatureData={setSignatureData} />
        </Grid>
        <Grid size={12}>
          <Typography sx={{ fontWeight: 600 }}>{tl("signature.designation")} </Typography>
          <Typography sx={{ textDecoration: "underline", fontSize: "0.75rem", mb: 2 }}>
            {tl("signature.designationNote")}
          </Typography>
          <FormTextField
            dataTestmation="designation"
            placeholder={t("signature.designation")}
            name="designation"
            control={control}
            label={t("Designation")}
            errors={errors}
            multiline
          />
        </Grid>
      </Grid>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          right: 0,
          padding: "1rem 2rem",
          display: "flex",
          gap: 4
        }}
      >
        <Button onClick={onCancel}>{tl("cancel")}</Button>
        <Button
          data-testmation="spCreate"
          color="primary"
          variant="contained"
          type="submit"
          onClick={handleSubmit(onSubmit)}
        >
          {createSPMode ? tl("serviceProvider.create") : tl("serviceProvider.save")}
        </Button>
      </Box>
    </>
  );
}

export default SPCreateEdit;
