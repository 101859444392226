import * as React from "react";
import { TextField } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import { t } from "../../components/translate";
import { DocumentTypes } from "../../interfaces/BillInterfaces";

// eslint-disable-next-line max-len
// eslint-disable-next-line import/prefer-default-export, @typescript-eslint/explicit-module-boundary-types
export const BillTypeAutoSelect = ({ billType, onChange, variant = "standard" }) => (
  <Autocomplete
    value={billType}
    options={[DocumentTypes.INVOICE, DocumentTypes.CREDITNOTE]}
    getOptionLabel={(option) => t(`billing.${option}`)}
    onChange={(e, value) => {
      onChange(value);
    }}
    renderInput={(params) => (
      <TextField
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...params}
        variant={variant}
        margin="dense"
        label={t("billType")}
        placeholder={t("reports.billType")}
        fullWidth
      />
    )}
  />
);
