import { push } from "connected-react-router";
import { Dispatch } from "redux";
import * as api from "../api/authentication";
import { saveAuthData, removeAuthData } from "../auth/authentication";
import * as NotificationActions from "./notification";
import { tl } from "../components/translate";
import { actionCreatorAsync } from "./actionHelpers";
import { getAccount } from "./user";
import localStorage from "../helpers/localStorage";
import sessionStorage from "../helpers/sessionStorage";
import { logIn, logOut } from "../slices/authenticateSlice";
import gaEvents from "../google-analytics/gaEvents";
import mixpanelAnalytics from "../mixpanel-analytics/mixpanelAnalytics";
import EVENT from "../mixpanel-analytics/event";
import { RootState, store } from "../store";
import { getGenderString } from "../containers/Campaign/Audience/AudienceHelpers";
import { clearQrImage, updateTwoFactorInfo } from "../slices/twoFactorInfoSlice";
import { navigateRemoveModal } from "./navigation";
import { PermissionGroups } from "../interfaces/User";

export enum Type {
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  GOTO_LOGIN = "GOTO_LOGIN",
  FB_LOGIN = "FB_LOGIN"
}

// Helper for firing analytic login events.
function trackAnalyticLoginEvent(loginType: Type): void {
  // GA event.
  gaEvents.appLogin();

  const { resourceCentre, user } = store.getState().userContext || {};

  // Mixpanel event.
  mixpanelAnalytics.identify(String(user?.id));
  mixpanelAnalytics.people.set({
    $first_name: user?.firstName || "",
    $last_name: user?.lastName || "",
    resource_centre: resourceCentre?.name || "",
    gender: getGenderString(user?.gender)
  });

  if (loginType === Type.LOGIN) {
    mixpanelAnalytics.track(EVENT.LOGIN_SUCCESS);
  }

  if (loginType === Type.FB_LOGIN) {
    mixpanelAnalytics.track(EVENT.LOGIN_FB_SUCCESS);
  }
}

export function logout() {
  return (dispatch: Dispatch): void => {
    removeAuthData();
    sessionStorage.removeItem("spID");
    localStorage.removeItem("billCounter");
    dispatch(logOut());
    dispatch(
      NotificationActions.notificationAdd({
        id: new Date().getUTCMilliseconds(),
        variant: "success",
        message: tl("main.loggedOut!"),
        autoTimeout: true
      })
    );
  };
}

export async function loginAsAdminImpersonater(rcId: number): Promise<void> {
  const response = await api.adminImpoersonate(rcId);
  if (response) {
    saveAuthData(response, sessionStorage);
  }
}

export function login(credentials: {
  email: string;
  password: string;
  token: string;
  isFromClinicsApp?: boolean;
  expoPushToken?: string;
}): Promise<void> {
  return async (dispatch: Dispatch, getState: () => RootState): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.LOGIN, async () => {
        const response = await api.login({
          username: credentials.email,
          ...credentials
        });
        if (response?.required2FAToken) {
          dispatch(updateTwoFactorInfo(response));
          return null;
        }
        if (response) {
          const twoFAInfo = getState().twoFactorAuthInfo;
          if (twoFAInfo?.imageUrl && twoFAInfo?.required2FAToken) {
            dispatch(navigateRemoveModal("ChangePassword"));
          }
          saveAuthData(response);
          await dispatch(getAccount());
          const userGroup = getState().userContext.userCreds.userGroups[0];
          dispatch(logIn());
          if (userGroup === PermissionGroups.SuperAdmin) {
            dispatch(push("/resourcecentres"));
          } else if (userGroup === PermissionGroups.PublicBookingAdmin) {
            dispatch(push("/public/verificationCodes"));
          } else {
            dispatch(push("/dashboard"));
          }
          if (twoFAInfo?.imageUrl) dispatch(clearQrImage());
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: tl("main.loggedIn!"),
              autoTimeout: true
            })
          );
          // Fire login event.
          trackAnalyticLoginEvent(Type.LOGIN);
        } else {
          mixpanelAnalytics.track(EVENT.LOGIN_FAILED);
        }
        return response;
      })
    );
  };
}

export function loginViaFacebook(credentials: Record<string, unknown>) {
  return async (dispatch: Dispatch): Promise<void> => {
    await dispatch(
      actionCreatorAsync(Type.FB_LOGIN, async () => {
        const response = await api.loginViaFacebook({
          token: credentials.token,
          userId: credentials.userId,
          ...credentials
        });
        if (response) {
          saveAuthData(response);
          await dispatch(getAccount());
          dispatch(logIn());
          dispatch(push("/dashboard"));
          dispatch(
            NotificationActions.notificationAdd({
              id: new Date().getUTCMilliseconds(),
              variant: "success",
              message: tl("main.loggedIn!"),
              autoTimeout: true
            })
          );

          // Fire login event.
          trackAnalyticLoginEvent(Type.FB_LOGIN);
        } else {
          mixpanelAnalytics.track(EVENT.LOGIN_FB_FAILED);
        }
        localStorage.removeItem("FacebookResponse");
        return response;
      })
    );
  };
}
