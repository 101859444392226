import React, { JSX, useEffect, useState } from "react";
import moment from "moment";
import { Autocomplete, Box, IconButton, TextField, Tooltip, Typography } from "@mui/material";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import FullscreenExitIcon from "@mui/icons-material/FullscreenExit";
import InfoIcon from "@mui/icons-material/Info";
import Panel from "../../components/Panel";
import { Assessment } from "../../interfaces/AssessmentInterfaces";
import { convertADtoBS } from "../../components/Calendar/functions/calendarFunctions";
import { getAssessments } from "../../api/assessment";
import { useAppDispatch } from "../../store/hooks";
import { notificationAdd as addNotification } from "../../actions/notification";
import { errorFetchMessage, MODULE } from "../../helpers/messages";
import { getOpdsToCompare, isValidImageFormat } from "./AssessmentForm/Compare";

interface Props {
  onClose: () => void;
  data: Assessment;
}

interface AssessmentSelectorProps {
  label: string;
  options: Assessment[];
  onSelectAssessment?: (assessment?: Assessment) => void;
  onSelectImage: (imageLink: { downloadLink: string; fileName: string }) => void;
  selectedAssessment?: Assessment;
  selectedImage: { downloadLink: string; fileName: string };
  isForCurrentAssessment?: boolean;
}

export const AssessmentSelector: React.FC<AssessmentSelectorProps> = ({
  label,
  options,
  onSelectAssessment,
  onSelectImage,
  selectedAssessment,
  selectedImage,
  isForCurrentAssessment = false
}): JSX.Element => (
  <Box
    sx={{ display: "flex", flexDirection: "column", gap: 2, alignItems: "center", width: "100%" }}
  >
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: 2,
        flexGrow: 1,
        padding: "8px",
        border: "1px solid #eee",
        borderRadius: "4px",
        pt: 2
      }}
    >
      <Autocomplete
        disablePortal
        disableClearable
        options={options}
        value={selectedAssessment}
        disabled={isForCurrentAssessment}
        getOptionLabel={(item) => convertADtoBS(moment(item.createdDate)).formatted5}
        onChange={(_, value) => {
          onSelectImage({ downloadLink: "", fileName: "" });
          if (onSelectAssessment) {
            onSelectAssessment(value);
          }
        }}
        sx={{ width: 300 }}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            label={label}
            InputLabelProps={{ shrink: true }}
          />
        )}
      />
      {selectedAssessment?.attachments && (
        <>
          <ArrowDownwardIcon />
          <Autocomplete
            disablePortal
            disableClearable
            value={selectedImage}
            sx={{ width: 300 }}
            options={selectedAssessment.attachments.filter((item) =>
              isValidImageFormat(item.fileName)
            )}
            getOptionLabel={(item) => item.fileName}
            onChange={(_, value) => {
              onSelectImage({
                fileName: value.fileName,
                downloadLink: value.downloadLink
              });
            }}
            renderInput={(params) => (
              <TextField
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...params}
                variant="outlined"
                label="Select Image"
                InputLabelProps={{ shrink: true }}
              />
            )}
          />
        </>
      )}
      {selectedImage.downloadLink && (
        <>
          <ArrowDownwardIcon />
          <img
            src={selectedImage.downloadLink}
            alt=""
            style={{ width: "100%", border: "2px solid #aaa", borderRadius: "4px" }}
          />
        </>
      )}
    </Box>
    <Typography>{isForCurrentAssessment ? "After" : "Before"}</Typography>
  </Box>
);

const ComparePanel = ({ onClose, data }: Props): JSX.Element => {
  const [assessmentsWithAttachments, setAssessmentsWithAttachments] = useState<Assessment[]>([]);
  const [assessmentToCompare, setAssessmentToCompare] = useState<Assessment>();
  const [image, setImage] = useState({
    current: { fileName: "", downloadLink: "" },
    compareWith: { fileName: "", downloadLink: "" }
  });
  const [isFullScreenView, setIsFullScreenView] = useState(false);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setImage({
      ...image,
      current: {
        fileName: data.attachments[0].fileName,
        downloadLink: data.attachments[0].downloadLink
      }
    });
    (async () => {
      try {
        const res = await getAssessments({ clientId: data.clientId, limit: 10 });
        const opds = getOpdsToCompare(data, res);
        setAssessmentsWithAttachments(opds);
        if (opds.length) {
          setAssessmentToCompare(opds[0]);
          setImage((prevState) => ({
            ...prevState,
            compareWith: {
              fileName: opds[0].attachments[0].fileName,
              downloadLink: opds[0].attachments[0].downloadLink
            }
          }));
        }
      } catch (e) {
        dispatch(
          addNotification({
            id: new Date().getUTCMilliseconds(),
            variant: "error",
            message: errorFetchMessage(MODULE.ASSESSMENT),
            autoTimeout: true
          })
        );
      }
    })();
  }, []);

  return (
    <Panel
      onClose={onClose}
      title="Compare assessment image"
      genericButton={
        <Tooltip arrow title="Full screen view">
          <IconButton onClick={() => setIsFullScreenView(!isFullScreenView)}>
            {isFullScreenView ? <FullscreenExitIcon /> : <FullscreenIcon />}
          </IconButton>
        </Tooltip>
      }
      wrapperStyle={{ maxWidth: isFullScreenView ? "100vw" : "960px", width: "100%" }}
    >
      <Box px={4} pb={4} pt={2}>
        {!assessmentToCompare ? (
          <Box display="flex" gap={1}>
            <InfoIcon color="info" />
            <Typography>There are no previous assessments with attachments to compare.</Typography>
          </Box>
        ) : (
          <Box display="flex" gap={1}>
            <AssessmentSelector
              label="Select Assessment By Date To Compare"
              options={assessmentsWithAttachments}
              onSelectAssessment={setAssessmentToCompare}
              onSelectImage={(link) =>
                setImage({
                  ...image,
                  compareWith: { fileName: link.fileName, downloadLink: link.downloadLink }
                })
              }
              selectedAssessment={assessmentToCompare}
              selectedImage={image.compareWith}
            />
            <CompareArrowsIcon sx={{ alignSelf: "center" }} />
            <AssessmentSelector
              isForCurrentAssessment
              label="Current Assessment"
              options={[data]}
              onSelectImage={(link) =>
                setImage({
                  ...image,
                  current: { fileName: link.fileName, downloadLink: link.downloadLink }
                })
              }
              selectedAssessment={data}
              selectedImage={image.current}
            />
          </Box>
        )}
      </Box>
    </Panel>
  );
};

export default ComparePanel;
