import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import SASubscriptions from "../ResourceCentre/SASubscriptions/index";
import { resourceCentreActions } from "../../actions";
import { RootState } from "../../store";
import { ResourceCentre } from "../../interfaces/ResourceCentreInterface";

export function ResourceCentreAutoComplete({
  setSelectedRc
}: {
  setSelectedRc: React.Dispatch<ResourceCentre>;
}): JSX.Element {
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(resourceCentreActions.getResourceCentres());
  }, [dispatch]);

  const resourceCentres = useSelector((state: RootState) => state.resources.resourceCentres);

  return (
    <Autocomplete
      disablePortal
      id="combo-box-demo"
      options={resourceCentres as Array<ResourceCentre>}
      sx={{ width: "300px" }}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      fullWidth
      renderOption={(props, option) => (
        //   eslint-disable-next-line react/jsx-props-no-spreading
        <Box component="li" {...props} key={option.id}>
          [{option.id}] {option.name}
        </Box>
      )}
      getOptionLabel={(option) => `${option.name} [${option.id}]`}
      onChange={(_, v) => {
        setSelectedRc(v);
      }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          placeholder="Search ResourceCentre..."
          label="Select ResourceCentre"
        />
      )}
    />
  );
}

export default function Subscriptions(): JSX.Element {
  const [selectedRc, setSelectedRc] = React.useState(null);

  return (
    <Box p={4}>
      <ResourceCentreAutoComplete setSelectedRc={setSelectedRc} />
      {selectedRc && (
        <>
          <Typography component="span">{selectedRc.address}</Typography>
          <SASubscriptions resourceCentre={selectedRc} />
        </>
      )}
    </Box>
  );
}
