import * as React from "react";
import Chip from "@mui/material/Chip";
import { Box } from "@mui/material";
import startCase from "lodash/startCase";
import styles from "./Status.module.css";
import { BookingStatus } from "../../containers/Bookings/ManageBooking";

interface StatusProps {
  selected: string;
  statuses: Array<{
    label: string;
    color: string;
    icon?: any;
  }>;
  selectable: boolean;
  hideLabel: boolean;
}

const Status: React.FC<StatusProps> = ({ selected = null, statuses = [], hideLabel = false }) => {
  const selectedStatus = statuses.find((status) => status.label === selected);
  if (!selectedStatus) {
    return null;
  }
  const Icon = selectedStatus.icon;
  const isReservedStatus = selected === BookingStatus.reserved;

  return (
    <Box component="span">
      {!hideLabel && <span className={styles.statusLabel}>Status</span>}
      <Chip
        size="small"
        style={{
          backgroundColor: selectedStatus.color,
          color: `${isReservedStatus ? "#171717" : "#FFFFFF"}`
        }}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...(Icon ? { icon: <Icon style={{ color: "#fff" }} /> } : {})}
        label={startCase(selected || "")}
        sx={{ border: `${isReservedStatus ? "1px solid black" : ""}` }}
      />
    </Box>
  );
};

export default Status;
