import * as React from "react";
import { connect } from "react-redux";
import { TextField, Button, Box } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { updatePassword } from "../../../actions/user";
import styles from "./style.module.css";
import { tl, t } from "../../../components/translate";

const ChangePassword = ({ onSave, onCancel }) => {
  const [form, updateForm] = React.useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: ""
  });
  const isDisabled = () => {
    const { oldPassword, newPassword, confirmPassword } = form;
    return !(oldPassword && newPassword && confirmPassword && newPassword === confirmPassword);
  };

  const [passwordVisibility, setPasswordVisibility] = React.useState({
    old: false,
    new: false,
    repeatNew: false
  });

  const passwordVisibilityToggler = (type) =>
    setPasswordVisibility({ ...passwordVisibility, [type]: !passwordVisibility[type] });

  return (
    <Box>
      <Box>
        <TextField
          label={t("account.oldPassword")}
          value={form.oldPassword}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              onSave(form);
              updateForm({ oldPassword: "", newPassword: "", confirmPassword: "" });
            }
          }}
          onChange={(e) => updateForm({ ...form, oldPassword: e.target.value })}
          margin="dense"
          type={passwordVisibility.old ? "text" : "password"}
          variant="outlined"
          data-testmation="oldPassword"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="start">
                  {passwordVisibility.old ? (
                    <VisibilityIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => passwordVisibilityToggler("old")}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => passwordVisibilityToggler("old")}
                    />
                  )}
                </InputAdornment>
              )
            }
          }}
        />
      </Box>
      <Box>
        <TextField
          autoComplete="new-password"
          data-testmation="newPassword"
          label={t("account.newPassword")}
          value={form.newPassword}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              onSave(form);
              updateForm({ oldPassword: "", newPassword: "", confirmPassword: "" });
            }
          }}
          onChange={(e) => updateForm({ ...form, newPassword: e.target.value })}
          margin="dense"
          type={passwordVisibility.new ? "text" : "password"}
          variant="outlined"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="start">
                  {passwordVisibility.new ? (
                    <VisibilityIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => passwordVisibilityToggler("new")}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => passwordVisibilityToggler("new")}
                    />
                  )}
                </InputAdornment>
              )
            }
          }}
        />
      </Box>
      <Box>
        <TextField
          autoComplete="new-password"
          data-testmation="repeatNewPassword"
          label={t("account.repeatNewPassword")}
          value={form.confirmPassword}
          onKeyDown={(e) => {
            if (e.keyCode === 13) {
              onSave(form);
              updateForm({ oldPassword: "", newPassword: "", confirmPassword: "" });
            }
          }}
          onChange={(e) => {
            updateForm({ ...form, confirmPassword: e.target.value });
          }}
          margin="dense"
          type={passwordVisibility.repeatNew ? "text" : "password"}
          variant="outlined"
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="start">
                  {passwordVisibility.repeatNew ? (
                    <VisibilityIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => passwordVisibilityToggler("repeatNew")}
                    />
                  ) : (
                    <VisibilityOffIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => passwordVisibilityToggler("repeatNew")}
                    />
                  )}
                </InputAdornment>
              )
            }
          }}
        />
      </Box>
      <Box display="flex">
        <Box display="flex" marginRight="16px">
          <Button
            variant="contained"
            className={styles.cancelButton}
            onClick={onCancel}
            data-testmation="cancelPassword"
          >
            {tl("booking.cancel")}
          </Button>
        </Box>
        <Box display="flex" marginRight="16px">
          <Button
            variant="contained"
            data-testmation="updatePassword"
            color="primary"
            className={styles.updateButton}
            disabled={isDisabled()}
            onClick={() => {
              onSave(form);
              updateForm({ oldPassword: "", newPassword: "", confirmPassword: "" });
            }}
          >
            {tl("account.update")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default connect(
  (state?) => ({
    tempPassword: state.userContext,
    mode: state.userContext.mode,
    user: state.userContext.user
  }),
  (dispatch) => ({
    onSave: async (passwords) => {
      await dispatch(updatePassword(passwords));
    }
  })
)(ChangePassword);
