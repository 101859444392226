import * as React from "react";
import SearchBtn from "@mui/icons-material/Search";
import { ClickAwayListener, Paper, TextField, Box } from "@mui/material";

interface PropsType {
  searchFn: (query: string) => Promise<Array<unknown>>;
  itemRenderer: (item: unknown) => JSX.Element;
  onItemSelect: (item: unknown) => void;
  onChange: (string) => void;
  inputProps?: object;
  Namevalue: string;
  placeholder: string;
}
const Search: React.FC<PropsType> = ({
  searchFn,
  itemRenderer,
  placeholder,
  Namevalue,
  onItemSelect,
  onChange,
  inputProps
}) => {
  const [open, setOpen] = React.useState(false);
  const [query, setQuery] = React.useState("");
  const [items, setItems] = React.useState([]);
  const timeoutRef = React.useRef(null);
  React.useEffect(() => {
    if (query.length > 2) {
      timeoutRef.current = setTimeout(
        () => {
          searchFn(query).then((results) => {
            setItems(results);
          });
        },
        query.length === 3 ? 0 : 500
      );
    } else if (query.length === 0) {
      setItems([]);
    }
    return () => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    };
  }, [query, searchFn]);
  return (
    <ClickAwayListener onClickAway={() => setOpen(false)}>
      <Box style={{ width: "100%" }}>
        <TextField
          placeholder={placeholder}
          fullWidth
          margin="dense"
          variant="outlined"
          value={Namevalue}
          onClick={() => setOpen(true)}
          onChange={(e) => {
            if (onChange) {
              onChange(e.currentTarget.value);
            }
            setQuery(e.currentTarget.value);
          }}
          slotProps={{
            input: inputProps || {
              endAdornment: <SearchBtn style={{ cursor: "pointer" }} />
            }
          }}
        />
        {open && query.length && items.length ? (
          <Paper>
            {items.map((item, idx) => (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={idx}
                onClick={() => {
                  setOpen(false);
                  onItemSelect(item);
                }}
              >
                {itemRenderer(item)}
              </Box>
            ))}
          </Paper>
        ) : null}
      </Box>
    </ClickAwayListener>
  );
};
export default Search;
