import React from "react";
import { Box, Typography } from "@mui/material";
import { t, tl } from "../../components/translate";
import styles from "./Assessment.module.css";
import DebouncedTextField from "../../components/DebouncedTextField";
import useAssessmentLabel from "../../hooks/useAssessmentLabel";

const ProvisionalDiagnosis = ({
  assessmentProvisionalDiagnosis,
  onChange,
  isOpd = true
}: {
  assessmentProvisionalDiagnosis: string;
  onChange: (value) => void;
  isOpd?: boolean;
}): JSX.Element => {
  const assessmentLabels = useAssessmentLabel();
  return (
    <Box className={styles.assessmentRow}>
      <Box className={styles.assessmentLabel}>
        <Typography component="span" fontWeight="600">
          {isOpd ? assessmentLabels.provisionalDiagnosis : tl("assessment.provisionalDiagnosis")}
        </Typography>
      </Box>
      <Box className={styles.assessmentField}>
        <DebouncedTextField
          data-testmation="provisionalDiagnosisTextField"
          margin="dense"
          variant="outlined"
          fullWidth
          multiline
          rowsMax={3}
          slotProps={{
            inputLabel: { shrink: true },
            htmlInput: { maxLength: 255 },
            formHelperText: { style: { position: "absolute", right: 0, bottom: -5, margin: 5 } }
          }}
          value={assessmentProvisionalDiagnosis}
          placeholder={t("assessment.typeProvisionalDiagnosis")}
          showTextCounter
          onChange={(e) => onChange(e.target.value)}
          debounceAt={400}
        />
      </Box>
    </Box>
  );
};

export default ProvisionalDiagnosis;
