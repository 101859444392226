import { TextField, Autocomplete } from "@mui/material";

import * as React from "react";
import { connect } from "react-redux";
import { IServiceTag } from "../../interfaces/ServiceTagsInterface";

const { useState, useEffect } = React;

interface Props {
  serviceTags: Array<IServiceTag>;
  onChange: (id, name) => void;
  value: number; // serviceTag id
}

function ServiceTagsSelect(props: Props) {
  const { serviceTags = [], onChange, value } = props;
  const [selected, setSelected] = useState<number>(null);

  useEffect(() => {
    setSelected(value);
  }, [value]);

  return (
    <Autocomplete<IServiceTag>
      value={serviceTags.find((tag) => tag.id === selected) || null}
      onChange={(_, v) => {
        if (typeof v === "string") return;
        setSelected(v.id);
        onChange(v.id, v.tag);
      }}
      options={serviceTags}
      getOptionLabel={(option) => option.tag}
      style={{ width: 300 }}
      renderInput={(params) => (
        <TextField
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...params}
          label="Service Tag"
          placeholder="Select service tag"
          slotProps={{
            inputLabel: { shrink: true }
          }}
          variant="outlined"
          margin="dense"
        />
      )}
    />
  );
}

const mapStateToProps = (state) => ({ serviceTags: state.serviceTags.collection });

export default connect(mapStateToProps, null)(ServiceTagsSelect);
