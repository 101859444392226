import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import * as React from "react";
import produce from "immer";
import { ResourceCentre } from "../../../interfaces/ResourceCentreInterface";
import { useAppDispatch } from "../../../store/hooks";
import { notificationAdd } from "../../../actions/notification";
import { MODULE, updateErrorMessage, updateSuccessMessage } from "../../../helpers/messages";
import { resourceCentreActions } from "../../../actions";

interface Props {
  resourceCentre: ResourceCentre;
}

const Ssf = ({ resourceCentre }: Props): JSX.Element => {
  const [formData, setFormData] = React.useState({ ...resourceCentre.settings.ssfSettings });
  const [isDirty, setIsDirty] = React.useState(false);

  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    try {
      setIsDirty(false);
      const newRc = produce(resourceCentre, (draft) => {
        draft.settings.ssfSettings = formData;
      });
      await dispatch(resourceCentreActions.putResourceCentre(newRc));
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: updateSuccessMessage(MODULE.SSF_SETTING),
          variant: "success",
          autoTimeout: true
        })
      );
    } catch (e) {
      dispatch(
        notificationAdd({
          id: new Date().getTime(),
          message: e.message || updateErrorMessage(MODULE.SSF_SETTING),
          variant: "error",
          autoTimeout: true
        })
      );
    }
  };

  return (
    <div>
      <Typography fontSize={16} fontWeight={500} gutterBottom>
        Ssf Settings (Provided by SSF)
      </Typography>
      <Box my={4}>
        <Stack gap={2} maxWidth={500}>
          <TextField
            fullWidth
            variant="outlined"
            label="Practitioner"
            placeholder="practitioner id givern by ssf"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            value={formData.practitioner}
            onChange={(event) => {
              setIsDirty(true);
              setFormData({
                ...formData,
                practitioner: event.currentTarget.value
              });
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Practitioner Role"
            placeholder="practitioner role givern by ssf"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            value={formData.practitionerRole}
            onChange={(event) => {
              setIsDirty(true);
              setFormData({
                ...formData,
                practitionerRole: event.currentTarget.value
              });
            }}
          />
          <TextField
            fullWidth
            variant="outlined"
            label="Location/Facility"
            placeholder="location/facility givern by ssf"
            slotProps={{
              inputLabel: { shrink: true }
            }}
            value={formData.location}
            onChange={(event) => {
              setIsDirty(true);
              setFormData({
                ...formData,
                location: event.currentTarget.value
              });
            }}
          />
        </Stack>
        <Box mt={1}>
          <Button variant="contained" onClick={onSubmit} disabled={!isDirty}>
            Save
          </Button>
        </Box>
      </Box>
    </div>
  );
};

export default Ssf;
